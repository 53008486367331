import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-wrapper',
  templateUrl: './common-wrapper.component.html',
  styleUrls: ['./common-wrapper.component.scss']
})
export class CommonWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    console.log('Inside common wrapper');
  }

}
