<app-banners></app-banners>
<section class="cate_sec">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <div class="cate_inner">
          <div class="cate_img">
            <img src="../../../../../assets/images/new-img/womens.jpg" alt="Women's">
          </div>
          <div class="btn_link">
            <a href="#">Women's</a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="cate_inner">
          <div class="cate_img">
            <img src="../../../../../assets/images/new-img/mens.jpg" alt="Men's">
          </div>
          <div class="btn_link">
            <a href="#">Men's</a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="cate_inner">
          <div class="cate_img">
            <img src="../../../../../assets/images/new-img/accessories.jpg" alt="Accessories">
          </div>
          <div class="btn_link">
            <a href="#">Accessories</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-products></app-products>
