import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor() { }

  private toastInfo = new Subject<string | any>();
  emitToast = this.toastInfo.asObservable();

  toastEmit( toastData: any) {
    this.toastInfo.next(toastData);
  }
}
