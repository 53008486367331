<footer class="st_footer">
  <div class="top_foot">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3 col-sm-6 col-12">
          <div class="foot_nav_wrapper">
            <h3>About Us</h3>
            <ul>
              <li><a [routerLink]="'.'">Contact Us</a></li>
              <li><a [routerLink]="'.'">About Us</a></li>
              <li><a [routerLink]="'.'">Careers</a></li>
              <li><a [routerLink]="'.'">Surya Stories</a></li>
              <li><a [routerLink]="'.'">Press</a></li>
              <li><a [routerLink]="'.'">Surya Wholesale</a></li>
              <li><a [routerLink]="'.'">Coporate Information</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="foot_nav_wrapper">
            <h3>Help</h3>
            <ul>
              <li><a [routerLink]="'.'">Payment</a></li>
              <li><a [routerLink]="'.'">Shiping</a></li>
              <li><a [routerLink]="'.'">Cancellation & Returns</a></li>
              <li><a [routerLink]="'.'">FAQ</a></li>
              <li><a [routerLink]="'.'">Report Infringement</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="foot_nav_wrapper">
            <h3>Policy</h3>
            <ul>
              <li><a [routerLink]="'.'">Return Policy</a></li>
              <li><a [routerLink]="'.'">Terms Of Use</a></li>
              <li><a [routerLink]="'.'">Security</a></li>
              <li><a [routerLink]="'.'">Privacy</a></li>
              <li><a [routerLink]="'.'">Sitemap</a></li>
              <li><a [routerLink]="'.'">EPR Compliance</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="foot_nav_wrapper">
            <h3>Social</h3>
            <ul>
              <li><a [routerLink]="'.'">Facebook</a></li>
              <li><a [routerLink]="'.'">Twitter</a></li>
              <li><a [routerLink]="'.'">Linked In</a></li>
              <li><a [routerLink]="'.'">YouTube</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom_foot">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <p>&#169; 2022 <span>Surya e-Commerce</span>. All Rights Reserved</p>
        </div>
      </div>
    </div>
  </div>
</footer>
