import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private static key(key: string): string {
    return `surya:${key}`;
  }

  get(key: string): any {
    return localStorage.getItem(LocalStorageService.key(key));
  }

  set<T>(key: string, value: any) {
    const valueToStore = typeof (value) === 'object' ? JSON.stringify(value) : value.toString();
    localStorage.setItem(LocalStorageService.key(key), valueToStore);
  }

  remove(key: string) {
    localStorage.removeItem(LocalStorageService.key(key));
  }

  clear() {
    localStorage.clear();
  }
}