import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/apis/auth.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { country_dial_code } from 'src/app/shared/constants/country-dial-code';
import { countries } from 'src/app/shared/constants/countries';
import { inputLengh } from 'src/app/shared/constants/common-constants';
import { ToastService } from 'src/app/shared/services/toast.service';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  show: boolean = true;
  formGroup: FormGroup;
  countries:any = countries;
  dialCodes:any = country_dial_code;
  states:any = countries[0].States;
  hidePassword: boolean = true;
  hideConfirmPassword: boolean = true;
  constructor(private fb: FormBuilder, private readonly route: Router, private readonly authService: AuthService,
    private readonly helperService: HelperService, private toastService: ToastService) { }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      'firstName': [null, [Validators.required, Validators.maxLength(inputLengh.nameLength), Validators.pattern('[a-zA-Z]*')]],
      'lastName': [null, [Validators.required, Validators.maxLength(inputLengh.nameLength), Validators.pattern('[a-zA-Z ]*')]],
      'email': [null, [Validators.required, Validators.email]],
      'password': [null, Validators.required],
      'confirmPassword': [null, Validators.required],
      'dialCode': [country_dial_code[0].dialCode],
      'phoneNumber': [null, [Validators.required, Validators.pattern('[0-9]{7,12}')]]
    })
  }

  confirmEquals() {
    return this.formGroup.value.password === this.formGroup.value.confirmPassword;
  }

  get signUpFormfield() {
    return this.formGroup.controls;
  }
  onCountryChange(selectedCountry: any) {
    this.states = countries.find(o => o.CountryName === selectedCountry).States;
  }

  onSubmit():void {
    console.log("this.form.controls ", this.formGroup.controls);
    Object.keys(this.formGroup.controls).forEach(field => { // {1}
      const control = this.formGroup.get(field);            // {2}
      control.markAsTouched({ onlySelf: true });       // {3}
    });
    if(this.formGroup.valid) {
      const data: any = {
        'firstName' : this.formGroup.value.firstName,
        'lastName' : this.formGroup.value.lastName,
        'email' : this.formGroup.value.email,
        'password' : this.formGroup.value.password,
        'phoneNumber' : this.formGroup.value.phoneNumber

      }
      this.authService.signup(data)
      .subscribe((res)=> {
        if(res.status){
          this.toastService.toastEmit({ message: 'Successful', classname: 'bg-success text-light', delay: 3000 });
          this.route.navigate(['/']);
        }
      }, err => {
          this.toastService.toastEmit({ message: err.error.message, classname: 'bg-danger text-light', delay: 3000 });
      })
    }
  }



}
