import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageWrapperComponent } from './pages/landing-page-wrapper/landing-page-wrapper.component';

const routes: Routes = [{
  path: '',
  component: LandingPageWrapperComponent,
  children: [
    // {
    //   path: ':categoryId/product-list',
    //   component: ProductListComponent,
    //   data: { title: 'category' }
    // },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LandingPageRoutingModule { }
