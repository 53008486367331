<section class="auth_sec">
  <div class="auth_card">
    <div class="row">
      <div class="col-6 left_col">
        <div class="inner_sec">
          <div class="img_sec">
            <img src="../../../../../assets/images/new-img/auth_img.png" alt="Shopping_img">
          </div>
          <p>Get access to your Orders,<br />
            Wishlist and Recommendations</p>
          <div class="btn_sec">
            <button type="submit" class="btn btn-block" [routerLink]="'/login'">Log In</button>
          </div>
        </div>
      </div>
      <div class="col-6 right_col">
        <div class="col_inner">
          <div class="brand-sec">
            <img src="../../../../../assets/images/logo.png" alt="Surya">
          </div>
          <div class="form_sec">
            <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>First Name</label>
                    <div class="input_group">
                      <img src="../../../../../assets/images/new-img/user.png" class="input_icon" alt="User">
                      <input type="text" class="form-control" formControlName="firstName"
                        placeholder="Enter your first name">
                      <span class="focus-border"></span>
                    </div>
                    <div class="error-message"
                      *ngIf="signUpFormfield.firstName.touched && signUpFormfield.firstName.errors?.required">
                      <small>Enter your first name</small>
                    </div>
                    <div class="error-message"
                      *ngIf="signUpFormfield.firstName.touched && !signUpFormfield.firstName.errors?.required && signUpFormfield.firstName.errors?.pattern">
                      <small>Please enter a valid first name</small>
                    </div>
                    <div class="error-message"
                      *ngIf="signUpFormfield.firstName.touched && !signUpFormfield.firstName.errors?.required && !signUpFormfield.firstName.errors?.pattern && signUpFormfield.firstName.errors?.maxlength">
                      <small>First Name is too long</small>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Last Name</label>
                    <div class="input_group">
                      <img src="../../../../../assets/images/new-img/user.png" class="input_icon" alt="User">
                      <input type="text" class="form-control" formControlName="lastName"
                        placeholder="Enter your last name">
                      <span class="focus-border"></span>
                    </div>
                    <div class="error-message"
                      *ngIf="signUpFormfield.lastName.touched && signUpFormfield.lastName.errors?.required">
                      <small>Enter your last name</small>
                    </div>
                    <div class="error-message"
                      *ngIf="signUpFormfield.lastName.touched && !signUpFormfield.lastName.errors?.required && signUpFormfield.lastName.errors?.pattern">
                      <small>Please enter a valid last name</small>
                    </div>
                    <div class="error-message"
                      *ngIf="signUpFormfield.lastName.touched && !signUpFormfield.lastName.errors?.required && !signUpFormfield.lastName.errors?.pattern && signUpFormfield.lastName.errors?.maxlength">
                      <small>Last Name is too long</small>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Your Email</label>
                    <div class="input_group">
                      <img src="../../../../../assets/images/new-img/email.png" class="input_icon" alt="Email">
                      <input type="email" formControlName="email" class="form-control" placeholder="Enter email">
                      <span class="focus-border"></span>
                    </div>
                    <div class="error-message"
                      *ngIf="signUpFormfield.email.touched && signUpFormfield.email.errors?.required"><small>Please
                        enter your email</small>
                    </div>
                    <div class="error-message"
                      *ngIf="signUpFormfield.email.touched && !signUpFormfield.email.errors?.required && signUpFormfield.email.errors?.email">
                      <small>Please enter a valid email</small>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Phone Number</label>
                    <div class="input_group">
                      <!-- <select class="input-group-text" id="basic-addon1" formControlName="dialCode">
                        <option *ngFor="let dialCode of dialCodes">{{dialCode.dialCode}}</option>
                      </select> -->
                      <img src="../../../../../assets/images/new-img/mobile.png" class="input_icon" alt="Mobile">
                      <input type="text" formControlName="phoneNumber" class="form-control" placeholder="Enter number">
                      <span class="focus-border"></span>
                    </div>
                    <div class="error-message"
                      *ngIf="signUpFormfield.phoneNumber.touched && signUpFormfield.phoneNumber.errors?.required">
                      <small>Please enter your phone</small>
                    </div>
                    <div class="error-message"
                      *ngIf="signUpFormfield.phoneNumber.touched && !signUpFormfield.phoneNumber.errors?.required && signUpFormfield.phoneNumber.errors?.pattern">
                      <small>Please enter a valid phone number</small>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>City</label>
                    <div class="input_group">
                      <img src="../../../../../assets/images/new-img/city.png" class="input_icon" alt="City">
                      <input type="text" class="form-control" placeholder="Enter your city">
                      <span class="focus-border"></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>State</label>
                    <div class="input_group">
                      <img src="../../../../../assets/images/new-img/state.png" class="input_icon" alt="State">
                      <input type="text" class="form-control" placeholder="Enter your state">
                      <span class="focus-border"></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Country</label>
                    <div class="input_group">
                      <img src="../../../../../assets/images/new-img/country.png" class="input_icon" alt="Country">
                      <input type="text" class="form-control" placeholder="Enter your Country">
                      <span class="focus-border"></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Pin Code</label>
                    <div class="input_group">
                      <img src="../../../../../assets/images/new-img/pin.png" class="input_icon" alt="Pincode">
                      <input type="text" class="form-control" placeholder="Enter your pin code">
                      <span class="focus-border"></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Password</label>
                    <div class="input_group phone_group">
                      <img src="../../../../../assets/images/new-img/password.png" class="input_icon" alt="Lock">
                      <input [type]="hidePassword ? 'password' : 'text'" class="form-control" formControlName="password"
                        placeholder="Enter password">
                      <span class="focus-border"></span>
                      <button *ngIf="hidePassword" type="button" (click)="hidePassword = !hidePassword"
                        class="btn btn-light"><img src="../../../assets/images/hidden.png"
                          alt="Password Hidden"></button>
                      <button *ngIf="!hidePassword" type="button" (click)="hidePassword = !hidePassword"
                        class="btn btn-light"><img src="../../../assets/images/view.png"
                          alt="Password Visible"></button>
                    </div>
                    <div class="error-message"
                      *ngIf="signUpFormfield.password.touched && signUpFormfield.password.errors?.required">
                      <small>Please enter your password</small>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Confirm Password</label>
                    <div class="input_group phone_group">
                      <img src="../../../../../assets/images/new-img/password.png" class="input_icon" alt="Lock">
                      <input [type]="hideConfirmPassword ? 'password' : 'text'" class="form-control"
                        formControlName="confirmPassword" placeholder="Re-enter password">
                      <span class="focus-border"></span>
                      <button *ngIf="hideConfirmPassword" type="button"
                        (click)="hideConfirmPassword = !hideConfirmPassword" class="btn btn-light"><img
                          src="../../../assets/images/hidden.png" alt="Password Hidden"></button>
                      <button *ngIf="!hideConfirmPassword" type="button"
                        (click)="hideConfirmPassword = !hideConfirmPassword" class="btn btn-light"><img
                          src="../../../assets/images/view.png" alt="Password Visible"></button>
                    </div>
                    <div class="error-message"
                      *ngIf="signUpFormfield.confirmPassword.touched && signUpFormfield.confirmPassword.errors?.required">
                      <small>Please enter your confirm password</small>
                    </div>
                    <div class="error-message"
                      *ngIf="!signUpFormfield?.confirmPassword?.errors?.required && signUpFormfield.confirmPassword.dirty && !confirmEquals()">
                      <small>New Password and Confirm Password must be same</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn_sec">
                <button type="submit" class="btn btn-block">Sign Up</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <app-toast-container></app-toast-container> -->
<!-- <section class="auth_wrapper">
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-lg-8 p-0">
        <div class="auth_form_wrapper">
          <div class="auth_form_inner">
            <div class="logo_sec">
              <img src="../../../../../assets/images/logo.png" alt="Logo">
            </div>
            <div class="surya_head">
              <h4>Registration to your account</h4>
            </div>
            <div class="surya_form">
              <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>First Name</label>
                      <input type="text" class="form-control" formControlName="firstName"
                        placeholder="Enter your first name">
                      <div class="error-message"
                        *ngIf="signUpFormfield.firstName.touched && signUpFormfield.firstName.errors?.required"><small>Please
                        enter your first name</small>
                    </div>
                    <div class="error-message"
                      *ngIf="signUpFormfield.firstName.touched && !signUpFormfield.firstName.errors?.required && signUpFormfield.firstName.errors?.pattern">
                      <small>Please enter a valid first name</small>
                    </div>
                    <div class="error-message"
                      *ngIf="signUpFormfield.firstName.touched && !signUpFormfield.firstName.errors?.required && !signUpFormfield.firstName.errors?.pattern && signUpFormfield.firstName.errors?.maxlength">
                      <small>First Name is too long</small>
                    </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Last Name</label>
                      <input type="text" class="form-control" formControlName="lastName"
                        placeholder="Enter your last name">
                      <div class="error-message"
                        *ngIf="signUpFormfield.lastName.touched && signUpFormfield.lastName.errors?.required">
                        <small>Please
                          enter your last name</small>
                      </div>
                      <div class="error-message"
                        *ngIf="signUpFormfield.lastName.touched && !signUpFormfield.lastName.errors?.required && signUpFormfield.lastName.errors?.pattern">
                        <small>Please enter a valid last name</small>
                      </div>
                      <div class="error-message"
                        *ngIf="signUpFormfield.lastName.touched && !signUpFormfield.lastName.errors?.required && !signUpFormfield.lastName.errors?.pattern && signUpFormfield.lastName.errors?.maxlength">
                        <small>Last Name is too long</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Your Email</label>
                      <input type="email" formControlName="email" class="form-control" placeholder="Enter email">
                      <div class="error-message"
                        *ngIf="signUpFormfield.email.touched && signUpFormfield.email.errors?.required"><small>Please
                          enter your email</small>
                      </div>
                      <div class="error-message" *ngIf="signUpFormfield.email.touched && !signUpFormfield.email.errors?.required && signUpFormfield.email.errors?.email"><small>Please
                          enter a valid email</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Phone Number</label> -->

<!-- <input type="tel" class="form-control" placeholder="Enter number"> -->

<!-- <div class="input-group mb-3">
                        <select class="input-group-text" id="basic-addon1" formControlName="dialCode">
                          <option *ngFor="let dialCode of dialCodes">{{dialCode.dialCode}}</option>
                        </select>
                          <input type="text" formControlName="phoneNumber" class="form-control" placeholder="Enter number">
                        </div>
                        <div class="error-message" *ngIf="signUpFormfield.phoneNumber.touched && signUpFormfield.phoneNumber.errors?.required">
                          <small>Please
                            enter your phone</small>
                        </div>
                        <div class="error-message"
                          *ngIf="signUpFormfield.phoneNumber.touched && !signUpFormfield.phoneNumber.errors?.required && signUpFormfield.phoneNumber.errors?.pattern">
                          <small>Please
                            enter a valid phone number</small>
                        </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Password</label>
                      <div class="password-input-wrapper">
                        <input [type]="hidePassword ? 'password' : 'text'" class="form-control" formControlName="password"
                          placeholder="Enter password">
                        <button *ngIf="hidePassword" type="button" (click)="hidePassword = !hidePassword" class="btn btn-light"><img src="../../../assets/images/hidden.png"
                            alt="Password Hidden"></button>
                        <button *ngIf="!hidePassword" type="button"  (click)="hidePassword = !hidePassword" class="btn btn-light"><img src="../../../assets/images/view.png"
                        alt="Password Visible"></button>
                      </div>
                      <div class="error-message"
                        *ngIf="signUpFormfield.password.touched && signUpFormfield.password.errors?.required">
                        <small>Please
                          enter your password</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Confirm Password</label>
                      <div class="password-input-wrapper">
                        <input [type]="hideConfirmPassword ? 'password' : 'text'" class="form-control" formControlName="confirmPassword"
                          placeholder="Re-enter password">
                        <button *ngIf="hideConfirmPassword" type="button"  (click)="hideConfirmPassword = !hideConfirmPassword" class="btn btn-light"><img src="../../../assets/images/hidden.png"
                            alt="Password Hidden"></button>
                        <button *ngIf="!hideConfirmPassword" type="button"  (click)="hideConfirmPassword = !hideConfirmPassword" class="btn btn-light"><img src="../../../assets/images/view.png"
                        alt="Password Visible"></button>
                      </div>
                      <div class="error-message"
                        *ngIf="signUpFormfield.confirmPassword.touched && signUpFormfield.confirmPassword.errors?.required">
                        <small>Please
                          enter your confirm password</small>
                      </div>
                      <div class="error-message"
                        *ngIf="!signUpFormfield?.confirmPassword?.errors?.required && signUpFormfield.confirmPassword.dirty && !confirmEquals()">
                        <small>New Password and Confirm Password must be same</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="btn_sec">
                  <button type="submit" class="btn btn-block">Register Now</button>
                </div>
              </form>
            </div> -->


<!-- <div class="other_login_sec">
              <p>Already have an account? <a [routerLink]="'/login'">Sign In</a></p>
            </div> -->


<!-- </div>
        </div>
      </div>
      <div class="col-lg-4 p-0">
        <div class="auth_info">
          <div class="info_inner">
            <h1>Welcome to Surya</h1>
            <p>An e-commerce platform that can<br />grow your business.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
