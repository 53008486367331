import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from 'src/app/shared/models/http-response';
import { UnauthenticatedApiClient } from 'src/app/shared/services/http-api-client.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpApi: UnauthenticatedApiClient) {}

  public login(creds: any): Observable<HttpResponse<any>> {
    return this.httpApi.post<any>(`/auth/customer/login`, creds);
  }

  public signup(registrationDetails: any): Observable<HttpResponse<any>> {
    return this.httpApi.post<any>(`/customer/signup`, registrationDetails);
  }

}
