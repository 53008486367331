export const countries = [
    {
      "States": [
        {
          "Cities": [
            "Eshkashem",
            "Fayzabad",
            "Jurm",
            "Khandud",
            "Qal'eh-ye Panjeh"
          ],
          "StateName": "Badakhshan"
        },
        {
          "Cities": [
            "Bala Morghab",
            "Qal'eh-ye Naw"
          ],
          "StateName": "Badgis"
        },
        {
          "Cities": [
            "Andarab",
            "Baghlan",
            "Dahaneh-ye Ghawri",
            "Nahrin",
            "Pol-e Khumri"
          ],
          "StateName": "Baglan"
        },
        {
          "Cities": [
            "Balkh",
            "Dawlatabad",
            "Mazar-e Sharif",
            "Qarchi Gak",
            "Shulgara",
            "Tash Gozar"
          ],
          "StateName": "Balkh"
        },
        {
          "Cities": [
            "Bamiyan",
            "Panjab",
            "Qil Qal'eh"
          ],
          "StateName": "Bamiyan"
        },
        {
          "Cities": [
            "Anar Darreh",
            "Farah",
            "Shindand"
          ],
          "StateName": "Farah"
        },
        {
          "Cities": [
            "Andkhvoy",
            "Darzi Ab",
            "Dawlatabad",
            "Maymanah"
          ],
          "StateName": "Faryab"
        },
        {
          "Cities": [
            "Chaghcharan",
            "Shahrak",
            "Taywarah"
          ],
          "StateName": "Gawr"
        },
        {
          "Cities": [
            "Ghazni"
          ],
          "StateName": "Gazni"
        },
        {
          "Cities": [
            "Awbeh",
            "Eslam Qal'eh",
            "Ghurian",
            "Herat",
            "Karukh",
            "Kuhestan",
            "Kushk",
            "Qarabagh",
            "Tawraghudi",
            "Tir Pol",
            "Zendejan"
          ],
          "StateName": "Herat"
        },
        {
          "Cities": [
            "Baghran",
            "Darwishan",
            "Deh Shu",
            "Gereshk",
            "Lashkar Gah",
            "Sangin"
          ],
          "StateName": "Hilmand"
        },
        {
          "Cities": [
            "Aqchah",
            "Qarqin",
            "Sang-e Charak",
            "Shibarghan"
          ],
          "StateName": "Jawzjan"
        },
        {
          "Cities": [
            "Baghrami",
            "Kabul",
            "Mir Bachchekut",
            "Paghman",
            "Qarabagh",
            "Sarawbi"
          ],
          "StateName": "Kabul"
        },
        {
          "Cities": [
            "Mahmud-e Raqi",
            "Taghab"
          ],
          "StateName": "Kapisa"
        },
        {
          "Cities": [
            "Khawst"
          ],
          "StateName": "Khawst"
        },
        {
          "Cities": [
            "Asadabad",
            "Asmar"
          ],
          "StateName": "Kunar"
        },
        {
          "Cities": [
            "Mehtar Lam"
          ],
          "StateName": "Lagman"
        },
        {
          "Cities": [
            "Azraw",
            "Baraki Barak",
            "Pol-e Alam"
          ],
          "StateName": "Lawghar"
        },
        {
          "Cities": [
            "Achin",
            "Batsawul",
            "Hugyani",
            "Jalalabad",
            "Nader Shah Kawt"
          ],
          "StateName": "Nangarhar"
        },
        {
          "Cities": [
            "Chahar Burjak",
            "Chakhansur",
            "Khash",
            "Mirabad",
            "Rudbar",
            "Zaranj"
          ],
          "StateName": "Nimruz"
        },
        {
          "Cities": [
            "Nuristan"
          ],
          "StateName": "Nuristan"
        },
        {
          "Cities": [
            "Orgun",
            "Zareh Sharan",
            "Zarghun Shahr"
          ],
          "StateName": "Paktika"
        },
        {
          "Cities": [
            "'Ali Khayl",
            "Ghardez"
          ],
          "StateName": "Paktiya"
        },
        {
          "Cities": [
            "Charikar",
            "Jabal-os-Saraj"
          ],
          "StateName": "Parwan"
        },
        {
          "Cities": [
            "Qandahar"
          ],
          "StateName": "Qandahar"
        },
        {
          "Cities": [
            "Dasht-e Archa",
            "Emam Saheb",
            "Hazart Imam",
            "Khanabad",
            "Qal'eh-ye Zal",
            "Qunduz"
          ],
          "StateName": "Qunduz"
        },
        {
          "Cities": [
            "Aybak",
            "Kholm"
          ],
          "StateName": "Samangan"
        },
        {
          "Cities": [],
          "StateName": "Sar-e Pul"
        },
        {
          "Cities": [
            "Chah Ab",
            "Eshkamesh",
            "Farkhar",
            "Khwajeh Ghar",
            "Rostaq",
            "Taloqan",
            "Yangi Qal'eh"
          ],
          "StateName": "Takhar"
        },
        {
          "Cities": [
            "Deh Rawud",
            "Gaz Ab",
            "Tarin Kawt",
            "Uruzgan"
          ],
          "StateName": "Uruzgan"
        },
        {
          "Cities": [
            "Gardan Diwal",
            "Maydanshahr"
          ],
          "StateName": "Wardag"
        },
        {
          "Cities": [
            "Qalat-e Ghilzay"
          ],
          "StateName": "Zabul"
        }
      ],
      "CountryName": "Afghanistan"
    },
    {
      "States": [
        {
          "Cities": [
            "Berat",
            "Polican",
            "Ure Vajgurore"
          ],
          "StateName": "Berat"
        },
        {
          "Cities": [
            "Bulqize"
          ],
          "StateName": "Bulqize"
        },
        {
          "Cities": [
            "Delvine"
          ],
          "StateName": "Delvine"
        },
        {
          "Cities": [
            "Bilisht"
          ],
          "StateName": "Devoll"
        },
        {
          "Cities": [
            "Maqellare",
            "Peshkopi"
          ],
          "StateName": "Dibre"
        },
        {
          "Cities": [
            "Durres",
            "Shijak"
          ],
          "StateName": "Durres"
        },
        {
          "Cities": [
            "Cerrik",
            "Elbasan",
            "Kerrabe"
          ],
          "StateName": "Elbasan"
        },
        {
          "Cities": [
            "Fier",
            "Patos",
            "Roskovec"
          ],
          "StateName": "Fier"
        },
        {
          "Cities": [
            "Gjirokaster",
            "Libohove"
          ],
          "StateName": "Gjirokaster"
        },
        {
          "Cities": [
            "Gramsh"
          ],
          "StateName": "Gramsh"
        },
        {
          "Cities": [
            "Krume"
          ],
          "StateName": "Has"
        },
        {
          "Cities": [
            "Kavaje"
          ],
          "StateName": "Kavaje"
        },
        {
          "Cities": [
            "Erseke",
            "Leskovik"
          ],
          "StateName": "Kolonje"
        },
        {
          "Cities": [
            "Korce",
            "Maliq"
          ],
          "StateName": "Korce"
        },
        {
          "Cities": [
            "Fushe-Kruje",
            "Kruje",
            "Mamurras",
            "Milot"
          ],
          "StateName": "Kruje"
        },
        {
          "Cities": [
            "Kucove"
          ],
          "StateName": "Kucove"
        },
        {
          "Cities": [
            "Kukes"
          ],
          "StateName": "Kukes"
        },
        {
          "Cities": [
            "Lac"
          ],
          "StateName": "Kurbin"
        },
        {
          "Cities": [
            "Lezhe",
            "Shengjin"
          ],
          "StateName": "Lezhe"
        },
        {
          "Cities": [
            "Librazhd",
            "Perrenjas"
          ],
          "StateName": "Librazhd"
        },
        {
          "Cities": [
            "Lushnje",
            "Rrogozhine"
          ],
          "StateName": "Lushnje"
        },
        {
          "Cities": [
            "Ballsh"
          ],
          "StateName": "Mallakaster"
        },
        {
          "Cities": [],
          "StateName": "Malsi e Madhe"
        },
        {
          "Cities": [
            "Burrel",
            "Klos",
            "Kraste",
            "Ulze"
          ],
          "StateName": "Mat"
        },
        {
          "Cities": [
            "Kurbnesh",
            "Rreshen",
            "Rubik"
          ],
          "StateName": "Mirdite"
        },
        {
          "Cities": [
            "Peqin"
          ],
          "StateName": "Peqin"
        },
        {
          "Cities": [
            "Kelcyre",
            "Permet"
          ],
          "StateName": "Permet"
        },
        {
          "Cities": [
            "Pogradec"
          ],
          "StateName": "Pogradec"
        },
        {
          "Cities": [
            "Fierze",
            "Fushe-Arrez",
            "Puke"
          ],
          "StateName": "Puke"
        },
        {
          "Cities": [
            "Konispol",
            "Sarande"
          ],
          "StateName": "Sarande"
        },
        {
          "Cities": [
            "Shkoder"
          ],
          "StateName": "Shkoder"
        },
        {
          "Cities": [
            "Corovode"
          ],
          "StateName": "Skrapar"
        },
        {
          "Cities": [
            "Memaliaj",
            "Tepelene"
          ],
          "StateName": "Tepelene"
        },
        {
          "Cities": [
            "Kamze",
            "Paskuqan",
            "Tirane",
            "Vore"
          ],
          "StateName": "Tirane"
        },
        {
          "Cities": [
            "Bajram Curri"
          ],
          "StateName": "Tropoje"
        },
        {
          "Cities": [
            "Himare",
            "Orikum",
            "Selenice",
            "Vlore"
          ],
          "StateName": "Vlore"
        }
      ],
      "CountryName": "Albania"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "'Ayn Daflah"
        },
        {
          "Cities": [],
          "StateName": "'Ayn Tamushanat"
        },
        {
          "Cities": [
            "Adrar",
            "Awlaf",
            "Rijan",
            "Timimun"
          ],
          "StateName": "Adrar"
        },
        {
          "Cities": [
            "Hydra",
            "Kouba"
          ],
          "StateName": "Algiers"
        },
        {
          "Cities": [
            "Annabah",
            "Birrahhal",
            "Saraydih",
            "Sidi Amar",
            "al-Buni",
            "al-Hajar"
          ],
          "StateName": "Annabah"
        },
        {
          "Cities": [
            "'Abadlah",
            "Bani Wanif",
            "Bashshar",
            "Qanadsan",
            "Taghit"
          ],
          "StateName": "Bashshar"
        },
        {
          "Cities": [
            "'Aris",
            "'Ayn Tutah",
            "Barikah",
            "Batnah",
            "Marwanah",
            "Naghaus",
            "Ra's-al-'Ayun",
            "Tazult"
          ],
          "StateName": "Batnah"
        },
        {
          "Cities": [
            "'Ayt Rizin",
            "Akbu",
            "Amizur",
            "Barbasha",
            "Bijayah",
            "Farrawn",
            "Ighram",
            "Sadduk",
            "Shamini",
            "Sidi 'Aysh",
            "Taskaryut",
            "Tazmalt",
            "Timazrit",
            "Uz-al-Laqin",
            "al-Qasr"
          ],
          "StateName": "Bijayah"
        },
        {
          "Cities": [
            "Awlad Jallal",
            "Biskrah",
            "Sidi Khalid",
            "Sidi Ukbah",
            "Tulja",
            "Um'ash",
            "Zaribat-al-Wad"
          ],
          "StateName": "Biskrah"
        },
        {
          "Cities": [
            "Awlad Salam",
            "Awlad Yaysh",
            "Bani Khalil",
            "Bani Marad",
            "Bani Tamu",
            "Blidah",
            "Bu Arfa",
            "Bufarik",
            "Buinan",
            "Buqara",
            "Maftah",
            "Muzayah",
            "Shabli",
            "Shari'ah",
            "Shiffa",
            "Sidi Mussa",
            "Suma",
            "Wadi al-Allagh",
            "al-'Afrun",
            "al-Arba'a"
          ],
          "StateName": "Blidah"
        },
        {
          "Cities": [
            "'Ayn Bissim",
            "Aghbalu",
            "Bi'r Ghabalu",
            "Buirah",
            "Lakhdariyah",
            "Shurfa",
            "Sur-al-Ghuzlan"
          ],
          "StateName": "Buirah"
        },
        {
          "Cities": [
            "'Ayn Tayah",
            "Awlad Haddaj",
            "Awlad Mussa",
            "Bani Amran",
            "Budwawu",
            "Budwawu al-Bahri",
            "Bumardas",
            "Burj Minayal",
            "Dalis",
            "Hammadi",
            "Issar",
            "Khamis-al-Khashnah",
            "Nasiriyah",
            "Raghayah",
            "Sa'abat",
            "Tinyah",
            "al-Arba'a Tash",
            "ar-Ruwibah"
          ],
          "StateName": "Bumardas"
        },
        {
          "Cities": [],
          "StateName": "Burj Bu Arririj"
        },
        {
          "Cities": [
            "Ammi Mussa",
            "Ghalizan",
            "Jidiwiyah",
            "Mazunah",
            "Sidi Muhammad Ban 'Ali",
            "Wadi Rahiyu",
            "Zammurah"
          ],
          "StateName": "Ghalizan"
        },
        {
          "Cities": [
            "Biryan",
            "Bu Nura",
            "Ghardaia",
            "Ghardayah",
            "Matlili",
            "al-Ghuli'ah",
            "al-Qararah"
          ],
          "StateName": "Ghardayah"
        },
        {
          "Cities": [
            "Ilizi"
          ],
          "StateName": "Ilizi"
        },
        {
          "Cities": [
            "Amir 'Abd-al-Qadar",
            "Jijili",
            "Shifka",
            "Tahar",
            "al-Miliyah"
          ],
          "StateName": "Jijili"
        },
        {
          "Cities": [
            "'Ayn Wissarah",
            "'Ayn-al-Ibil",
            "Birin",
            "Dar Shiyukh",
            "Hassi Bahbah",
            "Jilfah",
            "Mis'ad",
            "Sharif",
            "al-Idrisiyah"
          ],
          "StateName": "Jilfah"
        },
        {
          "Cities": [
            "Khanshalah",
            "Sharshar",
            "Tawziyanat",
            "al-Mahmal"
          ],
          "StateName": "Khanshalah"
        },
        {
          "Cities": [
            "'Ayn-al-Hajal",
            "'Ayn-al-Milh",
            "Bu Sa'adah",
            "Hammam Dhala'a",
            "Ma'adid",
            "Maghra",
            "Masilah",
            "Sidi 'Aysa",
            "Wanugha"
          ],
          "StateName": "Masilah"
        },
        {
          "Cities": [
            "'Ayn Bu Sif",
            "Birwaghiyah",
            "Midyah",
            "Qasr-al-Bukhari",
            "Shillalah",
            "Tablat"
          ],
          "StateName": "Midyah"
        },
        {
          "Cities": [
            "Farjiwah",
            "Milah",
            "Qararam Quqa",
            "Ruwashad",
            "Salghum-al-'Ayd",
            "Sidi Maruf",
            "Sidi Marwan",
            "Tajananah",
            "Talighmah",
            "Wadi Athmaniyah"
          ],
          "StateName": "Milah"
        },
        {
          "Cities": [
            "Bu Khanifiyah",
            "Muaskar",
            "Muhammadiyah",
            "Siq",
            "Tighinnif",
            "Wadi al-Abtal",
            "Zahana"
          ],
          "StateName": "Muaskar"
        },
        {
          "Cities": [
            "'Ayn Tadalas",
            "Hassi Mamash",
            "Mazaghran",
            "Mustaghanam",
            "Sidi Ali"
          ],
          "StateName": "Mustaghanam"
        },
        {
          "Cities": [
            "'Ayn Safra",
            "Mishriyah",
            "Naama"
          ],
          "StateName": "Naama"
        },
        {
          "Cities": [
            "Oran"
          ],
          "StateName": "Oran"
        },
        {
          "Cities": [
            "Ouargla"
          ],
          "StateName": "Ouargla"
        },
        {
          "Cities": [
            "'Ayn Bardah",
            "Bumahra Ahmad",
            "Hamman Awlad 'Ali",
            "Qalmah",
            "Wadi Zinati"
          ],
          "StateName": "Qalmah"
        },
        {
          "Cities": [
            "'Ayn Abid",
            "'Ayn Samara",
            "Didush Murad",
            "Hamma Bu Ziyan",
            "Qustantinah",
            "Zighut Yusuf",
            "al-Khurub"
          ],
          "StateName": "Qustantinah"
        },
        {
          "Cities": [
            "'Azzabah",
            "Amjaz Adshish",
            "Fil Fila",
            "Karkira",
            "Ramadan Jamal",
            "Sakikdah",
            "Shataybih",
            "Tamalus",
            "al-Harush",
            "al-Qull"
          ],
          "StateName": "Sakikdah"
        },
        {
          "Cities": [
            "'Ayn 'Azl",
            "'Ayn Arnat",
            "'Ayn Taqrut",
            "'Ayn Wilman",
            "'Ayn-al-Khabira",
            "Bouira",
            "Buq'ah",
            "Salah Bay",
            "Satif",
            "Setif",
            "Ziyama Mansuriyah",
            "al-'Ulmah"
          ],
          "StateName": "Satif"
        },
        {
          "Cities": [
            "'Ayn-al-Hajar",
            "Sayda'"
          ],
          "StateName": "Sayda'"
        },
        {
          "Cities": [],
          "StateName": "Sidi ban-al-'Abbas"
        },
        {
          "Cities": [],
          "StateName": "Suq Ahras"
        },
        {
          "Cities": [
            "'Ayn Qazzan",
            "'Ayn Salah",
            "Tamanghasat"
          ],
          "StateName": "Tamanghasat"
        },
        {
          "Cities": [
            "'Ayn Binyan",
            "Bu Isma'il",
            "Bu Midfar'ah",
            "Damus",
            "Duwirah",
            "Hajut",
            "Hammam Righa",
            "Sawlah",
            "Shiragha",
            "Shirshall",
            "Sidi Farj",
            "Stawali",
            "Tibazah",
            "Ziralda",
            "al-Qull'ah"
          ],
          "StateName": "Tibazah"
        },
        {
          "Cities": [
            "Bi'r-al-'Itir",
            "Hammamat",
            "Mursut",
            "Shariyah",
            "Tibissah",
            "Winzah",
            "al-'Awaynat"
          ],
          "StateName": "Tibissah"
        },
        {
          "Cities": [
            "Awlad Mimun",
            "Bani Mastar",
            "Bani Sikran",
            "Ghazawat",
            "Hannayah",
            "Maghniyah",
            "Nidruma",
            "Ramsh",
            "Sabra",
            "Shatwan",
            "Sibdu",
            "Sidi 'Abdallah",
            "Tilimsan",
            "al-Mansurah"
          ],
          "StateName": "Tilimsan"
        },
        {
          "Cities": [
            "Tinduf"
          ],
          "StateName": "Tinduf"
        },
        {
          "Cities": [
            "Thaniyat-al-Had",
            "Tisamsilt"
          ],
          "StateName": "Tisamsilt"
        },
        {
          "Cities": [
            "'Ayn Dhahab",
            "Firindah",
            "Mahdiyah",
            "Mashra'a Asfa",
            "Qasr Shillalah",
            "Rahuyah",
            "Sughar",
            "Takhamarat",
            "Tiyarat"
          ],
          "StateName": "Tiyarat"
        },
        {
          "Cities": [],
          "StateName": "Tizi Wazu"
        },
        {
          "Cities": [
            "'Ayn Bayda",
            "'Ayn Fakrun",
            "'Ayn Kirshah",
            "'Ayn Malilah",
            "Bi'r Shuhada",
            "Miskyanah",
            "Shamurah",
            "Umm-al-Bawaghi"
          ],
          "StateName": "Umm-al-Bawaghi"
        },
        {
          "Cities": [
            "'Ayn Biya",
            "'Ayn-at-Turk",
            "Arzu",
            "Bi'r-al-Jir",
            "Butlilis",
            "Hassi Bu Nif",
            "Mars-al-Kabir",
            "Qadayal",
            "Sidi ash-Shami",
            "Wadi Thalatha",
            "Wahran",
            "al-Ansur",
            "as-Saniyah"
          ],
          "StateName": "Wahran"
        },
        {
          "Cities": [
            "Hassi Mas'ud",
            "Nazla",
            "Ruwisiyat",
            "Tabisbast",
            "Tamalhat",
            "Tamasin",
            "Tayabat-al-Janubiyah",
            "Tughghurt",
            "Warqla",
            "al-Hajirah"
          ],
          "StateName": "Warqla"
        },
        {
          "Cities": [],
          "StateName": "Wilaya d Alger"
        },
        {
          "Cities": [],
          "StateName": "Wilaya de Bejaia"
        },
        {
          "Cities": [],
          "StateName": "Wilaya de Constantine"
        },
        {
          "Cities": [
            "Aflu",
            "Hassi al-Raml",
            "al-Aghwat"
          ],
          "StateName": "al-Aghwat"
        },
        {
          "Cities": [
            "Brizyanah",
            "al-Abyad Sidi Shaykh",
            "al-Bayadh"
          ],
          "StateName": "al-Bayadh"
        },
        {
          "Cities": [
            "Bab Azwar",
            "Baraki",
            "Bir Murad Rais",
            "Birkhadam",
            "Burj-al-Kiffan",
            "Dar-al-Bayda",
            "al-Jaza'ir"
          ],
          "StateName": "al-Jaza'ir"
        },
        {
          "Cities": [
            "Bayadha",
            "Dabilah",
            "Hassan 'Abd-al-Karim",
            "Hassi Halifa",
            "Jama'a",
            "Maqran",
            "Qamar",
            "Raqiba",
            "Rubbah",
            "Sidi Amran",
            "al-Mighair",
            "al-Wad"
          ],
          "StateName": "al-Wad"
        },
        {
          "Cities": [
            "'Ayn Maran",
            "Abu al-Hassan",
            "Bani Hawa",
            "Bu Qadir",
            "Sidi Ukaskah",
            "Tanas",
            "Wadi Sali",
            "Wadi al-Fiddah",
            "ash-Shalif",
            "ash-Shattiyah"
          ],
          "StateName": "ash-Shalif"
        },
        {
          "Cities": [
            "Ban Mahdi",
            "Bani Amar",
            "Basbas",
            "Dariyan",
            "Saba'ita Muk",
            "al-Qal'ah",
            "at-Tarif"
          ],
          "StateName": "at-Tarif"
        }
      ],
      "CountryName": "Algeria"
    },
    {
      "States": [
        {
          "Cities": [
            "Afono",
            "Alao",
            "Alofau",
            "Amouli",
            "Aoa",
            "Atu'u",
            "Aua",
            "Aunu'u",
            "Faga'alu",
            "Faga'itua",
            "Fagasa",
            "Fagatogo",
            "Lauli'i",
            "Leloaloa",
            "Nu'uuli",
            "Pago Pago",
            "Tula",
            "Utulei",
            "Vatia"
          ],
          "StateName": "Eastern"
        },
        {
          "Cities": [
            "Ofu"
          ],
          "StateName": "Manu'a"
        },
        {
          "Cities": [],
          "StateName": "Swains Island"
        },
        {
          "Cities": [
            "Aoloau",
            "Faleniu",
            "Futiga",
            "Ili'ili",
            "Leone",
            "Malaeimi",
            "Malaeloa-Aitulagi",
            "Malaeloa-Ituau",
            "Mapusagafou",
            "Mesepa",
            "Pava'ia'i",
            "Tafuna",
            "Taputimu",
            "Vailoatai",
            "Vaitogi"
          ],
          "StateName": "Western"
        }
      ],
      "CountryName": "American Samoa"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Andorra la Vella"
        },
        {
          "Cities": [
            "Canillo"
          ],
          "StateName": "Canillo"
        },
        {
          "Cities": [
            "Encamp"
          ],
          "StateName": "Encamp"
        },
        {
          "Cities": [],
          "StateName": "La Massana"
        },
        {
          "Cities": [],
          "StateName": "Les Escaldes"
        },
        {
          "Cities": [
            "Ordino"
          ],
          "StateName": "Ordino"
        },
        {
          "Cities": [],
          "StateName": "Sant Julia de Loria"
        }
      ],
      "CountryName": "Andorra"
    },
    {
      "States": [
        {
          "Cities": [
            "Caxito"
          ],
          "StateName": "Bengo"
        },
        {
          "Cities": [
            "Benguela",
            "Lobito"
          ],
          "StateName": "Benguela"
        },
        {
          "Cities": [
            "Camacupa",
            "Catabola",
            "Catumbela",
            "Chissamba",
            "Kuito"
          ],
          "StateName": "Bie"
        },
        {
          "Cities": [
            "Cabinda"
          ],
          "StateName": "Cabinda"
        },
        {
          "Cities": [
            "Ondjiva"
          ],
          "StateName": "Cunene"
        },
        {
          "Cities": [
            "Caala",
            "Catchiungo",
            "Huambo",
            "Longonjo"
          ],
          "StateName": "Huambo"
        },
        {
          "Cities": [
            "Caconda",
            "Caluquembe",
            "Lubango"
          ],
          "StateName": "Huila"
        },
        {
          "Cities": [
            "Menongue"
          ],
          "StateName": "Kuando-Kubango"
        },
        {
          "Cities": [],
          "StateName": "Kwanza Norte"
        },
        {
          "Cities": [],
          "StateName": "Kwanza Sul"
        },
        {
          "Cities": [
            "Luanda"
          ],
          "StateName": "Luanda"
        },
        {
          "Cities": [],
          "StateName": "Lunda Norte"
        },
        {
          "Cities": [],
          "StateName": "Lunda Sul"
        },
        {
          "Cities": [
            "Malanje"
          ],
          "StateName": "Malanje"
        },
        {
          "Cities": [
            "Cazaje",
            "Leua",
            "Luau",
            "Luena",
            "Lumeje"
          ],
          "StateName": "Moxico"
        },
        {
          "Cities": [
            "Namibe"
          ],
          "StateName": "Namibe"
        },
        {
          "Cities": [
            "Camabatela",
            "Uige"
          ],
          "StateName": "Uige"
        },
        {
          "Cities": [
            "M'banza-Kongo",
            "N'zeto",
            "Soyo"
          ],
          "StateName": "Zaire"
        }
      ],
      "CountryName": "Angola"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Other Provinces"
        }
      ],
      "CountryName": "Anguilla"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Sector claimed by Argentina/Ch"
        },
        {
          "Cities": [],
          "StateName": "Sector claimed by Argentina/UK"
        },
        {
          "Cities": [],
          "StateName": "Sector claimed by Australia"
        },
        {
          "Cities": [],
          "StateName": "Sector claimed by France"
        },
        {
          "Cities": [],
          "StateName": "Sector claimed by New Zealand"
        },
        {
          "Cities": [],
          "StateName": "Sector claimed by Norway"
        },
        {
          "Cities": [],
          "StateName": "Unclaimed Sector"
        }
      ],
      "CountryName": "Antarctica"
    },
    {
      "States": [
        {
          "Cities": [
            "Codrington"
          ],
          "StateName": "Barbuda"
        },
        {
          "Cities": [],
          "StateName": "Saint George"
        },
        {
          "Cities": [],
          "StateName": "Saint John"
        },
        {
          "Cities": [],
          "StateName": "Saint Mary"
        },
        {
          "Cities": [],
          "StateName": "Saint Paul"
        },
        {
          "Cities": [],
          "StateName": "Saint Peter"
        },
        {
          "Cities": [],
          "StateName": "Saint Philip"
        }
      ],
      "CountryName": "Antigua And Barbuda"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Buenos Aires"
        },
        {
          "Cities": [
            "Catamarca"
          ],
          "StateName": "Catamarca"
        },
        {
          "Cities": [
            "Presidencia Roque Saenz Pena",
            "Resistencia"
          ],
          "StateName": "Chaco"
        },
        {
          "Cities": [
            "Comodoro Rivadavia",
            "Puerto Madryn",
            "Rawson",
            "Trelew"
          ],
          "StateName": "Chubut"
        },
        {
          "Cities": [
            "Alta Gracia",
            "Bell Ville",
            "Cordoba",
            "Rio Cuarto",
            "Rio Tercero",
            "San Francisco",
            "Villa Carlos Paz",
            "Villa Maria"
          ],
          "StateName": "Cordoba"
        },
        {
          "Cities": [
            "Corrientes",
            "Goya",
            "Mercedes",
            "San Lorenzo"
          ],
          "StateName": "Corrientes"
        },
        {
          "Cities": [],
          "StateName": "Distrito Federal"
        },
        {
          "Cities": [],
          "StateName": "Entre Rios"
        },
        {
          "Cities": [
            "Formosa"
          ],
          "StateName": "Formosa"
        },
        {
          "Cities": [
            "Jujuy",
            "Libertador General San Martin",
            "San Pedro"
          ],
          "StateName": "Jujuy"
        },
        {
          "Cities": [],
          "StateName": "La Pampa"
        },
        {
          "Cities": [],
          "StateName": "La Rioja"
        },
        {
          "Cities": [
            "Godoy Cruz",
            "Lujan",
            "Mendoza",
            "Rivadavia",
            "San Martin",
            "San Rafael"
          ],
          "StateName": "Mendoza"
        },
        {
          "Cities": [
            "Eldorado",
            "Obera",
            "Posadas"
          ],
          "StateName": "Misiones"
        },
        {
          "Cities": [
            "Cutral Co",
            "Neuquen"
          ],
          "StateName": "Neuquen"
        },
        {
          "Cities": [],
          "StateName": "Rio Negro"
        },
        {
          "Cities": [
            "Salta",
            "San Ramon de la Nueva Oran",
            "Tartagal"
          ],
          "StateName": "Salta"
        },
        {
          "Cities": [],
          "StateName": "San Juan"
        },
        {
          "Cities": [],
          "StateName": "San Luis"
        },
        {
          "Cities": [],
          "StateName": "Santa Cruz"
        },
        {
          "Cities": [],
          "StateName": "Santa Fe"
        },
        {
          "Cities": [],
          "StateName": "Santiago del Estero"
        },
        {
          "Cities": [],
          "StateName": "Tierra del Fuego"
        },
        {
          "Cities": [
            "Tucuman"
          ],
          "StateName": "Tucuman"
        }
      ],
      "CountryName": "Argentina"
    },
    {
      "States": [
        {
          "Cities": [
            "Ashtarak",
            "Talin"
          ],
          "StateName": "Aragatsotn"
        },
        {
          "Cities": [
            "Ararat",
            "Artashat"
          ],
          "StateName": "Ararat"
        },
        {
          "Cities": [
            "Armavir",
            "Vagharshapat"
          ],
          "StateName": "Armavir"
        },
        {
          "Cities": [
            "Gavar",
            "Martuni",
            "Sevan",
            "Vardenis"
          ],
          "StateName": "Gegharkunik"
        },
        {
          "Cities": [
            "Abovyan",
            "Arzni",
            "Charentsavan",
            "Hrazdan"
          ],
          "StateName": "Kotaik"
        },
        {
          "Cities": [
            "Akhtala",
            "Alaverdi",
            "Spitak",
            "Stepanavan",
            "Tashir",
            "Vanadzor"
          ],
          "StateName": "Lori"
        },
        {
          "Cities": [
            "Artik",
            "Gyumri"
          ],
          "StateName": "Shirak"
        },
        {
          "Cities": [
            "Stepanakert"
          ],
          "StateName": "Stepanakert"
        },
        {
          "Cities": [
            "Angeghakot",
            "Goris",
            "Kajaran",
            "Kapan",
            "Meghri",
            "Sisian"
          ],
          "StateName": "Syunik"
        },
        {
          "Cities": [
            "Dilijan",
            "Ijevan"
          ],
          "StateName": "Tavush"
        },
        {
          "Cities": [],
          "StateName": "Vayots Dzor"
        },
        {
          "Cities": [
            "Yerevan"
          ],
          "StateName": "Yerevan"
        }
      ],
      "CountryName": "Armenia"
    },
    {
      "States": [
        {
          "Cities": [
            "Aruba"
          ],
          "StateName": "Aruba"
        }
      ],
      "CountryName": "Aruba"
    },
    {
      "States": [
        {
          "Cities": [
            "Glenfield",
            "Henderson",
            "Milford"
          ],
          "StateName": "Auckland"
        },
        {
          "Cities": [],
          "StateName": "Australian Capital Territory"
        },
        {
          "Cities": [
            "Balgowlah"
          ],
          "StateName": "Balgowlah"
        },
        {
          "Cities": [
            "Balmain"
          ],
          "StateName": "Balmain"
        },
        {
          "Cities": [
            "Bankstown"
          ],
          "StateName": "Bankstown"
        },
        {
          "Cities": [],
          "StateName": "Baulkham Hills"
        },
        {
          "Cities": [],
          "StateName": "Bonnet Bay"
        },
        {
          "Cities": [
            "Camberwell"
          ],
          "StateName": "Camberwell"
        },
        {
          "Cities": [],
          "StateName": "Carole Park"
        },
        {
          "Cities": [],
          "StateName": "Castle Hill"
        },
        {
          "Cities": [
            "Caulfield"
          ],
          "StateName": "Caulfield"
        },
        {
          "Cities": [
            "Chatswood"
          ],
          "StateName": "Chatswood"
        },
        {
          "Cities": [
            "Cheltenham"
          ],
          "StateName": "Cheltenham"
        },
        {
          "Cities": [
            "Cherrybrook"
          ],
          "StateName": "Cherrybrook"
        },
        {
          "Cities": [
            "Clayton"
          ],
          "StateName": "Clayton"
        },
        {
          "Cities": [
            "Collingwood"
          ],
          "StateName": "Collingwood"
        },
        {
          "Cities": [],
          "StateName": "Frenchs Forest"
        },
        {
          "Cities": [
            "Hawthorn"
          ],
          "StateName": "Hawthorn"
        },
        {
          "Cities": [
            "Jannnali"
          ],
          "StateName": "Jannnali"
        },
        {
          "Cities": [
            "Knoxfield"
          ],
          "StateName": "Knoxfield"
        },
        {
          "Cities": [
            "Melbourne",
            "South Oakleigh"
          ],
          "StateName": "Melbourne"
        },
        {
          "Cities": [],
          "StateName": "New South Wales"
        },
        {
          "Cities": [],
          "StateName": "Northern Territory"
        },
        {
          "Cities": [
            "Cottesole",
            "Darch",
            "Mullaloo",
            "Noranda",
            "Osborne Park",
            "Willetton"
          ],
          "StateName": "Perth"
        },
        {
          "Cities": [
            "Acacia Ridge",
            "Aitkenvale",
            "Alderley",
            "Alexander Hills",
            "Archerfield",
            "Ashmore",
            "Atherton",
            "Ayr",
            "Beachmere",
            "Beenleigh",
            "Beerwah",
            "Bell",
            "Belmont",
            "Biloela",
            "Birkdale",
            "Blackwater",
            "Bongaree",
            "Bonogin",
            "Boonah",
            "Bowen",
            "Brendale",
            "Brisbane",
            "Buddina",
            "Buderim",
            "Bundaberg",
            "Bundall",
            "Burleigh Heads",
            "Burpengary",
            "Cabaland",
            "Caboolture",
            "Cairns",
            "Cairns Northern Beaches",
            "Caloundra",
            "Capalaba",
            "Charters Towers",
            "Childers",
            "Cleveland",
            "Coolum Beach",
            "Coopers Plain",
            "Dalby",
            "Deception Bay",
            "Eagle Farm",
            "Eagleby",
            "Emerald",
            "Emundi",
            "Forest Lake",
            "Gatton",
            "Gladstone",
            "Gold Coast",
            "Gowrie Junction",
            "Gympie",
            "Helensvale",
            "Hervey Bay",
            "Ingham",
            "Innisfail",
            "Ipswich",
            "Jindalee",
            "Julatten",
            "Kawana Waters",
            "Kingaroy",
            "Lawnton",
            "Loganholme",
            "Mackay",
            "Mareeba",
            "Maroochydore",
            "Maroochydore-Mooloolaba",
            "Maryborough",
            "Miami",
            "Milton",
            "Molendinar",
            "Moranbah",
            "Morayfield",
            "Mossman",
            "Mount Glorious",
            "Mount Isa",
            "Murarrie",
            "Nairand Kay",
            "Nambour",
            "Nerang",
            "Nobby Beach",
            "Noosa Heads",
            "Noosaville",
            "Paddington",
            "Port Douglas",
            "Robina",
            "Rockhampton",
            "Rocklea",
            "Roma",
            "Rubyvale",
            "Sanctuary Cove",
            "Slade Point",
            "Southport",
            "Suburb",
            "Sunshine Coast",
            "Tewantin-Noosa",
            "The Summit",
            "Toowong",
            "Toowoomba",
            "Townsville",
            "Victoria Point",
            "Warwick",
            "Willawong",
            "Yatala",
            "Yeppoon",
            "Zillmere"
          ],
          "StateName": "Queensland"
        },
        {
          "Cities": [],
          "StateName": "South Australia"
        },
        {
          "Cities": [
            "Bridgewater-Gagebrook",
            "Burnie",
            "Burnie-Devonport",
            "Glenorchy",
            "Hobart",
            "Kingston-Blackmans Bay",
            "Launceston",
            "New Norfolk",
            "Rowella",
            "Sheffield",
            "Ulverstone"
          ],
          "StateName": "Tasmania"
        },
        {
          "Cities": [
            "Templestowe"
          ],
          "StateName": "Templestowe"
        },
        {
          "Cities": [
            "Albion",
            "Ararat",
            "Avenel",
            "Avondale Heights",
            "Baccus Marsh",
            "Bairnsdale",
            "Ballarat",
            "Balwyn",
            "Batman",
            "Bayswater",
            "Belmont",
            "Benalla",
            "Bendigo",
            "Bentleigh East",
            "Berwick",
            "Blackburn",
            "Blue Mountains",
            "Boonah",
            "Boronia",
            "Box Hill",
            "Brighton East",
            "Bundoora",
            "Campbellfield",
            "Carlton South",
            "Caroline Springs",
            "Castle Hill",
            "Castlemaine",
            "Caulfield",
            "Cheltenham",
            "Chester",
            "Clayton South",
            "Clifton Springs",
            "Coburg",
            "Colac",
            "Collingwood",
            "Craigieburn",
            "Cranbourne",
            "Crib Point",
            "Dandenong",
            "Devon Meadows",
            "Diamond Creek",
            "Doncaster East",
            "Doncester",
            "Donvale",
            "Echuca-Moama",
            "Elsternwick",
            "Eltham",
            "Fairfield",
            "Fitzroy",
            "Frnakston",
            "Geelong",
            "Glen Waverley",
            "Glenorchy",
            "Hallam",
            "Hamilton",
            "Hastings",
            "Healesville",
            "Horsham",
            "Hughesdale",
            "Ivanhoe",
            "Keilor",
            "Kensington",
            "Kerang",
            "Kilmore",
            "Kilsyth",
            "Kyabram",
            "Lakes Entrance",
            "Lalor",
            "Lara",
            "Laverton North",
            "Leopold",
            "Lower Templestowe",
            "Maidstone",
            "Malvern",
            "Maryborough",
            "Melbourne",
            "Melton",
            "Middle Park",
            "Mildura",
            "Mitcham",
            "Moe-Yallourn",
            "Moorabbin",
            "Mordialloc",
            "Morwell",
            "Mulgrave",
            "Murrumbeena",
            "Neerim South",
            "Noble Park North",
            "Notting Hill",
            "Oak Park",
            "Ocean Grove-Barwon Heads",
            "Olinda",
            "Pakenham",
            "Park Orchards",
            "Port Melbourne",
            "Portland",
            "Prahran",
            "Preston",
            "Pritbourne",
            "Queenscliff",
            "Reservoir",
            "Ringwood",
            "Rowville",
            "Sale",
            "Sandringham",
            "Scoresby",
            "Sebastopol",
            "Seymour",
            "Shepparton-Mooroopna",
            "Somerville",
            "South Yarra",
            "Southbank",
            "St. Albans",
            "St. Kilda East",
            "Stawell",
            "Sunbury",
            "Sunshine West",
            "Swan Hill",
            "Tatura",
            "Thomastown",
            "Thornbury",
            "Tongala",
            "Torquay",
            "Traralgon",
            "Tullamarine",
            "Vermont",
            "Wangaratta",
            "Wantirna",
            "Warragul",
            "Warrnambool",
            "Welshpool",
            "Wendouree",
            "Wonthaggi",
            "Woodend",
            "Yarrawonga-Mulwala",
            "Yea"
          ],
          "StateName": "Victoria"
        },
        {
          "Cities": [],
          "StateName": "Werribee south"
        },
        {
          "Cities": [],
          "StateName": "Western Australia"
        },
        {
          "Cities": [
            "Wheeler"
          ],
          "StateName": "Wheeler"
        }
      ],
      "CountryName": "Australia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Bundesland Salzburg"
        },
        {
          "Cities": [],
          "StateName": "Bundesland Steiermark"
        },
        {
          "Cities": [],
          "StateName": "Bundesland Tirol"
        },
        {
          "Cities": [
            "Eisenstadt",
            "GroBpetersdorf",
            "Jennersdorf",
            "Kemeten",
            "Mattersburg",
            "Neudorfl",
            "Neusiedl am See",
            "Oberwart",
            "Pinkafeld",
            "Rust"
          ],
          "StateName": "Burgenland"
        },
        {
          "Cities": [
            "Maria Rain",
            "Poggersdorf"
          ],
          "StateName": "Carinthia"
        },
        {
          "Cities": [
            "Althofen",
            "Arnoldstein",
            "Bad Sankt Leonhard",
            "Bleiburg",
            "Ebenthal",
            "Eberndorf",
            "Feldkirchen",
            "Ferlach",
            "Finkenstein",
            "Friesach",
            "Hermagor",
            "Klagenfurt",
            "Klagenfurt ",
            "Lohnsburg",
            "Moosburg",
            "Paternion",
            "Radentheim",
            "Sankt Andra",
            "Sankt Jakob",
            "Sankt Veit",
            "Seeboden",
            "Spittal",
            "Velden am Worthersee",
            "Villach",
            "Volkermarkt",
            "Wernberg",
            "Wolfsberg"
          ],
          "StateName": "Karnten"
        },
        {
          "Cities": [],
          "StateName": "Liezen"
        },
        {
          "Cities": [],
          "StateName": "Lower Austria"
        },
        {
          "Cities": [
            "Amstetten",
            "Bad Voslau",
            "Baden",
            "Berndorf",
            "Boheimkirchen",
            "Bruck an der Leitha",
            "Brunn",
            "Deutsch-Wagram",
            "Ebreichsdorf",
            "Eggendorf",
            "Fischamend",
            "Gablitz",
            "Ganserndorf",
            "Gerasdorf",
            "Gloggnitz",
            "Gmund",
            "Greifenstein",
            "GroB-Enzersdorf",
            "GroB-Gerungs",
            "Guntramsdorf",
            "Haag",
            "Hainburg",
            "Heidenreichstein",
            "Herzogenburg",
            "Himberg",
            "Hollabrunn",
            "Horn",
            "Klosterneuburg",
            "Korneuburg",
            "Kottingbrunn",
            "Krems",
            "Laa",
            "Langenlois",
            "Langenzersdorf",
            "Leobendorf",
            "Leopoldsdorf",
            "Lilienfeld",
            "Loipersdorf",
            "Maria Enzersdorf",
            "Melk",
            "Mistelbach",
            "Modling",
            "Neulengbach",
            "Neunkirchen",
            "Niederleis",
            "Ober-Grabendorf",
            "Perchtoldsdorf",
            "Pernitz",
            "Pottendorf",
            "Poysdorf",
            "Pressbaum",
            "Purgstall",
            "Purkersdorf",
            "Reichenau",
            "Retz",
            "Sankt Andra-Wordern",
            "Sankt Peter in der Au",
            "Sankt Polten",
            "Sankt Valentin",
            "Scheibbs",
            "Schrems",
            "Schwechat",
            "Seitenstetten",
            "Sollenau",
            "Stockerau",
            "Strasshof",
            "Ternitz",
            "Traiskirchen",
            "Traismauer",
            "Tulln",
            "Vosendorf",
            "Waidhofen",
            "Wiener Neudorf",
            "Wiener Neustadt",
            "Wieselburg",
            "Wilhelmsburg",
            "Wolkersdorf",
            "Ybbs",
            "Ybbsitz",
            "Zistersdorf",
            "Zwettl"
          ],
          "StateName": "Niederosterreich"
        },
        {
          "Cities": [
            "Alkoven",
            "Altheim",
            "Altmunster",
            "Andorf",
            "Ansfelden",
            "Asten",
            "Attnang-Puchheim",
            "Aurolzmunster",
            "Bad Goisern",
            "Bad Hall",
            "Bad Ischl",
            "Braunau",
            "Breitenfurt",
            "Ebensee",
            "Eferding",
            "Engerwitzdorf",
            "Enns",
            "Feldkirchen an der Donau",
            "Frankenburg",
            "Freistadt",
            "Gallneukirchen",
            "Garsten",
            "Gmunden",
            "Gramastetten",
            "Grieskirchen",
            "Gunskirchen",
            "Horsching",
            "Kirchdorf an der Krems",
            "Kremsmunster",
            "Krenglbach",
            "Laakirchen",
            "Lenzing",
            "Leonding",
            "Linz",
            "Loibichl",
            "Marchtrenk",
            "Mattighofen",
            "Mauthausen",
            "Micheldorf",
            "Neuhofen an der Krems",
            "Ohlsdorf",
            "Ottensheim",
            "Pasching",
            "Perg",
            "Pettenbach",
            "Pram",
            "Pregarten",
            "Puchenau",
            "Regau",
            "Ried",
            "Rohrbach in Oberosterreich",
            "Rutzenmoos",
            "Sankt Florian",
            "Sankt Georgen",
            "Sankt Marien",
            "Scharding",
            "Scharnstein",
            "Schwertberg",
            "Seewalchen",
            "Sierning",
            "Stadl-Paura",
            "Steyr",
            "Steyregg",
            "Steyrermuhl",
            "Thalheim",
            "Timelkam",
            "Traun",
            "Vocklabruck",
            "Vocklamarkt",
            "Vorchdorf",
            "Wels",
            "Wilhering"
          ],
          "StateName": "Oberosterreich"
        },
        {
          "Cities": [
            "Abtenau",
            "Anif",
            "Bad Gastein",
            "Bad Hofgastein",
            "Bergheim",
            "Bischofshofen",
            "Bruck an der GroBglocknerstraB",
            "Burmoos",
            "Elsbethen",
            "Eugendorf",
            "Forstau",
            "Grodig",
            "Hallein",
            "Hallwang",
            "Henndorf",
            "Kuchl",
            "Mayrhofen",
            "Mittersill",
            "Neumarkt",
            "Oberndorf",
            "Obertrum am See",
            "Piesendorf",
            "Puch",
            "Radstadt",
            "Saalfelden",
            "Salzburg",
            "Sankt Johann im Pongau",
            "Seekirchen",
            "Sieghartskirchen",
            "StraBwalchen",
            "Strobl",
            "Tamsweg",
            "Thalgau",
            "Wals-Siezenheim",
            "Wolfgangsee",
            "Zell am See"
          ],
          "StateName": "Salzburg"
        },
        {
          "Cities": [
            "Albersdorf"
          ],
          "StateName": "Schleswig-Holstein"
        },
        {
          "Cities": [
            "Bad Aussee",
            "Barnbach",
            "Bruck an der Mur",
            "Deutschlandsberg",
            "Eisenerz",
            "Feldbach",
            "Feldkirchen bei Graz",
            "Fohnsdorf",
            "Frohnleiten",
            "Furstenfeld",
            "Gleisdorf",
            "Gratkorn",
            "Graz",
            "Hartberg",
            "Judenburg",
            "Judendorf-StraBengel",
            "Kapfenberg",
            "Karlsdorf",
            "Kindberg",
            "Knittelfeld",
            "Koflach",
            "Krieglach",
            "Lannach",
            "Leibnitz",
            "Leoben",
            "Liezen",
            "Murzzuschlag",
            "Rottenmann",
            "Schladming",
            "Seiersberg",
            "Spielberg",
            "Trofaiach",
            "Voitsberg",
            "Wagna",
            "Weiz",
            "Zeltweg"
          ],
          "StateName": "Steiermark"
        },
        {
          "Cities": [
            "Deutschfeistritz",
            "Liezen",
            "Sankt Bartholoma"
          ],
          "StateName": "Styria"
        },
        {
          "Cities": [
            "Absam",
            "Axams",
            "Ebbs",
            "Fugen",
            "Hall",
            "Haselgehr",
            "Hopfgarten",
            "Imst",
            "Innsbruck",
            "Jenbach",
            "Kirchberg",
            "Kirchbichl",
            "Kitzbuhel",
            "Kramsach",
            "Kufstein",
            "Landeck",
            "Lienz",
            "Matrei",
            "Neustift im Stubaital",
            "Reutte",
            "Rum",
            "Sankt Johann in Tirol",
            "Scheffau",
            "Schwaz",
            "St. Johann Am Walde",
            "Telfs",
            "Vols",
            "Vomp",
            "Wattens",
            "Worgl",
            "Zirl"
          ],
          "StateName": "Tirol"
        },
        {
          "Cities": [],
          "StateName": "Upper Austria"
        },
        {
          "Cities": [
            "Altach",
            "Bludenz",
            "Bregenz",
            "Chassieu",
            "Dietmannsried",
            "Dornbirn",
            "Feldkirch",
            "Frastanz",
            "Gotzis",
            "Hard",
            "Hochst",
            "Hohenems",
            "Horbranz",
            "Hufingen",
            "Lauterach",
            "Lochau",
            "Lustenau",
            "Mittelberg",
            "Nenzing",
            "Nuziders",
            "Rankweil",
            "Schruns",
            "Thuringen",
            "Wolfurt"
          ],
          "StateName": "Vorarlberg"
        },
        {
          "Cities": [
            "Vienna",
            "Wien"
          ],
          "StateName": "Wien"
        }
      ],
      "CountryName": "Austria"
    },
    {
      "States": [
        {
          "Cities": [
            "Alat",
            "Artyom",
            "Baki",
            "Bakixanov",
            "Balaxani",
            "Bilacari",
            "Bilqax",
            "Bina",
            "Buzovna",
            "Haci Zeynalabdin",
            "Hovsan",
            "Lokbatan",
            "Mastaga",
            "Puta",
            "Qarasuxur",
            "Qobustan",
            "Rasulzada",
            "Sabuncu",
            "Sanqacal",
            "Sumqayit",
            "Suraxani",
            "Xirdalan",
            "Zirya"
          ],
          "StateName": "Abseron"
        },
        {
          "Cities": [],
          "StateName": "Baki Sahari"
        },
        {
          "Cities": [
            "Daskasan",
            "Ganca",
            "Xanlar"
          ],
          "StateName": "Ganca"
        },
        {
          "Cities": [
            "Ganja"
          ],
          "StateName": "Ganja"
        },
        {
          "Cities": [
            "Cabrayil",
            "Kalbacar",
            "Lacin"
          ],
          "StateName": "Kalbacar"
        },
        {
          "Cities": [
            "Astara",
            "Goytapa",
            "Lankaran",
            "Masalli",
            "Neftcala"
          ],
          "StateName": "Lankaran"
        },
        {
          "Cities": [
            "Agcabadi",
            "Agdam",
            "Barda",
            "Mingacevir",
            "Tartar",
            "Yevlax"
          ],
          "StateName": "Mil-Qarabax"
        },
        {
          "Cities": [
            "Ali Bayramli",
            "Bilasuvar",
            "Calilabad",
            "Qarasu",
            "Qazimammad",
            "Saatli",
            "Sabirabad",
            "Salyan"
          ],
          "StateName": "Mugan-Salyan"
        },
        {
          "Cities": [
            "Susa",
            "Xankandi",
            "Xocavand"
          ],
          "StateName": "Nagorni-Qarabax"
        },
        {
          "Cities": [
            "Culfa",
            "Naxcivan",
            "Ordubad",
            "Sadarak",
            "Sarur"
          ],
          "StateName": "Naxcivan"
        },
        {
          "Cities": [
            "Beylaqan",
            "Fuzuli",
            "Imisli"
          ],
          "StateName": "Priaraks"
        },
        {
          "Cities": [
            "Agstafa",
            "Gadabay",
            "Kovlar",
            "Qaracamirli",
            "Qazax",
            "Samkir",
            "Tovuz"
          ],
          "StateName": "Qazax"
        },
        {
          "Cities": [
            "Amircan",
            "Balakan",
            "Katex",
            "Oguz",
            "Qabala",
            "Qax",
            "Saki",
            "Zaqatala"
          ],
          "StateName": "Saki"
        },
        {
          "Cities": [
            "Agdas",
            "Agsu",
            "Goycay",
            "Ismayilli",
            "Kurdamir",
            "Samaxi",
            "Ucar",
            "Zardab"
          ],
          "StateName": "Sirvan"
        },
        {
          "Cities": [
            "Davaci",
            "Quba",
            "Qusar",
            "Siyazan",
            "Xacmaz",
            "Xudat"
          ],
          "StateName": "Xacmaz"
        }
      ],
      "CountryName": "Azerbaijan"
    },
    {
      "States": [
        {
          "Cities": [
            "Coopers Town",
            "Marsh Harbour"
          ],
          "StateName": "Abaco"
        },
        {
          "Cities": [],
          "StateName": "Acklins Island"
        },
        {
          "Cities": [
            "Andros Town",
            "Nicholls Town"
          ],
          "StateName": "Andros"
        },
        {
          "Cities": [],
          "StateName": "Berry Islands"
        },
        {
          "Cities": [
            "Alice Town"
          ],
          "StateName": "Biminis"
        },
        {
          "Cities": [],
          "StateName": "Cat Island"
        },
        {
          "Cities": [],
          "StateName": "Crooked Island"
        },
        {
          "Cities": [
            "Freetown",
            "Rock Sound"
          ],
          "StateName": "Eleuthera"
        },
        {
          "Cities": [],
          "StateName": "Exuma and Cays"
        },
        {
          "Cities": [],
          "StateName": "Grand Bahama"
        },
        {
          "Cities": [],
          "StateName": "Inagua Islands"
        },
        {
          "Cities": [],
          "StateName": "Long Island"
        },
        {
          "Cities": [
            "Pirates Well"
          ],
          "StateName": "Mayaguana"
        },
        {
          "Cities": [],
          "StateName": "New Providence"
        },
        {
          "Cities": [],
          "StateName": "Ragged Island"
        },
        {
          "Cities": [],
          "StateName": "Rum Cay"
        },
        {
          "Cities": [],
          "StateName": "San Salvador"
        }
      ],
      "CountryName": "Bahamas The"
    },
    {
      "States": [
        {
          "Cities": [
            "'Isa"
          ],
          "StateName": "'Isa"
        },
        {
          "Cities": [
            "Badiyah"
          ],
          "StateName": "Badiyah"
        },
        {
          "Cities": [
            "Hidd"
          ],
          "StateName": "Hidd"
        },
        {
          "Cities": [],
          "StateName": "Jidd Hafs"
        },
        {
          "Cities": [
            "Mahama"
          ],
          "StateName": "Mahama"
        },
        {
          "Cities": [
            "Manama"
          ],
          "StateName": "Manama"
        },
        {
          "Cities": [
            "Sitrah"
          ],
          "StateName": "Sitrah"
        },
        {
          "Cities": [
            "al-Manamah"
          ],
          "StateName": "al-Manamah"
        },
        {
          "Cities": [
            "al-Muharraq"
          ],
          "StateName": "al-Muharraq"
        },
        {
          "Cities": [
            "ar-Rifa'a"
          ],
          "StateName": "ar-Rifa'a"
        }
      ],
      "CountryName": "Bahrain"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Bagar Hat"
        },
        {
          "Cities": [
            "Bandarban"
          ],
          "StateName": "Bandarban"
        },
        {
          "Cities": [
            "Barguna"
          ],
          "StateName": "Barguna"
        },
        {
          "Cities": [
            "Barisal",
            "Gaurnadi",
            "Mehendiganj",
            "Nalchiti"
          ],
          "StateName": "Barisal"
        },
        {
          "Cities": [
            "Bhola",
            "Burhanuddin",
            "Char Fasson",
            "Lalmohan"
          ],
          "StateName": "Bhola"
        },
        {
          "Cities": [
            "Adamighi",
            "Bogora",
            "Sherpur"
          ],
          "StateName": "Bogora"
        },
        {
          "Cities": [],
          "StateName": "Brahman Bariya"
        },
        {
          "Cities": [
            "Chandpur",
            "Hajiganj"
          ],
          "StateName": "Chandpur"
        },
        {
          "Cities": [
            "Boalkhali",
            "Chattagam",
            "Fatikchhari",
            "Lohagara",
            "Patiya",
            "Rangunia",
            "Raozan",
            "Sandip",
            "Satkaniya"
          ],
          "StateName": "Chattagam"
        },
        {
          "Cities": [],
          "StateName": "Chittagong Division"
        },
        {
          "Cities": [
            "Alamdanga",
            "Chuadanga",
            "Damurhuda"
          ],
          "StateName": "Chuadanga"
        },
        {
          "Cities": [
            "Dhaka",
            "Dhamrai",
            "Dohar"
          ],
          "StateName": "Dhaka"
        },
        {
          "Cities": [
            "Bochanganj",
            "Dinajpur",
            "Fulbari",
            "Parbatipur"
          ],
          "StateName": "Dinajpur"
        },
        {
          "Cities": [
            "Bhanga",
            "Char Bhadrasan",
            "Faridpur"
          ],
          "StateName": "Faridpur"
        },
        {
          "Cities": [
            "Chhagalnaiya",
            "Feni"
          ],
          "StateName": "Feni"
        },
        {
          "Cities": [
            "Gaybanda"
          ],
          "StateName": "Gaybanda"
        },
        {
          "Cities": [
            "Gazipur",
            "Tungi"
          ],
          "StateName": "Gazipur"
        },
        {
          "Cities": [
            "Gopalganj",
            "Tungi Para"
          ],
          "StateName": "Gopalganj"
        },
        {
          "Cities": [
            "Baniachang",
            "Habiganj"
          ],
          "StateName": "Habiganj"
        },
        {
          "Cities": [],
          "StateName": "Jaipur Hat"
        },
        {
          "Cities": [
            "Jamalpur",
            "Sarishabari"
          ],
          "StateName": "Jamalpur"
        },
        {
          "Cities": [
            "Abhaynagar",
            "Jessor",
            "Jhikargachha",
            "Keshabpur"
          ],
          "StateName": "Jessor"
        },
        {
          "Cities": [
            "Jhalakati"
          ],
          "StateName": "Jhalakati"
        },
        {
          "Cities": [
            "Jhanaydah",
            "Kaliganj",
            "Kotchandpur",
            "Shailkupa"
          ],
          "StateName": "Jhanaydah"
        },
        {
          "Cities": [
            "Khagrachari",
            "Manikchhari",
            "Ramgarh"
          ],
          "StateName": "Khagrachhari"
        },
        {
          "Cities": [
            "Khulna",
            "Phultala"
          ],
          "StateName": "Khulna"
        },
        {
          "Cities": [
            "Bajitpur",
            "Bhairab Bazar",
            "Itna",
            "Kishorganj"
          ],
          "StateName": "Kishorganj"
        },
        {
          "Cities": [],
          "StateName": "Koks Bazar"
        },
        {
          "Cities": [
            "Komilla",
            "Laksham"
          ],
          "StateName": "Komilla"
        },
        {
          "Cities": [
            "Chilmari",
            "Kurigram",
            "Nageshwari",
            "Ulipur"
          ],
          "StateName": "Kurigram"
        },
        {
          "Cities": [
            "Bheramara",
            "Kushtiya"
          ],
          "StateName": "Kushtiya"
        },
        {
          "Cities": [
            "Lakshmipur",
            "Ramganj",
            "Ramgati",
            "Raypur"
          ],
          "StateName": "Lakshmipur"
        },
        {
          "Cities": [],
          "StateName": "Lalmanir Hat"
        },
        {
          "Cities": [
            "Madaripur"
          ],
          "StateName": "Madaripur"
        },
        {
          "Cities": [
            "Magura"
          ],
          "StateName": "Magura"
        },
        {
          "Cities": [
            "Bhaluka",
            "Fulbaria",
            "Gafargaon",
            "Ishwarganj",
            "Maimansingh",
            "Muktagachha",
            "Trishal"
          ],
          "StateName": "Maimansingh"
        },
        {
          "Cities": [
            "Manikganj"
          ],
          "StateName": "Manikganj"
        },
        {
          "Cities": [],
          "StateName": "Maulvi Bazar"
        },
        {
          "Cities": [
            "Meherpur"
          ],
          "StateName": "Meherpur"
        },
        {
          "Cities": [
            "Munshiganj"
          ],
          "StateName": "Munshiganj"
        },
        {
          "Cities": [
            "Kalia",
            "Naral"
          ],
          "StateName": "Naral"
        },
        {
          "Cities": [
            "Narayanganj",
            "Rupganj"
          ],
          "StateName": "Narayanganj"
        },
        {
          "Cities": [
            "Narsingdi",
            "Roypura"
          ],
          "StateName": "Narsingdi"
        },
        {
          "Cities": [
            "Gurudaspur",
            "Nator"
          ],
          "StateName": "Nator"
        },
        {
          "Cities": [
            "Naugaon"
          ],
          "StateName": "Naugaon"
        },
        {
          "Cities": [
            "Gomastapur",
            "Nawabganj",
            "Shibganj"
          ],
          "StateName": "Nawabganj"
        },
        {
          "Cities": [
            "Netrakona"
          ],
          "StateName": "Netrakona"
        },
        {
          "Cities": [
            "Domar",
            "Nilphamari",
            "Sa'idpur"
          ],
          "StateName": "Nilphamari"
        },
        {
          "Cities": [
            "Begamganj",
            "Noakhali",
            "Senbagh"
          ],
          "StateName": "Noakhali"
        },
        {
          "Cities": [
            "Bera",
            "Bhangura",
            "Ishurdi",
            "Pabna"
          ],
          "StateName": "Pabna"
        },
        {
          "Cities": [
            "Panchagarh"
          ],
          "StateName": "Panchagarh"
        },
        {
          "Cities": [
            "Patuakhali"
          ],
          "StateName": "Patuakhali"
        },
        {
          "Cities": [
            "Bhandaria",
            "Mathbaria",
            "Nesarabad",
            "Pirojpur"
          ],
          "StateName": "Pirojpur"
        },
        {
          "Cities": [
            "Pangsha",
            "Rajbari"
          ],
          "StateName": "Rajbari"
        },
        {
          "Cities": [
            "Rajshahi"
          ],
          "StateName": "Rajshahi"
        },
        {
          "Cities": [
            "Kaptai",
            "Rangamati"
          ],
          "StateName": "Rangamati"
        },
        {
          "Cities": [
            "Badarganj",
            "Kaunia",
            "Rangpur"
          ],
          "StateName": "Rangpur"
        },
        {
          "Cities": [
            "Satkhira"
          ],
          "StateName": "Satkhira"
        },
        {
          "Cities": [
            "Palang"
          ],
          "StateName": "Shariatpur"
        },
        {
          "Cities": [
            "Nalitabari",
            "Sherpur"
          ],
          "StateName": "Sherpur"
        },
        {
          "Cities": [
            "Silhat"
          ],
          "StateName": "Silhat"
        },
        {
          "Cities": [
            "Shahjadpur",
            "Sirajganj"
          ],
          "StateName": "Sirajganj"
        },
        {
          "Cities": [
            "Chhatak",
            "Sunamganj"
          ],
          "StateName": "Sunamganj"
        },
        {
          "Cities": [
            "Gopalpur",
            "Mirzapur",
            "Sakhipur",
            "Tangayal"
          ],
          "StateName": "Tangayal"
        },
        {
          "Cities": [
            "Pirganj",
            "Thakurgaon"
          ],
          "StateName": "Thakurgaon"
        }
      ],
      "CountryName": "Bangladesh"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Christ Church"
        },
        {
          "Cities": [],
          "StateName": "Saint Andrew"
        },
        {
          "Cities": [],
          "StateName": "Saint George"
        },
        {
          "Cities": [],
          "StateName": "Saint James"
        },
        {
          "Cities": [],
          "StateName": "Saint John"
        },
        {
          "Cities": [],
          "StateName": "Saint Joseph"
        },
        {
          "Cities": [],
          "StateName": "Saint Lucy"
        },
        {
          "Cities": [],
          "StateName": "Saint Michael"
        },
        {
          "Cities": [],
          "StateName": "Saint Peter"
        },
        {
          "Cities": [],
          "StateName": "Saint Philip"
        },
        {
          "Cities": [],
          "StateName": "Saint Thomas"
        }
      ],
      "CountryName": "Barbados"
    },
    {
      "States": [
        {
          "Cities": [
            "Baranavichy",
            "Bjaroza",
            "Bjeloozersk",
            "Brest",
            "David-Haradok",
            "Drahichyn",
            "Hancavichy",
            "Ivacevichy",
            "Ivanava",
            "Kamjenec",
            "Kobryn",
            "Kosava",
            "Ljahavichy",
            "Luninjec",
            "Malaryta",
            "Mikashevichy",
            "Pinsk",
            "Pruzhany",
            "Stolin",
            "Vysokoje",
            "Zhabinka"
          ],
          "StateName": "Brest"
        },
        {
          "Cities": [
            "Buda Kosheljovo",
            "Chechersk",
            "Dobrush",
            "Hojniki",
            "Homjel'",
            "Jel'sk",
            "Kalinkavichy",
            "Kascjukovka",
            "Mazyr",
            "Narovlja",
            "Pjetrykav",
            "Rahachow",
            "Rechyca",
            "Svetlahorsk",
            "Vasilevichy",
            "Vjetka",
            "Zhlobin",
            "Zhytkavichy"
          ],
          "StateName": "Homjel'"
        },
        {
          "Cities": [
            "Ashmjany",
            "Berjozovka",
            "Hrodna",
            "Lida",
            "Masty",
            "Navahrudak",
            "Shchuchyn",
            "Skidel'",
            "Slonim",
            "Smarhon'",
            "Vawkavysk"
          ],
          "StateName": "Hrodna"
        },
        {
          "Cities": [
            "Asipovichy",
            "Babrujsk",
            "Byhau",
            "Chausy",
            "Cherikov",
            "Horki",
            "Kascjukovichy",
            "Klimavichy",
            "Krychaw",
            "Mahiljow",
            "Mstislav",
            "Shklov",
            "Slaeuharad"
          ],
          "StateName": "Mahiljow"
        },
        {
          "Cities": [],
          "StateName": "Mahilyowskaya Voblasts"
        },
        {
          "Cities": [
            "Minsk"
          ],
          "StateName": "Minsk"
        },
        {
          "Cities": [],
          "StateName": "Minskaja Voblasts'"
        },
        {
          "Cities": [
            "Petrikov"
          ],
          "StateName": "Petrik"
        },
        {
          "Cities": [
            "Baran'",
            "Braslav",
            "Chashniki",
            "Disna",
            "Dokshicy",
            "Dubrovna",
            "Haradok",
            "Hlybokaje",
            "Ljepjel'",
            "Mjory",
            "Navapolack",
            "Novalukoml'",
            "Orsha",
            "Pastavy",
            "Polack",
            "Polotsk",
            "Senno",
            "Talachyn",
            "Verhnjadzvimsk",
            "Vicebsk"
          ],
          "StateName": "Vicebsk"
        }
      ],
      "CountryName": "Belarus"
    },
    {
      "States": [
        {
          "Cities": [
            "Aartselaar",
            "Antwerpen",
            "Arendonk",
            "Baarle-Hertog",
            "Balen",
            "Beerse",
            "Berlaar",
            "Boechout",
            "Bonheiden",
            "Boom",
            "Bornem",
            "Borsbeek",
            "Brasschaat",
            "Brecht",
            "Dessel",
            "Deurne",
            "Duffel",
            "Edegem",
            "Ekeren",
            "Essen",
            "Geel",
            "Geluvele",
            "Grobbendonk",
            "Heist-op-den-Berg",
            "Hemiksem",
            "Herentals",
            "Herenthout",
            "Herselt",
            "Hoogstraten",
            "Hove",
            "Hulshout",
            "Kalmpthout",
            "Kalmthout",
            "Kapellen",
            "Kasterlee",
            "Kontich",
            "Laakdal",
            "Lier",
            "Lille",
            "Lint",
            "Malle",
            "Mechelen",
            "Meerhout",
            "Merksplas",
            "Mol",
            "Mortsel",
            "Niel",
            "Nijlen",
            "Olen",
            "Oud-Turnhout",
            "Putte",
            "Puurs",
            "Ranst",
            "Ravels",
            "Retie",
            "Rijkevorsel",
            "Robrechts",
            "Rumst",
            "Schelle",
            "Schilde",
            "Schoten",
            "Sint-Amands",
            "Sint-Katelijne-Waver",
            "Stabroek",
            "Turnhout",
            "Vorselaar",
            "Vosselaar",
            "Westerlo",
            "Wijnegem",
            "Willebroek",
            "Wilrijk",
            "Wommelgem",
            "Wuustwezel",
            "Zandhoven",
            "Zoersel",
            "Zwijndrecht"
          ],
          "StateName": "Antwerpen"
        },
        {
          "Cities": [
            "Berchem"
          ],
          "StateName": "Berchem"
        },
        {
          "Cities": [
            "Alsemberg",
            "Asse Zellik",
            "Brussels",
            "Bruxelles",
            "Diegem"
          ],
          "StateName": "Brabant"
        },
        {
          "Cities": [
            "Alsemberg",
            "Asse Zellik",
            "Brussels",
            "Bruxelles",
            "Diegem"
          ],
          "StateName": "Brabant Wallon"
        },
        {
          "Cities": [
            "Brussel"
          ],
          "StateName": "Brussel"
        },
        {
          "Cities": [],
          "StateName": "East Flanders"
        },
        {
          "Cities": [
            "Aiseau-Presles",
            "Anderlues",
            "Antoing",
            "Ath",
            "Beaumont",
            "Beloeil",
            "Bernissart",
            "Binche",
            "Boussu",
            "Braine-le-Comte",
            "Brugelette",
            "Brunehaut",
            "Celles",
            "Chapelle-lez-Herlaimont",
            "Charleroi",
            "Chatelet",
            "Chievres",
            "Chimay",
            "Colfontaine",
            "Comines-Warneton",
            "Courcelles",
            "Dour",
            "Ecaussinnes",
            "Ellezelles",
            "Enghien",
            "Erquelinnes",
            "Estaimpuis",
            "Estinnes",
            "Farciennes",
            "Fleurus",
            "Flobecq",
            "Fontaine-l'Eveque",
            "Frameries",
            "Frasnes-lez-Anvaing",
            "Froidchapelle",
            "Gerpinnes",
            "Haine-Saint-Pierre",
            "Ham-sur-Heure-Nalinnes",
            "Hensies",
            "Honnelles",
            "Jurbise",
            "La Louviere",
            "Le Roeulx",
            "Lens",
            "Les Bons Villers",
            "Lessines",
            "Leuze-en-Hainaut",
            "Lobbes",
            "Manage",
            "Merbes-le-Chateau",
            "Momignies",
            "Mons",
            "Mont-de-l'Enclus",
            "Montigny-le-Tilleul",
            "Morlanwelz",
            "Mouscron",
            "Pecq",
            "Peruwelz",
            "Pont-a-Celles",
            "Quaregnon",
            "Quevy",
            "Quievrain",
            "Rumes",
            "Saint-Ghislain",
            "Seneffe",
            "Silly",
            "Sivry-Rance",
            "Soignies",
            "Thuin",
            "Tournai"
          ],
          "StateName": "Hainaut"
        },
        {
          "Cities": [
            "Amay",
            "Amel",
            "Angleur",
            "Ans",
            "Anthisnes",
            "Aubel",
            "Awans",
            "Aywaille",
            "Baelen",
            "Bassenge",
            "Berloz",
            "Beyne-Heusay",
            "Blegny",
            "Braives",
            "Bullingen",
            "Burdinne",
            "Burg-Reuland",
            "Butgenbach",
            "Chaudfontaine",
            "Clavier",
            "Comblain-au-Pont",
            "Crisnee",
            "Dalhem",
            "Dison",
            "Donceel",
            "Engis",
            "Esneux",
            "Eupen",
            "Faimes",
            "Ferrieres",
            "Fexhe-le-Haut-Clocher",
            "Flemalle",
            "Fleron",
            "Geer",
            "Grace-Hollogne",
            "Hamoir",
            "Hannut",
            "Heron",
            "Herstal",
            "Herve",
            "Huy",
            "Jalhay",
            "Juprelle",
            "Kelmis",
            "Liege",
            "Lierneux",
            "Limbourg",
            "Lincent",
            "Lontzen",
            "Malmedy",
            "Marchin",
            "Modave",
            "Montzen",
            "Nandrin",
            "Neupre",
            "Olne",
            "Oreye",
            "Ouffet",
            "Oupeye",
            "Pepinster",
            "Plombieres",
            "Racour",
            "Raeren",
            "Remicourt",
            "Saint-Georges-sur-Meuse",
            "Saint-Nicolas",
            "Sankt Vith",
            "Seraing",
            "Soumagne",
            "Spa",
            "Sprimont",
            "Stavelot",
            "Stoumont",
            "Theux",
            "Thimister-Clermont",
            "Tinlot",
            "Trois-Ponts",
            "Trooz",
            "Verlaine",
            "Verviers",
            "Villers-le-Bouillet",
            "Vise",
            "Waimes",
            "Wanze",
            "Waremme",
            "Wasseiges",
            "Welkenraedt"
          ],
          "StateName": "Liege"
        },
        {
          "Cities": [
            "Alken",
            "As",
            "Beringen",
            "Bilzen",
            "Bocholt",
            "Borgloon",
            "Bree",
            "Diepenbeek",
            "Dilsen-Stokkem",
            "Genk",
            "Gingelom",
            "Halen",
            "Ham",
            "Hamont-Achel",
            "Hasselt",
            "Hechtel-Eksel",
            "Heers",
            "Herk-de-Stad",
            "Herstappe",
            "Heusden-Zolder",
            "Hoeselt",
            "Houthalen-Helchteren",
            "Kinrooi",
            "Kortessem",
            "Kuringen",
            "Lanaken",
            "Leopoldsburg",
            "Lommel",
            "Lummen",
            "Maaseik",
            "Maasmechelen",
            "Meeuwen-Gruitrode",
            "Neerpelt",
            "Nieuwerkerken",
            "Opglabbeek",
            "Overpelt",
            "Peer",
            "Riemst",
            "Sint-Truiden",
            "Tessenderlo",
            "Tongeren",
            "Voeren",
            "Wellen",
            "Zonhoven",
            "Zutendaal"
          ],
          "StateName": "Limburg"
        },
        {
          "Cities": [
            "Arlon",
            "Attert",
            "Aubange",
            "Bastogne",
            "Bertogne",
            "Bertrix",
            "Bouillon",
            "Chiny",
            "Daverdisse",
            "Durbuy",
            "Erezee",
            "Etalle",
            "Fauvillers",
            "Florenville",
            "Gouvy",
            "Grapfontaine",
            "Habay",
            "Herbeumont",
            "Hotton",
            "Houffalize",
            "La Roche-en-Ardenne",
            "Leglise",
            "Libin",
            "Libramont-Chevigny",
            "Manhay",
            "Marche-en-Famenne",
            "Marloie",
            "Martelange",
            "Meix-devant-Virton",
            "Messancy",
            "Musson",
            "Nassogne",
            "Neufchateau",
            "Paliseul",
            "Rendeux",
            "Rouvroy",
            "Saint-Hubert",
            "Saint-Leger",
            "Sainte-Ode",
            "Tellin",
            "Tenneville",
            "Tintigny",
            "Vaux-sur-Sure",
            "Vielsalm",
            "Virton",
            "Wellin"
          ],
          "StateName": "Luxembourg"
        },
        {
          "Cities": [
            "Andenne",
            "Anhee",
            "Assesse",
            "Beauraing",
            "Bievre",
            "Cerfontaine",
            "Ciney",
            "Couvin",
            "Dinant",
            "Doische",
            "Eghezee",
            "Fernelmont",
            "Floreffe",
            "Florennes",
            "Fosses-la-Ville",
            "Gedinne",
            "Gembloux",
            "Gesves",
            "Hamois",
            "Hastiere",
            "Havelange",
            "Houyet",
            "Jambes",
            "Jemeppe-sur-Sambre",
            "La Bruyere",
            "Malonne",
            "Mettet",
            "Namur",
            "Ohey",
            "Onhaye",
            "Philippeville",
            "Profondeville",
            "Rochefort",
            "Sambreville",
            "Sombreffe",
            "Somme-Leuze",
            "Suarlee",
            "Viroinval",
            "Vresse-sur-Semois",
            "Walcourt",
            "Yvoir"
          ],
          "StateName": "Namur"
        },
        {
          "Cities": [
            "Gullegem",
            "Langdorp"
          ],
          "StateName": "Ontario"
        },
        {
          "Cities": [
            "Aalst",
            "Aalter",
            "Assenede",
            "Berlare",
            "Beveren",
            "Brakel",
            "Buggenhout",
            "De Pinte",
            "Deinze",
            "Denderleeuw",
            "Dendermonde",
            "Destelbergen",
            "Drongen",
            "Eeklo",
            "Erembodegem",
            "Erpe-Mere",
            "Evergem",
            "Gavere",
            "Gent",
            "Geraardsbergen",
            "Ghent",
            "Haaltert",
            "Hamme",
            "Herzele",
            "Horebeke",
            "Kaprijke",
            "Kerksken",
            "Kluisbergen",
            "Knesselare",
            "Kruibeke",
            "Kruishoutem",
            "Laarne",
            "Lebbeke",
            "Lede",
            "Lierde",
            "Lochristi",
            "Lokeren",
            "Lovendegem",
            "Maarkedal",
            "Maldegem",
            "Melle",
            "Merelbeke",
            "Moerbeke",
            "Nazareth",
            "Nevele",
            "Ninove",
            "Oosterzele",
            "Oudenaarde",
            "Ronse",
            "Semmerzake",
            "Sint-Gillis-Waas",
            "Sint-Laureins",
            "Sint-Lievens-Houtem",
            "Sint-Martens-Latem",
            "Sint-Niklaas",
            "Stekene",
            "Temse",
            "Waarschoot",
            "Waasmunster",
            "Wachtebeke",
            "Wetteren",
            "Wichelen",
            "Wortegem-Petegem",
            "Zele",
            "Zelzate",
            "Zingem",
            "Zomergem",
            "Zottegem",
            "Zulte",
            "Zwalm",
            "Zwijnaarde"
          ],
          "StateName": "Oost-Vlaanderen"
        },
        {
          "Cities": [],
          "StateName": "Provincie Brabant"
        },
        {
          "Cities": [
            "Aarschot",
            "Affligem",
            "Asse",
            "Beersel",
            "Begijnendijk",
            "Bekkevoort",
            "Bertem",
            "Bever",
            "Bierbeek",
            "Boortmeerbeek",
            "Boutersem",
            "Diest",
            "Dilbeek",
            "Drogenbos",
            "Galmaarden",
            "Geetbets",
            "Glabbeek",
            "Gooik",
            "Grimbergen",
            "Haacht",
            "Halle",
            "Herent",
            "Herne",
            "Hoegaarden",
            "Hoeilaart",
            "Holsbeek",
            "Huldenberg",
            "Kampenhout",
            "Kapelle-op-den-Bos",
            "Keerbergen",
            "Kortenaken",
            "Kortenberg",
            "Kraainem",
            "Landen",
            "Lennik",
            "Leuven",
            "Liedekerke",
            "Linkebeek",
            "Linter",
            "Londerzeel",
            "Lubbeek",
            "Machelen",
            "Meise",
            "Merchtem",
            "Opwijk",
            "Oud-Heverlee",
            "Overijse",
            "Pepingen",
            "Roosdaal",
            "Rotselaar",
            "Scherpenheuvel-Zichem",
            "Sint-Genesius-Rode",
            "Sint-Pieters-Leeuw",
            "Steenokkerzeel",
            "Ternat",
            "Tervuren",
            "Tielt-Winge",
            "Tienen",
            "Tremelo",
            "Vilvoorde",
            "Wemmel",
            "Wezembeek-Oppem",
            "Zaventem",
            "Zemst",
            "Zoutleeuw"
          ],
          "StateName": "Vlaams-Brabant"
        },
        {
          "Cities": [
            "Callenelle",
            "Marcinelle",
            "Strepy-Bracquegnies"
          ],
          "StateName": "Wallonne"
        },
        {
          "Cities": [
            "Alveringem",
            "Anzegem",
            "Ardooie",
            "Avelgem",
            "Beernem",
            "Blankenberge",
            "Bredene",
            "Brugge",
            "Damme",
            "De Haan",
            "De Panne",
            "Deerlijk",
            "Dentergem",
            "Diksmuide",
            "Geluveld",
            "Gistel",
            "Harelbeke",
            "Heuvelland",
            "Hooglede",
            "Houthulst",
            "Ichtegem",
            "Ieper",
            "Ingelmunster",
            "Ingooigem",
            "Izegem",
            "Jabbeke",
            "Knokke-Heist",
            "Koekelare",
            "Koksijde",
            "Kortemark",
            "Kortrijk",
            "Kuurne",
            "Langemark-Poelkapelle",
            "Ledegem",
            "Lendelede",
            "Lichtervelde",
            "Lo-Reninge",
            "Menen",
            "Mesen",
            "Meulebeke",
            "Middelkerke",
            "Moorslede",
            "Nieuwpoort",
            "Oostende",
            "Oostkamp",
            "Oostrozebeke",
            "Oudenburg",
            "Pittem",
            "Poperinge",
            "Roeselare",
            "Ruiselede",
            "Spiere-Helkijn",
            "Staden",
            "Tielt",
            "Torhout",
            "Veurne",
            "Vleteren",
            "Waregem",
            "Wervik",
            "Wevelgem",
            "Wielsbeke",
            "Wingene",
            "Zedelgem",
            "Zeebrugge",
            "Zonnebeke",
            "Zuienkerke",
            "Zwevegem"
          ],
          "StateName": "West-Vlaanderen"
        }
      ],
      "CountryName": "Belgium"
    },
    {
      "States": [
        {
          "Cities": [
            "Belize",
            "San Pedro"
          ],
          "StateName": "Belize"
        },
        {
          "Cities": [
            "Belmopan",
            "Benque Viejo",
            "San Ignacio"
          ],
          "StateName": "Cayo"
        },
        {
          "Cities": [
            "Corozal"
          ],
          "StateName": "Corozal"
        },
        {
          "Cities": [],
          "StateName": "Orange Walk"
        },
        {
          "Cities": [],
          "StateName": "Stann Creek"
        },
        {
          "Cities": [
            "Punta Gorda"
          ],
          "StateName": "Toledo"
        }
      ],
      "CountryName": "Belize"
    },
    {
      "States": [
        {
          "Cities": [
            "Kandi",
            "Malanville"
          ],
          "StateName": "Alibori"
        },
        {
          "Cities": [
            "Batia",
            "Boukombe",
            "Kouande",
            "Natitingou"
          ],
          "StateName": "Atacora"
        },
        {
          "Cities": [
            "Allada",
            "Cotonou",
            "Ouidah",
            "Tanguiete"
          ],
          "StateName": "Atlantique"
        },
        {
          "Cities": [
            "Bembereke",
            "Nikki",
            "Parakou",
            "Tchaourou"
          ],
          "StateName": "Borgou"
        },
        {
          "Cities": [
            "Dassa",
            "Savalou",
            "Save"
          ],
          "StateName": "Collines"
        },
        {
          "Cities": [
            "Aplahoue",
            "Dogbo"
          ],
          "StateName": "Couffo"
        },
        {
          "Cities": [
            "Djougou"
          ],
          "StateName": "Donga"
        },
        {
          "Cities": [
            "Cotonou"
          ],
          "StateName": "Littoral"
        },
        {
          "Cities": [
            "Athieme",
            "Come",
            "Grand Popo",
            "Lokossa"
          ],
          "StateName": "Mono"
        },
        {
          "Cities": [
            "Adjohoun",
            "Porto Novo"
          ],
          "StateName": "Oueme"
        },
        {
          "Cities": [
            "Ketou",
            "Pobe",
            "Sakete"
          ],
          "StateName": "Plateau"
        },
        {
          "Cities": [
            "Abomey",
            "Bohicon",
            "Cove",
            "Zagnanado"
          ],
          "StateName": "Zou"
        }
      ],
      "CountryName": "Benin"
    },
    {
      "States": [
        {
          "Cities": [
            "Hamilton"
          ],
          "StateName": "Hamilton"
        },
        {
          "Cities": [],
          "StateName": "Saint George"
        }
      ],
      "CountryName": "Bermuda"
    },
    {
      "States": [
        {
          "Cities": [
            "Jakar"
          ],
          "StateName": "Bumthang"
        },
        {
          "Cities": [
            "Chhukha",
            "Phuentsholing"
          ],
          "StateName": "Chhukha"
        },
        {
          "Cities": [
            "Damphu"
          ],
          "StateName": "Chirang"
        },
        {
          "Cities": [
            "Taga Dzong"
          ],
          "StateName": "Daga"
        },
        {
          "Cities": [
            "Geylegphug"
          ],
          "StateName": "Geylegphug"
        },
        {
          "Cities": [
            "Ha"
          ],
          "StateName": "Ha"
        },
        {
          "Cities": [
            "Lhuntshi"
          ],
          "StateName": "Lhuntshi"
        },
        {
          "Cities": [
            "Mongar"
          ],
          "StateName": "Mongar"
        },
        {
          "Cities": [
            "Pemagatsel"
          ],
          "StateName": "Pemagatsel"
        },
        {
          "Cities": [
            "Gasa",
            "Punakha"
          ],
          "StateName": "Punakha"
        },
        {
          "Cities": [
            "Paro"
          ],
          "StateName": "Rinpung"
        },
        {
          "Cities": [
            "Phuntsholing",
            "Samchi"
          ],
          "StateName": "Samchi"
        },
        {
          "Cities": [],
          "StateName": "Samdrup Jongkhar"
        },
        {
          "Cities": [
            "Shemgang"
          ],
          "StateName": "Shemgang"
        },
        {
          "Cities": [
            "Tashigang"
          ],
          "StateName": "Tashigang"
        },
        {
          "Cities": [
            "Thimphu",
            "Timphu"
          ],
          "StateName": "Timphu"
        },
        {
          "Cities": [
            "Tongsa"
          ],
          "StateName": "Tongsa"
        },
        {
          "Cities": [
            "Wangdiphodrang"
          ],
          "StateName": "Wangdiphodrang"
        }
      ],
      "CountryName": "Bhutan"
    },
    {
      "States": [
        {
          "Cities": [
            "Guayaramerin",
            "Magdalena",
            "Reyes",
            "Riberalta",
            "Rurrenabaque",
            "San Borja",
            "San Ignacio",
            "San Ramon",
            "Santa Ana",
            "Santa Rosa",
            "Trinidad"
          ],
          "StateName": "Beni"
        },
        {
          "Cities": [
            "Camargo",
            "Monteagudo",
            "Muyupampa",
            "Padilla",
            "Sucre",
            "Tarabuco",
            "Villa Serano"
          ],
          "StateName": "Chuquisaca"
        },
        {
          "Cities": [
            "Aiquile",
            "Arani",
            "Capinota",
            "Chimore",
            "Cliza",
            "Cochabamba",
            "Colomi",
            "Entre Rios",
            "Irpa Irpa",
            "Ivirgarzama",
            "Mizque",
            "Punata",
            "Shinahota",
            "Sipe Sipe",
            "Tarata",
            "Ucurena"
          ],
          "StateName": "Cochabamba"
        },
        {
          "Cities": [],
          "StateName": "La Paz"
        },
        {
          "Cities": [
            "Caracollo",
            "Challapata",
            "Eucaliptus",
            "Huanuni",
            "Machacamarca",
            "Oruro",
            "Poopo",
            "Santiago de Huari",
            "Totoral"
          ],
          "StateName": "Oruro"
        },
        {
          "Cities": [
            "Cobija"
          ],
          "StateName": "Pando"
        },
        {
          "Cities": [
            "Atocha",
            "Betanzos",
            "Colquechaca",
            "Llallagua",
            "Potosi",
            "Santa Barbara",
            "Tupiza",
            "Uncia",
            "Uyuni",
            "Villazon"
          ],
          "StateName": "Potosi"
        },
        {
          "Cities": [],
          "StateName": "Santa Cruz"
        },
        {
          "Cities": [
            "Bermejo",
            "Entre Rios",
            "San Lorenzo",
            "Tarija",
            "Villamontes",
            "Yacuiba"
          ],
          "StateName": "Tarija"
        }
      ],
      "CountryName": "Bolivia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Federacija Bosna i Hercegovina"
        },
        {
          "Cities": [],
          "StateName": "Republika Srpska"
        }
      ],
      "CountryName": "Bosnia and Herzegovina"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Central Bobonong"
        },
        {
          "Cities": [],
          "StateName": "Central Boteti"
        },
        {
          "Cities": [],
          "StateName": "Central Mahalapye"
        },
        {
          "Cities": [],
          "StateName": "Central Serowe-Palapye"
        },
        {
          "Cities": [],
          "StateName": "Central Tutume"
        },
        {
          "Cities": [
            "Kachikau",
            "Kasane",
            "Kavimba",
            "Kazungula",
            "Lesoma",
            "Muchinje-Mabale",
            "Pandamatenga",
            "Pandamatenga Botswana Defence ",
            "Parakarungu",
            "Satau"
          ],
          "StateName": "Chobe"
        },
        {
          "Cities": [
            "Francistown"
          ],
          "StateName": "Francistown"
        },
        {
          "Cities": [
            "Gaborone"
          ],
          "StateName": "Gaborone"
        },
        {
          "Cities": [
            "Bere",
            "Charles Hill",
            "Chobokwane",
            "Dekar",
            "East Hanahai",
            "Ghanzi",
            "Groote Laagte",
            "Kacgae",
            "Karakobis",
            "Kuke Quarantine Camp",
            "Kule",
            "Makunda",
            "Ncojane",
            "New Xade",
            "New Xanagas",
            "Qabo",
            "Tsootsha",
            "West Hanahai"
          ],
          "StateName": "Ghanzi"
        },
        {
          "Cities": [
            "Jwaneng"
          ],
          "StateName": "Jwaneng"
        },
        {
          "Cities": [],
          "StateName": "Kgalagadi North"
        },
        {
          "Cities": [],
          "StateName": "Kgalagadi South"
        },
        {
          "Cities": [
            "Artisia",
            "Bokaa",
            "Dikgonye",
            "Dikwididi",
            "Kgomodiatshaba",
            "Khurutshe",
            "Leshibitse",
            "Mabalane",
            "Malolwane",
            "Malotwana Siding",
            "Matebeleng",
            "Mmamashia",
            "Mmathubudukwane",
            "Mochudi",
            "Modipane",
            "Morwa",
            "Oliphants Drift",
            "Oodi",
            "Pilane",
            "Ramonaka",
            "Ramotlabaki",
            "Rasesa",
            "Sikwane"
          ],
          "StateName": "Kgatleng"
        },
        {
          "Cities": [
            "Boatlaname",
            "Botlhapatlou",
            "Diagane",
            "Diphudugodu",
            "Diremogolo Lands",
            "Ditshegwane",
            "Ditshukudu",
            "Dumadumane",
            "Dutlwe",
            "Gabane",
            "Gakgatla",
            "Gakuto",
            "Galekgatshwane",
            "Gamodubu",
            "Gaphatshwa",
            "Hatsalatladi",
            "Kamenakwe",
            "Kaudwane",
            "Kgaphamadi",
            "Kgope",
            "Khekhenya-Chepetese",
            "Khudumelapye",
            "Kopong",
            "Kotolaname",
            "Kubung",
            "Kumakwane",
            "Kweneng",
            "Lentsweletau",
            "Lephepe",
            "Lesirane",
            "Letlhakeng",
            "Losilakgokong",
            "Maboane",
            "Mahetlwe",
            "Makabanyane-Dikgokong",
            "Malwelwe",
            "Mamhiko",
            "Manaledi",
            "Mantshwabisi",
            "Marejwane",
            "Masebele",
            "Medie",
            "Metsibotlhoko",
            "Metsimotlhaba",
            "Mmakanke",
            "Mmankgodi",
            "Mmanoko",
            "Mmokolodi",
            "Mmopane",
            "Mmopane Lands",
            "Mogoditshane",
            "Mogoditshane Botswana Defence ",
            "Mogoditshane Lands",
            "Mogonono",
            "Molepolole",
            "Mononyane",
            "Monwane",
            "Morabane",
            "Morope",
            "Moshaweng",
            "Mosokotswe",
            "Motokwe",
            "Ngware",
            "Nkoyaphiri",
            "Ramaphatlhe",
            "Salajwe",
            "Serinane",
            "Sesung",
            "Shadishadi",
            "Sojwe",
            "Sorilatholo",
            "Suping",
            "Takatokwane",
            "Thamaga",
            "Thebephatshwa",
            "Tlowaneng",
            "Tsetseng",
            "Tswaane"
          ],
          "StateName": "Kweneng"
        },
        {
          "Cities": [
            "Lobatse"
          ],
          "StateName": "Lobatse"
        },
        {
          "Cities": [
            "Bodibeng",
            "Boro",
            "Botlhatlogo",
            "Chanoga",
            "Chuchubega",
            "Daonara",
            "Ditshiping",
            "Habu",
            "Jao",
            "Kareng",
            "Katamaga",
            "Kgakge",
            "Khwai Camp",
            "Komana",
            "Legotlhwana",
            "Mababe",
            "Makalamabedi",
            "Matlapana",
            "Matsaudi",
            "Mawana",
            "Mokgalo-Haka",
            "Morutsha",
            "Nxharaga",
            "Phuduhudu",
            "Samodupi",
            "Sankuyo",
            "Sehithwa",
            "Semboyo",
            "Sexaxa",
            "Shakawe",
            "Shorobe",
            "Somela",
            "Toteng",
            "Tsanekona",
            "Tsao",
            "Xaxaba",
            "Xhobe"
          ],
          "StateName": "Ngamiland"
        },
        {
          "Cities": [
            "Bethel",
            "Borobadilepe",
            "Diabo",
            "Digawana",
            "Dikhukhung",
            "Dinatshana",
            "Dipotsana",
            "Ditlharapa",
            "Gamajalela",
            "Gasita",
            "Gathwane",
            "Good Hope",
            "Goora-seno",
            "Gopong",
            "Hebron",
            "Itholoke",
            "Kanaku",
            "Kangwe",
            "Kanye",
            "Keng",
            "Kgomokasitwa",
            "Kgoro",
            "Khakhea",
            "Khonkhwa",
            "Kokong",
            "Lehoko",
            "Lejwana",
            "Lekgolobotlo",
            "Leporung",
            "Logagane",
            "Lorolwana",
            "Lorwana",
            "Lotlhakane",
            "Lotlhakane West",
            "Mabule",
            "Mabutsane",
            "Madingwana",
            "Magoriapitse",
            "Magotlhawane",
            "Mahotshwane",
            "Maisane",
            "Makokwe",
            "Malokaganyane",
            "Manyana",
            "Maokane",
            "Marojane",
            "Maruswa",
            "Metlobo",
            "Metlojane",
            "Mmakgori",
            "Mmathethe",
            "Mogojogojo",
            "Mogonye",
            "Mogwalale",
            "Mokatako",
            "Mokgomane",
            "Mokhomba",
            "Molapowabojang",
            "Molete",
            "Morwamosu",
            "Moshaneng",
            "Moshupa",
            "Motlhwatse",
            "Motsentshe",
            "Musi",
            "Ngwatsau",
            "Ntlhantlhe",
            "Papatlo",
            "Phihitshwane",
            "Pitsana-Potokwe",
            "Pitsane",
            "Pitseng-Ralekgetho",
            "Pitshane Molopo",
            "Rakhuna",
            "Ralekgetho",
            "Ramatlabama",
            "Ranaka",
            "Sedibeng",
            "Segakwana",
            "Segwagwa",
            "Seherelela",
            "Sekhutlane",
            "Sekoma",
            "Selokolela",
            "Semane",
            "Sese",
            "Sesung",
            "Sheep Farm",
            "Tlhankane",
            "Tlhareseleele",
            "Tshidilamolomo",
            "Tshwaane",
            "Tsonyane",
            "Tswaaneng",
            "Tswagare-Lothoje-Lokalana",
            "Tswanyaneng"
          ],
          "StateName": "Ngwaketse"
        },
        {
          "Cities": [],
          "StateName": "North East"
        },
        {
          "Cities": [
            "Beetsha",
            "Eretsha",
            "Etsha 1",
            "Etsha 13",
            "Etsha 6",
            "Etsha 8",
            "Etsha 9",
            "Gane",
            "Gonutsuga",
            "Gowe",
            "Gudingwa",
            "Gumare",
            "Ikoga",
            "Kajaja",
            "Kapotora Lands",
            "Kauxwhi",
            "Matswee",
            "Maun",
            "Moaha",
            "Mohembo East",
            "Mohembo West",
            "Mokgacha",
            "Ngarange",
            "Nokaneng",
            "Nxamasere",
            "Nxaunxau",
            "Nxwee",
            "Qangwa",
            "Roye",
            "Samochema",
            "Sekondomboro",
            "Sepopa",
            "Seronga",
            "Shaowe",
            "Tobere Lands",
            "Tubu",
            "Tubu Lands",
            "Xadau",
            "Xakao",
            "Xaxa",
            "Xhauga",
            "Xurube"
          ],
          "StateName": "Okavango"
        },
        {
          "Cities": [
            "Orapa"
          ],
          "StateName": "Orapa"
        },
        {
          "Cities": [],
          "StateName": "Selibe Phikwe"
        },
        {
          "Cities": [],
          "StateName": "South East"
        },
        {
          "Cities": [
            "Sowa"
          ],
          "StateName": "Sowa"
        }
      ],
      "CountryName": "Botswana"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Bouvet Island"
        }
      ],
      "CountryName": "Bouvet Island"
    },
    {
      "States": [
        {
          "Cities": [
            "Acrelandia",
            "Brasileia",
            "Cruzeiro do Sul",
            "Epitaciolandia",
            "Feijo",
            "Mancio Lima",
            "Manoel Urbano",
            "Marechal Thaumaturgo",
            "Placido de Castro",
            "Porto Walter",
            "Rio Branco",
            "Rodrigues Alves",
            "Sena Madureira",
            "Senador Guiomard",
            "Tarauaca",
            "Xapuri"
          ],
          "StateName": "Acre"
        },
        {
          "Cities": [
            "Agua Branca",
            "Anadia",
            "Arapiraca",
            "Atalaia",
            "Barra de Santo Antonio",
            "Batalha",
            "Boca da Mata",
            "Cacimbinhas",
            "Cajueiro",
            "Campo Alegre",
            "Campo Grande",
            "Canapi",
            "Capela",
            "Coite do Noia",
            "Colonia Leopoldina",
            "Coruripe",
            "Craibas",
            "Delmiro Gouveia",
            "Dois Riachos",
            "Estrela de Alagoas",
            "Feira Grande",
            "Flexeiras",
            "Girau do Ponciano",
            "Ibateguara",
            "Igaci",
            "Igreja Nova",
            "Inhapi",
            "Joaquim Gomes",
            "Jundia",
            "Junqueiro",
            "Lagoa da Canoa",
            "Limoeiro de Anadia",
            "Maceio",
            "Major Isidoro",
            "Maragogi",
            "Maravilha",
            "Marechal Deodoro",
            "Maribondo",
            "Mata Grande",
            "Matriz de Camaragibe",
            "Messias",
            "Minador do Negrao",
            "Murici",
            "Novo Lino",
            "Olho d'Agua das Flores",
            "Olivenca",
            "Palmeira dos Indios",
            "Pao de Acucar",
            "Passo de Camaragibe",
            "Penedo",
            "Piacabucu",
            "Pilar",
            "Piranhas",
            "Poco das Trincheiras",
            "Porto Calvo",
            "Porto Real do Colegio",
            "Quebrangulo",
            "Rio Largo",
            "Santana do Ipanema",
            "Santana do Mundau",
            "Sao Jose da Laje",
            "Sao Jose da Tapera",
            "Sao Luis do Quitunde",
            "Sao Miguel dos Campos",
            "Sao Sebastiao",
            "Satuba",
            "Senador Rui Palmeira",
            "Taquarana",
            "Teotonio Vilela",
            "Traipu",
            "Uniao dos Palmares",
            "Vicosa"
          ],
          "StateName": "Alagoas"
        },
        {
          "Cities": [
            "Amapa",
            "Laranjal do Jari",
            "Macapa",
            "Mazagao",
            "Oiapoque",
            "Santana"
          ],
          "StateName": "Amapa"
        },
        {
          "Cities": [
            "Alvaraes",
            "Anori",
            "Apui",
            "Autazes",
            "Barcelos",
            "Barreirinha",
            "Benjamin Constant",
            "Boca do Acre",
            "Borba",
            "Canutama",
            "Carauari",
            "Careiro",
            "Careiro da Varzea",
            "Coari",
            "Codajas",
            "Eirunepe",
            "Envira",
            "Fonte Boa",
            "Guajara",
            "Humaita",
            "Ipixuna",
            "Iranduba",
            "Itacoatiara",
            "Japura",
            "Jutai",
            "Labrea",
            "Manacapuru",
            "Manaquiri",
            "Manaus",
            "Manicore",
            "Maraa",
            "Maues",
            "Nhamunda",
            "Nova Olinda do Norte",
            "Novo Airao",
            "Novo Aripuana",
            "Parintins",
            "Pauini",
            "Rio Preto da Eva",
            "Santa Isabel do Rio Negro",
            "Santo Antonio do Ica",
            "Sao Gabriel da Cachoeira",
            "Sao Paulo de Olivenca",
            "Tabatinga",
            "Tapaua",
            "Tefe",
            "Tonantins",
            "Uarini",
            "Urucara",
            "Urucurituba"
          ],
          "StateName": "Amazonas"
        },
        {
          "Cities": [
            "Acajutiba",
            "Alagoinhas",
            "Amargosa",
            "Amelia Rodrigues",
            "America Dourada",
            "Anage",
            "Araci",
            "Aurelino Leal",
            "Baixa Grande",
            "Barra",
            "Barra da Estiva",
            "Barra do Choca",
            "Barreiras",
            "Belmonte",
            "Boa Vista do Tupim",
            "Bom Jesus da Lapa",
            "Boquira",
            "Brumado",
            "Buerarema",
            "Cachoeira",
            "Cacule",
            "Caetite",
            "Cafarnaum",
            "Camacan",
            "Camacari",
            "Camamu",
            "Campo Alegre de Lourdes",
            "Campo Formoso",
            "Canarana",
            "Canavieiras",
            "Candeias",
            "Candido Sales",
            "Cansancao",
            "Capim Grosso",
            "Caravelas",
            "Carinhanha",
            "Casa Nova",
            "Castro Alves",
            "Catu",
            "Cicero Dantas",
            "Cipo",
            "Coaraci",
            "Conceicao da Feira",
            "Conceicao do Almeida",
            "Conceicao do Coite",
            "Conceicao do Jacuipe",
            "Conde",
            "Coracao de Maria",
            "Coronel Joao Sa",
            "Correntina",
            "Cruz das Almas",
            "Curaca",
            "Dias d'Avila",
            "Encruzilhada",
            "Entre Rios",
            "Esplanada",
            "Euclides da Cunha",
            "Eunapolis",
            "Feira de Santana",
            "Filadelfia",
            "Formosa do Rio Preto",
            "Gandu",
            "Guanambi",
            "Guaratinga",
            "Iacu",
            "Ibicarai",
            "Ibicui",
            "Ibipeba",
            "Ibirapitanga",
            "Ibirataia",
            "Ibotirama",
            "Iguai",
            "Ilheus",
            "Inhambupe",
            "Ipiau",
            "Ipira",
            "Iraquara",
            "Irara",
            "Irece",
            "Itabela",
            "Itaberaba",
            "Itabuna",
            "Itacare",
            "Itagi",
            "Itagiba",
            "Itajuipe",
            "Itamaraju",
            "Itambe",
            "Itanhem",
            "Itaparica",
            "Itapetinga",
            "Itapicuru",
            "Itarantim",
            "Itirucu",
            "Itiuba",
            "Itororo",
            "Ituacu",
            "Itubera",
            "Jacobina",
            "Jaguaquara",
            "Jaguarari",
            "Jequie",
            "Jeremoabo",
            "Jitauna",
            "Joao Dourado",
            "Juazeiro",
            "Jussara",
            "Laje",
            "Lapao",
            "Lauro de Freitas",
            "Livramento",
            "Macarani",
            "Macaubas",
            "Madre de Deus",
            "Mairi",
            "Maracas",
            "Maragogipe",
            "Marau",
            "Mascote",
            "Mata de Sao Joao",
            "Medeiros Neto",
            "Miguel Calmon",
            "Milagres",
            "Monte Santo",
            "Morro de Chapeu",
            "Mucuri",
            "Mundo Novo",
            "Muritiba",
            "Mutuipe",
            "Nazare",
            "Nova Soure",
            "Nova Vicosa",
            "Olindina",
            "Oliveira dos Brejinhos",
            "Palmas de Monte Alto",
            "Paramirim",
            "Paratinga",
            "Paripiranga",
            "Pau Brasil",
            "Paulo Afonso",
            "Pilao Arcado",
            "Pindobacu",
            "Piritiba",
            "Planalto",
            "Pocoes",
            "Pojuca",
            "Ponto Novo",
            "Porto Seguro",
            "Prado",
            "Presidente Tancredo Neves",
            "Queimadas",
            "Quijingue",
            "Rafael Jambeiro",
            "Remanso",
            "Riachao das Neves",
            "Riachao do Jacuipe",
            "Riacho de Santana",
            "Ribeira do Pombal",
            "Rio Real",
            "Ruy Barbosa",
            "Salvador",
            "Santa Cruz Cabralia",
            "Santa Ines",
            "Santa Maria da Vitoria",
            "Santa Rita de Cassia",
            "Santaluz",
            "Santana",
            "Santo Amaro",
            "Santo Antonio de Jesus",
            "Santo Estevao",
            "Sao Desiderio",
            "Sao Felipe",
            "Sao Francisco do Conde",
            "Sao Gabriel",
            "Sao Goncalo dos Campos",
            "Sao Sebastiao do Passe",
            "Saubara",
            "Seabra",
            "Senhor do Bonfim",
            "Sento Se",
            "Serra Dourada",
            "Serra do Ramalho",
            "Serrinha",
            "Simoes Filho",
            "Sobradinho",
            "Souto Soares",
            "Tanhacu",
            "Taperoa",
            "Tapiramuta",
            "Teixeira de Freitas",
            "Teofilandia",
            "Terra Nova",
            "Tremedal",
            "Tucano",
            "Uaua",
            "Ubaira",
            "Ubaitaba",
            "Ubata",
            "Una",
            "Urucuca",
            "Utinga",
            "Valenca",
            "Valente",
            "Vera Cruz",
            "Vitoria da Conquista",
            "Wenceslau Guimaraes",
            "Xique-Xique"
          ],
          "StateName": "Bahia"
        },
        {
          "Cities": [
            "Acarau",
            "Acopiara",
            "Amontada",
            "Aquiraz",
            "Aracati",
            "Aracoiaba",
            "Araripe",
            "Assare",
            "Aurora",
            "Barbalha",
            "Barro",
            "Barroquinha",
            "Baturite",
            "Beberibe",
            "Bela Cruz",
            "Boa Viagem",
            "Brejo Santo",
            "Camocim",
            "Campos Sales",
            "Caninde",
            "Carire",
            "Caririacu",
            "Cascavel",
            "Caucaia",
            "Cedro",
            "Chorozinho",
            "Coreau",
            "Crateus",
            "Crato",
            "Cruz",
            "Eusebio",
            "Farias Brito",
            "Forquilha",
            "Fortaleza",
            "Granja",
            "Guaiuba",
            "Guaraciaba do Norte",
            "Hidrolandia",
            "Horizonte",
            "Ibiapina",
            "Ico",
            "Iguatu",
            "Independencia",
            "Ipu",
            "Ipueiras",
            "Iraucuba",
            "Itaitinga",
            "Itapage",
            "Itapipoca",
            "Itarema",
            "Jaguaribe",
            "Jaguaruana",
            "Jardim",
            "Juazeiro do Norte",
            "Jucas",
            "Lavras da Mangabeira",
            "Limoeiro do Norte",
            "Maracanau",
            "Maranguape",
            "Marco",
            "Massape",
            "Mauriti",
            "Milagres",
            "Missao Velha",
            "Mombaca",
            "Morada Nova",
            "Nova Russas",
            "Novo Oriente",
            "Ocara",
            "Oros",
            "Pacajus",
            "Pacatuba",
            "Paracuru",
            "Paraipaba",
            "Parambu",
            "Pedra Branca",
            "Pentecoste",
            "Quixada",
            "Quixeramobim",
            "Quixere",
            "Redencao",
            "Reriutaba",
            "Russas",
            "Santa Quiteria",
            "Santana do Acarau",
            "Sao Benedito",
            "Sao Goncalo do Amarante",
            "Senador Pompeu",
            "Sobral",
            "Tabuleiro do Norte",
            "Tamboril",
            "Taua",
            "Tiangua",
            "Trairi",
            "Ubajara",
            "Umirim",
            "Uruburetama",
            "Varjota",
            "Varzea Alegre",
            "Vicosa do Ceara"
          ],
          "StateName": "Ceara"
        },
        {
          "Cities": [],
          "StateName": "Distrito Federal"
        },
        {
          "Cities": [],
          "StateName": "Espirito Santo"
        },
        {
          "Cities": [],
          "StateName": "Estado de Sao Paulo"
        },
        {
          "Cities": [
            "Abadiania",
            "Acreuna",
            "Aguas Lindas de Goias",
            "Alexania",
            "Anapolis",
            "Anicuns",
            "Aparecida de Goiania",
            "Aragarcas",
            "Bela Vista de Goias",
            "Bom Jesus de Goias",
            "Buriti Alegre",
            "Cacu",
            "Caiaponia",
            "Caldas Novas",
            "Campos Belos",
            "Campos Verdes",
            "Carmo do Rio Verde",
            "Catalao",
            "Cavalcante",
            "Ceres",
            "Cidade Ocidental",
            "Cocalzinho de Coias",
            "Cristalina",
            "Crixas",
            "Doverlandia",
            "Edeia",
            "Firminopolis",
            "Formosa",
            "Goianapolis",
            "Goianesia",
            "Goiania",
            "Goianira",
            "Goias",
            "Goiatuba",
            "Guapo",
            "Hidrolandia",
            "Iaciara",
            "Indiara",
            "Inhumas",
            "Ipameri",
            "Ipora",
            "Itaberai",
            "Itapaci",
            "Itapirapua",
            "Itapuranga",
            "Itumbiara",
            "Jaragua",
            "Jatai",
            "Jussara",
            "Luziania",
            "Mara Rosa",
            "Minacu",
            "Mineiros",
            "Morrinhos",
            "Mozarlandia",
            "Neropolis",
            "Niquelandia",
            "Nova Crixas",
            "Novo Gama",
            "Orizona",
            "Padre Bernardo",
            "Palmeiras de Goias",
            "Parauna",
            "Petrolina de Goias",
            "Piracanjuba",
            "Piranhas",
            "Pirenopolis",
            "Pires do Rio",
            "Planaltina",
            "Pontalina",
            "Porangatu",
            "Posse",
            "Quirinopolis",
            "Rialma",
            "Rio Verde",
            "Rubiataba",
            "Santa Helena de Goias",
            "Santa Terezinha de Goias",
            "Santo Antonio do Descoberto",
            "Sao Domingos",
            "Sao Luis de Montes Belos",
            "Sao Miguel do Araguaia",
            "Sao Simao",
            "Senador Canedo",
            "Silvania",
            "Trindade",
            "Uruacu",
            "Uruana",
            "Valparaiso de Goias",
            "Vianopolis"
          ],
          "StateName": "Goias"
        },
        {
          "Cities": [
            "Acailandia",
            "Alcantara",
            "Aldeias Altas",
            "Alto Alegre do Pindare",
            "Amarante do Maranhao",
            "Anajatuba",
            "Araioses",
            "Arame",
            "Arari",
            "Bacabal",
            "Balsas",
            "Barra do Corda",
            "Barreirinhas",
            "Bequimao",
            "Bom Jardim",
            "Brejo",
            "Buriti",
            "Buriti Bravo",
            "Buriticupu",
            "Candido Mendes",
            "Cantanhede",
            "Carolina",
            "Carutapera",
            "Caxias",
            "Chapadinha",
            "Codo",
            "Coelho Neto",
            "Colinas",
            "Coroata",
            "Cururupu",
            "Davinopolis",
            "Dom Pedro",
            "Esperantinopolis",
            "Estreito",
            "Fortuna",
            "Godofredo Viana",
            "Governador Eugenio Barros",
            "Governador Nunes Freire",
            "Grajau",
            "Humberto de Campos",
            "Icatu",
            "Imperatriz",
            "Itapecuru Mirim",
            "Itinga do Maranhao",
            "Joao Lisboa",
            "Lago da Pedra",
            "Lago do Junco",
            "Maracacume",
            "Matinha",
            "Matoes",
            "Mirador",
            "Miranda do Norte",
            "Moncao",
            "Montes Altos",
            "Morros",
            "Nova Olinda do Maranhao",
            "Olho d'Agua das Cunhas",
            "Paco do Lumiar",
            "Paraibano",
            "Parnarama",
            "Passagem Franca",
            "Pastos Bons",
            "Paulo Ramos",
            "Pedreiras",
            "Penalva",
            "Pindare Mirim",
            "Pinheiro",
            "Pio XII",
            "Pirapemas",
            "Pocao de Pedras",
            "Porto Franco",
            "Presidente Dutra",
            "Raposa",
            "Riachao",
            "Rosario",
            "Santa Helena",
            "Santa Ines",
            "Santa Luzia",
            "Santa Luzia do Parua",
            "Santa Quiteria do Maranhao",
            "Santa Rita",
            "Sao Benedito do Rio Preto",
            "Sao Bento",
            "Sao Bernardo",
            "Sao Domingos do Maranhao",
            "Sao Joao Batista",
            "Sao Joao dos Patos",
            "Sao Jose de Ribamar",
            "Sao Luis",
            "Sao Luis Gonzaga do Maranhao",
            "Sao Mateus do Maranhao",
            "Sao Pedro da Agua Branca",
            "Sao Raimundo das Mangabeiras",
            "Timbiras",
            "Timon",
            "Trizidela do Vale",
            "Tuntum",
            "Turiacu",
            "Tutoia",
            "Urbano Santos",
            "Vargem Grande",
            "Viana",
            "Vitoria do Mearim",
            "Vitorino Freire",
            "Ze Doca"
          ],
          "StateName": "Maranhao"
        },
        {
          "Cities": [],
          "StateName": "Mato Grosso"
        },
        {
          "Cities": [],
          "StateName": "Mato Grosso do Sul"
        },
        {
          "Cities": [],
          "StateName": "Minas Gerais"
        },
        {
          "Cities": [
            "Abaetetuba",
            "Acara",
            "Afua",
            "Agua Azul do Norte",
            "Alenquer",
            "Almeirim",
            "Altamira",
            "Ananindeua",
            "Augusto Correa",
            "Baiao",
            "Barcarena",
            "Belem",
            "Benevides",
            "Braganca",
            "Breu Branco",
            "Breves",
            "Bujaru",
            "Cameta",
            "Capanema",
            "Capitao Poco",
            "Castanhal",
            "Conceicao do Araguaia",
            "Concordia do Para",
            "Curionopolis",
            "Curuca",
            "Dom Eliseu",
            "Eldorado dos Carajas",
            "Garrafao do Norte",
            "Goianesia do Para",
            "Gurupa",
            "Igarape-Acu",
            "Igarape-Miri",
            "Irituia",
            "Itaituba",
            "Itupiranga",
            "Jacareacanga",
            "Jacunda",
            "Juruti",
            "Limoeiro do Ajuru",
            "Mae do Rio",
            "Maraba",
            "Maracana",
            "Marapanim",
            "Marituba",
            "Medicilandia",
            "Mocajuba",
            "Moju",
            "Monte Alegre",
            "Muana",
            "Novo Progresso",
            "Novo Repartimento",
            "Obidos",
            "Oeiras do Para",
            "Oriximina",
            "Ourem",
            "Ourilandia",
            "Pacaja",
            "Paragominas",
            "Parauapebas",
            "Portel",
            "Porto de Moz",
            "Prainha",
            "Redencao",
            "Rio Maria",
            "Rondon do Para",
            "Ruropolis",
            "Salinopolis",
            "Santa Isabel do Para",
            "Santa Luzia do Para",
            "Santa Maria do Para",
            "Santana do Araguaia",
            "Santarem",
            "Santo Antonio do Taua",
            "Sao Caetano de Odivelas",
            "Sao Domingos do Araguaia",
            "Sao Domingos do Capim",
            "Sao Felix do Xingu",
            "Sao Geraldo do Araguaia",
            "Sao Joao de Pirabas",
            "Sao Miguel do Guama",
            "Senador Jose Porfirio",
            "Soure",
            "Tailandia",
            "Terra Santa",
            "Tome-Acu",
            "Tucuma",
            "Tucurui",
            "Ulianopolis",
            "Uruara",
            "Vigia",
            "Viseu",
            "Xinguara"
          ],
          "StateName": "Para"
        },
        {
          "Cities": [
            "Alagoa Grande",
            "Alagoa Nova",
            "Alagoinha",
            "Alhandra",
            "Aracagi",
            "Arara",
            "Araruna",
            "Areia",
            "Aroeiras",
            "Bananeiras",
            "Barra de Santa Rosa",
            "Bayeux",
            "Belem",
            "Boqueirao",
            "Brejo do Cruz",
            "Caapora",
            "Cabedelo",
            "Cacimba de Dentro",
            "Cajazeiras",
            "Campina Grande",
            "Catole do Rocha",
            "Conceicao",
            "Conde",
            "Coremas",
            "Cruz do Espirito Santo",
            "Cuite",
            "Desterro",
            "Dona Ines",
            "Esperanca",
            "Fagundes",
            "Guarabira",
            "Gurinhem",
            "Imaculada",
            "Inga",
            "Itabaiana",
            "Itaporanga",
            "Itapororoca",
            "Itatuba",
            "Jacarau",
            "Joao Pessoa",
            "Juazeirinho",
            "Juripiranga",
            "Juru",
            "Lagoa Seca",
            "Mamanguape",
            "Manaira",
            "Mari",
            "Massaranduba",
            "Mogeiro",
            "Monteiro",
            "Mulungu",
            "Natuba",
            "Nova Floresta",
            "Patos",
            "Paulista",
            "Pedras de Fogo",
            "Pianco",
            "Picui",
            "Pilar",
            "Pirpirituba",
            "Pitimbu",
            "Pocinhos",
            "Pombal",
            "Princesa Isabel",
            "Puxinana",
            "Queimadas",
            "Remigio",
            "Rio Tinto",
            "Salgado de Sao Felix",
            "Santa Luzia",
            "Santa Rita",
            "Sao Bento",
            "Sao Joao do Rio do Peixe",
            "Sao Jose de Piranhas",
            "Sao Sebastiao de Lagoa de Roca",
            "Sape",
            "Serra Branca",
            "Solanea",
            "Soledade",
            "Sousa",
            "Sume",
            "Taperoa",
            "Tavares",
            "Teixeira",
            "Triunfo",
            "Uirauna",
            "Umbuzeiro"
          ],
          "StateName": "Paraiba"
        },
        {
          "Cities": [
            "Almirante Tamandare",
            "Alto Parana",
            "Alto Piquiri",
            "Altonia",
            "Ampere",
            "Andira",
            "Antonina",
            "Apucarana",
            "Arapongas",
            "Arapoti",
            "Araucaria",
            "Assai",
            "Assis Chateaubriand",
            "Astorga",
            "Bandeirantes",
            "Barbosa Ferraz",
            "Bela Vista do Paraiso",
            "Cambara",
            "Cambe",
            "Campina Grande do Sul",
            "Campina da Lagoa",
            "Campo Largo",
            "Campo Murao",
            "Candido de Abreu",
            "Capitao Leonidas Marques",
            "Carambei",
            "Cascavel",
            "Castro",
            "Centenario do Sul",
            "Chopinzinho",
            "Cianorte",
            "Clevelandia",
            "Colombo",
            "Colorado",
            "Contenda",
            "Corbelia",
            "Cornelio Procopio",
            "Coronel Vivida",
            "Cruzeiro do Oeste",
            "Curitiba",
            "Dois Vizinhos",
            "Engenheiro Beltrao",
            "Faxinal",
            "Fazenda Rio Grande",
            "Florestopolis",
            "Foz do Iguacu",
            "Francisco Beltrao",
            "Goioere",
            "Guaira",
            "Guaraniacu",
            "Guarapuava",
            "Guaratuba",
            "Ibaiti",
            "Ibipora",
            "Imbituva",
            "Ipora",
            "Irati",
            "Itaperucu",
            "Ivaipora",
            "Jacarezinho",
            "Jaguariaiva",
            "Jandaia do Sul",
            "Jataizinho",
            "Lapa",
            "Laranjeiras do Sul",
            "Loanda",
            "Londrina",
            "Mandaguacu",
            "Mandaguari",
            "Marechal Candido Rondon",
            "Marialva",
            "Maringa",
            "Matelandia",
            "Matinhos",
            "Medianeira",
            "Moreira Sales",
            "Nova Aurora",
            "Nova Esperanca",
            "Nova Londrina",
            "Ortigueira",
            "Paicandu",
            "Palmas",
            "Palmeira",
            "Palotina",
            "Paranagua",
            "Paranavai",
            "Pato Branco",
            "Peabiru",
            "Pinhais",
            "Pinhao",
            "Pirai do Sul",
            "Piraquara",
            "Pitanga",
            "Ponta Grossa",
            "Pontal do Parana",
            "Porecatu",
            "Primero de Maio",
            "Prudentopolis",
            "Quatro Barras",
            "Quedas do Iguacu",
            "Realeza",
            "Reserva",
            "Ribeirao do Pinhal",
            "Rio Branco do Sul",
            "Rio Negro",
            "Rolandia",
            "Santa Helena",
            "Santa Terezinha de Itaipu",
            "Santo Antonio da Platina",
            "Santo Antonio do Sudoeste",
            "Sao Joao do Ivai",
            "Sao Jose dos Pinhais",
            "Sao Mateus do Sul",
            "Sao Miguel do Iguacu",
            "Sarandi",
            "Senges",
            "Sertanopolis",
            "Siquera Campos",
            "Tapejara",
            "Telemaco Borba",
            "Terra Boa",
            "Terra Rica",
            "Terra Roxa",
            "Tibagi",
            "Toledo",
            "Ubirata",
            "Umuarama",
            "Uniao da Victoria",
            "Wenceslau Braz"
          ],
          "StateName": "Parana"
        },
        {
          "Cities": [
            "Abreu e Lima",
            "Afogados da Ingazeira",
            "Agrestina",
            "Agua Preta",
            "Aguas Belas",
            "Alianca",
            "Altinho",
            "Amaraji",
            "Aracoiaba",
            "Araripina",
            "Arcoverde",
            "Barra de Guabiraba",
            "Barreiros",
            "Belem de Sao Francisco",
            "Belo Jardim",
            "Bezerros",
            "Bodoco",
            "Bom Conselho",
            "Bom Jardim",
            "Bonito",
            "Brejo da Madre de Deus",
            "Buique",
            "Cabo de Santo Agostinho",
            "Cabrobo",
            "Cachoeirinha",
            "Caetes",
            "Camaragibe",
            "Camocim de Sao Felix",
            "Canhotinho",
            "Capoeiras",
            "Carnaiba",
            "Carpina",
            "Caruaru",
            "Catende",
            "Cha Grande",
            "Condado",
            "Cumaru",
            "Cupira",
            "Custodia",
            "Escada",
            "Exu",
            "Feira Nova",
            "Fernando de Noronha",
            "Flores",
            "Floresta",
            "Gameleira",
            "Garanhuns",
            "Gloria do Goita",
            "Goiana",
            "Gravata",
            "Ibimirim",
            "Igarassu",
            "Inaja",
            "Ipojuca",
            "Ipubi",
            "Itaiba",
            "Itamaraca",
            "Itambe",
            "Itapissuma",
            "Itaquitinga",
            "Jaboatao",
            "Joao Alfredo",
            "Joaquim Nabuco",
            "Lagoa do Itaenga",
            "Lajedo",
            "Limoeiro",
            "Macaparana",
            "Maraial",
            "Moreno",
            "Nazare da Mata",
            "Olinda",
            "Orobo",
            "Ouricuri",
            "Palmares",
            "Panelas",
            "Parnamirim",
            "Passira",
            "Paudalho",
            "Paulista",
            "Pedra",
            "Pesqueira",
            "Petrolandia",
            "Petrolina",
            "Pombos",
            "Quipapa",
            "Recife",
            "Ribeirao",
            "Rio Formoso",
            "Salgueiro",
            "Santa Cruz do Capibaribe",
            "Santa Maria da Boa Vista",
            "Sao Bento do Una",
            "Sao Caitano",
            "Sao Joao",
            "Sao Joaquim do Monte",
            "Sao Jose da Coroa Grande",
            "Sao Jose do Belmonte",
            "Sao Jose do Egito",
            "Sao Lourenco da Mata",
            "Serra Talhada",
            "Sertania",
            "Sirinhaem",
            "Surubim",
            "Tabira",
            "Tamandare",
            "Taquaritinga do Norte",
            "Timbauba",
            "Toritama",
            "Trindade",
            "Triunfo",
            "Tupanatinga",
            "Vicencia",
            "Vitoria de Santo Antao"
          ],
          "StateName": "Pernambuco"
        },
        {
          "Cities": [
            "Agua Branca",
            "Alto Longa",
            "Altos",
            "Amarante",
            "Avelino Lopes",
            "Barras",
            "Batalha",
            "Beneditinos",
            "Bom Jesus",
            "Buriti dos Lopes",
            "Campo Maior",
            "Canto do Buriti",
            "Castelo do Piaui",
            "Cocal",
            "Corrente",
            "Demerval Lobao",
            "Elesbao Veloso",
            "Esperantina",
            "Floriano",
            "Gilbues",
            "Guadalupe",
            "Inhuma",
            "Itainopolis",
            "Itaueira",
            "Jaicos",
            "Joaquim Pires",
            "Jose de Freitas",
            "Luis Correia",
            "Luzilandia",
            "Matias Olimpio",
            "Miguel Alves",
            "Monsenhor Gil",
            "Oeiras",
            "Palmeirais",
            "Parnaiba",
            "Pedro II",
            "Picos",
            "Pimenteiras",
            "Pio IX",
            "Piracuruca",
            "Piripiri",
            "Porto",
            "Regeneracao",
            "Sao Joao do Piaui",
            "Sao Miguel do Tapuio",
            "Sao Pedro do Piaui",
            "Sao Raimundo Nonato",
            "Simoes",
            "Simplicio Mendes",
            "Teresina",
            "Uniao",
            "Urucui",
            "Valenca do Piaui"
          ],
          "StateName": "Piaui"
        },
        {
          "Cities": [],
          "StateName": "Rio Grande do Norte"
        },
        {
          "Cities": [],
          "StateName": "Rio Grande do Sul"
        },
        {
          "Cities": [],
          "StateName": "Rio de Janeiro"
        },
        {
          "Cities": [
            "Alta Floresta d'Oeste",
            "Alto Alegre do Parecis",
            "Alto Paraiso",
            "Alvorada d'Oeste",
            "Ariquemes",
            "Buritis",
            "Cacoal",
            "Candeias do Jamari",
            "Cerejeiras",
            "Colorado do Oeste",
            "Corumbiara",
            "Espigao d'Oeste",
            "Governador Jorge Teixeira",
            "Guajara-Mirim",
            "Jaru",
            "Ji-Parana",
            "Machadinho d'Oeste",
            "Ministro Andreazza",
            "Mirante da Serra",
            "Nova Brasilandia d'Oeste",
            "Nova Mamore",
            "Novo Horizonte do Oeste",
            "Ouro Preto do Oeste",
            "Pimenta Bueno",
            "Porto Velho",
            "Presidente Medici",
            "Rolim de Moura",
            "Santa Luzia d'Oeste",
            "Sao Miguel do Guapore",
            "Urupa",
            "Vale do Paraiso",
            "Vilhena"
          ],
          "StateName": "Rondonia"
        },
        {
          "Cities": [
            "Alto Alegre",
            "Boa Vista",
            "Bonfim",
            "Caracarai",
            "Mucajai",
            "Normandia",
            "Sao Joao da Baliza",
            "Sao Luiz"
          ],
          "StateName": "Roraima"
        },
        {
          "Cities": [],
          "StateName": "Santa Catarina"
        },
        {
          "Cities": [],
          "StateName": "Sao Paulo"
        },
        {
          "Cities": [
            "Aquidaba",
            "Aracaju",
            "Araua",
            "Areia Branca",
            "Barra dos Coqueiros",
            "Boquim",
            "Campo do Brito",
            "Caninde de Sao Francisco",
            "Capela",
            "Carira",
            "Cristinapolis",
            "Estancia",
            "Frei Paulo",
            "Gararu",
            "Indiaroba",
            "Itabaiana",
            "Itabaianinha",
            "Itaporanga d'Ajuda",
            "Japaratuba",
            "Japoata",
            "Lagarto",
            "Laranjeiras",
            "Malhador",
            "Maruim",
            "Moita Bonita",
            "Monte Alegre de Sergipe",
            "Neopolis",
            "Nossa Senhora da Gloria",
            "Nossa Senhora das Dores",
            "Nossa Senhora do Socorro",
            "Pacatuba",
            "Poco Verde",
            "Porto da Folha",
            "Propria",
            "Riachao do Dantas",
            "Ribeiropolis",
            "Salgado",
            "Santa Luzia do Itanhy",
            "Santo Amaro das Brotas",
            "Sao Cristovao",
            "Simao Dias",
            "Tobias Barreto",
            "Tomar do Geru",
            "Umbauba"
          ],
          "StateName": "Sergipe"
        },
        {
          "Cities": [
            "Alvorada",
            "Ananas",
            "Araguacu",
            "Araguaina",
            "Araguatins",
            "Arraias",
            "Augustinopolis",
            "Axixa do Tocantins",
            "Colinas do Tocantins",
            "Dianopolis",
            "Formoso do Araguaia",
            "Goiatins",
            "Guarai",
            "Gurupi",
            "Miracema do Tocantins",
            "Miranorte",
            "Palmas",
            "Paraiso",
            "Parana",
            "Porto Nacional",
            "Sitio Novo do Tocantins",
            "Taguatinga",
            "Tocantinopolis",
            "Wanderlandia",
            "Xambioa"
          ],
          "StateName": "Tocantins"
        }
      ],
      "CountryName": "Brazil"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "British Indian Ocean Territory"
        }
      ],
      "CountryName": "British Indian Ocean Territory"
    },
    {
      "States": [
        {
          "Cities": [
            "Kuala Belait",
            "Seria"
          ],
          "StateName": "Belait"
        },
        {
          "Cities": [
            "Bandar Seri Begawan"
          ],
          "StateName": "Brunei-Muara"
        },
        {
          "Cities": [
            "Bangar"
          ],
          "StateName": "Temburong"
        },
        {
          "Cities": [
            "Tutong"
          ],
          "StateName": "Tutong"
        }
      ],
      "CountryName": "Brunei"
    },
    {
      "States": [
        {
          "Cities": [
            "Bansko",
            "Belica",
            "Blagoevgrad",
            "Goce Delchev",
            "Hadzhidimovo",
            "Jakoruda",
            "Kresna",
            "Melnik",
            "Petrich",
            "Razlog",
            "Sandanski",
            "Simitli"
          ],
          "StateName": "Blagoevgrad"
        },
        {
          "Cities": [
            "Ahtopol",
            "Ajtos",
            "Balgarovo",
            "Bourgas",
            "Burgas",
            "Carevo",
            "Kableshkovo",
            "Kameno",
            "Karnobat",
            "Malko Tarnovo",
            "Nesebar",
            "Obzor",
            "Pomorie",
            "Primorsko",
            "Sozopol",
            "Sredec",
            "Sungurlare",
            "Tvardica"
          ],
          "StateName": "Burgas"
        },
        {
          "Cities": [
            "Balchik",
            "Dobrich",
            "General-Toshevo",
            "Kavarna",
            "Loznica",
            "Shabla",
            "Tervel"
          ],
          "StateName": "Dobrich"
        },
        {
          "Cities": [
            "Drjanovo",
            "Gabrovo",
            "Plachkovci",
            "Sevlievo",
            "Trjavna"
          ],
          "StateName": "Gabrovo"
        },
        {
          "Cities": [
            "Dimitrovgrad",
            "Harmanli",
            "Haskovo",
            "Ivajlovgrad",
            "Ljubimec",
            "Madzharovo",
            "Merichleri",
            "Simeonovgrad",
            "Svilengrad"
          ],
          "StateName": "Haskovo"
        },
        {
          "Cities": [
            "Boljarovo",
            "Elhovo",
            "Jambol",
            "Straldzha",
            "Topolovgrad"
          ],
          "StateName": "Jambol"
        },
        {
          "Cities": [
            "Ardino",
            "Dzhebel",
            "Kardzhali",
            "Krumovgrad",
            "Momchilgrad"
          ],
          "StateName": "Kardzhali"
        },
        {
          "Cities": [
            "Boboshevo",
            "Bobovdol",
            "Dupnica",
            "Kjustendil",
            "Kocherinovo",
            "Rila",
            "Sapareva Banja",
            "Zemen"
          ],
          "StateName": "Kjustendil"
        },
        {
          "Cities": [
            "Aprilci",
            "Jablanica",
            "Letnica",
            "Lovech",
            "Lukovit",
            "Sopot",
            "Teteven",
            "Trojan",
            "Ugarchin"
          ],
          "StateName": "Lovech"
        },
        {
          "Cities": [
            "Berkovica",
            "Bojchinovci",
            "Brusarci",
            "Chiprovci",
            "Lom",
            "Montana",
            "Valchedram",
            "Varshec"
          ],
          "StateName": "Montana"
        },
        {
          "Cities": [],
          "StateName": "Oblast Sofiya-Grad"
        },
        {
          "Cities": [
            "Batak",
            "Belovo",
            "Bracigovo",
            "Koprivshtica",
            "Panagjurishte",
            "Pazardzhik",
            "Peshtera",
            "Rakitovo",
            "Septemvri",
            "Strelcha",
            "Velingrad"
          ],
          "StateName": "Pazardzhik"
        },
        {
          "Cities": [
            "Bankja",
            "Batanovci",
            "Breznik",
            "Pernik",
            "Radomir",
            "Tran"
          ],
          "StateName": "Pernik"
        },
        {
          "Cities": [
            "Belene",
            "Cherven Brjag",
            "Dolna Mitropolija",
            "Dolni Dabnik",
            "Guljanci",
            "Levski",
            "Nikopol",
            "Pleven",
            "Pordim",
            "Slavjanovo",
            "Trashtenik",
            "Varbica"
          ],
          "StateName": "Pleven"
        },
        {
          "Cities": [
            "Asenovgrad",
            "Brezovo",
            "Car Kalojan",
            "Hisarja",
            "Kalofer",
            "Karlovo",
            "Klisura",
            "Krichim",
            "Parvomaj",
            "Perushtica",
            "Plovdiv",
            "Rakovski",
            "Sadovo",
            "Saedinenie",
            "Stambolijski"
          ],
          "StateName": "Plovdiv"
        },
        {
          "Cities": [
            "Isperih",
            "Kubrat",
            "Razgrad",
            "Senovo",
            "Zavet"
          ],
          "StateName": "Razgrad"
        },
        {
          "Cities": [
            "Bjala",
            "Borovo",
            "Dve Mogili",
            "Ruse",
            "Russe",
            "Vetovo"
          ],
          "StateName": "Ruse"
        },
        {
          "Cities": [
            "Kaolinovo",
            "Kaspichan",
            "Novi Pazar",
            "Pliska",
            "Shumen",
            "Smjadovo",
            "Veliki Preslav"
          ],
          "StateName": "Shumen"
        },
        {
          "Cities": [
            "Alfatar",
            "Dulovo",
            "Glavinica",
            "Silistra",
            "Tutrakan"
          ],
          "StateName": "Silistra"
        },
        {
          "Cities": [
            "Kermen",
            "Kotel",
            "Nova Zagora",
            "Shivachevo",
            "Sliven"
          ],
          "StateName": "Sliven"
        },
        {
          "Cities": [
            "Chepelare",
            "Devin",
            "Dospat",
            "Laki",
            "Madan",
            "Nedelino",
            "Rudozem",
            "Smoljan",
            "Zlatograd"
          ],
          "StateName": "Smoljan"
        },
        {
          "Cities": [],
          "StateName": "Sofija grad"
        },
        {
          "Cities": [],
          "StateName": "Sofijska oblast"
        },
        {
          "Cities": [],
          "StateName": "Stara Zagora"
        },
        {
          "Cities": [
            "Antonovo",
            "Omurtag",
            "Opaka",
            "Popovo",
            "Targovishte"
          ],
          "StateName": "Targovishte"
        },
        {
          "Cities": [
            "Beloslav",
            "Bjala",
            "Dalgopol",
            "Devnja",
            "Iskar",
            "Provadija",
            "Suvorovo",
            "Valchi Dol",
            "Varna"
          ],
          "StateName": "Varna"
        },
        {
          "Cities": [],
          "StateName": "Veliko Tarnovo"
        },
        {
          "Cities": [
            "Belogradchik",
            "Bregovo",
            "Dimovo",
            "Dolni Chiflik",
            "Dunavci",
            "Gramada",
            "Kula",
            "Vidin"
          ],
          "StateName": "Vidin"
        },
        {
          "Cities": [
            "Bjala Slatina",
            "Knezha",
            "Kojnare",
            "Kozloduj",
            "Krivodol",
            "Mezdra",
            "Mizija",
            "Orjahovo",
            "Roman",
            "Vraca"
          ],
          "StateName": "Vraca"
        },
        {
          "Cities": [
            "Yablaniza"
          ],
          "StateName": "Yablaniza"
        }
      ],
      "CountryName": "Bulgaria"
    },
    {
      "States": [
        {
          "Cities": [
            "Boromo"
          ],
          "StateName": "Bale"
        },
        {
          "Cities": [
            "Kongoussi"
          ],
          "StateName": "Bam"
        },
        {
          "Cities": [
            "Kombissiri"
          ],
          "StateName": "Bazega"
        },
        {
          "Cities": [
            "Diebougou",
            "Pa"
          ],
          "StateName": "Bougouriba"
        },
        {
          "Cities": [
            "Garango",
            "Tenkodogo"
          ],
          "StateName": "Boulgou"
        },
        {
          "Cities": [
            "Koudougou"
          ],
          "StateName": "Boulkiemde"
        },
        {
          "Cities": [
            "Banfora"
          ],
          "StateName": "Comoe"
        },
        {
          "Cities": [
            "Zorgo"
          ],
          "StateName": "Ganzourgou"
        },
        {
          "Cities": [
            "Bogande"
          ],
          "StateName": "Gnagna"
        },
        {
          "Cities": [
            "Fada N'gourma"
          ],
          "StateName": "Gourma"
        },
        {
          "Cities": [
            "Bekuy",
            "Bobo Dioulasso"
          ],
          "StateName": "Houet"
        },
        {
          "Cities": [
            "Dano"
          ],
          "StateName": "Ioba"
        },
        {
          "Cities": [
            "Ouagadougou"
          ],
          "StateName": "Kadiogo"
        },
        {
          "Cities": [
            "Koalla",
            "Koloko",
            "Orodara"
          ],
          "StateName": "Kenedougou"
        },
        {
          "Cities": [
            "Gayeri"
          ],
          "StateName": "Komandjari"
        },
        {
          "Cities": [
            "Pama"
          ],
          "StateName": "Kompienga"
        },
        {
          "Cities": [
            "Nouna"
          ],
          "StateName": "Kossi"
        },
        {
          "Cities": [
            "Koupela"
          ],
          "StateName": "Kouritenga"
        },
        {
          "Cities": [
            "Bousse"
          ],
          "StateName": "Kourweogo"
        },
        {
          "Cities": [
            "Sindou"
          ],
          "StateName": "Leraba"
        },
        {
          "Cities": [
            "Dedougou"
          ],
          "StateName": "Mouhoun"
        },
        {
          "Cities": [
            "Po"
          ],
          "StateName": "Nahouri"
        },
        {
          "Cities": [
            "Boulsa"
          ],
          "StateName": "Namentenga"
        },
        {
          "Cities": [
            "Batie"
          ],
          "StateName": "Noumbiel"
        },
        {
          "Cities": [
            "Ziniare"
          ],
          "StateName": "Oubritenga"
        },
        {
          "Cities": [
            "Gorom-Gorom"
          ],
          "StateName": "Oudalan"
        },
        {
          "Cities": [
            "Yako"
          ],
          "StateName": "Passore"
        },
        {
          "Cities": [
            "Gaoua",
            "Kampti",
            "Loropeni"
          ],
          "StateName": "Poni"
        },
        {
          "Cities": [
            "Reo"
          ],
          "StateName": "Sanguie"
        },
        {
          "Cities": [
            "Kaya"
          ],
          "StateName": "Sanmatenga"
        },
        {
          "Cities": [
            "Dori"
          ],
          "StateName": "Seno"
        },
        {
          "Cities": [
            "Gao",
            "Leo"
          ],
          "StateName": "Sissili"
        },
        {
          "Cities": [
            "Aribinda",
            "Djibo"
          ],
          "StateName": "Soum"
        },
        {
          "Cities": [
            "Louta",
            "Tougan"
          ],
          "StateName": "Sourou"
        },
        {
          "Cities": [
            "Diapaga",
            "Kantchari"
          ],
          "StateName": "Tapoa"
        },
        {
          "Cities": [
            "Hounde"
          ],
          "StateName": "Tuy"
        },
        {
          "Cities": [
            "Ouahigouya"
          ],
          "StateName": "Yatenga"
        },
        {
          "Cities": [
            "Gourcy"
          ],
          "StateName": "Zondoma"
        },
        {
          "Cities": [
            "Manga"
          ],
          "StateName": "Zoundweogo"
        }
      ],
      "CountryName": "Burkina Faso"
    },
    {
      "States": [
        {
          "Cities": [
            "Bubanza"
          ],
          "StateName": "Bubanza"
        },
        {
          "Cities": [
            "Bujumbura"
          ],
          "StateName": "Bujumbura"
        },
        {
          "Cities": [
            "Bururi"
          ],
          "StateName": "Bururi"
        },
        {
          "Cities": [
            "Cankuzo"
          ],
          "StateName": "Cankuzo"
        },
        {
          "Cities": [
            "Cibitoke"
          ],
          "StateName": "Cibitoke"
        },
        {
          "Cities": [
            "Gitega"
          ],
          "StateName": "Gitega"
        },
        {
          "Cities": [
            "Karuzi"
          ],
          "StateName": "Karuzi"
        },
        {
          "Cities": [
            "Kayanza"
          ],
          "StateName": "Kayanza"
        },
        {
          "Cities": [
            "Kirundo"
          ],
          "StateName": "Kirundo"
        },
        {
          "Cities": [
            "Makamba"
          ],
          "StateName": "Makamba"
        },
        {
          "Cities": [
            "Muramvya"
          ],
          "StateName": "Muramvya"
        },
        {
          "Cities": [
            "Muyinga"
          ],
          "StateName": "Muyinga"
        },
        {
          "Cities": [
            "Ngozi"
          ],
          "StateName": "Ngozi"
        },
        {
          "Cities": [
            "Rutana"
          ],
          "StateName": "Rutana"
        },
        {
          "Cities": [
            "Ruyigi"
          ],
          "StateName": "Ruyigi"
        }
      ],
      "CountryName": "Burundi"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Banteay Mean Chey"
        },
        {
          "Cities": [],
          "StateName": "Bat Dambang"
        },
        {
          "Cities": [],
          "StateName": "Kampong Cham"
        },
        {
          "Cities": [],
          "StateName": "Kampong Chhnang"
        },
        {
          "Cities": [],
          "StateName": "Kampong Spoeu"
        },
        {
          "Cities": [],
          "StateName": "Kampong Thum"
        },
        {
          "Cities": [
            "Kampot"
          ],
          "StateName": "Kampot"
        },
        {
          "Cities": [
            "Ta Khmau"
          ],
          "StateName": "Kandal"
        },
        {
          "Cities": [],
          "StateName": "Kaoh Kong"
        },
        {
          "Cities": [
            "Kracheh"
          ],
          "StateName": "Kracheh"
        },
        {
          "Cities": [],
          "StateName": "Krong Kaeb"
        },
        {
          "Cities": [],
          "StateName": "Krong Pailin"
        },
        {
          "Cities": [],
          "StateName": "Krong Preah Sihanouk"
        },
        {
          "Cities": [],
          "StateName": "Mondol Kiri"
        },
        {
          "Cities": [],
          "StateName": "Otdar Mean Chey"
        },
        {
          "Cities": [],
          "StateName": "Phnum Penh"
        },
        {
          "Cities": [
            "Pousat"
          ],
          "StateName": "Pousat"
        },
        {
          "Cities": [],
          "StateName": "Preah Vihear"
        },
        {
          "Cities": [],
          "StateName": "Prey Veaeng"
        },
        {
          "Cities": [],
          "StateName": "Rotanak Kiri"
        },
        {
          "Cities": [],
          "StateName": "Siem Reab"
        },
        {
          "Cities": [],
          "StateName": "Stueng Traeng"
        },
        {
          "Cities": [],
          "StateName": "Svay Rieng"
        },
        {
          "Cities": [
            "Phumi Takaev"
          ],
          "StateName": "Takaev"
        }
      ],
      "CountryName": "Cambodia"
    },
    {
      "States": [
        {
          "Cities": [
            "Banyo",
            "Meiganga",
            "Ngaoundere",
            "Tibati",
            "Tignere"
          ],
          "StateName": "Adamaoua"
        },
        {
          "Cities": [
            "Akonolinga",
            "Bafia",
            "Eseka",
            "Mbalmayo",
            "Mfou",
            "Monatele",
            "Nanga Eboko",
            "Obala",
            "Ombesa",
            "Saa",
            "Yaounde"
          ],
          "StateName": "Centre"
        },
        {
          "Cities": [
            "Abong Mbang",
            "Batouri",
            "Bertoua",
            "Betare Oya",
            "Djoum",
            "Doume",
            "Lomie",
            "Yokadouma"
          ],
          "StateName": "Est"
        },
        {
          "Cities": [
            "Bonaberi",
            "Dibombari",
            "Douala",
            "Edea",
            "Loum",
            "Manjo",
            "Mbanga",
            "Nkongsamba",
            "Yabassi"
          ],
          "StateName": "Littoral"
        },
        {
          "Cities": [
            "Figuif",
            "Garoua",
            "Guider",
            "Lagdo",
            "Poli",
            "Rey Bouba",
            "Tchollire"
          ],
          "StateName": "Nord"
        },
        {
          "Cities": [
            "Figuif",
            "Garoua",
            "Guider",
            "Lagdo",
            "Poli",
            "Rey Bouba",
            "Tchollire"
          ],
          "StateName": "Nord Extreme"
        },
        {
          "Cities": [
            "Bamenda",
            "Kumbo",
            "Mbengwi",
            "Mme",
            "Njinikom",
            "Nkambe",
            "Wum"
          ],
          "StateName": "Nordouest"
        },
        {
          "Cities": [
            "Bafang",
            "Bafoussam",
            "Bafut",
            "Bali",
            "Bana",
            "Bangangte",
            "Djang",
            "Fontem",
            "Foumban",
            "Foumbot",
            "Mbouda"
          ],
          "StateName": "Ouest"
        },
        {
          "Cities": [
            "Akom",
            "Ambam",
            "Ebolowa",
            "Kribi",
            "Lolodorf",
            "Moloundou",
            "Mvangue",
            "Sangmelima"
          ],
          "StateName": "Sud"
        },
        {
          "Cities": [
            "Buea",
            "Idenao",
            "Kumba",
            "Limbe",
            "Mamfe",
            "Muyuka",
            "Tiko"
          ],
          "StateName": "Sudouest"
        }
      ],
      "CountryName": "Cameroon"
    },
    {
      "States": [
        {
          "Cities": [
            "Airdrie",
            "Athabasca",
            "Banff",
            "Barrhead",
            "Bassano",
            "Beaumont",
            "Beaverlodge",
            "Black Diamond",
            "Blackfalds",
            "Blairmore",
            "Bon Accord",
            "Bonnyville",
            "Bow Island",
            "Brooks",
            "Calgary",
            "Calmar",
            "Camrose",
            "Canmore",
            "Cardston",
            "Carstairs",
            "Chateau Lake Louise",
            "Chestermere",
            "Clairmont",
            "Claresholm",
            "Coaldale",
            "Coalhurst",
            "Cochrane",
            "Crossfield",
            "Devon",
            "Didsbury",
            "Drayton Valley",
            "Drumheller",
            "Edmonton",
            "Edson",
            "Elk Point",
            "Fairview",
            "Falher",
            "Fort MacLeod",
            "Fox Creek",
            "Gibbons",
            "Grand Centre",
            "Grande Cache",
            "Grande Prairie",
            "Grimshaw",
            "Hanna",
            "High Level",
            "High Prairie",
            "High River",
            "Hinton",
            "Irricana",
            "Jasper",
            "Killam",
            "La Crete",
            "Lac la Biche",
            "Lacombe",
            "Lamont",
            "Leduc",
            "Lethbridge",
            "Lloydminster",
            "Magrath",
            "Manning",
            "Mayerthorpe",
            "McMurray",
            "Medicine Hat",
            "Millet",
            "Morinville",
            "Nanton",
            "Okotoks",
            "Olds",
            "Peace River",
            "Penhold",
            "Picture Butte",
            "Pincher Creek",
            "Ponoka",
            "Provost",
            "Raymond",
            "Red Deer",
            "Redwater",
            "Rimbey",
            "Rocky Mountain House",
            "Rocky View",
            "Saint Paul",
            "Sexsmith",
            "Sherwood Park",
            "Slave Lake",
            "Smoky Lake",
            "Spirit River",
            "Spruce Grove",
            "Stettler",
            "Stony Plain",
            "Strathmore",
            "Sundre",
            "Swan Hills",
            "Sylvan Lake",
            "Taber",
            "Three Hills",
            "Tofield",
            "Two Hills",
            "Valleyview",
            "Vegreville",
            "Vermilion",
            "Viking",
            "Vulcan",
            "Wainwright",
            "Wembley",
            "Westlock",
            "Wetaskiwin",
            "Whitecourt",
            "Wood Buffalo"
          ],
          "StateName": "Alberta"
        },
        {
          "Cities": [],
          "StateName": "British Columbia"
        },
        {
          "Cities": [
            "Altona",
            "Beausejour",
            "Boissevain",
            "Brandon",
            "Carberry",
            "Carman",
            "Dauphin",
            "Deloraine",
            "Dugald",
            "Flin Flon",
            "Gimli",
            "Hamiota",
            "Killarney",
            "Lac du Bonnet",
            "Leaf Rapids",
            "Lorette",
            "Melita",
            "Minnedosa",
            "Morden",
            "Morris",
            "Neepawa",
            "Niverville",
            "Pinawa",
            "Portage la Prairie",
            "Ritchot",
            "Rivers",
            "Roblin",
            "Saint Adolphe",
            "Sainte Anne",
            "Sainte Rose du Lac",
            "Selkirk",
            "Shilo",
            "Snow Lake",
            "Souris",
            "Springfield",
            "Steinbach",
            "Stonewall",
            "Stony Mountain",
            "Swan River",
            "The Pas",
            "Thompson",
            "Virden",
            "Winkler",
            "Winnipeg"
          ],
          "StateName": "Manitoba"
        },
        {
          "Cities": [],
          "StateName": "New Brunswick"
        },
        {
          "Cities": [],
          "StateName": "Newfoundland and Labrador"
        },
        {
          "Cities": [],
          "StateName": "Northwest Territories"
        },
        {
          "Cities": [],
          "StateName": "Nova Scotia"
        },
        {
          "Cities": [
            "Clyde River",
            "Iqaluit",
            "Kangerdlinerk",
            "Oqsuqtooq",
            "Pangnirtung",
            "Tununirusiq"
          ],
          "StateName": "Nunavut"
        },
        {
          "Cities": [
            "Acton",
            "Ajax",
            "Alexandria",
            "Alfred",
            "Alliston",
            "Almonte",
            "Amherstburg",
            "Amigo Beach",
            "Angus-Borden",
            "Arnprior",
            "Arthur",
            "Athens",
            "Atikokan",
            "Attawapiskat",
            "Aurora",
            "Aylmer",
            "Ayr",
            "Barrie",
            "Barry's Bay",
            "Beamsville",
            "Beaverton",
            "Beeton",
            "Belleville",
            "Belmont",
            "Blenheim",
            "Blind River",
            "Bobcaygeon",
            "Bolton",
            "Bourget",
            "Bowmanville-Newcastle",
            "Bracebridge",
            "Bradford",
            "Brampton",
            "Brantford",
            "Bridgenorth-Chemong Park Area",
            "Brighton",
            "Brockville",
            "Brooklin",
            "Brussels",
            "Burford",
            "Burlington",
            "Caledon",
            "Caledon East",
            "Caledonia",
            "Cambridge",
            "Campbellford",
            "Campbellville",
            "Cannington",
            "Capreol",
            "Cardinal",
            "Carleton Place",
            "Carlisle",
            "Casselman",
            "Cayuga",
            "Chalk River",
            "Chapleau",
            "Chatham",
            "Chesley",
            "Chesterville",
            "Clinton",
            "Cobourg",
            "Cochrane",
            "Colborne",
            "Colchester",
            "Collingwood",
            "Concord",
            "Constance Bay",
            "Cookstown",
            "Cornwall",
            "Creemore",
            "Crystal Beach",
            "Deep River",
            "Delhi",
            "Deseronto",
            "Downsview",
            "Drayton",
            "Dresden",
            "Dryden",
            "Dundalk",
            "Dunnville",
            "Durham",
            "Dutton",
            "Eganville",
            "Elliot Lake",
            "Elmira",
            "Elmvale",
            "Embrun",
            "Englehart",
            "Erin",
            "Espanola",
            "Essex",
            "Etobicoke",
            "Everett",
            "Exeter",
            "Fenelon Falls",
            "Fergus",
            "Forest",
            "Fort Erie",
            "Fort Frances",
            "Frankford",
            "Gananoque",
            "Georgetown",
            "Georgina",
            "Geraldton",
            "Glencoe",
            "Goderich",
            "Golden",
            "Gormley",
            "Grand Bend",
            "Grand Valley",
            "Gravenhurst",
            "Guelph",
            "Hagersville",
            "Haileybury",
            "Hamilton",
            "Hanover",
            "Harriston",
            "Harrow",
            "Hastings",
            "Havelock",
            "Hawkesbury",
            "Hearst",
            "Hensall",
            "Hillsburgh",
            "Hornepayne",
            "Huntsville",
            "Ingersoll",
            "Innisfil",
            "Iroquois",
            "Iroquois Falls",
            "Jarvis",
            "Kanata",
            "Kapuskasing",
            "Kars",
            "Kemptville",
            "Kenora",
            "Kincardine",
            "Kingston",
            "Kirkland Lake",
            "Kitchener",
            "L'Original",
            "Lakefield",
            "Lanark",
            "Leamington",
            "Lindsay",
            "Listowel",
            "Little Current",
            "Lively",
            "London",
            "Longlac",
            "Lucan",
            "Lucknow",
            "Madoc",
            "Manitouwadge",
            "Maple",
            "Marathon",
            "Markdale",
            "Markham",
            "Marmora",
            "Mattawa",
            "Meaford",
            "Metcalfe",
            "Midland",
            "Mildmay",
            "Millbrook",
            "Milton",
            "Milverton",
            "Mississauga",
            "Mississauga Beach",
            "Mitchell",
            "Moose Factory",
            "Morrisburg",
            "Mount Albert",
            "Mount Brydges",
            "Mount Forest",
            "Munster",
            "Nanticoke",
            "Napanee",
            "Nepean",
            "New Hamburg",
            "Newmarket",
            "Newtonville",
            "Nobleton",
            "North Bay",
            "North Gower",
            "North York",
            "Norwich",
            "Norwood",
            "Oakville",
            "Omemee",
            "Onaping-Levack",
            "Ontario",
            "Orangeville",
            "Orillia",
            "Orono",
            "Osgoode",
            "Oshawa",
            "Ottawa",
            "Owen Sound",
            "Paisley",
            "Palmerston",
            "Paris",
            "Parkhill",
            "Parry Sound",
            "Pembroke",
            "Perth",
            "Petawawa",
            "Peterborough",
            "Petrolia",
            "Pickering",
            "Picton",
            "Porcupine",
            "Port Credit",
            "Port Dover",
            "Port Elgin",
            "Port Hope",
            "Port Perry",
            "Port Stanley",
            "Powassan",
            "Prescott",
            "Queensville",
            "Renfrew",
            "Richmond",
            "Richmond Hill",
            "Ridgetown",
            "Rockland",
            "Rockwood",
            "Rodney",
            "Saint Catharines",
            "Saint Catharines-Niagara",
            "Saint George",
            "Saint Jacobs",
            "Saint Marys",
            "Saint Thomas",
            "Sarnia",
            "Sault Sainte Marie",
            "Scarborough",
            "Schomberg",
            "Seaforth",
            "Shelburne",
            "Simcoe",
            "Sioux Lookout",
            "Smiths Falls",
            "Smithville",
            "South River",
            "Southampton",
            "Stayner",
            "Stirling",
            "Stoney Creek",
            "Stoney Point",
            "Stouffville",
            "Stratford",
            "Strathroy",
            "Sturgeon Falls",
            "Sudbury",
            "Sutton",
            "Tavistock",
            "Teeswater",
            "Terrace Bay",
            "Thamesford",
            "Thessalon",
            "Thornbury",
            "Thornhill",
            "Thunder Bay",
            "Tilbury",
            "Tilsonburg",
            "Timmins",
            "Toronto",
            "Tory Hill",
            "Tottenham",
            "Tweed",
            "Uxbridge",
            "Valley East",
            "Vankleek Hill",
            "Vaughan",
            "Vineland",
            "Walkerton",
            "Wallaceburg",
            "Wasaga Beach",
            "Waterdown",
            "Waterford",
            "Waterloo",
            "Watford",
            "Wawa",
            "Welland",
            "Wellesley",
            "Wellington",
            "West Lorne",
            "Wheatley",
            "Whitby",
            "Whitchurch-Stouffville",
            "Wiarton",
            "Wikwemikong",
            "Willowdale",
            "Winchester",
            "Windsor",
            "Wingham",
            "Woodbridge",
            "Woodstock",
            "Wyoming"
          ],
          "StateName": "Ontario"
        },
        {
          "Cities": [],
          "StateName": "Prince Edward Island"
        },
        {
          "Cities": [
            "Acton Vale",
            "Albanel",
            "Alencon",
            "Alma",
            "Amos",
            "Amqui",
            "Anjou",
            "Asbestos",
            "Bagotville",
            "Baie-Comeau",
            "Baie-Saint-Paul",
            "Barraute",
            "Beauceville",
            "Beaupre",
            "Bedford",
            "Beloeil",
            "Bernierville",
            "Berthierville",
            "Betsiamites",
            "Boisbriand",
            "Bonaventure",
            "Boucherville",
            "Bromont",
            "Brossard",
            "Brownsburg",
            "Buckingham",
            "Cabano",
            "Candiac",
            "Cap-Chat",
            "Cap-aux-Meules",
            "Carleton",
            "Causapscal",
            "Chandler",
            "Chapais",
            "Charlesbourg",
            "Chateau-Richer",
            "Chibougamou",
            "Chicoutimi-Jonquiere",
            "Chisasibi",
            "Chute-aux-Outardes",
            "Clermont",
            "Coaticook",
            "Coleraine",
            "Contrecoeur",
            "Cookshire",
            "Cowansville",
            "Crabtree",
            "Danville",
            "Daveluyville",
            "Degelis",
            "Desbiens",
            "Disraeli",
            "Dolbeau",
            "Donnacona",
            "Dorval",
            "Drummondville",
            "East Angus",
            "East Broughton",
            "Farnham",
            "Ferme-Neuve",
            "Fermont",
            "Filion",
            "Forestville",
            "Fort-Coulonge",
            "Gaspe",
            "Gentilly",
            "Granby",
            "Grande-Riviere",
            "Grenville",
            "Ham Nord",
            "Hampstead",
            "Hauterive",
            "Havre-Saint-Pierre",
            "Hebertville",
            "Huntingdon",
            "Joliette",
            "Kingsey Falls",
            "L'Annonciation",
            "L'Ascension-de-Notre-Seigneur",
            "L'Epiphanie",
            "La Malbaie",
            "La Pocatiere",
            "La Sarre",
            "La Tuque",
            "Labelle",
            "Lac-Etchemin",
            "Lac-Lapierre",
            "Lac-Megantic",
            "Lac-au-Saumon",
            "Lachine",
            "Lachute",
            "Lacolle",
            "Lasalle",
            "Laurentides",
            "Laurier-Station",
            "Laval",
            "Lavaltrie",
            "Le Bic",
            "Lebel-sur-Quevillon",
            "Les Cedres",
            "Les Coteaux",
            "Les Escoumins",
            "Liniere",
            "Longueuil",
            "Louiseville",
            "Luceville",
            "Macamic",
            "Magog",
            "Malartic",
            "Maniwaki",
            "Marieville",
            "Maskinonge",
            "Matagami",
            "Matane",
            "Metabetchouan",
            "Mirabel",
            "Mistissini",
            "Mont-Joli",
            "Mont-Laurier",
            "Montmagny",
            "Montreal",
            "Murdochville",
            "Napierville",
            "New Richmond",
            "Nicolet",
            "Normandin",
            "Notre-Dame-du-Bon-Conseil",
            "Notre-Dame-du-Lac",
            "Notre-Dame-du-Mont-Carmel",
            "Oka-Kanesatake",
            "Ormstown",
            "Papineauville",
            "Pierreville",
            "Plessisville",
            "Pointe-Claire",
            "Pont-Rouge",
            "Port-Alfred-Bagotville",
            "Port-Cartier",
            "Portneuf",
            "Price",
            "Princeville",
            "Quebec",
            "Rawdon",
            "Repentigny",
            "Richmond",
            "Rigaud",
            "Rimouski",
            "Riviere-au-Renard",
            "Riviere-du-Loup",
            "Roberval",
            "Rougemont",
            "Rouyn-Noranda",
            "Saint-Agapit",
            "Saint-Alexandre",
            "Saint-Alexis-des-Monts",
            "Saint-Ambroise",
            "Saint-Andre-Avellin",
            "Saint-Anselme",
            "Saint-Apollinaire",
            "Saint-Augustin",
            "Saint-Basile-Sud",
            "Saint-Bruno",
            "Saint-Canut",
            "Saint-Cesaire",
            "Saint-Cyrill-de-Wendover",
            "Saint-Damase",
            "Saint-Damien-de-Buckland",
            "Saint-Denis",
            "Saint-Donat-de-Montcalm",
            "Saint-Ephrem-de-Tring",
            "Saint-Fabien",
            "Saint-Felicien",
            "Saint-Felix-de-Valois",
            "Saint-Gabriel",
            "Saint-Gedeon",
            "Saint-Georges",
            "Saint-Germain-de-Grantham",
            "Saint-Gregoire",
            "Saint-Henri-de-Levis",
            "Saint-Honore",
            "Saint-Hyacinthe",
            "Saint-Jacques",
            "Saint-Jean-Port-Joli",
            "Saint-Jean-de-Dieu",
            "Saint-Jean-sur-Richelieu",
            "Saint-Jerome",
            "Saint-Josephe-de-Beauce",
            "Saint-Josephe-de-Lanoraie",
            "Saint-Josephe-de-la-Riviere-Bl",
            "Saint-Jovite",
            "Saint-Laurent",
            "Saint-Liboire",
            "Saint-Marc-des-Carrieres",
            "Saint-Martin",
            "Saint-Michel-des-Saints",
            "Saint-Pacome",
            "Saint-Pascal",
            "Saint-Pie",
            "Saint-Prosper",
            "Saint-Raphael",
            "Saint-Raymond",
            "Saint-Remi",
            "Saint-Roch-de-l'Achigan",
            "Saint-Sauveur-des-Monts",
            "Saint-Tite",
            "Sainte-Adele",
            "Sainte-Agathe-des-Monts",
            "Sainte-Anne-des-Monts",
            "Sainte-Anne-des-Plaines",
            "Sainte-Catherine",
            "Sainte-Claire",
            "Sainte-Julienne",
            "Sainte-Justine",
            "Sainte-Madeleine",
            "Sainte-Marie",
            "Sainte-Martine",
            "Sainte-Sophie",
            "Sainte-Thecle",
            "Sainte-Therese",
            "Salaberry-de-Valleyfield",
            "Sayabec",
            "Senneterre",
            "Sept-Iles",
            "Shawinigan",
            "Shawville",
            "Sherbrooke",
            "Sorel",
            "St Faustin",
            "St. Hubert",
            "St. Jean Chrysostome",
            "Temiscaming",
            "Terrebonne",
            "Thetford Mines",
            "Thurso",
            "Trois-Pistoles",
            "Trois-Rivieres",
            "Val-David",
            "Val-d'Or",
            "Valcourt",
            "Vallee-Jonction",
            "Vaudreuil",
            "Vercheres",
            "Victoriaville",
            "Ville-Marie",
            "Warwick",
            "Waterloo",
            "Weedon Centre",
            "Westmount",
            "Wickham",
            "Windsor",
            "Yamachiche"
          ],
          "StateName": "Quebec"
        },
        {
          "Cities": [
            "Assiniboia",
            "Biggar",
            "Canora",
            "Carlyle",
            "Carnduff",
            "Caronport",
            "Carrot",
            "Dalmeny",
            "Davidson",
            "Esterhazy",
            "Estevan",
            "Eston",
            "Foam Lake",
            "Fort Qu'Appelle",
            "Gravelbourg",
            "Grenfell",
            "Gull Lake",
            "Hudson Bay",
            "Humboldt",
            "Indian Head",
            "Kamsack",
            "Kelvington",
            "Kerrobert",
            "Kindersley",
            "Kipling",
            "La Ronge",
            "Langenburg",
            "Langham",
            "Lanigan",
            "Lloydminster",
            "Lumsden",
            "Macklin",
            "Maple Creek",
            "Martensville",
            "Meadow Lake",
            "Melfort",
            "Melville",
            "Moose Jaw",
            "Moosomin",
            "Nipawin",
            "North Battleford",
            "Outlook",
            "Oxbow",
            "Pilot Butte",
            "Preeceville",
            "Prince Albert",
            "Regina",
            "Rosetown",
            "Rosthem",
            "Saskatoon",
            "Shaunavon",
            "Shellbrook",
            "Swift Current",
            "Tisdale",
            "Unity",
            "Wadena",
            "Warman",
            "Watrous",
            "Weyburn",
            "White City",
            "Wilkie",
            "Wynyard",
            "Yorkton"
          ],
          "StateName": "Saskatchewan"
        },
        {
          "Cities": [
            "Haines Junction",
            "Mayo",
            "Whitehorse"
          ],
          "StateName": "Yukon"
        }
      ],
      "CountryName": "Canada"
    },
    {
      "States": [
        {
          "Cities": [
            "Sal Rei"
          ],
          "StateName": "Boavista"
        },
        {
          "Cities": [
            "Nova Sintra"
          ],
          "StateName": "Brava"
        },
        {
          "Cities": [
            "Mosteiros",
            "Sao Filipe"
          ],
          "StateName": "Fogo"
        },
        {
          "Cities": [
            "Vila do Maio"
          ],
          "StateName": "Maio"
        },
        {
          "Cities": [
            "Santa Maria"
          ],
          "StateName": "Sal"
        },
        {
          "Cities": [],
          "StateName": "Santo Antao"
        },
        {
          "Cities": [],
          "StateName": "Sao Nicolau"
        },
        {
          "Cities": [],
          "StateName": "Sao Tiago"
        },
        {
          "Cities": [],
          "StateName": "Sao Vicente"
        }
      ],
      "CountryName": "Cape Verde"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Grand Cayman"
        }
      ],
      "CountryName": "Cayman Islands"
    },
    {
      "States": [
        {
          "Cities": [
            "Ndele"
          ],
          "StateName": "Bamingui-Bangoran"
        },
        {
          "Cities": [
            "Bangui"
          ],
          "StateName": "Bangui"
        },
        {
          "Cities": [
            "Alindao",
            "Kembe",
            "Mobaye"
          ],
          "StateName": "Basse-Kotto"
        },
        {
          "Cities": [
            "Obo",
            "Zemio"
          ],
          "StateName": "Haut-Mbomou"
        },
        {
          "Cities": [
            "Bria",
            "Ouadda"
          ],
          "StateName": "Haute-Kotto"
        },
        {
          "Cities": [
            "Dekoa",
            "Sibut"
          ],
          "StateName": "Kemo"
        },
        {
          "Cities": [
            "Boda",
            "Mbaiki",
            "Mongoumba"
          ],
          "StateName": "Lobaye"
        },
        {
          "Cities": [
            "Berberati",
            "Carnot",
            "Gamboula"
          ],
          "StateName": "Mambere-Kadei"
        },
        {
          "Cities": [
            "Bangassou",
            "Gambo",
            "Ouango",
            "Rafai"
          ],
          "StateName": "Mbomou"
        },
        {
          "Cities": [
            "Kaga-Bandoro"
          ],
          "StateName": "Nana-Gribizi"
        },
        {
          "Cities": [
            "Baboua",
            "Baoro",
            "Bouar"
          ],
          "StateName": "Nana-Mambere"
        },
        {
          "Cities": [],
          "StateName": "Ombella Mpoko"
        },
        {
          "Cities": [
            "Bambari",
            "Grimari",
            "Ippy",
            "Kouango"
          ],
          "StateName": "Ouaka"
        },
        {
          "Cities": [
            "Batangafo",
            "Bossangoa",
            "Bouca",
            "Kabo"
          ],
          "StateName": "Ouham"
        },
        {
          "Cities": [
            "Bocaranga",
            "Bozoum",
            "Paoua"
          ],
          "StateName": "Ouham-Pende"
        },
        {
          "Cities": [
            "Nola"
          ],
          "StateName": "Sangha-Mbaere"
        },
        {
          "Cities": [
            "Birao"
          ],
          "StateName": "Vakaga"
        }
      ],
      "CountryName": "Central African Republic"
    },
    {
      "States": [
        {
          "Cities": [
            "Ati",
            "Oum Hadjer"
          ],
          "StateName": "Batha"
        },
        {
          "Cities": [
            "Biltine"
          ],
          "StateName": "Biltine"
        },
        {
          "Cities": [
            "Aouzou",
            "Bardai",
            "Fada",
            "Faya"
          ],
          "StateName": "Bourkou-Ennedi-Tibesti"
        },
        {
          "Cities": [
            "Bokoro",
            "Bousso",
            "Dourbali",
            "Massaguet",
            "Massakory",
            "Massenya",
            "N'Djamena",
            "Ngama"
          ],
          "StateName": "Chari-Baguirmi"
        },
        {
          "Cities": [
            "Bitkine",
            "Melfi",
            "Mongo"
          ],
          "StateName": "Guera"
        },
        {
          "Cities": [
            "Mao",
            "Moussoro",
            "Rig-Rig"
          ],
          "StateName": "Kanem"
        },
        {
          "Cities": [
            "Bol"
          ],
          "StateName": "Lac"
        },
        {
          "Cities": [],
          "StateName": "Logone Occidental"
        },
        {
          "Cities": [],
          "StateName": "Logone Oriental"
        },
        {
          "Cities": [
            "Bongor",
            "Fianga",
            "Gounou Gaya",
            "Guelengdeng",
            "Lere",
            "Pala"
          ],
          "StateName": "Mayo-Kebbi"
        },
        {
          "Cities": [
            "Goundi",
            "Koumra",
            "Kyabe",
            "Moissala",
            "Sarh"
          ],
          "StateName": "Moyen-Chari"
        },
        {
          "Cities": [
            "Abeche",
            "Adre",
            "Am Dam"
          ],
          "StateName": "Ouaddai"
        },
        {
          "Cities": [
            "Abou Deia",
            "Am Timan",
            "Mangueigne"
          ],
          "StateName": "Salamat"
        },
        {
          "Cities": [
            "Benoy",
            "Bere",
            "Kelo",
            "Lai"
          ],
          "StateName": "Tandjile"
        }
      ],
      "CountryName": "Chad"
    },
    {
      "States": [
        {
          "Cities": [
            "Aisen",
            "Chile Chico",
            "Cisnes",
            "Cochrane",
            "Coihaique",
            "Guaitecas",
            "Lago Verde",
            "O'Higgins",
            "Rio Ibanez",
            "Tortel"
          ],
          "StateName": "Aisen"
        },
        {
          "Cities": [
            "Antofagasta",
            "Calama",
            "Maria Elena",
            "Mejilones",
            "Ollague",
            "San Pedro de Atacama",
            "Sierra Gorda",
            "Taltal",
            "Tocopilla"
          ],
          "StateName": "Antofagasta"
        },
        {
          "Cities": [
            "Angol",
            "Carahue",
            "Collipulli",
            "Cunco",
            "Curacautin",
            "Curarrehue",
            "Ercilla",
            "Freire",
            "Galvarino",
            "Gorbea",
            "Lautaro",
            "Loncoche",
            "Lonquimay",
            "Los Sauces",
            "Lumaco",
            "Melipeuco",
            "Nueva Imperial",
            "Padre las Casas",
            "Perquenco",
            "Pitrufquen",
            "Pucon",
            "Puren",
            "Renaico",
            "Saavedra",
            "Temuco",
            "Teodoro Schmidt",
            "Tolten",
            "Traiguen",
            "Victoria",
            "Vilcun",
            "Villarica"
          ],
          "StateName": "Araucania"
        },
        {
          "Cities": [
            "Alto del Carmen",
            "Caldera",
            "Chanaral",
            "Copiapo",
            "Diego de Almagro",
            "Freirina",
            "Huasco",
            "Tierra Amarilla",
            "Vallenar"
          ],
          "StateName": "Atacama"
        },
        {
          "Cities": [],
          "StateName": "Bio Bio"
        },
        {
          "Cities": [
            "Andacollo",
            "Canela",
            "Combarbala",
            "Coquimbo",
            "Illapel",
            "La Higuera",
            "La Serena",
            "Los Vilos",
            "Monte Patria",
            "Ovalle",
            "Paiguano",
            "Punitaci",
            "Rio Hurtado",
            "Salamanca",
            "Vicuna"
          ],
          "StateName": "Coquimbo"
        },
        {
          "Cities": [],
          "StateName": "Libertador General Bernardo O'"
        },
        {
          "Cities": [],
          "StateName": "Los Lagos"
        },
        {
          "Cities": [
            "Cabo de Horno",
            "Laguna Blanca",
            "Natales",
            "Porvenir",
            "Primavera",
            "Punta Arenas",
            "Rio Verde",
            "San Gregorio",
            "Timaukel",
            "Torres del Paine"
          ],
          "StateName": "Magellanes"
        },
        {
          "Cities": [
            "Cauquenes",
            "Chanco",
            "Colbun",
            "Constitucion",
            "Curepto",
            "Curico",
            "Empedrado",
            "Hualane",
            "Licanten",
            "Linares",
            "Longavi",
            "Maule",
            "Molina",
            "Parral",
            "Pelarco",
            "Pelluhue",
            "Pencahue",
            "Rauco",
            "Retiro",
            "Rio Claro",
            "Romeral",
            "Sagrada Familia",
            "San Clemente",
            "San Javier",
            "San Rafael",
            "Talca",
            "Teno",
            "Vichuquen",
            "Villa Alegre",
            "Yerbas Buenas"
          ],
          "StateName": "Maule"
        },
        {
          "Cities": [
            "Alhue",
            "Buin",
            "Calera de Tango",
            "Colina",
            "Curacavi",
            "El Monte",
            "Isla de Maipo",
            "Lampa",
            "Maria Pinto",
            "Melipilla",
            "Padre Hurtado",
            "Paine",
            "Penaflor",
            "Pirque",
            "Puente Alto",
            "Quilicura",
            "San Bernardo",
            "San Jose de Maipo",
            "San Pedro",
            "Santiago",
            "Talagante",
            "Tiltil"
          ],
          "StateName": "Metropolitana"
        },
        {
          "Cities": [
            "Alhue",
            "Buin",
            "Calera de Tango",
            "Colina",
            "Curacavi",
            "El Monte",
            "Isla de Maipo",
            "Lampa",
            "Maria Pinto",
            "Melipilla",
            "Padre Hurtado",
            "Paine",
            "Penaflor",
            "Pirque",
            "Puente Alto",
            "Quilicura",
            "San Bernardo",
            "San Jose de Maipo",
            "San Pedro",
            "Santiago",
            "Talagante",
            "Tiltil"
          ],
          "StateName": "Metropolitana de Santiago"
        },
        {
          "Cities": [
            "Arica",
            "Camarones",
            "Camina",
            "Colchane",
            "General Lagos",
            "Huara",
            "Iquique",
            "Pica",
            "Pozo Almonte",
            "Putre"
          ],
          "StateName": "Tarapaca"
        },
        {
          "Cities": [
            "Algarrobo",
            "Cabildo",
            "Calera",
            "Calle Larga",
            "Cartagena",
            "Casablanca",
            "Catemu",
            "Concon",
            "El Quisco",
            "El Tabo",
            "Hijuelas",
            "La Cruz",
            "La Ligua",
            "Limache",
            "Llaillay",
            "Los Andes",
            "Nogales",
            "Olmue",
            "Panquehue",
            "Papudo",
            "Petorca",
            "Puchuncavi",
            "Putaendeo",
            "Quillota",
            "Quilpue",
            "Quintero",
            "Rinconada",
            "San Antonio",
            "San Esteban",
            "San Felipe",
            "Santa Maria",
            "Santo Domingo",
            "Valparaiso",
            "Villa Alemana",
            "Vina del Mar",
            "Zapallar"
          ],
          "StateName": "Valparaiso"
        }
      ],
      "CountryName": "Chile"
    },
    {
      "States": [
        {
          "Cities": [
            "Fengyang",
            "Guangde",
            "Liuan",
            "Ningguo",
            "Shucheng",
            "Xinchang",
            "Xuancheng"
          ],
          "StateName": "Anhui"
        },
        {
          "Cities": [
            "Fengyang",
            "Guangde",
            "Liuan",
            "Ningguo",
            "Shucheng",
            "Xinchang",
            "Xuancheng"
          ],
          "StateName": "Anhui Province"
        },
        {
          "Cities": [
            "Fengyang",
            "Guangde",
            "Liuan",
            "Ningguo",
            "Shucheng",
            "Xinchang",
            "Xuancheng"
          ],
          "StateName": "Anhui Sheng"
        },
        {
          "Cities": [
            "Aomen"
          ],
          "StateName": "Aomen"
        },
        {
          "Cities": [
            "Beijing",
            "Changping",
            "Fangshan",
            "Huangcun",
            "Liangxiang",
            "Mentougou",
            "Shunyi",
            "Tongzhou"
          ],
          "StateName": "Beijing"
        },
        {
          "Cities": [
            "Beijing",
            "Changping",
            "Fangshan",
            "Huangcun",
            "Liangxiang",
            "Mentougou",
            "Shunyi",
            "Tongzhou"
          ],
          "StateName": "Beijing Shi"
        },
        {
          "Cities": [
            "Beibei",
            "Chongqing",
            "Fuling",
            "Longhua",
            "Nantongkuang",
            "Wanxian",
            "Xiuma",
            "Yubei",
            "Yudong"
          ],
          "StateName": "Chongqing"
        },
        {
          "Cities": [
            "Bantou",
            "Dongshan",
            "Fuan",
            "Fujian",
            "Fuqing",
            "Fuzhou",
            "Gantou",
            "Hanyang",
            "Jiangkou",
            "Jiaocheng",
            "Jinjiang",
            "Jinshang",
            "Longhai",
            "Longyan",
            "Luoyang",
            "Nanan",
            "Nanping",
            "Nanpu",
            "Putian",
            "Qingyang",
            "Quanzhou",
            "Rongcheng",
            "Sanming",
            "Shaowu",
            "Shima",
            "Shishi",
            "Tantou",
            "Tongshan",
            "Xiamen",
            "Xiapu",
            "Xiapu Ningde",
            "Ximei",
            "Yongan",
            "Zhangzhou",
            "Zhicheng"
          ],
          "StateName": "Fujian"
        },
        {
          "Cities": [
            "Bantou",
            "Dongshan",
            "Fuan",
            "Fujian",
            "Fuqing",
            "Fuzhou",
            "Gantou",
            "Hanyang",
            "Jiangkou",
            "Jiaocheng",
            "Jinjiang",
            "Jinshang",
            "Longhai",
            "Longyan",
            "Luoyang",
            "Nanan",
            "Nanping",
            "Nanpu",
            "Putian",
            "Qingyang",
            "Quanzhou",
            "Rongcheng",
            "Sanming",
            "Shaowu",
            "Shima",
            "Shishi",
            "Tantou",
            "Tongshan",
            "Xiamen",
            "Xiapu",
            "Xiapu Ningde",
            "Ximei",
            "Yongan",
            "Zhangzhou",
            "Zhicheng"
          ],
          "StateName": "Fujian Sheng"
        },
        {
          "Cities": [
            "Baiyin",
            "Baoji",
            "Beidao",
            "Jiayuguan",
            "Jinchang",
            "Jiuquan",
            "Lanzhou",
            "Linxia",
            "Pingliang",
            "Qincheng",
            "Wuwei",
            "Yaojie",
            "Yumen",
            "Zhangye",
            "Zhuanglang"
          ],
          "StateName": "Gansu"
        },
        {
          "Cities": [
            "Anbu",
            "Chaozhou",
            "Chenghai",
            "Chuncheng",
            "Daliang",
            "Danshui",
            "Dongguan",
            "Donghai",
            "Dongli",
            "Dongzhen",
            "Ducheng",
            "Encheng",
            "Foahn",
            "Foshan",
            "Gaozhou",
            "Guangdong",
            "Guangzhou",
            "Guanjiao",
            "Haicheng",
            "Haimen",
            "Hepo",
            "Houpu",
            "Huaicheng",
            "Huanggang",
            "Huangpu",
            "Huazhou",
            "Huicheng",
            "Huizhou",
            "Humen",
            "Jiangmen",
            "Jiazi",
            "Jieshi",
            "Jieyang",
            "Lecheng",
            "Leicheng",
            "Liancheng",
            "Lianzhou",
            "Licheng",
            "Liusha",
            "Longgang",
            "Lubu",
            "Luocheng",
            "Luohu",
            "Luoyang",
            "Maba",
            "Maoming",
            "Mata",
            "Meilu",
            "Meizhou",
            "Mianchang",
            "Nanfeng",
            "Nanhai",
            "Pingshan",
            "Qingtang",
            "Qingyuan",
            "Rongcheng",
            "Sanbu",
            "Shantou",
            "Shanwei",
            "Shaoguan",
            "Shaping",
            "Shenzhen",
            "Shilong",
            "Shiqiao",
            "Shiwan",
            "Shuizhai",
            "Shunde",
            "Suicheng",
            "Taicheng",
            "Tangping",
            "Xiaolan",
            "Xinan",
            "Xingcheng",
            "Xiongzhou",
            "Xucheng",
            "Yangjiang",
            "Yingcheng",
            "Yuancheng",
            "Yuncheng",
            "Yunfu",
            "Zengcheng",
            "Zhanjiang",
            "Zhaoqing",
            "Zhilong",
            "Zhongshan",
            "Zhuhai"
          ],
          "StateName": "Guangdong"
        },
        {
          "Cities": [
            "Anbu",
            "Chaozhou",
            "Chenghai",
            "Chuncheng",
            "Daliang",
            "Danshui",
            "Dongguan",
            "Donghai",
            "Dongli",
            "Dongzhen",
            "Ducheng",
            "Encheng",
            "Foahn",
            "Foshan",
            "Gaozhou",
            "Guangdong",
            "Guangzhou",
            "Guanjiao",
            "Haicheng",
            "Haimen",
            "Hepo",
            "Houpu",
            "Huaicheng",
            "Huanggang",
            "Huangpu",
            "Huazhou",
            "Huicheng",
            "Huizhou",
            "Humen",
            "Jiangmen",
            "Jiazi",
            "Jieshi",
            "Jieyang",
            "Lecheng",
            "Leicheng",
            "Liancheng",
            "Lianzhou",
            "Licheng",
            "Liusha",
            "Longgang",
            "Lubu",
            "Luocheng",
            "Luohu",
            "Luoyang",
            "Maba",
            "Maoming",
            "Mata",
            "Meilu",
            "Meizhou",
            "Mianchang",
            "Nanfeng",
            "Nanhai",
            "Pingshan",
            "Qingtang",
            "Qingyuan",
            "Rongcheng",
            "Sanbu",
            "Shantou",
            "Shanwei",
            "Shaoguan",
            "Shaping",
            "Shenzhen",
            "Shilong",
            "Shiqiao",
            "Shiwan",
            "Shuizhai",
            "Shunde",
            "Suicheng",
            "Taicheng",
            "Tangping",
            "Xiaolan",
            "Xinan",
            "Xingcheng",
            "Xiongzhou",
            "Xucheng",
            "Yangjiang",
            "Yingcheng",
            "Yuancheng",
            "Yuncheng",
            "Yunfu",
            "Zengcheng",
            "Zhanjiang",
            "Zhaoqing",
            "Zhilong",
            "Zhongshan",
            "Zhuhai"
          ],
          "StateName": "Guangdong Sheng"
        },
        {
          "Cities": [
            "Babu",
            "Baihe",
            "Baise",
            "Beihai",
            "Binzhou",
            "Bose",
            "Fangchenggang",
            "Guicheng",
            "Guilin",
            "Guiping",
            "Jinchengjiang",
            "Jinji",
            "Laibin",
            "Lianzhou",
            "Liuzhou",
            "Luorong",
            "Matong",
            "Nandu",
            "Nanning",
            "Pingnan",
            "Pumiao",
            "Qinzhou",
            "Songhua",
            "Wuzhou",
            "Yashan",
            "Yulin"
          ],
          "StateName": "Guangxi"
        },
        {
          "Cities": [
            "Anshun",
            "Bijie",
            "Caohai",
            "Duyun",
            "Guiyang",
            "Kaili",
            "Liupanshui",
            "Luoyang",
            "Pingzhai",
            "Tongren",
            "Tongzi",
            "Xiaoweizhai",
            "Xingyi",
            "Zunyi"
          ],
          "StateName": "Guizhou"
        },
        {
          "Cities": [
            "Chengmai",
            "Dingan",
            "Haikou",
            "Lingao",
            "Qiongshan",
            "Sansha ",
            "Sanya",
            "Wanning"
          ],
          "StateName": "Hainan"
        },
        {
          "Cities": [
            "Anping",
            "Baoding",
            "Botou",
            "Cangzhou",
            "Changli",
            "Chengde",
            "Dingzhou",
            "Fengfeng",
            "Fengrun",
            "Guye",
            "Handan",
            "Hebei",
            "Hecun",
            "Hejian",
            "Hengshui",
            "Huanghua",
            "Jingxingkuang",
            "Jinzhou",
            "Langfang",
            "Lianzhou",
            "Linshui",
            "Linxi",
            "Longyao County",
            "Nangong",
            "Pengcheng",
            "Qinhuangdao",
            "Renqiu",
            "Shahe",
            "Shijiazhuang",
            "Tangjiazhuang",
            "Tangshan",
            "Wuan",
            "Xian County",
            "Xingtai",
            "Xinji",
            "Xinle",
            "Xuanhua",
            "Zhangjiakou",
            "Zhaogezhuang",
            "Zhuozhou"
          ],
          "StateName": "Hebei"
        },
        {
          "Cities": [
            "Acheng",
            "Anda",
            "Angangxi",
            "Baiquan",
            "Bamiantong",
            "Baoqing",
            "Baoshan",
            "Bayan",
            "Beian",
            "Binzhou",
            "Boli",
            "Chaihe",
            "Chengzihe",
            "Cuiluan",
            "Daqing",
            "Didao",
            "Dongning",
            "Fujin",
            "Fuli",
            "Fulitun",
            "Fuyu",
            "Gannan",
            "Hailin",
            "Hailun",
            "Harbin",
            "Hegang",
            "Heihe",
            "Hengshan",
            "Honggang",
            "Huanan",
            "Hulan",
            "Hulan Ergi",
            "Jiamusi",
            "Jidong",
            "Jixi",
            "Keshan",
            "Langxiang",
            "Lanxi",
            "Lingdong",
            "Linkou",
            "Lishu",
            "Longfeng",
            "Longjiang",
            "Mingshui",
            "Mishan",
            "Mudanjiang",
            "Nancha",
            "Nehe",
            "Nenjiang",
            "Nianzishan",
            "Ningan",
            "Qingan",
            "Qinggang",
            "Qiqihar",
            "Qitaihe",
            "Ranghulu",
            "Saertu",
            "Shangzhi",
            "Shanhetun",
            "Shuangcheng",
            "Shuangyashan",
            "Sifantan",
            "Suifenhe",
            "Suihua",
            "Suileng",
            "Tahe",
            "Taikang",
            "Tailai",
            "Tieli",
            "Wangkui",
            "Weihe",
            "Wuchang",
            "Xinglongzhen",
            "Xinqing",
            "Yian",
            "Yichun",
            "Yilan",
            "Youhao",
            "Zhaodong",
            "Zhaoyuan",
            "Zhaozhou"
          ],
          "StateName": "Heilongjiang"
        },
        {
          "Cities": [
            "Anyang",
            "Changying",
            "Dancheng",
            "Daokou",
            "Dengzhou",
            "Gongyi",
            "Gushi",
            "Hebi",
            "Huaidian",
            "Huangchuan",
            "Huangzhai",
            "Jiaozuo",
            "Jishui",
            "Kaifeng",
            "Liupen",
            "Luohe",
            "Luoyang",
            "Luyang",
            "Mengzhou",
            "Minggang",
            "Nandun",
            "Nanyang",
            "Pingdingshan",
            "Puyang",
            "Sanmenxia",
            "Shangqiu",
            "Tanghe",
            "Xiaoyi",
            "Xihua",
            "Xinxiang",
            "Xinyang",
            "Xinye",
            "Xixiang",
            "Xuanwu",
            "Xuchang",
            "Yigou",
            "Yima",
            "Yinzhuang",
            "Yunyang",
            "Yuzhou",
            "Zhecheng",
            "Zhengzhou",
            "Zhenping",
            "Zhoukou",
            "Zhumadian"
          ],
          "StateName": "Henan"
        },
        {
          "Cities": [
            "Anlu",
            "Baisha",
            "Buhe",
            "Caidian",
            "Caohe",
            "Danjiangkou",
            "Daye",
            "Duobao",
            "Enshi",
            "Ezhou",
            "Fengkou",
            "Guangshui",
            "Gucheng",
            "Hanchuan",
            "Hongan",
            "Honghu",
            "Huangmei",
            "Huangpi",
            "Huangshi",
            "Huangzhou",
            "Jingmen",
            "Jingzhou",
            "Laohekou",
            "Lichuan",
            "Macheng",
            "Nanhai",
            "Nanzhang",
            "Puqi",
            "Qianjiang",
            "Qingquan",
            "Qixingtai",
            "Rongcheng",
            "Shashi",
            "Shishou",
            "Shiyan",
            "Suizhou",
            "Tianmen",
            "Tongcheng",
            "Wuhan",
            "Wuxue",
            "Xiangfan",
            "Xianning",
            "Xiantao",
            "Xiaogan",
            "Xiaoxita",
            "Xiaxindian",
            "Xihe",
            "Xinpu",
            "Xinshi",
            "Xinzhou",
            "Yichang",
            "Yicheng",
            "Yingcheng",
            "Yingzhong",
            "Zaoyang",
            "Zhengchang",
            "Zhicheng",
            "Zhifang",
            "Zhongxiang"
          ],
          "StateName": "Hubei"
        },
        {
          "Cities": [
            "Anjiang",
            "Anxiang",
            "Changde",
            "Changsha",
            "Chenzhou",
            "Dayong",
            "Hengyang",
            "Hongjiang",
            "Huaihua",
            "Jinshi",
            "Jishou",
            "Leiyang",
            "Lengshuijiang",
            "Lengshuitan",
            "Lianyuan",
            "Liling",
            "Liuyang",
            "Loudi",
            "Matian",
            "Nanzhou",
            "Ningxiang",
            "Qidong",
            "Qiyang",
            "Shaoyang",
            "Xiangtan",
            "Xiangxiang",
            "Xiangyin",
            "Xinhua",
            "Yiyang",
            "Yongfeng",
            "Yongzhou",
            "Yuanjiang",
            "Yueyang",
            "Zhuzhou"
          ],
          "StateName": "Hunan"
        },
        {
          "Cities": [
            "Baoying",
            "Changzhou",
            "Dachang",
            "Dafeng",
            "Danyang",
            "Dingshu",
            "Dongkan",
            "Dongtai",
            "Fengxian",
            "Gaogou",
            "Gaoyou",
            "Guiren",
            "Haian",
            "Haizhou",
            "Hede",
            "Huaicheng",
            "Huaiyin",
            "Huilong",
            "Hutang",
            "Jiangdu",
            "Jiangyan",
            "Jiangyin",
            "Jiangyuan",
            "Jianhu",
            "Jingcheng",
            "Jinsha",
            "Jintan",
            "Juegang",
            "Jurong",
            "Kunshan",
            "Lianyungang",
            "Liucheng",
            "Liyang",
            "Luodu",
            "Mudu",
            "Nanjing",
            "Nantong",
            "Pecheng",
            "Pukou",
            "Qidong",
            "Qinnan",
            "Qixia",
            "Rucheng",
            "Songling",
            "Sucheng",
            "Suicheng",
            "Suqian",
            "Suzhou",
            "Taicang",
            "Taixing",
            "Wujiang",
            "Wuxi",
            "Xiaolingwei",
            "Xiaoshi",
            "Xinan",
            "Xinpu",
            "Xuzhou",
            "Yancheng",
            "Yangshe",
            "Yangzhou",
            "Yizheng",
            "Yunhe",
            "Yunyang",
            "Yushan",
            "Zhangjiagang",
            "Zhangjiangang",
            "Zhaoyang",
            "Zhenjiang",
            "Zhongxing"
          ],
          "StateName": "Jiangsu"
        },
        {
          "Cities": [
            "Baoying",
            "Changzhou",
            "Dachang",
            "Dafeng",
            "Danyang",
            "Dingshu",
            "Dongkan",
            "Dongtai",
            "Fengxian",
            "Gaogou",
            "Gaoyou",
            "Guiren",
            "Haian",
            "Haizhou",
            "Hede",
            "Huaicheng",
            "Huaiyin",
            "Huilong",
            "Hutang",
            "Jiangdu",
            "Jiangyan",
            "Jiangyin",
            "Jiangyuan",
            "Jianhu",
            "Jingcheng",
            "Jinsha",
            "Jintan",
            "Juegang",
            "Jurong",
            "Kunshan",
            "Lianyungang",
            "Liucheng",
            "Liyang",
            "Luodu",
            "Mudu",
            "Nanjing",
            "Nantong",
            "Pecheng",
            "Pukou",
            "Qidong",
            "Qinnan",
            "Qixia",
            "Rucheng",
            "Songling",
            "Sucheng",
            "Suicheng",
            "Suqian",
            "Suzhou",
            "Taicang",
            "Taixing",
            "Wujiang",
            "Wuxi",
            "Xiaolingwei",
            "Xiaoshi",
            "Xinan",
            "Xinpu",
            "Xuzhou",
            "Yancheng",
            "Yangshe",
            "Yangzhou",
            "Yizheng",
            "Yunhe",
            "Yunyang",
            "Yushan",
            "Zhangjiagang",
            "Zhangjiangang",
            "Zhaoyang",
            "Zhenjiang",
            "Zhongxing"
          ],
          "StateName": "Jiangsu Sheng"
        },
        {
          "Cities": [
            "Fengxin",
            "Fenyi",
            "Ganzhou",
            "Jian",
            "Jiangguang",
            "Jingdezhen",
            "Jiujiang",
            "Leping",
            "Linchuan",
            "Nanchang",
            "Pingxiang",
            "Poyang",
            "Shangrao",
            "Xiangdong",
            "Xingan",
            "Xinjian",
            "Xinyu",
            "Xiongshi",
            "Yichun",
            "Yingtai",
            "Yingtan",
            "Zhangshui"
          ],
          "StateName": "Jiangxi"
        },
        {
          "Cities": [
            "Badaojiang",
            "Baicheng",
            "Baishishan",
            "Changchun",
            "Changling",
            "Chaoyang",
            "Daan",
            "Dashitou",
            "Dehui",
            "Dongchang",
            "Dongfeng",
            "Dunhua",
            "Erdaojiang",
            "Fuyu",
            "Gongzhuling",
            "Helong",
            "Hongmei",
            "Huadian",
            "Huangnihe",
            "Huinan",
            "Hunchun",
            "Jiaohe",
            "Jilin",
            "Jishu",
            "Jiutai",
            "Kaitong",
            "Kouqian",
            "Liaoyuan",
            "Linjiang",
            "Lishu",
            "Liuhe",
            "Longjing",
            "Meihekou",
            "Mingyue",
            "Nongan",
            "Panshi",
            "Pizhou",
            "Qianan",
            "Qianguo",
            "Sanchazi",
            "Shuangyang",
            "Shulan",
            "Siping",
            "Songjianghe",
            "Taonan",
            "Tumen",
            "Wangou",
            "Wangqing",
            "Xinglongshan",
            "Yanji",
            "Yantongshan",
            "Yushu",
            "Zhengjiatun",
            "Zhenlai"
          ],
          "StateName": "Jilin"
        },
        {
          "Cities": [
            "Anshan",
            "Beipiao",
            "Benxi",
            "Changtu",
            "Chaoyang",
            "Dalian",
            "Dalianwan",
            "Dalinghe",
            "Dandong",
            "Dashiqiao",
            "Dongling",
            "Fengcheng",
            "Fushun",
            "Fuxin",
            "Haicheng",
            "Heishan",
            "Huanren",
            "Huludao",
            "Hushitai",
            "Jinxi",
            "Jinzhou",
            "Jiupu",
            "Kaiyuan",
            "Kuandian",
            "Langtou",
            "Liaoyang",
            "Liaozhong",
            "Lingyuan",
            "Liuerbao",
            "Lushunkou",
            "Nantai",
            "Panjin",
            "Pulandian",
            "Shenyang",
            "Sujiatun",
            "Tieling",
            "Wafangdian",
            "Xiaoshi",
            "Xifeng",
            "Xinchengxi",
            "Xingcheng",
            "Xinmin",
            "Xiongyue",
            "Xiuyan",
            "Yebaishou",
            "Yingkou",
            "Yuhong",
            "Zhuanghe"
          ],
          "StateName": "Liaoning"
        },
        {
          "Cities": [
            "Anshan",
            "Beipiao",
            "Benxi",
            "Changtu",
            "Chaoyang",
            "Dalian",
            "Dalianwan",
            "Dalinghe",
            "Dandong",
            "Dashiqiao",
            "Dongling",
            "Fengcheng",
            "Fushun",
            "Fuxin",
            "Haicheng",
            "Heishan",
            "Huanren",
            "Huludao",
            "Hushitai",
            "Jinxi",
            "Jinzhou",
            "Jiupu",
            "Kaiyuan",
            "Kuandian",
            "Langtou",
            "Liaoyang",
            "Liaozhong",
            "Lingyuan",
            "Liuerbao",
            "Lushunkou",
            "Nantai",
            "Panjin",
            "Pulandian",
            "Shenyang",
            "Sujiatun",
            "Tieling",
            "Wafangdian",
            "Xiaoshi",
            "Xifeng",
            "Xinchengxi",
            "Xingcheng",
            "Xinmin",
            "Xiongyue",
            "Xiuyan",
            "Yebaishou",
            "Yingkou",
            "Yuhong",
            "Zhuanghe"
          ],
          "StateName": "Liaoning Sheng"
        },
        {
          "Cities": [],
          "StateName": "Nei Monggol"
        },
        {
          "Cities": [],
          "StateName": "Ningxia Hui"
        },
        {
          "Cities": [
            "Qiatou",
            "Xining"
          ],
          "StateName": "Qinghai"
        },
        {
          "Cities": [
            "Ankang",
            "Baoji",
            "Guozhen",
            "Hancheng",
            "Hanzhong",
            "Lishan",
            "Qili",
            "Tongchuan",
            "Weinan",
            "Xian",
            "Xianyang",
            "Yanan",
            "Yanliang",
            "Yulin",
            "Yuxia"
          ],
          "StateName": "Shaanxi"
        },
        {
          "Cities": [
            "Anqiu",
            "Bianzhuang",
            "Binzhou",
            "Boshan",
            "Boxing County",
            "Caocheng",
            "Changqing",
            "Chengyang",
            "Dengzhou",
            "Dezhou",
            "Dingtao",
            "Dongcun",
            "Dongdu",
            "Donge County",
            "Dongying",
            "Feicheng",
            "Fushan",
            "Gaomi",
            "Haiyang",
            "Hanting",
            "Hekou",
            "Heze",
            "Jiaonan",
            "Jiaozhou",
            "Jiehu",
            "Jimo",
            "Jinan",
            "Jining",
            "Juxian",
            "Juye",
            "Kunlun",
            "Laiwu",
            "Laiyang",
            "Laizhou",
            "Leling",
            "Liaocheng",
            "Licung",
            "Linqing",
            "Linqu",
            "Linshu",
            "Linyi",
            "Longkou",
            "Mengyin",
            "Mingshui",
            "Nanchou",
            "Nanding",
            "Nanma",
            "Ninghai",
            "Ningyang",
            "Pingdu",
            "Pingyi",
            "Pingyin",
            "Qingdao",
            "Qingzhou",
            "Qixia",
            "Qufu",
            "Rizhao",
            "Rongcheng",
            "Shancheng",
            "Shanting",
            "Shengzhuang",
            "Shenxian",
            "Shizilu",
            "Shouguang",
            "Shuiji",
            "Sishui",
            "Suozhen",
            "Taian",
            "Tancheng",
            "Taozhuang",
            "Tengzhou",
            "Weifang",
            "Weihai",
            "Wencheng",
            "Wendeng",
            "Wenshang",
            "Wudi",
            "Xiazhen",
            "Xincheng",
            "Xindian",
            "Xintai",
            "Yanggu",
            "Yangshan",
            "Yantai",
            "Yanzhou",
            "Yatou",
            "Yidu",
            "Yishui",
            "Yucheng",
            "Yuncheng",
            "Zaozhuang",
            "Zhangdian",
            "Zhangjiawa",
            "Zhangqiu",
            "Zhaocheng",
            "Zhoucheng",
            "Zhoucun",
            "Zhucheng",
            "Zhuwang",
            "Zicheng",
            "Zouping",
            "Zouxian"
          ],
          "StateName": "Shandong"
        },
        {
          "Cities": [
            "Anqiu",
            "Bianzhuang",
            "Binzhou",
            "Boshan",
            "Boxing County",
            "Caocheng",
            "Changqing",
            "Chengyang",
            "Dengzhou",
            "Dezhou",
            "Dingtao",
            "Dongcun",
            "Dongdu",
            "Donge County",
            "Dongying",
            "Feicheng",
            "Fushan",
            "Gaomi",
            "Haiyang",
            "Hanting",
            "Hekou",
            "Heze",
            "Jiaonan",
            "Jiaozhou",
            "Jiehu",
            "Jimo",
            "Jinan",
            "Jining",
            "Juxian",
            "Juye",
            "Kunlun",
            "Laiwu",
            "Laiyang",
            "Laizhou",
            "Leling",
            "Liaocheng",
            "Licung",
            "Linqing",
            "Linqu",
            "Linshu",
            "Linyi",
            "Longkou",
            "Mengyin",
            "Mingshui",
            "Nanchou",
            "Nanding",
            "Nanma",
            "Ninghai",
            "Ningyang",
            "Pingdu",
            "Pingyi",
            "Pingyin",
            "Qingdao",
            "Qingzhou",
            "Qixia",
            "Qufu",
            "Rizhao",
            "Rongcheng",
            "Shancheng",
            "Shanting",
            "Shengzhuang",
            "Shenxian",
            "Shizilu",
            "Shouguang",
            "Shuiji",
            "Sishui",
            "Suozhen",
            "Taian",
            "Tancheng",
            "Taozhuang",
            "Tengzhou",
            "Weifang",
            "Weihai",
            "Wencheng",
            "Wendeng",
            "Wenshang",
            "Wudi",
            "Xiazhen",
            "Xincheng",
            "Xindian",
            "Xintai",
            "Yanggu",
            "Yangshan",
            "Yantai",
            "Yanzhou",
            "Yatou",
            "Yidu",
            "Yishui",
            "Yucheng",
            "Yuncheng",
            "Zaozhuang",
            "Zhangdian",
            "Zhangjiawa",
            "Zhangqiu",
            "Zhaocheng",
            "Zhoucheng",
            "Zhoucun",
            "Zhucheng",
            "Zhuwang",
            "Zicheng",
            "Zouping",
            "Zouxian"
          ],
          "StateName": "Shandong Sheng"
        },
        {
          "Cities": [
            "Jiading",
            "Minhang",
            "Shanghai",
            "Songjiang",
            "Trencin"
          ],
          "StateName": "Shanghai"
        },
        {
          "Cities": [
            "Changzhi",
            "Datong",
            "Houma",
            "Jiexiu",
            "Jincheng",
            "Linfen",
            "Taiyuan",
            "Xian",
            "Xinzhi",
            "Xinzhou",
            "Yangquan",
            "Yuanping",
            "Yuci",
            "Yuncheng"
          ],
          "StateName": "Shanxi"
        },
        {
          "Cities": [
            "Anju",
            "Baoning",
            "Chengdu",
            "Daan",
            "Dawan",
            "Daxian",
            "Deyang",
            "Dujiangyan City",
            "Guangkou",
            "Guangyuan",
            "Guihu",
            "Heyang",
            "Huayang",
            "Jiancheng",
            "Jiangyou",
            "Jijiang",
            "Leshan",
            "Linqiong",
            "Luocheng",
            "Luzhou",
            "Mianyang",
            "Nanchong",
            "Nanlong",
            "Neijiang",
            "Panzhihua",
            "Shifang",
            "Suining",
            "Taihe",
            "Tianpeng",
            "Tongchuan",
            "Xichang",
            "Xunchang",
            "Yaan",
            "Yibin",
            "Yongchang",
            "Zhonglong",
            "Zigong",
            "Ziyang"
          ],
          "StateName": "Sichuan"
        },
        {
          "Cities": [
            "Beichen",
            "Gangdong",
            "Hangu",
            "Jinghai",
            "Nankai",
            "Tanggu",
            "Tianjin",
            "Xianshuigu",
            "Yangcun",
            "Yangliuqing"
          ],
          "StateName": "Tianjin"
        },
        {
          "Cities": [
            "Guiqing",
            "Jiulong",
            "Quanwan",
            "Saigong",
            "Shatin",
            "Taipo",
            "Tuanmun",
            "Xianggang",
            "Yuanlong"
          ],
          "StateName": "Xianggang"
        },
        {
          "Cities": [
            "Aksu",
            "Baijiantan",
            "Changji",
            "Dongshan",
            "Hami",
            "Hetian",
            "Karamay",
            "Kashi",
            "Korla",
            "Kuche",
            "Kuytun",
            "Shache",
            "Shihezi",
            "Shuimogou",
            "Toutunhe",
            "Urumqi",
            "Yining"
          ],
          "StateName": "Xinjiang"
        },
        {
          "Cities": [
            "Lasa"
          ],
          "StateName": "Xizang"
        },
        {
          "Cities": [
            "Dali",
            "Gejiu",
            "Haikou",
            "Heilin",
            "Jinma",
            "Kaihua",
            "Kaiyuan",
            "Kunming",
            "Lianran",
            "Longquan",
            "Lucheng",
            "Mabai",
            "Majie",
            "Miyang",
            "Qujing",
            "Rongcheng",
            "Simao",
            "Wufeng",
            "Yunjinghong",
            "Yuxi Municipal",
            "Zhaotong",
            "Zhenhai",
            "Zhongshu",
            "Zhoucheng",
            "Zhuocheng"
          ],
          "StateName": "Yunnan"
        },
        {
          "Cities": [
            "Aojiang",
            "Choucheng",
            "Cixi",
            "Daqiao",
            "Deqing",
            "Dinghai",
            "Dongyang",
            "Fuyang",
            "Haining",
            "Haiyan",
            "Hangzhou",
            "Huangyan",
            "Hushan",
            "Huzhou",
            "Jiaojiang",
            "Jiaxing",
            "Jinhua",
            "Jinxiang",
            "Kaihua",
            "Kunyang",
            "Lanxi",
            "Linan City",
            "Linhai",
            "Linping",
            "Lishui",
            "Liushi",
            "Ningbo",
            "Ninghai",
            "Pinghu",
            "Quzhou",
            "Ruian",
            "Shangyu",
            "Shaoxing",
            "Shenjiamen",
            "Taizhou City",
            "Tonglu",
            "Wenling",
            "Wenzhou",
            "Wuning",
            "Wuyi",
            "Xianju",
            "Xiaoshan",
            "Xiashi",
            "Xushan",
            "Yiwu",
            "Yongkang",
            "Yueqing",
            "Yuhuan",
            "Yuyao",
            "Zhejiang",
            "Zhenhai",
            "Zhicheng",
            "Zhuji",
            "fenghua",
            "jiashan"
          ],
          "StateName": "Zhejiang"
        },
        {
          "Cities": [
            "Aojiang",
            "Choucheng",
            "Cixi",
            "Daqiao",
            "Deqing",
            "Dinghai",
            "Dongyang",
            "Fuyang",
            "Haining",
            "Haiyan",
            "Hangzhou",
            "Huangyan",
            "Hushan",
            "Huzhou",
            "Jiaojiang",
            "Jiaxing",
            "Jinhua",
            "Jinxiang",
            "Kaihua",
            "Kunyang",
            "Lanxi",
            "Linan City",
            "Linhai",
            "Linping",
            "Lishui",
            "Liushi",
            "Ningbo",
            "Ninghai",
            "Pinghu",
            "Quzhou",
            "Ruian",
            "Shangyu",
            "Shaoxing",
            "Shenjiamen",
            "Taizhou City",
            "Tonglu",
            "Wenling",
            "Wenzhou",
            "Wuning",
            "Wuyi",
            "Xianju",
            "Xiaoshan",
            "Xiashi",
            "Xushan",
            "Yiwu",
            "Yongkang",
            "Yueqing",
            "Yuhuan",
            "Yuyao",
            "Zhejiang",
            "Zhenhai",
            "Zhicheng",
            "Zhuji",
            "fenghua",
            "jiashan"
          ],
          "StateName": "Zhejiang Sheng"
        }
      ],
      "CountryName": "China"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Christmas Island"
        }
      ],
      "CountryName": "Christmas Island"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Cocos (Keeling) Islands"
        }
      ],
      "CountryName": "Cocos (Keeling) Islands"
    },
    {
      "States": [
        {
          "Cities": [
            "Leticia",
            "Puerto Narino"
          ],
          "StateName": "Amazonas"
        },
        {
          "Cities": [
            "Abejorral",
            "Abriaqui",
            "Alejandria",
            "Amaga",
            "Amalfi",
            "Andes",
            "Angelopolis",
            "Angostura",
            "Anori",
            "Antioquia",
            "Anza",
            "Apartado",
            "Arboletes",
            "Argelia",
            "Armenia",
            "Barbosa",
            "Bello",
            "Belmira",
            "Betania",
            "Betulia",
            "Bolivar",
            "Briceno",
            "Buritica",
            "Caceres",
            "Caicedo",
            "Caldas",
            "Campamento",
            "Canasgordas",
            "Caracoli",
            "Caramanta",
            "Carepa",
            "Carmen de Viboral",
            "Carolina",
            "Caucasia",
            "Chigorodo",
            "Cisneros",
            "Cocorna",
            "Concepcion",
            "Concordia",
            "Copacabana",
            "Dabeiba",
            "Don Matias",
            "Ebejico",
            "El Bagre",
            "Entrerrios",
            "Envigado",
            "Fredonia",
            "Frontino",
            "Giraldo",
            "Girardota",
            "Gomez Plata",
            "Granada",
            "Guadalupe",
            "Guarne",
            "Guatape",
            "Heliconia",
            "Hispania",
            "Itagui",
            "Ituango",
            "Jardin",
            "Jerico",
            "La Ceja",
            "La Estrella",
            "La Pintada",
            "La Union",
            "Liborina",
            "Maceo",
            "Marinilla",
            "Medellin",
            "Montebello",
            "Murindo",
            "Mutata",
            "Narino",
            "Nechi",
            "Necocli",
            "Olaya",
            "Penol",
            "Peque",
            "Pueblorrico",
            "Puerto Berrio",
            "Puerto Nare",
            "Puerto Triunfo",
            "Remedios",
            "Retiro",
            "Rionegro",
            "Sabanalarga",
            "Sabaneta",
            "Salgar",
            "San Andres",
            "San Carlos",
            "San Francisco",
            "San Jeronimo",
            "San Jose de la Montana",
            "San Juan de Uraba",
            "San Luis",
            "San Pedro",
            "San Pedro de Uraba",
            "San Rafael",
            "San Roque",
            "San Vicente",
            "Santa Barbara",
            "Santa Rosa de Osos",
            "Santo Domingo",
            "Santuario",
            "Segovia",
            "Sonson",
            "Sopetran",
            "Tamesis",
            "Taraza",
            "Tarso",
            "Titiribi",
            "Toledo",
            "Turbo",
            "Uramita",
            "Urrao",
            "Valdivia",
            "Valparaiso",
            "Vegachi",
            "Venecia",
            "Vigia del Fuerte",
            "Yali",
            "Yarumal",
            "Yolombo",
            "Yondo",
            "Zaragoza"
          ],
          "StateName": "Antioquia"
        },
        {
          "Cities": [
            "Arauca",
            "Arauquita",
            "Cravo Norte",
            "Fortul",
            "Puerto Rondon",
            "Saravena",
            "Tame"
          ],
          "StateName": "Arauca"
        },
        {
          "Cities": [
            "Baranoa",
            "Barranquilla",
            "Campo de la Cruz",
            "Candelaria",
            "Galapa",
            "Juan de Acosta",
            "Luruaco",
            "Malambo",
            "Manati",
            "Palmar de Varela",
            "Piojo",
            "Polo Nuevo",
            "Ponedera",
            "Puerto Colombia",
            "Repelon",
            "Sabanagrande",
            "Sabanalarga",
            "Santa Lucia",
            "Santo Tomas",
            "Soledad",
            "Suan",
            "Tubara",
            "Usiacuri"
          ],
          "StateName": "Atlantico"
        },
        {
          "Cities": [
            "Bogota"
          ],
          "StateName": "Bogota"
        },
        {
          "Cities": [
            "Achi",
            "Altos del Rosario",
            "Arenal",
            "Arjona",
            "Arroyohondo",
            "Barranco de Loba",
            "Calamar",
            "Cantagallo",
            "Cartagena",
            "Cicuco",
            "Clemencia",
            "Cordoba",
            "El Carmen de Bolivar",
            "El Guamo",
            "El Penon",
            "Hatillo de Loba",
            "Magangue",
            "Mahates",
            "Margarita",
            "Maria la Baja",
            "Mompos",
            "Montecristo",
            "Morales",
            "Pinillos",
            "Regidor",
            "Rio Viejo",
            "San Cristobal",
            "San Estanislao",
            "San Fernando",
            "San Jacinto",
            "San Jacinto del Cauca",
            "San Juan Nepomuceno",
            "San Martin de Loba",
            "San Pablo",
            "Santa Catalina",
            "Santa Rosa",
            "Santa Rosa del Sur",
            "Simiti",
            "Soplaviento",
            "Talaigua Nuevo",
            "Tiquisio",
            "Turbaco",
            "Turbana",
            "Villanueva",
            "Zambrano"
          ],
          "StateName": "Bolivar"
        },
        {
          "Cities": [
            "Almeida",
            "Aquitania",
            "Arcabuco",
            "Belen",
            "Berbeo",
            "Beteitiva",
            "Boavita",
            "Boyaca",
            "Briceno",
            "Buenavista",
            "Busbanza",
            "Caldas",
            "Campohermoso",
            "Cerinza",
            "Chinavita",
            "Chiquinquira",
            "Chiquiza",
            "Chiscas",
            "Chita",
            "Chitaraque",
            "Chivata",
            "Chivor",
            "Cienega",
            "Combita",
            "Coper",
            "Corrales",
            "Covarachia",
            "Cubara",
            "Cucaita",
            "Cuitiva",
            "Duitama",
            "El Cocuy",
            "El Espino",
            "Firavitoba",
            "Floresta",
            "Gachantiva",
            "Gameza",
            "Garagoa",
            "Guacamayas",
            "Guateque",
            "Guayata",
            "Guican",
            "Iza",
            "Jenesano",
            "Jerico",
            "La Capilla",
            "La Uvita",
            "La Victoria",
            "Labranzagrande",
            "Leiva",
            "Macanal",
            "Maripi",
            "Miraflores",
            "Mongua",
            "Mongui",
            "Moniquira",
            "Motavita",
            "Muzo",
            "Nobsa",
            "Nuevo Colon",
            "Oicata",
            "Otanche",
            "Pachavita",
            "Paez",
            "Paipa",
            "Pajarito",
            "Panqueba",
            "Pauna",
            "Paya",
            "Paz del Rio",
            "Pesca",
            "Pisba",
            "Puerto Boyaca",
            "Quipama",
            "Ramiriqui",
            "Raquira",
            "Rondon",
            "Saboya",
            "Sachica",
            "Samaca",
            "San Eduardo",
            "San Jose de Pare",
            "San Luis de Gaceno",
            "San Mateo",
            "San Miguel de Sema",
            "San Pablo de Borbur",
            "Santa Maria",
            "Santa Rosa de Viterbo",
            "Santa Sofia",
            "Santana",
            "Sativanorte",
            "Sativasur",
            "Siachoque",
            "Soata",
            "Socha",
            "Socota",
            "Sogamoso",
            "Somondoco",
            "Sora",
            "Soraca",
            "Sotaquira",
            "Susacon",
            "Sutamarchan",
            "Sutatenza",
            "Tasco",
            "Tenza",
            "Tibana",
            "Tibasosa",
            "Tinjaca",
            "Tipacoque",
            "Toca",
            "Togui",
            "Topaga",
            "Tota",
            "Tunja",
            "Tunungua",
            "Turmeque",
            "Tuta",
            "Tutasa",
            "Umbita",
            "Ventaquemada",
            "Viracacha",
            "Zetaquira"
          ],
          "StateName": "Boyaca"
        },
        {
          "Cities": [
            "Aguadas",
            "Anserma",
            "Aranzazu",
            "Belalcazar",
            "Chinchina",
            "Filadelfia",
            "La Dorada",
            "La Merced",
            "Manizales",
            "Manzanares",
            "Marmato",
            "Marquetalia",
            "Marulanda",
            "Neira",
            "Norcasia",
            "Pacora",
            "Palestina",
            "Pensilvania",
            "Riosucio",
            "Risaralda",
            "Salamina",
            "Samana",
            "San Jose",
            "Supia",
            "Victoria",
            "Villamaria",
            "Viterbo"
          ],
          "StateName": "Caldas"
        },
        {
          "Cities": [
            "Albania",
            "Belen Andaquies",
            "Cartagena del Chaira",
            "Curillo",
            "El Doncello",
            "El Paujil",
            "Florencia",
            "La Montanita",
            "Milan",
            "Morelia",
            "Puerto Rico",
            "San Jose de Fragua",
            "San Vicente del Caguan",
            "Solano",
            "Solita",
            "Valparaiso"
          ],
          "StateName": "Caqueta"
        },
        {
          "Cities": [
            "Aguazul",
            "Chameza",
            "Hato Corozal",
            "La Salina",
            "Mani",
            "Monterrey",
            "Nunchia",
            "Orocue",
            "Paz de Ariporo",
            "Pore",
            "Recetor",
            "Sabanalarga",
            "Sacama",
            "San Luis de Palenque",
            "Tamara",
            "Tauramena",
            "Trinidad",
            "Villanueva",
            "Yopal"
          ],
          "StateName": "Casanare"
        },
        {
          "Cities": [
            "Almaguer",
            "Argelia",
            "Balboa",
            "Bolivar",
            "Buenos Aires",
            "Cajibio",
            "Caldono",
            "Caloto",
            "Corinto",
            "El Bordo",
            "El Tambo",
            "Florencia",
            "Guapi",
            "Inza",
            "Jambalo",
            "La Sierra",
            "La Vega",
            "Lopez",
            "Mercaderes",
            "Miranda",
            "Morales",
            "Padilla",
            "Paez",
            "Piamonte",
            "Piendamo",
            "Popayan",
            "Puerto Tejada",
            "Purace",
            "Rosas",
            "San Sebastian",
            "Santa Rosa",
            "Santander de Quilichao",
            "Silvia",
            "Sotara",
            "Suarez",
            "Sucre",
            "Timbio",
            "Timbiqui",
            "Toribio",
            "Totoro",
            "Villa Rica"
          ],
          "StateName": "Cauca"
        },
        {
          "Cities": [
            "Aguachica",
            "Agustin Codazzi",
            "Astrea",
            "Becerril",
            "Bosconia",
            "Chimichagua",
            "Chiriguana",
            "Curumani",
            "El Copey",
            "El Paso",
            "Gamarra",
            "Gonzalez",
            "La Gloria",
            "La Jagua Ibirico",
            "Manaure",
            "Pailitas",
            "Pelaya",
            "Pueblo Bello",
            "Rio de Oro",
            "Robles la Paz",
            "San Alberto",
            "San Diego",
            "San Martin",
            "Tamalameque",
            "Valledupar"
          ],
          "StateName": "Cesar"
        },
        {
          "Cities": [
            "Acandi",
            "Alto Baudo",
            "Atrato",
            "Bagado",
            "Bahia Solano",
            "Bajo Baudo",
            "Bojaya",
            "Canton de San Pablo",
            "Carmen del Darien",
            "Certegui",
            "Condoto",
            "El Carmen",
            "Istmina",
            "Jurado",
            "Litoral del San Juan",
            "Lloro",
            "Medio Atrato",
            "Medio Baudo",
            "Medio San Juan",
            "Novita",
            "Nuqui",
            "Quibdo",
            "Rio Iro",
            "Rio Quito",
            "Riosucio",
            "San Jose del Palmar",
            "Sipi",
            "Tado",
            "Unguia",
            "Union Panamericana"
          ],
          "StateName": "Choco"
        },
        {
          "Cities": [
            "Ayapel",
            "Buenavista",
            "Canalete",
            "Cerete",
            "Chima",
            "Chinu",
            "Cienaga de Oro",
            "Cotorra",
            "La Apartada",
            "Lorica",
            "Los Cordobas",
            "Momil",
            "Monitos",
            "Montelibano",
            "Monteria",
            "Planeta Rica",
            "Pueblo Nuevo",
            "Puerto Escondido",
            "Puerto Libertador",
            "Purisima",
            "Sahagun",
            "San Andres Sotavento",
            "San Antero",
            "San Bernardo Viento",
            "San Carlos",
            "San Pelayo",
            "Tierralta",
            "Valencia"
          ],
          "StateName": "Cordoba"
        },
        {
          "Cities": [
            "Agua de Dios",
            "Alban",
            "Anapoima",
            "Anolaima",
            "Arbelaez",
            "Beltran",
            "Bituima",
            "Bojaca",
            "Cabrera",
            "Cachipay",
            "Cajica",
            "Caparrapi",
            "Caqueza",
            "Carmen de Carupa",
            "Chaguani",
            "Chia",
            "Chipaque",
            "Choachi",
            "Choconta",
            "Cogua",
            "Cota",
            "Cucunuba",
            "El Colegio",
            "El Penon",
            "El Rosal",
            "Facatativa",
            "Fomeque",
            "Fosca",
            "Funza",
            "Fuquene",
            "Fusagasuga",
            "Gachala",
            "Gachancipa",
            "Gacheta",
            "Gama",
            "Girardot",
            "Granada",
            "Guacheta",
            "Guaduas",
            "Guasca",
            "Guataqui",
            "Guatavita",
            "Guayabal de Siquima",
            "Guayabetal",
            "Gutierrez",
            "Jerusalen",
            "Junin",
            "La Calera",
            "La Mesa",
            "La Palma",
            "La Pena",
            "La Vega",
            "Lenguazaque",
            "Macheta",
            "Madrid",
            "Manta",
            "Medina",
            "Mosquera",
            "Narino",
            "Nemocon",
            "Nilo",
            "Nimaima",
            "Nocaima",
            "Ospina Perez",
            "Pacho",
            "Paime",
            "Pandi",
            "Paratebueno",
            "Pasca",
            "Puerto Salgar",
            "Puli",
            "Quebradanegra",
            "Quetame",
            "Quipile",
            "Rafael Reyes",
            "Ricaurte",
            "San Antonio del Tequendama",
            "San Bernardo",
            "San Cayetano",
            "San Francisco",
            "San Juan de Rioseco",
            "Sasaima",
            "Sesquile",
            "Sibate",
            "Silvania",
            "Simijaca",
            "Soacha",
            "Sopo",
            "Subachoque",
            "Suesca",
            "Supata",
            "Susa",
            "Sutatausa",
            "Tabio",
            "Tausa",
            "Tena",
            "Tenjo",
            "Tibacuy",
            "Tibirita",
            "Tocaima",
            "Tocancipa",
            "Topaipi",
            "Ubala",
            "Ubaque",
            "Ubate",
            "Une",
            "Utica",
            "Vergara",
            "Viani",
            "Villagomez",
            "Villapinzon",
            "Villeta",
            "Viota",
            "Yacopi",
            "Zipacon",
            "Zipaquira"
          ],
          "StateName": "Cundinamarca"
        },
        {
          "Cities": [
            "Inirida"
          ],
          "StateName": "Guainia"
        },
        {
          "Cities": [
            "Calamar",
            "El Retorno",
            "Miraflores",
            "San Jose del Guaviare"
          ],
          "StateName": "Guaviare"
        },
        {
          "Cities": [
            "Acevedo",
            "Agrado",
            "Aipe",
            "Algeciras",
            "Altamira",
            "Baraya",
            "Campoalegre",
            "Colombia",
            "Elias",
            "Garzon",
            "Gigante",
            "Guadalupe",
            "Hobo",
            "Iquira",
            "Isnos",
            "La Argentina",
            "La Plata",
            "Nataga",
            "Neiva",
            "Oporapa",
            "Paicol",
            "Palermo",
            "Palestina",
            "Pital",
            "Pitalito",
            "Rivera",
            "Saladoblanco",
            "San Agustin",
            "Santa Maria",
            "Suaza",
            "Tarqui",
            "Tello",
            "Teruel",
            "Tesalia",
            "Timana",
            "Villavieja",
            "Yaguara"
          ],
          "StateName": "Huila"
        },
        {
          "Cities": [],
          "StateName": "La Guajira"
        },
        {
          "Cities": [
            "Algarrobo",
            "Aracataca",
            "Ariguani",
            "Cerro San Antonio",
            "Chivolo",
            "Cienaga",
            "Concordia",
            "El Banco",
            "El Pinon",
            "El Reten",
            "Fundacion",
            "Guamal",
            "Nueva Granada",
            "Pedraza",
            "Pijino del Carmen",
            "Pivijay",
            "Plato",
            "Puebloviejo",
            "Remolino",
            "Sabanas de San Angel",
            "Salamina",
            "San Sebastian",
            "San Zenon",
            "Santa Ana",
            "Santa Barbara de Pinto",
            "Santa Marta",
            "Sitionuevo",
            "Tenerife",
            "Zapayan",
            "Zona Bananera"
          ],
          "StateName": "Magdalena"
        },
        {
          "Cities": [
            "Acacias",
            "Barranca de Upia",
            "Cabuyaro",
            "Castilla la Nueva",
            "Cubarral",
            "Cumaral",
            "El Calvario",
            "El Castillo",
            "El Dorado",
            "Fuente de Oro",
            "Granada",
            "Guamal",
            "La Macarena",
            "La Uribe",
            "Lejanias",
            "Mapiripan",
            "Mesetas",
            "Puerto Concordia",
            "Puerto Gaitan",
            "Puerto Lleras",
            "Puerto Lopez",
            "Puerto Rico",
            "Restrepo",
            "San Carlos Guaroa",
            "San Juan de Arama",
            "San Juanito",
            "San Martin",
            "Villavicencio",
            "Vista Hermosa"
          ],
          "StateName": "Meta"
        },
        {
          "Cities": [
            "Alban",
            "Aldana",
            "Ancuya",
            "Arboleda",
            "Barbacoas",
            "Belen",
            "Buesaco",
            "Chachagui",
            "Colon",
            "Consaca",
            "Contadero",
            "Cordoba",
            "Cuaspud",
            "Cumbal",
            "Cumbitara",
            "El Charco",
            "El Penol",
            "El Rosario",
            "El Tablon",
            "El Tambo",
            "Funes",
            "Guachucal",
            "Guaitarilla",
            "Gualmatan",
            "Iles",
            "Imues",
            "Ipiales",
            "La Cruz",
            "La Florida",
            "La Llanada",
            "La Tola",
            "La Union",
            "Leiva",
            "Linares",
            "Los Andes",
            "Magui",
            "Mallama",
            "Mosquera",
            "Narino",
            "Olaya Herrera",
            "Ospina",
            "Pasto",
            "Pizarro",
            "Policarpa",
            "Potosi",
            "Providencia",
            "Puerres",
            "Pupiales",
            "Ricaurte",
            "Roberto Payan",
            "Samaniego",
            "San Bernardo",
            "San Lorenzo",
            "San Pablo",
            "San Pedro de Cartago",
            "Sandona",
            "Santa Barbara",
            "Santacruz",
            "Sapuyes",
            "Taminango",
            "Tangua",
            "Tumaco",
            "Tuquerres",
            "Yacuanquer"
          ],
          "StateName": "Narino"
        },
        {
          "Cities": [],
          "StateName": "Norte de Santander"
        },
        {
          "Cities": [
            "Colon",
            "Mocoa",
            "Orito",
            "Puerto Asis",
            "Puerto Caycedo",
            "Puerto Guzman",
            "Puerto Leguizamo",
            "San Francisco",
            "San Miguel",
            "Santiago",
            "Sibundoy",
            "Valle del Guamuez",
            "Villagarzon"
          ],
          "StateName": "Putumayo"
        },
        {
          "Cities": [
            "Armenia",
            "Buenavista",
            "Calarca",
            "Circasia",
            "Cordoba",
            "Filandia",
            "Genova",
            "La Tebaida",
            "Montenegro",
            "Pijao",
            "Quimbaya",
            "Salento"
          ],
          "StateName": "Quindio"
        },
        {
          "Cities": [
            "Apia",
            "Balboa",
            "Belen de Umbria",
            "Dos Quebradas",
            "Guatica",
            "La Celia",
            "La Virginia",
            "Marsella",
            "Mistrato",
            "Pereira",
            "Pueblo Rico",
            "Quinchia",
            "Santa Rosa de Cabal",
            "Santuario"
          ],
          "StateName": "Risaralda"
        },
        {
          "Cities": [],
          "StateName": "San Andres y Providencia"
        },
        {
          "Cities": [
            "Aguada",
            "Albania",
            "Aratoca",
            "Barbosa",
            "Barichara",
            "Barrancabermeja",
            "Betulia",
            "Bolivar",
            "Bucaramanga",
            "Cabrera",
            "Capitanejo",
            "Carcasi",
            "Cepita",
            "Cerrito",
            "Charala",
            "Charta",
            "Chima",
            "Chipata",
            "Cimitarra",
            "Concepcion",
            "Confines",
            "Contratacion",
            "Coromoro",
            "Curiti",
            "El Carmen",
            "El Guacamayo",
            "El Penon",
            "El Playon",
            "Encino",
            "Enciso",
            "Florian",
            "Floridablanca",
            "Galan",
            "Gambita",
            "Giron",
            "Guaca",
            "Guadalupe",
            "Guapota",
            "Guavata",
            "Guepsa",
            "Hato",
            "Jesus Maria",
            "Jordan",
            "La Belleza",
            "La Paz",
            "Landazuri",
            "Lebrija",
            "Los Santos",
            "Macaravita",
            "Malaga",
            "Matanza",
            "Mogotes",
            "Molagavita",
            "Ocamonte",
            "Oiba",
            "Onzaga",
            "Palmar",
            "Palmas del Socorro",
            "Paramo",
            "Piedecuesta",
            "Pinchote",
            "Puente Nacional",
            "Puerto Parra",
            "Puerto Wilches",
            "Rionegro",
            "Sabana de Torres",
            "San Andres",
            "San Benito",
            "San Gil",
            "San Joaquin",
            "San Jose de Miranda",
            "San Miguel",
            "San Vicente de Chucuri",
            "Santa Barbara",
            "Santa Helena",
            "Simacota",
            "Socorro",
            "Suaita",
            "Sucre",
            "Surata",
            "Tona",
            "Valle San Jose",
            "Velez",
            "Vetas",
            "Villanueva",
            "Zapatoca"
          ],
          "StateName": "Santander"
        },
        {
          "Cities": [
            "Buenavista",
            "Caimito",
            "Chalan",
            "Coloso",
            "Corozal",
            "El Roble",
            "Galeras",
            "Guaranda",
            "La Union",
            "Los Palmitos",
            "Majagual",
            "Morroa",
            "Ovejas",
            "Palmito",
            "Sampues",
            "San Benito Abad",
            "San Juan de Betulia",
            "San Marcos",
            "San Onofre",
            "San Pedro",
            "Since",
            "Sincelejo",
            "Sucre",
            "Tolu",
            "Toluviejo"
          ],
          "StateName": "Sucre"
        },
        {
          "Cities": [
            "Alpujarra",
            "Alvarado",
            "Ambalema",
            "Anzoategui",
            "Ataco",
            "Cajamarca",
            "Carmen de Apicala",
            "Casabianca",
            "Chaparral",
            "Coello",
            "Coyaima",
            "Cunday",
            "Dolores",
            "Espinal",
            "Falan",
            "Flandes",
            "Fresno",
            "Guamo",
            "Guayabal",
            "Herveo",
            "Honda",
            "Ibague",
            "Icononzo",
            "Lerida",
            "Libano",
            "Mariquita",
            "Melgar",
            "Murillo",
            "Natagaima",
            "Ortega",
            "Palocabildo",
            "Piedras",
            "Planadas",
            "Prado",
            "Purificacion",
            "Rioblanco",
            "Roncesvalles",
            "Rovira",
            "Saldana",
            "San Antonio",
            "San Luis",
            "Santa Isabel",
            "Suarez",
            "Valle de San Juan",
            "Venadillo",
            "Villahermosa",
            "Villarrica"
          ],
          "StateName": "Tolima"
        },
        {
          "Cities": [],
          "StateName": "Valle del Cauca"
        },
        {
          "Cities": [
            "Acaricuara",
            "Mitu",
            "Papunaua",
            "Taraira",
            "Villa Fatima",
            "Yavarate"
          ],
          "StateName": "Vaupes"
        },
        {
          "Cities": [
            "Cumaribo",
            "La Primavera",
            "Puerto Carreno",
            "Santa Rosalia"
          ],
          "StateName": "Vichada"
        }
      ],
      "CountryName": "Colombia"
    },
    {
      "States": [
        {
          "Cities": [
            "Fomboni"
          ],
          "StateName": "Mwali"
        },
        {
          "Cities": [
            "Mitsamiouli",
            "Moroni"
          ],
          "StateName": "Njazidja"
        },
        {
          "Cities": [
            "Domoni",
            "Mutsamudu"
          ],
          "StateName": "Nzwani"
        }
      ],
      "CountryName": "Comoros"
    },
    {
      "States": [
        {
          "Cities": [
            "Loudima",
            "Madingou",
            "Nkayi"
          ],
          "StateName": "Bouenza"
        },
        {
          "Cities": [
            "Brazzaville"
          ],
          "StateName": "Brazzaville"
        },
        {
          "Cities": [
            "Ewo",
            "Kelle",
            "Makoua",
            "Mossaka",
            "Owando"
          ],
          "StateName": "Cuvette"
        },
        {
          "Cities": [
            "Loandjili",
            "Ngamaba-Mfilou",
            "Pointe Noire"
          ],
          "StateName": "Kouilou"
        },
        {
          "Cities": [
            "Sibiti",
            "Zanaga"
          ],
          "StateName": "Lekoumou"
        },
        {
          "Cities": [
            "Dongou",
            "Epena",
            "Impfondo"
          ],
          "StateName": "Likouala"
        },
        {
          "Cities": [
            "Kibangou",
            "Loubomo",
            "Matsanga",
            "Mossendjo"
          ],
          "StateName": "Niari"
        },
        {
          "Cities": [
            "Djambala",
            "Gamboma"
          ],
          "StateName": "Plateaux"
        },
        {
          "Cities": [
            "Boko",
            "Kinkala",
            "Mindouli"
          ],
          "StateName": "Pool"
        },
        {
          "Cities": [
            "Ikelemba",
            "Ouesso",
            "Sembe",
            "Souanke"
          ],
          "StateName": "Sangha"
        }
      ],
      "CountryName": "Congo"
    },
    {
      "States": [
        {
          "Cities": [
            "Bandundu",
            "Bolobo",
            "Bulungu",
            "Gungu",
            "Idiofa",
            "Inongo",
            "Kahemba",
            "Kasongo-Lunda",
            "Kenge",
            "Kikwit",
            "Kiri",
            "Kutu",
            "Lusanga",
            "Mangai",
            "Mushie",
            "Nioki"
          ],
          "StateName": "Bandundu"
        },
        {
          "Cities": [
            "Boma",
            "Kasangulu",
            "Kimpese",
            "Madimba",
            "Matadi",
            "Mbanza-Ngungu",
            "Muanda",
            "Tshela"
          ],
          "StateName": "Bas-Congo"
        },
        {
          "Cities": [
            "Basankusu",
            "Binga",
            "Bodalangi",
            "Boende",
            "Bongandanga",
            "Bosobolo",
            "Bumba",
            "Businga",
            "Gbadolite",
            "Gemena",
            "Ikela",
            "Libenge",
            "Lisala",
            "Makanza",
            "Mbandaka",
            "Mobayi-Mbongo",
            "Yakoma",
            "Yandongi",
            "Yumbi",
            "Zongo"
          ],
          "StateName": "Equateur"
        },
        {
          "Cities": [
            "Aba",
            "Aketi",
            "Bafwasende",
            "Banalia",
            "Basoko",
            "Bondo",
            "Bunia",
            "Buta",
            "Djugu",
            "Faradje",
            "Gwane",
            "Isiro",
            "Itoko",
            "Kisangani",
            "Mambasa",
            "Mongbwalu",
            "Niangara",
            "Poko",
            "Simba",
            "Titule",
            "Ubundu",
            "Wamba",
            "Watsa",
            "Yangambi"
          ],
          "StateName": "Haut-Congo"
        },
        {
          "Cities": [
            "Demba",
            "Dibaya",
            "Ilebo",
            "Kananga",
            "Kazumba",
            "Luebo",
            "Mweka",
            "Tshikapa"
          ],
          "StateName": "Kasai-Occidental"
        },
        {
          "Cities": [
            "Gandajika",
            "Kabinda",
            "Katako-Kombe",
            "Kole",
            "Lodja",
            "Lubao",
            "Lubefu",
            "Lusambo",
            "Mbuji-Mayi",
            "Mwene-Ditu",
            "Tshilenge",
            "Tshofa"
          ],
          "StateName": "Kasai-Oriental"
        },
        {
          "Cities": [
            "Bukama",
            "Dilolo",
            "Kabalo",
            "Kalemie",
            "Kambove",
            "Kamina",
            "Kaniama",
            "Kikondjo",
            "Kipushi",
            "Kolwezi",
            "Kongolo",
            "Le Marinel",
            "Likasi",
            "Lubudi",
            "Lubumbashi",
            "Malemba-Nkulu",
            "Manono",
            "Moba",
            "Mulongo",
            "Mwanza",
            "Nyunzu",
            "Sakania",
            "Shinkolobwe"
          ],
          "StateName": "Katanga"
        },
        {
          "Cities": [
            "Kinshasa"
          ],
          "StateName": "Kinshasa"
        },
        {
          "Cities": [
            "Kalima",
            "Kasongo",
            "Kindu"
          ],
          "StateName": "Maniema"
        },
        {
          "Cities": [
            "Beni",
            "Butembo",
            "Goma"
          ],
          "StateName": "Nord-Kivu"
        },
        {
          "Cities": [
            "Bukavu",
            "Kabare",
            "Kama",
            "Kampene",
            "Kibombo",
            "Uvira"
          ],
          "StateName": "Sud-Kivu"
        }
      ],
      "CountryName": "Congo The Democratic Republic Of The"
    },
    {
      "States": [
        {
          "Cities": [
            "Amuri"
          ],
          "StateName": "Aitutaki"
        },
        {
          "Cities": [
            "Atiu"
          ],
          "StateName": "Atiu"
        },
        {
          "Cities": [
            "Mangaia"
          ],
          "StateName": "Mangaia"
        },
        {
          "Cities": [
            "Tauhunu"
          ],
          "StateName": "Manihiki"
        },
        {
          "Cities": [
            "Mauke"
          ],
          "StateName": "Mauke"
        },
        {
          "Cities": [
            "Mitiaro"
          ],
          "StateName": "Mitiaro"
        },
        {
          "Cities": [
            "Nassau"
          ],
          "StateName": "Nassau"
        },
        {
          "Cities": [
            "Roto"
          ],
          "StateName": "Pukapuka"
        },
        {
          "Cities": [
            "Rakahanga"
          ],
          "StateName": "Rakahanga"
        },
        {
          "Cities": [
            "Avarua"
          ],
          "StateName": "Rarotonga"
        },
        {
          "Cities": [
            "Omoka"
          ],
          "StateName": "Tongareva"
        }
      ],
      "CountryName": "Cook Islands"
    },
    {
      "States": [
        {
          "Cities": [
            "Alajuela",
            "Atenas",
            "Bijagua",
            "Buenos Aires",
            "Carrillos",
            "Desemparados",
            "Dos Rios",
            "Esquipulas",
            "Florencia",
            "Fortuna",
            "Grecia",
            "Guacimo",
            "Laguna",
            "Los Chiles",
            "Mastate",
            "Naranjo",
            "Orotina",
            "Palmares",
            "Piedades Norte",
            "Pital",
            "Pocosol",
            "Quesada",
            "Rio Segundo",
            "Sabanilla",
            "San Antonio",
            "San Jose",
            "San Juan",
            "San Mateo",
            "San Pedro",
            "San Rafael",
            "San Ramon",
            "San Roque",
            "Santiago",
            "Sarchi Norte",
            "Sarchi Sur",
            "Tigra",
            "Turricares",
            "Upala",
            "Venado",
            "Zarcero"
          ],
          "StateName": "Alajuela"
        },
        {
          "Cities": [
            "Aguacaliente",
            "Capellades",
            "Carmen",
            "Cartago",
            "Concepcion",
            "Cot",
            "Dulce Nombre",
            "El Tejar",
            "Guadalupe",
            "Juan Vinas",
            "La Suiza",
            "Orosi",
            "Pacayas",
            "Paraiso",
            "Pejibaye",
            "San Diego",
            "San Isidro",
            "San Juan",
            "San Nicolas",
            "San Rafael",
            "San Ramon",
            "Tierra Blanca",
            "Tobosi",
            "Tres Rios",
            "Tucurrique",
            "Turrialba"
          ],
          "StateName": "Cartago"
        },
        {
          "Cities": [
            "Bagaces",
            "Belen",
            "Canas",
            "Filadeldia",
            "Fortuna",
            "Hojancha",
            "Juntas",
            "La Cruz",
            "Liberia",
            "Mogote",
            "Nandayure",
            "Nicoya",
            "Samara",
            "Santa Cruz",
            "Sardinal",
            "Tilaran"
          ],
          "StateName": "Guanacaste"
        },
        {
          "Cities": [
            "Angeles",
            "Asuncion",
            "Barrantes",
            "Barva",
            "Heredia",
            "Horquetas",
            "Llorente",
            "Mercedes",
            "Puerto Viejo",
            "Rivera",
            "San Antonio",
            "San Francisco",
            "San Isidro",
            "San Joaquin",
            "San Jose",
            "San Josecito",
            "San Juan",
            "San Miguel",
            "San Pablo",
            "San Pedro",
            "San Rafael",
            "San Roque",
            "San Vicente",
            "Santa Barbara",
            "Santa Lucia",
            "Santa Rosa",
            "Santiago",
            "Santo Domingo",
            "Santo Tomas",
            "Ulloa"
          ],
          "StateName": "Heredia"
        },
        {
          "Cities": [
            "Batan",
            "Cahuita",
            "Cariari",
            "Guacimo",
            "Guapiles",
            "Jimenez",
            "Limon",
            "Matina",
            "Pocora",
            "Rita",
            "Roxana",
            "Siquirres",
            "Sixaola",
            "Valle la Estrella"
          ],
          "StateName": "Limon"
        },
        {
          "Cities": [
            "Buenos Aires",
            "Canoas",
            "Chacarita",
            "Corredor",
            "Esparta",
            "Espiritu Santo",
            "Golfito",
            "Guaycara",
            "Jaco",
            "La Cuesta",
            "Macacona",
            "Manzanillo",
            "Miramar",
            "Palmar",
            "Paquera",
            "Parrita",
            "Puerto Cortes",
            "Puerto Jimenez",
            "Puntarenas",
            "Quepos",
            "San Vito"
          ],
          "StateName": "Puntarenas"
        },
        {
          "Cities": [],
          "StateName": "San Jose"
        }
      ],
      "CountryName": "Costa Rica"
    },
    {
      "States": [
        {
          "Cities": [
            "Marcory"
          ],
          "StateName": "Abidjan"
        },
        {
          "Cities": [
            "Adzope",
            "Affery",
            "Agboville",
            "Akoupe",
            "Rubino"
          ],
          "StateName": "Agneby"
        },
        {
          "Cities": [
            "Touba"
          ],
          "StateName": "Bafing"
        },
        {
          "Cities": [
            "Bako",
            "Odienne"
          ],
          "StateName": "Denguele"
        },
        {
          "Cities": [],
          "StateName": "Dix-huit Montagnes"
        },
        {
          "Cities": [
            "Gagnoa",
            "Guiberoua",
            "Hire",
            "Ndouci",
            "Oume",
            "Ouragahio"
          ],
          "StateName": "Fromager"
        },
        {
          "Cities": [
            "Daloa",
            "Issia",
            "Vavoua"
          ],
          "StateName": "Haut-Sassandra"
        },
        {
          "Cities": [
            "Ndiekro",
            "Tiebissou",
            "Toumodi",
            "Yamoussoukro"
          ],
          "StateName": "Lacs"
        },
        {
          "Cities": [
            "Abidjan",
            "Alepe",
            "Anyama",
            "Bingerville",
            "Dabou",
            "Grand-Lahou",
            "Jacqueville",
            "Tiassale"
          ],
          "StateName": "Lagunes"
        },
        {
          "Cities": [
            "Bouafle",
            "Sinfra",
            "Zuenoula"
          ],
          "StateName": "Marahoue"
        },
        {
          "Cities": [
            "Duekoue",
            "Guiglo",
            "Toulepleu"
          ],
          "StateName": "Moyen-Cavally"
        },
        {
          "Cities": [
            "Abengourou",
            "Agnibilekrou"
          ],
          "StateName": "Moyen-Comoe"
        },
        {
          "Cities": [
            "Arrah",
            "Bocanda",
            "Bongouanou",
            "Daoukro",
            "Dimbokro",
            "M'bahiakro",
            "Mbatto",
            "N'zi-Comoe"
          ],
          "StateName": "N'zi-Comoe"
        },
        {
          "Cities": [
            "San-Pedro",
            "Sassandra",
            "Soubre",
            "Tabou"
          ],
          "StateName": "Sassandra"
        },
        {
          "Cities": [
            "Boundiali",
            "Dikodougou",
            "Ferkessedougou",
            "Korhogo",
            "Tingrela"
          ],
          "StateName": "Savanes"
        },
        {
          "Cities": [
            "Divo",
            "Fresco",
            "Lakota"
          ],
          "StateName": "Sud-Bandama"
        },
        {
          "Cities": [
            "Aboisso",
            "Adiake",
            "Ayame",
            "Bonoua",
            "Grand Bassam"
          ],
          "StateName": "Sud-Comoe"
        },
        {
          "Cities": [],
          "StateName": "Vallee du Bandama"
        },
        {
          "Cities": [
            "Kani",
            "Mankono",
            "Seguela"
          ],
          "StateName": "Worodougou"
        },
        {
          "Cities": [
            "Bondoukou",
            "Bouna",
            "Tanda"
          ],
          "StateName": "Zanzan"
        }
      ],
      "CountryName": "Cote D'Ivoire (Ivory Coast)"
    },
    {
      "States": [
        {
          "Cities": [
            "Bjelovar",
            "Brezovac",
            "Chazma",
            "Daruvar",
            "Daruvarski Brestovac",
            "Dezhanovac",
            "Dhulovac",
            "Gareshnica",
            "Gareshnichki Brestovac",
            "Grubishno Polje",
            "Gudovac",
            "Hercegovac",
            "Ivanska",
            "Klokochevac",
            "Konchanica",
            "Predavac",
            "Rovishce",
            "Shandrovac",
            "Sirach",
            "Trojstveni Markovac",
            "Velika Pisanica",
            "Veliki Grdhevac",
            "Veliki Zdenci",
            "Veliko Trojstvo",
            "Zhdralovi"
          ],
          "StateName": "Bjelovar-Bilogora"
        },
        {
          "Cities": [
            "Blato",
            "Cavtat",
            "Chibacha",
            "Chilipi",
            "Dubrovnik",
            "Komin",
            "Korchula",
            "Lumbarda",
            "Metkovic",
            "Mlini",
            "Mokoshica",
            "Nova Mokoshica",
            "Opuzen",
            "Orebic",
            "Otrich-Seoci",
            "Ploche",
            "Smokvica",
            "Stashevica",
            "Vela Luka",
            "Zaton",
            "Zhrnovo"
          ],
          "StateName": "Dubrovnik-Neretva"
        },
        {
          "Cities": [],
          "StateName": "Grad Zagreb"
        },
        {
          "Cities": [
            "Bale",
            "Banjole",
            "Brtonigla",
            "Buje",
            "Buzet",
            "Fazhana",
            "Funtana",
            "Galizhana",
            "Labin",
            "Lizhnjan",
            "Marchana",
            "Medulin",
            "Novigrad",
            "Pazin",
            "Porech",
            "Premantura",
            "Pula",
            "Rabac",
            "Rasha",
            "Rovinj",
            "Sveti Petar u Shumi",
            "Tar",
            "Umag",
            "Vinezh",
            "Vodnjan",
            "Vrsar"
          ],
          "StateName": "Istra"
        },
        {
          "Cities": [
            "Cerovac Vukmansichki",
            "Draganic",
            "Duga Resa",
            "Josipdol",
            "Karlovac",
            "Mrezhnichki Varosh",
            "Ogulin",
            "Oshtarije",
            "Ozalj",
            "Plashki",
            "Slunj",
            "Vojnic"
          ],
          "StateName": "Karlovac"
        },
        {
          "Cities": [
            "Dhelekovec",
            "Dhurdhevac",
            "Drnje",
            "Ferdinandovac",
            "Glogovac",
            "Gola",
            "Hlebine",
            "Kalinovac",
            "Kloshtar Podravski",
            "Koprivnica",
            "Koprivnichki Bregi",
            "Koprivnichki Ivanec",
            "Krizhevci",
            "Legrad",
            "Molve",
            "Novigrad Podravski",
            "Novo Virje",
            "Peteranec",
            "Podravske Sesvete",
            "Rasinja",
            "Reka",
            "Sigetec",
            "Starigrad",
            "Sveti Ivan Zhabno",
            "Trema",
            "Virje"
          ],
          "StateName": "Koprivnica-Krizhevci"
        },
        {
          "Cities": [
            "Andrashevec",
            "Bedekovchina",
            "Dhurmanec",
            "Donja Pachetina",
            "Donja Shemnica",
            "Donja Stubica",
            "Dubrovchan",
            "Gornja Stubica",
            "Hum na Sutli",
            "Klanjec",
            "Konjishchina",
            "Krapina",
            "Krapinske Toplice",
            "Laz Bistrichki",
            "Marija Bistrica",
            "Mihovljan",
            "Oroslavje",
            "Podgorje Bistrichko",
            "Poznanovec",
            "Pregrada",
            "Radoboj",
            "Shkaricevo",
            "Shpichkovina",
            "Stubichke Toplice",
            "Sveti Krizh Zachretje",
            "Veliko Trgovishce",
            "Zabok",
            "Zlatar",
            "Zlatar-Bistrica"
          ],
          "StateName": "Krapina-Zagorje"
        },
        {
          "Cities": [
            "Brinje",
            "Donji Lapac",
            "Gospic",
            "Korenica",
            "Lichki Osik",
            "Lichko Leshce",
            "Novalja",
            "Otochac",
            "Perushic",
            "Prozor",
            "Senj"
          ],
          "StateName": "Lika-Senj"
        },
        {
          "Cities": [
            "Belica",
            "Chakovec",
            "Cirkovljan",
            "Dekanovec",
            "Domashinec",
            "Donja Dubrava",
            "Donji Kraljevec",
            "Donji Vidovec",
            "Drzhimurec",
            "Dunjkovec",
            "Gardinovec",
            "Gorichan",
            "Gornji Hrashcan",
            "Hodoshan",
            "Ivanovec",
            "Kotoriba",
            "Kurshanec",
            "Lopatinec",
            "Machkovec",
            "Mala Subotica",
            "Mihovljan",
            "Mursko Sredishce",
            "Nedelishce",
            "Novakovec",
            "Novo Selo Rok",
            "Orehovica",
            "Palovec",
            "Peklenica",
            "Podturen",
            "Prelog",
            "Pribislavec",
            "Pushcine",
            "Savska Ves",
            "Selnica",
            "Shenkovec",
            "Strahoninec",
            "Sveta Marija",
            "Trnovec",
            "Vratishinec",
            "Zasadbreg"
          ],
          "StateName": "Medhimurje"
        },
        {
          "Cities": [],
          "StateName": "Medimurska Zupanija"
        },
        {
          "Cities": [
            "Antunovac",
            "Batina",
            "Beli Manastir",
            "Belishce",
            "Beljevina",
            "Bijelo Brdo",
            "Bilje",
            "Bistrinci",
            "Bizovac",
            "Branjin Vrh",
            "Brijeshce",
            "Brijest",
            "Ceminac",
            "Chepin",
            "Crnkovci",
            "Dalj",
            "Darda",
            "Dhakovo",
            "Dhurdhenovac",
            "Donja Motichina",
            "Donji Miholac",
            "Erdut",
            "Ernestinovo",
            "Ferichanci",
            "Gashinci",
            "Gorjani",
            "Grabovac",
            "Ivanovac",
            "Ivanovci Gorjanski",
            "Jagodnjak",
            "Jelisavac",
            "Josipovac",
            "Josipovac Punitovachki",
            "Karanac",
            "Keshinci",
            "Knezhevi Vinogradi",
            "Knezhevo",
            "Koritna",
            "Koshka",
            "Kushevac",
            "Ladimirevci",
            "Laslovo",
            "Lug",
            "Marijanci",
            "Markovac Nashichki",
            "Martin",
            "Mece",
            "Moslavina Podravska",
            "Nashice",
            "Osijek",
            "Ovchara",
            "Petlovac",
            "Petrijevci",
            "Pishkorevci",
            "Podgorach",
            "Popvac",
            "Rakitovica",
            "Sarvash",
            "Satnica Dhakovachka",
            "Selci Dhakovacki",
            "Semeljci",
            "Shiroko Polje",
            "Strizivojna",
            "Sveti Dhuradh",
            "Tenja",
            "Valpovo",
            "Velimirovac",
            "Viljevo",
            "Vishkovci",
            "Vishnjevac",
            "Vladislavci",
            "Vuka",
            "Vukojevci",
            "Zmajevac",
            "Zoljan"
          ],
          "StateName": "Osijek-Baranja"
        },
        {
          "Cities": [],
          "StateName": "Osjecko-Baranjska Zupanija"
        },
        {
          "Cities": [
            "Badljevina",
            "Brodski Drenovac",
            "Dervishaga",
            "Gradac",
            "Jakshic",
            "Kaptol",
            "Kutjevo",
            "Lipik",
            "Pakrac",
            "Pleternica",
            "Pozhega",
            "Prekopkra",
            "Trenkovo",
            "Velika",
            "Vetovo",
            "Vidovci"
          ],
          "StateName": "Pozhega-Slavonija"
        },
        {
          "Cities": [],
          "StateName": "Primorje-Gorski Kotar"
        },
        {
          "Cities": [
            "Bilice",
            "Brodarica",
            "Drnish",
            "Dubrava kod Shibenika",
            "Grebashtica",
            "Jezera",
            "Kistanje",
            "Knin",
            "Kovachic",
            "Murter",
            "Pirovac",
            "Primoshten",
            "Rogoznica",
            "Shibenik",
            "Skradin",
            "Tisno",
            "Tribunj",
            "Vodice",
            "Vrpolje",
            "Zaton"
          ],
          "StateName": "Shibenik-Knin"
        },
        {
          "Cities": [
            "Brestacha",
            "Brochice",
            "Budashevo",
            "Donja Grachenica",
            "Dvor",
            "Glina",
            "Gornja Grachenica",
            "Gornja Jelenska",
            "Greda",
            "Gvozd",
            "Hrastelnica",
            "Hrvatska Dubica",
            "Hrvatska Kostajnica",
            "Husain",
            "Ilova",
            "Kutina",
            "Lekenik",
            "Lipovljani",
            "Moshchenica",
            "Novska",
            "Odra Sisachka",
            "Osekovo",
            "Peshcenica",
            "Petrinja",
            "Popovacha",
            "Potok",
            "Rajic",
            "Repushnica",
            "Sisak",
            "Staro Prachno",
            "Sunja",
            "Topolovac",
            "Voloder"
          ],
          "StateName": "Sisak-Moslavina"
        },
        {
          "Cities": [],
          "StateName": "Slavonski Brod-Posavina"
        },
        {
          "Cities": [
            "Bashka Voda",
            "Bol",
            "Brela",
            "Brnaze",
            "Cista Velika",
            "Donji Prolozhac",
            "Donji Vinjani",
            "Duce",
            "Dugi Rat",
            "Dugopolje",
            "Gala",
            "Glavice",
            "Glavina Donja",
            "Gornji Vincjani",
            "Gradac",
            "Grubine",
            "Hrvace",
            "Hvar",
            "Imotski",
            "Jelsa",
            "Jesenice",
            "Kamen",
            "Kashtel Gambelovac",
            "Kashtel Gomilica",
            "Kashtel Lukshic",
            "Kashtel Novi",
            "Kashtel Shtafilic",
            "Kashtel Stari",
            "Kashtel Sucurac",
            "Katuni",
            "Klis",
            "Komizha",
            "Koshute",
            "Lovrec",
            "Makarska",
            "Marina",
            "Mastrinka",
            "Milna",
            "Mravince",
            "Neoric",
            "Obrovac Sinjski",
            "Okrug Gornji",
            "Omish",
            "Otok",
            "Podgora",
            "Podstrana",
            "Poljica",
            "Postira",
            "Postranje",
            "Potravlje",
            "Primorski Dolac",
            "Puchishca",
            "Ruda",
            "Runovic",
            "Seget Donji",
            "Seget Vranjica",
            "Selca",
            "Sinj",
            "Slatine",
            "Solin",
            "Split",
            "Srinjine",
            "Stari Grad",
            "Stobrech",
            "Supetar",
            "Trilj",
            "Trogir",
            "Tuchepi",
            "Turjaci",
            "Vinishce",
            "Vis",
            "Vranjic",
            "Vrgorac",
            "Vrlika",
            "Zagvozd",
            "Zhrnovnica",
            "Zmijavci"
          ],
          "StateName": "Split-Dalmacija"
        },
        {
          "Cities": [
            "Bedenec",
            "Beletinec",
            "Beretinec",
            "Breznica",
            "Chreshnjevo",
            "Donja Voca",
            "Donje Ladanje",
            "Gornje Ladanje",
            "Gornje Vratno",
            "Gornji Kneginec",
            "Gornji Kucan",
            "Hrashcica",
            "Hrastovsko",
            "Hrzhenica",
            "Ivanec",
            "Jalkovec",
            "Jalzhabet",
            "Jerovec",
            "Klenovnik",
            "Kljuch",
            "Kucan Marof",
            "Lepoglava",
            "Ljubeshcica",
            "Ludbreg",
            "Madzharevo",
            "Mali Bukovec",
            "Nedeljanec",
            "Nova Ves Petrijanec",
            "Novi Marof",
            "Petrijanec",
            "Podevchevo",
            "Presechno",
            "Remetinec",
            "Selnik",
            "Shemovec",
            "Srachinec",
            "Sveti Petar",
            "Svibovec Podravski",
            "Trnovec",
            "Turchin",
            "Tuzhno",
            "Varazhdin",
            "Varazhdin Breg",
            "Varazhdinske Toplice",
            "Vidovec",
            "Vinica",
            "Zavrshje Podbelsko",
            "Zharovnica"
          ],
          "StateName": "Varazhdin"
        },
        {
          "Cities": [
            "Borova",
            "Busetina",
            "Cabuna",
            "Chachinci",
            "Gradina",
            "Korija",
            "Mikleush",
            "Milanovac",
            "Nova Bukovica",
            "Orahovica",
            "Pitomacha",
            "Podgorje",
            "Rezovac",
            "Shpishic Bukovica",
            "Slatina",
            "Suhopolje",
            "Turanovac",
            "Virovitica",
            "Vocin",
            "Zdenci"
          ],
          "StateName": "Virovitica-Podravina"
        },
        {
          "Cities": [
            "Andrijashevci",
            "Antin",
            "Babina Greda",
            "Bapska",
            "Bobota",
            "Bogdanovci",
            "Borovo",
            "Boshnjaci",
            "Brshadin",
            "Ceric",
            "Cerna",
            "Drenovci",
            "Gradishte",
            "Gunja",
            "Ilacha",
            "Ilok",
            "Ivankovo",
            "Jarmina",
            "Komletinci",
            "Lipovac",
            "Lovas",
            "Markushica",
            "Mirkovci",
            "Negoslavci",
            "Nijemci",
            "Novi Jankovci",
            "Nushtar",
            "Otok",
            "Petrovci",
            "Posavski Podgajci",
            "Privlaka",
            "Rachinovci",
            "Rajevo Selo",
            "Retkovci",
            "Rokovci",
            "Sharengrad",
            "Shishkovci",
            "Shtitar",
            "Slakovci",
            "Soljani",
            "Sotin",
            "Stari Jankovci",
            "Stari Mikanovci",
            "Tordinci",
            "Tovarnik",
            "Trpinja",
            "Vinkovci",
            "Vodhinci",
            "Vrbanja",
            "Vukovar",
            "Zhupanja"
          ],
          "StateName": "Vukovar-Srijem"
        },
        {
          "Cities": [
            "Benkovac",
            "Bibinje",
            "Biograd na Moru",
            "Debeljak",
            "Galovac",
            "Gorica",
            "Gornji Karin",
            "Grachac",
            "Jasenice",
            "Kali",
            "Krushevo",
            "Nin",
            "Obrovac",
            "Pag",
            "Pakoshtane",
            "Polacha",
            "Polichnik",
            "Poljica",
            "Posedarje",
            "Preko",
            "Pridraga",
            "Privlaka",
            "Razhanac",
            "Shkabrnja",
            "Slivnica",
            "Starigrad",
            "Sukoshan",
            "Sveti Filip i Jakov",
            "Turanj",
            "Ugljan",
            "Vir",
            "Vrsi",
            "Zadar",
            "Zemunik Donji"
          ],
          "StateName": "Zadar"
        },
        {
          "Cities": [
            "Bestovje",
            "Bishkupec Zelinski",
            "Brckovljani",
            "Brdovec",
            "Bregana",
            "Brezje",
            "Bushevec",
            "Celine",
            "Domaslovec",
            "Donja Bistra",
            "Donja Kupchina",
            "Donja Lomnica",
            "Donja Zdenchina",
            "Donji Desinec",
            "Donji Stupnik",
            "Dubrava",
            "Dugo Selo",
            "Gornja Bistra",
            "Gornji Laduch",
            "Gornji Stupnik",
            "Grachec",
            "Gradici",
            "Ivan Bistranski",
            "Ivanic-Grad",
            "Jablanovec",
            "Jakovlje",
            "Jastrebarsko",
            "Kerestinec",
            "Klincha Sela",
            "Kloshtar Ivanic",
            "Kozinshchak",
            "Krizh",
            "Kuche",
            "Kupinec",
            "Lonjica",
            "Luka",
            "Lukarishce",
            "Lukavec",
            "Lupoglav",
            "Michevec",
            "Mraclin",
            "Novaki",
            "Novo Chiche",
            "Novoselec",
            "Oborovo Bistranski",
            "Oreshje",
            "Pojatno",
            "Poljanica Bistranska",
            "Prigorje Brdovechko",
            "Rakitje",
            "Rakov Potok",
            "Rude",
            "Samobor",
            "Strmec",
            "Sveta Nedelja",
            "Sveti Ivan Zelina",
            "Turopolje",
            "Velika Gorica",
            "Velika Mlaka",
            "Velika Ostrna",
            "Vrbovec",
            "Vukovina",
            "Zapreshic",
            "Zdenci Brdovechki"
          ],
          "StateName": "Zagreb"
        }
      ],
      "CountryName": "Croatia (Hrvatska)"
    },
    {
      "States": [
        {
          "Cities": [
            "Camaguey",
            "Caney",
            "Carlos Manuel de Cespedes",
            "Esmeralda",
            "Florida",
            "Guaimaro",
            "Minas",
            "Nuevitas",
            "Santa Cruz del Sur",
            "Sibanicu",
            "Vertientes"
          ],
          "StateName": "Camaguey"
        },
        {
          "Cities": [],
          "StateName": "Ciego de Avila"
        },
        {
          "Cities": [
            "Cienfuegos",
            "Cruces",
            "Cumanayagua",
            "Palmira",
            "Rodas"
          ],
          "StateName": "Cienfuegos"
        },
        {
          "Cities": [],
          "StateName": "Ciudad de la Habana"
        },
        {
          "Cities": [
            "Bayamo",
            "Campechuela",
            "Guisa",
            "Jiguani",
            "Manzanillo",
            "Media Luna",
            "Niquero",
            "Pilon",
            "Rio Cauto",
            "Yara"
          ],
          "StateName": "Granma"
        },
        {
          "Cities": [
            "Baracoa",
            "Guantanamo",
            "Yateras"
          ],
          "StateName": "Guantanamo"
        },
        {
          "Cities": [
            "Havana"
          ],
          "StateName": "Habana"
        },
        {
          "Cities": [
            "Antilla",
            "Baguanos",
            "Banes",
            "Cacocum",
            "Cauto Cristo",
            "Cueto",
            "Gibara",
            "Holguin",
            "Jobabo",
            "Moa",
            "Sagua de Tanamo"
          ],
          "StateName": "Holguin"
        },
        {
          "Cities": [],
          "StateName": "Isla de la Juventud"
        },
        {
          "Cities": [],
          "StateName": "La Habana"
        },
        {
          "Cities": [],
          "StateName": "Las Tunas"
        },
        {
          "Cities": [
            "Abreus",
            "Agramonte",
            "Aguacate",
            "Aguada de Pasajeros",
            "Alacranes",
            "Bolondron",
            "Calimete",
            "Cardenas",
            "Carlos Rojas",
            "Colon",
            "Corralillo",
            "Jaguey Grande",
            "Jovellanos",
            "Juan Gualberto Gomez",
            "Los Arabos",
            "Manguito",
            "Marti",
            "Matanzas",
            "Maximo Gomez",
            "Pedro Betancourt",
            "Perico",
            "Union de Reyes",
            "Varadero"
          ],
          "StateName": "Matanzas"
        },
        {
          "Cities": [],
          "StateName": "Pinar del Rio"
        },
        {
          "Cities": [],
          "StateName": "Sancti Spiritus"
        },
        {
          "Cities": [],
          "StateName": "Santiago de Cuba"
        },
        {
          "Cities": [],
          "StateName": "Villa Clara"
        }
      ],
      "CountryName": "Cuba"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Government controlled area"
        },
        {
          "Cities": [
            "Limassol"
          ],
          "StateName": "Limassol"
        },
        {
          "Cities": [],
          "StateName": "Nicosia District"
        },
        {
          "Cities": [
            "Paphos"
          ],
          "StateName": "Paphos"
        },
        {
          "Cities": [],
          "StateName": "Turkish controlled area"
        }
      ],
      "CountryName": "Cyprus"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Central Bohemian"
        },
        {
          "Cities": [
            "Frycovice"
          ],
          "StateName": "Frycovice"
        },
        {
          "Cities": [],
          "StateName": "Jihocesky Kraj"
        },
        {
          "Cities": [
            "Bechyne",
            "Blatna",
            "Cheske Budejovice",
            "Chesky Krumlov",
            "Dachice",
            "Jindrichuv Hradec",
            "Kaplice",
            "Milevsko",
            "Pisek",
            "Prachatice",
            "Protivin",
            "Sezimovo Usti",
            "Sobeslav",
            "Strakonice",
            "Tabor",
            "Trebon",
            "Tyn nad Vltavou",
            "Veseli nad Luzhnici",
            "Vimperk",
            "Vodnany"
          ],
          "StateName": "Jihochesky"
        },
        {
          "Cities": [
            "Adamov",
            "Blansko",
            "Boskovice",
            "Breclav",
            "Brno",
            "Buchovice",
            "Dubnany",
            "Hodonin",
            "Hrusky",
            "Hustopeche",
            "Ivanchice",
            "Kurim",
            "Kyjov",
            "Letovice",
            "Mikulov",
            "Moravsky Krumlov",
            "Namesht' nad Oslavou",
            "Rosice",
            "Shlapanice",
            "Slavkov u Brna",
            "Tishnov",
            "Vyshkov",
            "Znojmo"
          ],
          "StateName": "Jihomoravsky"
        },
        {
          "Cities": [
            "Ash",
            "Bozicany",
            "Cheb",
            "Chodov",
            "Frantishkovy Lazne",
            "Horni Slavkov",
            "Karlovy Vary",
            "Kraslice",
            "Kynshperk nad Ohri",
            "Marianske Lazne",
            "Nejdek",
            "Ostrov",
            "Sokolov"
          ],
          "StateName": "Karlovarsky"
        },
        {
          "Cities": [
            "Klecany"
          ],
          "StateName": "Klecany"
        },
        {
          "Cities": [
            "Broumov",
            "Cherveny Kostelec",
            "Cheska Skalice",
            "Chlumec nad Cidlinou",
            "Dobrushka",
            "Dvur Kralove",
            "Habartov",
            "Holice",
            "Horice",
            "Hostinne",
            "Hradec Kralove",
            "Hronov",
            "Jaromer",
            "Jichin",
            "Kostelec nad Orlici",
            "Nachod",
            "Nova Paka",
            "Nove Mesto nad Metuji",
            "Novy Bydzhov",
            "Rychnov nad Knezhnou",
            "Trebechovice pod Orebem",
            "Trutnov",
            "Tynishte nad Orlici",
            "Upice",
            "Vrchlabi"
          ],
          "StateName": "Kralovehradecky"
        },
        {
          "Cities": [
            "Ceska Lipa",
            "Cheska Kamenice",
            "Cheska Lipa",
            "Chrastava",
            "Doksy",
            "Frydlant",
            "Hradek",
            "Jablonec",
            "Jilemnice",
            "Liberec",
            "Lomnice nad Popelkou",
            "Mimon",
            "Novy Bor",
            "Semily",
            "Tanvald",
            "Turnov",
            "Zhelezny Brod"
          ],
          "StateName": "Liberecky"
        },
        {
          "Cities": [
            "Lipov"
          ],
          "StateName": "Lipov"
        },
        {
          "Cities": [
            "Bilovec",
            "Bohumin",
            "Bruntal",
            "Chesky Teshin",
            "Frenshtat",
            "Frydek-Mistek",
            "Frydlant nad Ostravici",
            "Fulnek",
            "Havirov",
            "Hluchin",
            "Hradec nad Moravice",
            "Jablunkov",
            "Karvina",
            "Koprivnice",
            "Kravare",
            "Krnov",
            "Novy Jichin",
            "Odry",
            "Opava",
            "Orlova",
            "Ostrava",
            "Petrvald",
            "Pribor",
            "Rychvald",
            "Rymarov",
            "Shenov",
            "Studenka",
            "Trinec",
            "Vitkov",
            "Vratimov",
            "Vrbno pod Pradedem"
          ],
          "StateName": "Moravskoslezsky"
        },
        {
          "Cities": [
            "Hranice",
            "Jesenik",
            "Kojetin",
            "Lipnik nad Becvou",
            "Litovel",
            "Mohelnice",
            "Olomouc",
            "Prerov",
            "Prostejov",
            "Shternberk",
            "Shumperk",
            "Unichov",
            "Zabreh"
          ],
          "StateName": "Olomoucky"
        },
        {
          "Cities": [
            "Hranice",
            "Jesenik",
            "Kojetin",
            "Lipnik nad Becvou",
            "Litovel",
            "Mohelnice",
            "Olomouc",
            "Prerov",
            "Prostejov",
            "Shternberk",
            "Shumperk",
            "Unichov",
            "Zabreh"
          ],
          "StateName": "Olomoucky Kraj"
        },
        {
          "Cities": [
            "Cheska Trebova",
            "Chocen",
            "Chrudim",
            "Chvaletice",
            "Hermanuv Mestec",
            "Hlinsko",
            "Lanshkroun",
            "Letohrad",
            "Litomyshl",
            "Moravska Trebova",
            "Pardubice",
            "Polichka",
            "Policka",
            "Prelouch",
            "Skutech",
            "Svitavy",
            "Usti nad Orlici",
            "Vysoke Myto",
            "Zhamberk"
          ],
          "StateName": "Pardubicky"
        },
        {
          "Cities": [
            "Dobrany",
            "Domazhlice",
            "Horazhd'ovice",
            "Horshovky Tyn",
            "Kdyne",
            "Klatovy",
            "Nyrany",
            "Nyrsko",
            "Plana",
            "Plzen",
            "Preshtice",
            "Radnice",
            "Rokycany",
            "Stribro",
            "Sushice",
            "Tachov"
          ],
          "StateName": "Plzensky"
        },
        {
          "Cities": [
            "Prague",
            "Praha"
          ],
          "StateName": "Praha"
        },
        {
          "Cities": [
            "Rajhrad"
          ],
          "StateName": "Rajhrad"
        },
        {
          "Cities": [
            "Smirice"
          ],
          "StateName": "Smirice"
        },
        {
          "Cities": [],
          "StateName": "South Moravian"
        },
        {
          "Cities": [],
          "StateName": "Straz nad Nisou"
        },
        {
          "Cities": [
            "Benatky nad Jizerou",
            "Beneshov",
            "Beroun",
            "Brandys nad Labem-Stara Bolesl",
            "Chaslav",
            "Chavaletice",
            "Chelakovice",
            "Chesky Brod",
            "Dobrish",
            "Horovice",
            "Kladno",
            "Kolin",
            "Kralupy nad Vltavou",
            "Kutna Hora",
            "Lysa nad Labem",
            "Melnik",
            "Mlada Boleslav",
            "Mnichovo Hradishte",
            "Neratovice",
            "Nove Strasheci",
            "Nymburk",
            "Podebrady",
            "Pribram",
            "Rakovnik",
            "Richany",
            "Rousinov",
            "Roztoky",
            "Sedlcany",
            "Slany",
            "Stochov",
            "Vlashim",
            "Zruch nad Sazavou"
          ],
          "StateName": "Stredochesky"
        },
        {
          "Cities": [
            "Unicov"
          ],
          "StateName": "Unicov"
        },
        {
          "Cities": [
            "Bilina",
            "Chomutov",
            "Dechin",
            "Dubi",
            "Duchcov",
            "Jilove",
            "Jirkov",
            "Kadan",
            "Klasterec nad Ohri",
            "Krupka",
            "Litomerice",
            "Litvinov",
            "Louny",
            "Lovosice",
            "Mezibori",
            "Most",
            "Osek",
            "Podborany",
            "Roudnice",
            "Rumburk",
            "Shluknov",
            "Shteti",
            "Teplice",
            "Usti",
            "Varnsdorf",
            "Zatec"
          ],
          "StateName": "Ustecky"
        },
        {
          "Cities": [
            "Valletta"
          ],
          "StateName": "Valletta"
        },
        {
          "Cities": [
            "Velesin"
          ],
          "StateName": "Velesin"
        },
        {
          "Cities": [
            "Bystrice nad Pernshtejnem",
            "Chotebor",
            "Havlichkuv Brod",
            "Humpolec",
            "Jihlava",
            "Ledech",
            "Moravske Budejovice",
            "Nove Mesto na Morave",
            "Okrisky",
            "Pacov",
            "Pelhrimov",
            "Polna",
            "Svetla nad Sazavou",
            "Telch",
            "Trebich",
            "Tresht'",
            "Velke Mezirichi",
            "Zhd'ar"
          ],
          "StateName": "Vysochina"
        },
        {
          "Cities": [
            "Brumov",
            "Bystrice pod Hostynem",
            "Chropyne",
            "Holeshov",
            "Hulin",
            "Kromerizh",
            "Kunovice",
            "Napajedla",
            "Otrokovice",
            "Rozhnov",
            "Roznov pod Radhostem",
            "Slavicin",
            "Slusovice",
            "Stare Mesto",
            "Strazhnice",
            "Uherske Hradishte",
            "Uhersky Brod",
            "Valashske Klobouky",
            "Valashske Mezirichi",
            "Veseli nad Moravou",
            "Vsetin",
            "Zborovice",
            "Zlin"
          ],
          "StateName": "Zlinsky"
        }
      ],
      "CountryName": "Czech Republic"
    },
    {
      "States": [
        {
          "Cities": [
            "Aarhus",
            "Allingabro",
            "Arhus",
            "Assentoft",
            "Auning",
            "Beder",
            "Brabrand",
            "Ebeltoft",
            "Framlev",
            "Galten",
            "Grenaa",
            "Hadsten",
            "Hammel",
            "Hinnerup",
            "Hjortshoj",
            "Horning",
            "Hornslet",
            "Kolt",
            "Langa",
            "Logten",
            "Lystrup",
            "Malling",
            "Mariager",
            "Marslet",
            "Odder",
            "Randers",
            "Risskov",
            "Ronde",
            "Ry",
            "Ryomgard",
            "Sabro",
            "Silkeborg",
            "Skanderborg",
            "Skovby",
            "Soften",
            "Solbjerg",
            "Spentrup",
            "Stavtrup",
            "Stilling",
            "Svejbak",
            "Tranbjerg",
            "Trige",
            "Virklund"
          ],
          "StateName": "Arhus"
        },
        {
          "Cities": [
            "Aakirkeby",
            "Allinge-Sandvig",
            "Nexo",
            "Ronne"
          ],
          "StateName": "Bornholm"
        },
        {
          "Cities": [
            "Allerod",
            "Birkerod",
            "Blovstrod",
            "Espergarde",
            "Farum",
            "Fredensborg",
            "Frederikssund",
            "Frederiksvark",
            "Ganlose",
            "Gilleleje",
            "Grasted",
            "Hellebak",
            "Helsinge",
            "Helsingor",
            "Hillerod",
            "Hornbak",
            "Horsholm",
            "Humlebak",
            "Hundested",
            "Jagerspris",
            "Kvistgaard",
            "Lillerod",
            "Liseleje",
            "Lynge",
            "Niva",
            "Nodebo",
            "Olstykke",
            "Skibby",
            "Slangerup",
            "Stavnsholt",
            "Stenlose",
            "Valby",
            "Vekso"
          ],
          "StateName": "Frederiksborg"
        },
        {
          "Cities": [
            "Aarup",
            "Arslev",
            "Assens",
            "Bellinge",
            "Blommenslyst",
            "Bogense",
            "Brenderup",
            "Broby",
            "Bullerup",
            "Ejby",
            "Faaborg",
            "Glamsbjerg",
            "Haarby",
            "Hojby",
            "Kerteminde",
            "Langeskov",
            "Marstal",
            "Middelfart",
            "Munkebo",
            "Neder Holluf",
            "Norre Aaby",
            "Nyborg",
            "Odense",
            "Otterup",
            "Ringe",
            "Rudkobing",
            "Sankt Klemens",
            "Seden",
            "Sonderso",
            "Stige",
            "Strib",
            "Svendborg",
            "Thuro",
            "Tommerup",
            "Ullerslev",
            "Vindeby",
            "Vissenbjerg"
          ],
          "StateName": "Fyn"
        },
        {
          "Cities": [
            "Ballerup",
            "Brondby",
            "Stenlose",
            "Vallensbaek"
          ],
          "StateName": "Hovedstaden"
        },
        {
          "Cities": [
            "Dragor",
            "Flong",
            "Gentofte",
            "Glostrup",
            "Herlev",
            "Hvidovre",
            "Ishoj",
            "Kastrup",
            "Lyngby",
            "Malov",
            "Smorumnedre",
            "Taastrup",
            "Trorod",
            "Vanlose",
            "Varlose"
          ],
          "StateName": "Kobenhavn"
        },
        {
          "Cities": [],
          "StateName": "Kobenhavns Amt"
        },
        {
          "Cities": [],
          "StateName": "Kobenhavns Kommune"
        },
        {
          "Cities": [
            "Aabybro",
            "Aalborg",
            "Aars",
            "Arden",
            "Bindslev",
            "Bronderslev",
            "Brovst",
            "Dronninglund",
            "Farso",
            "Fjerritslev",
            "Frederikshavn",
            "Frejlev",
            "Gistrup",
            "Gorlose",
            "Hadsund",
            "Hals",
            "Hirtshals",
            "Hjallerup",
            "Hjorring",
            "Hobro",
            "Kas",
            "Klarup",
            "Logstor",
            "Nibe",
            "Norresundby",
            "NÃÆÃÆÃâÃÂ¸rresundby",
            "Pandrup",
            "Saby",
            "Sindal",
            "Skagen",
            "Skorping",
            "Storvorde",
            "Stovring",
            "Strandby",
            "Sulsted",
            "Svenstrup",
            "Tars",
            "Tranekaer",
            "Vadum",
            "Vestbjerg",
            "Vester Hassing",
            "Vodskov",
            "Vra"
          ],
          "StateName": "Nordjylland"
        },
        {
          "Cities": [
            "Ansager",
            "Billund",
            "Bramming",
            "Brorup",
            "Esbjerg",
            "Grindsted",
            "Holsted",
            "Nordby",
            "Oksbol",
            "Olgod",
            "Ribe",
            "Tjareborg",
            "Varde",
            "Vejen",
            "Vorbasse"
          ],
          "StateName": "Ribe"
        },
        {
          "Cities": [
            "Aulum",
            "Bording",
            "Brande",
            "Gjellerup Kirkeby",
            "Hammerum",
            "Harboore",
            "Herning",
            "Holstebro",
            "Hvide Sande",
            "Ikast",
            "Kibak",
            "Lemvig",
            "Lind",
            "Ringkobing",
            "Skaerbaek",
            "Skjern",
            "Snejbjerg",
            "Struer",
            "Sunds",
            "Tarm",
            "Thyboron",
            "Ulfborg",
            "Videbak",
            "Vildbjerg",
            "Vinderup"
          ],
          "StateName": "Ringkobing"
        },
        {
          "Cities": [
            "Roervig"
          ],
          "StateName": "Roervig"
        },
        {
          "Cities": [
            "Bjaverskov",
            "Borup",
            "Ejby",
            "Greve Strand",
            "Gundsomagle",
            "Harlev",
            "Havdrup",
            "Hvalso",
            "Jyllinge",
            "Koge",
            "Lejre",
            "Osted",
            "Roskilde",
            "Solrod",
            "Stroby Egede",
            "Svogerslev",
            "Tune",
            "Viby",
            "Vindinge"
          ],
          "StateName": "Roskilde"
        },
        {
          "Cities": [
            "Glyngore"
          ],
          "StateName": "Roslev"
        },
        {
          "Cities": [
            "Karise",
            "Naestved"
          ],
          "StateName": "Sjaelland"
        },
        {
          "Cities": [
            "Soeborg"
          ],
          "StateName": "Soeborg"
        },
        {
          "Cities": [
            "Aabenraa",
            "Aarsleve",
            "Augustenborg",
            "Broager",
            "Christiansfeld",
            "Dybbol",
            "Gram",
            "Grasten",
            "Guderup",
            "Haderslev",
            "Horuphav",
            "Krusa",
            "Logumkloster",
            "Lojt Kirkeby",
            "Nordborg",
            "Padborg",
            "Rodding",
            "Rodekro",
            "Skarbak",
            "Sonderborg",
            "Starup",
            "Tinglev",
            "Toftlund",
            "Tonder",
            "Vojens"
          ],
          "StateName": "Sonderjylland"
        },
        {
          "Cities": [
            "Fakse",
            "Fakse Ladeplads",
            "Fensmark",
            "Holeby",
            "Maribo",
            "Nakskov",
            "Nastved",
            "Neder Vindinge",
            "Norre Alslev",
            "Nykobing",
            "Nyrad",
            "Orslev",
            "Prasto",
            "Rodby",
            "Rodbyhavn",
            "Ronnede",
            "Sakskobing",
            "Stege",
            "Store Heddinge",
            "Stubbekobing",
            "Sundby",
            "Vordingborg"
          ],
          "StateName": "Storstrom"
        },
        {
          "Cities": [
            "Bylderup-Bov",
            "Fovling"
          ],
          "StateName": "Syddanmark"
        },
        {
          "Cities": [
            "Toelloese"
          ],
          "StateName": "Toelloese"
        },
        {
          "Cities": [
            "Borkop",
            "Bradstrup",
            "Brejning",
            "Egtved",
            "Fredericia",
            "Give",
            "Hedensted",
            "Horsens",
            "Jelling",
            "Juelsminde",
            "Kolding",
            "Lunderskov",
            "Snoghoj",
            "Sonder Bjert",
            "Taulov",
            "Torring",
            "Vamdrup",
            "Vejle"
          ],
          "StateName": "Vejle"
        },
        {
          "Cities": [
            "Asnas",
            "Dianalund",
            "Forlev",
            "Frederiksberg",
            "Fuglebjerg",
            "Gorlev",
            "Haslev",
            "Holbaek",
            "Hong",
            "Horve",
            "Jyderup",
            "Kalundborg",
            "Korsor",
            "Nykobing",
            "Ringsted",
            "Skalskor",
            "Slagelse",
            "Soro",
            "Svebolle",
            "Svinninge",
            "Tollose",
            "Vipperod"
          ],
          "StateName": "Vestsjalland"
        },
        {
          "Cities": [
            "Aalestrup",
            "Bjerringbro",
            "Hanstholm",
            "Hojslev",
            "Hurup",
            "Karup",
            "Kjellerup",
            "Nykobing",
            "Skive",
            "Stoholm",
            "Thisted",
            "Ulstrup",
            "Viborg"
          ],
          "StateName": "Viborg"
        }
      ],
      "CountryName": "Denmark"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "'Ali Sabih"
        },
        {
          "Cities": [
            "Dikhil"
          ],
          "StateName": "Dikhil"
        },
        {
          "Cities": [
            "Jibuti"
          ],
          "StateName": "Jibuti"
        },
        {
          "Cities": [
            "Tajurah"
          ],
          "StateName": "Tajurah"
        },
        {
          "Cities": [
            "Ubuk"
          ],
          "StateName": "Ubuk"
        }
      ],
      "CountryName": "Djibouti"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Saint Andrew"
        },
        {
          "Cities": [],
          "StateName": "Saint David"
        },
        {
          "Cities": [],
          "StateName": "Saint George"
        },
        {
          "Cities": [],
          "StateName": "Saint John"
        },
        {
          "Cities": [],
          "StateName": "Saint Joseph"
        },
        {
          "Cities": [],
          "StateName": "Saint Luke"
        },
        {
          "Cities": [],
          "StateName": "Saint Mark"
        },
        {
          "Cities": [],
          "StateName": "Saint Patrick"
        },
        {
          "Cities": [],
          "StateName": "Saint Paul"
        },
        {
          "Cities": [],
          "StateName": "Saint Peter"
        }
      ],
      "CountryName": "Dominica"
    },
    {
      "States": [
        {
          "Cities": [
            "Azua",
            "Sabana Yegua"
          ],
          "StateName": "Azua"
        },
        {
          "Cities": [
            "Neyba",
            "Tamayo"
          ],
          "StateName": "Bahoruco"
        },
        {
          "Cities": [
            "Barahona",
            "Cabral",
            "El Penon"
          ],
          "StateName": "Barahona"
        },
        {
          "Cities": [
            "Dajabon"
          ],
          "StateName": "Dajabon"
        },
        {
          "Cities": [],
          "StateName": "Distrito Nacional"
        },
        {
          "Cities": [
            "Las Guaranas",
            "Pimentel",
            "San Francisco de Macoris"
          ],
          "StateName": "Duarte"
        },
        {
          "Cities": [],
          "StateName": "El Seybo"
        },
        {
          "Cities": [],
          "StateName": "Elias Pina"
        },
        {
          "Cities": [
            "Moca"
          ],
          "StateName": "Espaillat"
        },
        {
          "Cities": [],
          "StateName": "Hato Mayor"
        },
        {
          "Cities": [
            "Duverge",
            "Jimani"
          ],
          "StateName": "Independencia"
        },
        {
          "Cities": [],
          "StateName": "La Altagracia"
        },
        {
          "Cities": [],
          "StateName": "La Romana"
        },
        {
          "Cities": [],
          "StateName": "La Vega"
        },
        {
          "Cities": [],
          "StateName": "Maria Trinidad Sanchez"
        },
        {
          "Cities": [],
          "StateName": "Monsenor Nouel"
        },
        {
          "Cities": [],
          "StateName": "Monte Cristi"
        },
        {
          "Cities": [],
          "StateName": "Monte Plata"
        },
        {
          "Cities": [
            "Pedernales"
          ],
          "StateName": "Pedernales"
        },
        {
          "Cities": [
            "Bani",
            "Ocoa"
          ],
          "StateName": "Peravia"
        },
        {
          "Cities": [],
          "StateName": "Puerto Plata"
        },
        {
          "Cities": [
            "Salcedo"
          ],
          "StateName": "Salcedo"
        },
        {
          "Cities": [
            "Samana",
            "Sanchez"
          ],
          "StateName": "Samana"
        },
        {
          "Cities": [],
          "StateName": "San Cristobal"
        },
        {
          "Cities": [],
          "StateName": "San Juan"
        },
        {
          "Cities": [],
          "StateName": "San Pedro de Macoris"
        },
        {
          "Cities": [],
          "StateName": "Sanchez Ramirez"
        },
        {
          "Cities": [
            "Santiago",
            "Tamboril",
            "Villa Bisono"
          ],
          "StateName": "Santiago"
        },
        {
          "Cities": [
            "Santiago",
            "Tamboril",
            "Villa Bisono"
          ],
          "StateName": "Santiago Rodriguez"
        },
        {
          "Cities": [
            "Esperanza",
            "Mao"
          ],
          "StateName": "Valverde"
        }
      ],
      "CountryName": "Dominican Republic"
    },
    {
      "States": [
        {
          "Cities": [
            "Aileu"
          ],
          "StateName": "Aileu"
        },
        {
          "Cities": [
            "Ainaro"
          ],
          "StateName": "Ainaro"
        },
        {
          "Cities": [
            "Pante Macassar"
          ],
          "StateName": "Ambeno"
        },
        {
          "Cities": [
            "Baucau"
          ],
          "StateName": "Baucau"
        },
        {
          "Cities": [
            "Auba",
            "Lolotoi",
            "Maliana"
          ],
          "StateName": "Bobonaro"
        },
        {
          "Cities": [],
          "StateName": "Cova Lima"
        },
        {
          "Cities": [
            "Dare",
            "Dili",
            "Metinaro"
          ],
          "StateName": "Dili"
        },
        {
          "Cities": [
            "Ermera"
          ],
          "StateName": "Ermera"
        },
        {
          "Cities": [
            "Lautem",
            "Los Palos"
          ],
          "StateName": "Lautem"
        },
        {
          "Cities": [
            "Bazartete",
            "Liquica"
          ],
          "StateName": "Liquica"
        },
        {
          "Cities": [
            "Manatuto"
          ],
          "StateName": "Manatuto"
        },
        {
          "Cities": [
            "Same"
          ],
          "StateName": "Manufahi"
        },
        {
          "Cities": [
            "Viqueque"
          ],
          "StateName": "Viqueque"
        }
      ],
      "CountryName": "East Timor"
    },
    {
      "States": [
        {
          "Cities": [
            "Cuenca",
            "Gualaceo"
          ],
          "StateName": "Azuay"
        },
        {
          "Cities": [
            "Guaranda",
            "San Miguel"
          ],
          "StateName": "Bolivar"
        },
        {
          "Cities": [
            "Azogues",
            "Canar",
            "La Troncal"
          ],
          "StateName": "Canar"
        },
        {
          "Cities": [
            "El Angel",
            "San Gabriel",
            "Tulcan"
          ],
          "StateName": "Carchi"
        },
        {
          "Cities": [
            "Alausi",
            "Guano",
            "Riobamba"
          ],
          "StateName": "Chimborazo"
        },
        {
          "Cities": [
            "La Mana",
            "Latacunga",
            "Pujili",
            "San Miguel",
            "Saquisili"
          ],
          "StateName": "Cotopaxi"
        },
        {
          "Cities": [],
          "StateName": "El Oro"
        },
        {
          "Cities": [
            "Esmeraldas",
            "Muisne",
            "Rosa Zarate",
            "San Lorenzo",
            "Valdez"
          ],
          "StateName": "Esmeraldas"
        },
        {
          "Cities": [
            "Puerto Ayora",
            "Puerto Baquerizo Moreno",
            "San Cristobal"
          ],
          "StateName": "Galapagos"
        },
        {
          "Cities": [
            "Alfredo Baquerizo Moreno",
            "Balao",
            "Balzar",
            "Colimes",
            "Coronel Mariduena",
            "Daule",
            "El Salitre",
            "El Triunfo",
            "Eloy Alfaro",
            "Guayaquil",
            "La Libertad",
            "Lomas de Sargentillo",
            "Mapasingue",
            "Milagro",
            "Naranjal",
            "Naranjito",
            "Palestina",
            "Pedro Carbo",
            "Playas",
            "Salinas",
            "Samborondon",
            "Santa Elena",
            "Santa Lucia",
            "Velasco Ibarra",
            "Yaguachi"
          ],
          "StateName": "Guayas"
        },
        {
          "Cities": [
            "Atuntaqui",
            "Cotacachi",
            "Ibarra",
            "Otavalo",
            "Pimampiro"
          ],
          "StateName": "Imbabura"
        },
        {
          "Cities": [
            "Alamor",
            "Cariamanga",
            "Catacocha",
            "Catamayo",
            "Celica",
            "Loja",
            "Macara"
          ],
          "StateName": "Loja"
        },
        {
          "Cities": [],
          "StateName": "Los Rios"
        },
        {
          "Cities": [
            "Bahia de Caraquez",
            "Calceta",
            "Chone",
            "El Carmen",
            "Jipijapa",
            "Junin",
            "Manta",
            "Montecristi",
            "Pajan",
            "Pedernales",
            "Portoviejo",
            "Rocafuerte",
            "Santa Ana",
            "Sucre",
            "Tosagua"
          ],
          "StateName": "Manabi"
        },
        {
          "Cities": [],
          "StateName": "Morona Santiago"
        },
        {
          "Cities": [
            "Archidona",
            "Tena"
          ],
          "StateName": "Napo"
        },
        {
          "Cities": [
            "Orellana"
          ],
          "StateName": "Orellana"
        },
        {
          "Cities": [
            "Puyo"
          ],
          "StateName": "Pastaza"
        },
        {
          "Cities": [
            "Cayambe",
            "Machachi",
            "Quito",
            "Sangolqui",
            "Santo Domingo"
          ],
          "StateName": "Pichincha"
        },
        {
          "Cities": [
            "Nueva Loja",
            "Shushufindi"
          ],
          "StateName": "Sucumbios"
        },
        {
          "Cities": [
            "Ambato",
            "Banos",
            "Pelileo",
            "Pillaro"
          ],
          "StateName": "Tungurahua"
        },
        {
          "Cities": [],
          "StateName": "Zamora Chinchipe"
        }
      ],
      "CountryName": "Ecuador"
    },
    {
      "States": [
        {
          "Cities": [
            "Aswan",
            "Daraw",
            "Kawm Umbu",
            "an-Nasir"
          ],
          "StateName": "Aswan"
        },
        {
          "Cities": [
            "Abnub",
            "Abu Tij",
            "Asyut",
            "Bani Muhammadiyat",
            "Dayrut",
            "Dayrut-ash-Sharif",
            "Manfalut",
            "Musha",
            "Sahil Salim",
            "Sanabu",
            "Umm-al-Qusur",
            "al-Badari",
            "al-Qusiyah",
            "an-Nukhaylah"
          ],
          "StateName": "Asyut"
        },
        {
          "Cities": [],
          "StateName": "Bani Suwayf"
        },
        {
          "Cities": [],
          "StateName": "Bur Sa'id"
        },
        {
          "Cities": [
            "6th of October City",
            "Ataba",
            "Cairo",
            "Nasr",
            "Nasr City",
            "Obour City"
          ],
          "StateName": "Cairo"
        },
        {
          "Cities": [
            "'Izbat-al-Burj",
            "Damietta",
            "Dumyat",
            "El-Zarka",
            "Faraskur",
            "Kafr Sa'd",
            "Kafr-al-Battikh",
            "az-Zarqa"
          ],
          "StateName": "Dumyat"
        },
        {
          "Cities": [
            "Biyala",
            "Disuq",
            "Fuwah",
            "Kafr-al-Jara'idah",
            "Kafr-ash-Shaykh",
            "Mutubis",
            "Qallin",
            "Sidi Salim",
            "al-Burj",
            "al-Burullus",
            "al-Haddadi",
            "al-Hamul"
          ],
          "StateName": "Kafr-ash-Shaykh"
        },
        {
          "Cities": [
            "Marsa Matruh",
            "Nasr",
            "Sidi Barrani",
            "Zawiyat Shammas",
            "ad-Da'ba"
          ],
          "StateName": "Matruh"
        },
        {
          "Cities": [],
          "StateName": "Muhafazat ad Daqahliyah"
        },
        {
          "Cities": [],
          "StateName": "Muhafazat al Fayyum"
        },
        {
          "Cities": [],
          "StateName": "Muhafazat al Gharbiyah"
        },
        {
          "Cities": [],
          "StateName": "Muhafazat al Iskandariyah"
        },
        {
          "Cities": [],
          "StateName": "Muhafazat al Qahirah"
        },
        {
          "Cities": [
            "Armant",
            "Asfun-al-Mata'inah",
            "Dandarah",
            "Dishna",
            "Farshut",
            "Hijazah",
            "Hiw",
            "Idfu",
            "Isna",
            "Kiman-al-Mata'inah",
            "Naj' Hammadi",
            "Naqadah",
            "Qift",
            "Qina",
            "Qus",
            "ad-Dabbiyah",
            "ad-Dayr",
            "al-Ballas",
            "al-Karnak",
            "al-Waqf",
            "ar-Radisiyat-al-Bahriyah"
          ],
          "StateName": "Qina"
        },
        {
          "Cities": [
            "Akhmim",
            "Awlad Tawq Sharq",
            "Dar-as-Salam",
            "Jirja",
            "Juhaynah",
            "Sawhaj",
            "Tahta",
            "Tima",
            "al-Balyana",
            "al-Manshah",
            "al-Maragah"
          ],
          "StateName": "Sawhaj"
        },
        {
          "Cities": [],
          "StateName": "Sina al-Janubiyah"
        },
        {
          "Cities": [],
          "StateName": "Sina ash-Shamaliyah"
        },
        {
          "Cities": [
            "Aja",
            "Bahut",
            "Bilqas",
            "Dikirnis",
            "Minyat-an-Nasr",
            "Mit Gamr",
            "Shirbin",
            "Talkha",
            "al-Jamaliyah",
            "al-Ma'sarah",
            "al-Mansurah",
            "al-Manzilah",
            "al-Matariyah",
            "as-Sinbillawayn"
          ],
          "StateName": "ad-Daqahliyah"
        },
        {
          "Cities": [
            "Ras Gharib",
            "Safaja",
            "al-Ghardaqah",
            "al-Qusayr"
          ],
          "StateName": "al-Bahr-al-Ahmar"
        },
        {
          "Cities": [
            "Abu Hummus",
            "Abu al-Matamir",
            "Buturis",
            "Damanhur",
            "Edfina",
            "Hawsh 'Isa",
            "Idku",
            "Ityay-al-Barud",
            "Kafr Salim",
            "Kafr-ad-Dawwar",
            "Kawm Hamada",
            "Nubaria",
            "Rashid",
            "Shubra Khit",
            "Zawiyat Sidi Gazi",
            "ad-Dilinjat",
            "al-Kawm-al-Akhdar",
            "al-Mahmudiyah",
            "ar-Rahmaniyah"
          ],
          "StateName": "al-Buhayrah"
        },
        {
          "Cities": [
            "Fidimin",
            "Ibshaway",
            "Itsa",
            "Qasr Qarun",
            "Sanhur",
            "Sinnuris",
            "Tamiyah",
            "al-Fayyum"
          ],
          "StateName": "al-Fayyum"
        },
        {
          "Cities": [
            "Abyar",
            "Basyun",
            "Kafr-az-Zayyat",
            "Mahallat Marhum",
            "Nisf Thani Bashbish",
            "Qutur",
            "Samannud",
            "Tanta",
            "Zifta",
            "ad-Daljamun",
            "al-Mahallah al-Kubra",
            "as-Santah"
          ],
          "StateName": "al-Gharbiyah"
        },
        {
          "Cities": [
            "Agamy",
            "al-Iskandariyah",
            "al-Maks"
          ],
          "StateName": "al-Iskandariyah"
        },
        {
          "Cities": [
            "Fa'id",
            "Sarabiyum",
            "al-Ismailiyah"
          ],
          "StateName": "al-Ismailiyah"
        },
        {
          "Cities": [
            "Atfih",
            "Awsim",
            "Giza",
            "Madinat Sittah Uktubar",
            "Nahya",
            "Saqqarah",
            "al-'Ayyat",
            "al-Badrashayn",
            "al-Hawamidiyah",
            "al-Jizah",
            "al-Mansuriyah",
            "al-Wahat-al-Bahriyah",
            "as-Saff"
          ],
          "StateName": "al-Jizah"
        },
        {
          "Cities": [
            "Ashmun",
            "Birkat-as-Sab'",
            "Milij",
            "Minuf",
            "Quwaysina",
            "Shibin-al-Kawm",
            "Sirs-al-Layyanah",
            "Tala",
            "al-Bajur",
            "al-Batanun",
            "ash-Shuhada"
          ],
          "StateName": "al-Minufiyah"
        },
        {
          "Cities": [
            "Abu Qurqas",
            "Bani Mazar",
            "Dayr Mawas",
            "Magagah",
            "Mallawi",
            "Matay",
            "Samalut",
            "Tallah",
            "Tandah",
            "al-Anayim",
            "al-Fikriyah",
            "al-Minya",
            "ar-Rawdah"
          ],
          "StateName": "al-Minya"
        },
        {
          "Cities": [
            "Badr City",
            "Heliopolis",
            "al-Qahira"
          ],
          "StateName": "al-Qahira"
        },
        {
          "Cities": [
            "Abu Za'bal",
            "Banha",
            "Qalyub",
            "Shubra al-Khaymah",
            "Sibin-al-Qanatir",
            "Tukh",
            "al-Khankah",
            "al-Qanatir-al-Khayriyah"
          ],
          "StateName": "al-Qalyubiyah"
        },
        {
          "Cities": [
            "al-Uqsur"
          ],
          "StateName": "al-Uqsur"
        },
        {
          "Cities": [],
          "StateName": "al-Wadi al-Jadid"
        },
        {
          "Cities": [
            "as-Suways"
          ],
          "StateName": "as-Suways"
        },
        {
          "Cities": [
            "Abu Hammad",
            "Abu Kabir",
            "Bilbays",
            "Diyarb Najm",
            "Faqus",
            "Hihya",
            "Kafr Saqr",
            "Mashtul-as-Suq",
            "Minyat-al-Qamh",
            "al-Ashir mir-Ramadan",
            "al-Husayniyah",
            "al-Ibrahimiyah",
            "al-Qanayat",
            "al-Qassasin",
            "al-Qurayn",
            "as-Salihiyah",
            "at-Tall-al-Kabir",
            "az-Zaqaziq"
          ],
          "StateName": "ash-Sharqiyah"
        }
      ],
      "CountryName": "Egypt"
    },
    {
      "States": [
        {
          "Cities": [
            "Ahuachapan",
            "Atiquizaya",
            "Concepcion de Ataco",
            "Guaymango",
            "Jujutla",
            "San Francisco Menendez",
            "Tacuba"
          ],
          "StateName": "Ahuachapan"
        },
        {
          "Cities": [
            "Ilobasco",
            "Sensuntepeque",
            "Victoria"
          ],
          "StateName": "Cabanas"
        },
        {
          "Cities": [
            "Chalatenango",
            "La Palma",
            "Nueva Concepcion",
            "San Francisco Morazan"
          ],
          "StateName": "Chalatenango"
        },
        {
          "Cities": [
            "Cojutepeque",
            "San Pedro Perulapan",
            "Suchitoto",
            "Tecoluca",
            "Tenancingo"
          ],
          "StateName": "Cuscatlan"
        },
        {
          "Cities": [],
          "StateName": "La Libertad"
        },
        {
          "Cities": [],
          "StateName": "La Paz"
        },
        {
          "Cities": [],
          "StateName": "La Union"
        },
        {
          "Cities": [
            "Cacaopera",
            "Corinto",
            "Gotera",
            "Guatajiagua",
            "Jocoro",
            "Sociedad"
          ],
          "StateName": "Morazan"
        },
        {
          "Cities": [],
          "StateName": "San Miguel"
        },
        {
          "Cities": [],
          "StateName": "San Salvador"
        },
        {
          "Cities": [],
          "StateName": "San Vicente"
        },
        {
          "Cities": [],
          "StateName": "Santa Ana"
        },
        {
          "Cities": [
            "Acajutla",
            "Armenia",
            "Izalco",
            "Juayua",
            "Nahuizalco",
            "San Antonio del Monte",
            "San Julian",
            "Sonsonate",
            "Sonzacate"
          ],
          "StateName": "Sonsonate"
        },
        {
          "Cities": [
            "Berlin",
            "Concepcion Batres",
            "Estanzuelas",
            "Jiquilisco",
            "Jucuapa",
            "Jucuaran",
            "Ozatlan",
            "Puerto El Triunfo",
            "San Agustin",
            "Santa Elena",
            "Santiago de Maria",
            "Usulutan"
          ],
          "StateName": "Usulutan"
        }
      ],
      "CountryName": "El Salvador"
    },
    {
      "States": [
        {
          "Cities": [
            "Pale"
          ],
          "StateName": "Annobon"
        },
        {
          "Cities": [],
          "StateName": "Bioko Norte"
        },
        {
          "Cities": [],
          "StateName": "Bioko Sur"
        },
        {
          "Cities": [],
          "StateName": "Centro Sur"
        },
        {
          "Cities": [
            "Ebebiyin",
            "Mikomeseng"
          ],
          "StateName": "Kie-Ntem"
        },
        {
          "Cities": [
            "Bata",
            "Mbini"
          ],
          "StateName": "Litoral"
        },
        {
          "Cities": [
            "Aconibe",
            "Anisoc",
            "Mongomo",
            "Nsok"
          ],
          "StateName": "Wele-Nzas"
        }
      ],
      "CountryName": "Equatorial Guinea"
    },
    {
      "States": [
        {
          "Cities": [
            "Keren"
          ],
          "StateName": "Anseba"
        },
        {
          "Cities": [
            "Addi Kwala",
            "Addi Ugri"
          ],
          "StateName": "Debub"
        },
        {
          "Cities": [
            "Asseb",
            "Beylul",
            "Edd",
            "Mersa Fatma"
          ],
          "StateName": "Debub-Keih-Bahri"
        },
        {
          "Cities": [
            "Ak'ordat",
            "Barentu",
            "Teseney"
          ],
          "StateName": "Gash-Barka"
        },
        {
          "Cities": [
            "Asmara",
            "Ginda",
            "Himbirti",
            "Nefasit"
          ],
          "StateName": "Maekel"
        },
        {
          "Cities": [
            "Addi K'eyih",
            "Dek'emhare",
            "Mitsiwa",
            "Sen'afe"
          ],
          "StateName": "Semien-Keih-Bahri"
        }
      ],
      "CountryName": "Eritrea"
    },
    {
      "States": [
        {
          "Cities": [
            "Aasmae",
            "Aaviku",
            "Aegviidu",
            "Aigrumae",
            "Aila",
            "Alavere",
            "Alliku",
            "Amari",
            "Anija",
            "Ardu",
            "Arukula",
            "Aruvalla",
            "Assaku",
            "Ellamaa",
            "Haabneeme",
            "Habaja",
            "Haiba",
            "Haljava",
            "Hara",
            "Harju-Risti",
            "Harku",
            "Harkujarve",
            "Harma",
            "Huuru",
            "Ilmandu",
            "Iru",
            "Jagala",
            "Jalgimae",
            "Jarsi",
            "Jarvekula",
            "Jogisoo",
            "Juri",
            "Kaasiku",
            "Kaberneeme",
            "Kahala",
            "Kalesi",
            "Kallavere",
            "Karjakula",
            "Karla",
            "Kasepere",
            "Kasispea",
            "Kehra",
            "Keila",
            "Keila-Joa",
            "Kelvingi",
            "Kiia",
            "Kiili",
            "Kiisa",
            "Kiiu",
            "Klooga",
            "Kloogaranna",
            "Kohatu",
            "Kolga",
            "Kolga-Aabla",
            "Kolgakula",
            "Konnu",
            "Kose",
            "Kose-Uuemoisa",
            "Kostivere",
            "Krei",
            "Kuivajoe",
            "Kumna",
            "Kurtna",
            "Kuusalu",
            "Laabi",
            "Laagri",
            "Lagedi",
            "Laitse",
            "Laulasmaa",
            "Lehetu",
            "Lehola",
            "Lehtmetsa",
            "Leppneeme",
            "Liikva",
            "Lilli",
            "Lohusalu",
            "Loksa",
            "Lokuti",
            "Loo",
            "Lubja",
            "Luige",
            "Maardu",
            "Maidla",
            "Manniku",
            "Metsakasti",
            "Metsanurme",
            "Miiduranna",
            "Munalaskme",
            "Muraste",
            "Muuga",
            "Nabala",
            "Neeme",
            "Ohtu",
            "Ojasoo",
            "Oru",
            "Padise",
            "Pae",
            "Paekna",
            "Pajupea",
            "Paldiski",
            "Palvere",
            "Parispea",
            "Patika",
            "Paunkula",
            "Peetri",
            "Peningi",
            "Perila",
            "Pikva",
            "Pillapalu",
            "Pringi",
            "Puunsi",
            "Raasiku",
            "Rae",
            "Randvere",
            "Rannamoisa",
            "Ravila",
            "Riisipere",
            "Rohuneeme",
            "Roobuka",
            "Ruila",
            "Rummu",
            "Saha",
            "Saku",
            "Saue",
            "Saula",
            "Saunja",
            "Suurpea",
            "Suurupi",
            "Tabasalu",
            "Tagadi",
            "Tagametsa",
            "Tallinn",
            "Tammneeme",
            "Tiskre",
            "Todva",
            "Turba",
            "Turisalu",
            "Tutermaa",
            "Tuula",
            "Tuulna",
            "Uksnurme",
            "Ulejoe",
            "Uuri",
            "Uuskula",
            "Vaana",
            "Vaana-Joesuu",
            "Vaida",
            "Vaidasoo",
            "Valingu",
            "Valkla",
            "Vanamoisa",
            "Vardja",
            "Vasalemma",
            "Vaskjala",
            "Vatsla",
            "Veskikula",
            "Vihasoo",
            "Viimsi",
            "Viinistu",
            "Viti"
          ],
          "StateName": "Harju"
        },
        {
          "Cities": [
            "Emmaste",
            "Jausa",
            "Kaina",
            "Kardla",
            "Korgessaare",
            "Lauka",
            "Lope",
            "Mannamaa",
            "Putkaste",
            "Suuremoisa"
          ],
          "StateName": "Hiiu"
        },
        {
          "Cities": [
            "Aa",
            "Alajoe",
            "Aseri",
            "Avinurme",
            "Edise",
            "Erra",
            "Iisaku",
            "Jarve",
            "Johvi",
            "Kahula",
            "Kiikla",
            "Kivioli",
            "Kohtla-Jarve",
            "Kohtla-Nomme",
            "Konju",
            "Kose",
            "Kuremae",
            "Kurtna",
            "Lohusuu",
            "Luganuse",
            "Maetaguse",
            "Maidla",
            "Narva",
            "Narva-Joesuu",
            "Olgina",
            "Pagari",
            "Puhajoe",
            "Purtse",
            "Pussi",
            "Rannu",
            "Saka",
            "Savala",
            "Sillamae",
            "Sinimae",
            "Soldina",
            "Sompa",
            "Sonda",
            "Tammiku",
            "Toila",
            "Tudulinna",
            "Ulvi",
            "Vaivara",
            "Varja",
            "Vasavere",
            "Voka"
          ],
          "StateName": "Ida-Viru"
        },
        {
          "Cities": [
            "Ahula",
            "Aiamaa",
            "Albu",
            "Ambla",
            "Anari",
            "Anna",
            "Aravete",
            "Ervita",
            "Imavere",
            "Janeda",
            "Jarva-Jaani",
            "Jogisoo",
            "Jootme",
            "Kaalepi",
            "Kabala",
            "Kahala",
            "Karavete",
            "Karevere",
            "Karinu",
            "Kasukonna",
            "Kirna",
            "Koeru",
            "Koigi",
            "Kolu",
            "Kuksema",
            "Laupa",
            "Lehtse",
            "Lokuta",
            "Loola",
            "Muusleri",
            "Oisu",
            "Paide",
            "Painurme",
            "Peetri",
            "Poikva",
            "Reopalu",
            "Retla",
            "Roa",
            "Roosna",
            "Roosna-Alliku",
            "Sarevere",
            "Sargvere",
            "Taikse",
            "Tarbja",
            "Turi",
            "Turi-Alliku",
            "Vaatsa",
            "Vahukula",
            "Vao",
            "Viisu",
            "Villevere"
          ],
          "StateName": "Jarva"
        },
        {
          "Cities": [
            "Adavere",
            "Esku",
            "Harjanurme",
            "Jogeva",
            "Kaarepere",
            "Kalana",
            "Kalme",
            "Kamari",
            "Karde",
            "Kasepaa",
            "Kassinurme",
            "Konnu",
            "Kudina",
            "Kukita",
            "Kuremaa",
            "Kurista",
            "Lahavere",
            "Laiuse",
            "Laiusevalja",
            "Leedi",
            "Lustivere",
            "Luua",
            "Maarja",
            "Mallikvere",
            "Metsakula",
            "Mohkula",
            "Mustvee",
            "Neanurme",
            "Nova",
            "Omedu",
            "Ouna",
            "Painkula",
            "Pajusi",
            "Pala",
            "Palamuse",
            "Pataste",
            "Pauastvere",
            "Pikkjarve",
            "Pikknurme",
            "Pisisaare",
            "Poltsamaa",
            "Poora",
            "Puurmani",
            "Raabise",
            "Raja",
            "Saare",
            "Sadala",
            "Sadukula",
            "Siimusti",
            "Tabivere",
            "Tahkvere",
            "Tiheda",
            "Toikvere",
            "Torma",
            "Umbusi",
            "Vagari",
            "Vaiatu",
            "Vaike-Kamari",
            "Vaimastvere",
            "Valgma",
            "Visusti",
            "Voduvere",
            "Vohmanomme",
            "Voisiku",
            "Voldi",
            "Voore",
            "Votikvere"
          ],
          "StateName": "Jogeva"
        },
        {
          "Cities": [
            "Haapsalu",
            "Hullo",
            "Joodre",
            "Kirbla",
            "Kirimae",
            "Koluvere",
            "Komsi",
            "Kullamaa",
            "Lihula",
            "Liivi",
            "Linnamae",
            "Martna",
            "Nigula",
            "Nova",
            "Palivere",
            "Palli",
            "Panga",
            "Paralepa",
            "Piirsalu",
            "Purksi",
            "Rannakula",
            "Risti",
            "Roude",
            "Sutlepa",
            "Taebla",
            "Tuudi",
            "Uuemoisa",
            "Variku",
            "Vatla",
            "Virtsu"
          ],
          "StateName": "Laane"
        },
        {
          "Cities": [
            "Aaspere",
            "Ama",
            "Arkna",
            "Assamalla",
            "Avanduse",
            "Avispea",
            "Ebavere",
            "Eipri",
            "Essu",
            "Haljala",
            "Hulja",
            "Imastu",
            "Inju",
            "Kadapiku",
            "Kadila",
            "Kadrina",
            "Kakumae",
            "Karitsa",
            "Karu",
            "Kasmu",
            "Kihlevere",
            "Kiku",
            "Kiltsi",
            "Kohala",
            "Kunda",
            "Kuti",
            "Laekvere",
            "Lasila",
            "Lepna",
            "Levala",
            "Liigvalla",
            "Modriku",
            "Moe",
            "Moora",
            "Muuga",
            "Napi",
            "Paasvere",
            "Pajusti",
            "Pandivere",
            "Piira",
            "Pikevere",
            "Podrangu",
            "Podruse",
            "Porkuni",
            "Rahkla",
            "Rakke",
            "Rakvere",
            "Ridakula",
            "Roela",
            "Roodevalja",
            "Saase",
            "Salda",
            "Salla",
            "Simuna",
            "Someru",
            "Taaravainu",
            "Tamsalu",
            "Tapa",
            "Torma",
            "Torremae",
            "Triigi",
            "Tudu",
            "Ubja",
            "Udriku",
            "Uhtna",
            "Ulvi",
            "Undla",
            "Ussimae",
            "Uudekula",
            "Vaekula",
            "Vahakulmu",
            "Vaiatu",
            "Vaike-Maarja",
            "Vajangu",
            "Vao",
            "Veltsi",
            "Venevere",
            "Vergi",
            "Vetiku",
            "Vihula",
            "Viitna",
            "Vinni",
            "Viru-Jaagupi",
            "Viru-Kabala",
            "Viru-Nigula",
            "Vohnja",
            "Vosu",
            "Vosupere"
          ],
          "StateName": "Laane-Viru"
        },
        {
          "Cities": [
            "Ahaste",
            "Aluste",
            "Are",
            "Arumetsa",
            "Audru",
            "Eametsa",
            "Haademeeste",
            "Halinga",
            "Ikla",
            "Ilvese",
            "Jaamakula",
            "Jaarja",
            "Joesuu",
            "Joopre",
            "Kaansoo",
            "Kabli",
            "Kadjaste",
            "Kaisma",
            "Kalita",
            "Kanakula",
            "Kergu",
            "Kihlepa",
            "Kilingi-Nomme",
            "Kilksama",
            "Koima",
            "Koonga",
            "Krundikula",
            "Laadi",
            "Langerma",
            "Lavassaare",
            "Leipste",
            "Lemmetsa",
            "Lemsi",
            "Libatse",
            "Linakula",
            "Lindi",
            "Liu",
            "Lodja",
            "Lope",
            "Malda",
            "Mannikuste",
            "Massiaru",
            "Metsaaare",
            "Metsapoole",
            "Moisakula",
            "Muraka",
            "Niidu",
            "Nurme",
            "Oara",
            "Oidrema",
            "Paikuse",
            "Papsaare",
            "Parnjoe",
            "Parnu",
            "Parnu-Jaagupi",
            "Penu",
            "Piistaoja",
            "Pohara",
            "Poldeotsa",
            "Pootsi",
            "Pulli",
            "Rannametsa",
            "Ratsepa",
            "Reiu",
            "Ridalepa",
            "Rootsikula",
            "Saarde",
            "Saare",
            "Sauga",
            "Seliste",
            "Selja",
            "Seljametsa",
            "Silla",
            "Sindi",
            "Soeva",
            "Soometsa",
            "Suigu",
            "Surju",
            "Suurejoe",
            "Taali",
            "Tahkuranna",
            "Tali",
            "Tammiste",
            "Tammuru",
            "Tihemetsa",
            "Tolla",
            "Tootsi",
            "Tori",
            "Tostamaa",
            "Tousi",
            "Treimani",
            "Urge",
            "Uulu",
            "Vahenurme",
            "Vaki",
            "Vandra",
            "Varbla",
            "Veelikse",
            "Vihtra",
            "Voidu",
            "Voiste"
          ],
          "StateName": "Parnu"
        },
        {
          "Cities": [
            "Aarna",
            "Ahja",
            "Erastvere",
            "Himma",
            "Himmaste",
            "Holvandi",
            "Ihamaru",
            "Jaanimoisa",
            "Kanepi",
            "Karilatsi",
            "Karsa",
            "Karste",
            "Kauksi",
            "Kiidjarve",
            "Kostrimae",
            "Krootuse",
            "Leevaku",
            "Leevi",
            "Leevijoe",
            "Linte",
            "Lootvina",
            "Maaritsa",
            "Mammaste",
            "Metste",
            "Mikitamae",
            "Mooste",
            "Naha",
            "Orava",
            "Pahtpaa",
            "Partsi",
            "Peri",
            "Piigandi",
            "Polgaste",
            "Polva",
            "Prangli",
            "Puuri",
            "Raadama",
            "Rahumae",
            "Raigla",
            "Rapina",
            "Rasina",
            "Ristipalo",
            "Rosma",
            "Ruusa",
            "Saverna",
            "Sillapaa",
            "Soodoma",
            "Suurkula",
            "Taevaskoja",
            "Tannassilma",
            "Tilsi",
            "Treski",
            "Vaike-Rosna",
            "Valgjarve",
            "Vana-Koiola",
            "Vardja",
            "Varska",
            "Vastse-Kuuste",
            "Veriora",
            "Viluste",
            "Voopsu"
          ],
          "StateName": "Polva"
        },
        {
          "Cities": [
            "Aespa",
            "Alu",
            "Eidapere",
            "Hageri",
            "Hagudi",
            "Haimre",
            "Hertu",
            "Iira",
            "Ingliste",
            "Jarlepa",
            "Jarvakandi",
            "Juuru",
            "Kaerepere",
            "Kaiu",
            "Kalbu",
            "Karu",
            "Kasti",
            "Keava",
            "Kehtna",
            "Kivi-Vigala",
            "Kodila",
            "Kohila",
            "Kuimetsa",
            "Kuusiku",
            "Laukna",
            "Lelle",
            "Lipa",
            "Lohu",
            "Lokuta",
            "Maidla",
            "Marjamaa",
            "Masti",
            "Moisamaa",
            "Naravere",
            "Orgita",
            "Paardu",
            "Pae",
            "Pahkla",
            "Pihali",
            "Pirgu",
            "Prillimae",
            "Purila",
            "Purku",
            "Rabivere",
            "Raikkula",
            "Rangu",
            "Rapla",
            "Salutaguse",
            "Sipa",
            "Sotke",
            "Sutlema",
            "Tamme",
            "Teenuse",
            "Uuskula",
            "Vahastu",
            "Valgu",
            "Valtu",
            "Vana-Kaiu",
            "Vana-Vigala",
            "Varbola",
            "Vilivere"
          ],
          "StateName": "Rapla"
        },
        {
          "Cities": [
            "Arandi",
            "Aste",
            "Eikla",
            "Hellamaa",
            "Kao",
            "Karja",
            "Karla",
            "Kihelkonna",
            "Koimla",
            "Koljala",
            "Korkkula",
            "Korkvere",
            "Kudjape",
            "Kuressaare",
            "Laatsa",
            "Lahekula",
            "Laimjala",
            "Leisi",
            "Liiva",
            "Lumanda",
            "Metskula",
            "Mustjala",
            "Nasva",
            "Nommkula",
            "Orissaare",
            "Pahkla",
            "Parsama",
            "Pihtla",
            "Sakla",
            "Salme",
            "Sandla",
            "Tagavere",
            "Tornimae",
            "Valjala",
            "Vohma"
          ],
          "StateName": "Saare"
        },
        {
          "Cities": [
            "Aardla",
            "Aksi",
            "Alasoo",
            "Alatskivi",
            "Annikoru",
            "Aravu",
            "Elva",
            "Erala",
            "Ervu",
            "Haage",
            "Haaslava",
            "Ignase",
            "Illi",
            "Ilmatsalu",
            "Kaagvere",
            "Kaardi",
            "Kaarlijarve",
            "Kallaste",
            "Kalme",
            "Kambja",
            "Kandikula",
            "Karevere",
            "Karkna",
            "Kasepaa",
            "Kavastu",
            "Kirepi",
            "Kodukula",
            "Kokora",
            "Kolkja",
            "Konguta",
            "Koosa",
            "Koruste",
            "Korvekula",
            "Kulitse",
            "Kurekula",
            "Kurista",
            "Laaniste",
            "Laeva",
            "Lahte",
            "Lalli",
            "Lapetukme",
            "Lemmatsi",
            "Lohkva",
            "Lossimae",
            "Luke",
            "Luunja",
            "Maksa",
            "Maramaa",
            "Marja",
            "Meeksi",
            "Meeri",
            "Mehikoorma",
            "Melliste",
            "Metsakivi",
            "Metsalaane",
            "Moisanurme",
            "Nina",
            "Nogiaru",
            "Noo",
            "Pilka",
            "Poka",
            "Pooritsa",
            "Puhja",
            "Rahinge",
            "Raigaste",
            "Ramsi",
            "Rani",
            "Rannu",
            "Rebase",
            "Reola",
            "Rohu",
            "Roiu",
            "Rongu",
            "Saadjarve",
            "Sinikula",
            "Sirgu",
            "Soinaste",
            "Sojamaa",
            "Tahtvere",
            "Tammiste",
            "Tammistu",
            "Tartu",
            "Teedla",
            "Tilga",
            "Toravere",
            "Torvandi",
            "Tuki",
            "Uderna",
            "Uhti",
            "Ulenurme",
            "Ulila",
            "Unikula",
            "Vahi",
            "Vaike-Rakke",
            "Valguta",
            "Vana-Kuuste",
            "Vara",
            "Varnja",
            "Vasula",
            "Vedu",
            "Vesneri",
            "Vissi",
            "Voibla",
            "Voika",
            "Vonnu",
            "Voopste",
            "Vorbuse"
          ],
          "StateName": "Tartu"
        },
        {
          "Cities": [
            "Aakre",
            "Ala",
            "Hargla",
            "Hellenurme",
            "Helme",
            "Hummuli",
            "Jeti",
            "Jogeveste",
            "Kaagjarve",
            "Kalme",
            "Karjatnurme",
            "Karula",
            "Keeni",
            "Koikkula",
            "Laanemetsa",
            "Laatre",
            "Laukula",
            "Leebiku",
            "Linna",
            "Lossikula",
            "Lullemae",
            "Moldre",
            "Nouni",
            "Nupli",
            "Oru",
            "Oruste",
            "Otepaa",
            "Paidla",
            "Paju",
            "Palupera",
            "Patkula",
            "Piiri",
            "Pikasilla",
            "Pilkuse",
            "Puhajarve",
            "Puka",
            "Restu",
            "Riidaja",
            "Sangaste",
            "Sihva",
            "Sooru",
            "Taagepera",
            "Tagula",
            "Taheva",
            "Tiidu",
            "Tolliste",
            "Torva",
            "Tsirguliina",
            "Valga",
            "Vana-Otepaa"
          ],
          "StateName": "Valga"
        },
        {
          "Cities": [
            "Abja-Paluoja",
            "Abja-Vanamoisa",
            "Aidu",
            "Angi",
            "Anikatsi",
            "Arikula",
            "Halliste",
            "Heimtali",
            "Holstre",
            "Intsu",
            "Jamejala",
            "Jaska",
            "Kaavere",
            "Kamara",
            "Karksi",
            "Karksi-Nuia",
            "Karstna",
            "Karula",
            "Kobruvere",
            "Koidama",
            "Koksvere",
            "Kolga-Jaani",
            "Koo",
            "Kopu",
            "Kulla",
            "Lalsi",
            "Leie",
            "Lilli",
            "Lohavere",
            "Loodi",
            "Maeltkula",
            "Matapera",
            "Meleski",
            "Metskula",
            "Moisakula",
            "Monnaste",
            "Mustivere",
            "Mustla",
            "Navesti",
            "Odiste",
            "Oisu",
            "Olustvere",
            "Pahuvere",
            "Paistu",
            "Parakula",
            "Pari",
            "Parsti",
            "Peetrimoisa",
            "Pilistvere",
            "Pinska",
            "Polde",
            "Polli",
            "Puiatu",
            "Ramsi",
            "Reegoldi",
            "Saarepeedi",
            "Savikoti",
            "Sinialliku",
            "Soe",
            "Soomevere",
            "Sudiste",
            "Suislepa",
            "Sultsi",
            "Supsi",
            "Surgavere",
            "Suure-Jaani",
            "Taaksi",
            "Tanassilma",
            "Tinnikuru",
            "Tohvri",
            "Tuhalaane",
            "Tusti",
            "Ulde",
            "Ulensi",
            "Uusna",
            "Valgita",
            "Valma",
            "Valuste",
            "Vana-Kariste",
            "Vana-Voidu",
            "Vardi",
            "Vardja",
            "Vastemoisa",
            "Veelikse",
            "Verilaske",
            "Veskimae",
            "Viiratsi",
            "Viljandi",
            "Villa",
            "Vohma",
            "Voivaku",
            "Vooru"
          ],
          "StateName": "Viljandi"
        },
        {
          "Cities": [
            "Antsla",
            "Haanja",
            "Jarvere",
            "Kaapa",
            "Kirumpaa",
            "Kobela",
            "Kollino",
            "Kose",
            "Kraavi",
            "Krabi",
            "Kulaoru",
            "Kuldre",
            "Kuutsi",
            "Lasva",
            "Loosu",
            "Lusti",
            "Meegomae",
            "Meremae",
            "Misso",
            "Moniste",
            "Navi",
            "Nursi",
            "Obinitsa",
            "Osula",
            "Otsa",
            "Parksepa",
            "Puiga",
            "Raiste",
            "Rimmi",
            "Rouge",
            "Ruusmae",
            "Saru",
            "Somerpalu",
            "Sulbi",
            "Taberlaane",
            "Tagakula",
            "Tsolgo",
            "Tsooru",
            "Urvaste",
            "Uue-Antsla",
            "Vaabina",
            "Vagula",
            "Vaimela",
            "Vana-Antsla",
            "Vana-Roosa",
            "Vana-Vastseliina",
            "Varstu",
            "Vastseliina",
            "Verijarve",
            "Viitina",
            "Viitka",
            "Visela",
            "Voru",
            "Vorumoisa"
          ],
          "StateName": "Voru"
        }
      ],
      "CountryName": "Estonia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Addis Abeba"
        },
        {
          "Cities": [
            "Asayita",
            "Awash",
            "Dubti",
            "Gewane"
          ],
          "StateName": "Afar"
        },
        {
          "Cities": [
            "Addi Ark'ay",
            "Addis 'Alem",
            "Addis Zemen",
            "Adet",
            "Bahir Dar",
            "Bati",
            "Bichena",
            "Bure",
            "Chagni",
            "Dabat",
            "Dangla",
            "Debark",
            "Debre Birhan",
            "Debre Mark'os",
            "Debre Sina",
            "Debre Tabor",
            "Debre Werk",
            "Dejen",
            "Dese",
            "Finote Selam",
            "Gondar",
            "K'obo",
            "Kembolcha",
            "Kemise",
            "Lalibela",
            "Mott'a",
            "Nefas Mewcha",
            "Sek'ot'a",
            "Shewa Robit",
            "Weldiya",
            "Were Ilu",
            "Werota"
          ],
          "StateName": "Amhara"
        },
        {
          "Cities": [
            "Asosa"
          ],
          "StateName": "Benishangul"
        },
        {
          "Cities": [
            "Dire Dawa"
          ],
          "StateName": "Diredawa"
        },
        {
          "Cities": [
            "Gambela"
          ],
          "StateName": "Gambella"
        },
        {
          "Cities": [
            "Harer"
          ],
          "StateName": "Harar"
        },
        {
          "Cities": [
            "Jigjiga"
          ],
          "StateName": "Jigjiga"
        },
        {
          "Cities": [],
          "StateName": "Mekele"
        },
        {
          "Cities": [
            "'Alem Maya",
            "Abomsa",
            "Agaro",
            "Asasa",
            "Asbe Teferi",
            "Assela",
            "Bako",
            "Bedele",
            "Bedesa",
            "Burayu",
            "Debre Zeyit",
            "Deder",
            "Dembi Dolo",
            "Dodola",
            "Fiche",
            "Gebre Guracha",
            "Gedo",
            "Gelemso",
            "Gimbi",
            "Ginch'i",
            "Ginir",
            "Giyon",
            "Goba",
            "Gore",
            "Guder",
            "Hagere Hiywet",
            "Hagere Maryam",
            "Hirna",
            "Holeta Genet",
            "Huruta",
            "Jimma",
            "Kibre Mengist",
            "Kofele",
            "Mega",
            "Mek'i",
            "Mendi",
            "Metehara",
            "Metu",
            "Mojo",
            "Moyale",
            "Nazret",
            "Nedjo",
            "Negele",
            "Nek'emte",
            "Robe",
            "Sebeta",
            "Sendafa",
            "Shakiso",
            "Shambu",
            "Shashemenne",
            "Sheh Hussen",
            "Sire",
            "Tulu Bolo",
            "Welench'iti",
            "Welk'it'e",
            "Wonji",
            "Yabelo",
            "Ziway"
          ],
          "StateName": "Oromia"
        },
        {
          "Cities": [
            "Bircot",
            "Degeh Bur",
            "Dollo Odo",
            "Imi",
            "Jijiga",
            "Werder"
          ],
          "StateName": "Somali"
        },
        {
          "Cities": [
            "Alaba Kulito",
            "Arba Minch",
            "Areka",
            "Awassa",
            "Bako",
            "Boditi",
            "Bonga",
            "Butajira",
            "Ch'ench'a",
            "Dilla",
            "Gidole",
            "Hossa'ina",
            "Jinka",
            "Leku",
            "Mizan Teferi",
            "Sawla",
            "Soddo",
            "Teppi",
            "Wendo",
            "Yirga Alem",
            "Yirga Ch'efe"
          ],
          "StateName": "Southern"
        },
        {
          "Cities": [
            "Abiy Adi",
            "Addigrat",
            "Adwa",
            "Aksum",
            "Alamat'a",
            "Endasilasie",
            "Hagere Selam",
            "Himora",
            "Korem",
            "Maych'ew",
            "Mek'ele",
            "Mekele",
            "Wik'ro"
          ],
          "StateName": "Tigray"
        }
      ],
      "CountryName": "Ethiopia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Christmas Island"
        },
        {
          "Cities": [],
          "StateName": "Cocos Islands"
        },
        {
          "Cities": [],
          "StateName": "Coral Sea Islands"
        }
      ],
      "CountryName": "External Territories of Australia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Falkland Islands"
        },
        {
          "Cities": [],
          "StateName": "South Georgia"
        }
      ],
      "CountryName": "Falkland Islands"
    },
    {
      "States": [
        {
          "Cities": [
            "Hvannasund",
            "Klaksvik"
          ],
          "StateName": "Klaksvik"
        },
        {
          "Cities": [],
          "StateName": "Nor ara Eysturoy"
        },
        {
          "Cities": [],
          "StateName": "Nor oy"
        },
        {
          "Cities": [
            "Husavik",
            "Sands",
            "Skalavik",
            "Skopun",
            "Skuvoy"
          ],
          "StateName": "Sandoy"
        },
        {
          "Cities": [
            "Haldarsvik",
            "Hests",
            "Hosvik",
            "Hvalvik",
            "Kirkjubo",
            "Kollafjar ",
            "Kvivik",
            "Nolsoy",
            "Saksun",
            "Torshavn",
            "Vestmanna"
          ],
          "StateName": "Streymoy"
        },
        {
          "Cities": [],
          "StateName": "Su uroy"
        },
        {
          "Cities": [],
          "StateName": "Sy ra Eysturoy"
        },
        {
          "Cities": [
            "Torshavn"
          ],
          "StateName": "Torshavn"
        },
        {
          "Cities": [
            "Biggjar",
            "Midvags",
            "Mykines",
            "Sandavags",
            "Sorvags"
          ],
          "StateName": "Vaga"
        }
      ],
      "CountryName": "Faroe Islands"
    },
    {
      "States": [
        {
          "Cities": [
            "Deuba",
            "Korovou",
            "Lami",
            "Namosi",
            "Nausori",
            "Navua",
            "Suva"
          ],
          "StateName": "Central"
        },
        {
          "Cities": [
            "Levuka",
            "Malhaha",
            "Tubou",
            "Vunisea"
          ],
          "StateName": "Eastern"
        },
        {
          "Cities": [
            "Korokade",
            "Labasa",
            "Savusavu"
          ],
          "StateName": "Northern"
        },
        {
          "Cities": [],
          "StateName": "South Pacific"
        },
        {
          "Cities": [
            "Ba",
            "Lautoka",
            "Nadi",
            "Rakiraki",
            "Sigatoka",
            "Tavua",
            "Vatukoula"
          ],
          "StateName": "Western"
        }
      ],
      "CountryName": "Fiji Islands"
    },
    {
      "States": [
        {
          "Cities": [
            "Maarianhamina"
          ],
          "StateName": "Ahvenanmaa"
        },
        {
          "Cities": [
            "Imatra",
            "Joutseno",
            "Lappeenranta"
          ],
          "StateName": "Etela-Karjala"
        },
        {
          "Cities": [
            "Hyllykallio",
            "Ilmajoki",
            "Kauhajoki",
            "Kurikka",
            "Lapua",
            "Seinajoki"
          ],
          "StateName": "Etela-Pohjanmaa"
        },
        {
          "Cities": [
            "Mikkeli",
            "Savonlinna"
          ],
          "StateName": "Etela-Savo"
        },
        {
          "Cities": [],
          "StateName": "Etela-Suomen Laani"
        },
        {
          "Cities": [],
          "StateName": "Ita-Suomen Laani"
        },
        {
          "Cities": [
            "Loviisa",
            "Orimattila",
            "Porvoo"
          ],
          "StateName": "Ita-Uusimaa"
        },
        {
          "Cities": [
            "Kajaani"
          ],
          "StateName": "Kainuu"
        },
        {
          "Cities": [
            "Forssa",
            "Hameenlinna",
            "Janakkala",
            "Riihimaki"
          ],
          "StateName": "Kanta-Hame"
        },
        {
          "Cities": [
            "Kokkola"
          ],
          "StateName": "Keski-Pohjanmaa"
        },
        {
          "Cities": [
            "Aanekoski",
            "Jamsa",
            "Jyvaskyla",
            "Keuruu",
            "Laukaa",
            "Lieto"
          ],
          "StateName": "Keski-Suomi"
        },
        {
          "Cities": [
            "Anjalankoski",
            "Hamina",
            "Kotka",
            "Kouvola",
            "Kuusankoski",
            "Valkeala",
            "Vehkalahti"
          ],
          "StateName": "Kymenlaakso"
        },
        {
          "Cities": [],
          "StateName": "Lansi-Suomen Laani"
        },
        {
          "Cities": [
            "Kemi",
            "Kemijarvi",
            "Rovaniemi",
            "Sonka",
            "Tornio"
          ],
          "StateName": "Lappi"
        },
        {
          "Cities": [],
          "StateName": "Northern Savonia"
        },
        {
          "Cities": [
            "Jakobstad",
            "Oulunsalo"
          ],
          "StateName": "Ostrobothnia"
        },
        {
          "Cities": [],
          "StateName": "Oulun Laani"
        },
        {
          "Cities": [
            "Heinola",
            "Hollola",
            "Lahti",
            "Nastola"
          ],
          "StateName": "Paijat-Hame"
        },
        {
          "Cities": [
            "Kangasala",
            "Lempaala",
            "Nokia",
            "Pirkkala",
            "Sastamala",
            "Tampere",
            "Valkeakoski",
            "Ylojarvi"
          ],
          "StateName": "Pirkanmaa"
        },
        {
          "Cities": [
            "Kristiinankaupunki",
            "Mustasaari",
            "Pietarsaari",
            "Uusikarlepyy",
            "Vaasa"
          ],
          "StateName": "Pohjanmaa"
        },
        {
          "Cities": [
            "Joensuu",
            "Lieksa"
          ],
          "StateName": "Pohjois-Karjala"
        },
        {
          "Cities": [
            "Haukipudas",
            "Kempele",
            "Kuusamo",
            "Muhos",
            "Nivala",
            "Oulainen",
            "Oulu",
            "Raahe",
            "Ylivieska"
          ],
          "StateName": "Pohjois-Pohjanmaa"
        },
        {
          "Cities": [
            "Iisalmi",
            "Kuopio",
            "Sillinjarvi",
            "Varkaus"
          ],
          "StateName": "Pohjois-Savo"
        },
        {
          "Cities": [
            "Saarijarvi"
          ],
          "StateName": "Saarijarvi"
        },
        {
          "Cities": [
            "Eura",
            "Eurajoki",
            "Harjavalta",
            "Huittinen",
            "Kankaanpaa",
            "Kokemaki",
            "Lappi",
            "Nakkila",
            "Noormarkku",
            "Pori",
            "Rauma",
            "Sakyla",
            "Ulvila",
            "Vammala"
          ],
          "StateName": "Satakunta"
        },
        {
          "Cities": [],
          "StateName": "Southern Savonia"
        },
        {
          "Cities": [],
          "StateName": "Tavastia Proper"
        },
        {
          "Cities": [],
          "StateName": "Uleaborgs Lan"
        },
        {
          "Cities": [
            "Espoo",
            "Hanko",
            "Helsinki",
            "Hyvinkaa",
            "Jarvenpaa",
            "Kauniainen",
            "Kerava",
            "Kirkkonummi",
            "Lohja",
            "Mantsala",
            "Nurmijarvi",
            "Sipoo",
            "Tammisaari",
            "Tuusula",
            "Vantaa",
            "Vihti"
          ],
          "StateName": "Uusimaa"
        },
        {
          "Cities": [
            "Kaarina",
            "Naantali",
            "Parainen",
            "Raisio",
            "Salo",
            "Turku",
            "Uusikaupunki"
          ],
          "StateName": "Varsinais-Suomi"
        }
      ],
      "CountryName": "Finland"
    },
    {
      "States": [
        {
          "Cities": [
            "Amberieu-en-Bugey",
            "Bellegarde-sur-Valserine",
            "Bourg-en-Bresse",
            "Oyonnax"
          ],
          "StateName": "Ain"
        },
        {
          "Cities": [
            "Chateau-Thierry",
            "Chauny",
            "Hirson",
            "Laon",
            "Saint-Quentin",
            "Soissons",
            "Strassbourg",
            "Tergnier"
          ],
          "StateName": "Aisne"
        },
        {
          "Cities": [],
          "StateName": "Albi Le Sequestre"
        },
        {
          "Cities": [
            "Cusset",
            "Montlucon",
            "Moulins",
            "Vichy",
            "Yzeure"
          ],
          "StateName": "Allier"
        },
        {
          "Cities": [],
          "StateName": "Alpes-Cote dAzur"
        },
        {
          "Cities": [
            "Antibes",
            "Beausoleil",
            "Cagnes-sur-Mer",
            "Cannes",
            "Carros",
            "Grasse",
            "La Trinite",
            "Le Cannet",
            "Mandelieu-la-Napoule",
            "Menton",
            "Mougins",
            "Nice",
            "Roquebrune-Cap-Martin",
            "Saint-Laurent-du-Var",
            "Valbonne",
            "Vallauris",
            "Vence",
            "Villeneuve-Loubet"
          ],
          "StateName": "Alpes-Maritimes"
        },
        {
          "Cities": [
            "Digne-les-Bains",
            "Manosque"
          ],
          "StateName": "Alpes-de-Haute-Provence"
        },
        {
          "Cities": [
            "Adainville",
            "Alby-sur-Cheran",
            "Aubervilliers",
            "Auterive",
            "Baillet",
            "Beaune",
            "Beauzelle",
            "Bonnet De Mure",
            "Bormes-les-Mimosas",
            "Brindas",
            "Burlats",
            "Buzancais",
            "Candillargues",
            "Carry-le-Rouet",
            "Cassis",
            "Cessenon-Sur-Orb",
            "Chanac",
            "Chevigny",
            "Cogolin",
            "Collioure",
            "Cremieu",
            "Drulingen",
            "Ecouen",
            "Eschau",
            "Feignies",
            "Ferus",
            "Fourqueux",
            "Franqueville",
            "Gignac",
            "Gravigny",
            "Hangenbieten",
            "Hunspach",
            "Kilstett",
            "La Chevroliere",
            "La-Fare-Les-Oliviers",
            "Lanvellec",
            "Le Faget",
            "Lesigny",
            "Lesquin",
            "Limonest",
            "Messein",
            "Morgat",
            "Mundolsheim",
            "Nantiat",
            "Niederbronn-les-Bain",
            "Nimes",
            "Opoul",
            "Pance",
            "Peronne",
            "Ploneour Lanvern",
            "Realmont",
            "Reichstett",
            "Saint Aubin",
            "Saint Christophe",
            "Saint Martin dÃÆÃÂ¢ÃâÃâ¬ÃâÃâ¢Here",
            "Saint-Berthevin",
            "Saint-Cergues",
            "Sancerre",
            "Sarzeau",
            "Seltz",
            "Seyssins",
            "Souffelweyersheim",
            "Vireux-Molhain",
            "Voves",
            "Wambrechies",
            "Wervocq Sud"
          ],
          "StateName": "Alsace"
        },
        {
          "Cities": [
            "Abzac",
            "Bidart",
            "Biganos",
            "Buzet-sur-Baise",
            "Coursac",
            "Hasparren",
            "Landiras",
            "Le Haillan",
            "Ledat",
            "Martillac",
            "Puyoo",
            "Saint-Jean-dIllac",
            "Seignosse",
            "Tresses"
          ],
          "StateName": "Aquitaine"
        },
        {
          "Cities": [
            "Annonay",
            "Aubenas",
            "Guilherand-Granges",
            "Privas",
            "Tournon-sur-Rhone"
          ],
          "StateName": "Ardeche"
        },
        {
          "Cities": [
            "Charleville-Mezieres",
            "Les Hautes-Rivieres",
            "Revin",
            "Sedan"
          ],
          "StateName": "Ardennes"
        },
        {
          "Cities": [
            "Foix",
            "Pamier"
          ],
          "StateName": "Ariege"
        },
        {
          "Cities": [
            "Bar-sur-Seine",
            "Romilly-sur-Seine",
            "Saint-Andre-les-Vergers",
            "Sainte-Savine",
            "Troyes"
          ],
          "StateName": "Aube"
        },
        {
          "Cities": [
            "Carcassonne",
            "Castelnaudary",
            "Limoux",
            "Narbonne"
          ],
          "StateName": "Aude"
        },
        {
          "Cities": [
            "Ambert",
            "Creuzier-le-Vieux",
            "Ferrieres",
            "Peschadoires",
            "Riotord",
            "Saint-Pal-de-Chalencon",
            "Saint-Romain-Lachalm",
            "Saint-Vidal",
            "Sainte-Sigolene"
          ],
          "StateName": "Auvergne"
        },
        {
          "Cities": [
            "Millau",
            "Onet-le-Chataeu",
            "Rodez",
            "Villefranche-de-Rouergue"
          ],
          "StateName": "Aveyron"
        },
        {
          "Cities": [
            "Bischheim",
            "Bischwiller",
            "Haguenau",
            "Hoenheim",
            "Illkirch-Graffenstaden",
            "Lingolsheim",
            "Obernai",
            "Ostwald",
            "Saverne",
            "Schiltigheim",
            "Selestat",
            "Strasbourg"
          ],
          "StateName": "Bas-Rhin"
        },
        {
          "Cities": [
            "Deauville"
          ],
          "StateName": "Basse-Normandie"
        },
        {
          "Cities": [
            "Aix-en-Provence",
            "Allauch",
            "Arles",
            "Aubagne",
            "Berre-l'Etang",
            "Bouc-Bel-Air",
            "Chateauneuf-les-Martigues",
            "Chateaurenard",
            "Fos-sur-Mer",
            "Gardanne",
            "Istres",
            "La Ciotat",
            "Les Pennes-Mirabeau",
            "Maillane",
            "Marignane",
            "Marseille",
            "Martigues",
            "Miramas",
            "Plan-de-Cuques",
            "Port-de-Bouc",
            "Rognac",
            "Saint-Martin-de-Crau",
            "Saint-Remy-de-Provence",
            "Salon-de-Provence",
            "Septemes-les-Vallons",
            "Tarascon",
            "Vitrolles"
          ],
          "StateName": "Bouches-du-Rhone"
        },
        {
          "Cities": [
            "Migennes"
          ],
          "StateName": "Bourgogne"
        },
        {
          "Cities": [
            "Javene",
            "Plouha"
          ],
          "StateName": "Bretagne"
        },
        {
          "Cities": [
            "Brehan",
            "Broons",
            "Guipry",
            "Miniac-Morvan",
            "Ploudaniel",
            "Vern-sur-Seiche"
          ],
          "StateName": "Brittany"
        },
        {
          "Cities": [
            "Aillant-sur-Tholon"
          ],
          "StateName": "Burgundy"
        },
        {
          "Cities": [
            "Bayeux",
            "Caen",
            "Herouville-Saint-Clair",
            "Lisieux",
            "Mondeville",
            "Vire"
          ],
          "StateName": "Calvados"
        },
        {
          "Cities": [
            "Aurillac"
          ],
          "StateName": "Cantal"
        },
        {
          "Cities": [
            "Brumath",
            "Courtaboeuf",
            "Iregny",
            "cedex"
          ],
          "StateName": "Cedex"
        },
        {
          "Cities": [
            "Azay-le-Rideau",
            "Chevillon-sur-Huillard",
            "Cloyes-sur-le-Loir",
            "Gellainville",
            "La Chaussse-Saint-Victor",
            "La Ville-aux-Clercs",
            "Ladon",
            "Le Chatelet"
          ],
          "StateName": "Centre"
        },
        {
          "Cities": [
            "Angouleme",
            "Cognac",
            "Lencloitre",
            "Soyaux"
          ],
          "StateName": "Charente"
        },
        {
          "Cities": [
            "La Rochelle",
            "Rochefort",
            "Royan",
            "Saintes"
          ],
          "StateName": "Charente-Maritime"
        },
        {
          "Cities": [
            "Bourges",
            "Saint-Amand-Montrond",
            "Saint-Doulchard",
            "Vierzon"
          ],
          "StateName": "Cher"
        },
        {
          "Cities": [
            "Brive-la-Gaillarde",
            "Tulle",
            "Ussel"
          ],
          "StateName": "Correze"
        },
        {
          "Cities": [
            "Ajaccio",
            "Porto-Vecchio"
          ],
          "StateName": "Corse-du-Sud"
        },
        {
          "Cities": [
            "Beaune",
            "Chenove",
            "Dijon",
            "Quetigny",
            "Talant"
          ],
          "StateName": "Cote-d'Or"
        },
        {
          "Cities": [
            "Dinan",
            "Lamballe",
            "Lannion",
            "Loudeac",
            "Plerin",
            "Ploufragan",
            "Saint-Brieuc"
          ],
          "StateName": "Cotes-d'Armor"
        },
        {
          "Cities": [
            "Gueret"
          ],
          "StateName": "Creuse"
        },
        {
          "Cities": [
            "Crolles"
          ],
          "StateName": "Crolles"
        },
        {
          "Cities": [
            "Bressuire",
            "Niort",
            "Parthenay",
            "Thouars"
          ],
          "StateName": "Deux-Sevres"
        },
        {
          "Cities": [
            "Bergerac",
            "Perigueux",
            "Sarlat-la-Caneda"
          ],
          "StateName": "Dordogne"
        },
        {
          "Cities": [
            "Audincourt",
            "Besancon",
            "Beure",
            "Montbeliard",
            "Pontarlier",
            "Valentigney"
          ],
          "StateName": "Doubs"
        },
        {
          "Cities": [
            "Bourg-les-Valence",
            "Montelimar",
            "Pierrelatte",
            "Romans-sur-Isere",
            "Valence"
          ],
          "StateName": "Drome"
        },
        {
          "Cities": [
            "Athis-Mons",
            "Bretigny-sur-Orge",
            "Brunoy",
            "Bures-sur-Yvette",
            "Chilly-Mazarin",
            "Corbeil-Essonnes",
            "Courcouronnes",
            "Dourdan",
            "Draveil",
            "Epinay-sous-Senart",
            "Epinay-sur-Orge",
            "Etampes",
            "Evry",
            "Fleury-Merogis",
            "Gif-sur-Yvette",
            "Grigny",
            "Igny",
            "Juvisy-sur-Orge",
            "Les Ulis",
            "Longjumeau",
            "Massy",
            "Mennecy",
            "Montgeron",
            "Morangis",
            "Morsang-sur-Orge",
            "Orsay",
            "Palaiseau",
            "Ris-Orangis",
            "Saint-Michel-sur-Orge",
            "Sainte-Genevieve-des-Bois",
            "Savigny-sur-Orge",
            "Verrieres-le-Buisson",
            "Vigneux-sur-Seine",
            "Villebon-sur-Yvette",
            "Viry-Chatillon",
            "Yerres"
          ],
          "StateName": "Essonne"
        },
        {
          "Cities": [
            "Bernay",
            "Evreux",
            "Gisors",
            "Louviers",
            "Pont-Audemer",
            "Val-de-Reuil",
            "Vernon"
          ],
          "StateName": "Eure"
        },
        {
          "Cities": [
            "Chartres",
            "Chateaudun",
            "Dreux",
            "Luce",
            "Mainvillier",
            "Nogent-le-Rotrou",
            "Vernouillet"
          ],
          "StateName": "Eure-et-Loir"
        },
        {
          "Cities": [
            "Feucherolles"
          ],
          "StateName": "Feucherolles"
        },
        {
          "Cities": [
            "Brest",
            "Concarneau",
            "Douarnenez",
            "Guipavas",
            "Landerneau",
            "Le Relecq-Kerhoun",
            "Morlaix",
            "Plougastel-Daoulas",
            "Plouzane",
            "Quimper",
            "Quimperle"
          ],
          "StateName": "Finistere"
        },
        {
          "Cities": [
            "Charquemont",
            "Chemaudin",
            "Pelousey",
            "Perrigny",
            "Pirey",
            "Villers-le-Lac"
          ],
          "StateName": "Franche-Comte"
        },
        {
          "Cities": [
            "Ales",
            "Bagnols-sur-Ceze",
            "Beaucaire",
            "Nimes",
            "Pont-Saint-Esprit",
            "Saint-Gilles",
            "Vauvert",
            "Villeneuve-les-Avignon"
          ],
          "StateName": "Gard"
        },
        {
          "Cities": [
            "Auch",
            "Beraut"
          ],
          "StateName": "Gers"
        },
        {
          "Cities": [
            "Ambares-et-Lagrave",
            "Arcachon",
            "Begles",
            "Blanquefort",
            "Bordeaux",
            "Bruges",
            "Cenon",
            "Cestas",
            "Eysines",
            "Floirac",
            "Gradignan",
            "Gujan-Mestras",
            "La Teste-de-Buch",
            "Le Bouscat",
            "Libourne",
            "Lormont",
            "Merignac",
            "Pessac",
            "Saint-Medard-en-Jalles",
            "Talence",
            "Villenave-d'Ornon"
          ],
          "StateName": "Gironde"
        },
        {
          "Cities": [
            "Cernay",
            "Colmar",
            "Guebwiller",
            "Illzach",
            "Kingersheim",
            "Mulhouse",
            "Riediesheim",
            "Rixheim",
            "Saint-Louis",
            "Wittelsheim",
            "Wittenheim"
          ],
          "StateName": "Haut-Rhin"
        },
        {
          "Cities": [
            "Bastia"
          ],
          "StateName": "Haute-Corse"
        },
        {
          "Cities": [
            "Balma",
            "Blagnac",
            "Colomiers",
            "Cugnaux",
            "L'Union",
            "Muret",
            "Plaisance-du-Touch",
            "Ramonville-Saint-Agne",
            "Saint-Gaudens",
            "Saint-Orens-de-Gameville",
            "Toulouse",
            "Tournefeuille"
          ],
          "StateName": "Haute-Garonne"
        },
        {
          "Cities": [
            "Blanzac",
            "Le Puy-en-Velay"
          ],
          "StateName": "Haute-Loire"
        },
        {
          "Cities": [
            "Chaumont",
            "Langres",
            "Saint-Dizier"
          ],
          "StateName": "Haute-Marne"
        },
        {
          "Cities": [
            "Hericourt",
            "Lure",
            "Luxeuil-les-Bains",
            "Vesoul"
          ],
          "StateName": "Haute-Saone"
        },
        {
          "Cities": [
            "Annecy",
            "Annecy-le-Vieux",
            "Annemasse",
            "Archamps",
            "Bonneville",
            "Chamonix-Mont-Blanc",
            "Cluses",
            "Contamine sur Arve",
            "Cran-Gevrier",
            "Passy",
            "Rumilly",
            "Sallanches",
            "Seynod",
            "Thonon-les-Bains"
          ],
          "StateName": "Haute-Savoie"
        },
        {
          "Cities": [
            "Gaillard",
            "Limoges",
            "Saint-Junien"
          ],
          "StateName": "Haute-Vienne"
        },
        {
          "Cities": [
            "Briancon",
            "Gap"
          ],
          "StateName": "Hautes-Alpes"
        },
        {
          "Cities": [
            "Lourdes",
            "Tarbes"
          ],
          "StateName": "Hautes-Pyrenees"
        },
        {
          "Cities": [
            "Antony",
            "Asnieres-sur-Seine",
            "Bagneux",
            "Bois-Colombes",
            "Boulogne-Billancourt",
            "Bourg-la-Reine",
            "Chatenay-Malabry",
            "Chatillon",
            "Chaville",
            "Clamart",
            "Clichy",
            "Colombes",
            "Courbevoie",
            "Fontenay-aux-Roses",
            "Garches",
            "Gennevillers",
            "Issy-les-Moulineaux",
            "La Garenne-Colombes",
            "Le Plessis-Robinson",
            "Levallois-Perret",
            "Malakoff",
            "Meudon",
            "Montrouge",
            "Nanterre",
            "Neuilly-sur-Seine",
            "Puteaux",
            "Rueil-Malmaison",
            "Saint-Cloud",
            "Sceaux",
            "Sevres",
            "Suresnes",
            "Vanves",
            "Ville-d'Avray",
            "Villeneuve-la-Garenne"
          ],
          "StateName": "Hauts-de-Seine"
        },
        {
          "Cities": [
            "Agde",
            "Beziers",
            "Castelnau-le-Lez",
            "Frontignan",
            "Lattes",
            "Lunel",
            "Mauguio",
            "Montpellier",
            "Sete"
          ],
          "StateName": "Herault"
        },
        {
          "Cities": [
            "Champagne-sur-Oise",
            "Croissy-Beaubourg",
            "Gennevilliers",
            "Le Mesnil-le-Roi",
            "Le Plessis-Bouchard",
            "Rebais",
            "Saint-Thibault-des-Vignes"
          ],
          "StateName": "Ile-de-France"
        },
        {
          "Cities": [
            "Cesson-Sevigne",
            "Dinard",
            "Fougeres",
            "Rennes",
            "Saint-Malo",
            "Vitre"
          ],
          "StateName": "Ille-et-Vilaine"
        },
        {
          "Cities": [
            "Chateauroux",
            "Issoudun"
          ],
          "StateName": "Indre"
        },
        {
          "Cities": [
            "Amboise",
            "Joue-les-Tours",
            "Saint-Avertin",
            "Saint-Cyr-sur-Loire",
            "Saint-Pierre-des-Corps",
            "Tours"
          ],
          "StateName": "Indre-et-Loire"
        },
        {
          "Cities": [
            "Bourgoin-Jallieu",
            "Crolles",
            "Echirolles",
            "Fontaine",
            "Grenoble",
            "Le Pont-de-Claix",
            "Meylan",
            "Saint-Egreve",
            "Saint-Martin-d'Heres",
            "Seyssinet-Pariset",
            "Vienne",
            "Villefontaine",
            "Voiron"
          ],
          "StateName": "Isere"
        },
        {
          "Cities": [
            "Champagnole",
            "Dole",
            "Lons-le-Saunier",
            "Saint-Claude"
          ],
          "StateName": "Jura"
        },
        {
          "Cities": [
            "Sebastiangasse"
          ],
          "StateName": "Klagenfurt"
        },
        {
          "Cities": [
            "Biscarrosse",
            "Dax",
            "Hagetmau",
            "Landes",
            "Mont-de-Marsan",
            "Saint-Paul-les-Dax",
            "Tarnos"
          ],
          "StateName": "Landes"
        },
        {
          "Cities": [
            "Codolet",
            "Cuxac-d'Aude",
            "Gigean",
            "Grabels",
            "Lamalou-les-Bains",
            "Perols",
            "Peyrens",
            "Tuchan"
          ],
          "StateName": "Languedoc-Roussillon"
        },
        {
          "Cities": [
            "Larcay"
          ],
          "StateName": "Larcay"
        },
        {
          "Cities": [],
          "StateName": "Le Castellet"
        },
        {
          "Cities": [],
          "StateName": "Le Creusot"
        },
        {
          "Cities": [
            "Voutezac"
          ],
          "StateName": "Limousin"
        },
        {
          "Cities": [
            "Blois",
            "Romorantin-Lanthenay",
            "Vendome"
          ],
          "StateName": "Loir-et-Cher"
        },
        {
          "Cities": [
            "Andrezieux-Boutheon",
            "Firminy",
            "La Ricamarie",
            "Le Chambon-Feugerolles",
            "Montbrison",
            "Riorges",
            "Rive-de-Gier",
            "Roanne",
            "Roche-la-Moliere",
            "Saint-Chamond",
            "Saint-Etienne",
            "Saint-Just-Saint-Rambert"
          ],
          "StateName": "Loire"
        },
        {
          "Cities": [
            "Bouguenais",
            "Carquefou",
            "Chateaubriant",
            "Coueron",
            "Guerande",
            "La Baule-Escoublac",
            "La Chapelle-sur-Erdre",
            "Nantes",
            "Orvault",
            "Reze",
            "Saint Etienne de Mer Morte",
            "Saint-Herblain",
            "Saint-Nazaire",
            "Saint-Sebastien-sur-Loire",
            "Sainte-Luce-sur-Loire",
            "Vertou"
          ],
          "StateName": "Loire-Atlantique"
        },
        {
          "Cities": [
            "Amilly",
            "Chalette-sur-Loing",
            "Fleury-les-Aubrais",
            "Gien",
            "Montargis",
            "Olivet",
            "Orleans",
            "Pithiviers",
            "Saint-Jean-de-Braye",
            "Saint-Jean-de-la-Ruelle",
            "Saran"
          ],
          "StateName": "Loiret"
        },
        {
          "Cities": [
            "Montbronn"
          ],
          "StateName": "Lorraine"
        },
        {
          "Cities": [
            "Cahors",
            "Figeac"
          ],
          "StateName": "Lot"
        },
        {
          "Cities": [
            "Agen",
            "Le Passage",
            "Marmande",
            "Tonneins",
            "Villeneuve-sur-Lot"
          ],
          "StateName": "Lot-et-Garonne"
        },
        {
          "Cities": [],
          "StateName": "Lower Normandy"
        },
        {
          "Cities": [
            "Mende"
          ],
          "StateName": "Lozere"
        },
        {
          "Cities": [
            "Angers",
            "Avrille",
            "Cholet",
            "Les Ponts-de-Ce",
            "Saint-Barthelemy-d'Anjou",
            "Saumur",
            "Torfou",
            "Trelaze"
          ],
          "StateName": "Maine-et-Loire"
        },
        {
          "Cities": [
            "Cherbourg",
            "Coutances",
            "Equeurdreville-Hainneville",
            "Granville",
            "Octeville",
            "Saint-Lo",
            "Tourlaville"
          ],
          "StateName": "Manche"
        },
        {
          "Cities": [
            "Chalons-en-Champagne",
            "Epernay",
            "Reims",
            "Tinqueux",
            "Vitry-le-Francois"
          ],
          "StateName": "Marne"
        },
        {
          "Cities": [
            "Chateau-Gontier",
            "Laval",
            "Mayenne",
            "Montsurs"
          ],
          "StateName": "Mayenne"
        },
        {
          "Cities": [
            "Dombasle-sur-Meurthe",
            "Jarville-la-Malgrange",
            "Laxou",
            "Longwy",
            "Luneville",
            "Nancy",
            "Pont-a-Mousson",
            "Saint-Max",
            "Toul",
            "Vandoeuvre-les-Nancy",
            "Villers-les-Nancy",
            "Villerupt"
          ],
          "StateName": "Meurthe-et-Moselle"
        },
        {
          "Cities": [
            "Bar-le-Duc",
            "Verdun"
          ],
          "StateName": "Meuse"
        },
        {
          "Cities": [
            "Boissezon",
            "Hauterive",
            "Launaguet",
            "Mauleon",
            "Maurens-Scopont"
          ],
          "StateName": "Midi-Pyrenees"
        },
        {
          "Cities": [
            "Auray",
            "Guidel",
            "Hennebont",
            "Lanester",
            "Lorient",
            "Ploemeur",
            "Pontivy",
            "Vannes"
          ],
          "StateName": "Morbihan"
        },
        {
          "Cities": [
            "Amneville",
            "Behren-les-Forbach",
            "Creutzwald",
            "Fameck",
            "Florange",
            "Forbach",
            "Freyming-Merlebach",
            "Hagondange",
            "Hayange",
            "Hombourg-Haut",
            "Maizieres-les-Metz",
            "Marly",
            "Metz",
            "Montigny-les-Metz",
            "Moyeuvre-Grande",
            "Rombas",
            "Saint-Avold",
            "Sarrebourg",
            "Sarreguemines",
            "Stiring-Wendel",
            "Thionville",
            "Uckange",
            "Woippy",
            "Yutz"
          ],
          "StateName": "Moselle"
        },
        {
          "Cities": [
            "Cosne-Cours-sur-Loire",
            "Nevers",
            "Varennes-Vauzelles"
          ],
          "StateName": "Nievre"
        },
        {
          "Cities": [
            "Aniche",
            "Annoeullin",
            "Anzin",
            "Armentieres",
            "Aulnoye-Aymeries",
            "Bailleul",
            "Bondues",
            "Bruay-sur-l'Escaut",
            "Cambrai",
            "Cappelle-la-Grande",
            "Caudry",
            "Comines",
            "Conde-sur-l'Escaut",
            "Coudekerque-Branche",
            "Croix",
            "Denain",
            "Douai",
            "Douchy-les-Mines",
            "Dunkerque",
            "Escaudain",
            "Fache-Thumesnil",
            "Fourmies",
            "Grande-Synthe",
            "Graveline",
            "Halluin",
            "Haubourdin",
            "Hautmont",
            "Hazebrouck",
            "Hem",
            "Hulluch",
            "Jeumont",
            "La Madeleine",
            "Lambersart",
            "Leers",
            "Lille",
            "Lomme",
            "Loos",
            "Lys-lez-Lannoy",
            "Marcq-en-Baroeul",
            "Marennes",
            "Marly",
            "Marquette-lez-Lille",
            "Maubeuge",
            "Merville",
            "Mons-en-Baroeul",
            "Mouvaux",
            "Neuville-en-Ferrain",
            "Onnaing",
            "Raismes",
            "Ronchin",
            "Roncq",
            "Roubaix",
            "Saint-Amand-les-Eaux",
            "Saint-Andre-lez-Lille",
            "Saint-Pol-sur-Mer",
            "Saint-Saulve",
            "Seclin",
            "Sin-le-Noble",
            "Somain",
            "Tourcoing",
            "Valenciennes",
            "Vieux-Conde",
            "Villeneuve-d'Ascq",
            "Wasquehal",
            "Wattignies",
            "Wattrelos",
            "Waziers"
          ],
          "StateName": "Nord"
        },
        {
          "Cities": [
            "Esquelbecq"
          ],
          "StateName": "Nord-Pas-de-Calais"
        },
        {
          "Cities": [
            "Beauvais",
            "Chantilly",
            "Clermont",
            "Compiegne",
            "Creil",
            "Crepy-en-Valois",
            "Gouvieux",
            "Meru",
            "Montataire",
            "Nogent-sur-Oise",
            "Noyon",
            "Pont-Sainte-Maxence",
            "Senlis"
          ],
          "StateName": "Oise"
        },
        {
          "Cities": [
            "Alencon",
            "Argentan",
            "Flers",
            "L'Aigle"
          ],
          "StateName": "Orne"
        },
        {
          "Cities": [
            "Paris"
          ],
          "StateName": "Paris"
        },
        {
          "Cities": [
            "Aire-sur-la-Lys",
            "Arras",
            "Auchel",
            "Avion",
            "Berck",
            "Bethune",
            "Boulogne-sur-Mer",
            "Bruay-la-Brussiere",
            "Bully-les-Mines",
            "Calais",
            "Carvin",
            "Courrieres",
            "Etaples",
            "Harnes",
            "Henin-Beaumont",
            "Le Portel",
            "Lens",
            "Libercourt",
            "Lievin",
            "Lillers",
            "Longuenesse",
            "Marck",
            "Mericourt",
            "Montigny-en-Gohelle",
            "Noeux-les-Mines",
            "Oignies",
            "Outreau",
            "Rouvroy",
            "Saint-Martin-Boulogne",
            "Saint-Omer",
            "Sallaumines",
            "Vendin-le-Vieil"
          ],
          "StateName": "Pas-de-Calais"
        },
        {
          "Cities": [],
          "StateName": "Pays de la Loire"
        },
        {
          "Cities": [
            "Loiron",
            "Marolles-les-Braults",
            "Mortagne-sur-Sevre",
            "Mouzillon",
            "Noirmoutier-en-l'ÃÆÃÅ½le"
          ],
          "StateName": "Pays-de-la-Loire"
        },
        {
          "Cities": [
            "Friville",
            "Liancourt",
            "Maizy",
            "Oust-Marest",
            "Puiseux-le-Hauberger",
            "Saint-Crepin-Ibouvillers"
          ],
          "StateName": "Picardy"
        },
        {
          "Cities": [
            "Aubiere",
            "Beaumont",
            "Chamalieres",
            "Clermont-Ferrand",
            "Cournon-d'Auvergne",
            "Gerzat",
            "Issoire",
            "Riom",
            "Thiers"
          ],
          "StateName": "Puy-de-Dome"
        },
        {
          "Cities": [
            "Anglet",
            "Bayonne",
            "Biarritz",
            "Billere",
            "Hendaye",
            "Lons",
            "Oloron-Sainte-Marie",
            "Orthez",
            "Pau",
            "Saint-Jean-de-Luz"
          ],
          "StateName": "Pyrenees-Atlantiques"
        },
        {
          "Cities": [
            "Perpignan",
            "Saint-Esteve"
          ],
          "StateName": "Pyrenees-Orientales"
        },
        {
          "Cities": [
            "Quelmes"
          ],
          "StateName": "Quelmes"
        },
        {
          "Cities": [
            "Brignais",
            "Bron",
            "Caluire-et-Cuire",
            "Decines-Charpieu",
            "Ecully",
            "Francheville",
            "Genas",
            "Genay",
            "Givors",
            "Lyon",
            "Meyzieu",
            "Mions",
            "Oullins",
            "Pierre-Benite",
            "Rillieux-la-Pape",
            "Saint-Fons",
            "Saint-Genis-Laval",
            "Saint-Priest",
            "Sainte-Foy-les-Lyon",
            "Tarare",
            "Tassin-la-Demi-Lune",
            "Vaulx-en-Velin",
            "Venissieux",
            "Villefranche-sur-Saone",
            "Villeurbanne"
          ],
          "StateName": "Rhone"
        },
        {
          "Cities": [
            "Beauvoir-en-Royans",
            "Belley",
            "Bons-en-Chablais",
            "Chalain-d'Uzore",
            "Chassieu",
            "Chavanod",
            "Chazay-d-Azergues",
            "Chimilin",
            "Civrieux-d Azergues",
            "Corbas",
            "Courzieu",
            "Dardilly",
            "Guereins",
            "Izernore",
            "La Talaudiere",
            "La Tronche",
            "La Verpilliere",
            "Le Cheylard",
            "Le Cheylas",
            "Mery",
            "Moirans",
            "Montalieu-Vercieu",
            "Montmiral",
            "Peronnas",
            "Poncin",
            "Quincie-en-Beaujolais",
            "Saint-Quentin-sur-Isere",
            "Sainte Agathe la Bouteresse",
            "Sainte-Consorce",
            "Sisteron",
            "Trevoux",
            "Villard-Bonnot"
          ],
          "StateName": "Rhone-Alpes"
        },
        {
          "Cities": [],
          "StateName": "Saint Ouen"
        },
        {
          "Cities": [],
          "StateName": "Saint Viatre"
        },
        {
          "Cities": [
            "Autun",
            "Chalon-sur-Saone",
            "Digoin",
            "Gueugnon",
            "Le Creusot",
            "Macon",
            "Montceau-les-Mines",
            "Paray-le-Monial",
            "Saint-Vallier"
          ],
          "StateName": "Saone-et-Loire"
        },
        {
          "Cities": [
            "Allonnes",
            "La Ferte-Bernard",
            "La Fleche",
            "Le Mans",
            "Sable-sur-Sarthe"
          ],
          "StateName": "Sarthe"
        },
        {
          "Cities": [
            "Aix-les-Bains",
            "Albertville",
            "Chambery",
            "La Motte-Servolex",
            "Saint-Jean-de-Maurienne"
          ],
          "StateName": "Savoie"
        },
        {
          "Cities": [
            "Barentin",
            "Bihorel",
            "Bois-Guillaume",
            "Bolbec",
            "Canteleu",
            "Caudebec-les-Elbeuf",
            "Darnetal",
            "Deville-les-Rouen",
            "Dieppe",
            "Elbeuf",
            "Fecamp",
            "Gonfreville-l'Orcher",
            "Grand-Couronne",
            "Harfleur",
            "Le Grand-Quevilly",
            "Le Havre",
            "Le Petit-Quevilly",
            "Lillebonne",
            "Maromme",
            "Mont-Saint-Aignan",
            "Montivilliers",
            "Notre-Dame-de-Gravenchon",
            "Oissel",
            "Rouen",
            "Saint-Etienne-du-Rouvray",
            "Sotteville-les-Rouen",
            "Yvetot"
          ],
          "StateName": "Seine-Maritime"
        },
        {
          "Cities": [
            "Aubervillers",
            "Aulnay-sous-Bois",
            "Bagnolet",
            "Bobigny",
            "Bondy",
            "Clichy-sous-Bois",
            "Drancy",
            "Epinay-sur-Seine",
            "Gagny",
            "La Courneuve",
            "Le Blanc-Mesnil",
            "Le Bourget",
            "Le Pre-Saint-Gervais",
            "Le Raincy",
            "Les Lilas",
            "Les Pavillons-sous-Bois",
            "Livry-Gargan",
            "Montfermeil",
            "Montreuil",
            "Neuilly-Plaisance",
            "Neuilly-sur-Marne",
            "Noisy-le-Grand",
            "Noisy-le-Sec",
            "Pantin",
            "Pierrefitte-sur-Seine",
            "Romainville",
            "Rosny-sous-Bois",
            "Saint-Denis",
            "Saint-Ouen",
            "Sevran",
            "Stains",
            "Tremblay-en-France",
            "Villemomble",
            "Villepinte",
            "Villetaneuse"
          ],
          "StateName": "Seine-Saint-Denis"
        },
        {
          "Cities": [
            "Avon",
            "Brie-Comte-Robert",
            "Champs-sur-Marne",
            "Chelles",
            "Claye-Souilly",
            "Combs-la-Ville",
            "Coulommiers",
            "Dammarie-les-Lys",
            "Fontainebleau",
            "Lagny-sur-Marne",
            "Le Mee-sur-Seine",
            "Lognes",
            "Meaux",
            "Melun",
            "Mitry-Mory",
            "Moissy-Cramayel",
            "Montereau-Fault-Yonne",
            "Nemours",
            "Noisiel",
            "Ozoir-la-Ferriere",
            "Pontault-Combault",
            "Provins",
            "Roissy-en-Brie",
            "Saint-Fargeau-Ponthierry",
            "Savigny-le-Temple",
            "Torcy",
            "Vaires-sur-Marne",
            "Veneux",
            "Villeparisis"
          ],
          "StateName": "Seine-et-Marne"
        },
        {
          "Cities": [
            "Abbeville",
            "Albert",
            "Amiens"
          ],
          "StateName": "Somme"
        },
        {
          "Cities": [],
          "StateName": "Sophia Antipolis"
        },
        {
          "Cities": [
            "Souvans"
          ],
          "StateName": "Souvans"
        },
        {
          "Cities": [
            "Albi",
            "Carmaux",
            "Castres",
            "Gaillac",
            "Graulhet",
            "Mazamet"
          ],
          "StateName": "Tarn"
        },
        {
          "Cities": [
            "Castelsarassin",
            "Moissac",
            "Montauban"
          ],
          "StateName": "Tarn-et-Garonne"
        },
        {
          "Cities": [],
          "StateName": "Territoire de Belfort"
        },
        {
          "Cities": [
            "Chamberet"
          ],
          "StateName": "Treignac"
        },
        {
          "Cities": [],
          "StateName": "Upper Normandy"
        },
        {
          "Cities": [
            "Argenteuil",
            "Arnouville-les-Gonesse",
            "Beauchamps",
            "Bezons",
            "Cergy",
            "Cormeilles-en-Parisis",
            "Deuil-la-Barre",
            "Domont",
            "Eaubonne",
            "Enghien-les-Bains",
            "Eragny",
            "Ermont",
            "Ezanville",
            "Fosses",
            "Franconville",
            "Garges-les-Gonesse",
            "Gonesse",
            "Goussainville",
            "Herblay",
            "Jouy-le-Moutier",
            "L'Isle-Adam",
            "Montigny-les-Cormeilles",
            "Montmagny",
            "Montmorency",
            "Osny",
            "Persan",
            "Pontoise",
            "Saint-Brice-sous-Foret",
            "Saint-Gratien",
            "Saint-Leu-la-Foret",
            "Saint-Ouen-l'Aumone",
            "Sannois",
            "Sarcelles",
            "Soisy-sous-Montmorency",
            "Taverny",
            "Vaureal",
            "Villiers-le-Bel"
          ],
          "StateName": "Val-d'Oise"
        },
        {
          "Cities": [
            "Alfortville",
            "Arcueil",
            "Boissy-Saint-Leger",
            "Bonneuil",
            "Bry-sur-Marne",
            "Cachan",
            "Champigny-sur-Marne",
            "Charenton-le-Pont",
            "Chennevieres-sur-Marne",
            "Chevilly-Larue",
            "Choisy-le-Roi",
            "Creteil",
            "Fontenay-sous-Bois",
            "Fresnes",
            "Gentilly",
            "Ivry-sur-Seine",
            "Joinville-le-Pont",
            "L'Hay-les-Roses",
            "La Queue-en-Brie",
            "Le Kremlin-Bicetre",
            "Le Perreux-sur-Marne",
            "Le Plessis-Trevise",
            "Limeil-Brevannes",
            "Maisons-Alfort",
            "Nogent-sur-Marne",
            "Orly",
            "Ormesson-sur-Marne",
            "Saint-Mande",
            "Saint-Maur-des-Fosses",
            "Saint-Maurice",
            "Sucy-en-Brie",
            "Thiais",
            "Valenton",
            "Villejuif",
            "Villeneuve-Saint-Georges",
            "Villeneuve-le-Roi",
            "Villiers-sur-Marne",
            "Vincennes",
            "Vitry-sur-Seine"
          ],
          "StateName": "Val-de-Marne"
        },
        {
          "Cities": [
            "Brignoles",
            "Draguignan",
            "Frejus",
            "Hyeres",
            "La Crau",
            "La Garde",
            "La Seyne-sur-Mer",
            "La Valette-du-Var",
            "Le Pradet",
            "Ollioules",
            "Roquebrune-sur-Argens",
            "Saint-Maximin-la-Sainte-Baume",
            "Saint-Raphael",
            "Sainte-Maxime",
            "Sanary-sur-Mer",
            "Six-Fours-les-Plages",
            "Sollies-Pont",
            "Toulon"
          ],
          "StateName": "Var"
        },
        {
          "Cities": [
            "Apt",
            "Avignon",
            "Bollene",
            "Carpentras",
            "Cavaillon",
            "L'Isle-sur-la-Sorgue",
            "Le Pontet",
            "Orange",
            "Pertuis",
            "Sorgues",
            "Valreas"
          ],
          "StateName": "Vaucluse"
        },
        {
          "Cities": [
            "Vellise"
          ],
          "StateName": "Vellise"
        },
        {
          "Cities": [
            "Challans",
            "Chateau-d'Olonne",
            "Fontenay-le-Comte",
            "La Chasnis",
            "La Roche-sur-Yon",
            "Les Herbiers",
            "Les Sables-d'Olonne",
            "Longeville-sur-Mer"
          ],
          "StateName": "Vendee"
        },
        {
          "Cities": [
            "Chatellerault",
            "Poitiers"
          ],
          "StateName": "Vienne"
        },
        {
          "Cities": [
            "Epinal",
            "Gerardmer",
            "Remiremont",
            "Saint-Die"
          ],
          "StateName": "Vosges"
        },
        {
          "Cities": [
            "Auxerre",
            "Avallon",
            "Joigny",
            "Sens"
          ],
          "StateName": "Yonne"
        },
        {
          "Cities": [
            "Acheres",
            "Andresy",
            "Aubergenville",
            "Bois-d'Arcy",
            "Carrieres-sous-Poissy",
            "Carrieres-sur-Seine",
            "Chanteloup-les-Vignes",
            "Chatou",
            "Conflans-Sainte-Honorine",
            "Croissy-sur-Seine",
            "Elancourt",
            "Fontenay-le-Fleury",
            "Guyancourt",
            "Houilles",
            "La Celle-Saint-Cloud",
            "Le Chesnay",
            "Le Pecq",
            "Le Vesinet",
            "Les Clayes-sous-Bois",
            "Les Mureaux",
            "Limay",
            "Maisons-Laffitte",
            "Mantes-la-Jolie",
            "Mantes-la-Ville",
            "Marly-le-Roi",
            "Maurepas",
            "Montesson",
            "Montigny-le-Bretonneux",
            "Plaisir",
            "Poissy",
            "Rambouillet",
            "Saint-Cyr-l'Ecole",
            "Saint-Germain-en-Laye",
            "Sartrouville",
            "Trappes",
            "Triel-sur-Seine",
            "Velizy-Villacoublay",
            "Verneuil-sur-Seine",
            "Versailles",
            "Viroflay",
            "Voisins-le-Bretonneux"
          ],
          "StateName": "Yvelines"
        }
      ],
      "CountryName": "France"
    },
    {
      "States": [
        {
          "Cities": [
            "Camopi",
            "Cayenne",
            "Iracoubo",
            "Kourou",
            "Macouria",
            "Matoury",
            "Remire-Montjoly",
            "Roura",
            "Saint-Georges",
            "Sinnamary"
          ],
          "StateName": "Cayenne"
        },
        {
          "Cities": [
            "Apatou",
            "Grand-Santi",
            "Mana",
            "Maripasoula",
            "Saint-Laurent-du-Maroni"
          ],
          "StateName": "Saint-Laurent-du-Maroni"
        }
      ],
      "CountryName": "French Guiana"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Iles du Vent"
        },
        {
          "Cities": [],
          "StateName": "Iles sous le Vent"
        },
        {
          "Cities": [
            "Atuona",
            "Fatu-Hiva",
            "Hakahao",
            "Hakamaii",
            "Hatiheu",
            "Tahuata",
            "Taiohae",
            "Taipivai",
            "Ua-Huka"
          ],
          "StateName": "Marquesas"
        },
        {
          "Cities": [
            "Ahe",
            "Amanu",
            "Anaa",
            "Apataki",
            "Arutua",
            "Faaite",
            "Fakahima",
            "Fakarava",
            "Fangatau",
            "Hao",
            "Hereheretue",
            "Hikueru",
            "Katiu",
            "Kauehi",
            "Kaukura",
            "Makatea",
            "Makemo",
            "Manihi",
            "Marokau",
            "Napuka",
            "Niau",
            "Nukutavake",
            "Puamau",
            "Pukapuka",
            "Pukarua",
            "Rangiroa",
            "Raroia",
            "Reao",
            "Rikitea",
            "Taenga",
            "Takapoto",
            "Takaroa",
            "Tatakoto",
            "Tepoto",
            "Tikehau",
            "Tureia",
            "Vahitahi",
            "Vairaatea"
          ],
          "StateName": "Tuamotu"
        },
        {
          "Cities": [
            "Amaru",
            "Anapoto",
            "Anatonu",
            "Auti",
            "Avera",
            "Mahu",
            "Mataura",
            "Moerai",
            "Mutuaura",
            "Rairua",
            "Rapa",
            "Taahuaia",
            "Vaiuru"
          ],
          "StateName": "Tubuai"
        }
      ],
      "CountryName": "French Polynesia"
    },
    {
      "States": [
        {
          "Cities": [
            "Martin-de-Vivies"
          ],
          "StateName": "Amsterdam"
        },
        {
          "Cities": [],
          "StateName": "Crozet Islands"
        },
        {
          "Cities": [
            "Port-aux-Francais"
          ],
          "StateName": "Kerguelen"
        }
      ],
      "CountryName": "French Southern Territories"
    },
    {
      "States": [
        {
          "Cities": [
            "Cocobeach",
            "Kango",
            "Libreville",
            "Nkan",
            "Ntoum"
          ],
          "StateName": "Estuaire"
        },
        {
          "Cities": [
            "Lekoni",
            "Masuku",
            "Moanda",
            "Mounana",
            "Okandja"
          ],
          "StateName": "Haut-Ogooue"
        },
        {
          "Cities": [
            "Lambarene",
            "Ndjole"
          ],
          "StateName": "Moyen-Ogooue"
        },
        {
          "Cities": [
            "Fougamou",
            "Mbigou",
            "Mimongo",
            "Mouila",
            "Ndende"
          ],
          "StateName": "Ngounie"
        },
        {
          "Cities": [
            "Mayumba",
            "Tchibanga",
            "Tsogni"
          ],
          "StateName": "Nyanga"
        },
        {
          "Cities": [
            "Booue",
            "Makokou",
            "Mekambo"
          ],
          "StateName": "Ogooue-Ivindo"
        },
        {
          "Cities": [
            "Koulamoutou",
            "Lastoursville"
          ],
          "StateName": "Ogooue-Lolo"
        },
        {
          "Cities": [
            "Gamba",
            "Omboue",
            "Port-Gentil",
            "Sette Cama"
          ],
          "StateName": "Ogooue-Maritime"
        },
        {
          "Cities": [
            "Bitam",
            "Lalara",
            "Medouneu",
            "Minvoul",
            "Mitzic",
            "Oyem"
          ],
          "StateName": "Woleu-Ntem"
        }
      ],
      "CountryName": "Gabon"
    },
    {
      "States": [
        {
          "Cities": [
            "Banjul"
          ],
          "StateName": "Banjul"
        },
        {
          "Cities": [
            "Basse",
            "Gambissara",
            "Sabi",
            "Salikeni"
          ],
          "StateName": "Basse"
        },
        {
          "Cities": [
            "Brikama",
            "Brufut",
            "Gunjur",
            "Sukuta"
          ],
          "StateName": "Brikama"
        },
        {
          "Cities": [
            "Bansang",
            "Janjanbureh"
          ],
          "StateName": "Janjanbureh"
        },
        {
          "Cities": [
            "Bakau",
            "Serekunda"
          ],
          "StateName": "Kanifing"
        },
        {
          "Cities": [
            "Barra",
            "Essau",
            "Farafenni",
            "Kerewan",
            "Lamin"
          ],
          "StateName": "Kerewan"
        },
        {
          "Cities": [
            "Kuntaur"
          ],
          "StateName": "Kuntaur"
        },
        {
          "Cities": [
            "Mansakonko"
          ],
          "StateName": "Mansakonko"
        }
      ],
      "CountryName": "Gambia The"
    },
    {
      "States": [
        {
          "Cities": [
            "Ahali Atoni",
            "Bihvinta",
            "Gagra",
            "Gali",
            "Gudauta",
            "Gulripshi",
            "Ochamchira",
            "Suhumi",
            "Tkvarcheli"
          ],
          "StateName": "Abhasia"
        },
        {
          "Cities": [
            "Batumi",
            "Dioknisi",
            "Kobuleti",
            "Mahindzhauri"
          ],
          "StateName": "Ajaria"
        },
        {
          "Cities": [
            "Lanchhuti",
            "Ozurgeti"
          ],
          "StateName": "Guria"
        },
        {
          "Cities": [
            "Bagdadi",
            "Chaltubo",
            "Chiatura",
            "Honi",
            "Kutaisi",
            "Sachhere",
            "Samtredia",
            "Terzhola",
            "Tkibuli",
            "Vani",
            "Zestaponi"
          ],
          "StateName": "Imereti"
        },
        {
          "Cities": [
            "Ahmeta",
            "Cnori",
            "Dedoplisckaro",
            "Gurdzhaani",
            "Kvareli",
            "Lagodehi",
            "Sagaredzho",
            "Signahi",
            "Telavi"
          ],
          "StateName": "Kaheti"
        },
        {
          "Cities": [],
          "StateName": "Kvemo Kartli"
        },
        {
          "Cities": [
            "Dusheti",
            "Mcheta"
          ],
          "StateName": "Mcheta-Mtianeti"
        },
        {
          "Cities": [
            "Ambrolauri",
            "Cageri",
            "Oni"
          ],
          "StateName": "Racha"
        },
        {
          "Cities": [],
          "StateName": "Samagrelo-Zemo Svaneti"
        },
        {
          "Cities": [
            "Ahalcihe",
            "Ahalkalaki",
            "Borzhomi",
            "Ninocminda",
            "Vale"
          ],
          "StateName": "Samche-Zhavaheti"
        },
        {
          "Cities": [],
          "StateName": "Shida Kartli"
        },
        {
          "Cities": [
            "Tbilisi"
          ],
          "StateName": "Tbilisi"
        }
      ],
      "CountryName": "Georgia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Auvergne"
        },
        {
          "Cities": [
            "Aalen",
            "Achern",
            "Aichtal",
            "Albstadt",
            "Aldingen",
            "Allmersbach",
            "Alpirsbach",
            "Altensteig",
            "Altlussheim",
            "Ammerbuch",
            "Appenweier",
            "Asperg",
            "Backnang",
            "Bad Durrheim",
            "Bad Friedrichshall",
            "Bad Krozingen",
            "Bad Liebenzell",
            "Bad Mergentheim",
            "Bad Rappenau",
            "Bad Sackingen",
            "Bad Schonborn",
            "Bad Urach",
            "Bad Waldsee",
            "Bad Wurzach",
            "Baden-Baden",
            "Bahlingen",
            "Baiersbronn",
            "Balgheim",
            "Balingen",
            "Ballrechten-Dottingen",
            "Besigheim",
            "Biberach",
            "Bietigheim-Bissingen",
            "Binzen",
            "Birkenfeld",
            "Bisingen",
            "Bitz",
            "Blaubeuren",
            "Blaustein",
            "Blumberg",
            "Boblingen",
            "Bodelshausen",
            "Bonndorf",
            "Bonnigheim",
            "Bopfingen",
            "Bottingen",
            "Brackenheim",
            "Breisach",
            "Bretten",
            "Bretzfeld",
            "Bruchsal",
            "Bruhl",
            "Buchen",
            "Buchenbach",
            "Buhl",
            "Burgstetten",
            "Burladingen",
            "Calw",
            "Crailsheim",
            "Denkendorf",
            "Denzlingen",
            "Dettingen",
            "Dietenheim",
            "Ditzingen",
            "Donaueschingen",
            "Donzdorf",
            "Dornhan",
            "Dornstadt",
            "Dornstetten",
            "Dossenheim",
            "Durbheim",
            "Durmersheim",
            "Eberbach",
            "Ebersbach",
            "Edingen",
            "Edingen-Neckarhausen",
            "Eggenstein-Leopoldshafen",
            "Ehingen",
            "Eislingen",
            "Ellhofen",
            "Ellwangen",
            "Emmendingen",
            "Emmingen-Liptingen",
            "Engen",
            "Eningen",
            "Eppelheim",
            "Eppingen",
            "Erbach",
            "Eriskirch",
            "Eschach",
            "Esslingen",
            "Ettenheim",
            "Ettlingen",
            "Fellbach",
            "Filderstadt",
            "Freiberg",
            "Freiburg",
            "Freudenstadt",
            "Frickenhausen",
            "Fridingen",
            "Friedrichshafen",
            "Friesenheim",
            "Frittlingen",
            "Furtwangen",
            "Gaggenau",
            "Gaildorf",
            "Gartringen",
            "Gaufelden",
            "Geislingen",
            "Gengenbach",
            "Gerlingen",
            "Gernsbach",
            "Gerstetten",
            "Giengen",
            "Goppingen",
            "Gottmadingen",
            "Graben-Neudorf",
            "Grafenberg",
            "Grafenhausen",
            "Grenzach-Wyhlen",
            "GroBbettlingen",
            "Grunkraut",
            "Gschwend",
            "Guglingen",
            "Gundelfingen",
            "Gutach",
            "Haigerloch",
            "Haiterbach",
            "Harmersabch",
            "Hausach",
            "Hechingen",
            "Heddesheim",
            "Heidelberg",
            "Heidenheim",
            "Heilbronn",
            "Hemsbach",
            "Herbrechtingen",
            "Herrenberg",
            "Heubach",
            "Hirschberg",
            "Hockenheim",
            "Holzgerlingen",
            "Horb",
            "Hufingen",
            "Huttlingen",
            "Isny",
            "Ispringen",
            "Jungingen",
            "Karlsbad",
            "Karlsruhe",
            "Kehl",
            "Kernen",
            "Ketsch",
            "Kieselbronn",
            "Kirchberg an der Jagst",
            "Kirchheim",
            "Kirchzarten",
            "Kisslegg",
            "Klettgau",
            "Kongen",
            "Konigsbach-Stein",
            "Konigsbronn",
            "Konstanz",
            "Korb",
            "Korntal-Munchingen",
            "Kornwestheim",
            "Kraichtal",
            "Kressbronn an Bodensee",
            "Kronau",
            "Kuessaberg",
            "Kunzelsau",
            "Ladenburg",
            "Lahr",
            "Laichingen",
            "Langenau",
            "Lauda-Konigshofen",
            "Lauffen",
            "Laupheim",
            "Leimen",
            "Leinfelden-Echterdingen",
            "Leingarten",
            "Lenningen",
            "Lenzkirch",
            "Leonberg",
            "Leutenbach",
            "Leutkirch",
            "Lichtenstein",
            "Linkenheim-Hochstetten",
            "Lorch",
            "Lorrach",
            "Ludwigsburg",
            "Malsch",
            "Mannheim",
            "Marbach",
            "Markdorf",
            "Markgroningen",
            "Maulburg",
            "MeBstetten",
            "Meckenbeuren",
            "Meckesheim",
            "Meersburg",
            "Mengen",
            "Metzingen",
            "Mockmuhl",
            "Moglingen",
            "Monsheim",
            "Mosbach",
            "Mossingen",
            "Muhlacker",
            "Mullheim",
            "Munsingen",
            "Murrhardt",
            "Nagold",
            "Neckargemund",
            "Neckarsulm",
            "Neresheim",
            "Neuenburg",
            "Neuhausen",
            "Niederstetten",
            "Niefern-Oschelbronn",
            "NuBloch",
            "Nurtingen",
            "Oberhausen-Rheinhausen",
            "Oberkirch",
            "Oberndorf",
            "Oberstenfeld",
            "Obersulm",
            "Ochsenhausen",
            "Offenburg",
            "Ofterdingen",
            "Oftersheim",
            "Oggelshausen",
            "Ohringen",
            "Olbronn-Durrn",
            "Oppenweiler",
            "Ostfildern",
            "Ostringen",
            "Otisheim",
            "Pfalzgrafenweiler",
            "Pfinztal",
            "Pforzheim",
            "Pfullendorf",
            "Pfullingen",
            "Philippsburg",
            "Plankstadt",
            "Pleidelsheim",
            "Pliezhausen",
            "Plochingen",
            "Pluderhausen",
            "Radolfzell",
            "Rastatt",
            "Ravensburg",
            "Reilingen",
            "Remchingen",
            "Remseck",
            "Remshalden",
            "Renchen",
            "Renningen",
            "Reutlingen",
            "Rheinau",
            "Rheinfelden",
            "Rheinmunster",
            "Rheinstetten",
            "Riederich",
            "Riedlingen",
            "Rielasingen-Worblingen",
            "Rosenfeld",
            "Rottenburg",
            "Rottweil",
            "Rudersberg",
            "Rutesheim",
            "Sachsenheim",
            "Salem",
            "Sandhausen",
            "Sankt Georgen",
            "Sankt Leon-Rot",
            "Saulgau",
            "Scheer",
            "Schlierbach",
            "Schonaich",
            "Schopfheim",
            "Schorndorf",
            "Schramberg",
            "Schriesheim",
            "Schwabisch Gmund",
            "Schwabisch Hall",
            "Schwaigern",
            "Schwetzingen",
            "Schwieberdingen",
            "Seitingen-Oberflacht",
            "Sexau",
            "Sigmaringen",
            "Sindelfingen",
            "Singen",
            "Sinsheim",
            "Sinzheim",
            "Sonnenbuhl",
            "Sontheim",
            "Spaichingen",
            "Stegen",
            "Steinen",
            "Steinheim",
            "Steinmauern",
            "Stockach",
            "Straubenhardt",
            "Stutensee",
            "Stuttgart",
            "SuBen",
            "Sulz",
            "Sulzfeld",
            "Tamm",
            "Tauberbischofsheim",
            "Teningen",
            "Tettnang",
            "Titisee-Neustadt",
            "Trossingen",
            "Tubingen",
            "Tuningen",
            "Tuttlingen",
            "Uberlingen",
            "Ubstadt-Weiher",
            "Uhingen",
            "Ulm",
            "Umkirch",
            "Vaihingen",
            "Villingen-Schwenningen",
            "Villingendorf",
            "Waghausel",
            "Waiblingen",
            "Waldbronn",
            "Waldkirch",
            "Waldlaubersheim",
            "Waldshut-Tiengen",
            "Walldorf",
            "Walldurn",
            "Walzbachtal",
            "Wangen",
            "Wehr",
            "Weikersheim",
            "Weil",
            "Weil am Rhein",
            "Weil der Stadt",
            "Weil im Schonbuch",
            "Weilheim",
            "Weingarten",
            "Weinheim",
            "Weinsberg",
            "Weinstadt",
            "Wellendingen",
            "Welzheim",
            "Wendlingen",
            "Wernau",
            "Wertheim",
            "Wiesloch",
            "Wildbad",
            "Wildberg",
            "Winnenden",
            "Wolpertshausen",
            "Zuzenhausen"
          ],
          "StateName": "Baden-Wurttemberg"
        },
        {
          "Cities": [
            "Ahorn",
            "Allershausen",
            "Attenhofen",
            "Bad Birnbach",
            "Bad Endorf",
            "Bad Gronenbach",
            "Barbing",
            "Benediktbeuern",
            "Breitenberg",
            "Bruckberg",
            "Brunnthal",
            "Burgheim",
            "Chiemsee",
            "Emersacker",
            "Eresing",
            "Fahrenzhausen",
            "Faulbach",
            "Finning",
            "Forstinning",
            "Georgensgmund",
            "Haldenwang",
            "Heideck",
            "Heimenkirch",
            "Hergensweiler",
            "Hirschau",
            "Iffeldorf",
            "Karlstein",
            "Kleinheubach",
            "Kleinwallstadt",
            "Kotz",
            "Krailling",
            "Langenbach",
            "Laudenbach",
            "Leutershausen",
            "Mammendorf",
            "Mombris",
            "Munnerstadt",
            "Neu-Ulm",
            "Niederlauer",
            "Obernburg",
            "Oberpfaffenhofen",
            "Ostallgau",
            "Prittriching",
            "Prutting",
            "Pullach",
            "Putzbrunn",
            "Randersacker",
            "Rednitzhembach",
            "Ronsberg",
            "Ruckersdorf",
            "Schaufling",
            "Schonberg",
            "Seefeld",
            "Sengenthal",
            "Sondheim v.d.Rhon",
            "Steinwiesen",
            "Stockheim",
            "Tagmersheim",
            "Uettingen",
            "Unterfohring",
            "Weibensberg",
            "Wiesthal",
            "Wildflecken",
            "Wolfertschwenden",
            "Zorneding"
          ],
          "StateName": "Bavaria"
        },
        {
          "Cities": [
            "Abensberg",
            "Aichach",
            "Ainring",
            "Altdorf",
            "Altotting",
            "Altusried",
            "Alzenau",
            "Amberg",
            "Amerang",
            "Ansbach",
            "Aschaffenburg",
            "Augsburg",
            "Bad Aibling",
            "Bad Kissingen",
            "Bad Neustadt",
            "Bad Reichenhall",
            "Bad Tolz",
            "Bad Windsheim",
            "Bad Worishofen",
            "Bamberg",
            "Bayreuth",
            "Bobingen",
            "Bogen",
            "Bruckmuhl",
            "Buchloe",
            "Burghausen",
            "Burgkirchen",
            "Burglengenfeld",
            "Burgthann",
            "Buttenheim",
            "Cadolzburg",
            "Castell",
            "Cham",
            "Coburg",
            "Dachau",
            "Deggendorf",
            "DieBen",
            "Diedorf",
            "Dietmannsried",
            "Dietramszell",
            "Dillingen",
            "Dingolfing",
            "Dinkelsbuhl",
            "Dombuhl",
            "Donauworth",
            "Dorfen",
            "Ebersberg",
            "Eching",
            "Eckental",
            "Eggenfelden",
            "Eichenau",
            "Eichstatt",
            "Elchingen",
            "Erding",
            "Ergolding",
            "Erlangen",
            "Erlenbach",
            "Essenbach",
            "Feldafing",
            "Feldkirchen-Westerham",
            "Feucht",
            "Feuchtwangen",
            "Forchheim",
            "Freilassing",
            "Freising",
            "Fridolfing",
            "Friedberg",
            "Furstenfeldbruck",
            "Furth",
            "Furth im Wald",
            "Fussen",
            "Gachenbach",
            "Gaimersheim",
            "Garching",
            "Garmisch-Partenkirchen",
            "Gauting",
            "Gemunden",
            "Geretsried",
            "Germering",
            "Gersthofen",
            "Gilching",
            "Goldbach",
            "Grafelfing",
            "Grafenau",
            "Grafing",
            "GroBostheim",
            "Grobenstadt",
            "Grobenzell",
            "Grunwald",
            "Gunzburg",
            "Gunzenhausen",
            "HaBfurt",
            "Haar",
            "Hammelburg",
            "Hasloch",
            "Hauzenberg",
            "Helmbrechts",
            "Henfenfeld",
            "Hersbruck",
            "Herzogenaurach",
            "Hilpoltstein",
            "Hirschaid",
            "Hochstadt",
            "Hof",
            "Holzkirchen",
            "Hosbach",
            "Illertissen",
            "Immenstadt",
            "Ingolstadt",
            "Inning",
            "Ismaning",
            "Karlsfeld",
            "Karlstadt",
            "Kaufbeuren",
            "Kelheim",
            "Kempten",
            "Kiefersfelden",
            "Kirchheim",
            "Kissing",
            "Kitzingen",
            "Kleinostheim",
            "Klingenberg",
            "Kolbermoor",
            "Konigsbrunn",
            "Kreuzwertheim",
            "Kronach",
            "Krumbach",
            "Kulmbach",
            "Kummersbruck",
            "Landau",
            "Landsberg",
            "Landshut",
            "Langenzenn",
            "Lappersdorf",
            "Lauf",
            "Lauingen",
            "Leinburg",
            "Lenggries",
            "Lichtenfels",
            "Lindach",
            "Lindau",
            "Lindenberg",
            "Lohr",
            "Mainburg",
            "Maisach",
            "Manching",
            "Markt Schwaben",
            "Marktheidenfeld",
            "Marktoberdorf",
            "Marktredwitz",
            "Maxhutte-Haidhof",
            "Meitingen",
            "Memmingen",
            "Mering",
            "Miesbach",
            "Miltenberg",
            "Mindelheim",
            "Moosburg",
            "Muhldorf",
            "Munchberg",
            "Munchen",
            "Munningen",
            "Murnau",
            "Naila",
            "Neubiberg",
            "Neuburg",
            "Neufahrn",
            "Neumarkt",
            "Neuried",
            "NeusaB",
            "Neustadt",
            "Neutraubling",
            "Nordlingen",
            "Nuremberg",
            "Nurnberg",
            "Oberasbach",
            "Oberhaching",
            "OberschleiBheim",
            "Oberstdorf",
            "Ochsenfurt",
            "Olching",
            "Osterhofen",
            "Ostheim",
            "Ottobrunn",
            "Parsberg",
            "Passau",
            "Pegnitz",
            "PeiBenberg",
            "Peiting",
            "Pentling",
            "Penzberg",
            "Pfaffenhofen",
            "Pfarrkirchen",
            "Planegg",
            "Plattling",
            "Pocking",
            "Poing",
            "Polling",
            "Pommelsbrunn",
            "Prien",
            "Puchheim",
            "Rannersdorf",
            "Raubling",
            "Regen",
            "Regensburg",
            "Regenstauf",
            "Rehau",
            "Rodental",
            "Roding",
            "Rosenheim",
            "Roth",
            "Rothenbach",
            "Rothenburg",
            "Ruhstorf",
            "Schnelldorf",
            "Schongau",
            "Schrobenhausen",
            "Schwabach",
            "Schwabmunchen",
            "Schwandorf",
            "Schwarzenbach",
            "Schweinfurt",
            "Selb",
            "Selbitz",
            "Senden",
            "Simbach",
            "Sonthofen",
            "Spalt",
            "Stadtbergen",
            "Staffelstein",
            "Starnberg",
            "Stein",
            "Stephanskirchen",
            "Straubing",
            "Sulzbach-Rosenberg",
            "Taufkirchen",
            "Thansau",
            "Tirschenreuth",
            "Toging",
            "Traunreut",
            "Traunstein",
            "Treuchtlingen",
            "Trostberg",
            "Tutzing",
            "Unterbergen",
            "Unterhaching",
            "UnterschleiBheim",
            "Valley",
            "Vaterstetten",
            "Veitshochheim",
            "Viechtach",
            "Vilgertshofen",
            "Vilsbiburg",
            "Vilshofen",
            "Vohringen",
            "Volkach",
            "Waldkirchen",
            "Waldkraiburg",
            "Walkertshofen",
            "Wasserburg",
            "WeiBenburg",
            "WeiBenhorn",
            "Weiden",
            "Weidenberg",
            "Weilheim",
            "Wendelstein",
            "Werneck",
            "Wessling",
            "Wolfratshausen",
            "Wolnzach",
            "Wunsiedel",
            "Wurzburg",
            "Zirndorf",
            "Zwiesel"
          ],
          "StateName": "Bayern"
        },
        {
          "Cities": [],
          "StateName": "Beilstein Wurtt"
        },
        {
          "Cities": [
            "Berlin",
            "Panketal",
            "Steinfeld"
          ],
          "StateName": "Berlin"
        },
        {
          "Cities": [
            "Angermunde",
            "Bad Freienwalde",
            "Bad Liebenwerda",
            "Barnim",
            "Beeskow",
            "Bernau",
            "Blankenfelde",
            "Brandenburg",
            "Brieselang",
            "Cottbus",
            "Dahlewitz",
            "Dahme",
            "Eberswalde",
            "Eisenhuttenstadt",
            "Elsterwerda",
            "Erkner",
            "Falkensee",
            "Finsterwalde",
            "Forst",
            "Frankfurt",
            "Fredersdorf-Vogelsdorf",
            "Furstenwalde",
            "Glienicke",
            "Gransee",
            "GroBraschen",
            "Guben",
            "Heidesee",
            "Hennigsdorf",
            "Herzberg",
            "Hohen Neuendorf",
            "Jacobsdorf",
            "Juterbog",
            "Kleinmachnow",
            "Kolkwitz",
            "Konigs Wusterhausen",
            "Kyritz",
            "Lauchhammer",
            "Lubben",
            "Lubbenau",
            "Luckenwalde",
            "Ludwigsfelde",
            "Nauen",
            "Neuenhagen",
            "Neuruppin",
            "Oranienburg",
            "Perleberg",
            "Petershagen-Eggersdorf",
            "Potsdam",
            "Premnitz",
            "Prenzlau",
            "Pritzwalk",
            "Rathenow",
            "Rudersdorf",
            "Schonefeld",
            "Schoneiche",
            "Schwedt",
            "Schwielowsee",
            "Senftenberg",
            "Spremberg",
            "Strausberg",
            "Teltow",
            "Templin",
            "Velten",
            "Werder",
            "Wildau",
            "Wittenberge",
            "Wittstock",
            "Zehdenick",
            "Zepernick"
          ],
          "StateName": "Brandenburg"
        },
        {
          "Cities": [
            "Bremen",
            "Bremerhaven"
          ],
          "StateName": "Bremen"
        },
        {
          "Cities": [],
          "StateName": "Dreisbach"
        },
        {
          "Cities": [],
          "StateName": "Freistaat Bayern"
        },
        {
          "Cities": [
            "Berne",
            "Hamburg"
          ],
          "StateName": "Hamburg"
        },
        {
          "Cities": [
            "Diekholzen"
          ],
          "StateName": "Hannover"
        },
        {
          "Cities": [
            "Heroldstatt"
          ],
          "StateName": "Heroldstatt"
        },
        {
          "Cities": [
            "ABlar",
            "Alsbach-HÃÆÃÂ¤hnlein",
            "Alsfeld",
            "Altenstadt",
            "Angelburg",
            "Arolsen",
            "Asslar",
            "Babenhausen",
            "Bad Camberg",
            "Bad Hersfeld",
            "Bad Homburg",
            "Bad Nauheim",
            "Bad Orb",
            "Bad Schwalbach",
            "Bad Soden",
            "Bad Soden-Salmunster",
            "Bad Sooden-Allendorf",
            "Bad Vilbel",
            "Bad Wildungen",
            "Baunatal",
            "Bebra",
            "Beerfelden",
            "Bensheim",
            "Berkatal",
            "Biblis",
            "Bickenbach",
            "Biebertal",
            "Biedenkopf",
            "Birkenau",
            "Bischofsheim",
            "Borken",
            "Braunfels",
            "Breidenbach",
            "Bruchkobel",
            "Budingen",
            "Burstadt",
            "Buseck",
            "Buttelborn",
            "Butzbach",
            "Darmstadt",
            "Dautphetal",
            "Dieburg",
            "Dietzenbach",
            "Dillenburg",
            "Dreieich",
            "Egelsbach",
            "Eichenzell",
            "Eltville",
            "Eppstein",
            "Erbach",
            "Erlensee",
            "Erzhausen",
            "Eschborn",
            "Eschenburg",
            "Eschwege",
            "Felsberg",
            "Fernwald",
            "Florsheim",
            "Frankenberg",
            "Frankfurt",
            "Freigericht",
            "Friedberg",
            "Friedrichsdorf",
            "Fritzlar",
            "Fulda",
            "Fuldabruck",
            "Fuldatal",
            "Geisenheim",
            "Gelnhausen",
            "Gernsheim",
            "GieBen",
            "Giessen",
            "Ginsheim-Gustavsburg",
            "Gladenbach",
            "Griesheim",
            "GroB-Gerau",
            "GroB-Umstadt",
            "GroB-Zimmern",
            "Grossenluder",
            "Gruenberg",
            "Grunberg",
            "Grundau",
            "Hadamar",
            "Haiger",
            "Hainburg",
            "Hanau",
            "Hattersheim",
            "Heppenheim",
            "Herborn",
            "Hessisch Lichtenau",
            "Heuchelheim",
            "Heusenstamm",
            "Hochheim",
            "Hochst",
            "Hofbieber",
            "Hofgeismar",
            "Hofheim",
            "Homberg",
            "Hunfeld",
            "Hunfelden",
            "Hungen",
            "Huttenberg",
            "Idstein",
            "Karben",
            "Kassel",
            "Kaufungen",
            "Kelkheim",
            "Kelsterbach",
            "Kirchhain",
            "Konigstein",
            "Korbach",
            "Kriftel",
            "Kronberg",
            "Kunzell",
            "Lahnau",
            "Lahntal",
            "Lampertheim",
            "Langen",
            "Langenselbold",
            "Langgons",
            "Laubach",
            "Lauterbach",
            "Lich",
            "Limburg",
            "Linden",
            "Lindenholzhausen",
            "Linsengericht",
            "Lohfelden",
            "Lollar",
            "Lorsch",
            "Maintal",
            "Marburg",
            "Melsungen",
            "Mengerskirchen",
            "Michelstadt",
            "Morfelden-Walldorf",
            "Morlenbach",
            "Mucke",
            "Muhlheim",
            "Muhltal",
            "Munster",
            "Nauheim",
            "Neu-Anspach",
            "Neu-Isenburg",
            "Neuhof",
            "Neustadt",
            "Nidda",
            "Niddatal",
            "Nidderau",
            "Niederdorfelden",
            "Niedernhausen",
            "Niestetal",
            "Ober-Ramstedt",
            "Obertshausen",
            "Oberursel",
            "Oestrich-Winkel",
            "Offenbach",
            "Petersberg",
            "Pfungstadt",
            "Pohlheim",
            "Raunheim",
            "Reichelsheim",
            "Reinheim",
            "Reiskirchen",
            "Riedstadt",
            "Rimbach",
            "RoBdorf",
            "Rodenbach",
            "Rodermark",
            "Rodgau",
            "Rosbach",
            "Rotenburg",
            "Rudesheim",
            "Runkel",
            "Russelsheim",
            "Schauenburg",
            "Schlangenbad",
            "Schlitz",
            "Schluchtern",
            "Schoneck",
            "Schotten",
            "Schwalbach",
            "Schwalbach am Taunus",
            "Schwalmstadt",
            "Seeheim-Jugenheim",
            "Seligenstadt",
            "Selters",
            "Sinntal",
            "Solms",
            "Sontra",
            "Spangenberg",
            "Stadtallendorf",
            "Steinau",
            "Steinbach",
            "Taunusstein",
            "Trebur",
            "Ulrichstein",
            "Usingen",
            "Vellmar",
            "Viernheim",
            "Volkmarsen",
            "Wachtersbach",
            "Wald-Michelbach",
            "Waldbrunn",
            "Waldems",
            "Wehrheim",
            "Weilburg",
            "Weilmunster",
            "Weiterstadt",
            "Wettenberg",
            "Wetter",
            "Wetzlar",
            "Wiesbaden",
            "Witzenhausen",
            "Wolfersheim",
            "Wolfhagen",
            "Zwingenberg"
          ],
          "StateName": "Hessen"
        },
        {
          "Cities": [
            "Kortenberg"
          ],
          "StateName": "Kortenberg"
        },
        {
          "Cities": [
            "Laasdorf"
          ],
          "StateName": "Laasdorf"
        },
        {
          "Cities": [],
          "StateName": "Land Baden-Wurttemberg"
        },
        {
          "Cities": [],
          "StateName": "Land Bayern"
        },
        {
          "Cities": [],
          "StateName": "Land Brandenburg"
        },
        {
          "Cities": [],
          "StateName": "Land Hessen"
        },
        {
          "Cities": [],
          "StateName": "Land Mecklenburg-Vorpommern"
        },
        {
          "Cities": [],
          "StateName": "Land Nordrhein-Westfalen"
        },
        {
          "Cities": [],
          "StateName": "Land Rheinland-Pfalz"
        },
        {
          "Cities": [],
          "StateName": "Land Sachsen"
        },
        {
          "Cities": [],
          "StateName": "Land Sachsen-Anhalt"
        },
        {
          "Cities": [],
          "StateName": "Land Thuringen"
        },
        {
          "Cities": [],
          "StateName": "Lower Saxony"
        },
        {
          "Cities": [
            "Anklam",
            "Bad Doberan",
            "Barth",
            "Bergen",
            "Boizenburg",
            "Butzow",
            "Demmin",
            "Gagelow",
            "Gallin",
            "Grabow",
            "Greifswald",
            "Grevesmuhlen",
            "Grimmen",
            "Gustrow",
            "Hagenow",
            "Konigsee",
            "Lubtheen",
            "Ludersdorf",
            "Ludwigslust",
            "Malchin",
            "Neubrandenburg",
            "Neustrelitz",
            "Parchim",
            "Pasewalk",
            "Ribnitz-Damgarten",
            "Rostock",
            "SaBnitz",
            "Schweina",
            "Schwerin",
            "Selmsdorf",
            "Stralsund",
            "Teterow",
            "Torgelow",
            "Ueckermunde",
            "Waren",
            "Wismar",
            "Wolgast",
            "Zarrentin"
          ],
          "StateName": "Mecklenburg-Vorpommern"
        },
        {
          "Cities": [
            "Mulfingen"
          ],
          "StateName": "Mulfingen"
        },
        {
          "Cities": [
            "Grafing bei Munchen"
          ],
          "StateName": "Munich"
        },
        {
          "Cities": [
            "Neubeuern"
          ],
          "StateName": "Neubeuern"
        },
        {
          "Cities": [
            "Achim",
            "Adendorf",
            "Aerzen",
            "Alfeld",
            "Ankum",
            "Apen",
            "Aurich",
            "Bad Bentheim",
            "Bad Bevensen",
            "Bad Essen",
            "Bad Gandersheim",
            "Bad Harzburg",
            "Bad Iburg",
            "Bad Laer",
            "Bad Lauterberg",
            "Bad Munder",
            "Bad Nenndorf",
            "Bad Pyrmont",
            "Bad Sachsa",
            "Bad Salzdetfurth",
            "Bad Zwischenahn",
            "BarBel",
            "Barsinghausen",
            "Bassum",
            "Beesten",
            "Belm",
            "Bergen",
            "Bissendorf",
            "Bleckede",
            "Bockenem",
            "Bohmte",
            "Bovenden",
            "Brake",
            "Bramsche",
            "Braunschweig",
            "Bremervorde",
            "Brockel",
            "Brunswick",
            "Buchholz",
            "Buckeburg",
            "Burgdorf",
            "Burgwedel",
            "Buxtehude",
            "Celle",
            "Clausthal-Zellerfeld",
            "Clenze",
            "Cloppenburg",
            "Cremlingen",
            "Cuxhaven",
            "Dahlenburg",
            "Damme",
            "Dannenberg",
            "Dassel",
            "Deinste",
            "Delmenhorst",
            "Diepholz",
            "Dinklage",
            "Dorverden",
            "Dransfeld",
            "Drochtersen",
            "Duderstadt",
            "Edemissen",
            "Edewecht",
            "Einbeck",
            "Elsfleth",
            "Elze",
            "Emden",
            "Emlichheim",
            "Emmerthal",
            "Emsburen",
            "Eppendorf",
            "Fallingbostel",
            "Frellstedt",
            "Friedeburg",
            "Friedland",
            "Friesoythe",
            "Ganderkesee",
            "Garbsen",
            "Garrel",
            "Geeste",
            "Gehrden",
            "Georgsmarienhutte",
            "Gifhorn",
            "Gleichen",
            "Goslar",
            "Gottingen",
            "GroBefehn",
            "GroBenkneten",
            "Hagen",
            "Hambergen",
            "Hameln",
            "Hannover",
            "Hannoversch Munden",
            "Haren",
            "Harsum",
            "Hasbergen",
            "Haselunne",
            "Hatten",
            "Hauslingen",
            "Helmstedt",
            "Hemmingen",
            "Herzberg",
            "Hessisch Oldendorf",
            "Hildesheim",
            "Hilter",
            "Hittfeld",
            "Holzminden",
            "Hoya",
            "Hude",
            "Ihlow",
            "Ilsede",
            "Isernhagen",
            "Jade",
            "Jever",
            "Jork",
            "Kalefeld",
            "Kirchlinteln",
            "Knesebeck",
            "Konigslutter",
            "Krummhorn",
            "Laatzen",
            "Lahstedt",
            "Langelsheim",
            "Langen",
            "Langenhagen",
            "Langwedel",
            "Lastrup",
            "Leer",
            "Lehre",
            "Lehrte",
            "Lemforde",
            "Lengede",
            "Liebenburg",
            "Lilienthal",
            "Lingen",
            "Lohne",
            "Loningen",
            "Lorup",
            "Loxstedt",
            "Luchow",
            "Luneburg",
            "Melle",
            "Meppen",
            "Moormerland",
            "Munster",
            "Neu Wulmstorf",
            "Neustadt",
            "Nienburg",
            "Norden",
            "Nordenham",
            "Nordhorn",
            "Nordstemmen",
            "Norten-Hardenberg",
            "Northeim",
            "Obernkirchen",
            "Oldenburg",
            "Osnabruck",
            "Ostercappeln",
            "Osterholz-Scharmbeck",
            "Osterode",
            "Ostrhauderfehn",
            "Ottersberg",
            "Oyten",
            "Papenburg",
            "Pattensen",
            "Peine",
            "Quakenbruck",
            "Rastede",
            "Rehburg-Loccum",
            "Rhauderfehn",
            "Rinteln",
            "Ritterhude",
            "Ronnenberg",
            "Rosdorf",
            "Rosengarten",
            "Rotenburg",
            "Salzgitter",
            "Salzhemmendorf",
            "Sarstedt",
            "Saterland",
            "ScheeBel",
            "Schiffdorf",
            "Schneverdingen",
            "Schoningen",
            "Schortens",
            "Schuttorf",
            "Schwanewede",
            "Seelze",
            "Seesen",
            "Seevetal",
            "Sehnde",
            "Soltau",
            "Springe",
            "Stade",
            "Stadthagen",
            "Stadtoldendorf",
            "Stelle",
            "Stuhr",
            "Sudbrookmerland",
            "Sulingen",
            "Syke",
            "Tarmstedt",
            "Tostedt",
            "Twistringen",
            "Uchte",
            "Uelzen",
            "Uetze",
            "Uplengen",
            "Uslar",
            "Varel",
            "Vechelde",
            "Vechta",
            "Verden",
            "Vienenburg",
            "Visselhovede",
            "Walkenried",
            "Wallenhorst",
            "Walsrode",
            "Wangerland",
            "Wardenburg",
            "Wedemark",
            "Weener",
            "Wendeburg",
            "Wennigsen",
            "Westerstede",
            "Westoverledingen",
            "Weyhe",
            "Wiefelstede",
            "Wiesmoor",
            "Wildeshausen",
            "Wilhelmshaven",
            "Winsen",
            "Wittingen",
            "Wittmund",
            "Wolfenbuttel",
            "Wolfsburg",
            "Worpswede",
            "Wunstorf",
            "Zetel",
            "Zeven"
          ],
          "StateName": "Niedersachsen"
        },
        {
          "Cities": [
            "Middenbeemster"
          ],
          "StateName": "Noord-Holland"
        },
        {
          "Cities": [
            "Aachen",
            "Ahaus",
            "Ahlen",
            "Aldenhoven",
            "Alfter",
            "Alpen",
            "Alsdorf",
            "Altena",
            "Altendorf",
            "Anrochte",
            "Arnsberg",
            "Ascheberg",
            "Attendorn",
            "Augustdorf",
            "Bad Berleburg",
            "Bad Driburg",
            "Bad Honnef",
            "Bad Laasphe",
            "Bad Lippspringe",
            "Bad Munstereifel",
            "Bad Oeynhausen",
            "Bad Salzuflen",
            "Bad Sassendorf",
            "Baesweiler",
            "Balve",
            "Barntrup",
            "Beckum",
            "Bedburg",
            "Bedburg-Hau",
            "Bergheim",
            "Bergisch Gladbach",
            "Bergkamen",
            "Bergneustadt",
            "Bestwig",
            "Beverungen",
            "Bielefeld",
            "Billerbeck",
            "Blomberg",
            "Bocholt",
            "Bochum",
            "Bocket",
            "Bonen",
            "Bonn",
            "Borchen",
            "Borgentreich",
            "Borgholzhausen",
            "Borken",
            "Bornheim",
            "Bottrop",
            "Brakel",
            "Brilon",
            "Bruggen",
            "Bruhl",
            "Bunde",
            "Burbach",
            "Buren",
            "Burscheid",
            "Castrop-Rauxel",
            "Coesfeld",
            "Cologne",
            "Datteln",
            "Delbruck",
            "Denklingen",
            "Detmold",
            "Dingden",
            "Dinslaken",
            "Dormagen",
            "Dorsten",
            "Dortmund",
            "Drensteinfurt",
            "Drolshagen",
            "Duisburg",
            "Dulmen",
            "Duren",
            "Dusseldorf",
            "Eitorf",
            "Elsdorf",
            "Emmerich",
            "Emsdetten",
            "Engelskirchen",
            "Enger",
            "Ennepetal",
            "Ennigerloh",
            "Ense",
            "Erftstadt",
            "Erkelenz",
            "Erkrath",
            "Erwitte",
            "Espelkamp",
            "Essen",
            "Euskirchen",
            "Extertal",
            "Finnentrop",
            "Frechen",
            "Freudenberg",
            "Frondenberg",
            "Gangelt",
            "Geilenkirchen",
            "Geldern",
            "Gelsenkirchen",
            "Gescher",
            "Geseke",
            "Gevelsberg",
            "Gladbeck",
            "Goch",
            "Grefrath",
            "Greven",
            "Grevenbroich",
            "Gronau",
            "Gummersbach",
            "Gutersloh",
            "Haan",
            "Hagen",
            "Halle",
            "Haltern",
            "Halver",
            "Hamm",
            "Hamminkeln",
            "Harsewinkel",
            "Hattingen",
            "Havixbeck",
            "Heiligenhaus",
            "Heinsberg",
            "Hemer",
            "Hennef",
            "Herdecke",
            "Herford",
            "Herne",
            "Herten",
            "Herzebrock-Clarholz",
            "Herzogenrath",
            "Hiddenhausen",
            "Hilchenbach",
            "Hilden",
            "Hille",
            "Holzwickede",
            "Horn-Bad Meinberg",
            "Horstel",
            "Hovelhof",
            "Hoxter",
            "Huckelhoven",
            "Huckeswagen",
            "Hullhorst",
            "Hunxe",
            "Hurth",
            "Ibbenburen",
            "Iserlohn",
            "Isselburg",
            "Issum",
            "Juchen",
            "Julich",
            "Kaarst",
            "Kalkar",
            "Kall",
            "Kalletal",
            "Kamen",
            "Kamp-Lintfort",
            "Kempen",
            "Kerken",
            "Kerpen",
            "Kevelaer",
            "Kierspe",
            "Kirchhundem",
            "Kirchlengern",
            "Kleve",
            "Koln",
            "Konigswinter",
            "Korschenbroich",
            "Krefeld",
            "Kreuzau",
            "Kreuztal",
            "Kurten",
            "Lage",
            "Langenfeld",
            "Langerwehe",
            "Leichlingen",
            "Lemgo",
            "Lengerich",
            "Lennestadt",
            "Leopoldshohe",
            "Leverkusen",
            "Lichtenau",
            "Lindlar",
            "Linnich",
            "Lippetal",
            "Lippstadt",
            "Lohmar",
            "Lohne",
            "Lotte",
            "Lubbecke",
            "Ludenscheid",
            "Ludinghausen",
            "Lugde",
            "Lunen",
            "Marienheide",
            "Marl",
            "Marsberg",
            "Mechernich",
            "Meckenheim",
            "Meerbusch",
            "Meinerzhagen",
            "Menden",
            "Meschede",
            "Mettingen",
            "Mettmann",
            "Minden",
            "Moers",
            "Mohnesee",
            "Monchengladbach",
            "Monheim",
            "Monschau",
            "Morsbach",
            "Much",
            "Mulheim",
            "Munster",
            "Netphen",
            "Nettetal",
            "Neuenkirchen",
            "Neuenrade",
            "Neukirchen-Vluyn",
            "Neunkirchen",
            "Neunkirchen-Seelscheid",
            "Neuss",
            "Nideggen",
            "Niederkassel",
            "Niederkruchten",
            "Niederzier",
            "Nordkirchen",
            "Norvenich",
            "Nottuln",
            "Numbrecht",
            "Oberhausen",
            "Ochtrup",
            "Odenthal",
            "Oelde",
            "Oer-Erkenschwick",
            "Oerlinghausen",
            "Olfen",
            "Olpe",
            "Olsberg",
            "Overath",
            "Paderborn",
            "Petershagen",
            "Plettenberg",
            "Porta Westfalica",
            "PreuBisch Oldendorf",
            "Pulheim",
            "Radevormwald",
            "Raesfeld",
            "Rahden",
            "Ratingen",
            "Recke",
            "Recklinghausen",
            "Rees",
            "Reichshof",
            "Reken",
            "Remscheid",
            "Rheda-Wiedenbruck",
            "Rhede",
            "Rheinbach",
            "Rheinberg",
            "Rheine",
            "Rietberg",
            "Rommerskirchen",
            "Rosendahl",
            "Rosrath",
            "Ruthen",
            "Salzkotten",
            "Sassenberg",
            "Schalksmuhle",
            "Schermbeck",
            "Schieder-Schwalenberg",
            "Schleiden",
            "SchloB Holte-Stukenbrock",
            "Schmallenberg",
            "Schwalmtal",
            "Schwelm",
            "Schwerte",
            "Selm",
            "Senden",
            "Sendenhorst",
            "Siegburg",
            "Siegen",
            "Simmerath",
            "Soest",
            "Solingen",
            "Sonsbeck",
            "Spenge",
            "Sprockhovel",
            "Stadtlohn",
            "Steinfurt",
            "Steinhagen",
            "Steinheim",
            "Stemwede",
            "Stolberg",
            "Straelen",
            "Sundern",
            "Swisttal",
            "Tecklenburg",
            "Telgte",
            "Tonisvorst",
            "Troisdorf",
            "Ubach-Palenberg",
            "Unna",
            "Velbert",
            "Velen",
            "Verl",
            "Versmold",
            "Viersen",
            "Vlotho",
            "Voerde",
            "Vreden",
            "Wachtberg",
            "Wachtendonk",
            "Wadersloh",
            "Waldbrol",
            "Waltrop",
            "Warburg",
            "Warendorf",
            "Warstein",
            "Wassenberg",
            "Weeze",
            "Wegberg",
            "Weilerswist",
            "Welver",
            "Wenden",
            "Werdohl",
            "Werl",
            "Wermelskirchen",
            "Werne",
            "Werther",
            "Wesel",
            "Wesseling",
            "Westerkappeln",
            "Wetter",
            "Wickede",
            "Wiehl",
            "Willich",
            "Wilnsdorf",
            "Windeck",
            "Winterberg",
            "Wipperfurth",
            "Witten",
            "Wulfrath",
            "Wunnenberg",
            "Wuppertal",
            "Wurselen",
            "Xanten",
            "Zulpich"
          ],
          "StateName": "Nordrhein-Westfalen"
        },
        {
          "Cities": [],
          "StateName": "North Rhine-Westphalia"
        },
        {
          "Cities": [
            "Herzberg am Harz"
          ],
          "StateName": "Osterode"
        },
        {
          "Cities": [
            "Alzey",
            "Andernach",
            "Bad Durkheim",
            "Bad Ems",
            "Bad Kreuznach",
            "Bad Neuenahr-Ahrweiler",
            "Bendorf",
            "Betzdorf",
            "Bingen",
            "Bitburg",
            "Bobenheim-Roxheim",
            "Bohl-Iggelheim",
            "Boppard",
            "Daun",
            "Diez",
            "Eisenberg",
            "Essingen",
            "Frankenthal",
            "Gau-Odernheim",
            "Germersheim",
            "Grunstadt",
            "HaBloch",
            "Hahnstatten",
            "Hallschlag",
            "Herschbach",
            "Herxheim",
            "Hirschhorn",
            "Hohr-Grenzhausen",
            "Holzheim",
            "Idar-Oberstein",
            "Ingelheim",
            "Kaisersesch",
            "Kaiserslautern",
            "Kastellaun",
            "Kindsbach",
            "Kirchen",
            "Kirn",
            "Koblenz",
            "Lahnstein",
            "Landau",
            "Limburgerhof",
            "Luckenburg",
            "Ludwigshafen",
            "Mainz",
            "Mayen",
            "Montabaur",
            "Morbach",
            "Mulheim-Karlich",
            "Mundersbach",
            "Mutterstadt",
            "Nassau",
            "Neitersen",
            "Neustadt",
            "Neuwied",
            "Niederzissen",
            "Pirmasens",
            "Plaidt",
            "Remagen",
            "Schifferstadt",
            "Schoenenberg Kuebelberg",
            "Sinzig",
            "Speyer",
            "St. Goar",
            "Trier",
            "Vallendar",
            "Winterbach",
            "Wittlich",
            "Worms",
            "Worth",
            "Zweibrucken"
          ],
          "StateName": "Rheinland-Pfalz"
        },
        {
          "Cities": [
            "Adenau",
            "Anhausen",
            "Barbelroth",
            "Berndroth",
            "Bernkastel-Kues",
            "Burgbrohl",
            "Dieblich",
            "Dierdorf",
            "Dreisbach",
            "Elsoff",
            "Enkenbach-Alsenborn",
            "Etzbach",
            "Flonheim",
            "Fohren",
            "Grafschaft",
            "Hochspeyer",
            "Leiningen",
            "Moschheim",
            "Murlenbach",
            "Neuhofen",
            "Nievern",
            "Norken",
            "Oberlahr",
            "Otterstadt",
            "Rennerod",
            "Rheinbreitbach",
            "Rieschweiler-Muhlbach",
            "Saarburg",
            "Stahlhofen",
            "Steinebach",
            "Weinsheim",
            "Winnweiler",
            "Wissen"
          ],
          "StateName": "Rhineland-Palatinate"
        },
        {
          "Cities": [
            "Beckingen",
            "Bexbach",
            "Blieskastel",
            "Dillingen",
            "Duppenweiler",
            "Eppelborn",
            "Friedrichsthal",
            "GroBrosseln",
            "Heusweiler",
            "Homburg",
            "Illingen",
            "Kirkel",
            "Kleinblittersdorf",
            "Lebach",
            "Losheim",
            "Mandelbachtal",
            "Marpingen",
            "Merchweiler",
            "Merzig",
            "Mettlach",
            "Nalbach",
            "Neunkirchen",
            "Nohfelden",
            "Nonnweiler",
            "Oberthal",
            "Ottweiler",
            "Puttlingen",
            "Quierschied",
            "Rehlingen-Siersburg",
            "Riegelsberg",
            "Saarbrucken",
            "Saarlouis",
            "Saarwellingen",
            "Sankt Ingbert",
            "Sankt Wendel",
            "Schiffweiler",
            "Schmelz",
            "Schwalbach",
            "Spiesen-Elversberg",
            "Sulzbach",
            "Tholey",
            "Uberherrn",
            "Volklingen",
            "Wadern",
            "Wadgassen",
            "Wallerfangen",
            "Weiskirchen"
          ],
          "StateName": "Saarland"
        },
        {
          "Cities": [
            "Annaberg-Buchholz",
            "Aue",
            "Auerbach",
            "Bautzen",
            "Bischofswerda",
            "Borna",
            "Brand-Erbisdorf",
            "Burgstadt",
            "Chemnitz",
            "Coswig",
            "Crimmitschau",
            "Delitzsch",
            "Dobeln",
            "Dresden",
            "Ebersbach",
            "Eilenburg",
            "Falkenstein",
            "Floha",
            "Frankenberg",
            "Freiberg",
            "Freital",
            "Friedewald",
            "Glauchau",
            "Gorlitz",
            "Grimma",
            "GroBenhain",
            "Groditz",
            "Hainichen",
            "Heidenau",
            "Hirschstein",
            "Hohenstein-Ernstthal",
            "Hoyerswerda",
            "Kamenz",
            "Klingenthal",
            "Leipzig",
            "Lichtenstein",
            "Limbach-Oberfrohna",
            "LoBnitz",
            "Lobau",
            "Lugau",
            "Marienberg",
            "Markkleeberg",
            "Meerane",
            "MeiBen",
            "Mittweida",
            "Muldenhammer",
            "Neustadt",
            "Niesky",
            "Oelsnitz",
            "Olbernhau",
            "Olbersdorf",
            "Oschatz",
            "Pirna",
            "Plauen",
            "Radeberg",
            "Radebeul",
            "Reichenbach",
            "Riesa",
            "Rietschen",
            "Schkeuditz",
            "Schneeberg",
            "Schwarzenberg",
            "Sebnitz",
            "Stollberg",
            "Taubenheim",
            "Taucha",
            "Thalheim",
            "Torgau",
            "Waldheim",
            "WeiBwasser",
            "Werdau",
            "Wilkau-HaBlau",
            "Wurzen",
            "Zittau",
            "Zschopau",
            "Zwickau",
            "Zwonitz"
          ],
          "StateName": "Sachsen"
        },
        {
          "Cities": [
            "Aken",
            "Aschersleben",
            "Bad Durrenberg",
            "Bebitz",
            "Bernburg",
            "Bitterfeld",
            "Blankenburg",
            "Braunsbedra",
            "Burg",
            "Calbe",
            "Coswig",
            "Dessau",
            "Eisleben",
            "Gardelegen",
            "Genthin",
            "Gommern",
            "Grafenhainichen",
            "Halberstadt",
            "Haldensleben",
            "Halle",
            "Hettstedt",
            "Heyrothsberge",
            "Hotensleben",
            "Kothen",
            "Leuna",
            "Magdeburg",
            "Merseburg",
            "Naumburg",
            "Oschersleben",
            "Osterburg",
            "Osterwieck",
            "Quedlinburg",
            "Querfurt",
            "Raguhn",
            "RoBlau",
            "Salzwedel",
            "Sangerhausen",
            "Schonebeck",
            "StaBfurt",
            "Stendal",
            "Tangermunde",
            "Thale",
            "WeiBenfels",
            "Wittenberg",
            "Wolfen",
            "Wolmirstedt",
            "Zeitz",
            "Zerbst"
          ],
          "StateName": "Sachsen-Anhalt"
        },
        {
          "Cities": [
            "Bad Lausick",
            "Bernsdorf",
            "Borde-Hakel",
            "Gelenau",
            "Groberkmannsdorf ",
            "Hartha",
            "Kreischa",
            "Malschwitz",
            "Naunhof",
            "Pausa",
            "Seiffen",
            "Stutzengrun"
          ],
          "StateName": "Saxony"
        },
        {
          "Cities": [
            "Ahrensbok",
            "Ahrensburg",
            "Altenholz",
            "Alveslohe",
            "Ammersbek",
            "Bad Bramstedt",
            "Bad Oldesloe",
            "Bad Schwartau",
            "Bad Segeberg",
            "Bargteheide",
            "Barmstedt",
            "Barsbuttel",
            "Bredstedt",
            "Brunsbuttel",
            "Budelsdorf",
            "Eckernforde",
            "Eddelak",
            "Elmshorn",
            "Eutin",
            "Flensburg",
            "Friedrichstadt",
            "Geesthacht",
            "Glinde",
            "Gluckstadt",
            "Grob Pampau",
            "Halstenbek",
            "Hamfelde",
            "Harrislee",
            "Hartenholm",
            "Heide",
            "Heiligenhafen",
            "Henstedt-Ulzburg",
            "Honenwestedt",
            "Husum",
            "Itzehoe",
            "Kaltenkirchen",
            "Kappeln",
            "Kiel",
            "Kronshagen",
            "Lauenburg",
            "Lensahn",
            "Lubeck",
            "Malente",
            "Mielkendorf",
            "Molfsee",
            "Molln",
            "Neuenbrook",
            "Neumunster",
            "Neustadt",
            "Norderstedt",
            "Oldenburg",
            "Oststeinbek",
            "Pinneberg",
            "Plon",
            "Preetz",
            "Quickborn",
            "Ratekau",
            "Ratzeburg",
            "Reinbek",
            "Reinfeld",
            "Rellingen",
            "Rendsburg",
            "Rethwisch",
            "Satrup",
            "Scharbeutz",
            "Schenefeld",
            "Schleswig",
            "Schmalfeld",
            "Schoenkirchen",
            "Schwarzenbek",
            "Seefeld",
            "Sievershutten",
            "Stockelsdorf",
            "Tangstedt",
            "Timmendorfer Strand",
            "Tornesch",
            "Travemunde",
            "Uetersen",
            "Wahlstedt",
            "Wedel",
            "Wentorf",
            "Westerland",
            "Westerronfeld",
            "Wohltorf",
            "Wotersen"
          ],
          "StateName": "Schleswig-Holstein"
        },
        {
          "Cities": [
            "Altenburg",
            "Apolda",
            "Arnstadt",
            "Bad Frankenhausen",
            "Bad Langensalza",
            "Bad Salzungen",
            "Cursdorf",
            "Dornburg",
            "Eisenach",
            "Eisenberg",
            "Erfurt",
            "Gera",
            "Geschwenda",
            "Gotha",
            "Greiz",
            "Heiligenstadt",
            "Hermsdorf",
            "Hildburghausen",
            "Ilmenau",
            "Immelborn",
            "Jena",
            "Leinefelde",
            "Leutenberg",
            "Meiningen",
            "Meuselwitz",
            "Muhlhausen",
            "Neustadt",
            "Nordhausen",
            "PoBneck",
            "Rosenthal",
            "Rositz",
            "Rudolstadt",
            "Ruhla",
            "Saalfeld",
            "Schmalkalden",
            "Schmolln",
            "Sommerda",
            "Sondershausen",
            "Sonneberg",
            "Suhl",
            "Triptis",
            "Uhlstadt",
            "Waltershausen",
            "Weida",
            "Weimar",
            "Wernigerode",
            "Wormstedt",
            "Zella-Mehlis",
            "Zeulenroda"
          ],
          "StateName": "Thuringia"
        },
        {
          "Cities": [
            "Webling"
          ],
          "StateName": "Webling"
        },
        {
          "Cities": [
            "Neustadt"
          ],
          "StateName": "Weinstrabe"
        },
        {
          "Cities": [
            "Schlobborn"
          ],
          "StateName": "schlobborn"
        }
      ],
      "CountryName": "Germany"
    },
    {
      "States": [
        {
          "Cities": [
            "Agogo",
            "Bekwai",
            "Konongo",
            "Kumasi",
            "Mampong",
            "Mankranso",
            "Obuasi",
            "Ofinso",
            "Tafo"
          ],
          "StateName": "Ashanti"
        },
        {
          "Cities": [
            "Bechem",
            "Berekum",
            "Duayaw Nkwanta",
            "Kintampo",
            "Sunyani",
            "Techiman",
            "Wenchi"
          ],
          "StateName": "Brong-Ahafo"
        },
        {
          "Cities": [
            "Apam",
            "Cape Coast",
            "Dunkwa",
            "Elmina",
            "Foso",
            "Komenda",
            "Mauri",
            "Mumford",
            "Nyakrom",
            "Okitsiu",
            "Saltpond",
            "Swedru",
            "Winneba"
          ],
          "StateName": "Central"
        },
        {
          "Cities": [
            "Aburi",
            "Ada",
            "Akim Swedru",
            "Akropong",
            "Asamankese",
            "Begoro",
            "Kade",
            "Kibi",
            "Koforidua",
            "Mpraeso",
            "Nkawkaw",
            "Nsawam",
            "Oda",
            "Somanya",
            "Suhum"
          ],
          "StateName": "Eastern"
        },
        {
          "Cities": [],
          "StateName": "Greater Accra"
        },
        {
          "Cities": [
            "Kpandae",
            "Salaga",
            "Savelugu",
            "Tamale",
            "Yendi"
          ],
          "StateName": "Northern"
        },
        {
          "Cities": [],
          "StateName": "Upper East"
        },
        {
          "Cities": [],
          "StateName": "Upper West"
        },
        {
          "Cities": [
            "Aflao",
            "Anloga",
            "Ho",
            "Hohoe",
            "Keta",
            "Kete-Krachi",
            "Kpandu"
          ],
          "StateName": "Volta"
        },
        {
          "Cities": [
            "Aboso",
            "Anomabu",
            "Axim",
            "Bibiani",
            "Prestea",
            "Sekondi",
            "Shama",
            "Takoradi",
            "Tarkwa"
          ],
          "StateName": "Western"
        }
      ],
      "CountryName": "Ghana"
    },
    {
      "States": [
        {
          "Cities": [
            "Gibraltar"
          ],
          "StateName": "Gibraltar"
        }
      ],
      "CountryName": "Gibraltar"
    },
    {
      "States": [
        {
          "Cities": [
            "Elassonos"
          ],
          "StateName": "Acharnes"
        },
        {
          "Cities": [
            "Aiyion",
            "Patra"
          ],
          "StateName": "Ahaia"
        },
        {
          "Cities": [],
          "StateName": "Aitolia kai Akarnania"
        },
        {
          "Cities": [
            "Argos",
            "Navplion"
          ],
          "StateName": "Argolis"
        },
        {
          "Cities": [
            "Tripoli"
          ],
          "StateName": "Arkadia"
        },
        {
          "Cities": [
            "Arta"
          ],
          "StateName": "Arta"
        },
        {
          "Cities": [
            "Acharnes",
            "Agios Ioannis Rentis",
            "Drapetsona",
            "Koropi",
            "Lavrion",
            "Mandra",
            "Spata"
          ],
          "StateName": "Attica"
        },
        {
          "Cities": [
            "Aharna",
            "Aiyaleo",
            "Alimos",
            "Amarousion",
            "Ano Liosia",
            "Aryiroupoli",
            "Aspropirgos",
            "Athina",
            "Athinai",
            "Ayia Barbara",
            "Ayia Paraskevi",
            "Ayios Anaryiros",
            "Ayios Dimitrios",
            "Dafne",
            "Elevsis",
            "Ellenikon",
            "Galatsion",
            "Glifada",
            "Haidarion",
            "Halandrion",
            "Holargos",
            "Ilioupoli",
            "Iraklion",
            "Kaisariani",
            "Kallithea",
            "Kamateron",
            "Keratea",
            "Keratsinion",
            "Kifisia",
            "Koridallos",
            "Kropion",
            "Markopoulos Mesogaia",
            "Maroussi",
            "Megara",
            "Melission",
            "Metamorfosios",
            "Moshatos",
            "Nea Filedelfia",
            "Nea Ionia",
            "Nea Liosia",
            "Nea Smirni",
            "Nikaia",
            "Palaion Faliron",
            "Perama",
            "Peristerion",
            "Petroupoli",
            "Pevka",
            "Piraeus",
            "Salamis",
            "Tavros",
            "Viron",
            "Voula",
            "Vrilission",
            "Zografos"
          ],
          "StateName": "Attiki"
        },
        {
          "Cities": [],
          "StateName": "Ayion Oros"
        },
        {
          "Cities": [
            "Heraklion"
          ],
          "StateName": "Crete"
        },
        {
          "Cities": [
            "Arhangelos",
            "Ialysos",
            "Kos",
            "Rodos"
          ],
          "StateName": "Dodekanisos"
        },
        {
          "Cities": [
            "Drama"
          ],
          "StateName": "Drama"
        },
        {
          "Cities": [
            "Chalkis"
          ],
          "StateName": "Evia"
        },
        {
          "Cities": [
            "Karpenisi"
          ],
          "StateName": "Evritania"
        },
        {
          "Cities": [
            "Alexandroupoli",
            "Orestias"
          ],
          "StateName": "Evros"
        },
        {
          "Cities": [
            "Halkida"
          ],
          "StateName": "Evvoia"
        },
        {
          "Cities": [
            "Florina"
          ],
          "StateName": "Florina"
        },
        {
          "Cities": [
            "Amfissa"
          ],
          "StateName": "Fokis"
        },
        {
          "Cities": [
            "Lamia"
          ],
          "StateName": "Fthiotis"
        },
        {
          "Cities": [
            "Grevena"
          ],
          "StateName": "Grevena"
        },
        {
          "Cities": [
            "Halandri"
          ],
          "StateName": "Halandri"
        },
        {
          "Cities": [
            "Lakkoma",
            "N. Kallikrateia",
            "Poliyiros"
          ],
          "StateName": "Halkidiki"
        },
        {
          "Cities": [
            "Hania"
          ],
          "StateName": "Hania"
        },
        {
          "Cities": [
            "Crete"
          ],
          "StateName": "Heraklion"
        },
        {
          "Cities": [
            "Hios"
          ],
          "StateName": "Hios"
        },
        {
          "Cities": [
            "Pirgos"
          ],
          "StateName": "Ilia"
        },
        {
          "Cities": [
            "Veroia"
          ],
          "StateName": "Imathia"
        },
        {
          "Cities": [
            "Ioannina"
          ],
          "StateName": "Ioannina"
        },
        {
          "Cities": [],
          "StateName": "Iraklion"
        },
        {
          "Cities": [
            "Karditsa"
          ],
          "StateName": "Karditsa"
        },
        {
          "Cities": [
            "Kastoria"
          ],
          "StateName": "Kastoria"
        },
        {
          "Cities": [
            "Kavala"
          ],
          "StateName": "Kavala"
        },
        {
          "Cities": [
            "Agioi Theodoroi",
            "Argostolion"
          ],
          "StateName": "Kefallinia"
        },
        {
          "Cities": [
            "Kerkira"
          ],
          "StateName": "Kerkira"
        },
        {
          "Cities": [
            "Ermoupoli",
            "Fira",
            "Mikonos"
          ],
          "StateName": "Kiklades"
        },
        {
          "Cities": [
            "Kilkis"
          ],
          "StateName": "Kilkis"
        },
        {
          "Cities": [
            "Korinthos"
          ],
          "StateName": "Korinthia"
        },
        {
          "Cities": [
            "Kozani",
            "Ptolemais"
          ],
          "StateName": "Kozani"
        },
        {
          "Cities": [
            "Sparti"
          ],
          "StateName": "Lakonia"
        },
        {
          "Cities": [
            "Larisa",
            "Larissa"
          ],
          "StateName": "Larisa"
        },
        {
          "Cities": [
            "Ayios Nikolaos",
            "Ierapetra",
            "Sitia"
          ],
          "StateName": "Lasithi"
        },
        {
          "Cities": [
            "Mitilini"
          ],
          "StateName": "Lesvos"
        },
        {
          "Cities": [
            "Levkas"
          ],
          "StateName": "Levkas"
        },
        {
          "Cities": [
            "Volos"
          ],
          "StateName": "Magnisia"
        },
        {
          "Cities": [
            "Kalamata"
          ],
          "StateName": "Messinia"
        },
        {
          "Cities": [],
          "StateName": "Nomos Attikis"
        },
        {
          "Cities": [],
          "StateName": "Nomos Zakynthou"
        },
        {
          "Cities": [
            "Edessa",
            "Yiannitsa"
          ],
          "StateName": "Pella"
        },
        {
          "Cities": [
            "Katerini"
          ],
          "StateName": "Pieria"
        },
        {
          "Cities": [
            "Acharne",
            "Pallini"
          ],
          "StateName": "Piraios"
        },
        {
          "Cities": [
            "Preveza"
          ],
          "StateName": "Preveza"
        },
        {
          "Cities": [
            "Rethimnon"
          ],
          "StateName": "Rethimni"
        },
        {
          "Cities": [
            "Komotini"
          ],
          "StateName": "Rodopi"
        },
        {
          "Cities": [
            "Samos"
          ],
          "StateName": "Samos"
        },
        {
          "Cities": [
            "Serrai"
          ],
          "StateName": "Serrai"
        },
        {
          "Cities": [
            "Igoumenitsa"
          ],
          "StateName": "Thesprotia"
        },
        {
          "Cities": [
            "Ampelokipa",
            "Kalamaria",
            "Neapoli",
            "Oristiada",
            "Thessaloniki"
          ],
          "StateName": "Thessaloniki"
        },
        {
          "Cities": [
            "Trikala"
          ],
          "StateName": "Trikala"
        },
        {
          "Cities": [
            "Levadia",
            "Thivai"
          ],
          "StateName": "Voiotia"
        },
        {
          "Cities": [],
          "StateName": "West Greece"
        },
        {
          "Cities": [
            "Xanthi"
          ],
          "StateName": "Xanthi"
        },
        {
          "Cities": [
            "Zakinthos"
          ],
          "StateName": "Zakinthos"
        }
      ],
      "CountryName": "Greece"
    },
    {
      "States": [
        {
          "Cities": [
            "Aasiaat",
            "Akunnaaq",
            "Kitsissuarsuit"
          ],
          "StateName": "Aasiaat"
        },
        {
          "Cities": [
            "Ikkatteq",
            "Isortoq",
            "Kulusuk",
            "Kuumiut",
            "Qernertuarssuit",
            "Sermiligaaq",
            "Tasiilaq",
            "Tiniteqilaaq"
          ],
          "StateName": "Ammassalik"
        },
        {
          "Cities": [
            "Illoqqortoormiut",
            "Itterajivit",
            "Uunarteq"
          ],
          "StateName": "Illoqqortoormiut"
        },
        {
          "Cities": [
            "Ilimanaq",
            "Ilulissat",
            "Oqaatsut",
            "Qeqertaq",
            "Saqqaq"
          ],
          "StateName": "Ilulissat"
        },
        {
          "Cities": [
            "Ivittuut",
            "Kangilinnguit"
          ],
          "StateName": "Ivittuut"
        },
        {
          "Cities": [
            "Attu",
            "Iginniarfik",
            "Ikerasaarsuk",
            "Kangaatsiaq",
            "Niaqornaarsuk"
          ],
          "StateName": "Kangaatsiaq"
        },
        {
          "Cities": [
            "Atammik",
            "Kangaamiut",
            "Maniitsoq",
            "Napasoq"
          ],
          "StateName": "Maniitsoq"
        },
        {
          "Cities": [
            "Aappilattoq",
            "Akuliaruseq",
            "Alluitsoq",
            "Alluitsup Paa",
            "Ammassivik",
            "Ikerasassuaq",
            "Nanortalik",
            "Narsarmijit",
            "Nuugaarsuk",
            "Qallimiut",
            "Qortortorsuaq",
            "Tasiusaq"
          ],
          "StateName": "Nanortalik"
        },
        {
          "Cities": [
            "Amannguit",
            "Atarnaatsoq",
            "Eqaluit Ilua",
            "Igaliku",
            "Igaliku Kujalleq",
            "Inneruulalik",
            "Issormiut",
            "Iterlak",
            "Kangerlua",
            "Narsaq",
            "Narsarsuaq",
            "Nunataaq",
            "Qassiarsuk",
            "Qinngua",
            "Qinngua Kangilleq",
            "Qolortup Itinnera",
            "Sillisit",
            "Tasiusaq",
            "Timerliit",
            "Uummannartuuaraq"
          ],
          "StateName": "Narsaq"
        },
        {
          "Cities": [
            "Kangerluarsoruseq",
            "Kapisillit",
            "Neriunaq",
            "Nuuk",
            "Qeqertarsuatsiaat",
            "Qooqqut",
            "Qoornoq"
          ],
          "StateName": "Nuuk"
        },
        {
          "Cities": [
            "Arsuk",
            "Paamiut"
          ],
          "StateName": "Paamiut"
        },
        {
          "Cities": [
            "Moriusaq",
            "Qaanaaq",
            "Qeqertarsuaq",
            "Qeqertat",
            "Savissivik",
            "Siorapaluk"
          ],
          "StateName": "Qaanaaq"
        },
        {
          "Cities": [
            "Eqalugaarsuit",
            "Illorsuit",
            "Kangerluarsorujuk",
            "Kangerluarsorujuup Qinngua",
            "Qanisartuut",
            "Qaqortokolook",
            "Qaqortoq",
            "Qassimiut",
            "Saarloq",
            "Saqqamiut",
            "Tasiluk",
            "Upernaviarsuk"
          ],
          "StateName": "Qaqortoq"
        },
        {
          "Cities": [
            "Ikamiut",
            "Qasigiannguit"
          ],
          "StateName": "Qasigiannguit"
        },
        {
          "Cities": [
            "Kangerluk",
            "Qeqertarsuaq"
          ],
          "StateName": "Qeqertarsuaq"
        },
        {
          "Cities": [
            "Itilleq",
            "Kangerlussuaq",
            "Sarfannguit",
            "Sisimiut"
          ],
          "StateName": "Sisimiut"
        },
        {
          "Cities": [],
          "StateName": "Udenfor kommunal inddeling"
        },
        {
          "Cities": [
            "Aappilattoq",
            "Ikerakuuk",
            "Innarsuit",
            "Kangersuatsiaq",
            "Kullorsuaq",
            "Naajaat",
            "Nutaarmiut",
            "Nuusuaq",
            "Tasiusaq",
            "Upernavik",
            "Upernavik Kujalleq"
          ],
          "StateName": "Upernavik"
        },
        {
          "Cities": [
            "Ikerasak",
            "Illorsuit",
            "Niaqornat",
            "Nuugaatsiaq",
            "Qaarsut",
            "Saattut",
            "Ukkusissat",
            "Uummannaq"
          ],
          "StateName": "Uummannaq"
        }
      ],
      "CountryName": "Greenland"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Carriacou-Petite Martinique"
        },
        {
          "Cities": [],
          "StateName": "Saint Andrew"
        },
        {
          "Cities": [],
          "StateName": "Saint Davids"
        },
        {
          "Cities": [],
          "StateName": "Saint George's"
        },
        {
          "Cities": [],
          "StateName": "Saint John"
        },
        {
          "Cities": [],
          "StateName": "Saint Mark"
        },
        {
          "Cities": [],
          "StateName": "Saint Patrick"
        }
      ],
      "CountryName": "Grenada"
    },
    {
      "States": [
        {
          "Cities": [
            "Baie-Mahault",
            "Baillif",
            "Basse-Terre",
            "Bouillante",
            "Capesterre-Belle-Eau",
            "Gourbeyre",
            "Lamentin",
            "Petit-Bourg",
            "Pointe-Noire",
            "Saint-Claude",
            "Sainte-Rose",
            "Trois-Rivieres",
            "Vieux-Habitants"
          ],
          "StateName": "Basse-Terre"
        },
        {
          "Cities": [
            "Anse-Bertrand",
            "Le Gosier",
            "Le Moule",
            "Les Abymes",
            "Morne-a-l'Eau",
            "Petit-Canal",
            "Point-a-Pitre",
            "Port-Louis",
            "Saint-Francois",
            "Sainte-Anne"
          ],
          "StateName": "Grande-Terre"
        },
        {
          "Cities": [],
          "StateName": "Iles des Saintes"
        },
        {
          "Cities": [],
          "StateName": "La Desirade"
        },
        {
          "Cities": [
            "Grand-Bourg"
          ],
          "StateName": "Marie-Galante"
        },
        {
          "Cities": [],
          "StateName": "Saint Barthelemy"
        },
        {
          "Cities": [],
          "StateName": "Saint Martin"
        }
      ],
      "CountryName": "Guadeloupe"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Agana Heights"
        },
        {
          "Cities": [
            "Agat"
          ],
          "StateName": "Agat"
        },
        {
          "Cities": [
            "Barrigada",
            "Barrigada Heights"
          ],
          "StateName": "Barrigada"
        },
        {
          "Cities": [
            "Chalan Pago",
            "Ordot"
          ],
          "StateName": "Chalan-Pago-Ordot"
        },
        {
          "Cities": [
            "Astumbo",
            "Dededo",
            "Finegayan Station"
          ],
          "StateName": "Dededo"
        },
        {
          "Cities": [
            "Agana",
            "Agana Station"
          ],
          "StateName": "Hagatna"
        },
        {
          "Cities": [
            "Inarajan"
          ],
          "StateName": "Inarajan"
        },
        {
          "Cities": [
            "Mangilao"
          ],
          "StateName": "Mangilao"
        },
        {
          "Cities": [
            "Merizo"
          ],
          "StateName": "Merizo"
        },
        {
          "Cities": [
            "Mongmong",
            "Toto"
          ],
          "StateName": "Mongmong-Toto-Maite"
        },
        {
          "Cities": [],
          "StateName": "Santa Rita"
        },
        {
          "Cities": [
            "Sinajana"
          ],
          "StateName": "Sinajana"
        },
        {
          "Cities": [
            "Talofofo"
          ],
          "StateName": "Talofofo"
        },
        {
          "Cities": [
            "Tamuning"
          ],
          "StateName": "Tamuning"
        },
        {
          "Cities": [
            "Anderson Air Force Base",
            "Yigo"
          ],
          "StateName": "Yigo"
        },
        {
          "Cities": [
            "Yona"
          ],
          "StateName": "Yona"
        }
      ],
      "CountryName": "Guam"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Alta Verapaz"
        },
        {
          "Cities": [],
          "StateName": "Baja Verapaz"
        },
        {
          "Cities": [
            "Chimaltenango",
            "Comalapa",
            "Itzapa",
            "Patzun"
          ],
          "StateName": "Chimaltenango"
        },
        {
          "Cities": [
            "Chiquimula",
            "Esquipulas"
          ],
          "StateName": "Chiquimula"
        },
        {
          "Cities": [],
          "StateName": "El Progreso"
        },
        {
          "Cities": [
            "Cotzumalguapa",
            "Escuintla",
            "Palin",
            "San Jose",
            "Tiquisate"
          ],
          "StateName": "Escuintla"
        },
        {
          "Cities": [
            "Amatitlan",
            "Chinautla",
            "Guatemala",
            "Mixco",
            "Petapa",
            "Villa Nueva"
          ],
          "StateName": "Guatemala"
        },
        {
          "Cities": [
            "Huehuetenango"
          ],
          "StateName": "Huehuetenango"
        },
        {
          "Cities": [
            "Puerto Barrios"
          ],
          "StateName": "Izabal"
        },
        {
          "Cities": [
            "Jalapa"
          ],
          "StateName": "Jalapa"
        },
        {
          "Cities": [
            "Asuncion Mita",
            "Jutiapa"
          ],
          "StateName": "Jutiapa"
        },
        {
          "Cities": [
            "Flores",
            "San Benito"
          ],
          "StateName": "Peten"
        },
        {
          "Cities": [
            "Quezaltenango"
          ],
          "StateName": "Quezaltenango"
        },
        {
          "Cities": [
            "Quiche"
          ],
          "StateName": "Quiche"
        },
        {
          "Cities": [
            "Retalhuleu"
          ],
          "StateName": "Retalhuleu"
        },
        {
          "Cities": [
            "Antigua",
            "Ciudad Vieja",
            "Jocotenango",
            "Santa Maria de Jesus",
            "Sumpango"
          ],
          "StateName": "Sacatepequez"
        },
        {
          "Cities": [],
          "StateName": "San Marcos"
        },
        {
          "Cities": [],
          "StateName": "Santa Rosa"
        },
        {
          "Cities": [
            "Atitlan",
            "Solola"
          ],
          "StateName": "Solola"
        },
        {
          "Cities": [
            "Mazatenango"
          ],
          "StateName": "Suchitepequez"
        },
        {
          "Cities": [
            "Totonicapan"
          ],
          "StateName": "Totonicapan"
        },
        {
          "Cities": [
            "Zacapa"
          ],
          "StateName": "Zacapa"
        }
      ],
      "CountryName": "Guatemala"
    },
    {
      "States": [
        {
          "Cities": [
            "Saint Anne's"
          ],
          "StateName": "Alderney"
        },
        {
          "Cities": [
            "Castle"
          ],
          "StateName": "Castel"
        },
        {
          "Cities": [
            "Forest"
          ],
          "StateName": "Forest"
        },
        {
          "Cities": [],
          "StateName": "Saint Andrew"
        },
        {
          "Cities": [],
          "StateName": "Saint Martin"
        },
        {
          "Cities": [],
          "StateName": "Saint Peter Port"
        },
        {
          "Cities": [],
          "StateName": "Saint Pierre du Bois"
        },
        {
          "Cities": [],
          "StateName": "Saint Sampson"
        },
        {
          "Cities": [],
          "StateName": "Saint Saviour"
        },
        {
          "Cities": [
            "Sark"
          ],
          "StateName": "Sark"
        },
        {
          "Cities": [
            "Torteval"
          ],
          "StateName": "Torteval"
        },
        {
          "Cities": [
            "Vale"
          ],
          "StateName": "Vale"
        }
      ],
      "CountryName": "Guernsey and Alderney"
    },
    {
      "States": [
        {
          "Cities": [
            "Beyla"
          ],
          "StateName": "Beyla"
        },
        {
          "Cities": [
            "Boffa"
          ],
          "StateName": "Boffa"
        },
        {
          "Cities": [
            "Boke"
          ],
          "StateName": "Boke"
        },
        {
          "Cities": [
            "Conakry"
          ],
          "StateName": "Conakry"
        },
        {
          "Cities": [
            "Coyah"
          ],
          "StateName": "Coyah"
        },
        {
          "Cities": [
            "Dabola"
          ],
          "StateName": "Dabola"
        },
        {
          "Cities": [
            "Dalaba"
          ],
          "StateName": "Dalaba"
        },
        {
          "Cities": [
            "Dinguiraye"
          ],
          "StateName": "Dinguiraye"
        },
        {
          "Cities": [
            "Faranah"
          ],
          "StateName": "Faranah"
        },
        {
          "Cities": [
            "Forecariah"
          ],
          "StateName": "Forecariah"
        },
        {
          "Cities": [
            "Fria"
          ],
          "StateName": "Fria"
        },
        {
          "Cities": [
            "Gaoual"
          ],
          "StateName": "Gaoual"
        },
        {
          "Cities": [
            "Guekedou"
          ],
          "StateName": "Gueckedou"
        },
        {
          "Cities": [
            "Kankan"
          ],
          "StateName": "Kankan"
        },
        {
          "Cities": [
            "Kerouane"
          ],
          "StateName": "Kerouane"
        },
        {
          "Cities": [
            "Kindia"
          ],
          "StateName": "Kindia"
        },
        {
          "Cities": [
            "Kissidougou"
          ],
          "StateName": "Kissidougou"
        },
        {
          "Cities": [
            "Koubia"
          ],
          "StateName": "Koubia"
        },
        {
          "Cities": [
            "Koundara"
          ],
          "StateName": "Koundara"
        },
        {
          "Cities": [
            "Kouroussa"
          ],
          "StateName": "Kouroussa"
        },
        {
          "Cities": [
            "Labe"
          ],
          "StateName": "Labe"
        },
        {
          "Cities": [
            "Lola"
          ],
          "StateName": "Lola"
        },
        {
          "Cities": [
            "Macenta"
          ],
          "StateName": "Macenta"
        },
        {
          "Cities": [
            "Mali"
          ],
          "StateName": "Mali"
        },
        {
          "Cities": [
            "Mamou"
          ],
          "StateName": "Mamou"
        },
        {
          "Cities": [
            "Mandiana"
          ],
          "StateName": "Mandiana"
        },
        {
          "Cities": [
            "Nzerekore"
          ],
          "StateName": "Nzerekore"
        },
        {
          "Cities": [
            "Pita"
          ],
          "StateName": "Pita"
        },
        {
          "Cities": [
            "Siguiri"
          ],
          "StateName": "Siguiri"
        },
        {
          "Cities": [
            "Telimele"
          ],
          "StateName": "Telimele"
        },
        {
          "Cities": [
            "Tougue"
          ],
          "StateName": "Tougue"
        },
        {
          "Cities": [
            "Yomou"
          ],
          "StateName": "Yomou"
        }
      ],
      "CountryName": "Guinea"
    },
    {
      "States": [
        {
          "Cities": [
            "Bafata"
          ],
          "StateName": "Bafata"
        },
        {
          "Cities": [
            "Bissau"
          ],
          "StateName": "Bissau"
        },
        {
          "Cities": [
            "Bolama",
            "Bubaque"
          ],
          "StateName": "Bolama"
        },
        {
          "Cities": [
            "Cacheu",
            "Canchungo"
          ],
          "StateName": "Cacheu"
        },
        {
          "Cities": [
            "Gabu"
          ],
          "StateName": "Gabu"
        },
        {
          "Cities": [
            "Bissora",
            "Farim",
            "Mansoa"
          ],
          "StateName": "Oio"
        },
        {
          "Cities": [
            "Buba",
            "Fulacunda",
            "Quebo"
          ],
          "StateName": "Quinara"
        },
        {
          "Cities": [
            "Catio"
          ],
          "StateName": "Tombali"
        }
      ],
      "CountryName": "Guinea-Bissau"
    },
    {
      "States": [
        {
          "Cities": [
            "Mabaruma",
            "Morawhanna"
          ],
          "StateName": "Barima-Waini"
        },
        {
          "Cities": [
            "Bartica",
            "Issano",
            "Kamarang"
          ],
          "StateName": "Cuyuni-Mazaruni"
        },
        {
          "Cities": [
            "Georgetown",
            "Mahaica",
            "Paradise",
            "Queenstown"
          ],
          "StateName": "Demerara-Mahaica"
        },
        {
          "Cities": [],
          "StateName": "East Berbice-Corentyne"
        },
        {
          "Cities": [],
          "StateName": "Essequibo Islands-West Demerar"
        },
        {
          "Cities": [
            "Fort Wellington",
            "Mahaicony",
            "Rosignol"
          ],
          "StateName": "Mahaica-Berbice"
        },
        {
          "Cities": [
            "Anna Regina",
            "Charity",
            "Suddie"
          ],
          "StateName": "Pomeroon-Supenaam"
        },
        {
          "Cities": [
            "Mahdia",
            "Tumatumari"
          ],
          "StateName": "Potaro-Siparuni"
        },
        {
          "Cities": [],
          "StateName": "Upper Demerara-Berbice"
        },
        {
          "Cities": [],
          "StateName": "Upper Takutu-Upper Essequibo"
        }
      ],
      "CountryName": "Guyana"
    },
    {
      "States": [
        {
          "Cities": [
            "Desdunes",
            "Dessalines",
            "Gonaives",
            "Gros-Morne",
            "L'Artibonite",
            "Saint-Marc",
            "Saint-Michel-de-l'Atalaye",
            "Saint-Raphael",
            "Verrettes"
          ],
          "StateName": "Artibonite"
        },
        {
          "Cities": [
            "Hinche",
            "Mirebalais"
          ],
          "StateName": "Centre"
        },
        {
          "Cities": [
            "Anse-d'Hainault",
            "Dame Marie",
            "Jeremie",
            "Miragoane"
          ],
          "StateName": "Grand'Anse"
        },
        {
          "Cities": [
            "Cap-Haitien",
            "Croix-des-Bouquets",
            "Grande Riviere du Nord",
            "Limbe",
            "Pignon"
          ],
          "StateName": "Nord"
        },
        {
          "Cities": [
            "Derac",
            "Fort-Liberte",
            "Ouanaminthe",
            "Trou-du-Nord"
          ],
          "StateName": "Nord-Est"
        },
        {
          "Cities": [
            "Port-de-Paix",
            "Saint-Louis-du-Nord"
          ],
          "StateName": "Nord-Ouest"
        },
        {
          "Cities": [
            "Anse-a-Galets",
            "Carrefour",
            "Delmas",
            "Kenscoff",
            "Lascahobas",
            "Leogane",
            "Petionville",
            "Petit Goave",
            "Port-au-Prince"
          ],
          "StateName": "Ouest"
        },
        {
          "Cities": [
            "Aquin",
            "Les Cayes"
          ],
          "StateName": "Sud"
        },
        {
          "Cities": [
            "Jacmel"
          ],
          "StateName": "Sud-Est"
        }
      ],
      "CountryName": "Haiti"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Heard and McDonald Islands"
        }
      ],
      "CountryName": "Heard and McDonald Islands"
    },
    {
      "States": [
        {
          "Cities": [
            "La Ceiba",
            "Olanchito",
            "Tela"
          ],
          "StateName": "Atlantida"
        },
        {
          "Cities": [
            "Choluteca",
            "El Triunfo",
            "Pespire"
          ],
          "StateName": "Choluteca"
        },
        {
          "Cities": [
            "Sonaguera",
            "Tocoa",
            "Trujillo"
          ],
          "StateName": "Colon"
        },
        {
          "Cities": [
            "Comayagua",
            "Siguatepeque"
          ],
          "StateName": "Comayagua"
        },
        {
          "Cities": [
            "Copan",
            "Corquin",
            "Dulce Nombre",
            "El Paraiso",
            "San Antonio",
            "San Nicolas",
            "Santa Rosa de Copan"
          ],
          "StateName": "Copan"
        },
        {
          "Cities": [
            "Choloma",
            "La Lima",
            "Omoa",
            "Puerto Cortes",
            "San Pedro Sula"
          ],
          "StateName": "Cortes"
        },
        {
          "Cities": [],
          "StateName": "Distrito Central"
        },
        {
          "Cities": [],
          "StateName": "El Paraiso"
        },
        {
          "Cities": [],
          "StateName": "Francisco Morazan"
        },
        {
          "Cities": [],
          "StateName": "Gracias a Dios"
        },
        {
          "Cities": [
            "Intibuca",
            "La Esperanza",
            "Utila"
          ],
          "StateName": "Intibuca"
        },
        {
          "Cities": [],
          "StateName": "Islas de la Bahia"
        },
        {
          "Cities": [],
          "StateName": "La Paz"
        },
        {
          "Cities": [
            "Gracias"
          ],
          "StateName": "Lempira"
        },
        {
          "Cities": [
            "Ocotepeque",
            "San Marcos",
            "Sinuapa"
          ],
          "StateName": "Ocotepeque"
        },
        {
          "Cities": [
            "Catacamas",
            "Juticalpa"
          ],
          "StateName": "Olancho"
        },
        {
          "Cities": [],
          "StateName": "Santa Barbara"
        },
        {
          "Cities": [
            "Amapala",
            "Langue",
            "Nacaome",
            "San Lorenzo"
          ],
          "StateName": "Valle"
        },
        {
          "Cities": [
            "El Progreso",
            "Morazan",
            "Santa Rita",
            "Yoro"
          ],
          "StateName": "Yoro"
        }
      ],
      "CountryName": "Honduras"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Hong Kong"
        }
      ],
      "CountryName": "Hong Kong S.A.R."
    },
    {
      "States": [
        {
          "Cities": [
            "Akaszto",
            "Bacsalmas",
            "Bacsbokod",
            "Baja",
            "Bugac",
            "Davod",
            "Dunapataj",
            "Dunavecse",
            "Fulopszallas",
            "Hajos",
            "Harta",
            "Izsak",
            "Jakabszallas",
            "Janoshalma",
            "Kalocsa",
            "Kecel",
            "Kecskemet",
            "Kiskoros",
            "Kiskunfelegyhaza",
            "Kiskunhalas",
            "Kiskunmajsa",
            "Kunfeherto",
            "Kunszentmiklos",
            "Lajosmizse",
            "Lakitelek",
            "Madaras",
            "Melykut",
            "Nagybaracska",
            "Orgovany",
            "Palmonostora",
            "Solt",
            "Soltvadkert",
            "Sukosd",
            "Szabadszallas",
            "Szalkszentmarton",
            "Tass",
            "Tiszakecske",
            "Tompa"
          ],
          "StateName": "Bacs-Kiskun"
        },
        {
          "Cities": [
            "Beremend",
            "Boly",
            "Dunaszekcso",
            "Harkany",
            "Hosszuheteny",
            "Komlo",
            "Magocs",
            "Mohacs",
            "Pecs",
            "Pecsvarad",
            "Sasd",
            "Sellye",
            "Siklos",
            "Szentlorinc",
            "Szigetvar",
            "Vajszlo",
            "Villany"
          ],
          "StateName": "Baranya"
        },
        {
          "Cities": [
            "Battonya",
            "Bekes",
            "Bekescsaba",
            "Bekessamson",
            "Bekesszentandras",
            "Csorvas",
            "Devavanya",
            "Doboz",
            "Elek",
            "Endrod",
            "Fuzesgyarmat",
            "Gyula",
            "Ketegyhaza",
            "Kondoros",
            "Korosladany",
            "Kunagota",
            "Lokoshaza",
            "Mezobereny",
            "Mezohegyes",
            "Mezokovacshaza",
            "Nagyszenas",
            "Oroshaza",
            "Sarkad",
            "Szabadkigyos",
            "Szarvas",
            "Szeghalom",
            "Totkomlos",
            "Veszto"
          ],
          "StateName": "Bekes"
        },
        {
          "Cities": [
            "Abaujszanto",
            "Arlo",
            "Bogacs",
            "Cigand",
            "Edeleny",
            "Emod",
            "Encs",
            "Gonc",
            "Karcsa",
            "Kazincbarcika",
            "Mad",
            "Megyaszo",
            "Mezokeresztes",
            "Mezokovesd",
            "Miskolc",
            "Monok",
            "Nyekladhaza",
            "Olaszliszka",
            "Onod",
            "Ozd",
            "Putnok",
            "Rudabanya",
            "Sajokaza",
            "Sajolad",
            "Sajoszentpeter",
            "Saly",
            "Sarospatak",
            "Satoraljaujhely",
            "Szendro",
            "Szentistvan",
            "Szerencs",
            "Szihalom",
            "Szikszo",
            "Taktaharkany",
            "Taktaszada",
            "Tallya",
            "Tarcal",
            "Tiszaluc",
            "Tiszaujvaros",
            "Tokaj",
            "Tolcsva"
          ],
          "StateName": "Borsod-Abauj-Zemplen"
        },
        {
          "Cities": [
            "Budapest"
          ],
          "StateName": "Budapest"
        },
        {
          "Cities": [
            "Csongrad",
            "Fabiansebestyen",
            "Foldeak",
            "Hodmezovasarhely",
            "Kiskundorozsma",
            "Kistelek",
            "Kiszombor",
            "Mako",
            "Mindszent",
            "Morahalom",
            "Pusztaszer",
            "Roszke",
            "Sandorfalva",
            "Szatymaz",
            "Szeged",
            "Szegvar",
            "Szekkutas",
            "Szentes"
          ],
          "StateName": "Csongrad"
        },
        {
          "Cities": [
            "Aba",
            "Adony",
            "Alap",
            "Apostag",
            "Bakonycsernye",
            "Bicske",
            "Bodajk",
            "Cece",
            "Csakvar",
            "Deg",
            "Dios",
            "Dunaujvaros",
            "Enying",
            "Ercsi",
            "Etyek",
            "Fehervarcsurgo",
            "Lovasbereny",
            "Martonvasar",
            "Mezofalva",
            "Mezoszilas",
            "Mor",
            "Pazmand",
            "Polgardi",
            "Pusztavam",
            "Rackeresztur",
            "Sarbogard",
            "Seregelyes",
            "Soponya",
            "Szabadbattyan",
            "Szekesfehervar",
            "Val"
          ],
          "StateName": "Fejer"
        },
        {
          "Cities": [
            "Asvanyraro",
            "Beled",
            "Bosarkany",
            "Csorna",
            "Fertod",
            "Fertorakos",
            "Fertoszentmiklos",
            "Gyor",
            "Gyorujbarat",
            "Hunyadi u.",
            "Kapuvar",
            "Lebeny",
            "Mihalyi",
            "Mosonmagyarovar",
            "Nyul",
            "Pannonhalma",
            "Rajka",
            "Sopron",
            "Szany",
            "Tet"
          ],
          "StateName": "Gyor-Moson-Sopron"
        },
        {
          "Cities": [
            "Balmazujvaros",
            "Barand",
            "Berettyoujfalu",
            "Biharkeresztes",
            "Biharnagybajom",
            "Debrecen",
            "Derecske",
            "Egyek",
            "Foldes",
            "Hajduboszormeny",
            "Hajdudorog",
            "Hajduhadhaz",
            "Hajdusamson",
            "Hajduszoboszlo",
            "Hajduszovat",
            "Hortobagy",
            "Hosszupalyi",
            "Kaba",
            "Komadi",
            "Mikepercs",
            "Monostorpalyi",
            "Nadudvar",
            "Nagyleta",
            "Nyirabrany",
            "Nyiracsad",
            "Nyiradony",
            "Polgar",
            "Puspokladany",
            "Sarretudvari",
            "Tiszacsege",
            "Ujfeherto",
            "Vamospercs"
          ],
          "StateName": "Hajdu-Bihar"
        },
        {
          "Cities": [
            "Abasar",
            "Andornaktalya",
            "Belapatfalva",
            "Domoszlo",
            "Eger",
            "Erdotelek",
            "Felsotarkany",
            "Fuzesabony",
            "Gyongyos",
            "Gyongyospata",
            "Gyongyossolymos",
            "Gyongyostarjan",
            "Hatvan",
            "Heves",
            "Kal",
            "Lorinci",
            "Matraderecske",
            "Parad",
            "Petervasara",
            "Recsk",
            "Sirok",
            "Tarnalelesz",
            "Verpelet"
          ],
          "StateName": "Heves"
        },
        {
          "Cities": [
            "Abadszalok",
            "Besenyszog",
            "Cserkeszolo",
            "Fegyvernek",
            "Hegyeshalom",
            "Jaszalsoszentgyorgy",
            "Jaszapati",
            "Jaszarokszallas",
            "Jaszbereny",
            "Jaszfenyzaru",
            "Jaszjakohalma",
            "Jaszkiser",
            "Jaszladany",
            "Jaszszentandras",
            "Karcag",
            "Kenderes",
            "Kisujszallas",
            "Kunhegyes",
            "Kunmadaras",
            "Kunszentmarton",
            "Martfu",
            "Mezotur",
            "Ocsod",
            "Szolnok",
            "Tiszabura",
            "Tiszafoldvar",
            "Tiszafured",
            "Tiszapuspoki",
            "Tiszaroff",
            "Tiszasuly",
            "Torokszentmiklos",
            "Toszeg",
            "Turkeve",
            "Ujszasz"
          ],
          "StateName": "Jasz-Nagykun-Szolnok"
        },
        {
          "Cities": [
            "Acs",
            "Almasfuzito",
            "Babolna",
            "Bajna",
            "Dorog",
            "Esztergom",
            "Kesztolc",
            "Kisber",
            "Komarom",
            "Kornye",
            "Labatlan",
            "Mocsa",
            "Nagyigmand",
            "Nyergesujfalu",
            "Oroszlany",
            "Sarisap",
            "Tardos",
            "Tarjan",
            "Tata",
            "Tatabanya",
            "Tokod",
            "Vertesszolos"
          ],
          "StateName": "Komarom-Esztergom"
        },
        {
          "Cities": [
            "Balassagyarmat",
            "Batonyterenye",
            "Bercel",
            "Bujak",
            "Diosjeno",
            "Karancskeszi",
            "Matraverebely",
            "Nagyoroszi",
            "Paszto",
            "Retsag",
            "Romhany",
            "Salgotarjan",
            "Szecseny",
            "Tar"
          ],
          "StateName": "Nograd"
        },
        {
          "Cities": [
            "Abony",
            "Albertirsa",
            "Aszod",
            "Biatorbagy",
            "Budakalasz",
            "Budakeszi",
            "Budaors",
            "Bugyi",
            "Cegled",
            "Csobanka",
            "Dabas",
            "Domsod",
            "Dunabogdany",
            "Dunaharaszti",
            "Dunakeszi",
            "Erd",
            "Forro",
            "Fot",
            "Galgaheviz",
            "God",
            "Godollo",
            "Gyomro",
            "Hevizgyork",
            "Isaszeg",
            "Jaszkarajeno",
            "Kiskunlachaza",
            "Kocser",
            "Koka",
            "Kosd",
            "Maglod",
            "Monor",
            "Nagykata",
            "Nagykoros",
            "Nagykovacsi",
            "Nagymaros",
            "Nagytarcsa",
            "Nyaregyhaza",
            "Ocsa",
            "Orbottyan",
            "Orkeny",
            "Paty",
            "Pecel",
            "Perbal",
            "Pilis",
            "Pilisborosjeno",
            "Piliscsaba",
            "Pilisszanto",
            "Pilisszentivan",
            "Pilisszentkereszt",
            "Pilisvorosvar",
            "Pomaz",
            "Racalmas",
            "Rackeve",
            "Solymar",
            "Soskut",
            "Szada",
            "Szazhalombatta",
            "Szentendre",
            "Szentmartonkata",
            "Szigetcsep",
            "Szigetszentmiklos",
            "Szigetujfalu",
            "Szob",
            "Tahitofalu",
            "Tapiobicske",
            "Tapioszecso",
            "Tapioszele",
            "Toalmas",
            "Torokbalint",
            "Tortel",
            "Tura",
            "Ullo",
            "Uri",
            "Urom",
            "Vac",
            "Vecses",
            "Veresegyhaz",
            "Verocemaros",
            "Visegrad",
            "Zsambek",
            "Zsambok"
          ],
          "StateName": "Pest"
        },
        {
          "Cities": [
            "Adand",
            "Balatonfoldvar",
            "Balatonoszod",
            "Balatonszabadi",
            "Balatonszarszo",
            "Barcs",
            "Berzence",
            "Boglarlelle",
            "Bohonye",
            "Csurgo",
            "Fonyod",
            "Kaposvar",
            "Karad",
            "Kethely",
            "Lengyeltoti",
            "Marcali",
            "Nagyatad",
            "Nagybajom",
            "Siofok",
            "Somogyvar",
            "Tab",
            "Zamardi"
          ],
          "StateName": "Somogy"
        },
        {
          "Cities": [
            "Ajak",
            "Baktaloranthaza",
            "Balkany",
            "Buj",
            "Demecser",
            "Dombrad",
            "Fehergyarmat",
            "Ibrany",
            "Kemecse",
            "Kisvarda",
            "Kotaj",
            "Mandok",
            "Mariapocs",
            "Mateszalka",
            "Nagyecsed",
            "Nagyhalasz",
            "Nagykallo",
            "Nyirbator",
            "Nyirbeltek",
            "Nyiregyhaza",
            "Nyirmada",
            "Nyirpazony",
            "Nyirtelek",
            "Ofeherto",
            "Rakamaz",
            "Tarpa",
            "Tiszabercel",
            "Tiszalok",
            "Tiszavasvari",
            "Tuzser",
            "Vaja",
            "Vasarosnameny",
            "Zahony"
          ],
          "StateName": "Szabolcs-Szatmar-Bereg"
        },
        {
          "Cities": [
            "Bataszek",
            "Bonyhad",
            "Decs",
            "Dombovar",
            "Dunafoldvar",
            "Fadd",
            "Gyonk",
            "Hogyesz",
            "Iregszemcse",
            "Madocsa",
            "Nagymagocs",
            "Nagymanyok",
            "Ozora",
            "Paks",
            "Pincehely",
            "Simontornya",
            "Szekszard",
            "Szentgal",
            "Tamasi",
            "Tengelic",
            "Tolna",
            "Zomba"
          ],
          "StateName": "Tolna"
        },
        {
          "Cities": [
            "Buk",
            "Celldomolk",
            "Csepreg",
            "Gencsapati",
            "Jak",
            "Janoshaza",
            "Kormend",
            "Koszeg",
            "Sarvar",
            "Szentgotthard",
            "Szombathely",
            "Vasvar",
            "Vep"
          ],
          "StateName": "Vas"
        },
        {
          "Cities": [
            "Ajka",
            "Badacsonytomaj",
            "Balatonalmadi",
            "Balatonfured",
            "Balatonfuzfo",
            "Balatonkenese",
            "Band",
            "Berhida",
            "Csabrendek",
            "Devecser",
            "Herend",
            "Papa",
            "Sumeg",
            "Tapolca",
            "Urkut",
            "Varpalota",
            "Veszprem",
            "Zirc"
          ],
          "StateName": "Veszprem"
        },
        {
          "Cities": [
            "Becsehely",
            "Heviz",
            "Keszthely",
            "Lenti",
            "Letenye",
            "Nagykanizsa",
            "Sarmellek",
            "Turje",
            "Zalaegerszeg",
            "Zalakomar",
            "Zalalovo",
            "Zalaszentgrot"
          ],
          "StateName": "Zala"
        }
      ],
      "CountryName": "Hungary"
    },
    {
      "States": [
        {
          "Cities": [
            "Bakkafjor ur",
            "Borgarfjor ur",
            "Brei dalsvik",
            "Djupivogur",
            "Egilssta ir",
            "Eskifjor ur",
            "Faskru sfjor ur",
            "Fellabar",
            "Hallormssta ur",
            "Hofn",
            "Nesjakauptun",
            "Neskaupsta ur",
            "Rey arfjor ur",
            "Sey isfjor ur",
            "Sto varfjor ur",
            "Vopnafjor ur"
          ],
          "StateName": "Austurland"
        },
        {
          "Cities": [],
          "StateName": "Gullbringusysla"
        },
        {
          "Cities": [],
          "StateName": "Hofu borgarsva i"
        },
        {
          "Cities": [],
          "StateName": "Nor urland eystra"
        },
        {
          "Cities": [],
          "StateName": "Nor urland vestra"
        },
        {
          "Cities": [],
          "StateName": "Su urland"
        },
        {
          "Cities": [],
          "StateName": "Su urnes"
        },
        {
          "Cities": [],
          "StateName": "Vestfir ir"
        },
        {
          "Cities": [
            "Akranes",
            "Borgarnes",
            "Bu ardalur",
            "Grundarfjor ur",
            "Hellissandur",
            "Hvanneyri",
            "Olafsvik",
            "Rif",
            "Stykkisholmur"
          ],
          "StateName": "Vesturland"
        }
      ],
      "CountryName": "Iceland"
    },
    {
      "States": [
        {
          "Cities": [
            "Bombuflat",
            "Garacharma",
            "Port Blair",
            "Rangat"
          ],
          "StateName": "Andaman and Nicobar Islands"
        },
        {
          "Cities": [
            "Addanki",
            "Adivivaram",
            "Adoni",
            "Aganampudi",
            "Ajjaram",
            "Akividu",
            "Akkarampalle",
            "Akkayapalle",
            "Akkireddipalem",
            "Alampur",
            "Amalapuram",
            "Amudalavalasa",
            "Amur",
            "Anakapalle",
            "Anantapur",
            "Andole",
            "Atmakur",
            "Attili",
            "Avanigadda",
            "Badepalli",
            "Badvel",
            "Balapur",
            "Bandarulanka",
            "Banganapalle",
            "Bapatla",
            "Bapulapadu",
            "Belampalli",
            "Bestavaripeta",
            "Betamcherla",
            "Bhattiprolu",
            "Bhimavaram",
            "Bhimunipatnam",
            "Bobbili",
            "Bombuflat",
            "Bommuru",
            "Bugganipalle",
            "Challapalle",
            "Chandur",
            "Chatakonda",
            "Chemmumiahpet",
            "Chidiga",
            "Chilakaluripet",
            "Chimakurthy",
            "Chinagadila",
            "Chinagantyada",
            "Chinnachawk",
            "Chintalavalasa",
            "Chipurupalle",
            "Chirala",
            "Chittoor",
            "Chodavaram",
            "Choutuppal",
            "Chunchupalle",
            "Cuddapah",
            "Cumbum",
            "Darnakal",
            "Dasnapur",
            "Dauleshwaram",
            "Dharmavaram",
            "Dhone",
            "Dommara Nandyal",
            "Dowlaiswaram",
            "East Godavari Dist.",
            "Eddumailaram",
            "Edulapuram",
            "Ekambara kuppam",
            "Eluru",
            "Enikapadu",
            "Fakirtakya",
            "Farrukhnagar",
            "Gaddiannaram",
            "Gajapathinagaram",
            "Gajularega",
            "Gajuvaka",
            "Gannavaram",
            "Garacharma",
            "Garimellapadu",
            "Giddalur",
            "Godavarikhani",
            "Gopalapatnam",
            "Gopalur",
            "Gorrekunta",
            "Gudivada",
            "Gudur",
            "Guntakal",
            "Guntur",
            "Guti",
            "Hindupur",
            "Hukumpeta",
            "Ichchapuram",
            "Isnapur",
            "Jaggayyapeta",
            "Jallaram Kamanpur",
            "Jammalamadugu",
            "Jangampalli",
            "Jarjapupeta",
            "Kadiri",
            "Kaikalur",
            "Kakinada",
            "Kallur",
            "Kalyandurg",
            "Kamalapuram",
            "Kamareddi",
            "Kanapaka",
            "Kanigiri",
            "Kanithi",
            "Kankipadu",
            "Kantabamsuguda",
            "Kanuru",
            "Karnul",
            "Katheru",
            "Kavali",
            "Kazipet",
            "Khanapuram Haveli",
            "Kodar",
            "Kollapur",
            "Kondapalem",
            "Kondapalle",
            "Kondukur",
            "Kosgi",
            "Kothavalasa",
            "Kottapalli",
            "Kovur",
            "Kovurpalle",
            "Kovvur",
            "Krishna",
            "Kuppam",
            "Kurmannapalem",
            "Kurnool",
            "Lakshettipet",
            "Lalbahadur Nagar",
            "Machavaram",
            "Macherla",
            "Machilipatnam",
            "Madanapalle",
            "Madaram",
            "Madhuravada",
            "Madikonda",
            "Madugule",
            "Mahabubnagar",
            "Mahbubabad",
            "Malkajgiri",
            "Mamilapalle",
            "Mancheral",
            "Mandapeta",
            "Mandasa",
            "Mangalagiri",
            "Manthani",
            "Markapur",
            "Marturu",
            "Metpalli",
            "Mindi",
            "Mirpet",
            "Moragudi",
            "Mothugudam",
            "Nagari",
            "Nagireddipalle",
            "Nandigama",
            "Nandikotkur",
            "Nandyal",
            "Narasannapeta",
            "Narasapur",
            "Narasaraopet",
            "Narayanavanam",
            "Narsapur",
            "Narsingi",
            "Narsipatnam",
            "Naspur",
            "Nathayyapalem",
            "Nayudupeta",
            "Nelimaria",
            "Nellore",
            "Nidadavole",
            "Nuzvid",
            "Omerkhan daira",
            "Ongole",
            "Osmania University",
            "Pakala",
            "Palakole",
            "Palakurthi",
            "Palasa",
            "Palempalle",
            "Palkonda",
            "Palmaner",
            "Pamur",
            "Panjim",
            "Papampeta",
            "Parasamba",
            "Parvatipuram",
            "Patancheru",
            "Payakaraopet",
            "Pedagantyada",
            "Pedana",
            "Peddapuram",
            "Pendurthi",
            "Penugonda",
            "Penukonda",
            "Phirangipuram",
            "Pithapuram",
            "Ponnur",
            "Port Blair",
            "Pothinamallayyapalem",
            "Prakasam",
            "Prasadampadu",
            "Prasantinilayam",
            "Proddatur",
            "Pulivendla",
            "Punganuru",
            "Puttur",
            "Qutubullapur",
            "Rajahmundry",
            "Rajamahendri",
            "Rajampet",
            "Rajendranagar",
            "Rajoli",
            "Ramachandrapuram",
            "Ramanayyapeta",
            "Ramapuram",
            "Ramarajupalli",
            "Ramavarappadu",
            "Rameswaram",
            "Rampachodavaram",
            "Ravulapalam",
            "Rayachoti",
            "Rayadrug",
            "Razam",
            "Razole",
            "Renigunta",
            "Repalle",
            "Rishikonda",
            "Salur",
            "Samalkot",
            "Sattenapalle",
            "Seetharampuram",
            "Serilungampalle",
            "Shankarampet",
            "Shar",
            "Singarayakonda",
            "Sirpur",
            "Sirsilla",
            "Sompeta",
            "Sriharikota",
            "Srikakulam",
            "Srikalahasti",
            "Sriramnagar",
            "Sriramsagar",
            "Srisailam",
            "Srisailamgudem Devasthanam",
            "Sulurpeta",
            "Suriapet",
            "Suryaraopet",
            "Tadepalle",
            "Tadepalligudem",
            "Tadpatri",
            "Tallapalle",
            "Tanuku",
            "Tekkali",
            "Tenali",
            "Tigalapahad",
            "Tiruchanur",
            "Tirumala",
            "Tirupati",
            "Tirvuru",
            "Trimulgherry",
            "Tuni",
            "Turangi",
            "Ukkayapalli",
            "Ukkunagaram",
            "Uppal Kalan",
            "Upper Sileru",
            "Uravakonda",
            "Vadlapudi",
            "Vaparala",
            "Vemalwada",
            "Venkatagiri",
            "Venkatapuram",
            "Vepagunta",
            "Vetapalem",
            "Vijayapuri",
            "Vijayapuri South",
            "Vijayawada",
            "Vinukonda",
            "Visakhapatnam",
            "Vizianagaram",
            "Vuyyuru",
            "Wanparti",
            "West Godavari Dist.",
            "Yadagirigutta",
            "Yarada",
            "Yellamanchili",
            "Yemmiganur",
            "Yenamalakudru",
            "Yendada",
            "Yerraguntla"
          ],
          "StateName": "Andhra Pradesh"
        },
        {
          "Cities": [
            "Along",
            "Basar",
            "Bondila",
            "Changlang",
            "Daporijo",
            "Deomali",
            "Itanagar",
            "Jairampur",
            "Khonsa",
            "Naharlagun",
            "Namsai",
            "Pasighat",
            "Roing",
            "Seppa",
            "Tawang",
            "Tezu",
            "Ziro"
          ],
          "StateName": "Arunachal Pradesh"
        },
        {
          "Cities": [
            "Abhayapuri",
            "Ambikapur",
            "Amguri",
            "Anand Nagar",
            "Badarpur",
            "Badarpur Railway Town",
            "Bahbari Gaon",
            "Bamun Sualkuchi",
            "Barbari",
            "Barpathar",
            "Barpeta",
            "Barpeta Road",
            "Basugaon",
            "Bihpuria",
            "Bijni",
            "Bilasipara",
            "Biswanath Chariali",
            "Bohori",
            "Bokajan",
            "Bokokhat",
            "Bongaigaon",
            "Bongaigaon Petro-chemical Town",
            "Borgolai",
            "Chabua",
            "Chandrapur Bagicha",
            "Chapar",
            "Chekonidhara",
            "Choto Haibor",
            "Dergaon",
            "Dharapur",
            "Dhekiajuli",
            "Dhemaji",
            "Dhing",
            "Dhubri",
            "Dhuburi",
            "Dibrugarh",
            "Digboi",
            "Digboi Oil Town",
            "Dimaruguri",
            "Diphu",
            "Dispur",
            "Doboka",
            "Dokmoka",
            "Donkamokan",
            "Duliagaon",
            "Duliajan",
            "Duliajan No.1",
            "Dum Duma",
            "Durga Nagar",
            "Gauripur",
            "Goalpara",
            "Gohpur",
            "Golaghat",
            "Golakganj",
            "Gossaigaon",
            "Guwahati",
            "Haflong",
            "Hailakandi",
            "Hamren",
            "Hauli",
            "Hauraghat",
            "Hojai",
            "Jagiroad",
            "Jagiroad Paper Mill",
            "Jogighopa",
            "Jonai Bazar",
            "Jorhat",
            "Kampur Town",
            "Kamrup",
            "Kanakpur",
            "Karimganj",
            "Kharijapikon",
            "Kharupetia",
            "Kochpara",
            "Kokrajhar",
            "Kumar Kaibarta Gaon",
            "Lakhimpur",
            "Lakhipur",
            "Lala",
            "Lanka",
            "Lido Tikok",
            "Lido Town",
            "Lumding",
            "Lumding Railway Colony",
            "Mahur",
            "Maibong",
            "Majgaon",
            "Makum",
            "Mangaldai",
            "Mankachar",
            "Margherita",
            "Mariani",
            "Marigaon",
            "Moran",
            "Moranhat",
            "Nagaon",
            "Naharkatia",
            "Nalbari",
            "Namrup",
            "Naubaisa Gaon",
            "Nazira",
            "New Bongaigaon Railway Colony",
            "Niz-Hajo",
            "North Guwahati",
            "Numaligarh",
            "Palasbari",
            "Panchgram",
            "Pathsala",
            "Raha",
            "Rangapara",
            "Rangia",
            "Salakati",
            "Sapatgram",
            "Sarthebari",
            "Sarupathar",
            "Sarupathar Bengali",
            "Senchoagaon",
            "Sibsagar",
            "Silapathar",
            "Silchar",
            "Silchar Part-X",
            "Sonari",
            "Sorbhog",
            "Sualkuchi",
            "Tangla",
            "Tezpur",
            "Tihu",
            "Tinsukia",
            "Titabor",
            "Udalguri",
            "Umrangso",
            "Uttar Krishnapur Part-I"
          ],
          "StateName": "Assam"
        },
        {
          "Cities": [
            "Amarpur",
            "Ara",
            "Araria",
            "Areraj",
            "Asarganj",
            "Aurangabad",
            "Bagaha",
            "Bahadurganj",
            "Bairgania",
            "Bakhtiyarpur",
            "Banka",
            "Banmankhi",
            "Bar Bigha",
            "Barauli",
            "Barauni Oil Township",
            "Barh",
            "Barhiya",
            "Bariapur",
            "Baruni",
            "Begusarai",
            "Behea",
            "Belsand",
            "Bettiah",
            "Bhabua",
            "Bhagalpur",
            "Bhimnagar",
            "Bhojpur",
            "Bihar",
            "Bihar Sharif",
            "Bihariganj",
            "Bikramganj",
            "Birpur",
            "Bodh Gaya",
            "Buxar",
            "Chakia",
            "Chanpatia",
            "Chhapra",
            "Chhatapur",
            "Colgong",
            "Dalsingh Sarai",
            "Darbhanga",
            "Daudnagar",
            "Dehri",
            "Dhaka",
            "Dighwara",
            "Dinapur",
            "Dinapur Cantonment",
            "Dumra",
            "Dumraon",
            "Fatwa",
            "Forbesganj",
            "Gaya",
            "Gazipur",
            "Ghoghardiha",
            "Gogri Jamalpur",
            "Gopalganj",
            "Habibpur",
            "Hajipur",
            "Hasanpur",
            "Hazaribagh",
            "Hilsa",
            "Hisua",
            "Islampur",
            "Jagdispur",
            "Jahanabad",
            "Jamalpur",
            "Jamhaur",
            "Jamui",
            "Janakpur Road",
            "Janpur",
            "Jaynagar",
            "Jha Jha",
            "Jhanjharpur",
            "Jogbani",
            "Kanti",
            "Kasba",
            "Kataiya",
            "Katihar",
            "Khagaria",
            "Khagaul",
            "Kharagpur",
            "Khusrupur",
            "Kishanganj",
            "Koath",
            "Koilwar",
            "Lakhisarai",
            "Lalganj",
            "Lauthaha",
            "Madhepura",
            "Madhubani",
            "Maharajganj",
            "Mahnar Bazar",
            "Mairwa",
            "Makhdumpur",
            "Maner",
            "Manihari",
            "Marhaura",
            "Masaurhi",
            "Mirganj",
            "Mohiuddinagar",
            "Mokama",
            "Motihari",
            "Motipur",
            "Munger",
            "Murliganj",
            "Muzaffarpur",
            "Nabinagar",
            "Narkatiaganj",
            "Nasriganj",
            "Natwar",
            "Naugachhia",
            "Nawada",
            "Nirmali",
            "Nokha",
            "Paharpur",
            "Patna",
            "Phulwari",
            "Piro",
            "Purnia",
            "Pusa",
            "Rafiganj",
            "Raghunathpur",
            "Rajgir",
            "Ramnagar",
            "Raxaul",
            "Revelganj",
            "Rusera",
            "Sagauli",
            "Saharsa",
            "Samastipur",
            "Sasaram",
            "Shahpur",
            "Shaikhpura",
            "Sherghati",
            "Shivhar",
            "Silao",
            "Sitamarhi",
            "Siwan",
            "Sonepur",
            "Sultanganj",
            "Supaul",
            "Teghra",
            "Tekari",
            "Thakurganj",
            "Vaishali",
            "Waris Aliganj"
          ],
          "StateName": "Bihar"
        },
        {
          "Cities": [
            "Chandigarh"
          ],
          "StateName": "Chandigarh"
        },
        {
          "Cities": [
            "Ahiwara",
            "Akaltara",
            "Ambagarh Chauki",
            "Ambikapur",
            "Arang",
            "Bade Bacheli",
            "Bagbahara",
            "Baikunthpur",
            "Balod",
            "Baloda",
            "Baloda Bazar",
            "Banarsi",
            "Basna",
            "Bemetra",
            "Bhanpuri",
            "Bhatapara",
            "Bhatgaon",
            "Bhilai",
            "Bilaspur",
            "Bilha",
            "Birgaon",
            "Bodri",
            "Champa",
            "Charcha",
            "Charoda",
            "Chhuikhadan",
            "Chirmiri",
            "Dantewada",
            "Deori",
            "Dhamdha",
            "Dhamtari",
            "Dharamjaigarh",
            "Dipka",
            "Doman Hill Colliery",
            "Dongargaon",
            "Dongragarh",
            "Durg",
            "Frezarpur",
            "Gandai",
            "Gariaband",
            "Gaurela",
            "Gelhapani",
            "Gharghoda",
            "Gidam",
            "Gobra Nawapara",
            "Gogaon",
            "Hatkachora",
            "Jagdalpur",
            "Jamui",
            "Jashpurnagar",
            "Jhagrakhand",
            "Kanker",
            "Katghora",
            "Kawardha",
            "Khairagarh",
            "Khamhria",
            "Kharod",
            "Kharsia",
            "Khonga Pani",
            "Kirandu",
            "Kirandul",
            "Kohka",
            "Kondagaon",
            "Korba",
            "Korea",
            "Koria Block",
            "Kota",
            "Kumhari",
            "Kumud Katta",
            "Kurasia",
            "Kurud",
            "Lingiyadih",
            "Lormi",
            "Mahasamund",
            "Mahendragarh",
            "Mehmand",
            "Mongra",
            "Mowa",
            "Mungeli",
            "Nailajanjgir",
            "Namna Kalan",
            "Naya Baradwar",
            "Pandariya",
            "Patan",
            "Pathalgaon",
            "Pendra",
            "Phunderdihari",
            "Pithora",
            "Raigarh",
            "Raipur",
            "Rajgamar",
            "Rajhara",
            "Rajnandgaon",
            "Ramanuj Ganj",
            "Ratanpur",
            "Sakti",
            "Saraipali",
            "Sarajpur",
            "Sarangarh",
            "Shivrinarayan",
            "Simga",
            "Sirgiti",
            "Takhatpur",
            "Telgaon",
            "Tildanewra",
            "Urla",
            "Vishrampur"
          ],
          "StateName": "Chhattisgarh"
        },
        {
          "Cities": [
            "Amli",
            "Silvassa"
          ],
          "StateName": "Dadra and Nagar Haveli"
        },
        {
          "Cities": [
            "Daman",
            "Diu"
          ],
          "StateName": "Daman and Diu"
        },
        {
          "Cities": [
            "Delhi",
            "New Delhi"
          ],
          "StateName": "Delhi"
        },
        {
          "Cities": [
            "Aldona",
            "Altinho",
            "Aquem",
            "Arpora",
            "Bambolim",
            "Bandora",
            "Bardez",
            "Benaulim",
            "Betora",
            "Bicholim",
            "Calapor",
            "Candolim",
            "Caranzalem",
            "Carapur",
            "Chicalim",
            "Chimbel",
            "Chinchinim",
            "Colvale",
            "Corlim",
            "Cortalim",
            "Cuncolim",
            "Curchorem",
            "Curti",
            "Davorlim",
            "Dona Paula",
            "Goa",
            "Guirim",
            "Jua",
            "Kalangat",
            "Kankon",
            "Kundaim",
            "Loutulim",
            "Madgaon",
            "Mapusa",
            "Margao",
            "Margaon",
            "Miramar",
            "Morjim",
            "Mormugao",
            "Navelim",
            "Pale",
            "Panaji",
            "Parcem",
            "Parra",
            "Penha de Franca",
            "Pernem",
            "Pilerne",
            "Pissurlem",
            "Ponda",
            "Porvorim",
            "Quepem",
            "Queula",
            "Raia",
            "Reis Magos",
            "Salcette",
            "Saligao",
            "Sancoale",
            "Sanguem",
            "Sanquelim",
            "Sanvordem",
            "Sao Jose-de-Areal",
            "Sattari",
            "Serula",
            "Sinquerim",
            "Siolim",
            "Taleigao",
            "Tivim",
            "Valpoi",
            "Varca",
            "Vasco",
            "Verna"
          ],
          "StateName": "Goa"
        },
        {
          "Cities": [
            "Abrama",
            "Adalaj",
            "Adityana",
            "Advana",
            "Ahmedabad",
            "Ahwa",
            "Alang",
            "Ambaji",
            "Ambaliyasan",
            "Amod",
            "Amreli",
            "Amroli",
            "Anand",
            "Andada",
            "Anjar",
            "Anklav",
            "Ankleshwar",
            "Anklesvar INA",
            "Antaliya",
            "Arambhada",
            "Asarma",
            "Atul",
            "Babra",
            "Bag-e-Firdosh",
            "Bagasara",
            "Bahadarpar",
            "Bajipura",
            "Bajva",
            "Balasinor",
            "Banaskantha",
            "Bansda",
            "Bantva",
            "Bardoli",
            "Barwala",
            "Bayad",
            "Bechar",
            "Bedi",
            "Beyt",
            "Bhachau",
            "Bhanvad",
            "Bharuch",
            "Bharuch INA",
            "Bhavnagar",
            "Bhayavadar",
            "Bhestan",
            "Bhuj",
            "Bilimora",
            "Bilkha",
            "Billimora",
            "Bodakdev",
            "Bodeli",
            "Bopal",
            "Boria",
            "Boriavi",
            "Borsad",
            "Botad",
            "Cambay",
            "Chaklasi",
            "Chala",
            "Chalala",
            "Chalthan",
            "Chanasma",
            "Chandisar",
            "Chandkheda",
            "Chanod",
            "Chaya",
            "Chenpur",
            "Chhapi",
            "Chhaprabhatha",
            "Chhatral",
            "Chhota Udepur",
            "Chikhli",
            "Chiloda",
            "Chorvad",
            "Chotila",
            "Dabhoi",
            "Dadara",
            "Dahod",
            "Dakor",
            "Damnagar",
            "Deesa",
            "Delvada",
            "Devgadh Baria",
            "Devsar",
            "Dhandhuka",
            "Dhanera",
            "Dhangdhra",
            "Dhansura",
            "Dharampur",
            "Dhari",
            "Dhola",
            "Dholka",
            "Dholka Rural",
            "Dhoraji",
            "Dhrangadhra",
            "Dhrol",
            "Dhuva",
            "Dhuwaran",
            "Digvijaygram",
            "Disa",
            "Dungar",
            "Dungarpur",
            "Dungra",
            "Dwarka",
            "Flelanganj",
            "GSFC Complex",
            "Gadhda",
            "Gandevi",
            "Gandhidham",
            "Gandhinagar",
            "Gariadhar",
            "Ghogha",
            "Godhra",
            "Gondal",
            "Hajira INA",
            "Halol",
            "Halvad",
            "Hansot",
            "Harij",
            "Himatnagar",
            "Ichchhapor",
            "Idar",
            "Jafrabad",
            "Jalalpore",
            "Jambusar",
            "Jamjodhpur",
            "Jamnagar",
            "Jasdan",
            "Jawaharnagar",
            "Jetalsar",
            "Jetpur",
            "Jodiya",
            "Joshipura",
            "Junagadh",
            "Kadi",
            "Kadodara",
            "Kalavad",
            "Kali",
            "Kaliawadi",
            "Kalol",
            "Kalol INA",
            "Kandla",
            "Kanjari",
            "Kanodar",
            "Kapadwanj",
            "Karachiya",
            "Karamsad",
            "Karjan",
            "Kathial",
            "Kathor",
            "Katpar",
            "Kavant",
            "Keshod",
            "Kevadiya",
            "Khambhaliya",
            "Khambhat",
            "Kharaghoda",
            "Khed Brahma",
            "Kheda",
            "Kheralu",
            "Kodinar",
            "Kosamba",
            "Kundla",
            "Kutch",
            "Kutiyana",
            "Lakhtar",
            "Lalpur",
            "Lambha",
            "Lathi",
            "Limbdi",
            "Limla",
            "Lunavada",
            "Madhapar",
            "Maflipur",
            "Mahemdavad",
            "Mahudha",
            "Mahuva",
            "Mahuvar",
            "Makarba",
            "Makarpura",
            "Makassar",
            "Maktampur",
            "Malia",
            "Malpur",
            "Manavadar",
            "Mandal",
            "Mandvi",
            "Mangrol",
            "Mansa",
            "Meghraj",
            "Mehsana",
            "Mendarla",
            "Mithapur",
            "Modasa",
            "Mogravadi",
            "Morbi",
            "Morvi",
            "Mundra",
            "Nadiad",
            "Naliya",
            "Nanakvada",
            "Nandej",
            "Nandesari",
            "Nandesari INA",
            "Naroda",
            "Navagadh",
            "Navagam Ghed",
            "Navsari",
            "Ode",
            "Okaf",
            "Okha",
            "Olpad",
            "Paddhari",
            "Padra",
            "Palanpur",
            "Palej",
            "Pali",
            "Palitana",
            "Paliyad",
            "Pandesara",
            "Panoli",
            "Pardi",
            "Parnera",
            "Parvat",
            "Patan",
            "Patdi",
            "Petlad",
            "Petrochemical Complex",
            "Porbandar",
            "Prantij",
            "Radhanpur",
            "Raiya",
            "Rajkot",
            "Rajpipla",
            "Rajula",
            "Ramod",
            "Ranavav",
            "Ranoli",
            "Rapar",
            "Sahij",
            "Salaya",
            "Sanand",
            "Sankheda",
            "Santrampur",
            "Saribujrang",
            "Sarigam INA",
            "Sayan",
            "Sayla",
            "Shahpur",
            "Shahwadi",
            "Shapar",
            "Shivrajpur",
            "Siddhapur",
            "Sidhpur",
            "Sihor",
            "Sika",
            "Singarva",
            "Sinor",
            "Sojitra",
            "Sola",
            "Songadh",
            "Suraj Karadi",
            "Surat",
            "Surendranagar",
            "Talaja",
            "Talala",
            "Talod",
            "Tankara",
            "Tarsali",
            "Thangadh",
            "Tharad",
            "Thasra",
            "Udyognagar",
            "Ukai",
            "Umbergaon",
            "Umbergaon INA",
            "Umrala",
            "Umreth",
            "Un",
            "Una",
            "Unjha",
            "Upleta",
            "Utran",
            "Uttarsanda",
            "V.U. Nagar",
            "V.V. Nagar",
            "Vadia",
            "Vadla",
            "Vadnagar",
            "Vadodara",
            "Vaghodia INA",
            "Valbhipur",
            "Vallabh Vidyanagar",
            "Valsad",
            "Valsad INA",
            "Vanthali",
            "Vapi",
            "Vapi INA",
            "Vartej",
            "Vasad",
            "Vasna Borsad INA",
            "Vaso",
            "Veraval",
            "Vidyanagar",
            "Vijalpor",
            "Vijapur",
            "Vinchhiya",
            "Vinzol",
            "Virpur",
            "Visavadar",
            "Visnagar",
            "Vyara",
            "Wadhwan",
            "Waghai",
            "Waghodia",
            "Wankaner",
            "Zalod"
          ],
          "StateName": "Gujarat"
        },
        {
          "Cities": [
            "Ambala",
            "Ambala Cantt",
            "Asan Khurd",
            "Asandh",
            "Ateli",
            "Babiyal",
            "Bahadurgarh",
            "Ballabgarh",
            "Barwala",
            "Bawal",
            "Bawani Khera",
            "Beri",
            "Bhiwani",
            "Bilaspur",
            "Buria",
            "Charkhi Dadri",
            "Chhachhrauli",
            "Chita",
            "Dabwali",
            "Dharuhera",
            "Dundahera",
            "Ellenabad",
            "Farakhpur",
            "Faridabad",
            "Farrukhnagar",
            "Fatehabad",
            "Firozpur Jhirka",
            "Gannaur",
            "Ghraunda",
            "Gohana",
            "Gurgaon",
            "Haileymandi",
            "Hansi",
            "Hasanpur",
            "Hathin",
            "Hisar",
            "Hissar",
            "Hodal",
            "Indri",
            "Jagadhri",
            "Jakhal Mandi",
            "Jhajjar",
            "Jind",
            "Julana",
            "Kaithal",
            "Kalanur",
            "Kalanwali",
            "Kalayat",
            "Kalka",
            "Kanina",
            "Kansepur",
            "Kardhan",
            "Karnal",
            "Kharkhoda",
            "Kheri Sampla",
            "Kundli",
            "Kurukshetra",
            "Ladrawan",
            "Ladwa",
            "Loharu",
            "Maham",
            "Mahendragarh",
            "Mustafabad",
            "Nagai Chaudhry",
            "Narayangarh",
            "Narnaul",
            "Narnaund",
            "Narwana",
            "Nilokheri",
            "Nuh",
            "Palwal",
            "Panchkula",
            "Panipat",
            "Panipat Taraf Ansar",
            "Panipat Taraf Makhdum Zadgan",
            "Panipat Taraf Rajputan",
            "Pehowa",
            "Pinjaur",
            "Punahana",
            "Pundri",
            "Radaur",
            "Raipur Rani",
            "Rania",
            "Ratiya",
            "Rewari",
            "Rohtak",
            "Ropar",
            "Sadauri",
            "Safidon",
            "Samalkha",
            "Sankhol",
            "Sasauli",
            "Shahabad",
            "Sirsa",
            "Siwani",
            "Sohna",
            "Sonipat",
            "Sukhrali",
            "Taoru",
            "Taraori",
            "Tauru",
            "Thanesar",
            "Tilpat",
            "Tohana",
            "Tosham",
            "Uchana",
            "Uklana Mandi",
            "Uncha Siwana",
            "Yamunanagar"
          ],
          "StateName": "Haryana"
        },
        {
          "Cities": [
            "Arki",
            "Baddi",
            "Bakloh",
            "Banjar",
            "Bhota",
            "Bhuntar",
            "Bilaspur",
            "Chamba",
            "Chaupal",
            "Chuari Khas",
            "Dagshai",
            "Dalhousie",
            "Dalhousie Cantonment",
            "Damtal",
            "Daulatpur",
            "Dera Gopipur",
            "Dhalli",
            "Dharamshala",
            "Gagret",
            "Ghamarwin",
            "Hamirpur",
            "Jawala Mukhi",
            "Jogindarnagar",
            "Jubbal",
            "Jutogh",
            "Kala Amb",
            "Kalpa",
            "Kangra",
            "Kasauli",
            "Kot Khai",
            "Kullu",
            "Kulu",
            "Manali",
            "Mandi",
            "Mant Khas",
            "Mehatpur Basdehra",
            "Nadaun",
            "Nagrota",
            "Nahan",
            "Naina Devi",
            "Nalagarh",
            "Narkanda",
            "Nurpur",
            "Palampur",
            "Pandoh",
            "Paonta Sahib",
            "Parwanoo",
            "Parwanu",
            "Rajgarh",
            "Rampur",
            "Rawalsar",
            "Rohru",
            "Sabathu",
            "Santokhgarh",
            "Sarahan",
            "Sarka Ghat",
            "Seoni",
            "Shimla",
            "Sirmaur",
            "Solan",
            "Solon",
            "Sundarnagar",
            "Sundernagar",
            "Talai",
            "Theog",
            "Tira Sujanpur",
            "Una",
            "Yol"
          ],
          "StateName": "Himachal Pradesh"
        },
        {
          "Cities": [
            "Achabal",
            "Akhnur",
            "Anantnag",
            "Arnia",
            "Awantipora",
            "Badami Bagh",
            "Bandipur",
            "Banihal",
            "Baramula",
            "Baramulla",
            "Bari Brahmana",
            "Bashohli",
            "Batote",
            "Bhaderwah",
            "Bijbiara",
            "Billawar",
            "Birwah",
            "Bishna",
            "Budgam",
            "Charari Sharief",
            "Chenani",
            "Doda",
            "Duru-Verinag",
            "Gandarbat",
            "Gho Manhasan",
            "Gorah Salathian",
            "Gulmarg",
            "Hajan",
            "Handwara",
            "Hiranagar",
            "Jammu",
            "Jammu Cantonment",
            "Jammu Tawi",
            "Jourian",
            "Kargil",
            "Kathua",
            "Katra",
            "Khan Sahib",
            "Khour",
            "Khrew",
            "Kishtwar",
            "Kud",
            "Kukernag",
            "Kulgam",
            "Kunzer",
            "Kupwara",
            "Lakhenpur",
            "Leh",
            "Magam",
            "Mattan",
            "Naushehra",
            "Pahalgam",
            "Pampore",
            "Parole",
            "Pattan",
            "Pulwama",
            "Punch",
            "Qazigund",
            "Rajauri",
            "Ramban",
            "Ramgarh",
            "Ramnagar",
            "Ranbirsingh Pora",
            "Reasi",
            "Rehambal",
            "Samba",
            "Shupiyan",
            "Sopur",
            "Srinagar",
            "Sumbal",
            "Sunderbani",
            "Talwara",
            "Thanamandi",
            "Tral",
            "Udhampur",
            "Uri",
            "Vijaypur"
          ],
          "StateName": "Jammu and Kashmir"
        },
        {
          "Cities": [
            "Adityapur",
            "Amlabad",
            "Angarpathar",
            "Ara",
            "Babua Kalan",
            "Bagbahra",
            "Baliapur",
            "Baliari",
            "Balkundra",
            "Bandhgora",
            "Barajamda",
            "Barhi",
            "Barka Kana",
            "Barki Saraiya",
            "Barughutu",
            "Barwadih",
            "Basaria",
            "Basukinath",
            "Bermo",
            "Bhagatdih",
            "Bhaurah",
            "Bhojudih",
            "Bhuli",
            "Bokaro",
            "Borio Bazar",
            "Bundu",
            "Chaibasa",
            "Chaitudih",
            "Chakradharpur",
            "Chakulia",
            "Chandaur",
            "Chandil",
            "Chandrapura",
            "Chas",
            "Chatra",
            "Chhatatanr",
            "Chhotaputki",
            "Chiria",
            "Chirkunda",
            "Churi",
            "Daltenganj",
            "Danguwapasi",
            "Dari",
            "Deoghar",
            "Deorikalan",
            "Devghar",
            "Dhanbad",
            "Dhanwar",
            "Dhaunsar",
            "Dugda",
            "Dumarkunda",
            "Dumka",
            "Egarkunr",
            "Gadhra",
            "Garwa",
            "Ghatsila",
            "Ghorabandha",
            "Gidi",
            "Giridih",
            "Gobindpur",
            "Godda",
            "Godhar",
            "Golphalbari",
            "Gomoh",
            "Gua",
            "Gumia",
            "Gumla",
            "Haludbani",
            "Hazaribag",
            "Hesla",
            "Husainabad",
            "Isri",
            "Jadugora",
            "Jagannathpur",
            "Jamadoba",
            "Jamshedpur",
            "Jamtara",
            "Jarangdih",
            "Jaridih",
            "Jasidih",
            "Jena",
            "Jharia",
            "Jharia Khas",
            "Jhinkpani",
            "Jhumri Tilaiya",
            "Jorapokhar",
            "Jugsalai",
            "Kailudih",
            "Kalikapur",
            "Kandra",
            "Kanke",
            "Katras",
            "Kedla",
            "Kenduadih",
            "Kharkhari",
            "Kharsawan",
            "Khelari",
            "Khunti",
            "Kiri Buru",
            "Kiriburu",
            "Kodarma",
            "Kuju",
            "Kurpania",
            "Kustai",
            "Lakarka",
            "Lapanga",
            "Latehar",
            "Lohardaga",
            "Loiya",
            "Loyabad",
            "Madhupur",
            "Mahesh Mundi",
            "Maithon",
            "Malkera",
            "Mango",
            "Manoharpur",
            "Marma",
            "Meghahatuburu Forest village",
            "Mera",
            "Meru",
            "Mihijam",
            "Mugma",
            "Muri",
            "Mushabani",
            "Nagri Kalan",
            "Netarhat",
            "Nirsa",
            "Noamundi",
            "Okni",
            "Orla",
            "Pakaur",
            "Palamau",
            "Palawa",
            "Panchet",
            "Panrra",
            "Paratdih",
            "Pathardih",
            "Patratu",
            "Phusro",
            "Pondar Kanali",
            "Rajmahal",
            "Ramgarh",
            "Ranchi",
            "Ray",
            "Rehla",
            "Religara",
            "Rohraband",
            "Sahibganj",
            "Sahnidih",
            "Saraidhela",
            "Saraikela",
            "Sarjamda",
            "Saunda",
            "Sewai",
            "Sijhua",
            "Sijua",
            "Simdega",
            "Sindari",
            "Sinduria",
            "Sini",
            "Sirka",
            "Siuliban",
            "Surubera",
            "Tati",
            "Tenudam",
            "Tisra",
            "Topa",
            "Topchanchi"
          ],
          "StateName": "Jharkhand"
        },
        {
          "Cities": [
            "Adityanagar",
            "Adityapatna",
            "Afzalpur",
            "Ajjampur",
            "Aland",
            "Almatti Sitimani",
            "Alnavar",
            "Alur",
            "Ambikanagara",
            "Anekal",
            "Ankola",
            "Annigeri",
            "Arkalgud",
            "Arsikere",
            "Athni",
            "Aurad",
            "Badagavettu",
            "Badami",
            "Bagalkot",
            "Bagepalli",
            "Bailhongal",
            "Baindur",
            "Bajala",
            "Bajpe",
            "Banavar",
            "Bangarapet",
            "Bankapura",
            "Bannur",
            "Bantwal",
            "Basavakalyan",
            "Basavana Bagevadi",
            "Belagula",
            "Belakavadiq",
            "Belgaum",
            "Belgaum Cantonment",
            "Bellary",
            "Belluru",
            "Beltangadi",
            "Belur",
            "Belvata",
            "Bengaluru",
            "Bhadravati",
            "Bhalki",
            "Bhatkal",
            "Bhimarayanagudi",
            "Bhogadi",
            "Bidar",
            "Bijapur",
            "Bilgi",
            "Birur",
            "Bommanahalli",
            "Bommasandra",
            "Byadgi",
            "Byatarayanapura",
            "Chakranagar Colony",
            "Challakere",
            "Chamrajnagar",
            "Chamundi Betta",
            "Channagiri",
            "Channapatna",
            "Channarayapatna",
            "Chickballapur",
            "Chik Ballapur",
            "Chikkaballapur",
            "Chikmagalur",
            "Chiknayakanhalli",
            "Chikodi",
            "Chincholi",
            "Chintamani",
            "Chitaguppa",
            "Chitapur",
            "Chitradurga",
            "Coorg",
            "Dandeli",
            "Dargajogihalli",
            "Dasarahalli",
            "Davangere",
            "Devadurga",
            "Devagiri",
            "Devanhalli",
            "Dharwar",
            "Dhupdal",
            "Dod Ballapur",
            "Donimalai",
            "Gadag",
            "Gajendragarh",
            "Ganeshgudi",
            "Gangawati",
            "Gangoli",
            "Gauribidanur",
            "Gokak",
            "Gokak Falls",
            "Gonikoppal",
            "Gorur",
            "Gottikere",
            "Gubbi",
            "Gudibanda",
            "Gulbarga",
            "Guledgudda",
            "Gundlupet",
            "Gurmatkal",
            "Haliyal",
            "Hangal",
            "Harihar",
            "Harpanahalli",
            "Hassan",
            "Hatti",
            "Hatti Gold Mines",
            "Haveri",
            "Hebbagodi",
            "Hebbalu",
            "Hebri",
            "Heggadadevanakote",
            "Herohalli",
            "Hidkal",
            "Hindalgi",
            "Hirekerur",
            "Hiriyur",
            "Holalkere",
            "Hole Narsipur",
            "Homnabad",
            "Honavar",
            "Honnali",
            "Hosakote",
            "Hosanagara",
            "Hosangadi",
            "Hosdurga",
            "Hoskote",
            "Hospet",
            "Hubli",
            "Hukeri",
            "Hunasagi",
            "Hunasamaranahalli",
            "Hungund",
            "Hunsur",
            "Huvina Hadagalli",
            "Ilkal",
            "Indi",
            "Jagalur",
            "Jamkhandi",
            "Jevargi",
            "Jog Falls",
            "Kabini Colony",
            "Kadur",
            "Kalghatgi",
            "Kamalapuram",
            "Kampli",
            "Kanakapura",
            "Kangrali BK",
            "Kangrali KH",
            "Kannur",
            "Karkala",
            "Karwar",
            "Kemminja",
            "Kengeri",
            "Kerur",
            "Khanapur",
            "Kodigenahalli",
            "Kodiyal",
            "Kodlipet",
            "Kolar",
            "Kollegal",
            "Konanakunte",
            "Konanur",
            "Konnur",
            "Koppa",
            "Koppal",
            "Koratagere",
            "Kotekara",
            "Kothnur",
            "Kotturu",
            "Krishnapura",
            "Krishnarajanagar",
            "Krishnarajapura",
            "Krishnarajasagara",
            "Krishnarajpet",
            "Kudchi",
            "Kudligi",
            "Kudremukh",
            "Kumsi",
            "Kumta",
            "Kundapura",
            "Kundgol",
            "Kunigal",
            "Kurgunta",
            "Kushalnagar",
            "Kushtagi",
            "Kyathanahalli",
            "Lakshmeshwar",
            "Lingsugur",
            "Londa",
            "Maddur",
            "Madhugiri",
            "Madikeri",
            "Magadi",
            "Magod Falls",
            "Mahadeswara Hills",
            "Mahadevapura",
            "Mahalingpur",
            "Maisuru",
            "Maisuru Cantonment",
            "Malavalli",
            "Mallar",
            "Malpe",
            "Malur",
            "Manchenahalli",
            "Mandya",
            "Mangalore",
            "Mangaluru",
            "Manipal",
            "Manvi",
            "Maski",
            "Mastikatte Colony",
            "Mayakonda",
            "Melukote",
            "Molakalmuru",
            "Mudalgi",
            "Mudbidri",
            "Muddebihal",
            "Mudgal",
            "Mudhol",
            "Mudigere",
            "Mudushedde",
            "Mulbagal",
            "Mulgund",
            "Mulki",
            "Mulur",
            "Mundargi",
            "Mundgod",
            "Munirabad",
            "Munnur",
            "Murudeshwara",
            "Mysore",
            "Nagamangala",
            "Nanjangud",
            "Naragund",
            "Narasimharajapura",
            "Naravi",
            "Narayanpur",
            "Naregal",
            "Navalgund",
            "Nelmangala",
            "Nipani",
            "Nitte",
            "Nyamati",
            "Padu",
            "Pandavapura",
            "Pattanagere",
            "Pavagada",
            "Piriyapatna",
            "Ponnampet",
            "Puttur",
            "Rabkavi",
            "Raichur",
            "Ramanagaram",
            "Ramdurg",
            "Ranibennur",
            "Raybag",
            "Robertsonpet",
            "Ron",
            "Sadalgi",
            "Sagar",
            "Sakleshpur",
            "Saligram",
            "Sandur",
            "Sanivarsante",
            "Sankeshwar",
            "Sargur",
            "Sathyamangala",
            "Saundatti Yellamma",
            "Savanur",
            "Sedam",
            "Shahabad",
            "Shahabad A.C.C.",
            "Shahapur",
            "Shahpur",
            "Shaktinagar",
            "Shiggaon",
            "Shikarpur",
            "Shimoga",
            "Shirhatti",
            "Shorapur",
            "Shravanabelagola",
            "Shrirangapattana",
            "Siddapur",
            "Sidlaghatta",
            "Sindgi",
            "Sindhnur",
            "Sira",
            "Sirakoppa",
            "Sirsi",
            "Siruguppa",
            "Someshwar",
            "Somvarpet",
            "Sorab",
            "Sringeri",
            "Srinivaspur",
            "Sulya",
            "Suntikopa",
            "Talikota",
            "Tarikera",
            "Tekkalakota",
            "Terdal",
            "Thokur",
            "Thumbe",
            "Tiptur",
            "Tirthahalli",
            "Tirumakudal Narsipur",
            "Tonse",
            "Tumkur",
            "Turuvekere",
            "Udupi",
            "Ullal",
            "Uttarahalli",
            "Venkatapura",
            "Vijayapura",
            "Virarajendrapet",
            "Wadi",
            "Wadi A.C.C.",
            "Yadgir",
            "Yelahanka",
            "Yelandur",
            "Yelbarga",
            "Yellapur",
            "Yenagudde"
          ],
          "StateName": "Karnataka"
        },
        {
          "Cities": [],
          "StateName": "Kenmore"
        },
        {
          "Cities": [
            "Adimaly",
            "Adoor",
            "Adur",
            "Akathiyur",
            "Alangad",
            "Alappuzha",
            "Aluva",
            "Ancharakandy",
            "Angamaly",
            "Aroor",
            "Arukutti",
            "Attingal",
            "Avinissery",
            "Azhikode North",
            "Azhikode South",
            "Azhiyur",
            "Balussery",
            "Bangramanjeshwar",
            "Beypur",
            "Brahmakulam",
            "Chala",
            "Chalakudi",
            "Changanacheri",
            "Chauwara",
            "Chavakkad",
            "Chelakkara",
            "Chelora",
            "Chendamangalam",
            "Chengamanad",
            "Chengannur",
            "Cheranallur",
            "Cheriyakadavu",
            "Cherthala",
            "Cherukunnu",
            "Cheruthazham",
            "Cheruvannur",
            "Cheruvattur",
            "Chevvur",
            "Chirakkal",
            "Chittur",
            "Chockli",
            "Churnikkara",
            "Dharmadam",
            "Edappal",
            "Edathala",
            "Elayavur",
            "Elur",
            "Eranholi",
            "Erattupetta",
            "Ernakulam",
            "Eruvatti",
            "Ettumanoor",
            "Feroke",
            "Guruvayur",
            "Haripad",
            "Hosabettu",
            "Idukki",
            "Iringaprom",
            "Irinjalakuda",
            "Iriveri",
            "Kadachira",
            "Kadalundi",
            "Kadamakkudy",
            "Kadirur",
            "Kadungallur",
            "Kakkodi",
            "Kalady",
            "Kalamassery",
            "Kalliasseri",
            "Kalpetta",
            "Kanhangad",
            "Kanhirode",
            "Kanjikkuzhi",
            "Kanjikode",
            "Kanjirappalli",
            "Kannadiparamba",
            "Kannangad",
            "Kannapuram",
            "Kannur",
            "Kannur Cantonment",
            "Karunagappally",
            "Karuvamyhuruthy",
            "Kasaragod",
            "Kasargod",
            "Kattappana",
            "Kayamkulam",
            "Kedamangalam",
            "Kochi",
            "Kodamthuruthu",
            "Kodungallur",
            "Koduvally",
            "Koduvayur",
            "Kokkothamangalam",
            "Kolazhy",
            "Kollam",
            "Komalapuram",
            "Koothattukulam",
            "Koratty",
            "Kothamangalam",
            "Kottarakkara",
            "Kottayam",
            "Kottayam Malabar",
            "Kottuvally",
            "Koyilandi",
            "Kozhikode",
            "Kudappanakunnu",
            "Kudlu",
            "Kumarakom",
            "Kumily",
            "Kunnamangalam",
            "Kunnamkulam",
            "Kurikkad",
            "Kurkkanchery",
            "Kuthuparamba",
            "Kuttakulam",
            "Kuttikkattur",
            "Kuttur",
            "Malappuram",
            "Mallappally",
            "Manjeri",
            "Manjeshwar",
            "Mannancherry",
            "Mannar",
            "Mannarakkat",
            "Maradu",
            "Marathakkara",
            "Marutharod",
            "Mattannur",
            "Mavelikara",
            "Mavilayi",
            "Mavur",
            "Methala",
            "Muhamma",
            "Mulavukad",
            "Mundakayam",
            "Munderi",
            "Munnar",
            "Muthakunnam",
            "Muvattupuzha",
            "Muzhappilangad",
            "Nadapuram",
            "Nadathara",
            "Narath",
            "Nattakam",
            "Nedumangad",
            "Nenmenikkara",
            "New Mahe",
            "Neyyattinkara",
            "Nileshwar",
            "Olavanna",
            "Ottapalam",
            "Ottappalam",
            "Paduvilayi",
            "Palai",
            "Palakkad",
            "Palayad",
            "Palissery",
            "Pallikkunnu",
            "Paluvai",
            "Panniyannur",
            "Pantalam",
            "Panthiramkavu",
            "Panur",
            "Pappinisseri",
            "Parassala",
            "Paravur",
            "Pathanamthitta",
            "Pathanapuram",
            "Pathiriyad",
            "Pattambi",
            "Pattiom",
            "Pavaratty",
            "Payyannur",
            "Peermade",
            "Perakam",
            "Peralasseri",
            "Peringathur",
            "Perinthalmanna",
            "Perole",
            "Perumanna",
            "Perumbaikadu",
            "Perumbavoor",
            "Pinarayi",
            "Piravam",
            "Ponnani",
            "Pottore",
            "Pudukad",
            "Punalur",
            "Puranattukara",
            "Puthunagaram",
            "Puthuppariyaram",
            "Puzhathi",
            "Ramanattukara",
            "Shoranur",
            "Sultans Battery",
            "Sulthan Bathery",
            "Talipparamba",
            "Thaikkad",
            "Thalassery",
            "Thannirmukkam",
            "Theyyalingal",
            "Thiruvalla",
            "Thiruvananthapuram",
            "Thiruvankulam",
            "Thodupuzha",
            "Thottada",
            "Thrippunithura",
            "Thrissur",
            "Tirur",
            "Udma",
            "Vadakara",
            "Vaikam",
            "Valapattam",
            "Vallachira",
            "Varam",
            "Varappuzha",
            "Varkala",
            "Vayalar",
            "Vazhakkala",
            "Venmanad",
            "Villiappally",
            "Wayanad"
          ],
          "StateName": "Kerala"
        },
        {
          "Cities": [
            "Agethi",
            "Amini",
            "Androth Island",
            "Kavaratti",
            "Minicoy"
          ],
          "StateName": "Lakshadweep"
        },
        {
          "Cities": [
            "Agar",
            "Ajaigarh",
            "Akoda",
            "Akodia",
            "Alampur",
            "Alirajpur",
            "Alot",
            "Amanganj",
            "Amarkantak",
            "Amarpatan",
            "Amarwara",
            "Ambada",
            "Ambah",
            "Amla",
            "Amlai",
            "Anjad",
            "Antri",
            "Anuppur",
            "Aron",
            "Ashoknagar",
            "Ashta",
            "Babai",
            "Bada Malhera",
            "Badagaon",
            "Badagoan",
            "Badarwas",
            "Badawada",
            "Badi",
            "Badkuhi",
            "Badnagar",
            "Badnawar",
            "Badod",
            "Badoda",
            "Badra",
            "Bagh",
            "Bagli",
            "Baihar",
            "Baikunthpur",
            "Bakswaha",
            "Balaghat",
            "Baldeogarh",
            "Bamaniya",
            "Bamhani",
            "Bamor",
            "Bamora",
            "Banda",
            "Bangawan",
            "Bansatar Kheda",
            "Baraily",
            "Barela",
            "Barghat",
            "Bargi",
            "Barhi",
            "Barigarh",
            "Barwaha",
            "Barwani",
            "Basoda",
            "Begamganj",
            "Beohari",
            "Berasia",
            "Betma",
            "Betul",
            "Betul Bazar",
            "Bhainsdehi",
            "Bhamodi",
            "Bhander",
            "Bhanpura",
            "Bharveli",
            "Bhaurasa",
            "Bhavra",
            "Bhedaghat",
            "Bhikangaon",
            "Bhilakhedi",
            "Bhind",
            "Bhitarwar",
            "Bhopal",
            "Bhuibandh",
            "Biaora",
            "Bijawar",
            "Bijeypur",
            "Bijrauni",
            "Bijuri",
            "Bilaua",
            "Bilpura",
            "Bina Railway Colony",
            "Bina-Etawa",
            "Birsinghpur",
            "Boda",
            "Budhni",
            "Burhanpur",
            "Burhar",
            "Chachaura Binaganj",
            "Chakghat",
            "Chandameta Butar",
            "Chanderi",
            "Chandia",
            "Chandla",
            "Chaurai Khas",
            "Chhatarpur",
            "Chhindwara",
            "Chhota Chhindwara",
            "Chichli",
            "Chitrakut",
            "Churhat",
            "Daboh",
            "Dabra",
            "Damoh",
            "Damua",
            "Datia",
            "Deodara",
            "Deori",
            "Deori Khas",
            "Depalpur",
            "Devendranagar",
            "Devhara",
            "Dewas",
            "Dhamnod",
            "Dhana",
            "Dhanpuri",
            "Dhar",
            "Dharampuri",
            "Dighawani",
            "Diken",
            "Dindori",
            "Dola",
            "Dumar Kachhar",
            "Dungariya Chhapara",
            "Gadarwara",
            "Gairatganj",
            "Gandhi Sagar Hydel Colony",
            "Ganjbasoda",
            "Garhakota",
            "Garhi Malhara",
            "Garoth",
            "Gautapura",
            "Ghansor",
            "Ghuwara",
            "Gogaon",
            "Gogapur",
            "Gohad",
            "Gormi",
            "Govindgarh",
            "Guna",
            "Gurh",
            "Gwalior",
            "Hanumana",
            "Harda",
            "Harpalpur",
            "Harrai",
            "Harsud",
            "Hatod",
            "Hatpipalya",
            "Hatta",
            "Hindoria",
            "Hirapur",
            "Hoshangabad",
            "Ichhawar",
            "Iklehra",
            "Indergarh",
            "Indore",
            "Isagarh",
            "Itarsi",
            "Jabalpur",
            "Jabalpur Cantonment",
            "Jabalpur G.C.F",
            "Jaisinghnagar",
            "Jaithari",
            "Jaitwara",
            "Jamai",
            "Jaora",
            "Jatachhapar",
            "Jatara",
            "Jawad",
            "Jawar",
            "Jeronkhalsa",
            "Jhabua",
            "Jhundpura",
            "Jiran",
            "Jirapur",
            "Jobat",
            "Joura",
            "Kailaras",
            "Kaimur",
            "Kakarhati",
            "Kalichhapar",
            "Kanad",
            "Kannod",
            "Kantaphod",
            "Kareli",
            "Karera",
            "Kari",
            "Karnawad",
            "Karrapur",
            "Kasrawad",
            "Katangi",
            "Katni",
            "Kelhauri",
            "Khachrod",
            "Khajuraho",
            "Khamaria",
            "Khand",
            "Khandwa",
            "Khaniyadhana",
            "Khargapur",
            "Khargone",
            "Khategaon",
            "Khetia",
            "Khilchipur",
            "Khirkiya",
            "Khujner",
            "Khurai",
            "Kolaras",
            "Kotar",
            "Kothi",
            "Kotma",
            "Kukshi",
            "Kumbhraj",
            "Kurwai",
            "Lahar",
            "Lakhnadon",
            "Lateri",
            "Laundi",
            "Lidhora Khas",
            "Lodhikheda",
            "Loharda",
            "Machalpur",
            "Madhogarh",
            "Maharajpur",
            "Maheshwar",
            "Mahidpur",
            "Maihar",
            "Majholi",
            "Makronia",
            "Maksi",
            "Malaj Khand",
            "Malanpur",
            "Malhargarh",
            "Manasa",
            "Manawar",
            "Mandav",
            "Mandideep",
            "Mandla",
            "Mandleshwar",
            "Mandsaur",
            "Manegaon",
            "Mangawan",
            "Manglaya Sadak",
            "Manpur",
            "Mau",
            "Mauganj",
            "Meghnagar",
            "Mehara Gaon",
            "Mehgaon",
            "Mhaugaon",
            "Mhow",
            "Mihona",
            "Mohgaon",
            "Morar",
            "Morena",
            "Morwa",
            "Multai",
            "Mundi",
            "Mungaoli",
            "Murwara",
            "Nagda",
            "Nagod",
            "Nagri",
            "Naigarhi",
            "Nainpur",
            "Nalkheda",
            "Namli",
            "Narayangarh",
            "Narsimhapur",
            "Narsingarh",
            "Narsinghpur",
            "Narwar",
            "Nasrullaganj",
            "Naudhia",
            "Naugaon",
            "Naurozabad",
            "Neemuch",
            "Nepa Nagar",
            "Neuton Chikhli Kalan",
            "Nimach",
            "Niwari",
            "Obedullaganj",
            "Omkareshwar",
            "Orachha",
            "Ordinance Factory Itarsi",
            "Pachmarhi",
            "Pachmarhi Cantonment",
            "Pachore",
            "Palchorai",
            "Palda",
            "Palera",
            "Pali",
            "Panagar",
            "Panara",
            "Pandaria",
            "Pandhana",
            "Pandhurna",
            "Panna",
            "Pansemal",
            "Parasia",
            "Pasan",
            "Patan",
            "Patharia",
            "Pawai",
            "Petlawad",
            "Phuph Kalan",
            "Pichhore",
            "Pipariya",
            "Pipliya Mandi",
            "Piploda",
            "Pithampur",
            "Polay Kalan",
            "Porsa",
            "Prithvipur",
            "Raghogarh",
            "Rahatgarh",
            "Raisen",
            "Rajakhedi",
            "Rajgarh",
            "Rajnagar",
            "Rajpur",
            "Rampur Baghelan",
            "Rampur Naikin",
            "Rampura",
            "Ranapur",
            "Ranipura",
            "Ratangarh",
            "Ratlam",
            "Ratlam Kasba",
            "Rau",
            "Rehli",
            "Rehti",
            "Rewa",
            "Sabalgarh",
            "Sagar",
            "Sagar Cantonment",
            "Sailana",
            "Sanawad",
            "Sanchi",
            "Sanwer",
            "Sarangpur",
            "Sardarpur",
            "Sarni",
            "Satai",
            "Satna",
            "Satwas",
            "Sausar",
            "Sehore",
            "Semaria",
            "Sendhwa",
            "Seondha",
            "Seoni",
            "Seoni Malwa",
            "Sethia",
            "Shahdol",
            "Shahgarh",
            "Shahpur",
            "Shahpura",
            "Shajapur",
            "Shamgarh",
            "Sheopur",
            "Shivpuri",
            "Shujalpur",
            "Sidhi",
            "Sihora",
            "Singolo",
            "Singrauli",
            "Sinhasa",
            "Sirgora",
            "Sirmaur",
            "Sironj",
            "Sitamau",
            "Sohagpur",
            "Sonkatch",
            "Soyatkalan",
            "Suhagi",
            "Sultanpur",
            "Susner",
            "Suthaliya",
            "Tal",
            "Talen",
            "Tarana",
            "Taricharkalan",
            "Tekanpur",
            "Tendukheda",
            "Teonthar",
            "Thandia",
            "Tikamgarh",
            "Timarni",
            "Tirodi",
            "Udaipura",
            "Ujjain",
            "Ukwa",
            "Umaria",
            "Unchahara",
            "Unhel",
            "Vehicle Factory Jabalpur",
            "Vidisha",
            "Vijayraghavgarh",
            "Waraseoni"
          ],
          "StateName": "Madhya Pradesh"
        },
        {
          "Cities": [
            "Achalpur",
            "Aheri",
            "Ahmadnagar Cantonment",
            "Ahmadpur",
            "Ahmednagar",
            "Ajra",
            "Akalkot",
            "Akkalkuwa",
            "Akola",
            "Akot",
            "Alandi",
            "Alibag",
            "Allapalli",
            "Alore",
            "Amalner",
            "Ambad",
            "Ambajogai",
            "Ambernath",
            "Ambivali Tarf Wankhal",
            "Amgaon",
            "Amravati",
            "Anjangaon",
            "Arvi",
            "Ashta",
            "Ashti",
            "Aurangabad",
            "Aurangabad Cantonment",
            "Ausa",
            "Babhulgaon",
            "Badlapur",
            "Balapur",
            "Ballarpur",
            "Baramati",
            "Barshi",
            "Basmat",
            "Beed",
            "Bhadravati",
            "Bhagur",
            "Bhandara",
            "Bhigvan",
            "Bhingar",
            "Bhiwandi",
            "Bhokhardan",
            "Bhor",
            "Bhosari",
            "Bhum",
            "Bhusawal",
            "Bid",
            "Biloli",
            "Birwadi",
            "Boisar",
            "Bop Khel",
            "Brahmapuri",
            "Budhgaon",
            "Buldana",
            "Buldhana",
            "Butibori",
            "Chakan",
            "Chalisgaon",
            "Chandrapur",
            "Chandur",
            "Chandur Bazar",
            "Chandvad",
            "Chicholi",
            "Chikhala",
            "Chikhaldara",
            "Chikhli",
            "Chinchani",
            "Chinchwad",
            "Chiplun",
            "Chopda",
            "Dabhol",
            "Dahance",
            "Dahanu",
            "Daharu",
            "Dapoli Camp",
            "Darwa",
            "Daryapur",
            "Dattapur",
            "Daund",
            "Davlameti",
            "Deglur",
            "Dehu Road",
            "Deolali",
            "Deolali Pravara",
            "Deoli",
            "Desaiganj",
            "Deulgaon Raja",
            "Dewhadi",
            "Dharangaon",
            "Dharmabad",
            "Dharur",
            "Dhatau",
            "Dhule",
            "Digdoh",
            "Diglur",
            "Digras",
            "Dombivli",
            "Dondaicha",
            "Dudhani",
            "Durgapur",
            "Dyane",
            "Edandol",
            "Eklahare",
            "Faizpur",
            "Fekari",
            "Gadchiroli",
            "Gadhinghaj",
            "Gandhi Nagar",
            "Ganeshpur",
            "Gangakher",
            "Gangapur",
            "Gevrai",
            "Ghatanji",
            "Ghoti",
            "Ghugus",
            "Ghulewadi",
            "Godoli",
            "Gondia",
            "Guhagar",
            "Hadgaon",
            "Harnai Beach",
            "Hinganghat",
            "Hingoli",
            "Hupari",
            "Ichalkaranji",
            "Igatpuri",
            "Indapur",
            "Jaisinghpur",
            "Jalgaon",
            "Jalna",
            "Jamkhed",
            "Jawhar",
            "Jaysingpur",
            "Jejuri",
            "Jintur",
            "Junnar",
            "Kabnur",
            "Kagal",
            "Kalamb",
            "Kalamnuri",
            "Kalas",
            "Kalmeshwar",
            "Kalundre",
            "Kalyan",
            "Kamthi",
            "Kamthi Cantonment",
            "Kandari",
            "Kandhar",
            "Kandri",
            "Kandri II",
            "Kanhan",
            "Kankavli",
            "Kannad",
            "Karad",
            "Karanja",
            "Karanje Tarf",
            "Karivali",
            "Karjat",
            "Karmala",
            "Kasara Budruk",
            "Katai",
            "Katkar",
            "Katol",
            "Kegaon",
            "Khadkale",
            "Khadki",
            "Khamgaon",
            "Khapa",
            "Kharadi",
            "Kharakvasla",
            "Khed",
            "Kherdi",
            "Khoni",
            "Khopoli",
            "Khuldabad",
            "Kinwat",
            "Kodoli",
            "Kolhapur",
            "Kon",
            "Kondumal",
            "Kopargaon",
            "Kopharad",
            "Koradi",
            "Koregaon",
            "Korochi",
            "Kudal",
            "Kundaim",
            "Kundalwadi",
            "Kurandvad",
            "Kurduvadi",
            "Kusgaon Budruk",
            "Lanja",
            "Lasalgaon",
            "Latur",
            "Loha",
            "Lohegaon",
            "Lonar",
            "Lonavala",
            "Madhavnagar",
            "Mahabaleshwar",
            "Mahad",
            "Mahadula",
            "Maindargi",
            "Majalgaon",
            "Malegaon",
            "Malgaon",
            "Malkapur",
            "Malwan",
            "Manadur",
            "Manchar",
            "Mangalvedhe",
            "Mangrul Pir",
            "Manmad",
            "Manor",
            "Mansar",
            "Manwath",
            "Mapuca",
            "Matheran",
            "Mehkar",
            "Mhasla",
            "Mhaswad",
            "Mira Bhayandar",
            "Miraj",
            "Mohpa",
            "Mohpada",
            "Moram",
            "Morshi",
            "Mowad",
            "Mudkhed",
            "Mukhed",
            "Mul",
            "Mulshi",
            "Mumbai",
            "Murbad",
            "Murgud",
            "Murtijapur",
            "Murud",
            "Nachane",
            "Nagardeole",
            "Nagothane",
            "Nagpur",
            "Nakoda",
            "Nalasopara",
            "Naldurg",
            "Nanded",
            "Nandgaon",
            "Nandura",
            "Nandurbar",
            "Narkhed",
            "Nashik",
            "Navapur",
            "Navi Mumbai",
            "Navi Mumbai Panvel",
            "Neral",
            "Nigdi",
            "Nilanga",
            "Nildoh",
            "Nimbhore",
            "Ojhar",
            "Osmanabad",
            "Pachgaon",
            "Pachora",
            "Padagha",
            "Paithan",
            "Palghar",
            "Pali",
            "Panchgani",
            "Pandhakarwada",
            "Pandharpur",
            "Panhala",
            "Panvel",
            "Paranda",
            "Parbhani",
            "Parli",
            "Parola",
            "Partur",
            "Pasthal",
            "Patan",
            "Pathardi",
            "Pathri",
            "Patur",
            "Pawni",
            "Pen",
            "Pethumri",
            "Phaltan",
            "Pimpri",
            "Poladpur",
            "Pulgaon",
            "Pune",
            "Pune Cantonment",
            "Purna",
            "Purushottamnagar",
            "Pusad",
            "Rahimatpur",
            "Rahta Pimplas",
            "Rahuri",
            "Raigad",
            "Rajapur",
            "Rajgurunagar",
            "Rajur",
            "Rajura",
            "Ramtek",
            "Ratnagiri",
            "Ravalgaon",
            "Raver",
            "Revadanda",
            "Risod",
            "Roha Ashtami",
            "Sakri",
            "Sandor",
            "Sangamner",
            "Sangli",
            "Sangole",
            "Sasti",
            "Sasvad",
            "Satana",
            "Satara",
            "Savantvadi",
            "Savda",
            "Savner",
            "Sawari Jawharnagar",
            "Selu",
            "Shahada",
            "Shahapur",
            "Shegaon",
            "Shelar",
            "Shendurjana",
            "Shirdi",
            "Shirgaon",
            "Shirpur",
            "Shirur",
            "Shirwal",
            "Shivatkar",
            "Shrigonda",
            "Shrirampur",
            "Shrirampur Rural",
            "Sillewada",
            "Sillod",
            "Sindhudurg",
            "Sindi",
            "Sindi Turf Hindnagar",
            "Sindkhed Raja",
            "Singnapur",
            "Sinnar",
            "Sirur",
            "Sitasawangi",
            "Solapur",
            "Sonai",
            "Sonegaon",
            "Soyagaon",
            "Srivardhan",
            "Surgana",
            "Talegaon Dabhade",
            "Taloda",
            "Taloja",
            "Talwade",
            "Tarapur",
            "Tasgaon",
            "Tathavade",
            "Tekadi",
            "Telhara",
            "Thane",
            "Tirira",
            "Totaladoh",
            "Trimbak",
            "Tuljapur",
            "Tumsar",
            "Uchgaon",
            "Udgir",
            "Ulhasnagar",
            "Umarga",
            "Umarkhed",
            "Umarsara",
            "Umbar Pada Nandade",
            "Umred",
            "Umri Pragane Balapur",
            "Uran",
            "Uran Islampur",
            "Utekhol",
            "Vada",
            "Vadgaon",
            "Vadgaon Kasba",
            "Vaijapur",
            "Vanvadi",
            "Varangaon",
            "Vasai",
            "Vasantnagar",
            "Vashind",
            "Vengurla",
            "Virar",
            "Visapur",
            "Vite",
            "Vithalwadi",
            "Wadi",
            "Waghapur",
            "Wai",
            "Wajegaon",
            "Walani",
            "Wanadongri",
            "Wani",
            "Wardha",
            "Warora",
            "Warthi",
            "Warud",
            "Washim",
            "Yaval",
            "Yavatmal",
            "Yeola",
            "Yerkheda"
          ],
          "StateName": "Maharashtra"
        },
        {
          "Cities": [
            "Andro",
            "Bijoy Govinda",
            "Bishnupur",
            "Churachandpur",
            "Heriok",
            "Imphal",
            "Jiribam",
            "Kakching",
            "Kakching Khunou",
            "Khongman",
            "Kumbi",
            "Kwakta",
            "Lamai",
            "Lamjaotongba",
            "Lamshang",
            "Lilong",
            "Mayang Imphal",
            "Moirang",
            "Moreh",
            "Nambol",
            "Naoriya Pakhanglakpa",
            "Ningthoukhong",
            "Oinam",
            "Porompat",
            "Samurou",
            "Sekmai Bazar",
            "Senapati",
            "Sikhong Sekmai",
            "Sugnu",
            "Thongkhong Laxmi Bazar",
            "Thoubal",
            "Torban",
            "Wangjing",
            "Wangoi",
            "Yairipok"
          ],
          "StateName": "Manipur"
        },
        {
          "Cities": [
            "Baghmara",
            "Cherrapunji",
            "Jawai",
            "Madanrting",
            "Mairang",
            "Mawlai",
            "Nongmynsong",
            "Nongpoh",
            "Nongstoin",
            "Nongthymmai",
            "Pynthorumkhrah",
            "Resubelpara",
            "Shillong",
            "Shillong Cantonment",
            "Tura",
            "Williamnagar"
          ],
          "StateName": "Meghalaya"
        },
        {
          "Cities": [
            "Aizawl",
            "Bairabi",
            "Biate",
            "Champhai",
            "Darlawn",
            "Hnahthial",
            "Kawnpui",
            "Khawhai",
            "Khawzawl",
            "Kolasib",
            "Lengpui",
            "Lunglei",
            "Mamit",
            "North Vanlaiphai",
            "Saiha",
            "Sairang",
            "Saitul",
            "Serchhip",
            "Thenzawl",
            "Tlabung",
            "Vairengte",
            "Zawlnuam"
          ],
          "StateName": "Mizoram"
        },
        {
          "Cities": [
            "Chumukedima",
            "Dimapur",
            "Kohima",
            "Mokokchung",
            "Mon",
            "Phek",
            "Tuensang",
            "Wokha",
            "Zunheboto"
          ],
          "StateName": "Nagaland"
        },
        {
          "Cities": [],
          "StateName": "Narora"
        },
        {
          "Cities": [],
          "StateName": "Natwar"
        },
        {
          "Cities": [
            "Anandapur",
            "Angul",
            "Aska",
            "Athgarh",
            "Athmallik",
            "Balagoda",
            "Balangir",
            "Balasore",
            "Baleshwar",
            "Balimeta",
            "Balugaon",
            "Banapur",
            "Bangura",
            "Banki",
            "Banposh",
            "Barbil",
            "Bargarh",
            "Baripada",
            "Barpali",
            "Basudebpur",
            "Baudh",
            "Belagachhia",
            "Belaguntha",
            "Belpahar",
            "Berhampur",
            "Bhadrak",
            "Bhanjanagar",
            "Bhawanipatna",
            "Bhuban",
            "Bhubaneswar",
            "Binika",
            "Birmitrapur",
            "Bishama Katek",
            "Bolangir",
            "Brahmapur",
            "Brajrajnagar",
            "Buguda",
            "Burla",
            "Byasanagar",
            "Champua",
            "Chandapur",
            "Chandbali",
            "Chandili",
            "Charibatia",
            "Chatrapur",
            "Chikitigarh",
            "Chitrakonda",
            "Choudwar",
            "Cuttack",
            "Dadhapatna",
            "Daitari",
            "Damanjodi",
            "Deogarh",
            "Deracolliery",
            "Dhamanagar",
            "Dhenkanal",
            "Digapahandi",
            "Dungamal",
            "Fertilizer Corporation of Indi",
            "Ganjam",
            "Ghantapada",
            "Gopalpur",
            "Gudari",
            "Gunupur",
            "Hatibandha",
            "Hinjilikatu",
            "Hirakud",
            "Jagatsinghapur",
            "Jajpur",
            "Jalda",
            "Jaleswar",
            "Jatni",
            "Jaypur",
            "Jeypore",
            "Jharsuguda",
            "Jhumpura",
            "Joda",
            "Junagarh",
            "Kamakhyanagar",
            "Kantabanji",
            "Kantilo",
            "Karanja",
            "Kashinagara",
            "Kataka",
            "Kavisuryanagar",
            "Kendrapara",
            "Kendujhar",
            "Keonjhar",
            "Kesinga",
            "Khaliapali",
            "Khalikote",
            "Khandaparha",
            "Kharhial",
            "Kharhial Road",
            "Khatiguda",
            "Khurda",
            "Kochinda",
            "Kodala",
            "Konark",
            "Koraput",
            "Kotaparh",
            "Lanjigarh",
            "Lattikata",
            "Makundapur",
            "Malkangiri",
            "Mukhiguda",
            "Nabarangpur",
            "Nalco",
            "Naurangapur",
            "Nayagarh",
            "Nilagiri",
            "Nimaparha",
            "Nuapada",
            "Nuapatna",
            "OCL Industrialship",
            "Padampur",
            "Paradip",
            "Paradwip",
            "Parlakimidi",
            "Patamundai",
            "Patnagarh",
            "Phulabani",
            "Pipili",
            "Polasara",
            "Pratapsasan",
            "Puri",
            "Purushottampur",
            "Rairangpur",
            "Raj Gangpur",
            "Rambha",
            "Raurkela",
            "Raurkela Civil Township",
            "Rayagada",
            "Redhakhol",
            "Remuna",
            "Rengali",
            "Rourkela",
            "Sambalpur",
            "Sinapali",
            "Sonepur",
            "Sorada",
            "Soro",
            "Sunabeda",
            "Sundargarh",
            "Talcher",
            "Talcher Thermal Power Station ",
            "Tarabha",
            "Tensa",
            "Titlagarh",
            "Udala",
            "Udayagiri",
            "Umarkot",
            "Vikrampur"
          ],
          "StateName": "Odisha"
        },
        {
          "Cities": [],
          "StateName": "Paschim Medinipur"
        },
        {
          "Cities": [
            "Ariankuppam",
            "Karaikal",
            "Kurumbapet",
            "Mahe",
            "Ozhukarai",
            "Pondicherry",
            "Villianur",
            "Yanam"
          ],
          "StateName": "Pondicherry"
        },
        {
          "Cities": [
            "Abohar",
            "Adampur",
            "Ahmedgarh",
            "Ajnala",
            "Akalgarh",
            "Alawalpur",
            "Amloh",
            "Amritsar",
            "Amritsar Cantonment",
            "Anandpur Sahib",
            "Badhni Kalan",
            "Bagh Purana",
            "Balachaur",
            "Banaur",
            "Banga",
            "Banur",
            "Baretta",
            "Bariwala",
            "Barnala",
            "Bassi Pathana",
            "Batala",
            "Bathinda",
            "Begowal",
            "Behrampur",
            "Bhabat",
            "Bhadur",
            "Bhankharpur",
            "Bharoli Kalan",
            "Bhawanigarh",
            "Bhikhi",
            "Bhikhiwind",
            "Bhisiana",
            "Bhogpur",
            "Bhuch",
            "Bhulath",
            "Budha Theh",
            "Budhlada",
            "Chima",
            "Chohal",
            "Dasuya",
            "Daulatpur",
            "Dera Baba Nanak",
            "Dera Bassi",
            "Dhanaula",
            "Dharam Kot",
            "Dhariwal",
            "Dhilwan",
            "Dhuri",
            "Dinanagar",
            "Dirba",
            "Doraha",
            "Faridkot",
            "Fateh Nangal",
            "Fatehgarh Churian",
            "Fatehgarh Sahib",
            "Fazilka",
            "Firozpur",
            "Firozpur Cantonment",
            "Gardhiwala",
            "Garhshankar",
            "Ghagga",
            "Ghanaur",
            "Giddarbaha",
            "Gobindgarh",
            "Goniana",
            "Goraya",
            "Gurdaspur",
            "Guru Har Sahai",
            "Hajipur",
            "Handiaya",
            "Hariana",
            "Hoshiarpur",
            "Hussainpur",
            "Jagraon",
            "Jaitu",
            "Jalalabad",
            "Jalandhar",
            "Jalandhar Cantonment",
            "Jandiala",
            "Jugial",
            "Kalanaur",
            "Kapurthala",
            "Karoran",
            "Kartarpur",
            "Khamanon",
            "Khanauri",
            "Khanna",
            "Kharar",
            "Khem Karan",
            "Kot Fatta",
            "Kot Isa Khan",
            "Kot Kapura",
            "Kotkapura",
            "Kurali",
            "Lalru",
            "Lehra Gaga",
            "Lodhian Khas",
            "Longowal",
            "Ludhiana",
            "Machhiwara",
            "Mahilpur",
            "Majitha",
            "Makhu",
            "Malaut",
            "Malerkotla",
            "Maloud",
            "Mandi Gobindgarh",
            "Mansa",
            "Maur",
            "Moga",
            "Mohali",
            "Moonak",
            "Morinda",
            "Mukerian",
            "Muktsar",
            "Mullanpur Dakha",
            "Mullanpur Garibdas",
            "Munak",
            "Muradpura",
            "Nabha",
            "Nakodar",
            "Nangal",
            "Nawashahr",
            "Naya Nangal",
            "Nehon",
            "Nurmahal",
            "Pathankot",
            "Patiala",
            "Patti",
            "Pattran",
            "Payal",
            "Phagwara",
            "Phillaur",
            "Qadian",
            "Rahon",
            "Raikot",
            "Raja Sansi",
            "Rajpura",
            "Ram Das",
            "Raman",
            "Rampura",
            "Rayya",
            "Rupnagar",
            "Rurki Kasba",
            "Sahnewal",
            "Samana",
            "Samrala",
            "Sanaur",
            "Sangat",
            "Sangrur",
            "Sansarpur",
            "Sardulgarh",
            "Shahkot",
            "Sham Churasi",
            "Shekhpura",
            "Sirhind",
            "Sri Hargobindpur",
            "Sujanpur",
            "Sultanpur Lodhi",
            "Sunam",
            "Talwandi Bhai",
            "Talwara",
            "Tappa",
            "Tarn Taran",
            "Urmar Tanda",
            "Zira",
            "Zirakpur"
          ],
          "StateName": "Punjab"
        },
        {
          "Cities": [
            "Abu Road",
            "Ajmer",
            "Aklera",
            "Alwar",
            "Amet",
            "Antah",
            "Anupgarh",
            "Asind",
            "Bagar",
            "Bagru",
            "Bahror",
            "Bakani",
            "Bali",
            "Balotra",
            "Bandikui",
            "Banswara",
            "Baran",
            "Bari",
            "Bari Sadri",
            "Barmer",
            "Basi",
            "Basni Belima",
            "Baswa",
            "Bayana",
            "Beawar",
            "Begun",
            "Bhadasar",
            "Bhadra",
            "Bhalariya",
            "Bharatpur",
            "Bhasawar",
            "Bhawani Mandi",
            "Bhawri",
            "Bhilwara",
            "Bhindar",
            "Bhinmal",
            "Bhiwadi",
            "Bijoliya Kalan",
            "Bikaner",
            "Bilara",
            "Bissau",
            "Borkhera",
            "Budhpura",
            "Bundi",
            "Chatsu",
            "Chechat",
            "Chhabra",
            "Chhapar",
            "Chhipa Barod",
            "Chhoti Sadri",
            "Chirawa",
            "Chittaurgarh",
            "Chittorgarh",
            "Chomun",
            "Churu",
            "Daosa",
            "Dariba",
            "Dausa",
            "Deoli",
            "Deshnok",
            "Devgarh",
            "Devli",
            "Dhariawad",
            "Dhaulpur",
            "Dholpur",
            "Didwana",
            "Dig",
            "Dungargarh",
            "Dungarpur",
            "Falna",
            "Fatehnagar",
            "Fatehpur",
            "Gajsinghpur",
            "Galiakot",
            "Ganganagar",
            "Gangapur",
            "Goredi Chancha",
            "Gothra",
            "Govindgarh",
            "Gulabpura",
            "Hanumangarh",
            "Hindaun",
            "Indragarh",
            "Jahazpur",
            "Jaipur",
            "Jaisalmer",
            "Jaiselmer",
            "Jaitaran",
            "Jalore",
            "Jhalawar",
            "Jhalrapatan",
            "Jhunjhunun",
            "Jobner",
            "Jodhpur",
            "Kaithun",
            "Kaman",
            "Kankroli",
            "Kanor",
            "Kapasan",
            "Kaprain",
            "Karanpura",
            "Karauli",
            "Kekri",
            "Keshorai Patan",
            "Kesrisinghpur",
            "Khairthal",
            "Khandela",
            "Khanpur",
            "Kherli",
            "Kherliganj",
            "Kherwara Chhaoni",
            "Khetri",
            "Kiranipura",
            "Kishangarh",
            "Kishangarh Ranwal",
            "Kolvi Rajendrapura",
            "Kot Putli",
            "Kota",
            "Kuchaman",
            "Kuchera",
            "Kumbhalgarh",
            "Kumbhkot",
            "Kumher",
            "Kushalgarh",
            "Lachhmangarh",
            "Ladnun",
            "Lakheri",
            "Lalsot",
            "Losal",
            "Madanganj",
            "Mahu Kalan",
            "Mahwa",
            "Makrana",
            "Malpura",
            "Mandal",
            "Mandalgarh",
            "Mandawar",
            "Mandwa",
            "Mangrol",
            "Manohar Thana",
            "Manoharpur",
            "Marwar",
            "Merta",
            "Modak",
            "Mount Abu",
            "Mukandgarh",
            "Mundwa",
            "Nadbai",
            "Naenwa",
            "Nagar",
            "Nagaur",
            "Napasar",
            "Naraina",
            "Nasirabad",
            "Nathdwara",
            "Nawa",
            "Nawalgarh",
            "Neem Ka Thana",
            "Neemrana",
            "Newa Talai",
            "Nimaj",
            "Nimbahera",
            "Niwai",
            "Nohar",
            "Nokha",
            "One SGM",
            "Padampur",
            "Pali",
            "Partapur",
            "Parvatsar",
            "Pasoond",
            "Phalna",
            "Phalodi",
            "Phulera",
            "Pilani",
            "Pilibanga",
            "Pindwara",
            "Pipalia Kalan",
            "Pipar",
            "Pirawa",
            "Pokaran",
            "Pratapgarh",
            "Pushkar",
            "Raipur",
            "Raisinghnagar",
            "Rajakhera",
            "Rajaldesar",
            "Rajgarh",
            "Rajsamand",
            "Ramganj Mandi",
            "Ramgarh",
            "Rani",
            "Raniwara",
            "Ratan Nagar",
            "Ratangarh",
            "Rawatbhata",
            "Rawatsar",
            "Rikhabdev",
            "Ringas",
            "Sadri",
            "Sadulshahar",
            "Sagwara",
            "Salumbar",
            "Sambhar",
            "Samdari",
            "Sanchor",
            "Sangariya",
            "Sangod",
            "Sardarshahr",
            "Sarwar",
            "Satal Kheri",
            "Sawai Madhopur",
            "Sewan Kalan",
            "Shahpura",
            "Sheoganj",
            "Sikar",
            "Sirohi",
            "Siwana",
            "Sogariya",
            "Sojat",
            "Sojat Road",
            "Sri Madhopur",
            "Sriganganagar",
            "Sujangarh",
            "Suket",
            "Sumerpur",
            "Sunel",
            "Surajgarh",
            "Suratgarh",
            "Swaroopganj",
            "Takhatgarh",
            "Taranagar",
            "Three STR",
            "Tijara",
            "Toda Bhim",
            "Toda Raisingh",
            "Todra",
            "Tonk",
            "Udaipur",
            "Udpura",
            "Uniara",
            "Vanasthali",
            "Vidyavihar",
            "Vijainagar",
            "Viratnagar",
            "Wer"
          ],
          "StateName": "Rajasthan"
        },
        {
          "Cities": [
            "Gangtok",
            "Gezing",
            "Jorethang",
            "Mangan",
            "Namchi",
            "Naya Bazar",
            "No City",
            "Rangpo",
            "Sikkim",
            "Singtam",
            "Upper Tadong"
          ],
          "StateName": "Sikkim"
        },
        {
          "Cities": [
            "Abiramam",
            "Achampudur",
            "Acharapakkam",
            "Acharipallam",
            "Achipatti",
            "Adikaratti",
            "Adiramapattinam",
            "Aduturai",
            "Adyar",
            "Agaram",
            "Agasthiswaram",
            "Akkaraipettai",
            "Alagappapuram",
            "Alagapuri",
            "Alampalayam",
            "Alandur",
            "Alanganallur",
            "Alangayam",
            "Alangudi",
            "Alangulam",
            "Alanthurai",
            "Alapakkam",
            "Allapuram",
            "Alur",
            "Alwar Tirunagari",
            "Alwarkurichi",
            "Ambasamudram",
            "Ambur",
            "Ammainaickanur",
            "Ammaparikuppam",
            "Ammapettai",
            "Ammavarikuppam",
            "Ammur",
            "Anaimalai",
            "Anaiyur",
            "Anakaputhur",
            "Ananthapuram",
            "Andanappettai",
            "Andipalayam",
            "Andippatti",
            "Anjugramam",
            "Annamalainagar",
            "Annavasal",
            "Annur",
            "Anthiyur",
            "Appakudal",
            "Arachalur",
            "Arakandanallur",
            "Arakonam",
            "Aralvaimozhi",
            "Arani",
            "Arani Road",
            "Arantangi",
            "Arasiramani",
            "Aravakurichi",
            "Aravankadu",
            "Arcot",
            "Arimalam",
            "Ariyalur",
            "Ariyappampalayam",
            "Ariyur",
            "Arni",
            "Arulmigu Thirumuruganpundi",
            "Arumanai",
            "Arumbavur",
            "Arumuganeri",
            "Aruppukkottai",
            "Ashokapuram",
            "Athani",
            "Athanur",
            "Athimarapatti",
            "Athipattu",
            "Athur",
            "Attayyampatti",
            "Attur",
            "Auroville",
            "Avadattur",
            "Avadi",
            "Avalpundurai",
            "Avaniapuram",
            "Avinashi",
            "Ayakudi",
            "Ayanadaippu",
            "Aygudi",
            "Ayothiapattinam",
            "Ayyalur",
            "Ayyampalayam",
            "Ayyampettai",
            "Azhagiapandiapuram",
            "Balakrishnampatti",
            "Balakrishnapuram",
            "Balapallam",
            "Balasamudram",
            "Bargur",
            "Belur",
            "Berhatty",
            "Bhavani",
            "Bhawanisagar",
            "Bhuvanagiri",
            "Bikketti",
            "Bodinayakkanur",
            "Brahmana Periya Agraharam",
            "Buthapandi",
            "Buthipuram",
            "Chatrapatti",
            "Chembarambakkam",
            "Chengalpattu",
            "Chengam",
            "Chennai",
            "Chennasamudram",
            "Chennimalai",
            "Cheranmadevi",
            "Cheruvanki",
            "Chetpet",
            "Chettiarpatti",
            "Chettipalaiyam",
            "Chettipalayam Cantonment",
            "Chettithangal",
            "Cheyur",
            "Cheyyar",
            "Chidambaram",
            "Chinalapatti",
            "Chinna Anuppanadi",
            "Chinna Salem",
            "Chinnakkampalayam",
            "Chinnammanur",
            "Chinnampalaiyam",
            "Chinnasekkadu",
            "Chinnavedampatti",
            "Chitlapakkam",
            "Chittodu",
            "Cholapuram",
            "Coimbatore",
            "Coonoor",
            "Courtalam",
            "Cuddalore",
            "Dalavaipatti",
            "Darasuram",
            "Denkanikottai",
            "Desur",
            "Devadanapatti",
            "Devakkottai",
            "Devakottai",
            "Devanangurichi",
            "Devarshola",
            "Devasthanam",
            "Dhalavoipuram",
            "Dhali",
            "Dhaliyur",
            "Dharapadavedu",
            "Dharapuram",
            "Dharmapuri",
            "Dindigul",
            "Dusi",
            "Edaganasalai",
            "Edaikodu",
            "Edakalinadu",
            "Elathur",
            "Elayirampannai",
            "Elumalai",
            "Eral",
            "Eraniel",
            "Eriodu",
            "Erode",
            "Erumaipatti",
            "Eruvadi",
            "Ethapur",
            "Ettaiyapuram",
            "Ettimadai",
            "Ezhudesam",
            "Ganapathipuram",
            "Gandhi Nagar",
            "Gangaikondan",
            "Gangavalli",
            "Ganguvarpatti",
            "Gingi",
            "Gopalasamudram",
            "Gopichettipalaiyam",
            "Gudalur",
            "Gudiyattam",
            "Guduvanchery",
            "Gummidipoondi",
            "Hanumanthampatti",
            "Harur",
            "Harveypatti",
            "Highways",
            "Hosur",
            "Hubbathala",
            "Huligal",
            "Idappadi",
            "Idikarai",
            "Ilampillai",
            "Ilanji",
            "Iluppaiyurani",
            "Iluppur",
            "Inam Karur",
            "Injambakkam",
            "Irugur",
            "Jaffrabad",
            "Jagathala",
            "Jalakandapuram",
            "Jalladiampet",
            "Jambai",
            "Jayankondam",
            "Jolarpet",
            "Kadambur",
            "Kadathur",
            "Kadayal",
            "Kadayampatti",
            "Kadayanallur",
            "Kadiapatti",
            "Kalakkad",
            "Kalambur",
            "Kalapatti",
            "Kalappanaickenpatti",
            "Kalavai",
            "Kalinjur",
            "Kaliyakkavilai",
            "Kallakkurichi",
            "Kallakudi",
            "Kallidaikurichchi",
            "Kallukuttam",
            "Kallupatti",
            "Kalpakkam",
            "Kalugumalai",
            "Kamayagoundanpatti",
            "Kambainallur",
            "Kambam",
            "Kamuthi",
            "Kanadukathan",
            "Kanakkampalayam",
            "Kanam",
            "Kanchipuram",
            "Kandanur",
            "Kangayam",
            "Kangayampalayam",
            "Kangeyanallur",
            "Kaniyur",
            "Kanjikoil",
            "Kannadendal",
            "Kannamangalam",
            "Kannampalayam",
            "Kannankurichi",
            "Kannapalaiyam",
            "Kannivadi",
            "Kanyakumari",
            "Kappiyarai",
            "Karaikkudi",
            "Karamadai",
            "Karambakkam",
            "Karambakkudi",
            "Kariamangalam",
            "Kariapatti",
            "Karugampattur",
            "Karumandi Chellipalayam",
            "Karumathampatti",
            "Karumbakkam",
            "Karungal",
            "Karunguzhi",
            "Karuppur",
            "Karur",
            "Kasipalaiyam",
            "Kasipalayam G",
            "Kathirvedu",
            "Kathujuganapalli",
            "Katpadi",
            "Kattivakkam",
            "Kattumannarkoil",
            "Kattupakkam",
            "Kattuputhur",
            "Kaveripakkam",
            "Kaveripattinam",
            "Kavundampalaiyam",
            "Kavundampalayam",
            "Kayalpattinam",
            "Kayattar",
            "Kelamangalam",
            "Kelambakkam",
            "Kembainaickenpalayam",
            "Kethi",
            "Kilakarai",
            "Kilampadi",
            "Kilkulam",
            "Kilkunda",
            "Killiyur",
            "Killlai",
            "Kilpennathur",
            "Kilvelur",
            "Kinathukadavu",
            "Kiramangalam",
            "Kiranur",
            "Kiripatti",
            "Kizhapavur",
            "Kmarasamipatti",
            "Kochadai",
            "Kodaikanal",
            "Kodambakkam",
            "Kodavasal",
            "Kodumudi",
            "Kolachal",
            "Kolappalur",
            "Kolathupalayam",
            "Kolathur",
            "Kollankodu",
            "Kollankoil",
            "Komaralingam",
            "Komarapalayam",
            "Kombai",
            "Konakkarai",
            "Konavattam",
            "Kondalampatti",
            "Konganapuram",
            "Koradacheri",
            "Korampallam",
            "Kotagiri",
            "Kothinallur",
            "Kottaiyur",
            "Kottakuppam",
            "Kottaram",
            "Kottivakkam",
            "Kottur",
            "Kovilpatti",
            "Koyampattur",
            "Krishnagiri",
            "Krishnarayapuram",
            "Krishnasamudram",
            "Kuchanur",
            "Kuhalur",
            "Kulasekarappattinam",
            "Kulasekarapuram",
            "Kulithalai",
            "Kumarapalaiyam",
            "Kumarapalayam",
            "Kumarapuram",
            "Kumbakonam",
            "Kundrathur",
            "Kuniyamuthur",
            "Kunnathur",
            "Kunur",
            "Kuraikundu",
            "Kurichi",
            "Kurinjippadi",
            "Kurudampalaiyam",
            "Kurumbalur",
            "Kuthalam",
            "Kuthappar",
            "Kuttalam",
            "Kuttanallur",
            "Kuzhithurai",
            "Labbaikudikadu",
            "Lakkampatti",
            "Lalgudi",
            "Lalpet",
            "Llayangudi",
            "Madambakkam",
            "Madanur",
            "Madathukulam",
            "Madhavaram",
            "Madippakkam",
            "Madukkarai",
            "Madukkur",
            "Madurai",
            "Maduranthakam",
            "Maduravoyal",
            "Mahabalipuram",
            "Makkinanpatti",
            "Mallamuppampatti",
            "Mallankinaru",
            "Mallapuram",
            "Mallasamudram",
            "Mallur",
            "Mamallapuram",
            "Mamsapuram",
            "Manachanallur",
            "Manali",
            "Manalmedu",
            "Manalurpet",
            "Manamadurai",
            "Manapakkam",
            "Manapparai",
            "Manavalakurichi",
            "Mandaikadu",
            "Mandapam",
            "Mangadu",
            "Mangalam",
            "Mangalampet",
            "Manimutharu",
            "Mannargudi",
            "Mappilaiurani",
            "Maraimalai Nagar",
            "Marakkanam",
            "Maramangalathupatti",
            "Marandahalli",
            "Markayankottai",
            "Marudur",
            "Marungur",
            "Masinigudi",
            "Mathigiri",
            "Mattur",
            "Mayiladuthurai",
            "Mecheri",
            "Melacheval",
            "Melachokkanathapuram",
            "Melagaram",
            "Melamadai",
            "Melamaiyur",
            "Melanattam",
            "Melathiruppanthuruthi",
            "Melattur",
            "Melmananbedu",
            "Melpattampakkam",
            "Melur",
            "Melvisharam",
            "Mettupalayam",
            "Mettur",
            "Meyyanur",
            "Milavittan",
            "Minakshipuram",
            "Minambakkam",
            "Minjur",
            "Modakurichi",
            "Mohanur",
            "Mopperipalayam",
            "Mudalur",
            "Mudichur",
            "Mudukulathur",
            "Mukasipidariyur",
            "Mukkudal",
            "Mulagumudu",
            "Mulakaraipatti",
            "Mulanur",
            "Mullakkadu",
            "Muruganpalayam",
            "Musiri",
            "Muthupet",
            "Muthur",
            "Muttayyapuram",
            "Muttupet",
            "Muvarasampettai",
            "Myladi",
            "Mylapore",
            "Nadukkuthagai",
            "Naduvattam",
            "Nagapattinam",
            "Nagavakulam",
            "Nagercoil",
            "Nagojanahalli",
            "Nallampatti",
            "Nallur",
            "Namagiripettai",
            "Namakkal",
            "Nambiyur",
            "Nambutalai",
            "Nandambakkam",
            "Nandivaram",
            "Nangavalli",
            "Nangavaram",
            "Nanguneri",
            "Nanjikottai",
            "Nannilam",
            "Naranammalpuram",
            "Naranapuram",
            "Narasimhanaickenpalayam",
            "Narasingapuram",
            "Narasojipatti",
            "Naravarikuppam",
            "Nasiyanur",
            "Natham",
            "Nathampannai",
            "Natrampalli",
            "Nattam",
            "Nattapettai",
            "Nattarasankottai",
            "Navalpattu",
            "Nazarethpettai",
            "Nazerath",
            "Neikkarapatti",
            "Neiyyur",
            "Nellikkuppam",
            "Nelliyalam",
            "Nemili",
            "Nemilicheri",
            "Neripperichal",
            "Nerkunram",
            "Nerkuppai",
            "Nerunjipettai",
            "Neykkarappatti",
            "Neyveli",
            "Nidamangalam",
            "Nilagiri",
            "Nilakkottai",
            "Nilankarai",
            "Odaipatti",
            "Odaiyakulam",
            "Oddanchatram",
            "Odugathur",
            "Oggiyamduraipakkam",
            "Olagadam",
            "Omalur",
            "Ooty",
            "Orathanadu",
            "Othakadai",
            "Othakalmandapam",
            "Ottapparai",
            "Pacode",
            "Padaividu",
            "Padianallur",
            "Padirikuppam",
            "Padmanabhapuram",
            "Padririvedu",
            "Palaganangudy",
            "Palaimpatti",
            "Palakkodu",
            "Palamedu",
            "Palani",
            "Palani Chettipatti",
            "Palavakkam",
            "Palavansathu",
            "Palayakayal",
            "Palayam",
            "Palayamkottai",
            "Palladam",
            "Pallapalayam",
            "Pallapatti",
            "Pallattur",
            "Pallavaram",
            "Pallikaranai",
            "Pallikonda",
            "Pallipalaiyam",
            "Pallipalaiyam Agraharam",
            "Pallipattu",
            "Pammal",
            "Panagudi",
            "Panaimarathupatti",
            "Panapakkam",
            "Panboli",
            "Pandamangalam",
            "Pannaikadu",
            "Pannaipuram",
            "Pannuratti",
            "Panruti",
            "Papanasam",
            "Pappankurichi",
            "Papparapatti",
            "Pappireddipatti",
            "Paramakkudi",
            "Paramankurichi",
            "Paramathi",
            "Parangippettai",
            "Paravai",
            "Pasur",
            "Pathamadai",
            "Pattinam",
            "Pattiviranpatti",
            "Pattukkottai",
            "Pazhugal",
            "Pennadam",
            "Pennagaram",
            "Pennathur",
            "Peraiyur",
            "Peralam",
            "Perambalur",
            "Peranamallur",
            "Peravurani",
            "Periyakodiveri",
            "Periyakulam",
            "Periyanayakkanpalaiyam",
            "Periyanegamam",
            "Periyapatti",
            "Periyasemur",
            "Pernambut",
            "Perumagalur",
            "Perumandi",
            "Perumuchi",
            "Perundurai",
            "Perungalathur",
            "Perungudi",
            "Perungulam",
            "Perur",
            "Perur Chettipalaiyam",
            "Pethampalayam",
            "Pethanaickenpalayam",
            "Pillanallur",
            "Pirkankaranai",
            "Polichalur",
            "Pollachi",
            "Polur",
            "Ponmani",
            "Ponnamaravathi",
            "Ponnampatti",
            "Ponneri",
            "Porur",
            "Pothanur",
            "Pothatturpettai",
            "Pudukadai",
            "Pudukkottai Cantonment",
            "Pudukottai",
            "Pudupalaiyam Aghraharam",
            "Pudupalayam",
            "Pudupatti",
            "Pudupattinam",
            "Pudur",
            "Puduvayal",
            "Pulambadi",
            "Pulampatti",
            "Puliyampatti",
            "Puliyankudi",
            "Puliyur",
            "Pullampadi",
            "Puluvapatti",
            "Punamalli",
            "Punjai Puliyampatti",
            "Punjai Thottakurichi",
            "Punjaipugalur",
            "Puthalam",
            "Putteri",
            "Puvalur",
            "Puzhal",
            "Puzhithivakkam",
            "Rajapalayam",
            "Ramanathapuram",
            "Ramapuram",
            "Rameswaram",
            "Ranipet",
            "Rasipuram",
            "Rayagiri",
            "Rithapuram",
            "Rosalpatti",
            "Rudravathi",
            "Sadayankuppam",
            "Saint Thomas Mount",
            "Salangapalayam",
            "Salem",
            "Samalapuram",
            "Samathur",
            "Sambavar Vadagarai",
            "Sankaramanallur",
            "Sankarankoil",
            "Sankarapuram",
            "Sankari",
            "Sankarnagar",
            "Saravanampatti",
            "Sarcarsamakulam",
            "Sathiyavijayanagaram",
            "Sathuvachari",
            "Sathyamangalam",
            "Sattankulam",
            "Sattur",
            "Sayalgudi",
            "Sayapuram",
            "Seithur",
            "Sembakkam",
            "Semmipalayam",
            "Sennirkuppam",
            "Senthamangalam",
            "Sentharapatti",
            "Senur",
            "Sethiathoppu",
            "Sevilimedu",
            "Sevugampatti",
            "Shenbakkam",
            "Shencottai",
            "Shenkottai",
            "Sholavandan",
            "Sholinganallur",
            "Sholingur",
            "Sholur",
            "Sikkarayapuram",
            "Singampuneri",
            "Singanallur",
            "Singaperumalkoil",
            "Sirapalli",
            "Sirkali",
            "Sirugamani",
            "Sirumugai",
            "Sithayankottai",
            "Sithurajapuram",
            "Sivaganga",
            "Sivagiri",
            "Sivakasi",
            "Sivanthipuram",
            "Sivur",
            "Soranjeri",
            "South Kannanur",
            "South Kodikulam",
            "Srimushnam",
            "Sriperumpudur",
            "Sriramapuram",
            "Srirangam",
            "Srivaikuntam",
            "Srivilliputtur",
            "Suchindram",
            "Suliswaranpatti",
            "Sulur",
            "Sundarapandiam",
            "Sundarapandiapuram",
            "Surampatti",
            "Surandai",
            "Suriyampalayam",
            "Swamimalai",
            "TNPL Pugalur",
            "Tambaram",
            "Taramangalam",
            "Tattayyangarpettai",
            "Tayilupatti",
            "Tenkasi",
            "Thadikombu",
            "Thakkolam",
            "Thalainayar",
            "Thalakudi",
            "Thamaraikulam",
            "Thammampatti",
            "Thanjavur",
            "Thanthoni",
            "Tharangambadi",
            "Thedavur",
            "Thenambakkam",
            "Thengampudur",
            "Theni",
            "Theni Allinagaram",
            "Thenkarai",
            "Thenthamaraikulam",
            "Thenthiruperai",
            "Thesur",
            "Thevaram",
            "Thevur",
            "Thiagadurgam",
            "Thiagarajar Colony",
            "Thingalnagar",
            "Thiruchirapalli",
            "Thirukarungudi",
            "Thirukazhukundram",
            "Thirumalayampalayam",
            "Thirumazhisai",
            "Thirunagar",
            "Thirunageswaram",
            "Thirunindravur",
            "Thirunirmalai",
            "Thiruparankundram",
            "Thiruparappu",
            "Thiruporur",
            "Thiruppanandal",
            "Thirupuvanam",
            "Thiruthangal",
            "Thiruthuraipundi",
            "Thiruvaivaru",
            "Thiruvalam",
            "Thiruvarur",
            "Thiruvattaru",
            "Thiruvenkatam",
            "Thiruvennainallur",
            "Thiruvithankodu",
            "Thisayanvilai",
            "Thittacheri",
            "Thondamuthur",
            "Thorapadi",
            "Thottipalayam",
            "Thottiyam",
            "Thudiyalur",
            "Thuthipattu",
            "Thuvakudi",
            "Timiri",
            "Tindivanam",
            "Tinnanur",
            "Tiruchchendur",
            "Tiruchengode",
            "Tirukkalukkundram",
            "Tirukkattuppalli",
            "Tirukkoyilur",
            "Tirumangalam",
            "Tirumullaivasal",
            "Tirumuruganpundi",
            "Tirunageswaram",
            "Tirunelveli",
            "Tirupathur",
            "Tirupattur",
            "Tiruppuvanam",
            "Tirupur",
            "Tirusulam",
            "Tiruttani",
            "Tiruvallur",
            "Tiruvannamalai",
            "Tiruverambur",
            "Tiruverkadu",
            "Tiruvethipuram",
            "Tiruvidaimarudur",
            "Tiruvottiyur",
            "Tittakudi",
            "Tondi",
            "Turaiyur",
            "Tuticorin",
            "Udagamandalam",
            "Udagamandalam Valley",
            "Udankudi",
            "Udayarpalayam",
            "Udumalaipettai",
            "Udumalpet",
            "Ullur",
            "Ulundurpettai",
            "Unjalaur",
            "Unnamalaikadai",
            "Uppidamangalam",
            "Uppiliapuram",
            "Urachikkottai",
            "Urapakkam",
            "Usilampatti",
            "Uthangarai",
            "Uthayendram",
            "Uthiramerur",
            "Uthukkottai",
            "Uttamapalaiyam",
            "Uttukkuli",
            "Vadakarai Kizhpadugai",
            "Vadakkanandal",
            "Vadakku Valliyur",
            "Vadalur",
            "Vadamadurai",
            "Vadavalli",
            "Vadipatti",
            "Vadugapatti",
            "Vaithiswarankoil",
            "Valangaiman",
            "Valasaravakkam",
            "Valavanur",
            "Vallam",
            "Valparai",
            "Valvaithankoshtam",
            "Vanavasi",
            "Vandalur",
            "Vandavasi",
            "Vandiyur",
            "Vaniputhur",
            "Vaniyambadi",
            "Varadarajanpettai",
            "Varadharajapuram",
            "Vasudevanallur",
            "Vathirairuppu",
            "Vattalkundu",
            "Vazhapadi",
            "Vedapatti",
            "Vedaranniyam",
            "Vedasandur",
            "Velampalaiyam",
            "Velankanni",
            "Vellakinar",
            "Vellakoil",
            "Vellalapatti",
            "Vellalur",
            "Vellanur",
            "Vellimalai",
            "Vellore",
            "Vellottamparappu",
            "Velluru",
            "Vengampudur",
            "Vengathur",
            "Vengavasal",
            "Venghatur",
            "Venkarai",
            "Vennanthur",
            "Veppathur",
            "Verkilambi",
            "Vettaikaranpudur",
            "Vettavalam",
            "Vijayapuri",
            "Vikramasingapuram",
            "Vikravandi",
            "Vilangudi",
            "Vilankurichi",
            "Vilapakkam",
            "Vilathikulam",
            "Vilavur",
            "Villukuri",
            "Villupuram",
            "Viraganur",
            "Virakeralam",
            "Virakkalpudur",
            "Virapandi",
            "Virapandi Cantonment",
            "Virappanchatram",
            "Viravanallur",
            "Virudambattu",
            "Virudhachalam",
            "Virudhunagar",
            "Virupakshipuram",
            "Viswanatham",
            "Vriddhachalam",
            "Walajabad",
            "Walajapet",
            "Wellington",
            "Yercaud",
            "Zamin Uthukuli"
          ],
          "StateName": "Tamil Nadu"
        },
        {
          "Cities": [
            "Achampet",
            "Adilabad",
            "Armoor",
            "Asifabad",
            "Badepally",
            "Banswada",
            "Bellampalli",
            "Bhadrachalam",
            "Bhainsa",
            "Bhongir",
            "Bhupalpally",
            "Bodhan",
            "Bollaram",
            "Devarkonda",
            "Farooqnagar",
            "Gadwal",
            "Gajwel",
            "Ghatkesar",
            "Hyderabad",
            "Jagtial",
            "Jangaon",
            "Kagaznagar",
            "Kalwakurthy",
            "Kamareddy",
            "Karimnagar",
            "Khammam",
            "Kodada",
            "Koratla",
            "Kottagudem",
            "Kyathampalle",
            "Madhira",
            "Mahabubabad",
            "Mahbubnagar",
            "Mancherial",
            "Mandamarri",
            "Manuguru",
            "Medak",
            "Medchal",
            "Miryalaguda",
            "Nagar Karnul",
            "Nakrekal",
            "Nalgonda",
            "Narayanpet",
            "Narsampet",
            "Nirmal",
            "Nizamabad",
            "Palwancha",
            "Peddapalli",
            "Ramagundam",
            "Ranga Reddy district",
            "Sadasivpet",
            "Sangareddy",
            "Sarapaka",
            "Sathupalle",
            "Secunderabad",
            "Siddipet",
            "Singapur",
            "Sircilla",
            "Suryapet",
            "Tandur",
            "Vemulawada",
            "Vikarabad",
            "Wanaparthy",
            "Warangal",
            "Yellandu",
            "Zahirabad"
          ],
          "StateName": "Telangana"
        },
        {
          "Cities": [
            "Agartala",
            "Amarpur",
            "Ambassa",
            "Badharghat",
            "Belonia",
            "Dharmanagar",
            "Gakulnagar",
            "Gandhigram",
            "Indranagar",
            "Jogendranagar",
            "Kailasahar",
            "Kamalpur",
            "Kanchanpur",
            "Khowai",
            "Kumarghat",
            "Kunjaban",
            "Narsingarh",
            "Pratapgarh",
            "Ranir Bazar",
            "Sabrum",
            "Sonamura",
            "Teliamura",
            "Udaipur"
          ],
          "StateName": "Tripura"
        },
        {
          "Cities": [
            "Achhalda",
            "Achhnera",
            "Adari",
            "Afzalgarh",
            "Agarwal Mandi",
            "Agra",
            "Agra Cantonment",
            "Ahraura",
            "Ailum",
            "Air Force Area",
            "Ajhuwa",
            "Akbarpur",
            "Alapur",
            "Aliganj",
            "Aligarh",
            "Allahabad",
            "Allahabad Cantonment",
            "Allahganj",
            "Amanpur",
            "Ambahta",
            "Amethi",
            "Amila",
            "Amilo",
            "Aminagar Sarai",
            "Aminagar Urf Bhurbaral",
            "Amraudha",
            "Amroha",
            "Anandnagar",
            "Anpara",
            "Antu",
            "Anupshahr",
            "Aonla",
            "Armapur Estate",
            "Ashokpuram",
            "Ashrafpur Kichhauchha",
            "Atarra",
            "Atasu",
            "Atrauli",
            "Atraulia",
            "Auraiya",
            "Aurangabad",
            "Aurangabad Bangar",
            "Auras",
            "Awagarh",
            "Ayodhya",
            "Azamgarh",
            "Azizpur",
            "Azmatgarh",
            "Babarpur Ajitmal",
            "Baberu",
            "Babina",
            "Babrala",
            "Babugarh",
            "Bachhiowan",
            "Bachhraon",
            "Bad",
            "Badaun",
            "Baghpat",
            "Bah",
            "Bahadurganj",
            "Baheri",
            "Bahjoi",
            "Bahraich",
            "Bahsuma",
            "Bahua",
            "Bajna",
            "Bakewar",
            "Bakiabad",
            "Baldeo",
            "Ballia",
            "Balrampur",
            "Banat",
            "Banda",
            "Bangarmau",
            "Banki",
            "Bansdih",
            "Bansgaon",
            "Bansi",
            "Barabanki",
            "Baragaon",
            "Baraut",
            "Bareilly",
            "Bareilly Cantonment",
            "Barhalganj",
            "Barhani",
            "Barhapur",
            "Barkhera",
            "Barsana",
            "Barva Sagar",
            "Barwar",
            "Basti",
            "Begumabad Budhana",
            "Behat",
            "Behta Hajipur",
            "Bela",
            "Belthara",
            "Beniganj",
            "Beswan",
            "Bewar",
            "Bhadarsa",
            "Bhadohi",
            "Bhagwantnagar",
            "Bharatganj",
            "Bhargain",
            "Bharthana",
            "Bharuhana",
            "Bharwari",
            "Bhatni Bazar",
            "Bhatpar Rani",
            "Bhawan Bahadurnagar",
            "Bhinga",
            "Bhojpur Dharampur",
            "Bhokarhedi",
            "Bhongaon",
            "Bhulepur",
            "Bidhuna",
            "Bighapur",
            "Bijnor",
            "Bijpur",
            "Bikapur",
            "Bilari",
            "Bilaspur",
            "Bilgram",
            "Bilhaur",
            "Bilram",
            "Bilrayaganj",
            "Bilsanda",
            "Bilsi",
            "Bindki",
            "Bisalpur",
            "Bisanda Buzurg",
            "Bisauli",
            "Bisharatganj",
            "Bisokhar",
            "Biswan",
            "Bithur",
            "Budaun",
            "Bugrasi",
            "Bulandshahar",
            "Burhana",
            "Chail",
            "Chak Imam Ali",
            "Chakeri",
            "Chakia",
            "Chandauli",
            "Chandausi",
            "Chandpur",
            "Charkhari",
            "Charthawal",
            "Chaumuhan",
            "Chhaprauli",
            "Chhara Rafatpur",
            "Chharprauli",
            "Chhata",
            "Chhatari",
            "Chhibramau",
            "Chhutmalpur",
            "Chilkana Sultanpur",
            "Chirgaon",
            "Chit Baragaon",
            "Chitrakut Dham",
            "Chopan",
            "Choubepur Kalan",
            "Chunar",
            "Churk Ghurma",
            "Colonelganj",
            "Dadri",
            "Dalmau",
            "Dankaur",
            "Dariyabad",
            "Dasna",
            "Dataganj",
            "Daurala",
            "Dayal Bagh",
            "Deoband",
            "Deoranian",
            "Deoria",
            "Dewa",
            "Dhampur",
            "Dhanauha",
            "Dhanauli",
            "Dhanaura",
            "Dharoti Khurd",
            "Dhauratanda",
            "Dhaurhra",
            "Dibai",
            "Dibiyapur",
            "Dildarnagar Fatehpur",
            "Do Ghat",
            "Dohrighat",
            "Dostpur",
            "Dudhinagar",
            "Dulhipur",
            "Dundwaraganj",
            "Ekdil",
            "Erich",
            "Etah",
            "Etawah",
            "Faizabad",
            "Faizabad Cantonment",
            "Faizganj",
            "Farah",
            "Faridnagar",
            "Faridpur",
            "Faridpur Cantonment",
            "Fariha",
            "Farrukhabad",
            "Fatehabad",
            "Fatehganj Pashchimi",
            "Fatehganj Purvi",
            "Fatehgarh",
            "Fatehpur",
            "Fatehpur Chaurasi",
            "Fatehpur Sikri",
            "Firozabad",
            "Gajraula",
            "Ganga Ghat",
            "Gangapur",
            "Gangoh",
            "Ganj Muradabad",
            "Garautha",
            "Garhi Pukhta",
            "Garhmukteshwar",
            "Gaura Barahaj",
            "Gauri Bazar",
            "Gausganj",
            "Gawan",
            "Ghatampur",
            "Ghaziabad",
            "Ghazipur",
            "Ghiror",
            "Ghorawal",
            "Ghosi",
            "Ghosia Bazar",
            "Ghughuli",
            "Gohand",
            "Gokul",
            "Gola Bazar",
            "Gola Gokarannath",
            "Gonda",
            "Gopamau",
            "Gopiganj",
            "Gorakhpur",
            "Gosainganj",
            "Govardhan",
            "Greater Noida",
            "Gulaothi",
            "Gulariya",
            "Gulariya Bhindara",
            "Gunnaur",
            "Gursahaiganj",
            "Gursarai",
            "Gyanpur",
            "Hafizpur",
            "Haidergarh",
            "Haldaur",
            "Hamirpur",
            "Handia",
            "Hapur",
            "Hardoi",
            "Harduaganj",
            "Hargaon",
            "Hariharpur",
            "Harraiya",
            "Hasanpur",
            "Hasayan",
            "Hastinapur",
            "Hata",
            "Hathras",
            "Hyderabad",
            "Ibrahimpur",
            "Iglas",
            "Ikauna",
            "Iltifatganj Bazar",
            "Indian Telephone Industry Mank",
            "Islamnagar",
            "Itaunja",
            "Itimadpur",
            "Jagner",
            "Jahanabad",
            "Jahangirabad",
            "Jahangirpur",
            "Jais",
            "Jaithara",
            "Jalalabad",
            "Jalali",
            "Jalalpur",
            "Jalaun",
            "Jalesar",
            "Jamshila",
            "Jangipur",
            "Jansath",
            "Jarwal",
            "Jasrana",
            "Jaswantnagar",
            "Jatari",
            "Jaunpur",
            "Jewar",
            "Jhalu",
            "Jhansi",
            "Jhansi Cantonment",
            "Jhansi Railway Settlement",
            "Jhinjhak",
            "Jhinjhana",
            "Jhusi",
            "Jhusi Kohna",
            "Jiyanpur",
            "Joya",
            "Jyoti Khuria",
            "Jyotiba Phule Nagar",
            "Kabrai",
            "Kachhauna Patseni",
            "Kachhla",
            "Kachhwa",
            "Kadaura",
            "Kadipur",
            "Kailashpur",
            "Kaimganj",
            "Kairana",
            "Kakgaina",
            "Kakod",
            "Kakori",
            "Kakrala",
            "Kalinagar",
            "Kalpi",
            "Kamalganj",
            "Kampil",
            "Kandhla",
            "Kandwa",
            "Kannauj",
            "Kanpur",
            "Kant",
            "Kanth",
            "Kaptanganj",
            "Karaon",
            "Karari",
            "Karhal",
            "Karnawal",
            "Kasganj",
            "Katariya",
            "Katghar Lalganj",
            "Kathera",
            "Katra",
            "Katra Medniganj",
            "Kauriaganj",
            "Kemri",
            "Kerakat",
            "Khadda",
            "Khaga",
            "Khailar",
            "Khair",
            "Khairabad",
            "Khairagarh",
            "Khalilabad",
            "Khamaria",
            "Khanpur",
            "Kharela",
            "Khargupur",
            "Khariya",
            "Kharkhoda",
            "Khatauli",
            "Khatauli Rural",
            "Khekra",
            "Kheri",
            "Kheta Sarai",
            "Khudaganj",
            "Khurja",
            "Khutar",
            "Kiraoli",
            "Kiratpur",
            "Kishanpur",
            "Kishni",
            "Kithaur",
            "Koiripur",
            "Konch",
            "Kopaganj",
            "Kora Jahanabad",
            "Korwa",
            "Kosi Kalan",
            "Kota",
            "Kotra",
            "Kotwa",
            "Kulpahar",
            "Kunda",
            "Kundarki",
            "Kunwargaon",
            "Kurara",
            "Kurawali",
            "Kursath",
            "Kurthi Jafarpur",
            "Kushinagar",
            "Kusmara",
            "Laharpur",
            "Lakhimpur",
            "Lakhna",
            "Lalganj",
            "Lalitpur",
            "Lar",
            "Lawar",
            "Ledwa Mahuwa",
            "Lohta",
            "Loni",
            "Lucknow",
            "Machhlishahr",
            "Madhoganj",
            "Madhogarh",
            "Maghar",
            "Mahaban",
            "Maharajganj",
            "Mahmudabad",
            "Mahoba",
            "Maholi",
            "Mahona",
            "Mahroni",
            "Mailani",
            "Mainpuri",
            "Majhara Pipar Ehatmali",
            "Majhauli Raj",
            "Malihabad",
            "Mallanwam",
            "Mandawar",
            "Manikpur",
            "Maniyar",
            "Manjhanpur",
            "Mankapur",
            "Marehra",
            "Mariahu",
            "Maruadih",
            "Maswasi",
            "Mataundh",
            "Mathu",
            "Mathura",
            "Mathura Cantonment",
            "Mau",
            "Mau Aima",
            "Maudaha",
            "Mauranipur",
            "Maurawan",
            "Mawana",
            "Meerut",
            "Mehnagar",
            "Mehndawal",
            "Mendu",
            "Milak",
            "Miranpur",
            "Mirat",
            "Mirat Cantonment",
            "Mirganj",
            "Mirzapur",
            "Misrikh",
            "Modinagar",
            "Mogra Badshahpur",
            "Mohan",
            "Mohanpur",
            "Mohiuddinpur",
            "Moradabad",
            "Moth",
            "Mubarakpur",
            "Mughal Sarai",
            "Mughal Sarai Railway Settlemen",
            "Muhammadabad",
            "Muhammadi",
            "Mukrampur Khema",
            "Mundia",
            "Mundora",
            "Muradnagar",
            "Mursan",
            "Musafirkhana",
            "Muzaffarnagar",
            "Nadigaon",
            "Nagina",
            "Nagram",
            "Nai Bazar",
            "Nainana Jat",
            "Najibabad",
            "Nakur",
            "Nanaunta",
            "Nandgaon",
            "Nanpara",
            "Naraini",
            "Narauli",
            "Naraura",
            "Naugawan Sadat",
            "Nautanwa",
            "Nawabganj",
            "Nichlaul",
            "Nidhauli Kalan",
            "Nihtaur",
            "Nindaura",
            "Niwari",
            "Nizamabad",
            "Noida",
            "Northern Railway Colony",
            "Nurpur",
            "Nyoria Husenpur",
            "Nyotini",
            "Obra",
            "Oel Dhakwa",
            "Orai",
            "Oran",
            "Ordinance Factory Muradnagar",
            "Pachperwa",
            "Padrauna",
            "Pahasu",
            "Paintepur",
            "Pali",
            "Palia Kalan",
            "Parasi",
            "Parichha",
            "Parichhatgarh",
            "Parsadepur",
            "Patala",
            "Patiyali",
            "Patti",
            "Pawayan",
            "Phalauda",
            "Phaphund",
            "Phulpur",
            "Phulwaria",
            "Pihani",
            "Pilibhit",
            "Pilkana",
            "Pilkhuwa",
            "Pinahat",
            "Pipalsana Chaudhari",
            "Pipiganj",
            "Pipraich",
            "Pipri",
            "Pratapgarh",
            "Pukhrayan",
            "Puranpur",
            "Purdil Nagar",
            "Purqazi",
            "Purwa",
            "Qasimpur",
            "Rabupura",
            "Radha Kund",
            "Rae Bareilly",
            "Raja Ka Rampur",
            "Rajapur",
            "Ramkola",
            "Ramnagar",
            "Rampur",
            "Rampur Bhawanipur",
            "Rampur Karkhana",
            "Rampur Maniharan",
            "Rampura",
            "Ranipur",
            "Rashidpur Garhi",
            "Rasra",
            "Rasulabad",
            "Rath",
            "Raya",
            "Renukut",
            "Reoti",
            "Richha",
            "Risia Bazar",
            "Rithora",
            "Robertsganj",
            "Roza",
            "Rudarpur",
            "Rudauli",
            "Rudayan",
            "Rura",
            "Rustamnagar Sahaspur",
            "Sabatwar",
            "Sadabad",
            "Sadat",
            "Safipur",
            "Sahanpur",
            "Saharanpur",
            "Sahaspur",
            "Sahaswan",
            "Sahawar",
            "Sahibabad",
            "Sahjanwa",
            "Sahpau",
            "Saidpur",
            "Sainthal",
            "Saiyadraja",
            "Sakhanu",
            "Sakit",
            "Salarpur Khadar",
            "Salimpur",
            "Salon",
            "Sambhal",
            "Sambhawali",
            "Samdhan",
            "Samthar",
            "Sandi",
            "Sandila",
            "Sarai Mir",
            "Sarai akil",
            "Sarauli",
            "Sardhana",
            "Sarila",
            "Sarsawan",
            "Sasni",
            "Satrikh",
            "Saunkh",
            "Saurikh",
            "Seohara",
            "Sewal Khas",
            "Sewarhi",
            "Shahabad",
            "Shahganj",
            "Shahi",
            "Shahjahanpur",
            "Shahjahanpur Cantonment",
            "Shahpur",
            "Shamli",
            "Shamsabad",
            "Shankargarh",
            "Shergarh",
            "Sherkot",
            "Shikarpur",
            "Shikohabad",
            "Shisgarh",
            "Shivdaspur",
            "Shivli",
            "Shivrajpur",
            "Shohratgarh",
            "Siddhanur",
            "Siddharthnagar",
            "Sidhauli",
            "Sidhpura",
            "Sikandarabad",
            "Sikandarpur",
            "Sikandra",
            "Sikandra Rao",
            "Singahi Bhiraura",
            "Sirathu",
            "Sirsa",
            "Sirsaganj",
            "Sirsi",
            "Sisauli",
            "Siswa Bazar",
            "Sitapur",
            "Siyana",
            "Som",
            "Sonbhadra",
            "Soron",
            "Suar",
            "Sukhmalpur Nizamabad",
            "Sultanpur",
            "Sumerpur",
            "Suriyawan",
            "Swamibagh",
            "Tajpur",
            "Talbahat",
            "Talgram",
            "Tambaur",
            "Tanda",
            "Tatarpur Lallu",
            "Tetribazar",
            "Thakurdwara",
            "Thana Bhawan",
            "Thiriya Nizamat Khan",
            "Tikaitnagar",
            "Tikri",
            "Tilhar",
            "Tindwari",
            "Tirwaganj",
            "Titron",
            "Tori Fatehpur",
            "Tulsipur",
            "Tundla",
            "Tundla Kham",
            "Tundla Railway Colony",
            "Ugu",
            "Ujhani",
            "Ujhari",
            "Umri",
            "Umri Kalan",
            "Un",
            "Unchahar",
            "Unnao",
            "Usaihat",
            "Usawan",
            "Utraula",
            "Varanasi",
            "Varanasi Cantonment",
            "Vijaigarh",
            "Vrindavan",
            "Wazirganj",
            "Zafarabad",
            "Zaidpur",
            "Zamania"
          ],
          "StateName": "Uttar Pradesh"
        },
        {
          "Cities": [
            "Almora",
            "Almora Cantonment",
            "Badrinathpuri",
            "Bageshwar",
            "Bah Bazar",
            "Banbasa",
            "Bandia",
            "Barkot",
            "Bazpur",
            "Bhim Tal",
            "Bhowali",
            "Chakrata",
            "Chamba",
            "Chamoli and Gopeshwar",
            "Champawat",
            "Clement Town",
            "Dehra Dun Cantonment",
            "Dehradun",
            "Dehrakhas",
            "Devaprayag",
            "Dhaluwala",
            "Dhandera",
            "Dharchula",
            "Dharchula Dehat",
            "Didihat",
            "Dineshpur",
            "Doiwala",
            "Dugadda",
            "Dwarahat",
            "Gadarpur",
            "Gangotri",
            "Gauchar",
            "Haldwani",
            "Haridwar",
            "Herbertpur",
            "Jaspur",
            "Jhabrera",
            "Joshimath",
            "Kachnal Gosain",
            "Kaladungi",
            "Kalagarh",
            "Karnaprayang",
            "Kashipur",
            "Kashirampur",
            "Kausani",
            "Kedarnath",
            "Kelakhera",
            "Khatima",
            "Kichha",
            "Kirtinagar",
            "Kotdwara",
            "Laksar",
            "Lalkuan",
            "Landaura",
            "Landhaura Cantonment",
            "Lensdaun",
            "Logahat",
            "Mahua Dabra Haripura",
            "Mahua Kheraganj",
            "Manglaur",
            "Masuri",
            "Mohanpur Mohammadpur",
            "Muni Ki Reti",
            "Nagla",
            "Nainital",
            "Nainital Cantonment",
            "Nandaprayang",
            "Narendranagar",
            "Pauri",
            "Pithoragarh",
            "Pratitnagar",
            "Raipur",
            "Raiwala",
            "Ramnagar",
            "Ranikhet",
            "Ranipur",
            "Rishikesh",
            "Rishikesh Cantonment",
            "Roorkee",
            "Rudraprayag",
            "Rudrapur",
            "Rurki",
            "Rurki Cantonment",
            "Shaktigarh",
            "Sitarganj",
            "Srinagar",
            "Sultanpur",
            "Tanakpur",
            "Tehri",
            "Udham Singh Nagar",
            "Uttarkashi",
            "Vikasnagar",
            "Virbhadra"
          ],
          "StateName": "Uttarakhand"
        },
        {
          "Cities": [],
          "StateName": "Vaishali"
        },
        {
          "Cities": [
            "24 Parganas (n)",
            "24 Parganas (s)",
            "Adra",
            "Ahmadpur",
            "Aiho",
            "Aistala",
            "Alipur Duar",
            "Alipur Duar Railway Junction",
            "Alpur",
            "Amalhara",
            "Amkula",
            "Amlagora",
            "Amodghata",
            "Amtala",
            "Andul",
            "Anksa",
            "Ankurhati",
            "Anup Nagar",
            "Arambagh",
            "Argari",
            "Arsha",
            "Asansol",
            "Ashoknagar Kalyangarh",
            "Aurangabad",
            "Bablari Dewanganj",
            "Badhagachhi",
            "Baduria",
            "Baghdogra",
            "Bagnan",
            "Bagra",
            "Bagula",
            "Baharampur",
            "Bahirgram",
            "Bahula",
            "Baidyabati",
            "Bairatisal",
            "Baj Baj",
            "Bakreswar",
            "Balaram Pota",
            "Balarampur",
            "Bali Chak",
            "Ballavpur",
            "Bally",
            "Balurghat",
            "Bamunari",
            "Banarhat Tea Garden",
            "Bandel",
            "Bangaon",
            "Bankra",
            "Bankura",
            "Bansbaria",
            "Banshra",
            "Banupur",
            "Bara Bamonia",
            "Barakpur",
            "Barakpur Cantonment",
            "Baranagar",
            "Barasat",
            "Barddhaman",
            "Barijhati",
            "Barjora",
            "Barrackpore",
            "Baruihuda",
            "Baruipur",
            "Barunda",
            "Basirhat",
            "Baska",
            "Begampur",
            "Beldanga",
            "Beldubi",
            "Belebathan",
            "Beliator",
            "Bhadreswar",
            "Bhandardaha",
            "Bhangar Raghunathpur",
            "Bhangri Pratham Khanda",
            "Bhanowara",
            "Bhatpara",
            "Bholar Dabri",
            "Bidhannagar",
            "Bidyadharpur",
            "Biki Hakola",
            "Bilandapur",
            "Bilpahari",
            "Bipra Noapara",
            "Birlapur",
            "Birnagar",
            "Bisarpara",
            "Bishnupur",
            "Bolpur",
            "Bongaon",
            "Bowali",
            "Burdwan",
            "Canning",
            "Cart Road",
            "Chachanda",
            "Chak Bankola",
            "Chak Enayetnagar",
            "Chak Kashipur",
            "Chakalampur",
            "Chakbansberia",
            "Chakdaha",
            "Chakpara",
            "Champahati",
            "Champdani",
            "Chamrail",
            "Chandannagar",
            "Chandpur",
            "Chandrakona",
            "Chapari",
            "Chapui",
            "Char Brahmanagar",
            "Char Maijdia",
            "Charka",
            "Chata Kalikapur",
            "Chauhati",
            "Checha Khata",
            "Chelad",
            "Chhora",
            "Chikrand",
            "Chittaranjan",
            "Contai",
            "Cooch Behar",
            "Dainhat",
            "Dakshin Baguan",
            "Dakshin Jhapardaha",
            "Dakshin Rajyadharpur",
            "Dakshin Raypur",
            "Dalkola",
            "Dalurband",
            "Darap Pur",
            "Darjiling",
            "Daulatpur",
            "Debipur",
            "Defahat",
            "Deora",
            "Deulia",
            "Dhakuria",
            "Dhandadihi",
            "Dhanyakuria",
            "Dharmapur",
            "Dhatri Gram",
            "Dhuilya",
            "Dhulagari",
            "Dhulian",
            "Dhupgari",
            "Dhusaripara",
            "Diamond Harbour",
            "Digha",
            "Dignala",
            "Dinhata",
            "Dubrajpur",
            "Dumjor",
            "Durgapur",
            "Durllabhganj",
            "Egra",
            "Eksara",
            "Falakata",
            "Farakka",
            "Fatellapur",
            "Fort Gloster",
            "Gabberia",
            "Gadigachha",
            "Gairkata",
            "Gangarampur",
            "Garalgachha",
            "Garbeta Amlagora",
            "Garhbeta",
            "Garshyamnagar",
            "Garui",
            "Garulia",
            "Gayespur",
            "Ghatal",
            "Ghorsala",
            "Goaljan",
            "Goasafat",
            "Gobardanga",
            "Gobindapur",
            "Gopalpur",
            "Gopinathpur",
            "Gora Bazar",
            "Guma",
            "Gurdaha",
            "Guriahati",
            "Guskhara",
            "Habra",
            "Haldia",
            "Haldibari",
            "Halisahar",
            "Haora",
            "Harharia Chak",
            "Harindanga",
            "Haringhata",
            "Haripur",
            "Harishpur",
            "Hatgachha",
            "Hatsimla",
            "Hijuli",
            "Hindustan Cables Town",
            "Hooghly",
            "Howrah",
            "Hugli-Chunchura",
            "Humaipur",
            "Ichha Pur Defence Estate",
            "Ingraj Bazar",
            "Islampur",
            "Jafarpur",
            "Jagadanandapur",
            "Jagdishpur",
            "Jagtaj",
            "Jala Kendua",
            "Jaldhaka",
            "Jalkhura",
            "Jalpaiguri",
            "Jamuria",
            "Jangipur",
            "Jaygaon",
            "Jaynagar-Majilpur",
            "Jemari",
            "Jemari Township",
            "Jetia",
            "Jhalida",
            "Jhargram",
            "Jhorhat",
            "Jiaganj-Azimganj",
            "Joka",
            "Jot Kamal",
            "Kachu Pukur",
            "Kajora",
            "Kakdihi",
            "Kakdwip",
            "Kalaikunda",
            "Kalara",
            "Kalimpong",
            "Kaliyaganj",
            "Kalna",
            "Kalyani",
            "Kamarhati",
            "Kanaipur",
            "Kanchrapara",
            "Kandi",
            "Kanki",
            "Kankuria",
            "Kantlia",
            "Kanyanagar",
            "Karimpur",
            "Karsiyang",
            "Kasba",
            "Kasimbazar",
            "Katwa",
            "Kaugachhi",
            "Kenda",
            "Kendra Khottamdi",
            "Kendua",
            "Kesabpur",
            "Khagrabari",
            "Khalia",
            "Khalor",
            "Khandra",
            "Khantora",
            "Kharagpur",
            "Kharagpur Railway Settlement",
            "Kharar",
            "Khardaha",
            "Khari Mala Khagrabari",
            "Kharsarai",
            "Khatra",
            "Khodarampur",
            "Kodalia",
            "Kolaghat",
            "Kolaghat Thermal Power Project",
            "Kolkata",
            "Konardihi",
            "Konnogar",
            "Krishnanagar",
            "Krishnapur",
            "Kshidirpur",
            "Kshirpai",
            "Kulihanda",
            "Kulti",
            "Kunustara",
            "Kuperskem",
            "Madanpur",
            "Madhusudanpur",
            "Madhyamgram",
            "Maheshtala",
            "Mahiari",
            "Mahikpur",
            "Mahira",
            "Mahishadal",
            "Mainaguri",
            "Makardaha",
            "Mal",
            "Malda",
            "Mandarbani",
            "Mansinhapur",
            "Masila",
            "Maslandapur",
            "Mathabhanga",
            "Mekliganj",
            "Memari",
            "Midnapur",
            "Mirik",
            "Monoharpur",
            "Mrigala",
            "Muragachha",
            "Murgathaul",
            "Murshidabad",
            "Nabadhai Dutta Pukur",
            "Nabagram",
            "Nabgram",
            "Nachhratpur Katabari",
            "Nadia",
            "Naihati",
            "Nalhati",
            "Nasra",
            "Natibpur",
            "Naupala",
            "Navadwip",
            "Nebadhai Duttapukur",
            "New Barrackpore",
            "Ni Barakpur",
            "Nibra",
            "Noapara",
            "Nokpul",
            "North Barakpur",
            "Odlabari",
            "Old Maldah",
            "Ondal",
            "Pairagachha",
            "Palashban",
            "Panchla",
            "Panchpara",
            "Pandua",
            "Pangachhiya",
            "Paniara",
            "Panihati",
            "Panuhat",
            "Par Beliya",
            "Parashkol",
            "Parasia",
            "Parbbatipur",
            "Parui",
            "Paschim Jitpur",
            "Paschim Punro Para",
            "Patrasaer",
            "Pattabong Tea Garden",
            "Patuli",
            "Patulia",
            "Phulia",
            "Podara",
            "Port Blair",
            "Prayagpur",
            "Pujali",
            "Purba Medinipur",
            "Purba Tajpur",
            "Purulia",
            "Raghudebbati",
            "Raghudebpur",
            "Raghunathchak",
            "Raghunathpur",
            "Raghunathpur-Dankuni",
            "Raghunathpur-Magra",
            "Raigachhi",
            "Raiganj",
            "Raipur",
            "Rajarhat Gopalpur",
            "Rajpur",
            "Ramchandrapur",
            "Ramjibanpur",
            "Ramnagar",
            "Rampur Hat",
            "Ranaghat",
            "Raniganj",
            "Ratibati",
            "Raypur",
            "Rishra",
            "Rishra Cantonment",
            "Ruiya",
            "Sahajadpur",
            "Sahapur",
            "Sainthia",
            "Salap",
            "Sankarpur",
            "Sankrail",
            "Santoshpur",
            "Saontaidih",
            "Sarenga",
            "Sarpi",
            "Satigachha",
            "Serpur",
            "Shankhanagar",
            "Shantipur",
            "Shrirampur",
            "Siduli",
            "Siliguri",
            "Simla",
            "Singur",
            "Sirsha",
            "Siuri",
            "Sobhaganj",
            "Sodpur",
            "Sonamukhi",
            "Sonatikiri",
            "Srikantabati",
            "Srirampur",
            "Sukdal",
            "Taherpur",
            "Taki",
            "Talbandha",
            "Tamluk",
            "Tarakeswar",
            "Tentulberia",
            "Tentulkuli",
            "Thermal Power Project",
            "Tinsukia",
            "Titagarh",
            "Tufanganj",
            "Ukhra",
            "Ula",
            "Ulubaria",
            "Uttar Durgapur",
            "Uttar Goara",
            "Uttar Kalas",
            "Uttar Kamakhyaguri",
            "Uttar Latabari",
            "Uttar Mahammadpur",
            "Uttar Pirpur",
            "Uttar Raypur",
            "Uttarpara-Kotrung"
          ],
          "StateName": "West Bengal"
        }
      ],
      "CountryName": "India"
    },
    {
      "States": [
        {
          "Cities": [
            "Banda Aceh",
            "Bireun",
            "Langsa",
            "Lhokseumawe",
            "Meulaboh"
          ],
          "StateName": "Aceh"
        },
        {
          "Cities": [
            "Denpasar",
            "Karangasem",
            "Klungkung",
            "Kuta",
            "Negara",
            "Singaraja",
            "Tabanan",
            "Ubud"
          ],
          "StateName": "Bali"
        },
        {
          "Cities": [
            "Manggar",
            "Mentok",
            "Pangkal Pinang",
            "Sungai Liat",
            "Tanjung Pandan",
            "Toboali-Rias"
          ],
          "StateName": "Bangka-Belitung"
        },
        {
          "Cities": [
            "Cikupa",
            "Cilegon",
            "Ciputat",
            "Curug",
            "Kresek",
            "Labuhan",
            "Pandegelang",
            "Pondok Aren",
            "Rangkasbitung",
            "Serang",
            "Serpong",
            "Tangerang",
            "Teluknaga"
          ],
          "StateName": "Banten"
        },
        {
          "Cities": [
            "Bengkulu",
            "Curup"
          ],
          "StateName": "Bengkulu"
        },
        {
          "Cities": [
            "Gandaria"
          ],
          "StateName": "Gandaria"
        },
        {
          "Cities": [
            "Gorontalo"
          ],
          "StateName": "Gorontalo"
        },
        {
          "Cities": [
            "Cengkareng",
            "Jakarta"
          ],
          "StateName": "Jakarta"
        },
        {
          "Cities": [
            "Jambi",
            "Kualatungka",
            "Simpang",
            "Sungaipenuh"
          ],
          "StateName": "Jambi"
        },
        {
          "Cities": [],
          "StateName": "Jawa Barat"
        },
        {
          "Cities": [],
          "StateName": "Jawa Tengah"
        },
        {
          "Cities": [],
          "StateName": "Jawa Timur"
        },
        {
          "Cities": [],
          "StateName": "Kalimantan Barat"
        },
        {
          "Cities": [],
          "StateName": "Kalimantan Selatan"
        },
        {
          "Cities": [],
          "StateName": "Kalimantan Tengah"
        },
        {
          "Cities": [],
          "StateName": "Kalimantan Timur"
        },
        {
          "Cities": [
            "Kendal"
          ],
          "StateName": "Kendal"
        },
        {
          "Cities": [
            "Bandar Lampung",
            "Kota Bumi",
            "Metro",
            "Pringsewu",
            "Terbanggi Besar"
          ],
          "StateName": "Lampung"
        },
        {
          "Cities": [
            "Amahai",
            "Ambon",
            "Tual"
          ],
          "StateName": "Maluku"
        },
        {
          "Cities": [
            "Amahai",
            "Ambon",
            "Tual"
          ],
          "StateName": "Maluku Utara"
        },
        {
          "Cities": [],
          "StateName": "Nusa Tenggara Barat"
        },
        {
          "Cities": [],
          "StateName": "Nusa Tenggara Timur"
        },
        {
          "Cities": [
            "Aberpura",
            "Biak",
            "Jaya Pura",
            "Manokwari",
            "Merauke",
            "Sorong"
          ],
          "StateName": "Papua"
        },
        {
          "Cities": [
            "Balaipungut",
            "Bengkalis",
            "Dumai",
            "Duri",
            "Pekan Baru",
            "Selatpanjang",
            "Tanjung Balai-Meral",
            "Tembilahan"
          ],
          "StateName": "Riau"
        },
        {
          "Cities": [
            "Balaipungut",
            "Bengkalis",
            "Dumai",
            "Duri",
            "Pekan Baru",
            "Selatpanjang",
            "Tanjung Balai-Meral",
            "Tembilahan"
          ],
          "StateName": "Riau Kepulauan"
        },
        {
          "Cities": [
            "Solo"
          ],
          "StateName": "Solo"
        },
        {
          "Cities": [],
          "StateName": "Sulawesi Selatan"
        },
        {
          "Cities": [],
          "StateName": "Sulawesi Tengah"
        },
        {
          "Cities": [],
          "StateName": "Sulawesi Tenggara"
        },
        {
          "Cities": [],
          "StateName": "Sulawesi Utara"
        },
        {
          "Cities": [],
          "StateName": "Sumatera Barat"
        },
        {
          "Cities": [],
          "StateName": "Sumatera Selatan"
        },
        {
          "Cities": [],
          "StateName": "Sumatera Utara"
        },
        {
          "Cities": [
            "Bambanglipuro",
            "Banguntapan",
            "Bantul",
            "Depok",
            "Gamping",
            "Godean",
            "Jetis",
            "Kasihan",
            "Ngaglik",
            "Pandak",
            "Pundong",
            "Sewon",
            "Seyegan",
            "Sleman",
            "Srandakan",
            "Wonosari",
            "Yogyakarta"
          ],
          "StateName": "Yogyakarta"
        }
      ],
      "CountryName": "Indonesia"
    },
    {
      "States": [
        {
          "Cities": [
            "Ardabil",
            "Garmi",
            "Khalkhal",
            "Meshkinshahr",
            "Parsabad"
          ],
          "StateName": "Ardabil"
        },
        {
          "Cities": [],
          "StateName": "Azarbayjan-e Bakhtari"
        },
        {
          "Cities": [],
          "StateName": "Azarbayjan-e Khavari"
        },
        {
          "Cities": [
            "Bandar-e Gonaveh",
            "Borazjan",
            "Bushehr",
            "Dashti",
            "Dir",
            "Khormuj",
            "Kongan",
            "Tangestan"
          ],
          "StateName": "Bushehr"
        },
        {
          "Cities": [],
          "StateName": "Chahar Mahal-e Bakhtiari"
        },
        {
          "Cities": [
            "Ardistan",
            "Dorchehpiyaz",
            "Dowlatabad",
            "Esfahan",
            "Falavarjan",
            "Faridan",
            "Fereydunshahr",
            "Fuladshahr",
            "Golara",
            "Golpayegan",
            "Kashan",
            "Kelishad",
            "Khomeynishahr",
            "Khonsar",
            "Khuresgan",
            "Mobarakeh",
            "Na'in",
            "Najafabad",
            "Natnaz",
            "Qahdarijan",
            "Rehnan",
            "Semirom",
            "Shahinshahr",
            "Shahreza",
            "Zarinshahr"
          ],
          "StateName": "Esfahan"
        },
        {
          "Cities": [
            "Abadeh",
            "Akbarabad",
            "Darab",
            "Eqlid",
            "Estehban",
            "Fasa",
            "Firuzabad",
            "Gerash",
            "Jahrom",
            "Kazerun",
            "Lar",
            "Marv Dasht",
            "Neyriz",
            "Nurabad",
            "Qa'emiyeh",
            "Sepidan",
            "Shiraz"
          ],
          "StateName": "Fars"
        },
        {
          "Cities": [
            "Astaneh-ye Ashrafiyeh",
            "Astara",
            "Bandar-e Anzali",
            "Faman",
            "Hashtpar",
            "Lahijan",
            "Langarud",
            "Rasht",
            "Rudbar",
            "Rudsar",
            "Sawma'eh Sara"
          ],
          "StateName": "Gilan"
        },
        {
          "Cities": [
            "Aq Qal'eh",
            "Azad Shahr",
            "Bandar-e Torkaman",
            "Gonbad-e Qabus",
            "Gorgan"
          ],
          "StateName": "Golestan"
        },
        {
          "Cities": [
            "Asadabad",
            "Bahar",
            "Hamadan",
            "Malayer",
            "Nahavand",
            "Tuysarkan"
          ],
          "StateName": "Hamadan"
        },
        {
          "Cities": [
            "Bandar Abbas",
            "Bandar-e 'Abbas",
            "Bandar-e Lengeh",
            "Gheshm",
            "Jask",
            "Kish",
            "Kish Island",
            "Minab"
          ],
          "StateName": "Hormozgan"
        },
        {
          "Cities": [
            "Abdanan",
            "Darrehshahr",
            "Dehloran",
            "Ilam",
            "Ivan",
            "Mehran"
          ],
          "StateName": "Ilam"
        },
        {
          "Cities": [
            "Baft",
            "Bam",
            "Bardsir",
            "Jiroft",
            "Kahnuj",
            "Kerman",
            "Rafsanjan",
            "Ravar",
            "Shahr-e Babak",
            "Sirjan",
            "Zarand"
          ],
          "StateName": "Kerman"
        },
        {
          "Cities": [
            "Eslamabad",
            "Gilan-e Garb",
            "Harsin",
            "Javanrud",
            "Kangavar",
            "Kermanshah",
            "Paveh",
            "Sahneh",
            "Sar-e-Pol-e-Zohab",
            "Sonqor"
          ],
          "StateName": "Kermanshah"
        },
        {
          "Cities": [
            "Birjand",
            "Bojnurd",
            "Chenaran",
            "Darreh Gaz",
            "Esfarayen",
            "Fariman",
            "Ferdus",
            "Gha'nat",
            "Gonabad",
            "Kashmar",
            "Mashad",
            "Mashhad",
            "Neyshabur",
            "Qayen",
            "Quchan",
            "Sabzevar",
            "Sarakhs",
            "Shirvan",
            "Tabas",
            "Tayyebat",
            "Torbat-e Heydariyeh",
            "Torbat-e Jam"
          ],
          "StateName": "Khorasan"
        },
        {
          "Cities": [
            "Abadan",
            "Agha Jari",
            "Ahvaz",
            "Ahwaz",
            "Andimeshk",
            "Bandar-e Emam Khomeyni",
            "Bandar-e Mahshahr",
            "Behbahan",
            "Dezful",
            "Ezeh",
            "Hendijan",
            "Khorramshahr",
            "Masjed-e Soleyman",
            "Omidiyeh",
            "Ramhormoz",
            "Ramshir",
            "Shadegan",
            "Shush",
            "Shushtar",
            "Susangerd"
          ],
          "StateName": "Khuzestan"
        },
        {
          "Cities": [],
          "StateName": "Kohgiluyeh-e Boyerahmad"
        },
        {
          "Cities": [
            "Baneh",
            "Bijar",
            "Kamyaran",
            "Marivan",
            "Qorveh",
            "Sanandaj",
            "Saqqez"
          ],
          "StateName": "Kordestan"
        },
        {
          "Cities": [
            "Alashtar",
            "Aligudarz",
            "Azna",
            "Borujerd",
            "Do Rud",
            "Khorramabad",
            "Kuhdasht",
            "Nurabad"
          ],
          "StateName": "Lorestan"
        },
        {
          "Cities": [
            "Arak",
            "Ashtian",
            "Delijan",
            "Khomeyn",
            "Mahallat",
            "Sarband",
            "Saveh",
            "Tafresh"
          ],
          "StateName": "Markazi"
        },
        {
          "Cities": [
            "Aliabad",
            "Amir Kala",
            "Amol",
            "Babol",
            "Babol Sar",
            "Behshahr",
            "Chalus",
            "Fereydunkenar",
            "Juybar",
            "Kalaleh",
            "Kordkuy",
            "Mahmudabad",
            "Minudasht",
            "Neka",
            "Nur",
            "Nushahr",
            "Qa'emshahr",
            "Ramsar",
            "Sari",
            "Savadkuh",
            "Tonekabon"
          ],
          "StateName": "Mazandaran"
        },
        {
          "Cities": [],
          "StateName": "Ostan-e Esfahan"
        },
        {
          "Cities": [
            "Abhar",
            "Abyek",
            "Qazvin",
            "Takestan"
          ],
          "StateName": "Qazvin"
        },
        {
          "Cities": [
            "Qom"
          ],
          "StateName": "Qom"
        },
        {
          "Cities": [
            "Damghan",
            "Garmsar",
            "Semnan",
            "Shahrud"
          ],
          "StateName": "Semnan"
        },
        {
          "Cities": [],
          "StateName": "Sistan-e Baluchestan"
        },
        {
          "Cities": [
            "Damavand",
            "Eqbaliyeh",
            "Eslamshahr",
            "Hashtgerd",
            "Karaj",
            "Mahdasht",
            "Malard",
            "Mohammadiyeh",
            "Nazarabad",
            "Pakdasht",
            "Pishva",
            "Qarchak",
            "Qods",
            "Robat Karim",
            "Shahriyar",
            "Tehran",
            "Varamin"
          ],
          "StateName": "Tehran"
        },
        {
          "Cities": [
            "Ardakan",
            "Bafq",
            "Mehriz",
            "Meybod",
            "Taft",
            "Yazd"
          ],
          "StateName": "Yazd"
        },
        {
          "Cities": [
            "Alvand",
            "Khorramdarreh",
            "Zanjan"
          ],
          "StateName": "Zanjan"
        }
      ],
      "CountryName": "Iran"
    },
    {
      "States": [
        {
          "Cities": [
            "Jurf-as-Sakhr",
            "Saddat-al-Hindiyah",
            "al-Hillah",
            "al-Madhatiyah",
            "al-Musayyib",
            "al-Qasim"
          ],
          "StateName": "Babil"
        },
        {
          "Cities": [
            "Baghdad"
          ],
          "StateName": "Baghdad"
        },
        {
          "Cities": [
            "Dahuk",
            "Zakhu"
          ],
          "StateName": "Dahuk"
        },
        {
          "Cities": [],
          "StateName": "Dhi Qar"
        },
        {
          "Cities": [
            "Ba'qubah",
            "Hanaqin",
            "Jalula''",
            "Kifri",
            "Mandali",
            "al-Fuhud",
            "al-Khalis",
            "al-Miqdadiyah",
            "ash-Shatrah"
          ],
          "StateName": "Diyala"
        },
        {
          "Cities": [
            "Ankawa"
          ],
          "StateName": "Erbil"
        },
        {
          "Cities": [
            "Chaqalawa",
            "Irbil",
            "Rawanduz"
          ],
          "StateName": "Irbil"
        },
        {
          "Cities": [
            "Karbala",
            "al-Hindiyah"
          ],
          "StateName": "Karbala"
        },
        {
          "Cities": [
            "Erbil"
          ],
          "StateName": "Kurdistan"
        },
        {
          "Cities": [
            "'Ali al Gharbi",
            "al-'Amarah",
            "al-Majarr-al-Kabir"
          ],
          "StateName": "Maysan"
        },
        {
          "Cities": [
            "Qarah Qush",
            "Sinjar",
            "Tall 'Afar",
            "Tall Kayf",
            "al-Mawsil",
            "ash-Shaykhan"
          ],
          "StateName": "Ninawa"
        },
        {
          "Cities": [
            "Balad",
            "Bayji",
            "Dhalu'iyah",
            "Samarra",
            "Tikrit",
            "Tuz",
            "ad-Dujayl"
          ],
          "StateName": "Salah-ad-Din"
        },
        {
          "Cities": [
            "al-'Aziziyah",
            "al-Hayy",
            "al-Kut",
            "an-Nu'maniyah",
            "as-Suwayrah"
          ],
          "StateName": "Wasit"
        },
        {
          "Cities": [
            "'Anah",
            "Hit",
            "Rawah",
            "al-Fallujah",
            "al-Habbaniyah",
            "al-Hadithah",
            "ar-Ramadi",
            "ar-Rutbah"
          ],
          "StateName": "al-Anbar"
        },
        {
          "Cities": [
            "Abu al-Khasib",
            "Harithah",
            "Shatt-al-'Arab",
            "al-Basrah",
            "al-Faw",
            "al-Qurnah",
            "az-Zubayr"
          ],
          "StateName": "al-Basrah"
        },
        {
          "Cities": [
            "ar-Rumaythah",
            "as-Samawah"
          ],
          "StateName": "al-Muthanna"
        },
        {
          "Cities": [
            "'Afak",
            "ad-Diwaniyah",
            "al-Ghammas",
            "al-Hamzah",
            "ash-Shamiyah",
            "ash-Shinafiyah"
          ],
          "StateName": "al-Qadisiyah"
        },
        {
          "Cities": [
            "al-Kufah",
            "al-Mishkhab",
            "an-Najaf"
          ],
          "StateName": "an-Najaf"
        },
        {
          "Cities": [
            "Chamchamal",
            "Halabjah",
            "Kusanjaq",
            "Panjwin",
            "Qal'at Dizeh",
            "as-Sulaymaniyah"
          ],
          "StateName": "as-Sulaymaniyah"
        },
        {
          "Cities": [
            "'Aqrah",
            "Kirkuk"
          ],
          "StateName": "at-Ta'mim"
        }
      ],
      "CountryName": "Iraq"
    },
    {
      "States": [
        {
          "Cities": [
            "Moira"
          ],
          "StateName": "Armagh"
        },
        {
          "Cities": [
            "Bagenalstown",
            "Carlow",
            "Tullow"
          ],
          "StateName": "Carlow"
        },
        {
          "Cities": [
            "Bailieborough",
            "Belturbet",
            "Cavan",
            "Coothill"
          ],
          "StateName": "Cavan"
        },
        {
          "Cities": [
            "Ennis",
            "Kilkee",
            "Kilrush",
            "Newmarket-on-Fergus",
            "Shannon"
          ],
          "StateName": "Clare"
        },
        {
          "Cities": [
            "Bandon",
            "Bantry",
            "Blarney",
            "Carrigaline",
            "Charleville",
            "Clonakilty",
            "Cobh",
            "Cork",
            "Drishane",
            "Dunmanway",
            "Fermoy",
            "Kanturk",
            "Kinsale",
            "Macroom",
            "Mallow",
            "Midleton",
            "Millstreet",
            "Mitchelstown",
            "Passage West",
            "Skibbereen",
            "Youghal"
          ],
          "StateName": "Cork"
        },
        {
          "Cities": [
            "Ballybofey",
            "Ballyshannon",
            "Buncrana",
            "Bundoran",
            "Carndonagh",
            "Donegal",
            "Killybegs",
            "Letterkenny",
            "Lifford",
            "Moville"
          ],
          "StateName": "Donegal"
        },
        {
          "Cities": [
            "Balbriggan",
            "Ballsbridge",
            "Dublin",
            "Leixlip",
            "Lucan",
            "Malahide",
            "Portrane",
            "Rathcoole",
            "Rush",
            "Skerries",
            "Swords"
          ],
          "StateName": "Dublin"
        },
        {
          "Cities": [
            "Athenry",
            "Ballinasloe",
            "Clifden",
            "Galway",
            "Gort",
            "Loughrea",
            "Tuam"
          ],
          "StateName": "Galway"
        },
        {
          "Cities": [
            "Ballybunion",
            "Cahirciveen",
            "Castleisland",
            "Dingle",
            "Kenmare",
            "Killarney",
            "Killorglin",
            "Listowel",
            "Tralee"
          ],
          "StateName": "Kerry"
        },
        {
          "Cities": [
            "Athy",
            "Celbridge",
            "Clane",
            "Kilcock",
            "Kilcullen",
            "Kildare",
            "Maynooth",
            "Monasterevan",
            "Naas",
            "Newbridge"
          ],
          "StateName": "Kildare"
        },
        {
          "Cities": [
            "Callan",
            "Castlecomer",
            "Kilkenny",
            "Thomastown"
          ],
          "StateName": "Kilkenny"
        },
        {
          "Cities": [
            "Abbeyleix",
            "Mountmellick",
            "Mountrath",
            "Port Laoise",
            "Portarlington"
          ],
          "StateName": "Laois"
        },
        {
          "Cities": [
            "Meath"
          ],
          "StateName": "Leinster"
        },
        {
          "Cities": [
            "Carrick-on-Shannon"
          ],
          "StateName": "Leitrim"
        },
        {
          "Cities": [
            "Abbeyfeale",
            "Kilmallock",
            "Limerick",
            "Newcastle",
            "Rathkeale"
          ],
          "StateName": "Limerick"
        },
        {
          "Cities": [],
          "StateName": "Loch Garman"
        },
        {
          "Cities": [
            "Granard",
            "Longford",
            "Moate"
          ],
          "StateName": "Longford"
        },
        {
          "Cities": [
            "Ardee",
            "Drogheda",
            "Drumcar",
            "Dundalk"
          ],
          "StateName": "Louth"
        },
        {
          "Cities": [
            "Ballina",
            "Ballinrobe",
            "Ballyhaunis",
            "Castlebar",
            "Claremorris",
            "Swinford",
            "Westport"
          ],
          "StateName": "Mayo"
        },
        {
          "Cities": [
            "Ashbourne",
            "Duleek",
            "Dunboyne",
            "Dunshaughlin",
            "Kells",
            "Laytown",
            "Navan",
            "Trim"
          ],
          "StateName": "Meath"
        },
        {
          "Cities": [
            "Carrickmacross",
            "Castleblayney",
            "Clones",
            "Monaghan"
          ],
          "StateName": "Monaghan"
        },
        {
          "Cities": [
            "Banagher",
            "Birr",
            "Clara",
            "Edenderry",
            "Kilcormac",
            "Tullamore"
          ],
          "StateName": "Offaly"
        },
        {
          "Cities": [
            "Ballaghaderreen",
            "Boyle",
            "Castlerea",
            "Roscommon"
          ],
          "StateName": "Roscommon"
        },
        {
          "Cities": [
            "Sligo"
          ],
          "StateName": "Sligo"
        },
        {
          "Cities": [],
          "StateName": "Tipperary North Riding"
        },
        {
          "Cities": [],
          "StateName": "Tipperary South Riding"
        },
        {
          "Cities": [
            "Co Tyrone",
            "Downpatrick"
          ],
          "StateName": "Ulster"
        },
        {
          "Cities": [
            "Dungarvan",
            "Tramore",
            "Waterford"
          ],
          "StateName": "Waterford"
        },
        {
          "Cities": [
            "Athlone",
            "Mullingar"
          ],
          "StateName": "Westmeath"
        },
        {
          "Cities": [
            "Enniscorthy",
            "Gorey",
            "New Ross",
            "Wexford"
          ],
          "StateName": "Wexford"
        },
        {
          "Cities": [
            "Arklow",
            "Baltinglass",
            "Blessington",
            "Bray",
            "Greystones",
            "Kilcoole",
            "Newtownmountkennedy",
            "Rathdrum",
            "Wicklow"
          ],
          "StateName": "Wicklow"
        }
      ],
      "CountryName": "Ireland"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Beit Hanania"
        },
        {
          "Cities": [],
          "StateName": "Ben Gurion Airport"
        },
        {
          "Cities": [
            "Bethlehem"
          ],
          "StateName": "Bethlehem"
        },
        {
          "Cities": [
            "Caesarea"
          ],
          "StateName": "Caesarea"
        },
        {
          "Cities": [
            "Petach Tikva",
            "Ramallah"
          ],
          "StateName": "Centre"
        },
        {
          "Cities": [
            "Gaza"
          ],
          "StateName": "Gaza"
        },
        {
          "Cities": [
            "'Arad",
            "'Omer",
            "Ashdod",
            "Ashqelon",
            "Be'er Sheva",
            "Beersheba",
            "Bene Ayish",
            "Dimona",
            "Elat",
            "Gan Yavne",
            "Nahal `Oz",
            "Netivot",
            "Ofaqim",
            "Qiryat Gat",
            "Qiryat Mal'akhi",
            "Sederot",
            "Yeroham",
            "kiryat Malachi"
          ],
          "StateName": "Hadaron"
        },
        {
          "Cities": [],
          "StateName": "Haifa District"
        },
        {
          "Cities": [
            "Be'er Ya'aqov",
            "Beit Shemesh",
            "Bene Beraq",
            "Bnei Brak",
            "Even Yehuda",
            "Fureidis",
            "Gat Rimon",
            "Gedera",
            "Givat Shmuel",
            "Hibat Zion",
            "Hod HaSharon",
            "Hogar",
            "Jaljulye",
            "Jatt",
            "Kafar Qasem",
            "Kefar Sava",
            "Kefar Yona",
            "Kfar Saba",
            "Kiryag Bialik",
            "Lod",
            "Mazkeret Batya",
            "Modi'in",
            "Nes Ziyyona",
            "Ness Ziona",
            "Netanya",
            "Nordiya",
            "Pardesiyya",
            "Petakh Tiqwa",
            "Qadima",
            "Qalansawe",
            "Qiryat 'Eqron",
            "Ra'anana",
            "Ramla",
            "Rehovot",
            "Rekhovot",
            "Rinnatya",
            "Rishon LeZiyyon",
            "Rosh HaAyin",
            "Shoham",
            "Tayibe",
            "Tire",
            "Tsur Igal",
            "Udim",
            "Yavne",
            "Yehud"
          ],
          "StateName": "Hamerkaz"
        },
        {
          "Cities": [
            "'Afula",
            "'Akko",
            "'Arrabe",
            "'Ein Mahel",
            "'Ilut",
            "Abu Sinan",
            "Basmat Tab'un",
            "Beit Jann",
            "Bet She'an",
            "Bi'ne",
            "Bir-al-Maksur",
            "Bu'eine-Nujeidat",
            "Dabburye",
            "Dayr Hannah",
            "Dayr-al-Asad",
            "Hazor HaGelilit",
            "I'billin",
            "Iksal",
            "Judeide-Maker",
            "Kabul",
            "Kafar Kanna",
            "Kafar Manda",
            "Kafar Yasif",
            "Karmiel",
            "Kisra-Sumei",
            "Ma'alot Tarshikha",
            "Majd-al-Kurum",
            "Migdal Ha'Emeq",
            "Mugar",
            "Nahariyya",
            "Nahef",
            "Nazerat",
            "Nazerat 'Illit",
            "Qiryat Shemona",
            "Qiryat Tiv'on",
            "Rame",
            "Reine",
            "Sakhnin",
            "Shefar'am",
            "Tamra",
            "Tiberias",
            "Tur'an",
            "Yirka",
            "Yoqne'am Illit",
            "Zefat",
            "Zur Yigal"
          ],
          "StateName": "Hazafon"
        },
        {
          "Cities": [
            "Hebron"
          ],
          "StateName": "Hebron"
        },
        {
          "Cities": [
            "Sgula"
          ],
          "StateName": "Jaffa"
        },
        {
          "Cities": [
            "Jerusalem"
          ],
          "StateName": "Jerusalem"
        },
        {
          "Cities": [
            "'Ar'ara",
            "'Isifya",
            "Baqa al-Gharbiyyah",
            "Binyamina",
            "Daliyat-al-Karmil",
            "Jizr-az-Zarqa",
            "Khadera",
            "Khefa",
            "Nesher",
            "Or 'Aqiva",
            "Pardes Khanna-Karkur",
            "Qiryat Atta",
            "Qiryat Bialik",
            "Qiryat Motzkin",
            "Qiryat Yam",
            "Rekhasim",
            "Tirat Karmel",
            "Umm-al-Fahm",
            "Zikhron Ya'aqov"
          ],
          "StateName": "Khefa"
        },
        {
          "Cities": [],
          "StateName": "Kiryat Yam"
        },
        {
          "Cities": [],
          "StateName": "Lower Galilee"
        },
        {
          "Cities": [
            "Qalqilya"
          ],
          "StateName": "Qalqilya"
        },
        {
          "Cities": [],
          "StateName": "Talme Elazar"
        },
        {
          "Cities": [],
          "StateName": "Tel Aviv"
        },
        {
          "Cities": [
            "Hadera",
            "Kiryag Bialik"
          ],
          "StateName": "Tsafon"
        },
        {
          "Cities": [],
          "StateName": "Umm El Fahem"
        },
        {
          "Cities": [
            "Bet Shemesh",
            "Mevasserat Ziyyon",
            "Yerushalayim"
          ],
          "StateName": "Yerushalayim"
        }
      ],
      "CountryName": "Israel"
    },
    {
      "States": [
        {
          "Cities": [
            "Meta",
            "Miano"
          ],
          "StateName": "Abruzzi"
        },
        {
          "Cities": [
            "Alba Adriatica",
            "Atessa",
            "Atri",
            "Avezzano",
            "Celano",
            "Cepagatti",
            "Chieti",
            "Citta Sant'Angelo",
            "Francavilla al Mare",
            "Giulianova",
            "Guardiagrele",
            "L'Aquila",
            "Lanciano",
            "Martinsicuro",
            "Montesilvano",
            "Montorio al Vomano",
            "Mosciano Sant'Angelo",
            "Ortona",
            "Penne",
            "Pescara",
            "Pineto",
            "Roseto degli Abruzzi",
            "San Giovanni Teatino",
            "San Salvo",
            "Sant'Egidio alla Vibrata",
            "Silvi",
            "Spoltore",
            "Sulmona",
            "Teramo",
            "Vasto"
          ],
          "StateName": "Abruzzo"
        },
        {
          "Cities": [
            "Agrigento",
            "Alessandria della Rocca",
            "Aragona",
            "Bivona",
            "Burgio",
            "Calamonaci",
            "Caltabellotta",
            "Camastra",
            "Cammarata",
            "Campobello di Licata",
            "CanicattÃÆÃÂ¬",
            "Casteltermini",
            "Castrofilippo",
            "Cattolica Eraclea",
            "Cianciana",
            "Comitini",
            "Favara",
            "Grotte",
            "Joppolo Giancaxio",
            "Lampedusa e Linosa",
            "Licata",
            "Lucca Sicula",
            "Menfi",
            "Montallegro",
            "Montevago",
            "Naro",
            "Palma di Montechiaro",
            "Porto Empedocle",
            "Racalmuto",
            "Raffadali",
            "Ravanusa",
            "Realmonte",
            "Ribera",
            "Sambuca di Sicilia",
            "San Biagio Platani",
            "San Giovanni Gemini",
            "Sant Angelo Muxaro",
            "Santa Elisabetta",
            "Santa Margherita di Belice",
            "Santo Stefano Quisquina",
            "Sciacca",
            "Siculiana",
            "Villafranca Sicula"
          ],
          "StateName": "Agrigento"
        },
        {
          "Cities": [
            "Castellazzo Bormida",
            "Gavi",
            "Villanova Monferrato"
          ],
          "StateName": "Alessandria"
        },
        {
          "Cities": [
            "Camerano",
            "Castelplanio"
          ],
          "StateName": "Ancona"
        },
        {
          "Cities": [
            "Capolona",
            "Montevarchi",
            "Subbiano"
          ],
          "StateName": "Arezzo"
        },
        {
          "Cities": [],
          "StateName": "Ascoli Piceno"
        },
        {
          "Cities": [
            "Buttigliera d'Asti"
          ],
          "StateName": "Asti"
        },
        {
          "Cities": [
            "Flumeri",
            "Nusco",
            "Prata di Principato Ultra",
            "Villanova del Battista"
          ],
          "StateName": "Avellino"
        },
        {
          "Cities": [],
          "StateName": "Bari"
        },
        {
          "Cities": [
            "Avigliano",
            "Bernalda",
            "Ferrandina",
            "Lauria",
            "Lavello",
            "Matera",
            "Melfi",
            "Montescaglioso",
            "Pisticci",
            "Policoro",
            "Potenza",
            "Rionero in Vulture",
            "Venosa"
          ],
          "StateName": "Basilicata"
        },
        {
          "Cities": [
            "Belluno",
            "D'alpago",
            "Longarone",
            "Pedavena"
          ],
          "StateName": "Belluno"
        },
        {
          "Cities": [
            "San Bartolomeo"
          ],
          "StateName": "Benevento"
        },
        {
          "Cities": [
            "",
            "Bagnatica",
            "Bergamo",
            "Bolgare",
            "Bottanuco",
            "Brignano Gera d'Adda",
            "Calcio",
            "Caravaggio",
            "Chiuduno",
            "Ciserano",
            "Comun Nuovo",
            "Costa di Mezzate",
            "Gandino",
            "Grassobbio",
            "Grumello Del Monte",
            "Grumello del Monte",
            "Lallio",
            "Levate",
            "Lurano",
            "Mapello",
            "Pagazzano",
            "Ponteranica",
            "Pontida",
            "Sant Omobono Imagna",
            "Torre Pallavicina",
            "Trescore Balneario",
            "Verdellino",
            "Zingonia"
          ],
          "StateName": "Bergamo"
        },
        {
          "Cities": [
            "Camburzano",
            "Crevacuore",
            "Gaglianico",
            "Sandigliano",
            "Vigliano Biellese"
          ],
          "StateName": "Biella"
        },
        {
          "Cities": [
            "Anzola dell'Emilia",
            "Bologna",
            "Borgo Tossignano",
            "Casalfiumanese",
            "Castiglione Dei Pepoli ",
            "Funo",
            "Loiano",
            "Monterenzio",
            "Osteria Grande"
          ],
          "StateName": "Bologna"
        },
        {
          "Cities": [
            "Frangarto"
          ],
          "StateName": "Bolzano"
        },
        {
          "Cities": [
            "Agnosine",
            "Brescia",
            "Capriano del Colle",
            "Capriolo",
            "Castegnato",
            "Castelcovati",
            "Cellatica",
            "Coccaglio",
            "Comezzano-Cizzago",
            "Erbusco",
            "Flero",
            "Lavenone",
            "Longhena",
            "Maclodio",
            "Muscoline",
            "Padenghe sul Garda",
            "Paderno Franciacorta",
            "Paratico",
            "Passirano",
            "Polaveno",
            "Poncarale",
            "Prevalle",
            "Provaglio dIseo",
            "Roncadelle",
            "Verolavecchia",
            "Visano"
          ],
          "StateName": "Brescia"
        },
        {
          "Cities": [
            "San Donaci"
          ],
          "StateName": "Brindisi"
        },
        {
          "Cities": [
            "Acri",
            "Amantea",
            "Bagnara Calabra",
            "Belvedere Marittimo",
            "Bisignano",
            "Bovalino",
            "Cariati",
            "Cassano allo Ionio",
            "Castrolibero",
            "Castrovillari",
            "Catanzaro",
            "Cetraro",
            "Ciro Marina",
            "Cittanova",
            "Corigliano Calabro",
            "Cosenza",
            "Crosia",
            "Crotone",
            "Cutro",
            "Fuscaldo",
            "Gioia Tauro",
            "Isola di Capo Rizzuto",
            "Lamezia Terme",
            "Locri",
            "Luzzi",
            "Melito di Porto Salvo",
            "Mendicino",
            "Montalto Uffugo",
            "Palmi",
            "Paola",
            "Petilia Policastro",
            "Pizzo",
            "Polistena",
            "Reggio di Calabria",
            "Rende",
            "Rosarno",
            "Rossano",
            "San Giovanni in Fiore",
            "Scalea",
            "Sellia Marina",
            "Siderno",
            "Soverato",
            "Taurianova",
            "Trebisacce",
            "Vibo Valentia",
            "Villa San Giovanni"
          ],
          "StateName": "Calabria"
        },
        {
          "Cities": [
            "Acerra",
            "Afragola",
            "Agropoli",
            "Angri",
            "Ariano Irpino",
            "Arzano",
            "Atripalda",
            "Avellino",
            "Aversa",
            "Bacoli",
            "Barano d'Ischia",
            "Baronissi",
            "Battipaglia",
            "Bellizzi",
            "Benevento",
            "Boscoreale",
            "Boscotrecase",
            "Brusciano",
            "Caivano",
            "Calvizzano",
            "Campagna",
            "Capaccio",
            "Capua",
            "Cardito",
            "Carinola",
            "Casagiove",
            "Casal di Principe",
            "Casalnuovo di Napoli",
            "Casaluce",
            "Casandrino",
            "Casavatore",
            "Caserta",
            "Casoria",
            "Castel San Giorgio",
            "Castel Volturno",
            "Castellammare di Stabia",
            "Cava de' Tirreni",
            "Cercola",
            "Cervinara",
            "Cicciano",
            "Crispano",
            "Eboli",
            "Ercolano",
            "Fisciano",
            "Forio",
            "Frattamaggiore",
            "Frattaminore",
            "Frignano",
            "Giffoni Valle Piana",
            "Giugliano in Campania",
            "Gragnano",
            "Gricignano di Aversa",
            "Grottaminarda",
            "Grumo Nevano",
            "Ischia",
            "Lusciano",
            "Macerata Campania",
            "Maddaloni",
            "Marano di Napoli",
            "Marcianise",
            "Marigliano",
            "Massa Lubrense",
            "Melito di Napoli",
            "Mercato San Severino",
            "Mercogliano",
            "Meta",
            "Mirabella Eclano",
            "Mondragone",
            "Monte di Procida",
            "Montecorvino Rovella",
            "Monteforte Irpino",
            "Montesarchio",
            "Montoro Inferiore",
            "Mugnano di Napoli",
            "Naples",
            "Napoli",
            "Nocera Inferiore",
            "Nocera Superiore",
            "Nola",
            "Orta di Atella",
            "Ottaviano",
            "Pagani",
            "Palma Campania",
            "Parete",
            "Pellezzano",
            "Piano di Sorrento",
            "Piedimonte Matese",
            "Poggiomarino",
            "Pollena Trocchia",
            "Pomigliano d'Arco",
            "Pompei",
            "Pontecagnano",
            "Portici",
            "Positano",
            "Pozzuoli",
            "Procida",
            "Qualiano",
            "Quarto",
            "Roccapiemonte",
            "Sala Consilina",
            "Salerno",
            "San Cipriano d'Aversa",
            "San Felice a Cancello",
            "San Gennaro Vesuviano",
            "San Giorgio a Cremano",
            "San Giorgio del Sannio",
            "San Giuseppe Vesuviano",
            "San Marcellino",
            "San Marzano sul Sarno",
            "San Nicola",
            "San Prisco",
            "San Sebastiano al Vesuvio",
            "San Valentino Torio",
            "Sant Antimo",
            "Sant'Agata de' Goti",
            "Sant'Agnello",
            "Sant'Anastasia",
            "Sant'Antonio Abate",
            "Sant'Arpino",
            "Sant'Egidio del Monte Albino",
            "Santa Maria Capua Vetere",
            "Santa Maria a Vico",
            "Santa Maria la Carita",
            "Sarno",
            "Saviano",
            "Scafati",
            "Sessa Aurunca",
            "Siano",
            "Solofra",
            "Somma Vesuviana",
            "Sorrento",
            "Teano",
            "Teggiano",
            "Terzigno",
            "Teverola",
            "Torre Annunziata",
            "Torre del Greco",
            "Trecase",
            "Trentola-Ducenta",
            "Vallo della Lucania",
            "Vico Equense",
            "Vietri sul Mare",
            "Villa Literno",
            "Villaricca",
            "Volla"
          ],
          "StateName": "Campania"
        },
        {
          "Cities": [
            "Cartoceto"
          ],
          "StateName": "Cartoceto"
        },
        {
          "Cities": [
            "Carinaro",
            "San Marco Evangelista"
          ],
          "StateName": "Caserta"
        },
        {
          "Cities": [
            "Fiandaca Di Acireale",
            "San Cono"
          ],
          "StateName": "Catania"
        },
        {
          "Cities": [
            "Altino",
            "Archi",
            "Ari",
            "Arielli",
            "Atessa",
            "Bomba",
            "Borrello",
            "Bucchianico",
            "Canosa Sannita",
            "Carpineto Sinello",
            "Carunchio",
            "Casacanditella",
            "Casalanguida",
            "Casalbordino",
            "Casalincontrada",
            "Casoli",
            "Castel Frentano",
            "Castelguidone",
            "Castiglione Messer Marino",
            "Celenza sul Trigno",
            "Chieti",
            "Civitaluparella",
            "Civitella Messer Raimondo",
            "Colledimacine",
            "Colledimezzo",
            "Crecchio",
            "Cupello",
            "Dogliola",
            "Fallo",
            "Fara Filiorum Petri",
            "Fara San Martino",
            "Filetto",
            "Fossacesia",
            "Fraine",
            "Francavilla al Mare",
            "Fresagrandinaria",
            "Frisa",
            "Furci",
            "Gamberale",
            "Gessopalena",
            "Gissi",
            "Giuliano Teatino",
            "Guardiagrele",
            "Guilmi",
            "Lama dei Peligni",
            "Lanciano",
            "Lentella",
            "Lettopalena",
            "Liscia",
            "Miglianico",
            "Montazzoli",
            "Montebello sul Sangro",
            "Monteferrante",
            "Montelapiano",
            "Montenerodomo",
            "Monteodorisio",
            "Mozzagrogna",
            "Orsogna",
            "Ortona",
            "Paglieta",
            "Palena",
            "Palmoli",
            "Palombaro",
            "Pennadomo",
            "Pennapiedimonte",
            "Perano",
            "Pietraferrazzana",
            "Pizzoferrato",
            "Poggiofiorito",
            "Pollutri",
            "Pretoro",
            "Quadri",
            "Rapino",
            "Ripa Teatina",
            "Rocca San Giovanni",
            "Roccamontepiano",
            "Roccascalegna",
            "Roccaspinalveti",
            "Roio del Sangro",
            "Rosello",
            "San Buono",
            "San Giovanni Lipioni",
            "San Giovanni Teatino",
            "San Martino sulla Marrucina",
            "San Salvo",
            "San Vito Chietino",
            "Sant Eusanio del Sangro",
            "Santa Maria Imbaro",
            "Scerni",
            "Schiavi di Abruzzo",
            "Taranta Peligna",
            "Tollo",
            "Torino di Sangro",
            "Tornareccio",
            "Torrebruna",
            "Torrevecchia Teatina",
            "Torricella Peligna",
            "Treglio",
            "Tufillo",
            "Vacri",
            "Vasto",
            "Villa Santa Maria",
            "Villalfonsina",
            "Villamagna"
          ],
          "StateName": "Chieti"
        },
        {
          "Cities": [
            "Albavilla",
            "Cadorago",
            "Carimate",
            "Castelmarte",
            "Cavaria",
            "Cernobbio",
            "Comocrea",
            "Dongo",
            "Gironico",
            "Grandate",
            "Lurago dErba",
            "Mozzate",
            "Novedrate",
            "Orsenigo",
            "Turate",
            "Uggiate"
          ],
          "StateName": "Como"
        },
        {
          "Cities": [
            "Corso del Tirreno",
            "Mangone"
          ],
          "StateName": "Cosenza"
        },
        {
          "Cities": [
            "Casalbuttano",
            "Casalmaggiore",
            "Castelverde",
            "Madignano",
            "Pieve San Giacomo"
          ],
          "StateName": "Cremona"
        },
        {
          "Cities": [
            "Bandito",
            "Bra",
            "Casalgrasso",
            "Cossano Belbo",
            "Magliano Alpi",
            "Mondovi",
            "Roddi",
            "Santa Vittoria d'Alba",
            "Verduno"
          ],
          "StateName": "Cuneo"
        },
        {
          "Cities": [
            "Alfonsine",
            "Argelato",
            "Argenta",
            "Bagnacavallo",
            "Bagnolo in Piano",
            "Bellaria-Igea Marina",
            "Bertinoro",
            "Bologna",
            "Bondeno",
            "Budrio",
            "Calderara di Reno",
            "Carpi",
            "Casalecchio di Reno",
            "Casalgrande",
            "Castel Bolognese",
            "Castel Maggiore",
            "Castel San Giovanni",
            "Castel San Pietro Terme",
            "Castelfranco Emilia",
            "Castellarano",
            "Castelnovo ne' Monti",
            "Castelnuovo Rangone",
            "Castelvetro di Modena",
            "Castenaso",
            "Cattolica",
            "Cavriago",
            "Cento",
            "Cervia",
            "Cesena",
            "Cesenatico",
            "Codigoro",
            "Collecchio",
            "Comacchio",
            "Concordia sulla Secchia",
            "Conselice",
            "Copparo",
            "Coriano",
            "Correggio",
            "Crespellano",
            "Crevalcore",
            "Faenza",
            "Ferrara",
            "Fidenza",
            "Finale Emilia",
            "Fiorano Modenese",
            "Fiorenzuola d'Arda",
            "Forli",
            "Forlimpopoli",
            "Formigine",
            "Gambettola",
            "Granarolo dell'Emilia",
            "Guastalla",
            "Imola",
            "Langhirano",
            "Lugo",
            "Luzzara",
            "Maranello",
            "Massa Lombarda",
            "Medesano",
            "Medicina",
            "Meldola",
            "Mirandola",
            "Misano Adriatico",
            "Modena",
            "Molinella",
            "Monte San Pietro",
            "Montecchio Emilia",
            "Montechiarugolo",
            "Noceto",
            "Nonantola",
            "Novellara",
            "Novi di Modena",
            "Ozzano dell'Emilia",
            "Parma",
            "Pavullo nel Frignano",
            "Piacenza",
            "Pianoro",
            "Ponticino",
            "Portomaggiore",
            "Quattro Castella",
            "Ravenna",
            "Reggio nell'Emilia",
            "Reggiolo",
            "Riccione",
            "Rimini",
            "Rottofreno",
            "Rubiera",
            "Russi",
            "Salsomaggiore Terme",
            "San Felice sul Panaro",
            "San Giovanni in Persiceto",
            "San Lazzaro di Savena",
            "San Mauro Pascoli",
            "San Pietro in Casale",
            "Sant'Ilario d'Enza",
            "Santarcangelo di Romagna",
            "Sasso Marconi",
            "Sassuolo",
            "Savignano sul Panaro",
            "Savignano sul Rubicone",
            "Scandiano",
            "Soliera",
            "Sorbolo",
            "Spilamberto",
            "Verucchio",
            "Vignola",
            "Zola Predosa"
          ],
          "StateName": "Emilia-Romagna"
        },
        {
          "Cities": [
            "Saint Agostino"
          ],
          "StateName": "Ferrara"
        },
        {
          "Cities": [
            "Capalle",
            "Firenze",
            "Pelago",
            "San Donnino",
            "Scarperia"
          ],
          "StateName": "Firenze"
        },
        {
          "Cities": [
            "Scandicci",
            "Sesto Fiorentino"
          ],
          "StateName": "Florence"
        },
        {
          "Cities": [],
          "StateName": "Forli-Cesena "
        },
        {
          "Cities": [],
          "StateName": "Friuli-Venezia Giulia"
        },
        {
          "Cities": [
            "Casalvieri",
            "Frosinone",
            "Sgurgola"
          ],
          "StateName": "Frosinone"
        },
        {
          "Cities": [
            "Genoa",
            "Moneglia"
          ],
          "StateName": "Genoa"
        },
        {
          "Cities": [
            "Romans d'Isonzo",
            "Savogna d'Isonzo"
          ],
          "StateName": "Gorizia"
        },
        {
          "Cities": [
            "Magliano de Marsi"
          ],
          "StateName": "L'Aquila"
        },
        {
          "Cities": [
            "Alatri",
            "Albano Laziale",
            "Anagni",
            "Anguillara Sabazia",
            "Anzio",
            "Aprilia",
            "Ardea",
            "Ariccia",
            "Artena",
            "Boville Ernica",
            "Bracciano",
            "Campagnano di Roma",
            "Cassino",
            "Cave",
            "Ceccano",
            "Ceprano",
            "Cerveteri",
            "Ciampino",
            "Cisterna",
            "Civita Castellana",
            "Civitavecchia",
            "Colleferro",
            "Cori",
            "Fara in Sabina",
            "Ferentino",
            "Fiano Romano",
            "Fiuggi",
            "Fiumicino",
            "Fondi",
            "Fonte Nuova",
            "Formello",
            "Formia",
            "Frascati",
            "Frosinone",
            "Gaeta",
            "Genzano di Roma",
            "Grottaferrata",
            "Guidonia",
            "Isola del Liri",
            "Itri",
            "Ladispoli",
            "Lanuvio",
            "Lariano",
            "Latina",
            "Marino",
            "Mentana",
            "Minturno",
            "Monte San Giovanni Campano",
            "Montefiascone",
            "Monterotondo",
            "Nettuno",
            "Palestrina",
            "Palombara Sabina",
            "Pomezia",
            "Pontecorvo",
            "Pontinia",
            "Priverno",
            "Rieti",
            "Rocca Priora",
            "Rocca di Papa",
            "Roma",
            "Rome",
            "Sabaudia",
            "San Cesareo",
            "Santa Marinella",
            "Segni",
            "Sezze",
            "Sora",
            "Soriano nel Cimino",
            "Subiaco",
            "Tarquinia",
            "Terracina",
            "Tivoli",
            "Valmontone",
            "Velletri",
            "Veroli",
            "Vetralla",
            "Viterbo",
            "Zagarolo"
          ],
          "StateName": "Lazio"
        },
        {
          "Cities": [
            "Acquarica del Capo"
          ],
          "StateName": "Lecce"
        },
        {
          "Cities": [
            "Airuno",
            "Bosisio Parini",
            "Lecco",
            "Margno",
            "Osnago",
            "Sirone"
          ],
          "StateName": "Lecco"
        },
        {
          "Cities": [
            "Airuno",
            "Bosisio Parini",
            "Lecco",
            "Margno",
            "Osnago",
            "Sirone"
          ],
          "StateName": "Lecco Province"
        },
        {
          "Cities": [
            "Alassio",
            "Albenga",
            "Albisola Superiore",
            "Arcola",
            "Arenzano",
            "Bordighera",
            "Borgonuovo",
            "Cairo Montenotte",
            "Chiavari",
            "Cogoleto",
            "Finale Ligure",
            "Genova",
            "Imperia",
            "La Spezia",
            "Lavagna",
            "Lerici",
            "Loano",
            "Ortonovo",
            "Pietra Ligure",
            "Rapallo",
            "Recco",
            "San Remo",
            "Santa Margherita Ligure",
            "Santo Stefano di Magra",
            "Sarzana",
            "Savona",
            "Sestri Levante",
            "Taggia",
            "Varazze",
            "Ventimiglia"
          ],
          "StateName": "Liguria"
        },
        {
          "Cities": [
            "Somaglia"
          ],
          "StateName": "Lodi"
        },
        {
          "Cities": [
            "Abbiategrasso",
            "Agrate Brianza",
            "Albiate",
            "Albino",
            "Albizzate",
            "Alzano Lombardo",
            "Arcisate",
            "Arconate",
            "Arcore",
            "Arese",
            "Arluno",
            "Asola",
            "Bagnolo Mella",
            "Ballabio",
            "Bareggio",
            "Basiglio",
            "Bedizzole",
            "Bergamo",
            "Bernareggio",
            "Besana in Brianza",
            "Besozzo",
            "Biassono",
            "Bienate",
            "Bollate",
            "Botticino",
            "Bovisio-Masciago",
            "Brembilla",
            "Brescia",
            "Bresso",
            "Broni",
            "Brugherio",
            "Buccinasco",
            "Bussero",
            "Busto Arsizio",
            "Busto Garolfo",
            "Cairate",
            "Calcinato",
            "Calolziocorte",
            "Calusco d'Adda",
            "Canegrate",
            "Cantu",
            "Capriolo",
            "Carate Brianza",
            "Caravaggio",
            "Cardano al Campo",
            "Caronno Pertusella",
            "Carpenedolo",
            "Carugate",
            "Carvico",
            "Casalmaggiore",
            "Casalpusterlengo",
            "Casatenovo",
            "Casazza",
            "Casnigo",
            "Cassano Magnago",
            "Cassano d'Adda",
            "Cassina de' Pecchi",
            "Castano Primo",
            "Castel Goffredo",
            "Castel Mella",
            "Castellanza",
            "Castelleone",
            "Castelli Calepio",
            "Castenedolo",
            "Castiglione delle Stiviere",
            "Cazzago San Martino",
            "Cene",
            "Cermenate",
            "Cernusco sul Naviglio",
            "Cerro Maggiore",
            "Cesano Boscone",
            "Cesano Maderno",
            "Cesate",
            "Chiari",
            "Cilavegna",
            "Cinisello Balsamo",
            "Cislago",
            "Clusone",
            "Codogno",
            "Cologno Monzese",
            "Cologno al Serio",
            "Como",
            "Concesio",
            "Concorezzo",
            "Corbetta",
            "Cormano",
            "Cornaredo",
            "Cornate d'Adda",
            "Corsico",
            "Corte dei Cortesi",
            "Costa Volpino",
            "Crema",
            "Cremona",
            "Crocetta",
            "Curtatone",
            "Cusano Milanino",
            "Dalmine",
            "Darfo",
            "Desenzano del Garda",
            "Desio",
            "Erba",
            "Fagnano Olona",
            "Fino Mornasco",
            "Gaggiano",
            "Galbiate",
            "Gallarate",
            "Gambolo",
            "Garbagnate Milanese",
            "Gardone Val Trompia",
            "Garlasco",
            "Gavardo",
            "Gavirate",
            "Gerenzano",
            "Ghedi",
            "Giussano",
            "Goito",
            "Gonzaga",
            "Gorgonzola",
            "Gussago",
            "Gussola",
            "Induno Olona",
            "Inveruno",
            "Inzago",
            "Iseo",
            "Isola Dovarese",
            "Lacchiarella",
            "Lainate",
            "Laveno-Mombello",
            "Lecco",
            "Leffe",
            "Legnano",
            "Leno",
            "Lentate sul Seveso",
            "Limbiate",
            "Lissone",
            "Locate di Triulzi",
            "Lodi",
            "Lomazzo",
            "Lonate Pozzolo",
            "Lonato",
            "Luino",
            "Lumezzane",
            "Lurate Caccivio",
            "Magenta",
            "Malnate",
            "Mandello del Lario",
            "Manerbio",
            "Mantova",
            "Mariano Comense",
            "Martinengo",
            "Mazzano",
            "Meda",
            "Mediglia",
            "Melegnano",
            "Melzo",
            "Merate",
            "Milano",
            "Molteno",
            "Montichiari",
            "Monza",
            "Morbegno",
            "Mornago",
            "Mortara",
            "Muggio",
            "Nave",
            "Nembro",
            "Nerviano",
            "Nova Milanese",
            "Novate Milanese",
            "Olgiate Comasco",
            "Olgiate Olona",
            "Opera",
            "Orzinuovi",
            "Osio Sotto",
            "Ospitaletto",
            "Paderno Dugnano",
            "Palazzolo sull'Oglio",
            "Pandino",
            "Parabiago",
            "Paullo",
            "Pavia",
            "Pero",
            "Peschiera Borromeo",
            "Pessano con Bornago",
            "Pieve Emanuele",
            "Pioltello",
            "Ponte Nossa",
            "Ponte San Pietro",
            "Porto Mantovano",
            "Pozzolengo",
            "Rescaldina",
            "Rezzato",
            "Rho",
            "Rivarolo Mantovano",
            "Rodano",
            "Romano di Lombardia",
            "Rovato",
            "Rozzano",
            "Saletto",
            "Salo",
            "Samarate",
            "San Donato Milanese",
            "San Giuliano Milanese",
            "Sant'Angelo Lodigiano",
            "Sarezzo",
            "Saronno",
            "Scanzorosciate",
            "Sedriano",
            "Segrate",
            "Senago",
            "Seregno",
            "Seriate",
            "Sesto Calende",
            "Sesto San Giovanni",
            "Settimo Milanese",
            "Seveso",
            "Sirmione",
            "Solaro",
            "Somma Lombardo",
            "Sondrio",
            "Soresina",
            "Sorisole",
            "Stezzano",
            "Stradella",
            "Suzzara",
            "Tirano",
            "Tornata",
            "Tradate",
            "Travagliato",
            "Treviglio",
            "Treviolo",
            "Trezzano sul Naviglio",
            "Trezzo",
            "Tromello",
            "Uboldo",
            "Urgnano",
            "Usmate Velate",
            "Valmadrera",
            "Varedo",
            "Varese",
            "Verano Brianza",
            "Vergiate",
            "Viadana",
            "Vigevano",
            "Vignate",
            "Villa Carcina",
            "Villa Guardia",
            "Villasanta",
            "Vimercate",
            "Vimodrone",
            "Virgilio",
            "Voghera",
            "Zibido San Giacomo",
            "Zogno"
          ],
          "StateName": "Lombardia"
        },
        {
          "Cities": [
            "Barasso",
            "Bergamo",
            "Bolladello",
            "Capergnanica",
            "Costa Masnaga",
            "Medolago",
            "Nibionno",
            "Rodano",
            "Sordio",
            "Torre d'Isola",
            "Varese",
            "Villongo",
            "milan"
          ],
          "StateName": "Lombardy"
        },
        {
          "Cities": [
            "Colmurano",
            "Monte San Giusto"
          ],
          "StateName": "Macerata"
        },
        {
          "Cities": [
            "Castel",
            "Gazoldo",
            "Marmirolo",
            "Monzambano",
            "Ostiglia",
            "Pegognaga",
            "Poggio Rusco",
            "Quistello",
            "Roverbella",
            "Suzzara"
          ],
          "StateName": "Mantova"
        },
        {
          "Cities": [
            "Ancona",
            "Ascoli Piceno",
            "Barchi",
            "Cagli",
            "Castelfidardo",
            "Chiaravalle",
            "Cingoli",
            "Civitanova Marche",
            "Corridonia",
            "Fabriano",
            "Falconara Marittima",
            "Fano",
            "Fermo",
            "Filottrano",
            "Folignano",
            "Fossombrone",
            "Grottammare",
            "Jesi",
            "Loreto",
            "Macerata",
            "Matelica",
            "Mondavio",
            "Mondolfo",
            "Montappone",
            "Montecosaro",
            "Montegranaro",
            "Montemarciano",
            "Monteprandone",
            "Morrovalle",
            "Osimo",
            "Pesaro",
            "Polverigi",
            "Porto Recanati",
            "Porto San Giorgio",
            "Porto Sant'Elpidio",
            "Potenza Picena",
            "Recanati",
            "San Benedetto del Tronto",
            "San Severino Marche",
            "Sant'Elpidio a Mare",
            "Senigallia",
            "Tolentino",
            "Treia",
            "Urbino"
          ],
          "StateName": "Marche"
        },
        {
          "Cities": [
            "Cumiana",
            "Giammoro"
          ],
          "StateName": "Messina"
        },
        {
          "Cities": [
            "Assago",
            "Besana in Brianza",
            "Biassono",
            "Burago Molgora",
            "Cernusco Sul Naviglio",
            "Cologno Monzese",
            "Concorezzo",
            "Cornaredo",
            "Cuggiono",
            "Cusago",
            "Foro Buonaparte",
            "Gessate",
            "Gorgonzola",
            "Liscate",
            "Magenta",
            "Milan",
            "Noviglio",
            "Passirana Di Rho",
            "Pregnana Milane",
            "San Donato Milanese",
            "Seregno",
            "Trezzo Sull'adda",
            "Tribiano",
            "Vaprio d'Adda",
            "Vermezzo"
          ],
          "StateName": "Milan"
        },
        {
          "Cities": [
            "Bomporto",
            "Campogalliano",
            "Cavezzo",
            "Medolla",
            "Nonantola",
            "San Possidonio",
            "Sassuolo",
            "Spilamberto"
          ],
          "StateName": "Modena"
        },
        {
          "Cities": [
            "Bojano",
            "Campobasso",
            "Cantalupo",
            "Isernia",
            "Termoli",
            "Venafro"
          ],
          "StateName": "Molise"
        },
        {
          "Cities": [],
          "StateName": "Molteno"
        },
        {
          "Cities": [
            "Montenegro"
          ],
          "StateName": "Montenegro"
        },
        {
          "Cities": [],
          "StateName": "Monza and Brianza"
        },
        {
          "Cities": [
            "Forio d'Ischia"
          ],
          "StateName": "Naples"
        },
        {
          "Cities": [
            "Bogogno",
            "Invorio",
            "Pombia"
          ],
          "StateName": "Novara"
        },
        {
          "Cities": [
            "Bagnoli di Sopra",
            "Bovolenta",
            "Casale Di Scodosia",
            "Cervarese Santa Croce",
            "Fontaniva",
            "Galliera Veneta",
            "Legnaro",
            "Limena",
            "Loreggia",
            "Massanzago",
            "Onara",
            "Ponso",
            "Portogallo",
            "Tribano"
          ],
          "StateName": "Padova"
        },
        {
          "Cities": [
            "Baganzola",
            "Busseto",
            "Casale Di Mezzani",
            "Fontevivo",
            "Solignano",
            "Torrile"
          ],
          "StateName": "Parma"
        },
        {
          "Cities": [
            "Codevilla",
            "Marcignago",
            "Pavia",
            "Siziano"
          ],
          "StateName": "Pavia"
        },
        {
          "Cities": [
            "Pianello",
            "Ponte Felcino",
            "Zanica"
          ],
          "StateName": "Perugia"
        },
        {
          "Cities": [
            "Gradara",
            "Monte Porzio",
            "Pergola",
            "Tavullia"
          ],
          "StateName": "Pesaro-Urbino"
        },
        {
          "Cities": [
            "Alseno",
            "Gossolengo",
            "Vigolzone"
          ],
          "StateName": "Piacenza"
        },
        {
          "Cities": [
            "Armeno",
            "Bergamasco",
            "Caselette",
            "Rosta",
            "San Damiano",
            "Spinetta Marengo"
          ],
          "StateName": "Piedmont"
        },
        {
          "Cities": [
            "Acqui Terme",
            "Alba",
            "Alessandria",
            "Alpignano",
            "Andezeno",
            "Andonno",
            "Arona",
            "Asti",
            "Avigliana",
            "Baveno",
            "Beinasco",
            "Bellinzago Novarese",
            "Biella",
            "Borgaro Torinese",
            "Borgo San Dalmazzo",
            "Borgomanero",
            "Borgosesia",
            "Boves",
            "Bra",
            "Busca",
            "Cameri",
            "Canelli",
            "Carignano",
            "Carmagnola",
            "Casale Monferrato",
            "Caselle Torinese",
            "Castellamonte",
            "Castelletto sopra Ticino",
            "Chieri",
            "Chivasso",
            "Cirie",
            "Collegno",
            "Cossato",
            "Cuneo",
            "Cuorgne",
            "Domodossola",
            "Druento",
            "Fossano",
            "Galliate",
            "Gassino Torinese",
            "Gattinara",
            "Giaveno",
            "Grugliasco",
            "Ivrea",
            "Leini",
            "LusigliÃÆÃÂ¨",
            "Marano Ticino",
            "Mergozzo",
            "Moncalieri",
            "Mondovi",
            "Mongrando",
            "Nichelino",
            "Nizza Monferrato",
            "Novara",
            "Novi Ligure",
            "Oleggio",
            "Omegna",
            "Orbassano",
            "Ovada",
            "Pianezza",
            "Pinerolo",
            "Pino Torinese",
            "Piossasco",
            "Poirino",
            "Racconigi",
            "Rivalta di Torino",
            "Rivarolo Canavese",
            "Rivoli",
            "Saluzzo",
            "San Maurizio",
            "San Mauro Torinese",
            "Sandigliano",
            "Santena",
            "Santhia",
            "Savigliano",
            "Settimo Torinese",
            "Torino",
            "Tortona",
            "Trecate",
            "Trofarello",
            "Valduggia",
            "Valenza",
            "Venaria Reale",
            "Verbania",
            "Vercelli",
            "Vigliano Biellese",
            "Vinovo",
            "Volpiano"
          ],
          "StateName": "Piemonte"
        },
        {
          "Cities": [
            "Perignano",
            "Ponte a Egola"
          ],
          "StateName": "Pisa"
        },
        {
          "Cities": [
            "San Quirino"
          ],
          "StateName": "Pordenone"
        },
        {
          "Cities": [
            "Latronico"
          ],
          "StateName": "Potenza"
        },
        {
          "Cities": [
            "Acquaviva delle Fonti",
            "Adelfia",
            "Alberobello",
            "Altamura",
            "Andria",
            "Apricena",
            "Aradeo",
            "Bari",
            "Barletta",
            "Bisceglie",
            "Bitetto",
            "Bitonto",
            "Bitritto",
            "Brindisi",
            "Campi Salentina",
            "Canosa di Puglia",
            "Capurso",
            "Carmiano",
            "Carovigno",
            "Casamassima",
            "Casarano",
            "Cassano delle Murge",
            "Castellana Grotte",
            "Castellaneta",
            "Cavallino",
            "Ceglie Messapica",
            "Cerignola",
            "Cisternino",
            "Conversano",
            "Copertino",
            "Corato",
            "Crispiano",
            "Cutrofiano",
            "Erchie",
            "Fasano",
            "Foggia",
            "Francavilla Fontana",
            "Galatina",
            "Galatone",
            "Gallipoli",
            "Ginosa",
            "Gioia del Colle",
            "Giovinazzo",
            "Gravina in Puglia",
            "Grottaglie",
            "Grumo Appula",
            "Laterza",
            "Latiano",
            "Lecce",
            "Leverano",
            "Lizzanello",
            "Lizzano",
            "Locorotondo",
            "Lucera",
            "Maglie",
            "Manduria",
            "Manfredonia",
            "Margherita di Savoia",
            "Martano",
            "Martina Franca",
            "Massafra",
            "Matino",
            "Melendugno",
            "Mesagne",
            "Minervino Murge",
            "Modugno",
            "Mola di Bari",
            "Molfetta",
            "Monopoli",
            "Monte Sant'Angelo",
            "Monteroni di Lecce",
            "Mottola",
            "Nardo",
            "Neviano",
            "Noci",
            "Noicattaro",
            "Novoli",
            "Oria",
            "Orta Nova",
            "Ostuni",
            "Palagiano",
            "Palo del Colle",
            "Parabita",
            "Polignano a Mare",
            "Pulsano",
            "Putignano"
          ],
          "StateName": "Puglia"
        },
        {
          "Cities": [],
          "StateName": "Reggio Emilia"
        },
        {
          "Cities": [],
          "StateName": "Rimini"
        },
        {
          "Cities": [],
          "StateName": "Roma"
        },
        {
          "Cities": [],
          "StateName": "Salerno"
        },
        {
          "Cities": [],
          "StateName": "Sardegna"
        },
        {
          "Cities": [],
          "StateName": "Sassari"
        },
        {
          "Cities": [],
          "StateName": "Savona"
        },
        {
          "Cities": [],
          "StateName": "Sicilia"
        },
        {
          "Cities": [],
          "StateName": "Siena"
        },
        {
          "Cities": [],
          "StateName": "Sondrio"
        },
        {
          "Cities": [],
          "StateName": "South Tyrol"
        },
        {
          "Cities": [],
          "StateName": "Taranto"
        },
        {
          "Cities": [],
          "StateName": "Teramo"
        },
        {
          "Cities": [],
          "StateName": "Torino"
        },
        {
          "Cities": [],
          "StateName": "Toscana"
        },
        {
          "Cities": [],
          "StateName": "Trapani"
        },
        {
          "Cities": [],
          "StateName": "Trentino-Alto Adige"
        },
        {
          "Cities": [],
          "StateName": "Trento"
        },
        {
          "Cities": [],
          "StateName": "Treviso"
        },
        {
          "Cities": [],
          "StateName": "Udine"
        },
        {
          "Cities": [],
          "StateName": "Umbria"
        },
        {
          "Cities": [],
          "StateName": "Valle d'Aosta"
        },
        {
          "Cities": [],
          "StateName": "Varese"
        },
        {
          "Cities": [],
          "StateName": "Veneto"
        },
        {
          "Cities": [],
          "StateName": "Venezia"
        },
        {
          "Cities": [],
          "StateName": "Verbano-Cusio-Ossola"
        },
        {
          "Cities": [],
          "StateName": "Vercelli"
        },
        {
          "Cities": [],
          "StateName": "Verona"
        },
        {
          "Cities": [],
          "StateName": "Vicenza"
        },
        {
          "Cities": [],
          "StateName": "Viterbo"
        }
      ],
      "CountryName": "Italy"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Buxoro Viloyati"
        },
        {
          "Cities": [],
          "StateName": "Clarendon"
        },
        {
          "Cities": [],
          "StateName": "Hanover"
        },
        {
          "Cities": [],
          "StateName": "Kingston"
        },
        {
          "Cities": [],
          "StateName": "Manchester"
        },
        {
          "Cities": [],
          "StateName": "Portland"
        },
        {
          "Cities": [],
          "StateName": "Saint Andrews"
        },
        {
          "Cities": [],
          "StateName": "Saint Ann"
        },
        {
          "Cities": [],
          "StateName": "Saint Catherine"
        },
        {
          "Cities": [],
          "StateName": "Saint Elizabeth"
        },
        {
          "Cities": [],
          "StateName": "Saint James"
        },
        {
          "Cities": [],
          "StateName": "Saint Mary"
        },
        {
          "Cities": [],
          "StateName": "Saint Thomas"
        },
        {
          "Cities": [],
          "StateName": "Trelawney"
        },
        {
          "Cities": [],
          "StateName": "Westmoreland"
        }
      ],
      "CountryName": "Jamaica"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Aichi"
        },
        {
          "Cities": [],
          "StateName": "Akita"
        },
        {
          "Cities": [],
          "StateName": "Aomori"
        },
        {
          "Cities": [],
          "StateName": "Chiba"
        },
        {
          "Cities": [],
          "StateName": "Ehime"
        },
        {
          "Cities": [],
          "StateName": "Fukui"
        },
        {
          "Cities": [],
          "StateName": "Fukuoka"
        },
        {
          "Cities": [],
          "StateName": "Fukushima"
        },
        {
          "Cities": [],
          "StateName": "Gifu"
        },
        {
          "Cities": [],
          "StateName": "Gumma"
        },
        {
          "Cities": [],
          "StateName": "Hiroshima"
        },
        {
          "Cities": [],
          "StateName": "Hokkaido"
        },
        {
          "Cities": [],
          "StateName": "Hyogo"
        },
        {
          "Cities": [],
          "StateName": "Ibaraki"
        },
        {
          "Cities": [],
          "StateName": "Ishikawa"
        },
        {
          "Cities": [],
          "StateName": "Iwate"
        },
        {
          "Cities": [],
          "StateName": "Kagawa"
        },
        {
          "Cities": [],
          "StateName": "Kagoshima"
        },
        {
          "Cities": [],
          "StateName": "Kanagawa"
        },
        {
          "Cities": [],
          "StateName": "Kanto"
        },
        {
          "Cities": [],
          "StateName": "Kochi"
        },
        {
          "Cities": [],
          "StateName": "Kumamoto"
        },
        {
          "Cities": [],
          "StateName": "Kyoto"
        },
        {
          "Cities": [],
          "StateName": "Mie"
        },
        {
          "Cities": [],
          "StateName": "Miyagi"
        },
        {
          "Cities": [],
          "StateName": "Miyazaki"
        },
        {
          "Cities": [],
          "StateName": "Nagano"
        },
        {
          "Cities": [],
          "StateName": "Nagasaki"
        },
        {
          "Cities": [],
          "StateName": "Nara"
        },
        {
          "Cities": [],
          "StateName": "Niigata"
        },
        {
          "Cities": [],
          "StateName": "Oita"
        },
        {
          "Cities": [],
          "StateName": "Okayama"
        },
        {
          "Cities": [],
          "StateName": "Okinawa"
        },
        {
          "Cities": [],
          "StateName": "Osaka"
        },
        {
          "Cities": [],
          "StateName": "Saga"
        },
        {
          "Cities": [],
          "StateName": "Saitama"
        },
        {
          "Cities": [],
          "StateName": "Shiga"
        },
        {
          "Cities": [],
          "StateName": "Shimane"
        },
        {
          "Cities": [],
          "StateName": "Shizuoka"
        },
        {
          "Cities": [],
          "StateName": "Tochigi"
        },
        {
          "Cities": [],
          "StateName": "Tokushima"
        },
        {
          "Cities": [],
          "StateName": "Tokyo"
        },
        {
          "Cities": [],
          "StateName": "Tottori"
        },
        {
          "Cities": [],
          "StateName": "Toyama"
        },
        {
          "Cities": [],
          "StateName": "Wakayama"
        },
        {
          "Cities": [],
          "StateName": "Yamagata"
        },
        {
          "Cities": [],
          "StateName": "Yamaguchi"
        },
        {
          "Cities": [],
          "StateName": "Yamanashi"
        }
      ],
      "CountryName": "Japan"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Grouville"
        },
        {
          "Cities": [],
          "StateName": "Saint Brelade"
        },
        {
          "Cities": [],
          "StateName": "Saint Clement"
        },
        {
          "Cities": [],
          "StateName": "Saint Helier"
        },
        {
          "Cities": [],
          "StateName": "Saint John"
        },
        {
          "Cities": [],
          "StateName": "Saint Lawrence"
        },
        {
          "Cities": [],
          "StateName": "Saint Martin"
        },
        {
          "Cities": [],
          "StateName": "Saint Mary"
        },
        {
          "Cities": [],
          "StateName": "Saint Peter"
        },
        {
          "Cities": [],
          "StateName": "Saint Saviour"
        },
        {
          "Cities": [],
          "StateName": "Trinity"
        }
      ],
      "CountryName": "Jersey"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "'Ajlun"
        },
        {
          "Cities": [],
          "StateName": "Amman"
        },
        {
          "Cities": [],
          "StateName": "Irbid"
        },
        {
          "Cities": [],
          "StateName": "Jarash"
        },
        {
          "Cities": [],
          "StateName": "Ma'an"
        },
        {
          "Cities": [],
          "StateName": "Madaba"
        },
        {
          "Cities": [],
          "StateName": "al-'Aqabah"
        },
        {
          "Cities": [],
          "StateName": "al-Balqa'"
        },
        {
          "Cities": [],
          "StateName": "al-Karak"
        },
        {
          "Cities": [],
          "StateName": "al-Mafraq"
        },
        {
          "Cities": [],
          "StateName": "at-Tafilah"
        },
        {
          "Cities": [],
          "StateName": "az-Zarqa'"
        }
      ],
      "CountryName": "Jordan"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Akmecet"
        },
        {
          "Cities": [],
          "StateName": "Akmola"
        },
        {
          "Cities": [],
          "StateName": "Aktobe"
        },
        {
          "Cities": [],
          "StateName": "Almati"
        },
        {
          "Cities": [],
          "StateName": "Atirau"
        },
        {
          "Cities": [],
          "StateName": "Batis Kazakstan"
        },
        {
          "Cities": [],
          "StateName": "Burlinsky Region"
        },
        {
          "Cities": [],
          "StateName": "Karagandi"
        },
        {
          "Cities": [],
          "StateName": "Kostanay"
        },
        {
          "Cities": [],
          "StateName": "Mankistau"
        },
        {
          "Cities": [],
          "StateName": "Ontustik Kazakstan"
        },
        {
          "Cities": [],
          "StateName": "Pavlodar"
        },
        {
          "Cities": [],
          "StateName": "Sigis Kazakstan"
        },
        {
          "Cities": [],
          "StateName": "Soltustik Kazakstan"
        },
        {
          "Cities": [],
          "StateName": "Taraz"
        }
      ],
      "CountryName": "Kazakhstan"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Central"
        },
        {
          "Cities": [],
          "StateName": "Coast"
        },
        {
          "Cities": [],
          "StateName": "Eastern"
        },
        {
          "Cities": [],
          "StateName": "Nairobi"
        },
        {
          "Cities": [],
          "StateName": "North Eastern"
        },
        {
          "Cities": [],
          "StateName": "Nyanza"
        },
        {
          "Cities": [],
          "StateName": "Rift Valley"
        },
        {
          "Cities": [],
          "StateName": "Western"
        }
      ],
      "CountryName": "Kenya"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Abaiang"
        },
        {
          "Cities": [],
          "StateName": "Abemana"
        },
        {
          "Cities": [],
          "StateName": "Aranuka"
        },
        {
          "Cities": [],
          "StateName": "Arorae"
        },
        {
          "Cities": [],
          "StateName": "Banaba"
        },
        {
          "Cities": [],
          "StateName": "Beru"
        },
        {
          "Cities": [],
          "StateName": "Butaritari"
        },
        {
          "Cities": [],
          "StateName": "Kiritimati"
        },
        {
          "Cities": [],
          "StateName": "Kuria"
        },
        {
          "Cities": [],
          "StateName": "Maiana"
        },
        {
          "Cities": [],
          "StateName": "Makin"
        },
        {
          "Cities": [],
          "StateName": "Marakei"
        },
        {
          "Cities": [],
          "StateName": "Nikunau"
        },
        {
          "Cities": [],
          "StateName": "Nonouti"
        },
        {
          "Cities": [],
          "StateName": "Onotoa"
        },
        {
          "Cities": [],
          "StateName": "Phoenix Islands"
        },
        {
          "Cities": [],
          "StateName": "Tabiteuea North"
        },
        {
          "Cities": [],
          "StateName": "Tabiteuea South"
        },
        {
          "Cities": [],
          "StateName": "Tabuaeran"
        },
        {
          "Cities": [],
          "StateName": "Tamana"
        },
        {
          "Cities": [],
          "StateName": "Tarawa North"
        },
        {
          "Cities": [],
          "StateName": "Tarawa South"
        },
        {
          "Cities": [],
          "StateName": "Teraina"
        }
      ],
      "CountryName": "Kiribati"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Chagangdo"
        },
        {
          "Cities": [],
          "StateName": "Hamgyeongbukto"
        },
        {
          "Cities": [],
          "StateName": "Hamgyeongnamdo"
        },
        {
          "Cities": [],
          "StateName": "Hwanghaebukto"
        },
        {
          "Cities": [],
          "StateName": "Hwanghaenamdo"
        },
        {
          "Cities": [],
          "StateName": "Kaeseong"
        },
        {
          "Cities": [],
          "StateName": "Kangweon"
        },
        {
          "Cities": [],
          "StateName": "Nampo"
        },
        {
          "Cities": [],
          "StateName": "Pyeonganbukto"
        },
        {
          "Cities": [],
          "StateName": "Pyeongannamdo"
        },
        {
          "Cities": [],
          "StateName": "Pyeongyang"
        },
        {
          "Cities": [],
          "StateName": "Yanggang"
        }
      ],
      "CountryName": "Korea North"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Busan"
        },
        {
          "Cities": [],
          "StateName": "Cheju"
        },
        {
          "Cities": [],
          "StateName": "Chollabuk"
        },
        {
          "Cities": [],
          "StateName": "Chollanam"
        },
        {
          "Cities": [],
          "StateName": "Chungbuk"
        },
        {
          "Cities": [],
          "StateName": "Chungcheongbuk"
        },
        {
          "Cities": [],
          "StateName": "Chungcheongnam"
        },
        {
          "Cities": [],
          "StateName": "Chungnam"
        },
        {
          "Cities": [],
          "StateName": "Daegu"
        },
        {
          "Cities": [],
          "StateName": "Gangwon-do"
        },
        {
          "Cities": [],
          "StateName": "Goyang-si"
        },
        {
          "Cities": [],
          "StateName": "Gyeonggi-do"
        },
        {
          "Cities": [],
          "StateName": "Gyeongsang "
        },
        {
          "Cities": [],
          "StateName": "Gyeongsangnam-do"
        },
        {
          "Cities": [],
          "StateName": "Incheon"
        },
        {
          "Cities": [],
          "StateName": "Jeju-Si"
        },
        {
          "Cities": [],
          "StateName": "Jeonbuk"
        },
        {
          "Cities": [],
          "StateName": "Kangweon"
        },
        {
          "Cities": [],
          "StateName": "Kwangju"
        },
        {
          "Cities": [],
          "StateName": "Kyeonggi"
        },
        {
          "Cities": [],
          "StateName": "Kyeongsangbuk"
        },
        {
          "Cities": [],
          "StateName": "Kyeongsangnam"
        },
        {
          "Cities": [],
          "StateName": "Kyonggi-do"
        },
        {
          "Cities": [],
          "StateName": "Kyungbuk-Do"
        },
        {
          "Cities": [],
          "StateName": "Kyunggi-Do"
        },
        {
          "Cities": [],
          "StateName": "Kyunggi-do"
        },
        {
          "Cities": [],
          "StateName": "Pusan"
        },
        {
          "Cities": [],
          "StateName": "Seoul"
        },
        {
          "Cities": [],
          "StateName": "Sudogwon"
        },
        {
          "Cities": [],
          "StateName": "Taegu"
        },
        {
          "Cities": [],
          "StateName": "Taejeon"
        },
        {
          "Cities": [],
          "StateName": "Taejon-gwangyoksi"
        },
        {
          "Cities": [],
          "StateName": "Ulsan"
        },
        {
          "Cities": [],
          "StateName": "Wonju"
        },
        {
          "Cities": [],
          "StateName": "gwangyoksi"
        }
      ],
      "CountryName": "Korea South"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Al Asimah"
        },
        {
          "Cities": [],
          "StateName": "Hawalli"
        },
        {
          "Cities": [],
          "StateName": "Mishref"
        },
        {
          "Cities": [],
          "StateName": "Qadesiya"
        },
        {
          "Cities": [],
          "StateName": "Safat"
        },
        {
          "Cities": [],
          "StateName": "Salmiya"
        },
        {
          "Cities": [],
          "StateName": "al-Ahmadi"
        },
        {
          "Cities": [],
          "StateName": "al-Farwaniyah"
        },
        {
          "Cities": [],
          "StateName": "al-Jahra"
        },
        {
          "Cities": [],
          "StateName": "al-Kuwayt"
        }
      ],
      "CountryName": "Kuwait"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Batken"
        },
        {
          "Cities": [],
          "StateName": "Bishkek"
        },
        {
          "Cities": [],
          "StateName": "Chui"
        },
        {
          "Cities": [],
          "StateName": "Issyk-Kul"
        },
        {
          "Cities": [],
          "StateName": "Jalal-Abad"
        },
        {
          "Cities": [],
          "StateName": "Naryn"
        },
        {
          "Cities": [],
          "StateName": "Osh"
        },
        {
          "Cities": [],
          "StateName": "Talas"
        }
      ],
      "CountryName": "Kyrgyzstan"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Attopu"
        },
        {
          "Cities": [],
          "StateName": "Bokeo"
        },
        {
          "Cities": [],
          "StateName": "Bolikhamsay"
        },
        {
          "Cities": [],
          "StateName": "Champasak"
        },
        {
          "Cities": [],
          "StateName": "Houaphanh"
        },
        {
          "Cities": [],
          "StateName": "Khammouane"
        },
        {
          "Cities": [],
          "StateName": "Luang Nam Tha"
        },
        {
          "Cities": [],
          "StateName": "Luang Prabang"
        },
        {
          "Cities": [],
          "StateName": "Oudomxay"
        },
        {
          "Cities": [],
          "StateName": "Phongsaly"
        },
        {
          "Cities": [],
          "StateName": "Saravan"
        },
        {
          "Cities": [],
          "StateName": "Savannakhet"
        },
        {
          "Cities": [],
          "StateName": "Sekong"
        },
        {
          "Cities": [],
          "StateName": "Viangchan Prefecture"
        },
        {
          "Cities": [],
          "StateName": "Viangchan Province"
        },
        {
          "Cities": [],
          "StateName": "Xaignabury"
        },
        {
          "Cities": [],
          "StateName": "Xiang Khuang"
        }
      ],
      "CountryName": "Laos"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Aizkraukles"
        },
        {
          "Cities": [],
          "StateName": "Aluksnes"
        },
        {
          "Cities": [],
          "StateName": "Balvu"
        },
        {
          "Cities": [],
          "StateName": "Bauskas"
        },
        {
          "Cities": [],
          "StateName": "Cesu"
        },
        {
          "Cities": [],
          "StateName": "Daugavpils"
        },
        {
          "Cities": [],
          "StateName": "Daugavpils City"
        },
        {
          "Cities": [],
          "StateName": "Dobeles"
        },
        {
          "Cities": [],
          "StateName": "Gulbenes"
        },
        {
          "Cities": [],
          "StateName": "Jekabspils"
        },
        {
          "Cities": [],
          "StateName": "Jelgava"
        },
        {
          "Cities": [],
          "StateName": "Jelgavas"
        },
        {
          "Cities": [],
          "StateName": "Jurmala City"
        },
        {
          "Cities": [],
          "StateName": "Kraslavas"
        },
        {
          "Cities": [],
          "StateName": "Kuldigas"
        },
        {
          "Cities": [],
          "StateName": "Liepaja"
        },
        {
          "Cities": [],
          "StateName": "Liepajas"
        },
        {
          "Cities": [],
          "StateName": "Limbazhu"
        },
        {
          "Cities": [],
          "StateName": "Ludzas"
        },
        {
          "Cities": [],
          "StateName": "Madonas"
        },
        {
          "Cities": [],
          "StateName": "Ogres"
        },
        {
          "Cities": [],
          "StateName": "Preilu"
        },
        {
          "Cities": [],
          "StateName": "Rezekne"
        },
        {
          "Cities": [],
          "StateName": "Rezeknes"
        },
        {
          "Cities": [],
          "StateName": "Riga"
        },
        {
          "Cities": [],
          "StateName": "Rigas"
        },
        {
          "Cities": [],
          "StateName": "Saldus"
        },
        {
          "Cities": [],
          "StateName": "Talsu"
        },
        {
          "Cities": [],
          "StateName": "Tukuma"
        },
        {
          "Cities": [],
          "StateName": "Valkas"
        },
        {
          "Cities": [],
          "StateName": "Valmieras"
        },
        {
          "Cities": [],
          "StateName": "Ventspils"
        },
        {
          "Cities": [],
          "StateName": "Ventspils City"
        }
      ],
      "CountryName": "Latvia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Beirut"
        },
        {
          "Cities": [],
          "StateName": "Jabal Lubnan"
        },
        {
          "Cities": [],
          "StateName": "Mohafazat Liban-Nord"
        },
        {
          "Cities": [],
          "StateName": "Mohafazat Mont-Liban"
        },
        {
          "Cities": [],
          "StateName": "Sidon"
        },
        {
          "Cities": [],
          "StateName": "al-Biqa"
        },
        {
          "Cities": [],
          "StateName": "al-Janub"
        },
        {
          "Cities": [],
          "StateName": "an-Nabatiyah"
        },
        {
          "Cities": [],
          "StateName": "ash-Shamal"
        }
      ],
      "CountryName": "Lebanon"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Berea"
        },
        {
          "Cities": [],
          "StateName": "Butha-Buthe"
        },
        {
          "Cities": [],
          "StateName": "Leribe"
        },
        {
          "Cities": [],
          "StateName": "Mafeteng"
        },
        {
          "Cities": [],
          "StateName": "Maseru"
        },
        {
          "Cities": [],
          "StateName": "Mohale's Hoek"
        },
        {
          "Cities": [],
          "StateName": "Mokhotlong"
        },
        {
          "Cities": [],
          "StateName": "Qacha's Nek"
        },
        {
          "Cities": [],
          "StateName": "Quthing"
        },
        {
          "Cities": [],
          "StateName": "Thaba-Tseka"
        }
      ],
      "CountryName": "Lesotho"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Bomi"
        },
        {
          "Cities": [],
          "StateName": "Bong"
        },
        {
          "Cities": [],
          "StateName": "Grand Bassa"
        },
        {
          "Cities": [],
          "StateName": "Grand Cape Mount"
        },
        {
          "Cities": [],
          "StateName": "Grand Gedeh"
        },
        {
          "Cities": [],
          "StateName": "Loffa"
        },
        {
          "Cities": [],
          "StateName": "Margibi"
        },
        {
          "Cities": [],
          "StateName": "Maryland and Grand Kru"
        },
        {
          "Cities": [],
          "StateName": "Montserrado"
        },
        {
          "Cities": [],
          "StateName": "Nimba"
        },
        {
          "Cities": [],
          "StateName": "Rivercess"
        },
        {
          "Cities": [],
          "StateName": "Sinoe"
        }
      ],
      "CountryName": "Liberia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Ajdabiya"
        },
        {
          "Cities": [],
          "StateName": "Banghazi"
        },
        {
          "Cities": [],
          "StateName": "Darnah"
        },
        {
          "Cities": [],
          "StateName": "Ghadamis"
        },
        {
          "Cities": [],
          "StateName": "Gharyan"
        },
        {
          "Cities": [],
          "StateName": "Misratah"
        },
        {
          "Cities": [],
          "StateName": "Murzuq"
        },
        {
          "Cities": [],
          "StateName": "Sabha"
        },
        {
          "Cities": [],
          "StateName": "Sawfajjin"
        },
        {
          "Cities": [],
          "StateName": "Surt"
        },
        {
          "Cities": [],
          "StateName": "Tarabulus"
        },
        {
          "Cities": [],
          "StateName": "Tarhunah"
        },
        {
          "Cities": [],
          "StateName": "Tripolitania"
        },
        {
          "Cities": [],
          "StateName": "Tubruq"
        },
        {
          "Cities": [],
          "StateName": "Yafran"
        },
        {
          "Cities": [],
          "StateName": "Zlitan"
        },
        {
          "Cities": [],
          "StateName": "al-'Aziziyah"
        },
        {
          "Cities": [],
          "StateName": "al-Fatih"
        },
        {
          "Cities": [],
          "StateName": "al-Jabal al Akhdar"
        },
        {
          "Cities": [],
          "StateName": "al-Jufrah"
        },
        {
          "Cities": [],
          "StateName": "al-Khums"
        },
        {
          "Cities": [],
          "StateName": "al-Kufrah"
        },
        {
          "Cities": [],
          "StateName": "an-Nuqat al-Khams"
        },
        {
          "Cities": [],
          "StateName": "ash-Shati'"
        },
        {
          "Cities": [],
          "StateName": "az-Zawiyah"
        }
      ],
      "CountryName": "Libya"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Balzers"
        },
        {
          "Cities": [],
          "StateName": "Eschen"
        },
        {
          "Cities": [],
          "StateName": "Gamprin"
        },
        {
          "Cities": [],
          "StateName": "Mauren"
        },
        {
          "Cities": [],
          "StateName": "Planken"
        },
        {
          "Cities": [],
          "StateName": "Ruggell"
        },
        {
          "Cities": [],
          "StateName": "Schaan"
        },
        {
          "Cities": [],
          "StateName": "Schellenberg"
        },
        {
          "Cities": [],
          "StateName": "Triesen"
        },
        {
          "Cities": [],
          "StateName": "Triesenberg"
        },
        {
          "Cities": [],
          "StateName": "Vaduz"
        }
      ],
      "CountryName": "Liechtenstein"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Alytaus"
        },
        {
          "Cities": [],
          "StateName": "Anyksciai"
        },
        {
          "Cities": [],
          "StateName": "Kauno"
        },
        {
          "Cities": [],
          "StateName": "Klaipedos"
        },
        {
          "Cities": [],
          "StateName": "Marijampoles"
        },
        {
          "Cities": [],
          "StateName": "Panevezhio"
        },
        {
          "Cities": [],
          "StateName": "Panevezys"
        },
        {
          "Cities": [],
          "StateName": "Shiauliu"
        },
        {
          "Cities": [],
          "StateName": "Taurages"
        },
        {
          "Cities": [],
          "StateName": "Telshiu"
        },
        {
          "Cities": [],
          "StateName": "Telsiai"
        },
        {
          "Cities": [],
          "StateName": "Utenos"
        },
        {
          "Cities": [],
          "StateName": "Vilniaus"
        }
      ],
      "CountryName": "Lithuania"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Capellen"
        },
        {
          "Cities": [],
          "StateName": "Clervaux"
        },
        {
          "Cities": [],
          "StateName": "Diekirch"
        },
        {
          "Cities": [],
          "StateName": "Echternach"
        },
        {
          "Cities": [],
          "StateName": "Esch-sur-Alzette"
        },
        {
          "Cities": [],
          "StateName": "Grevenmacher"
        },
        {
          "Cities": [],
          "StateName": "Luxembourg"
        },
        {
          "Cities": [],
          "StateName": "Mersch"
        },
        {
          "Cities": [],
          "StateName": "Redange"
        },
        {
          "Cities": [],
          "StateName": "Remich"
        },
        {
          "Cities": [],
          "StateName": "Vianden"
        },
        {
          "Cities": [],
          "StateName": "Wiltz"
        }
      ],
      "CountryName": "Luxembourg"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Macau"
        }
      ],
      "CountryName": "Macau S.A.R."
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Berovo"
        },
        {
          "Cities": [],
          "StateName": "Bitola"
        },
        {
          "Cities": [],
          "StateName": "Brod"
        },
        {
          "Cities": [],
          "StateName": "Debar"
        },
        {
          "Cities": [],
          "StateName": "Delchevo"
        },
        {
          "Cities": [],
          "StateName": "Demir Hisar"
        },
        {
          "Cities": [],
          "StateName": "Gevgelija"
        },
        {
          "Cities": [],
          "StateName": "Gostivar"
        },
        {
          "Cities": [],
          "StateName": "Kavadarci"
        },
        {
          "Cities": [],
          "StateName": "Kichevo"
        },
        {
          "Cities": [],
          "StateName": "Kochani"
        },
        {
          "Cities": [],
          "StateName": "Kratovo"
        },
        {
          "Cities": [],
          "StateName": "Kriva Palanka"
        },
        {
          "Cities": [],
          "StateName": "Krushevo"
        },
        {
          "Cities": [],
          "StateName": "Kumanovo"
        },
        {
          "Cities": [],
          "StateName": "Negotino"
        },
        {
          "Cities": [],
          "StateName": "Ohrid"
        },
        {
          "Cities": [],
          "StateName": "Prilep"
        },
        {
          "Cities": [],
          "StateName": "Probishtip"
        },
        {
          "Cities": [],
          "StateName": "Radovish"
        },
        {
          "Cities": [],
          "StateName": "Resen"
        },
        {
          "Cities": [],
          "StateName": "Shtip"
        },
        {
          "Cities": [],
          "StateName": "Skopje"
        },
        {
          "Cities": [],
          "StateName": "Struga"
        },
        {
          "Cities": [],
          "StateName": "Strumica"
        },
        {
          "Cities": [],
          "StateName": "Sveti Nikole"
        },
        {
          "Cities": [],
          "StateName": "Tetovo"
        },
        {
          "Cities": [],
          "StateName": "Valandovo"
        },
        {
          "Cities": [],
          "StateName": "Veles"
        },
        {
          "Cities": [],
          "StateName": "Vinica"
        }
      ],
      "CountryName": "Macedonia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Antananarivo"
        },
        {
          "Cities": [],
          "StateName": "Antsiranana"
        },
        {
          "Cities": [],
          "StateName": "Fianarantsoa"
        },
        {
          "Cities": [],
          "StateName": "Mahajanga"
        },
        {
          "Cities": [],
          "StateName": "Toamasina"
        },
        {
          "Cities": [],
          "StateName": "Toliary"
        }
      ],
      "CountryName": "Madagascar"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Balaka"
        },
        {
          "Cities": [],
          "StateName": "Blantyre City"
        },
        {
          "Cities": [],
          "StateName": "Chikwawa"
        },
        {
          "Cities": [],
          "StateName": "Chiradzulu"
        },
        {
          "Cities": [],
          "StateName": "Chitipa"
        },
        {
          "Cities": [],
          "StateName": "Dedza"
        },
        {
          "Cities": [],
          "StateName": "Dowa"
        },
        {
          "Cities": [],
          "StateName": "Karonga"
        },
        {
          "Cities": [],
          "StateName": "Kasungu"
        },
        {
          "Cities": [],
          "StateName": "Lilongwe City"
        },
        {
          "Cities": [],
          "StateName": "Machinga"
        },
        {
          "Cities": [],
          "StateName": "Mangochi"
        },
        {
          "Cities": [],
          "StateName": "Mchinji"
        },
        {
          "Cities": [],
          "StateName": "Mulanje"
        },
        {
          "Cities": [],
          "StateName": "Mwanza"
        },
        {
          "Cities": [],
          "StateName": "Mzimba"
        },
        {
          "Cities": [],
          "StateName": "Mzuzu City"
        },
        {
          "Cities": [],
          "StateName": "Nkhata Bay"
        },
        {
          "Cities": [],
          "StateName": "Nkhotakota"
        },
        {
          "Cities": [],
          "StateName": "Nsanje"
        },
        {
          "Cities": [],
          "StateName": "Ntcheu"
        },
        {
          "Cities": [],
          "StateName": "Ntchisi"
        },
        {
          "Cities": [],
          "StateName": "Phalombe"
        },
        {
          "Cities": [],
          "StateName": "Rumphi"
        },
        {
          "Cities": [],
          "StateName": "Salima"
        },
        {
          "Cities": [],
          "StateName": "Thyolo"
        },
        {
          "Cities": [],
          "StateName": "Zomba Municipality"
        }
      ],
      "CountryName": "Malawi"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Johor"
        },
        {
          "Cities": [],
          "StateName": "Kedah"
        },
        {
          "Cities": [],
          "StateName": "Kelantan"
        },
        {
          "Cities": [],
          "StateName": "Kuala Lumpur"
        },
        {
          "Cities": [],
          "StateName": "Labuan"
        },
        {
          "Cities": [],
          "StateName": "Melaka"
        },
        {
          "Cities": [],
          "StateName": "Negeri Johor"
        },
        {
          "Cities": [],
          "StateName": "Negeri Sembilan"
        },
        {
          "Cities": [],
          "StateName": "Pahang"
        },
        {
          "Cities": [],
          "StateName": "Penang"
        },
        {
          "Cities": [],
          "StateName": "Perak"
        },
        {
          "Cities": [],
          "StateName": "Perlis"
        },
        {
          "Cities": [],
          "StateName": "Pulau Pinang"
        },
        {
          "Cities": [],
          "StateName": "Sabah"
        },
        {
          "Cities": [],
          "StateName": "Sarawak"
        },
        {
          "Cities": [],
          "StateName": "Selangor"
        },
        {
          "Cities": [],
          "StateName": "Sembilan"
        },
        {
          "Cities": [],
          "StateName": "Terengganu"
        }
      ],
      "CountryName": "Malaysia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Alif Alif"
        },
        {
          "Cities": [],
          "StateName": "Alif Dhaal"
        },
        {
          "Cities": [],
          "StateName": "Baa"
        },
        {
          "Cities": [],
          "StateName": "Dhaal"
        },
        {
          "Cities": [],
          "StateName": "Faaf"
        },
        {
          "Cities": [],
          "StateName": "Gaaf Alif"
        },
        {
          "Cities": [],
          "StateName": "Gaaf Dhaal"
        },
        {
          "Cities": [],
          "StateName": "Ghaviyani"
        },
        {
          "Cities": [],
          "StateName": "Haa Alif"
        },
        {
          "Cities": [],
          "StateName": "Haa Dhaal"
        },
        {
          "Cities": [],
          "StateName": "Kaaf"
        },
        {
          "Cities": [],
          "StateName": "Laam"
        },
        {
          "Cities": [],
          "StateName": "Lhaviyani"
        },
        {
          "Cities": [],
          "StateName": "Male"
        },
        {
          "Cities": [],
          "StateName": "Miim"
        },
        {
          "Cities": [],
          "StateName": "Nuun"
        },
        {
          "Cities": [],
          "StateName": "Raa"
        },
        {
          "Cities": [],
          "StateName": "Shaviyani"
        },
        {
          "Cities": [],
          "StateName": "Siin"
        },
        {
          "Cities": [],
          "StateName": "Thaa"
        },
        {
          "Cities": [],
          "StateName": "Vaav"
        }
      ],
      "CountryName": "Maldives"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Bamako"
        },
        {
          "Cities": [],
          "StateName": "Gao"
        },
        {
          "Cities": [],
          "StateName": "Kayes"
        },
        {
          "Cities": [],
          "StateName": "Kidal"
        },
        {
          "Cities": [],
          "StateName": "Koulikoro"
        },
        {
          "Cities": [],
          "StateName": "Mopti"
        },
        {
          "Cities": [],
          "StateName": "Segou"
        },
        {
          "Cities": [],
          "StateName": "Sikasso"
        },
        {
          "Cities": [],
          "StateName": "Tombouctou"
        }
      ],
      "CountryName": "Mali"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Gozo and Comino"
        },
        {
          "Cities": [],
          "StateName": "Inner Harbour"
        },
        {
          "Cities": [],
          "StateName": "Northern"
        },
        {
          "Cities": [],
          "StateName": "Outer Harbour"
        },
        {
          "Cities": [],
          "StateName": "South Eastern"
        },
        {
          "Cities": [],
          "StateName": "Valletta"
        },
        {
          "Cities": [],
          "StateName": "Western"
        }
      ],
      "CountryName": "Malta"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Castletown"
        },
        {
          "Cities": [],
          "StateName": "Douglas"
        },
        {
          "Cities": [],
          "StateName": "Laxey"
        },
        {
          "Cities": [],
          "StateName": "Onchan"
        },
        {
          "Cities": [],
          "StateName": "Peel"
        },
        {
          "Cities": [],
          "StateName": "Port Erin"
        },
        {
          "Cities": [],
          "StateName": "Port Saint Mary"
        },
        {
          "Cities": [],
          "StateName": "Ramsey"
        }
      ],
      "CountryName": "Man (Isle of)"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Ailinlaplap"
        },
        {
          "Cities": [],
          "StateName": "Ailuk"
        },
        {
          "Cities": [],
          "StateName": "Arno"
        },
        {
          "Cities": [],
          "StateName": "Aur"
        },
        {
          "Cities": [],
          "StateName": "Bikini"
        },
        {
          "Cities": [],
          "StateName": "Ebon"
        },
        {
          "Cities": [],
          "StateName": "Enewetak"
        },
        {
          "Cities": [],
          "StateName": "Jabat"
        },
        {
          "Cities": [],
          "StateName": "Jaluit"
        },
        {
          "Cities": [],
          "StateName": "Kili"
        },
        {
          "Cities": [],
          "StateName": "Kwajalein"
        },
        {
          "Cities": [],
          "StateName": "Lae"
        },
        {
          "Cities": [],
          "StateName": "Lib"
        },
        {
          "Cities": [],
          "StateName": "Likiep"
        },
        {
          "Cities": [],
          "StateName": "Majuro"
        },
        {
          "Cities": [],
          "StateName": "Maloelap"
        },
        {
          "Cities": [],
          "StateName": "Mejit"
        },
        {
          "Cities": [],
          "StateName": "Mili"
        },
        {
          "Cities": [],
          "StateName": "Namorik"
        },
        {
          "Cities": [],
          "StateName": "Namu"
        },
        {
          "Cities": [],
          "StateName": "Rongelap"
        },
        {
          "Cities": [],
          "StateName": "Ujae"
        },
        {
          "Cities": [],
          "StateName": "Utrik"
        },
        {
          "Cities": [],
          "StateName": "Wotho"
        },
        {
          "Cities": [],
          "StateName": "Wotje"
        }
      ],
      "CountryName": "Marshall Islands"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Fort-de-France"
        },
        {
          "Cities": [],
          "StateName": "La Trinite"
        },
        {
          "Cities": [],
          "StateName": "Le Marin"
        },
        {
          "Cities": [],
          "StateName": "Saint-Pierre"
        }
      ],
      "CountryName": "Martinique"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Adrar"
        },
        {
          "Cities": [],
          "StateName": "Assaba"
        },
        {
          "Cities": [],
          "StateName": "Brakna"
        },
        {
          "Cities": [],
          "StateName": "Dhakhlat Nawadibu"
        },
        {
          "Cities": [],
          "StateName": "Hudh-al-Gharbi"
        },
        {
          "Cities": [],
          "StateName": "Hudh-ash-Sharqi"
        },
        {
          "Cities": [],
          "StateName": "Inshiri"
        },
        {
          "Cities": [],
          "StateName": "Nawakshut"
        },
        {
          "Cities": [],
          "StateName": "Qidimagha"
        },
        {
          "Cities": [],
          "StateName": "Qurqul"
        },
        {
          "Cities": [],
          "StateName": "Taqant"
        },
        {
          "Cities": [],
          "StateName": "Tiris Zammur"
        },
        {
          "Cities": [],
          "StateName": "Trarza"
        }
      ],
      "CountryName": "Mauritania"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Black River"
        },
        {
          "Cities": [],
          "StateName": "Eau Coulee"
        },
        {
          "Cities": [],
          "StateName": "Flacq"
        },
        {
          "Cities": [],
          "StateName": "Floreal"
        },
        {
          "Cities": [],
          "StateName": "Grand Port"
        },
        {
          "Cities": [],
          "StateName": "Moka"
        },
        {
          "Cities": [],
          "StateName": "Pamplempousses"
        },
        {
          "Cities": [],
          "StateName": "Plaines Wilhelm"
        },
        {
          "Cities": [],
          "StateName": "Port Louis"
        },
        {
          "Cities": [],
          "StateName": "Riviere du Rempart"
        },
        {
          "Cities": [],
          "StateName": "Rodrigues"
        },
        {
          "Cities": [],
          "StateName": "Rose Hill"
        },
        {
          "Cities": [],
          "StateName": "Savanne"
        }
      ],
      "CountryName": "Mauritius"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Mayotte"
        },
        {
          "Cities": [],
          "StateName": "Pamanzi"
        }
      ],
      "CountryName": "Mayotte"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Aguascalientes"
        },
        {
          "Cities": [],
          "StateName": "Baja California"
        },
        {
          "Cities": [],
          "StateName": "Baja California Sur"
        },
        {
          "Cities": [],
          "StateName": "Campeche"
        },
        {
          "Cities": [],
          "StateName": "Chiapas"
        },
        {
          "Cities": [],
          "StateName": "Chihuahua"
        },
        {
          "Cities": [],
          "StateName": "Coahuila"
        },
        {
          "Cities": [],
          "StateName": "Colima"
        },
        {
          "Cities": [],
          "StateName": "Distrito Federal"
        },
        {
          "Cities": [],
          "StateName": "Durango"
        },
        {
          "Cities": [],
          "StateName": "Estado de Mexico"
        },
        {
          "Cities": [],
          "StateName": "Guanajuato"
        },
        {
          "Cities": [],
          "StateName": "Guerrero"
        },
        {
          "Cities": [],
          "StateName": "Hidalgo"
        },
        {
          "Cities": [],
          "StateName": "Jalisco"
        },
        {
          "Cities": [],
          "StateName": "Mexico"
        },
        {
          "Cities": [],
          "StateName": "Michoacan"
        },
        {
          "Cities": [],
          "StateName": "Morelos"
        },
        {
          "Cities": [],
          "StateName": "Nayarit"
        },
        {
          "Cities": [],
          "StateName": "Nuevo Leon"
        },
        {
          "Cities": [],
          "StateName": "Oaxaca"
        },
        {
          "Cities": [],
          "StateName": "Puebla"
        },
        {
          "Cities": [],
          "StateName": "Queretaro"
        },
        {
          "Cities": [],
          "StateName": "Quintana Roo"
        },
        {
          "Cities": [],
          "StateName": "San Luis Potosi"
        },
        {
          "Cities": [],
          "StateName": "Sinaloa"
        },
        {
          "Cities": [],
          "StateName": "Sonora"
        },
        {
          "Cities": [],
          "StateName": "Tabasco"
        },
        {
          "Cities": [],
          "StateName": "Tamaulipas"
        },
        {
          "Cities": [],
          "StateName": "Tlaxcala"
        },
        {
          "Cities": [],
          "StateName": "Veracruz"
        },
        {
          "Cities": [],
          "StateName": "Yucatan"
        },
        {
          "Cities": [],
          "StateName": "Zacatecas"
        }
      ],
      "CountryName": "Mexico"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Chuuk"
        },
        {
          "Cities": [],
          "StateName": "Kusaie"
        },
        {
          "Cities": [],
          "StateName": "Pohnpei"
        },
        {
          "Cities": [],
          "StateName": "Yap"
        }
      ],
      "CountryName": "Micronesia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Balti"
        },
        {
          "Cities": [],
          "StateName": "Cahul"
        },
        {
          "Cities": [],
          "StateName": "Chisinau"
        },
        {
          "Cities": [],
          "StateName": "Chisinau Oras"
        },
        {
          "Cities": [],
          "StateName": "Edinet"
        },
        {
          "Cities": [],
          "StateName": "Gagauzia"
        },
        {
          "Cities": [],
          "StateName": "Lapusna"
        },
        {
          "Cities": [],
          "StateName": "Orhei"
        },
        {
          "Cities": [],
          "StateName": "Soroca"
        },
        {
          "Cities": [],
          "StateName": "Taraclia"
        },
        {
          "Cities": [],
          "StateName": "Tighina"
        },
        {
          "Cities": [],
          "StateName": "Transnistria"
        },
        {
          "Cities": [],
          "StateName": "Ungheni"
        }
      ],
      "CountryName": "Moldova"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Fontvieille"
        },
        {
          "Cities": [],
          "StateName": "La Condamine"
        },
        {
          "Cities": [],
          "StateName": "Monaco-Ville"
        },
        {
          "Cities": [],
          "StateName": "Monte Carlo"
        }
      ],
      "CountryName": "Monaco"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Arhangaj"
        },
        {
          "Cities": [],
          "StateName": "Bajan-Olgij"
        },
        {
          "Cities": [],
          "StateName": "Bajanhongor"
        },
        {
          "Cities": [],
          "StateName": "Bulgan"
        },
        {
          "Cities": [],
          "StateName": "Darhan-Uul"
        },
        {
          "Cities": [],
          "StateName": "Dornod"
        },
        {
          "Cities": [],
          "StateName": "Dornogovi"
        },
        {
          "Cities": [],
          "StateName": "Dundgovi"
        },
        {
          "Cities": [],
          "StateName": "Govi-Altaj"
        },
        {
          "Cities": [],
          "StateName": "Govisumber"
        },
        {
          "Cities": [],
          "StateName": "Hentij"
        },
        {
          "Cities": [],
          "StateName": "Hovd"
        },
        {
          "Cities": [],
          "StateName": "Hovsgol"
        },
        {
          "Cities": [],
          "StateName": "Omnogovi"
        },
        {
          "Cities": [],
          "StateName": "Orhon"
        },
        {
          "Cities": [],
          "StateName": "Ovorhangaj"
        },
        {
          "Cities": [],
          "StateName": "Selenge"
        },
        {
          "Cities": [],
          "StateName": "Suhbaatar"
        },
        {
          "Cities": [],
          "StateName": "Tov"
        },
        {
          "Cities": [],
          "StateName": "Ulaanbaatar"
        },
        {
          "Cities": [],
          "StateName": "Uvs"
        },
        {
          "Cities": [],
          "StateName": "Zavhan"
        }
      ],
      "CountryName": "Mongolia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Montserrat"
        }
      ],
      "CountryName": "Montserrat"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Agadir"
        },
        {
          "Cities": [],
          "StateName": "Casablanca"
        },
        {
          "Cities": [],
          "StateName": "Chaouia-Ouardigha"
        },
        {
          "Cities": [],
          "StateName": "Doukkala-Abda"
        },
        {
          "Cities": [],
          "StateName": "Fes-Boulemane"
        },
        {
          "Cities": [],
          "StateName": "Gharb-Chrarda-Beni Hssen"
        },
        {
          "Cities": [],
          "StateName": "Guelmim"
        },
        {
          "Cities": [],
          "StateName": "Kenitra"
        },
        {
          "Cities": [],
          "StateName": "Marrakech-Tensift-Al Haouz"
        },
        {
          "Cities": [],
          "StateName": "Meknes-Tafilalet"
        },
        {
          "Cities": [],
          "StateName": "Oriental"
        },
        {
          "Cities": [],
          "StateName": "Oujda"
        },
        {
          "Cities": [],
          "StateName": "Province de Tanger"
        },
        {
          "Cities": [],
          "StateName": "Rabat-Sale-Zammour-Zaer"
        },
        {
          "Cities": [],
          "StateName": "Sala Al Jadida"
        },
        {
          "Cities": [],
          "StateName": "Settat"
        },
        {
          "Cities": [],
          "StateName": "Souss Massa-Draa"
        },
        {
          "Cities": [],
          "StateName": "Tadla-Azilal"
        },
        {
          "Cities": [],
          "StateName": "Tangier-Tetouan"
        },
        {
          "Cities": [],
          "StateName": "Taza-Al Hoceima-Taounate"
        },
        {
          "Cities": [],
          "StateName": "Wilaya de Casablanca"
        },
        {
          "Cities": [],
          "StateName": "Wilaya de Rabat-Sale"
        }
      ],
      "CountryName": "Morocco"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Cabo Delgado"
        },
        {
          "Cities": [],
          "StateName": "Gaza"
        },
        {
          "Cities": [],
          "StateName": "Inhambane"
        },
        {
          "Cities": [],
          "StateName": "Manica"
        },
        {
          "Cities": [],
          "StateName": "Maputo"
        },
        {
          "Cities": [],
          "StateName": "Maputo Provincia"
        },
        {
          "Cities": [],
          "StateName": "Nampula"
        },
        {
          "Cities": [],
          "StateName": "Niassa"
        },
        {
          "Cities": [],
          "StateName": "Sofala"
        },
        {
          "Cities": [],
          "StateName": "Tete"
        },
        {
          "Cities": [],
          "StateName": "Zambezia"
        }
      ],
      "CountryName": "Mozambique"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Ayeyarwady"
        },
        {
          "Cities": [],
          "StateName": "Bago"
        },
        {
          "Cities": [],
          "StateName": "Chin"
        },
        {
          "Cities": [],
          "StateName": "Kachin"
        },
        {
          "Cities": [],
          "StateName": "Kayah"
        },
        {
          "Cities": [],
          "StateName": "Kayin"
        },
        {
          "Cities": [],
          "StateName": "Magway"
        },
        {
          "Cities": [],
          "StateName": "Mandalay"
        },
        {
          "Cities": [],
          "StateName": "Mon"
        },
        {
          "Cities": [],
          "StateName": "Nay Pyi Taw"
        },
        {
          "Cities": [],
          "StateName": "Rakhine"
        },
        {
          "Cities": [],
          "StateName": "Sagaing"
        },
        {
          "Cities": [],
          "StateName": "Shan"
        },
        {
          "Cities": [],
          "StateName": "Tanintharyi"
        },
        {
          "Cities": [],
          "StateName": "Yangon"
        }
      ],
      "CountryName": "Myanmar"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Caprivi"
        },
        {
          "Cities": [],
          "StateName": "Erongo"
        },
        {
          "Cities": [],
          "StateName": "Hardap"
        },
        {
          "Cities": [],
          "StateName": "Karas"
        },
        {
          "Cities": [],
          "StateName": "Kavango"
        },
        {
          "Cities": [],
          "StateName": "Khomas"
        },
        {
          "Cities": [],
          "StateName": "Kunene"
        },
        {
          "Cities": [],
          "StateName": "Ohangwena"
        },
        {
          "Cities": [],
          "StateName": "Omaheke"
        },
        {
          "Cities": [],
          "StateName": "Omusati"
        },
        {
          "Cities": [],
          "StateName": "Oshana"
        },
        {
          "Cities": [],
          "StateName": "Oshikoto"
        },
        {
          "Cities": [],
          "StateName": "Otjozondjupa"
        }
      ],
      "CountryName": "Namibia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Yaren"
        }
      ],
      "CountryName": "Nauru"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Bagmati"
        },
        {
          "Cities": [],
          "StateName": "Bheri"
        },
        {
          "Cities": [],
          "StateName": "Dhawalagiri"
        },
        {
          "Cities": [],
          "StateName": "Gandaki"
        },
        {
          "Cities": [],
          "StateName": "Janakpur"
        },
        {
          "Cities": [],
          "StateName": "Karnali"
        },
        {
          "Cities": [],
          "StateName": "Koshi"
        },
        {
          "Cities": [],
          "StateName": "Lumbini"
        },
        {
          "Cities": [],
          "StateName": "Mahakali"
        },
        {
          "Cities": [],
          "StateName": "Mechi"
        },
        {
          "Cities": [],
          "StateName": "Narayani"
        },
        {
          "Cities": [],
          "StateName": "Rapti"
        },
        {
          "Cities": [],
          "StateName": "Sagarmatha"
        },
        {
          "Cities": [],
          "StateName": "Seti"
        }
      ],
      "CountryName": "Nepal"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Bonaire"
        },
        {
          "Cities": [],
          "StateName": "Curacao"
        },
        {
          "Cities": [],
          "StateName": "Saba"
        },
        {
          "Cities": [],
          "StateName": "Sint Eustatius"
        },
        {
          "Cities": [],
          "StateName": "Sint Maarten"
        }
      ],
      "CountryName": "Netherlands Antilles"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Amsterdam"
        },
        {
          "Cities": [],
          "StateName": "Benelux"
        },
        {
          "Cities": [],
          "StateName": "Drenthe"
        },
        {
          "Cities": [],
          "StateName": "Flevoland"
        },
        {
          "Cities": [],
          "StateName": "Friesland"
        },
        {
          "Cities": [],
          "StateName": "Gelderland"
        },
        {
          "Cities": [],
          "StateName": "Groningen"
        },
        {
          "Cities": [],
          "StateName": "Limburg"
        },
        {
          "Cities": [],
          "StateName": "Noord-Brabant"
        },
        {
          "Cities": [],
          "StateName": "Noord-Holland"
        },
        {
          "Cities": [],
          "StateName": "Overijssel"
        },
        {
          "Cities": [],
          "StateName": "South Holland"
        },
        {
          "Cities": [],
          "StateName": "Utrecht"
        },
        {
          "Cities": [],
          "StateName": "Zeeland"
        },
        {
          "Cities": [],
          "StateName": "Zuid-Holland"
        }
      ],
      "CountryName": "Netherlands The"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Iles"
        },
        {
          "Cities": [],
          "StateName": "Nord"
        },
        {
          "Cities": [],
          "StateName": "Sud"
        }
      ],
      "CountryName": "New Caledonia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Area Outside Region"
        },
        {
          "Cities": [],
          "StateName": "Auckland"
        },
        {
          "Cities": [],
          "StateName": "Bay of Plenty"
        },
        {
          "Cities": [],
          "StateName": "Canterbury"
        },
        {
          "Cities": [],
          "StateName": "Christchurch"
        },
        {
          "Cities": [],
          "StateName": "Gisborne"
        },
        {
          "Cities": [],
          "StateName": "Hawke's Bay"
        },
        {
          "Cities": [],
          "StateName": "Manawatu-Wanganui"
        },
        {
          "Cities": [],
          "StateName": "Marlborough"
        },
        {
          "Cities": [],
          "StateName": "Nelson"
        },
        {
          "Cities": [],
          "StateName": "Northland"
        },
        {
          "Cities": [],
          "StateName": "Otago"
        },
        {
          "Cities": [],
          "StateName": "Rodney"
        },
        {
          "Cities": [],
          "StateName": "Southland"
        },
        {
          "Cities": [],
          "StateName": "Taranaki"
        },
        {
          "Cities": [],
          "StateName": "Tasman"
        },
        {
          "Cities": [],
          "StateName": "Waikato"
        },
        {
          "Cities": [],
          "StateName": "Wellington"
        },
        {
          "Cities": [],
          "StateName": "West Coast"
        }
      ],
      "CountryName": "New Zealand"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Atlantico Norte"
        },
        {
          "Cities": [],
          "StateName": "Atlantico Sur"
        },
        {
          "Cities": [],
          "StateName": "Boaco"
        },
        {
          "Cities": [],
          "StateName": "Carazo"
        },
        {
          "Cities": [],
          "StateName": "Chinandega"
        },
        {
          "Cities": [],
          "StateName": "Chontales"
        },
        {
          "Cities": [],
          "StateName": "Esteli"
        },
        {
          "Cities": [],
          "StateName": "Granada"
        },
        {
          "Cities": [],
          "StateName": "Jinotega"
        },
        {
          "Cities": [],
          "StateName": "Leon"
        },
        {
          "Cities": [],
          "StateName": "Madriz"
        },
        {
          "Cities": [],
          "StateName": "Managua"
        },
        {
          "Cities": [],
          "StateName": "Masaya"
        },
        {
          "Cities": [],
          "StateName": "Matagalpa"
        },
        {
          "Cities": [],
          "StateName": "Nueva Segovia"
        },
        {
          "Cities": [],
          "StateName": "Rio San Juan"
        },
        {
          "Cities": [],
          "StateName": "Rivas"
        }
      ],
      "CountryName": "Nicaragua"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Agadez"
        },
        {
          "Cities": [],
          "StateName": "Diffa"
        },
        {
          "Cities": [],
          "StateName": "Dosso"
        },
        {
          "Cities": [],
          "StateName": "Maradi"
        },
        {
          "Cities": [],
          "StateName": "Niamey"
        },
        {
          "Cities": [],
          "StateName": "Tahoua"
        },
        {
          "Cities": [],
          "StateName": "Tillabery"
        },
        {
          "Cities": [],
          "StateName": "Zinder"
        }
      ],
      "CountryName": "Niger"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Abia"
        },
        {
          "Cities": [],
          "StateName": "Abuja Federal Capital Territor"
        },
        {
          "Cities": [],
          "StateName": "Adamawa"
        },
        {
          "Cities": [],
          "StateName": "Akwa Ibom"
        },
        {
          "Cities": [],
          "StateName": "Anambra"
        },
        {
          "Cities": [],
          "StateName": "Bauchi"
        },
        {
          "Cities": [],
          "StateName": "Bayelsa"
        },
        {
          "Cities": [],
          "StateName": "Benue"
        },
        {
          "Cities": [],
          "StateName": "Borno"
        },
        {
          "Cities": [],
          "StateName": "Cross River"
        },
        {
          "Cities": [],
          "StateName": "Delta"
        },
        {
          "Cities": [],
          "StateName": "Ebonyi"
        },
        {
          "Cities": [],
          "StateName": "Edo"
        },
        {
          "Cities": [],
          "StateName": "Ekiti"
        },
        {
          "Cities": [],
          "StateName": "Enugu"
        },
        {
          "Cities": [],
          "StateName": "Gombe"
        },
        {
          "Cities": [],
          "StateName": "Imo"
        },
        {
          "Cities": [],
          "StateName": "Jigawa"
        },
        {
          "Cities": [],
          "StateName": "Kaduna"
        },
        {
          "Cities": [],
          "StateName": "Kano"
        },
        {
          "Cities": [],
          "StateName": "Katsina"
        },
        {
          "Cities": [],
          "StateName": "Kebbi"
        },
        {
          "Cities": [],
          "StateName": "Kogi"
        },
        {
          "Cities": [],
          "StateName": "Kwara"
        },
        {
          "Cities": [],
          "StateName": "Lagos"
        },
        {
          "Cities": [],
          "StateName": "Nassarawa"
        },
        {
          "Cities": [],
          "StateName": "Niger"
        },
        {
          "Cities": [],
          "StateName": "Ogun"
        },
        {
          "Cities": [],
          "StateName": "Ondo"
        },
        {
          "Cities": [],
          "StateName": "Osun"
        },
        {
          "Cities": [],
          "StateName": "Oyo"
        },
        {
          "Cities": [],
          "StateName": "Plateau"
        },
        {
          "Cities": [],
          "StateName": "Rivers"
        },
        {
          "Cities": [],
          "StateName": "Sokoto"
        },
        {
          "Cities": [],
          "StateName": "Taraba"
        },
        {
          "Cities": [],
          "StateName": "Yobe"
        },
        {
          "Cities": [],
          "StateName": "Zamfara"
        }
      ],
      "CountryName": "Nigeria"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Niue"
        }
      ],
      "CountryName": "Niue"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Norfolk Island"
        }
      ],
      "CountryName": "Norfolk Island"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Northern Islands"
        },
        {
          "Cities": [],
          "StateName": "Rota"
        },
        {
          "Cities": [],
          "StateName": "Saipan"
        },
        {
          "Cities": [],
          "StateName": "Tinian"
        }
      ],
      "CountryName": "Northern Mariana Islands"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Akershus"
        },
        {
          "Cities": [],
          "StateName": "Aust Agder"
        },
        {
          "Cities": [],
          "StateName": "Bergen"
        },
        {
          "Cities": [],
          "StateName": "Buskerud"
        },
        {
          "Cities": [],
          "StateName": "Finnmark"
        },
        {
          "Cities": [],
          "StateName": "Hedmark"
        },
        {
          "Cities": [],
          "StateName": "Hordaland"
        },
        {
          "Cities": [],
          "StateName": "Moere og Romsdal"
        },
        {
          "Cities": [],
          "StateName": "Nord Trondelag"
        },
        {
          "Cities": [],
          "StateName": "Nordland"
        },
        {
          "Cities": [],
          "StateName": "Oestfold"
        },
        {
          "Cities": [],
          "StateName": "Oppland"
        },
        {
          "Cities": [],
          "StateName": "Oslo"
        },
        {
          "Cities": [],
          "StateName": "Rogaland"
        },
        {
          "Cities": [],
          "StateName": "Soer Troendelag"
        },
        {
          "Cities": [],
          "StateName": "Sogn og Fjordane"
        },
        {
          "Cities": [],
          "StateName": "Stavern"
        },
        {
          "Cities": [],
          "StateName": "Sykkylven"
        },
        {
          "Cities": [],
          "StateName": "Telemark"
        },
        {
          "Cities": [],
          "StateName": "Troms"
        },
        {
          "Cities": [],
          "StateName": "Vest Agder"
        },
        {
          "Cities": [],
          "StateName": "Vestfold"
        },
        {
          "Cities": [],
          "StateName": "ÃÆÃËstfold"
        }
      ],
      "CountryName": "Norway"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Al Buraimi"
        },
        {
          "Cities": [],
          "StateName": "Dhufar"
        },
        {
          "Cities": [],
          "StateName": "Masqat"
        },
        {
          "Cities": [],
          "StateName": "Musandam"
        },
        {
          "Cities": [],
          "StateName": "Rusayl"
        },
        {
          "Cities": [],
          "StateName": "Wadi Kabir"
        },
        {
          "Cities": [],
          "StateName": "ad-Dakhiliyah"
        },
        {
          "Cities": [],
          "StateName": "adh-Dhahirah"
        },
        {
          "Cities": [],
          "StateName": "al-Batinah"
        },
        {
          "Cities": [],
          "StateName": "ash-Sharqiyah"
        }
      ],
      "CountryName": "Oman"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Baluchistan"
        },
        {
          "Cities": [],
          "StateName": "Federal Capital Area"
        },
        {
          "Cities": [],
          "StateName": "Federally administered Tribal "
        },
        {
          "Cities": [],
          "StateName": "North-West Frontier"
        },
        {
          "Cities": [],
          "StateName": "Northern Areas"
        },
        {
          "Cities": [
            "Abohar",
            "Adampur",
            "Ahmedgarh",
            "Ajnala",
            "Akalgarh",
            "Alawalpur",
            "Amloh",
            "Amritsar",
            "Amritsar Cantonment",
            "Anandpur Sahib",
            "Badhni Kalan",
            "Bagh Purana",
            "Balachaur",
            "Banaur",
            "Banga",
            "Banur",
            "Baretta",
            "Bariwala",
            "Barnala",
            "Bassi Pathana",
            "Batala",
            "Bathinda",
            "Begowal",
            "Behrampur",
            "Bhabat",
            "Bhadur",
            "Bhankharpur",
            "Bharoli Kalan",
            "Bhawanigarh",
            "Bhikhi",
            "Bhikhiwind",
            "Bhisiana",
            "Bhogpur",
            "Bhuch",
            "Bhulath",
            "Budha Theh",
            "Budhlada",
            "Chima",
            "Chohal",
            "Dasuya",
            "Daulatpur",
            "Dera Baba Nanak",
            "Dera Bassi",
            "Dhanaula",
            "Dharam Kot",
            "Dhariwal",
            "Dhilwan",
            "Dhuri",
            "Dinanagar",
            "Dirba",
            "Doraha",
            "Faridkot",
            "Fateh Nangal",
            "Fatehgarh Churian",
            "Fatehgarh Sahib",
            "Fazilka",
            "Firozpur",
            "Firozpur Cantonment",
            "Gardhiwala",
            "Garhshankar",
            "Ghagga",
            "Ghanaur",
            "Giddarbaha",
            "Gobindgarh",
            "Goniana",
            "Goraya",
            "Gurdaspur",
            "Guru Har Sahai",
            "Hajipur",
            "Handiaya",
            "Hariana",
            "Hoshiarpur",
            "Hussainpur",
            "Jagraon",
            "Jaitu",
            "Jalalabad",
            "Jalandhar",
            "Jalandhar Cantonment",
            "Jandiala",
            "Jugial",
            "Kalanaur",
            "Kapurthala",
            "Karoran",
            "Kartarpur",
            "Khamanon",
            "Khanauri",
            "Khanna",
            "Kharar",
            "Khem Karan",
            "Kot Fatta",
            "Kot Isa Khan",
            "Kot Kapura",
            "Kotkapura",
            "Kurali",
            "Lalru",
            "Lehra Gaga",
            "Lodhian Khas",
            "Longowal",
            "Ludhiana",
            "Machhiwara",
            "Mahilpur",
            "Majitha",
            "Makhu",
            "Malaut",
            "Malerkotla",
            "Maloud",
            "Mandi Gobindgarh",
            "Mansa",
            "Maur",
            "Moga",
            "Mohali",
            "Moonak",
            "Morinda",
            "Mukerian",
            "Muktsar",
            "Mullanpur Dakha",
            "Mullanpur Garibdas",
            "Munak",
            "Muradpura",
            "Nabha",
            "Nakodar",
            "Nangal",
            "Nawashahr",
            "Naya Nangal",
            "Nehon",
            "Nurmahal",
            "Pathankot",
            "Patiala",
            "Patti",
            "Pattran",
            "Payal",
            "Phagwara",
            "Phillaur",
            "Qadian",
            "Rahon",
            "Raikot",
            "Raja Sansi",
            "Rajpura",
            "Ram Das",
            "Raman",
            "Rampura",
            "Rayya",
            "Rupnagar",
            "Rurki Kasba",
            "Sahnewal",
            "Samana",
            "Samrala",
            "Sanaur",
            "Sangat",
            "Sangrur",
            "Sansarpur",
            "Sardulgarh",
            "Shahkot",
            "Sham Churasi",
            "Shekhpura",
            "Sirhind",
            "Sri Hargobindpur",
            "Sujanpur",
            "Sultanpur Lodhi",
            "Sunam",
            "Talwandi Bhai",
            "Talwara",
            "Tappa",
            "Tarn Taran",
            "Urmar Tanda",
            "Zira",
            "Zirakpur"
          ],
          "StateName": "Punjab"
        },
        {
          "Cities": [],
          "StateName": "Sind"
        }
      ],
      "CountryName": "Pakistan"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Aimeliik"
        },
        {
          "Cities": [],
          "StateName": "Airai"
        },
        {
          "Cities": [],
          "StateName": "Angaur"
        },
        {
          "Cities": [],
          "StateName": "Hatobohei"
        },
        {
          "Cities": [],
          "StateName": "Kayangel"
        },
        {
          "Cities": [],
          "StateName": "Koror"
        },
        {
          "Cities": [],
          "StateName": "Melekeok"
        },
        {
          "Cities": [],
          "StateName": "Ngaraard"
        },
        {
          "Cities": [],
          "StateName": "Ngardmau"
        },
        {
          "Cities": [],
          "StateName": "Ngaremlengui"
        },
        {
          "Cities": [],
          "StateName": "Ngatpang"
        },
        {
          "Cities": [],
          "StateName": "Ngchesar"
        },
        {
          "Cities": [],
          "StateName": "Ngerchelong"
        },
        {
          "Cities": [],
          "StateName": "Ngiwal"
        },
        {
          "Cities": [],
          "StateName": "Peleliu"
        },
        {
          "Cities": [],
          "StateName": "Sonsorol"
        }
      ],
      "CountryName": "Palau"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Ariha"
        },
        {
          "Cities": [],
          "StateName": "Bayt Lahm"
        },
        {
          "Cities": [],
          "StateName": "Bethlehem"
        },
        {
          "Cities": [],
          "StateName": "Dayr-al-Balah"
        },
        {
          "Cities": [],
          "StateName": "Ghazzah"
        },
        {
          "Cities": [],
          "StateName": "Ghazzah ash-Shamaliyah"
        },
        {
          "Cities": [],
          "StateName": "Janin"
        },
        {
          "Cities": [],
          "StateName": "Khan Yunis"
        },
        {
          "Cities": [],
          "StateName": "Nabulus"
        },
        {
          "Cities": [],
          "StateName": "Qalqilyah"
        },
        {
          "Cities": [],
          "StateName": "Rafah"
        },
        {
          "Cities": [],
          "StateName": "Ram Allah wal-Birah"
        },
        {
          "Cities": [],
          "StateName": "Salfit"
        },
        {
          "Cities": [],
          "StateName": "Tubas"
        },
        {
          "Cities": [],
          "StateName": "Tulkarm"
        },
        {
          "Cities": [],
          "StateName": "al-Khalil"
        },
        {
          "Cities": [],
          "StateName": "al-Quds"
        }
      ],
      "CountryName": "Palestinian Territory Occupied"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Bocas del Toro"
        },
        {
          "Cities": [],
          "StateName": "Chiriqui"
        },
        {
          "Cities": [],
          "StateName": "Cocle"
        },
        {
          "Cities": [],
          "StateName": "Colon"
        },
        {
          "Cities": [],
          "StateName": "Darien"
        },
        {
          "Cities": [],
          "StateName": "Embera"
        },
        {
          "Cities": [],
          "StateName": "Herrera"
        },
        {
          "Cities": [],
          "StateName": "Kuna Yala"
        },
        {
          "Cities": [],
          "StateName": "Los Santos"
        },
        {
          "Cities": [],
          "StateName": "Ngobe Bugle"
        },
        {
          "Cities": [],
          "StateName": "Panama"
        },
        {
          "Cities": [],
          "StateName": "Veraguas"
        }
      ],
      "CountryName": "Panama"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "East New Britain"
        },
        {
          "Cities": [],
          "StateName": "East Sepik"
        },
        {
          "Cities": [],
          "StateName": "Eastern Highlands"
        },
        {
          "Cities": [],
          "StateName": "Enga"
        },
        {
          "Cities": [],
          "StateName": "Fly River"
        },
        {
          "Cities": [],
          "StateName": "Gulf"
        },
        {
          "Cities": [],
          "StateName": "Madang"
        },
        {
          "Cities": [],
          "StateName": "Manus"
        },
        {
          "Cities": [],
          "StateName": "Milne Bay"
        },
        {
          "Cities": [],
          "StateName": "Morobe"
        },
        {
          "Cities": [],
          "StateName": "National Capital District"
        },
        {
          "Cities": [],
          "StateName": "New Ireland"
        },
        {
          "Cities": [],
          "StateName": "North Solomons"
        },
        {
          "Cities": [],
          "StateName": "Oro"
        },
        {
          "Cities": [],
          "StateName": "Sandaun"
        },
        {
          "Cities": [],
          "StateName": "Simbu"
        },
        {
          "Cities": [],
          "StateName": "Southern Highlands"
        },
        {
          "Cities": [],
          "StateName": "West New Britain"
        },
        {
          "Cities": [],
          "StateName": "Western Highlands"
        }
      ],
      "CountryName": "Papua new Guinea"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Alto Paraguay"
        },
        {
          "Cities": [],
          "StateName": "Alto Parana"
        },
        {
          "Cities": [],
          "StateName": "Amambay"
        },
        {
          "Cities": [],
          "StateName": "Asuncion"
        },
        {
          "Cities": [],
          "StateName": "Boqueron"
        },
        {
          "Cities": [],
          "StateName": "Caaguazu"
        },
        {
          "Cities": [],
          "StateName": "Caazapa"
        },
        {
          "Cities": [],
          "StateName": "Canendiyu"
        },
        {
          "Cities": [],
          "StateName": "Central"
        },
        {
          "Cities": [],
          "StateName": "Concepcion"
        },
        {
          "Cities": [],
          "StateName": "Cordillera"
        },
        {
          "Cities": [],
          "StateName": "Guaira"
        },
        {
          "Cities": [],
          "StateName": "Itapua"
        },
        {
          "Cities": [],
          "StateName": "Misiones"
        },
        {
          "Cities": [],
          "StateName": "Neembucu"
        },
        {
          "Cities": [],
          "StateName": "Paraguari"
        },
        {
          "Cities": [],
          "StateName": "Presidente Hayes"
        },
        {
          "Cities": [],
          "StateName": "San Pedro"
        }
      ],
      "CountryName": "Paraguay"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Amazonas"
        },
        {
          "Cities": [],
          "StateName": "Ancash"
        },
        {
          "Cities": [],
          "StateName": "Apurimac"
        },
        {
          "Cities": [],
          "StateName": "Arequipa"
        },
        {
          "Cities": [],
          "StateName": "Ayacucho"
        },
        {
          "Cities": [],
          "StateName": "Cajamarca"
        },
        {
          "Cities": [],
          "StateName": "Cusco"
        },
        {
          "Cities": [],
          "StateName": "Huancavelica"
        },
        {
          "Cities": [],
          "StateName": "Huanuco"
        },
        {
          "Cities": [],
          "StateName": "Ica"
        },
        {
          "Cities": [],
          "StateName": "Junin"
        },
        {
          "Cities": [],
          "StateName": "La Libertad"
        },
        {
          "Cities": [],
          "StateName": "Lambayeque"
        },
        {
          "Cities": [],
          "StateName": "Lima y Callao"
        },
        {
          "Cities": [],
          "StateName": "Loreto"
        },
        {
          "Cities": [],
          "StateName": "Madre de Dios"
        },
        {
          "Cities": [],
          "StateName": "Moquegua"
        },
        {
          "Cities": [],
          "StateName": "Pasco"
        },
        {
          "Cities": [],
          "StateName": "Piura"
        },
        {
          "Cities": [],
          "StateName": "Puno"
        },
        {
          "Cities": [],
          "StateName": "San Martin"
        },
        {
          "Cities": [],
          "StateName": "Tacna"
        },
        {
          "Cities": [],
          "StateName": "Tumbes"
        },
        {
          "Cities": [],
          "StateName": "Ucayali"
        }
      ],
      "CountryName": "Peru"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Batangas"
        },
        {
          "Cities": [],
          "StateName": "Bicol"
        },
        {
          "Cities": [],
          "StateName": "Bulacan"
        },
        {
          "Cities": [],
          "StateName": "Cagayan"
        },
        {
          "Cities": [],
          "StateName": "Caraga"
        },
        {
          "Cities": [],
          "StateName": "Central Luzon"
        },
        {
          "Cities": [],
          "StateName": "Central Mindanao"
        },
        {
          "Cities": [],
          "StateName": "Central Visayas"
        },
        {
          "Cities": [],
          "StateName": "Cordillera"
        },
        {
          "Cities": [],
          "StateName": "Davao"
        },
        {
          "Cities": [],
          "StateName": "Eastern Visayas"
        },
        {
          "Cities": [],
          "StateName": "Greater Metropolitan Area"
        },
        {
          "Cities": [],
          "StateName": "Ilocos"
        },
        {
          "Cities": [],
          "StateName": "Laguna"
        },
        {
          "Cities": [],
          "StateName": "Luzon"
        },
        {
          "Cities": [],
          "StateName": "Mactan"
        },
        {
          "Cities": [],
          "StateName": "Metropolitan Manila Area"
        },
        {
          "Cities": [],
          "StateName": "Muslim Mindanao"
        },
        {
          "Cities": [],
          "StateName": "Northern Mindanao"
        },
        {
          "Cities": [],
          "StateName": "Southern Mindanao"
        },
        {
          "Cities": [],
          "StateName": "Southern Tagalog"
        },
        {
          "Cities": [],
          "StateName": "Western Mindanao"
        },
        {
          "Cities": [],
          "StateName": "Western Visayas"
        }
      ],
      "CountryName": "Philippines"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Pitcairn Island"
        }
      ],
      "CountryName": "Pitcairn Island"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Biale Blota"
        },
        {
          "Cities": [],
          "StateName": "Dobroszyce"
        },
        {
          "Cities": [],
          "StateName": "Dolnoslaskie"
        },
        {
          "Cities": [],
          "StateName": "Dziekanow Lesny"
        },
        {
          "Cities": [],
          "StateName": "Hopowo"
        },
        {
          "Cities": [],
          "StateName": "Kartuzy"
        },
        {
          "Cities": [],
          "StateName": "Koscian"
        },
        {
          "Cities": [],
          "StateName": "Krakow"
        },
        {
          "Cities": [],
          "StateName": "Kujawsko-Pomorskie"
        },
        {
          "Cities": [],
          "StateName": "Lodzkie"
        },
        {
          "Cities": [],
          "StateName": "Lubelskie"
        },
        {
          "Cities": [],
          "StateName": "Lubuskie"
        },
        {
          "Cities": [],
          "StateName": "Malomice"
        },
        {
          "Cities": [],
          "StateName": "Malopolskie"
        },
        {
          "Cities": [],
          "StateName": "Mazowieckie"
        },
        {
          "Cities": [],
          "StateName": "Mirkow"
        },
        {
          "Cities": [],
          "StateName": "Opolskie"
        },
        {
          "Cities": [],
          "StateName": "Ostrowiec"
        },
        {
          "Cities": [],
          "StateName": "Podkarpackie"
        },
        {
          "Cities": [],
          "StateName": "Podlaskie"
        },
        {
          "Cities": [],
          "StateName": "Polska"
        },
        {
          "Cities": [],
          "StateName": "Pomorskie"
        },
        {
          "Cities": [],
          "StateName": "Poznan"
        },
        {
          "Cities": [],
          "StateName": "Pruszkow"
        },
        {
          "Cities": [],
          "StateName": "Rymanowska"
        },
        {
          "Cities": [],
          "StateName": "Rzeszow"
        },
        {
          "Cities": [],
          "StateName": "Slaskie"
        },
        {
          "Cities": [],
          "StateName": "Stare Pole"
        },
        {
          "Cities": [],
          "StateName": "Swietokrzyskie"
        },
        {
          "Cities": [],
          "StateName": "Warminsko-Mazurskie"
        },
        {
          "Cities": [],
          "StateName": "Warsaw"
        },
        {
          "Cities": [],
          "StateName": "Wejherowo"
        },
        {
          "Cities": [],
          "StateName": "Wielkopolskie"
        },
        {
          "Cities": [],
          "StateName": "Wroclaw"
        },
        {
          "Cities": [],
          "StateName": "Zachodnio-Pomorskie"
        },
        {
          "Cities": [],
          "StateName": "Zukowo"
        }
      ],
      "CountryName": "Poland"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Abrantes"
        },
        {
          "Cities": [],
          "StateName": "Acores"
        },
        {
          "Cities": [],
          "StateName": "Alentejo"
        },
        {
          "Cities": [],
          "StateName": "Algarve"
        },
        {
          "Cities": [],
          "StateName": "Braga"
        },
        {
          "Cities": [],
          "StateName": "Centro"
        },
        {
          "Cities": [],
          "StateName": "Distrito de Leiria"
        },
        {
          "Cities": [],
          "StateName": "Distrito de Viana do Castelo"
        },
        {
          "Cities": [],
          "StateName": "Distrito de Vila Real"
        },
        {
          "Cities": [],
          "StateName": "Distrito do Porto"
        },
        {
          "Cities": [],
          "StateName": "Lisboa e Vale do Tejo"
        },
        {
          "Cities": [],
          "StateName": "Madeira"
        },
        {
          "Cities": [],
          "StateName": "Norte"
        },
        {
          "Cities": [],
          "StateName": "Paivas"
        }
      ],
      "CountryName": "Portugal"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Arecibo"
        },
        {
          "Cities": [],
          "StateName": "Bayamon"
        },
        {
          "Cities": [],
          "StateName": "Carolina"
        },
        {
          "Cities": [],
          "StateName": "Florida"
        },
        {
          "Cities": [],
          "StateName": "Guayama"
        },
        {
          "Cities": [],
          "StateName": "Humacao"
        },
        {
          "Cities": [],
          "StateName": "Mayaguez-Aguadilla"
        },
        {
          "Cities": [],
          "StateName": "Ponce"
        },
        {
          "Cities": [],
          "StateName": "Salinas"
        },
        {
          "Cities": [],
          "StateName": "San Juan"
        }
      ],
      "CountryName": "Puerto Rico"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Doha"
        },
        {
          "Cities": [],
          "StateName": "Jarian-al-Batnah"
        },
        {
          "Cities": [],
          "StateName": "Umm Salal"
        },
        {
          "Cities": [],
          "StateName": "ad-Dawhah"
        },
        {
          "Cities": [],
          "StateName": "al-Ghuwayriyah"
        },
        {
          "Cities": [],
          "StateName": "al-Jumayliyah"
        },
        {
          "Cities": [],
          "StateName": "al-Khawr"
        },
        {
          "Cities": [],
          "StateName": "al-Wakrah"
        },
        {
          "Cities": [],
          "StateName": "ar-Rayyan"
        },
        {
          "Cities": [],
          "StateName": "ash-Shamal"
        }
      ],
      "CountryName": "Qatar"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Saint-Benoit"
        },
        {
          "Cities": [],
          "StateName": "Saint-Denis"
        },
        {
          "Cities": [],
          "StateName": "Saint-Paul"
        },
        {
          "Cities": [],
          "StateName": "Saint-Pierre"
        }
      ],
      "CountryName": "Reunion"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Alba"
        },
        {
          "Cities": [],
          "StateName": "Arad"
        },
        {
          "Cities": [],
          "StateName": "Arges"
        },
        {
          "Cities": [],
          "StateName": "Bacau"
        },
        {
          "Cities": [],
          "StateName": "Bihor"
        },
        {
          "Cities": [],
          "StateName": "Bistrita-Nasaud"
        },
        {
          "Cities": [],
          "StateName": "Botosani"
        },
        {
          "Cities": [],
          "StateName": "Braila"
        },
        {
          "Cities": [],
          "StateName": "Brasov"
        },
        {
          "Cities": [],
          "StateName": "Bucuresti"
        },
        {
          "Cities": [],
          "StateName": "Buzau"
        },
        {
          "Cities": [],
          "StateName": "Calarasi"
        },
        {
          "Cities": [],
          "StateName": "Caras-Severin"
        },
        {
          "Cities": [],
          "StateName": "Cluj"
        },
        {
          "Cities": [],
          "StateName": "Constanta"
        },
        {
          "Cities": [],
          "StateName": "Covasna"
        },
        {
          "Cities": [],
          "StateName": "Dambovita"
        },
        {
          "Cities": [],
          "StateName": "Dolj"
        },
        {
          "Cities": [],
          "StateName": "Galati"
        },
        {
          "Cities": [],
          "StateName": "Giurgiu"
        },
        {
          "Cities": [],
          "StateName": "Gorj"
        },
        {
          "Cities": [],
          "StateName": "Harghita"
        },
        {
          "Cities": [],
          "StateName": "Hunedoara"
        },
        {
          "Cities": [],
          "StateName": "Ialomita"
        },
        {
          "Cities": [],
          "StateName": "Iasi"
        },
        {
          "Cities": [],
          "StateName": "Ilfov"
        },
        {
          "Cities": [],
          "StateName": "Maramures"
        },
        {
          "Cities": [],
          "StateName": "Mehedinti"
        },
        {
          "Cities": [],
          "StateName": "Mures"
        },
        {
          "Cities": [],
          "StateName": "Neamt"
        },
        {
          "Cities": [],
          "StateName": "Olt"
        },
        {
          "Cities": [],
          "StateName": "Prahova"
        },
        {
          "Cities": [],
          "StateName": "Salaj"
        },
        {
          "Cities": [],
          "StateName": "Satu Mare"
        },
        {
          "Cities": [],
          "StateName": "Sibiu"
        },
        {
          "Cities": [],
          "StateName": "Sondelor"
        },
        {
          "Cities": [],
          "StateName": "Suceava"
        },
        {
          "Cities": [],
          "StateName": "Teleorman"
        },
        {
          "Cities": [],
          "StateName": "Timis"
        },
        {
          "Cities": [],
          "StateName": "Tulcea"
        },
        {
          "Cities": [],
          "StateName": "Valcea"
        },
        {
          "Cities": [],
          "StateName": "Vaslui"
        },
        {
          "Cities": [],
          "StateName": "Vrancea"
        }
      ],
      "CountryName": "Romania"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Adygeja"
        },
        {
          "Cities": [],
          "StateName": "Aga"
        },
        {
          "Cities": [],
          "StateName": "Alanija"
        },
        {
          "Cities": [],
          "StateName": "Altaj"
        },
        {
          "Cities": [],
          "StateName": "Amur"
        },
        {
          "Cities": [],
          "StateName": "Arhangelsk"
        },
        {
          "Cities": [],
          "StateName": "Astrahan"
        },
        {
          "Cities": [],
          "StateName": "Bashkortostan"
        },
        {
          "Cities": [],
          "StateName": "Belgorod"
        },
        {
          "Cities": [],
          "StateName": "Brjansk"
        },
        {
          "Cities": [],
          "StateName": "Burjatija"
        },
        {
          "Cities": [],
          "StateName": "Chechenija"
        },
        {
          "Cities": [],
          "StateName": "Cheljabinsk"
        },
        {
          "Cities": [],
          "StateName": "Chita"
        },
        {
          "Cities": [],
          "StateName": "Chukotka"
        },
        {
          "Cities": [],
          "StateName": "Chuvashija"
        },
        {
          "Cities": [],
          "StateName": "Dagestan"
        },
        {
          "Cities": [],
          "StateName": "Evenkija"
        },
        {
          "Cities": [],
          "StateName": "Gorno-Altaj"
        },
        {
          "Cities": [],
          "StateName": "Habarovsk"
        },
        {
          "Cities": [],
          "StateName": "Hakasija"
        },
        {
          "Cities": [],
          "StateName": "Hanty-Mansija"
        },
        {
          "Cities": [],
          "StateName": "Ingusetija"
        },
        {
          "Cities": [],
          "StateName": "Irkutsk"
        },
        {
          "Cities": [],
          "StateName": "Ivanovo"
        },
        {
          "Cities": [],
          "StateName": "Jamalo-Nenets"
        },
        {
          "Cities": [],
          "StateName": "Jaroslavl"
        },
        {
          "Cities": [],
          "StateName": "Jevrej"
        },
        {
          "Cities": [],
          "StateName": "Kabardino-Balkarija"
        },
        {
          "Cities": [],
          "StateName": "Kaliningrad"
        },
        {
          "Cities": [],
          "StateName": "Kalmykija"
        },
        {
          "Cities": [],
          "StateName": "Kaluga"
        },
        {
          "Cities": [],
          "StateName": "Kamchatka"
        },
        {
          "Cities": [],
          "StateName": "Karachaj-Cherkessija"
        },
        {
          "Cities": [],
          "StateName": "Karelija"
        },
        {
          "Cities": [],
          "StateName": "Kemerovo"
        },
        {
          "Cities": [],
          "StateName": "Khabarovskiy Kray"
        },
        {
          "Cities": [],
          "StateName": "Kirov"
        },
        {
          "Cities": [],
          "StateName": "Komi"
        },
        {
          "Cities": [],
          "StateName": "Komi-Permjakija"
        },
        {
          "Cities": [],
          "StateName": "Korjakija"
        },
        {
          "Cities": [],
          "StateName": "Kostroma"
        },
        {
          "Cities": [],
          "StateName": "Krasnodar"
        },
        {
          "Cities": [],
          "StateName": "Krasnojarsk"
        },
        {
          "Cities": [],
          "StateName": "Krasnoyarskiy Kray"
        },
        {
          "Cities": [],
          "StateName": "Kurgan"
        },
        {
          "Cities": [],
          "StateName": "Kursk"
        },
        {
          "Cities": [],
          "StateName": "Leningrad"
        },
        {
          "Cities": [],
          "StateName": "Lipeck"
        },
        {
          "Cities": [],
          "StateName": "Magadan"
        },
        {
          "Cities": [],
          "StateName": "Marij El"
        },
        {
          "Cities": [],
          "StateName": "Mordovija"
        },
        {
          "Cities": [],
          "StateName": "Moscow"
        },
        {
          "Cities": [],
          "StateName": "Moskovskaja Oblast"
        },
        {
          "Cities": [],
          "StateName": "Moskovskaya Oblast"
        },
        {
          "Cities": [],
          "StateName": "Moskva"
        },
        {
          "Cities": [],
          "StateName": "Murmansk"
        },
        {
          "Cities": [],
          "StateName": "Nenets"
        },
        {
          "Cities": [],
          "StateName": "Nizhnij Novgorod"
        },
        {
          "Cities": [],
          "StateName": "Novgorod"
        },
        {
          "Cities": [],
          "StateName": "Novokusnezk"
        },
        {
          "Cities": [],
          "StateName": "Novosibirsk"
        },
        {
          "Cities": [],
          "StateName": "Omsk"
        },
        {
          "Cities": [],
          "StateName": "Orenburg"
        },
        {
          "Cities": [],
          "StateName": "Orjol"
        },
        {
          "Cities": [],
          "StateName": "Penza"
        },
        {
          "Cities": [],
          "StateName": "Perm"
        },
        {
          "Cities": [],
          "StateName": "Primorje"
        },
        {
          "Cities": [],
          "StateName": "Pskov"
        },
        {
          "Cities": [],
          "StateName": "Pskovskaya Oblast"
        },
        {
          "Cities": [],
          "StateName": "Rjazan"
        },
        {
          "Cities": [],
          "StateName": "Rostov"
        },
        {
          "Cities": [],
          "StateName": "Saha"
        },
        {
          "Cities": [],
          "StateName": "Sahalin"
        },
        {
          "Cities": [],
          "StateName": "Samara"
        },
        {
          "Cities": [],
          "StateName": "Samarskaya"
        },
        {
          "Cities": [],
          "StateName": "Sankt-Peterburg"
        },
        {
          "Cities": [],
          "StateName": "Saratov"
        },
        {
          "Cities": [],
          "StateName": "Smolensk"
        },
        {
          "Cities": [],
          "StateName": "Stavropol"
        },
        {
          "Cities": [],
          "StateName": "Sverdlovsk"
        },
        {
          "Cities": [],
          "StateName": "Tajmyrija"
        },
        {
          "Cities": [],
          "StateName": "Tambov"
        },
        {
          "Cities": [],
          "StateName": "Tatarstan"
        },
        {
          "Cities": [],
          "StateName": "Tjumen"
        },
        {
          "Cities": [],
          "StateName": "Tomsk"
        },
        {
          "Cities": [],
          "StateName": "Tula"
        },
        {
          "Cities": [],
          "StateName": "Tver"
        },
        {
          "Cities": [],
          "StateName": "Tyva"
        },
        {
          "Cities": [],
          "StateName": "Udmurtija"
        },
        {
          "Cities": [],
          "StateName": "Uljanovsk"
        },
        {
          "Cities": [],
          "StateName": "Ulyanovskaya Oblast"
        },
        {
          "Cities": [],
          "StateName": "Ust-Orda"
        },
        {
          "Cities": [],
          "StateName": "Vladimir"
        },
        {
          "Cities": [],
          "StateName": "Volgograd"
        },
        {
          "Cities": [],
          "StateName": "Vologda"
        },
        {
          "Cities": [],
          "StateName": "Voronezh"
        }
      ],
      "CountryName": "Russia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Butare"
        },
        {
          "Cities": [],
          "StateName": "Byumba"
        },
        {
          "Cities": [],
          "StateName": "Cyangugu"
        },
        {
          "Cities": [],
          "StateName": "Gikongoro"
        },
        {
          "Cities": [],
          "StateName": "Gisenyi"
        },
        {
          "Cities": [],
          "StateName": "Gitarama"
        },
        {
          "Cities": [],
          "StateName": "Kibungo"
        },
        {
          "Cities": [],
          "StateName": "Kibuye"
        },
        {
          "Cities": [],
          "StateName": "Kigali-ngali"
        },
        {
          "Cities": [],
          "StateName": "Ruhengeri"
        }
      ],
      "CountryName": "Rwanda"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Ascension"
        },
        {
          "Cities": [],
          "StateName": "Gough Island"
        },
        {
          "Cities": [],
          "StateName": "Saint Helena"
        },
        {
          "Cities": [],
          "StateName": "Tristan da Cunha"
        }
      ],
      "CountryName": "Saint Helena"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Christ Church Nichola Town"
        },
        {
          "Cities": [],
          "StateName": "Saint Anne Sandy Point"
        },
        {
          "Cities": [],
          "StateName": "Saint George Basseterre"
        },
        {
          "Cities": [],
          "StateName": "Saint George Gingerland"
        },
        {
          "Cities": [],
          "StateName": "Saint James Windward"
        },
        {
          "Cities": [],
          "StateName": "Saint John Capesterre"
        },
        {
          "Cities": [],
          "StateName": "Saint John Figtree"
        },
        {
          "Cities": [],
          "StateName": "Saint Mary Cayon"
        },
        {
          "Cities": [],
          "StateName": "Saint Paul Capesterre"
        },
        {
          "Cities": [],
          "StateName": "Saint Paul Charlestown"
        },
        {
          "Cities": [],
          "StateName": "Saint Peter Basseterre"
        },
        {
          "Cities": [],
          "StateName": "Saint Thomas Lowland"
        },
        {
          "Cities": [],
          "StateName": "Saint Thomas Middle Island"
        },
        {
          "Cities": [],
          "StateName": "Trinity Palmetto Point"
        }
      ],
      "CountryName": "Saint Kitts And Nevis"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Anse-la-Raye"
        },
        {
          "Cities": [],
          "StateName": "Canaries"
        },
        {
          "Cities": [],
          "StateName": "Castries"
        },
        {
          "Cities": [],
          "StateName": "Choiseul"
        },
        {
          "Cities": [],
          "StateName": "Dennery"
        },
        {
          "Cities": [],
          "StateName": "Gros Inlet"
        },
        {
          "Cities": [],
          "StateName": "Laborie"
        },
        {
          "Cities": [],
          "StateName": "Micoud"
        },
        {
          "Cities": [],
          "StateName": "Soufriere"
        },
        {
          "Cities": [],
          "StateName": "Vieux Fort"
        }
      ],
      "CountryName": "Saint Lucia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Miquelon-Langlade"
        },
        {
          "Cities": [],
          "StateName": "Saint-Pierre"
        }
      ],
      "CountryName": "Saint Pierre and Miquelon"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Charlotte"
        },
        {
          "Cities": [],
          "StateName": "Grenadines"
        },
        {
          "Cities": [],
          "StateName": "Saint Andrew"
        },
        {
          "Cities": [],
          "StateName": "Saint David"
        },
        {
          "Cities": [],
          "StateName": "Saint George"
        },
        {
          "Cities": [],
          "StateName": "Saint Patrick"
        }
      ],
      "CountryName": "Saint Vincent And The Grenadines"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "A'ana"
        },
        {
          "Cities": [],
          "StateName": "Aiga-i-le-Tai"
        },
        {
          "Cities": [],
          "StateName": "Atua"
        },
        {
          "Cities": [],
          "StateName": "Fa'asaleleaga"
        },
        {
          "Cities": [],
          "StateName": "Gaga'emauga"
        },
        {
          "Cities": [],
          "StateName": "Gagaifomauga"
        },
        {
          "Cities": [],
          "StateName": "Palauli"
        },
        {
          "Cities": [],
          "StateName": "Satupa'itea"
        },
        {
          "Cities": [],
          "StateName": "Tuamasaga"
        },
        {
          "Cities": [],
          "StateName": "Va'a-o-Fonoti"
        },
        {
          "Cities": [],
          "StateName": "Vaisigano"
        }
      ],
      "CountryName": "Samoa"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Acquaviva"
        },
        {
          "Cities": [],
          "StateName": "Borgo Maggiore"
        },
        {
          "Cities": [],
          "StateName": "Chiesanuova"
        },
        {
          "Cities": [],
          "StateName": "Domagnano"
        },
        {
          "Cities": [],
          "StateName": "Faetano"
        },
        {
          "Cities": [],
          "StateName": "Fiorentino"
        },
        {
          "Cities": [],
          "StateName": "Montegiardino"
        },
        {
          "Cities": [],
          "StateName": "San Marino"
        },
        {
          "Cities": [],
          "StateName": "Serravalle"
        }
      ],
      "CountryName": "San Marino"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Agua Grande"
        },
        {
          "Cities": [],
          "StateName": "Cantagalo"
        },
        {
          "Cities": [],
          "StateName": "Lemba"
        },
        {
          "Cities": [],
          "StateName": "Lobata"
        },
        {
          "Cities": [],
          "StateName": "Me-Zochi"
        },
        {
          "Cities": [],
          "StateName": "Pague"
        }
      ],
      "CountryName": "Sao Tome and Principe"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Al Khobar"
        },
        {
          "Cities": [],
          "StateName": "Aseer"
        },
        {
          "Cities": [],
          "StateName": "Ash Sharqiyah"
        },
        {
          "Cities": [],
          "StateName": "Asir"
        },
        {
          "Cities": [],
          "StateName": "Central Province"
        },
        {
          "Cities": [],
          "StateName": "Eastern Province"
        },
        {
          "Cities": [],
          "StateName": "Ha'il"
        },
        {
          "Cities": [],
          "StateName": "Jawf"
        },
        {
          "Cities": [],
          "StateName": "Jizan"
        },
        {
          "Cities": [],
          "StateName": "Makkah"
        },
        {
          "Cities": [],
          "StateName": "Najran"
        },
        {
          "Cities": [],
          "StateName": "Qasim"
        },
        {
          "Cities": [],
          "StateName": "Tabuk"
        },
        {
          "Cities": [],
          "StateName": "Western Province"
        },
        {
          "Cities": [],
          "StateName": "al-Bahah"
        },
        {
          "Cities": [],
          "StateName": "al-Hudud-ash-Shamaliyah"
        },
        {
          "Cities": [],
          "StateName": "al-Madinah"
        },
        {
          "Cities": [],
          "StateName": "ar-Riyad"
        }
      ],
      "CountryName": "Saudi Arabia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Dakar"
        },
        {
          "Cities": [],
          "StateName": "Diourbel"
        },
        {
          "Cities": [],
          "StateName": "Fatick"
        },
        {
          "Cities": [],
          "StateName": "Kaolack"
        },
        {
          "Cities": [],
          "StateName": "Kolda"
        },
        {
          "Cities": [],
          "StateName": "Louga"
        },
        {
          "Cities": [],
          "StateName": "Saint-Louis"
        },
        {
          "Cities": [],
          "StateName": "Tambacounda"
        },
        {
          "Cities": [],
          "StateName": "Thies"
        },
        {
          "Cities": [],
          "StateName": "Ziguinchor"
        }
      ],
      "CountryName": "Senegal"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Central Serbia"
        },
        {
          "Cities": [],
          "StateName": "Kosovo and Metohija"
        },
        {
          "Cities": [],
          "StateName": "Vojvodina"
        }
      ],
      "CountryName": "Serbia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Anse Boileau"
        },
        {
          "Cities": [],
          "StateName": "Anse Royale"
        },
        {
          "Cities": [],
          "StateName": "Cascade"
        },
        {
          "Cities": [],
          "StateName": "Takamaka"
        },
        {
          "Cities": [],
          "StateName": "Victoria"
        }
      ],
      "CountryName": "Seychelles"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Eastern"
        },
        {
          "Cities": [],
          "StateName": "Northern"
        },
        {
          "Cities": [],
          "StateName": "Southern"
        },
        {
          "Cities": [],
          "StateName": "Western"
        }
      ],
      "CountryName": "Sierra Leone"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Singapore"
        }
      ],
      "CountryName": "Singapore"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Banskobystricky"
        },
        {
          "Cities": [],
          "StateName": "Bratislavsky"
        },
        {
          "Cities": [],
          "StateName": "Kosicky"
        },
        {
          "Cities": [],
          "StateName": "Nitriansky"
        },
        {
          "Cities": [],
          "StateName": "Presovsky"
        },
        {
          "Cities": [],
          "StateName": "Trenciansky"
        },
        {
          "Cities": [],
          "StateName": "Trnavsky"
        },
        {
          "Cities": [],
          "StateName": "Zilinsky"
        }
      ],
      "CountryName": "Slovakia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Benedikt"
        },
        {
          "Cities": [],
          "StateName": "Gorenjska"
        },
        {
          "Cities": [],
          "StateName": "Gorishka"
        },
        {
          "Cities": [],
          "StateName": "Jugovzhodna Slovenija"
        },
        {
          "Cities": [],
          "StateName": "Koroshka"
        },
        {
          "Cities": [],
          "StateName": "Notranjsko-krashka"
        },
        {
          "Cities": [],
          "StateName": "Obalno-krashka"
        },
        {
          "Cities": [],
          "StateName": "Obcina Domzale"
        },
        {
          "Cities": [],
          "StateName": "Obcina Vitanje"
        },
        {
          "Cities": [],
          "StateName": "Osrednjeslovenska"
        },
        {
          "Cities": [],
          "StateName": "Podravska"
        },
        {
          "Cities": [],
          "StateName": "Pomurska"
        },
        {
          "Cities": [],
          "StateName": "Savinjska"
        },
        {
          "Cities": [],
          "StateName": "Slovenian Littoral"
        },
        {
          "Cities": [],
          "StateName": "Spodnjeposavska"
        },
        {
          "Cities": [],
          "StateName": "Zasavska"
        }
      ],
      "CountryName": "Slovenia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Pitcairn"
        }
      ],
      "CountryName": "Smaller Territories of the UK"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Central"
        },
        {
          "Cities": [],
          "StateName": "Choiseul"
        },
        {
          "Cities": [],
          "StateName": "Guadalcanal"
        },
        {
          "Cities": [],
          "StateName": "Isabel"
        },
        {
          "Cities": [],
          "StateName": "Makira and Ulawa"
        },
        {
          "Cities": [],
          "StateName": "Malaita"
        },
        {
          "Cities": [],
          "StateName": "Rennell and Bellona"
        },
        {
          "Cities": [],
          "StateName": "Temotu"
        },
        {
          "Cities": [],
          "StateName": "Western"
        }
      ],
      "CountryName": "Solomon Islands"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Awdal"
        },
        {
          "Cities": [],
          "StateName": "Bakol"
        },
        {
          "Cities": [],
          "StateName": "Banadir"
        },
        {
          "Cities": [],
          "StateName": "Bari"
        },
        {
          "Cities": [],
          "StateName": "Bay"
        },
        {
          "Cities": [],
          "StateName": "Galgudug"
        },
        {
          "Cities": [],
          "StateName": "Gedo"
        },
        {
          "Cities": [],
          "StateName": "Hiran"
        },
        {
          "Cities": [],
          "StateName": "Jubbada Hose"
        },
        {
          "Cities": [],
          "StateName": "Jubbadha Dexe"
        },
        {
          "Cities": [],
          "StateName": "Mudug"
        },
        {
          "Cities": [],
          "StateName": "Nugal"
        },
        {
          "Cities": [],
          "StateName": "Sanag"
        },
        {
          "Cities": [],
          "StateName": "Shabellaha Dhexe"
        },
        {
          "Cities": [],
          "StateName": "Shabellaha Hose"
        },
        {
          "Cities": [],
          "StateName": "Togdher"
        },
        {
          "Cities": [],
          "StateName": "Woqoyi Galbed"
        }
      ],
      "CountryName": "Somalia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Eastern Cape"
        },
        {
          "Cities": [],
          "StateName": "Free State"
        },
        {
          "Cities": [],
          "StateName": "Gauteng"
        },
        {
          "Cities": [],
          "StateName": "Kempton Park"
        },
        {
          "Cities": [],
          "StateName": "Kramerville"
        },
        {
          "Cities": [],
          "StateName": "KwaZulu Natal"
        },
        {
          "Cities": [],
          "StateName": "Limpopo"
        },
        {
          "Cities": [],
          "StateName": "Mpumalanga"
        },
        {
          "Cities": [],
          "StateName": "North West"
        },
        {
          "Cities": [],
          "StateName": "Northern Cape"
        },
        {
          "Cities": [],
          "StateName": "Parow"
        },
        {
          "Cities": [],
          "StateName": "Table View"
        },
        {
          "Cities": [],
          "StateName": "Umtentweni"
        },
        {
          "Cities": [],
          "StateName": "Western Cape"
        }
      ],
      "CountryName": "South Africa"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "South Georgia"
        }
      ],
      "CountryName": "South Georgia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Central Equatoria"
        }
      ],
      "CountryName": "South Sudan"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "A Coruna"
        },
        {
          "Cities": [],
          "StateName": "Alacant"
        },
        {
          "Cities": [],
          "StateName": "Alava"
        },
        {
          "Cities": [],
          "StateName": "Albacete"
        },
        {
          "Cities": [],
          "StateName": "Almeria"
        },
        {
          "Cities": [],
          "StateName": "Andalucia"
        },
        {
          "Cities": [],
          "StateName": "Asturias"
        },
        {
          "Cities": [],
          "StateName": "Avila"
        },
        {
          "Cities": [],
          "StateName": "Badajoz"
        },
        {
          "Cities": [],
          "StateName": "Balears"
        },
        {
          "Cities": [],
          "StateName": "Barcelona"
        },
        {
          "Cities": [],
          "StateName": "Bertamirans"
        },
        {
          "Cities": [],
          "StateName": "Biscay"
        },
        {
          "Cities": [],
          "StateName": "Burgos"
        },
        {
          "Cities": [],
          "StateName": "Caceres"
        },
        {
          "Cities": [],
          "StateName": "Cadiz"
        },
        {
          "Cities": [],
          "StateName": "Cantabria"
        },
        {
          "Cities": [],
          "StateName": "Castello"
        },
        {
          "Cities": [],
          "StateName": "Catalunya"
        },
        {
          "Cities": [],
          "StateName": "Ceuta"
        },
        {
          "Cities": [],
          "StateName": "Ciudad Real"
        },
        {
          "Cities": [],
          "StateName": "Comunidad Autonoma de Canarias"
        },
        {
          "Cities": [],
          "StateName": "Comunidad Autonoma de Cataluna"
        },
        {
          "Cities": [],
          "StateName": "Comunidad Autonoma de Galicia"
        },
        {
          "Cities": [],
          "StateName": "Comunidad Autonoma de las Isla"
        },
        {
          "Cities": [],
          "StateName": "Comunidad Autonoma del Princip"
        },
        {
          "Cities": [],
          "StateName": "Comunidad Valenciana"
        },
        {
          "Cities": [],
          "StateName": "Cordoba"
        },
        {
          "Cities": [],
          "StateName": "Cuenca"
        },
        {
          "Cities": [],
          "StateName": "Gipuzkoa"
        },
        {
          "Cities": [],
          "StateName": "Girona"
        },
        {
          "Cities": [],
          "StateName": "Granada"
        },
        {
          "Cities": [],
          "StateName": "Guadalajara"
        },
        {
          "Cities": [],
          "StateName": "Guipuzcoa"
        },
        {
          "Cities": [],
          "StateName": "Huelva"
        },
        {
          "Cities": [],
          "StateName": "Huesca"
        },
        {
          "Cities": [],
          "StateName": "Jaen"
        },
        {
          "Cities": [],
          "StateName": "La Rioja"
        },
        {
          "Cities": [],
          "StateName": "Las Palmas"
        },
        {
          "Cities": [],
          "StateName": "Leon"
        },
        {
          "Cities": [],
          "StateName": "Lerida"
        },
        {
          "Cities": [],
          "StateName": "Lleida"
        },
        {
          "Cities": [],
          "StateName": "Lugo"
        },
        {
          "Cities": [],
          "StateName": "Madrid"
        },
        {
          "Cities": [],
          "StateName": "Malaga"
        },
        {
          "Cities": [],
          "StateName": "Melilla"
        },
        {
          "Cities": [],
          "StateName": "Murcia"
        },
        {
          "Cities": [],
          "StateName": "Navarra"
        },
        {
          "Cities": [],
          "StateName": "Ourense"
        },
        {
          "Cities": [],
          "StateName": "Pais Vasco"
        },
        {
          "Cities": [],
          "StateName": "Palencia"
        },
        {
          "Cities": [],
          "StateName": "Pontevedra"
        },
        {
          "Cities": [],
          "StateName": "Salamanca"
        },
        {
          "Cities": [],
          "StateName": "Santa Cruz de Tenerife"
        },
        {
          "Cities": [],
          "StateName": "Segovia"
        },
        {
          "Cities": [],
          "StateName": "Sevilla"
        },
        {
          "Cities": [],
          "StateName": "Soria"
        },
        {
          "Cities": [],
          "StateName": "Tarragona"
        },
        {
          "Cities": [],
          "StateName": "Tenerife"
        },
        {
          "Cities": [],
          "StateName": "Teruel"
        },
        {
          "Cities": [],
          "StateName": "Toledo"
        },
        {
          "Cities": [],
          "StateName": "Valencia"
        },
        {
          "Cities": [],
          "StateName": "Valladolid"
        },
        {
          "Cities": [],
          "StateName": "Vizcaya"
        },
        {
          "Cities": [],
          "StateName": "Zamora"
        },
        {
          "Cities": [],
          "StateName": "Zaragoza"
        }
      ],
      "CountryName": "Spain"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Amparai"
        },
        {
          "Cities": [],
          "StateName": "Anuradhapuraya"
        },
        {
          "Cities": [],
          "StateName": "Badulla"
        },
        {
          "Cities": [],
          "StateName": "Boralesgamuwa"
        },
        {
          "Cities": [],
          "StateName": "Colombo"
        },
        {
          "Cities": [],
          "StateName": "Galla"
        },
        {
          "Cities": [],
          "StateName": "Gampaha"
        },
        {
          "Cities": [],
          "StateName": "Hambantota"
        },
        {
          "Cities": [],
          "StateName": "Kalatura"
        },
        {
          "Cities": [],
          "StateName": "Kegalla"
        },
        {
          "Cities": [],
          "StateName": "Kilinochchi"
        },
        {
          "Cities": [],
          "StateName": "Kurunegala"
        },
        {
          "Cities": [],
          "StateName": "Madakalpuwa"
        },
        {
          "Cities": [],
          "StateName": "Maha Nuwara"
        },
        {
          "Cities": [],
          "StateName": "Malwana"
        },
        {
          "Cities": [],
          "StateName": "Mannarama"
        },
        {
          "Cities": [],
          "StateName": "Matale"
        },
        {
          "Cities": [],
          "StateName": "Matara"
        },
        {
          "Cities": [],
          "StateName": "Monaragala"
        },
        {
          "Cities": [],
          "StateName": "Mullaitivu"
        },
        {
          "Cities": [],
          "StateName": "North Eastern Province"
        },
        {
          "Cities": [],
          "StateName": "North Western Province"
        },
        {
          "Cities": [],
          "StateName": "Nuwara Eliya"
        },
        {
          "Cities": [],
          "StateName": "Polonnaruwa"
        },
        {
          "Cities": [],
          "StateName": "Puttalama"
        },
        {
          "Cities": [],
          "StateName": "Ratnapuraya"
        },
        {
          "Cities": [],
          "StateName": "Southern Province"
        },
        {
          "Cities": [],
          "StateName": "Tirikunamalaya"
        },
        {
          "Cities": [],
          "StateName": "Tuscany"
        },
        {
          "Cities": [],
          "StateName": "Vavuniyawa"
        },
        {
          "Cities": [],
          "StateName": "Western Province"
        },
        {
          "Cities": [],
          "StateName": "Yapanaya"
        },
        {
          "Cities": [],
          "StateName": "kadawatha"
        }
      ],
      "CountryName": "Sri Lanka"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "A'ali-an-Nil"
        },
        {
          "Cities": [],
          "StateName": "Bahr-al-Jabal"
        },
        {
          "Cities": [],
          "StateName": "Central Equatoria"
        },
        {
          "Cities": [],
          "StateName": "Gharb Bahr-al-Ghazal"
        },
        {
          "Cities": [],
          "StateName": "Gharb Darfur"
        },
        {
          "Cities": [],
          "StateName": "Gharb Kurdufan"
        },
        {
          "Cities": [],
          "StateName": "Gharb-al-Istiwa'iyah"
        },
        {
          "Cities": [],
          "StateName": "Janub Darfur"
        },
        {
          "Cities": [],
          "StateName": "Janub Kurdufan"
        },
        {
          "Cities": [],
          "StateName": "Junqali"
        },
        {
          "Cities": [],
          "StateName": "Kassala"
        },
        {
          "Cities": [],
          "StateName": "Nahr-an-Nil"
        },
        {
          "Cities": [],
          "StateName": "Shamal Bahr-al-Ghazal"
        },
        {
          "Cities": [],
          "StateName": "Shamal Darfur"
        },
        {
          "Cities": [],
          "StateName": "Shamal Kurdufan"
        },
        {
          "Cities": [],
          "StateName": "Sharq-al-Istiwa'iyah"
        },
        {
          "Cities": [],
          "StateName": "Sinnar"
        },
        {
          "Cities": [],
          "StateName": "Warab"
        },
        {
          "Cities": [],
          "StateName": "Wilayat al Khartum"
        },
        {
          "Cities": [],
          "StateName": "al-Bahr-al-Ahmar"
        },
        {
          "Cities": [],
          "StateName": "al-Buhayrat"
        },
        {
          "Cities": [],
          "StateName": "al-Jazirah"
        },
        {
          "Cities": [],
          "StateName": "al-Khartum"
        },
        {
          "Cities": [],
          "StateName": "al-Qadarif"
        },
        {
          "Cities": [],
          "StateName": "al-Wahdah"
        },
        {
          "Cities": [],
          "StateName": "an-Nil-al-Abyad"
        },
        {
          "Cities": [],
          "StateName": "an-Nil-al-Azraq"
        },
        {
          "Cities": [],
          "StateName": "ash-Shamaliyah"
        }
      ],
      "CountryName": "Sudan"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Brokopondo"
        },
        {
          "Cities": [],
          "StateName": "Commewijne"
        },
        {
          "Cities": [],
          "StateName": "Coronie"
        },
        {
          "Cities": [],
          "StateName": "Marowijne"
        },
        {
          "Cities": [],
          "StateName": "Nickerie"
        },
        {
          "Cities": [],
          "StateName": "Para"
        },
        {
          "Cities": [],
          "StateName": "Paramaribo"
        },
        {
          "Cities": [],
          "StateName": "Saramacca"
        },
        {
          "Cities": [],
          "StateName": "Wanica"
        }
      ],
      "CountryName": "Suriname"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Svalbard"
        }
      ],
      "CountryName": "Svalbard And Jan Mayen Islands"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Hhohho"
        },
        {
          "Cities": [],
          "StateName": "Lubombo"
        },
        {
          "Cities": [],
          "StateName": "Manzini"
        },
        {
          "Cities": [],
          "StateName": "Shiselweni"
        }
      ],
      "CountryName": "Swaziland"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Alvsborgs Lan"
        },
        {
          "Cities": [],
          "StateName": "Angermanland"
        },
        {
          "Cities": [],
          "StateName": "Blekinge"
        },
        {
          "Cities": [],
          "StateName": "Bohuslan"
        },
        {
          "Cities": [],
          "StateName": "Dalarna"
        },
        {
          "Cities": [],
          "StateName": "Gavleborg"
        },
        {
          "Cities": [],
          "StateName": "Gaza"
        },
        {
          "Cities": [],
          "StateName": "Gotland"
        },
        {
          "Cities": [],
          "StateName": "Halland"
        },
        {
          "Cities": [],
          "StateName": "Jamtland"
        },
        {
          "Cities": [],
          "StateName": "Jonkoping"
        },
        {
          "Cities": [],
          "StateName": "Kalmar"
        },
        {
          "Cities": [],
          "StateName": "Kristianstads"
        },
        {
          "Cities": [],
          "StateName": "Kronoberg"
        },
        {
          "Cities": [],
          "StateName": "Norrbotten"
        },
        {
          "Cities": [],
          "StateName": "Orebro"
        },
        {
          "Cities": [],
          "StateName": "Ostergotland"
        },
        {
          "Cities": [],
          "StateName": "Saltsjo-Boo"
        },
        {
          "Cities": [],
          "StateName": "Skane"
        },
        {
          "Cities": [],
          "StateName": "Smaland"
        },
        {
          "Cities": [],
          "StateName": "Sodermanland"
        },
        {
          "Cities": [],
          "StateName": "Stockholm"
        },
        {
          "Cities": [],
          "StateName": "Uppsala"
        },
        {
          "Cities": [],
          "StateName": "Varmland"
        },
        {
          "Cities": [],
          "StateName": "Vasterbotten"
        },
        {
          "Cities": [],
          "StateName": "Vastergotland"
        },
        {
          "Cities": [],
          "StateName": "Vasternorrland"
        },
        {
          "Cities": [],
          "StateName": "Vastmanland"
        },
        {
          "Cities": [],
          "StateName": "Vastra Gotaland"
        }
      ],
      "CountryName": "Sweden"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Aargau"
        },
        {
          "Cities": [],
          "StateName": "Appenzell Inner-Rhoden"
        },
        {
          "Cities": [],
          "StateName": "Appenzell-Ausser Rhoden"
        },
        {
          "Cities": [],
          "StateName": "Basel-Landschaft"
        },
        {
          "Cities": [],
          "StateName": "Basel-Stadt"
        },
        {
          "Cities": [],
          "StateName": "Bern"
        },
        {
          "Cities": [],
          "StateName": "Canton Ticino"
        },
        {
          "Cities": [],
          "StateName": "Fribourg"
        },
        {
          "Cities": [],
          "StateName": "Geneve"
        },
        {
          "Cities": [],
          "StateName": "Glarus"
        },
        {
          "Cities": [],
          "StateName": "Graubunden"
        },
        {
          "Cities": [],
          "StateName": "Heerbrugg"
        },
        {
          "Cities": [],
          "StateName": "Jura"
        },
        {
          "Cities": [],
          "StateName": "Kanton Aargau"
        },
        {
          "Cities": [],
          "StateName": "Luzern"
        },
        {
          "Cities": [],
          "StateName": "Morbio Inferiore"
        },
        {
          "Cities": [],
          "StateName": "Muhen"
        },
        {
          "Cities": [],
          "StateName": "Neuchatel"
        },
        {
          "Cities": [],
          "StateName": "Nidwalden"
        },
        {
          "Cities": [],
          "StateName": "Obwalden"
        },
        {
          "Cities": [],
          "StateName": "Sankt Gallen"
        },
        {
          "Cities": [],
          "StateName": "Schaffhausen"
        },
        {
          "Cities": [],
          "StateName": "Schwyz"
        },
        {
          "Cities": [],
          "StateName": "Solothurn"
        },
        {
          "Cities": [],
          "StateName": "Thurgau"
        },
        {
          "Cities": [],
          "StateName": "Ticino"
        },
        {
          "Cities": [],
          "StateName": "Uri"
        },
        {
          "Cities": [],
          "StateName": "Valais"
        },
        {
          "Cities": [],
          "StateName": "Vaud"
        },
        {
          "Cities": [],
          "StateName": "Vauffelin"
        },
        {
          "Cities": [],
          "StateName": "Zug"
        },
        {
          "Cities": [],
          "StateName": "Zurich"
        }
      ],
      "CountryName": "Switzerland"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Aleppo"
        },
        {
          "Cities": [],
          "StateName": "Dar'a"
        },
        {
          "Cities": [],
          "StateName": "Dayr-az-Zawr"
        },
        {
          "Cities": [],
          "StateName": "Dimashq"
        },
        {
          "Cities": [],
          "StateName": "Halab"
        },
        {
          "Cities": [],
          "StateName": "Hamah"
        },
        {
          "Cities": [],
          "StateName": "Hims"
        },
        {
          "Cities": [],
          "StateName": "Idlib"
        },
        {
          "Cities": [],
          "StateName": "Madinat Dimashq"
        },
        {
          "Cities": [],
          "StateName": "Tartus"
        },
        {
          "Cities": [],
          "StateName": "al-Hasakah"
        },
        {
          "Cities": [],
          "StateName": "al-Ladhiqiyah"
        },
        {
          "Cities": [],
          "StateName": "al-Qunaytirah"
        },
        {
          "Cities": [],
          "StateName": "ar-Raqqah"
        },
        {
          "Cities": [],
          "StateName": "as-Suwayda"
        }
      ],
      "CountryName": "Syria"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Changhwa"
        },
        {
          "Cities": [],
          "StateName": "Chiayi Hsien"
        },
        {
          "Cities": [],
          "StateName": "Chiayi Shih"
        },
        {
          "Cities": [],
          "StateName": "Eastern Taipei"
        },
        {
          "Cities": [],
          "StateName": "Hsinchu Hsien"
        },
        {
          "Cities": [],
          "StateName": "Hsinchu Shih"
        },
        {
          "Cities": [],
          "StateName": "Hualien"
        },
        {
          "Cities": [],
          "StateName": "Ilan"
        },
        {
          "Cities": [],
          "StateName": "Kaohsiung Hsien"
        },
        {
          "Cities": [],
          "StateName": "Kaohsiung Shih"
        },
        {
          "Cities": [],
          "StateName": "Keelung Shih"
        },
        {
          "Cities": [],
          "StateName": "Kinmen"
        },
        {
          "Cities": [],
          "StateName": "Miaoli"
        },
        {
          "Cities": [],
          "StateName": "Nantou"
        },
        {
          "Cities": [],
          "StateName": "Northern Taiwan"
        },
        {
          "Cities": [],
          "StateName": "Penghu"
        },
        {
          "Cities": [],
          "StateName": "Pingtung"
        },
        {
          "Cities": [],
          "StateName": "Taichung"
        },
        {
          "Cities": [],
          "StateName": "Taichung Hsien"
        },
        {
          "Cities": [],
          "StateName": "Taichung Shih"
        },
        {
          "Cities": [],
          "StateName": "Tainan Hsien"
        },
        {
          "Cities": [],
          "StateName": "Tainan Shih"
        },
        {
          "Cities": [],
          "StateName": "Taipei Hsien"
        },
        {
          "Cities": [],
          "StateName": "Taipei Shih / Taipei Hsien"
        },
        {
          "Cities": [],
          "StateName": "Taitung"
        },
        {
          "Cities": [],
          "StateName": "Taoyuan"
        },
        {
          "Cities": [],
          "StateName": "Yilan"
        },
        {
          "Cities": [],
          "StateName": "Yun-Lin Hsien"
        },
        {
          "Cities": [],
          "StateName": "Yunlin"
        }
      ],
      "CountryName": "Taiwan"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Dushanbe"
        },
        {
          "Cities": [],
          "StateName": "Gorno-Badakhshan"
        },
        {
          "Cities": [],
          "StateName": "Karotegin"
        },
        {
          "Cities": [],
          "StateName": "Khatlon"
        },
        {
          "Cities": [],
          "StateName": "Sughd"
        }
      ],
      "CountryName": "Tajikistan"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Arusha"
        },
        {
          "Cities": [],
          "StateName": "Dar es Salaam"
        },
        {
          "Cities": [],
          "StateName": "Dodoma"
        },
        {
          "Cities": [],
          "StateName": "Iringa"
        },
        {
          "Cities": [],
          "StateName": "Kagera"
        },
        {
          "Cities": [],
          "StateName": "Kigoma"
        },
        {
          "Cities": [],
          "StateName": "Kilimanjaro"
        },
        {
          "Cities": [],
          "StateName": "Lindi"
        },
        {
          "Cities": [],
          "StateName": "Mara"
        },
        {
          "Cities": [],
          "StateName": "Mbeya"
        },
        {
          "Cities": [],
          "StateName": "Morogoro"
        },
        {
          "Cities": [],
          "StateName": "Mtwara"
        },
        {
          "Cities": [],
          "StateName": "Mwanza"
        },
        {
          "Cities": [],
          "StateName": "Pwani"
        },
        {
          "Cities": [],
          "StateName": "Rukwa"
        },
        {
          "Cities": [],
          "StateName": "Ruvuma"
        },
        {
          "Cities": [],
          "StateName": "Shinyanga"
        },
        {
          "Cities": [],
          "StateName": "Singida"
        },
        {
          "Cities": [],
          "StateName": "Tabora"
        },
        {
          "Cities": [],
          "StateName": "Tanga"
        },
        {
          "Cities": [],
          "StateName": "Zanzibar and Pemba"
        }
      ],
      "CountryName": "Tanzania"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Amnat Charoen"
        },
        {
          "Cities": [],
          "StateName": "Ang Thong"
        },
        {
          "Cities": [],
          "StateName": "Bangkok"
        },
        {
          "Cities": [],
          "StateName": "Buri Ram"
        },
        {
          "Cities": [],
          "StateName": "Chachoengsao"
        },
        {
          "Cities": [],
          "StateName": "Chai Nat"
        },
        {
          "Cities": [],
          "StateName": "Chaiyaphum"
        },
        {
          "Cities": [],
          "StateName": "Changwat Chaiyaphum"
        },
        {
          "Cities": [],
          "StateName": "Chanthaburi"
        },
        {
          "Cities": [],
          "StateName": "Chiang Mai"
        },
        {
          "Cities": [],
          "StateName": "Chiang Rai"
        },
        {
          "Cities": [],
          "StateName": "Chon Buri"
        },
        {
          "Cities": [],
          "StateName": "Chumphon"
        },
        {
          "Cities": [],
          "StateName": "Kalasin"
        },
        {
          "Cities": [],
          "StateName": "Kamphaeng Phet"
        },
        {
          "Cities": [],
          "StateName": "Kanchanaburi"
        },
        {
          "Cities": [],
          "StateName": "Khon Kaen"
        },
        {
          "Cities": [],
          "StateName": "Krabi"
        },
        {
          "Cities": [],
          "StateName": "Krung Thep"
        },
        {
          "Cities": [],
          "StateName": "Lampang"
        },
        {
          "Cities": [],
          "StateName": "Lamphun"
        },
        {
          "Cities": [],
          "StateName": "Loei"
        },
        {
          "Cities": [],
          "StateName": "Lop Buri"
        },
        {
          "Cities": [],
          "StateName": "Mae Hong Son"
        },
        {
          "Cities": [],
          "StateName": "Maha Sarakham"
        },
        {
          "Cities": [],
          "StateName": "Mukdahan"
        },
        {
          "Cities": [],
          "StateName": "Nakhon Nayok"
        },
        {
          "Cities": [],
          "StateName": "Nakhon Pathom"
        },
        {
          "Cities": [],
          "StateName": "Nakhon Phanom"
        },
        {
          "Cities": [],
          "StateName": "Nakhon Ratchasima"
        },
        {
          "Cities": [],
          "StateName": "Nakhon Sawan"
        },
        {
          "Cities": [],
          "StateName": "Nakhon Si Thammarat"
        },
        {
          "Cities": [],
          "StateName": "Nan"
        },
        {
          "Cities": [],
          "StateName": "Narathiwat"
        },
        {
          "Cities": [],
          "StateName": "Nong Bua Lam Phu"
        },
        {
          "Cities": [],
          "StateName": "Nong Khai"
        },
        {
          "Cities": [],
          "StateName": "Nonthaburi"
        },
        {
          "Cities": [],
          "StateName": "Pathum Thani"
        },
        {
          "Cities": [],
          "StateName": "Pattani"
        },
        {
          "Cities": [],
          "StateName": "Phangnga"
        },
        {
          "Cities": [],
          "StateName": "Phatthalung"
        },
        {
          "Cities": [],
          "StateName": "Phayao"
        },
        {
          "Cities": [],
          "StateName": "Phetchabun"
        },
        {
          "Cities": [],
          "StateName": "Phetchaburi"
        },
        {
          "Cities": [],
          "StateName": "Phichit"
        },
        {
          "Cities": [],
          "StateName": "Phitsanulok"
        },
        {
          "Cities": [],
          "StateName": "Phra Nakhon Si Ayutthaya"
        },
        {
          "Cities": [],
          "StateName": "Phrae"
        },
        {
          "Cities": [],
          "StateName": "Phuket"
        },
        {
          "Cities": [],
          "StateName": "Prachin Buri"
        },
        {
          "Cities": [],
          "StateName": "Prachuap Khiri Khan"
        },
        {
          "Cities": [],
          "StateName": "Ranong"
        },
        {
          "Cities": [],
          "StateName": "Ratchaburi"
        },
        {
          "Cities": [],
          "StateName": "Rayong"
        },
        {
          "Cities": [],
          "StateName": "Roi Et"
        },
        {
          "Cities": [],
          "StateName": "Sa Kaeo"
        },
        {
          "Cities": [],
          "StateName": "Sakon Nakhon"
        },
        {
          "Cities": [],
          "StateName": "Samut Prakan"
        },
        {
          "Cities": [],
          "StateName": "Samut Sakhon"
        },
        {
          "Cities": [],
          "StateName": "Samut Songkhran"
        },
        {
          "Cities": [],
          "StateName": "Saraburi"
        },
        {
          "Cities": [],
          "StateName": "Satun"
        },
        {
          "Cities": [],
          "StateName": "Si Sa Ket"
        },
        {
          "Cities": [],
          "StateName": "Sing Buri"
        },
        {
          "Cities": [],
          "StateName": "Songkhla"
        },
        {
          "Cities": [],
          "StateName": "Sukhothai"
        },
        {
          "Cities": [],
          "StateName": "Suphan Buri"
        },
        {
          "Cities": [],
          "StateName": "Surat Thani"
        },
        {
          "Cities": [],
          "StateName": "Surin"
        },
        {
          "Cities": [],
          "StateName": "Tak"
        },
        {
          "Cities": [],
          "StateName": "Trang"
        },
        {
          "Cities": [],
          "StateName": "Trat"
        },
        {
          "Cities": [],
          "StateName": "Ubon Ratchathani"
        },
        {
          "Cities": [],
          "StateName": "Udon Thani"
        },
        {
          "Cities": [],
          "StateName": "Uthai Thani"
        },
        {
          "Cities": [],
          "StateName": "Uttaradit"
        },
        {
          "Cities": [],
          "StateName": "Yala"
        },
        {
          "Cities": [],
          "StateName": "Yasothon"
        }
      ],
      "CountryName": "Thailand"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Centre"
        },
        {
          "Cities": [],
          "StateName": "Kara"
        },
        {
          "Cities": [],
          "StateName": "Maritime"
        },
        {
          "Cities": [],
          "StateName": "Plateaux"
        },
        {
          "Cities": [],
          "StateName": "Savanes"
        }
      ],
      "CountryName": "Togo"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Atafu"
        },
        {
          "Cities": [],
          "StateName": "Fakaofo"
        },
        {
          "Cities": [],
          "StateName": "Nukunonu"
        }
      ],
      "CountryName": "Tokelau"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Eua"
        },
        {
          "Cities": [],
          "StateName": "Ha'apai"
        },
        {
          "Cities": [],
          "StateName": "Niuas"
        },
        {
          "Cities": [],
          "StateName": "Tongatapu"
        },
        {
          "Cities": [],
          "StateName": "Vava'u"
        }
      ],
      "CountryName": "Tonga"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Arima-Tunapuna-Piarco"
        },
        {
          "Cities": [],
          "StateName": "Caroni"
        },
        {
          "Cities": [],
          "StateName": "Chaguanas"
        },
        {
          "Cities": [],
          "StateName": "Couva-Tabaquite-Talparo"
        },
        {
          "Cities": [],
          "StateName": "Diego Martin"
        },
        {
          "Cities": [],
          "StateName": "Glencoe"
        },
        {
          "Cities": [],
          "StateName": "Penal Debe"
        },
        {
          "Cities": [],
          "StateName": "Point Fortin"
        },
        {
          "Cities": [],
          "StateName": "Port of Spain"
        },
        {
          "Cities": [],
          "StateName": "Princes Town"
        },
        {
          "Cities": [],
          "StateName": "Saint George"
        },
        {
          "Cities": [],
          "StateName": "San Fernando"
        },
        {
          "Cities": [],
          "StateName": "San Juan"
        },
        {
          "Cities": [],
          "StateName": "Sangre Grande"
        },
        {
          "Cities": [],
          "StateName": "Siparia"
        },
        {
          "Cities": [],
          "StateName": "Tobago"
        }
      ],
      "CountryName": "Trinidad And Tobago"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Aryanah"
        },
        {
          "Cities": [],
          "StateName": "Bajah"
        },
        {
          "Cities": [],
          "StateName": "Bin 'Arus"
        },
        {
          "Cities": [],
          "StateName": "Binzart"
        },
        {
          "Cities": [],
          "StateName": "Gouvernorat de Ariana"
        },
        {
          "Cities": [],
          "StateName": "Gouvernorat de Nabeul"
        },
        {
          "Cities": [],
          "StateName": "Gouvernorat de Sousse"
        },
        {
          "Cities": [],
          "StateName": "Hammamet Yasmine"
        },
        {
          "Cities": [],
          "StateName": "Jundubah"
        },
        {
          "Cities": [],
          "StateName": "Madaniyin"
        },
        {
          "Cities": [],
          "StateName": "Manubah"
        },
        {
          "Cities": [],
          "StateName": "Monastir"
        },
        {
          "Cities": [],
          "StateName": "Nabul"
        },
        {
          "Cities": [],
          "StateName": "Qabis"
        },
        {
          "Cities": [],
          "StateName": "Qafsah"
        },
        {
          "Cities": [],
          "StateName": "Qibili"
        },
        {
          "Cities": [],
          "StateName": "Safaqis"
        },
        {
          "Cities": [],
          "StateName": "Sfax"
        },
        {
          "Cities": [],
          "StateName": "Sidi Bu Zayd"
        },
        {
          "Cities": [],
          "StateName": "Silyanah"
        },
        {
          "Cities": [],
          "StateName": "Susah"
        },
        {
          "Cities": [],
          "StateName": "Tatawin"
        },
        {
          "Cities": [],
          "StateName": "Tawzar"
        },
        {
          "Cities": [],
          "StateName": "Tunis"
        },
        {
          "Cities": [],
          "StateName": "Zaghwan"
        },
        {
          "Cities": [],
          "StateName": "al-Kaf"
        },
        {
          "Cities": [],
          "StateName": "al-Mahdiyah"
        },
        {
          "Cities": [],
          "StateName": "al-Munastir"
        },
        {
          "Cities": [],
          "StateName": "al-Qasrayn"
        },
        {
          "Cities": [],
          "StateName": "al-Qayrawan"
        }
      ],
      "CountryName": "Tunisia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Adana"
        },
        {
          "Cities": [],
          "StateName": "Adiyaman"
        },
        {
          "Cities": [],
          "StateName": "Afyon"
        },
        {
          "Cities": [],
          "StateName": "Agri"
        },
        {
          "Cities": [],
          "StateName": "Aksaray"
        },
        {
          "Cities": [],
          "StateName": "Amasya"
        },
        {
          "Cities": [],
          "StateName": "Ankara"
        },
        {
          "Cities": [],
          "StateName": "Antalya"
        },
        {
          "Cities": [],
          "StateName": "Ardahan"
        },
        {
          "Cities": [],
          "StateName": "Artvin"
        },
        {
          "Cities": [],
          "StateName": "Aydin"
        },
        {
          "Cities": [],
          "StateName": "Balikesir"
        },
        {
          "Cities": [],
          "StateName": "Bartin"
        },
        {
          "Cities": [],
          "StateName": "Batman"
        },
        {
          "Cities": [],
          "StateName": "Bayburt"
        },
        {
          "Cities": [],
          "StateName": "Bilecik"
        },
        {
          "Cities": [],
          "StateName": "Bingol"
        },
        {
          "Cities": [],
          "StateName": "Bitlis"
        },
        {
          "Cities": [],
          "StateName": "Bolu"
        },
        {
          "Cities": [],
          "StateName": "Burdur"
        },
        {
          "Cities": [],
          "StateName": "Bursa"
        },
        {
          "Cities": [],
          "StateName": "Canakkale"
        },
        {
          "Cities": [],
          "StateName": "Cankiri"
        },
        {
          "Cities": [],
          "StateName": "Corum"
        },
        {
          "Cities": [],
          "StateName": "Denizli"
        },
        {
          "Cities": [],
          "StateName": "Diyarbakir"
        },
        {
          "Cities": [],
          "StateName": "Duzce"
        },
        {
          "Cities": [],
          "StateName": "Edirne"
        },
        {
          "Cities": [],
          "StateName": "Elazig"
        },
        {
          "Cities": [],
          "StateName": "Erzincan"
        },
        {
          "Cities": [],
          "StateName": "Erzurum"
        },
        {
          "Cities": [],
          "StateName": "Eskisehir"
        },
        {
          "Cities": [],
          "StateName": "Gaziantep"
        },
        {
          "Cities": [],
          "StateName": "Giresun"
        },
        {
          "Cities": [],
          "StateName": "Gumushane"
        },
        {
          "Cities": [],
          "StateName": "Hakkari"
        },
        {
          "Cities": [],
          "StateName": "Hatay"
        },
        {
          "Cities": [],
          "StateName": "Icel"
        },
        {
          "Cities": [],
          "StateName": "Igdir"
        },
        {
          "Cities": [],
          "StateName": "Isparta"
        },
        {
          "Cities": [],
          "StateName": "Istanbul"
        },
        {
          "Cities": [],
          "StateName": "Izmir"
        },
        {
          "Cities": [],
          "StateName": "Kahramanmaras"
        },
        {
          "Cities": [],
          "StateName": "Karabuk"
        },
        {
          "Cities": [],
          "StateName": "Karaman"
        },
        {
          "Cities": [],
          "StateName": "Kars"
        },
        {
          "Cities": [],
          "StateName": "Karsiyaka"
        },
        {
          "Cities": [],
          "StateName": "Kastamonu"
        },
        {
          "Cities": [],
          "StateName": "Kayseri"
        },
        {
          "Cities": [],
          "StateName": "Kilis"
        },
        {
          "Cities": [],
          "StateName": "Kirikkale"
        },
        {
          "Cities": [],
          "StateName": "Kirklareli"
        },
        {
          "Cities": [],
          "StateName": "Kirsehir"
        },
        {
          "Cities": [],
          "StateName": "Kocaeli"
        },
        {
          "Cities": [],
          "StateName": "Konya"
        },
        {
          "Cities": [],
          "StateName": "Kutahya"
        },
        {
          "Cities": [],
          "StateName": "Lefkosa"
        },
        {
          "Cities": [],
          "StateName": "Malatya"
        },
        {
          "Cities": [],
          "StateName": "Manisa"
        },
        {
          "Cities": [],
          "StateName": "Mardin"
        },
        {
          "Cities": [],
          "StateName": "Mugla"
        },
        {
          "Cities": [],
          "StateName": "Mus"
        },
        {
          "Cities": [],
          "StateName": "Nevsehir"
        },
        {
          "Cities": [],
          "StateName": "Nigde"
        },
        {
          "Cities": [],
          "StateName": "Ordu"
        },
        {
          "Cities": [],
          "StateName": "Osmaniye"
        },
        {
          "Cities": [],
          "StateName": "Rize"
        },
        {
          "Cities": [],
          "StateName": "Sakarya"
        },
        {
          "Cities": [],
          "StateName": "Samsun"
        },
        {
          "Cities": [],
          "StateName": "Sanliurfa"
        },
        {
          "Cities": [],
          "StateName": "Siirt"
        },
        {
          "Cities": [],
          "StateName": "Sinop"
        },
        {
          "Cities": [],
          "StateName": "Sirnak"
        },
        {
          "Cities": [],
          "StateName": "Sivas"
        },
        {
          "Cities": [],
          "StateName": "Tekirdag"
        },
        {
          "Cities": [],
          "StateName": "Tokat"
        },
        {
          "Cities": [],
          "StateName": "Trabzon"
        },
        {
          "Cities": [],
          "StateName": "Tunceli"
        },
        {
          "Cities": [],
          "StateName": "Usak"
        },
        {
          "Cities": [],
          "StateName": "Van"
        },
        {
          "Cities": [],
          "StateName": "Yalova"
        },
        {
          "Cities": [],
          "StateName": "Yozgat"
        },
        {
          "Cities": [],
          "StateName": "Zonguldak"
        }
      ],
      "CountryName": "Turkey"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Ahal"
        },
        {
          "Cities": [],
          "StateName": "Asgabat"
        },
        {
          "Cities": [],
          "StateName": "Balkan"
        },
        {
          "Cities": [],
          "StateName": "Dasoguz"
        },
        {
          "Cities": [],
          "StateName": "Lebap"
        },
        {
          "Cities": [],
          "StateName": "Mari"
        }
      ],
      "CountryName": "Turkmenistan"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Grand Turk"
        },
        {
          "Cities": [],
          "StateName": "South Caicos and East Caicos"
        }
      ],
      "CountryName": "Turks And Caicos Islands"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Funafuti"
        },
        {
          "Cities": [],
          "StateName": "Nanumanga"
        },
        {
          "Cities": [],
          "StateName": "Nanumea"
        },
        {
          "Cities": [],
          "StateName": "Niutao"
        },
        {
          "Cities": [],
          "StateName": "Nui"
        },
        {
          "Cities": [],
          "StateName": "Nukufetau"
        },
        {
          "Cities": [],
          "StateName": "Nukulaelae"
        },
        {
          "Cities": [],
          "StateName": "Vaitupu"
        }
      ],
      "CountryName": "Tuvalu"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Central"
        },
        {
          "Cities": [],
          "StateName": "Eastern"
        },
        {
          "Cities": [],
          "StateName": "Northern"
        },
        {
          "Cities": [],
          "StateName": "Western"
        }
      ],
      "CountryName": "Uganda"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Cherkas'ka"
        },
        {
          "Cities": [],
          "StateName": "Chernihivs'ka"
        },
        {
          "Cities": [],
          "StateName": "Chernivets'ka"
        },
        {
          "Cities": [],
          "StateName": "Crimea"
        },
        {
          "Cities": [],
          "StateName": "Dnipropetrovska"
        },
        {
          "Cities": [],
          "StateName": "Donets'ka"
        },
        {
          "Cities": [],
          "StateName": "Ivano-Frankivs'ka"
        },
        {
          "Cities": [],
          "StateName": "Kharkiv"
        },
        {
          "Cities": [],
          "StateName": "Kharkov"
        },
        {
          "Cities": [],
          "StateName": "Khersonska"
        },
        {
          "Cities": [],
          "StateName": "Khmel'nyts'ka"
        },
        {
          "Cities": [],
          "StateName": "Kirovohrad"
        },
        {
          "Cities": [],
          "StateName": "Krym"
        },
        {
          "Cities": [],
          "StateName": "Kyyiv"
        },
        {
          "Cities": [],
          "StateName": "Kyyivs'ka"
        },
        {
          "Cities": [],
          "StateName": "L'vivs'ka"
        },
        {
          "Cities": [],
          "StateName": "Luhans'ka"
        },
        {
          "Cities": [],
          "StateName": "Mykolayivs'ka"
        },
        {
          "Cities": [],
          "StateName": "Odes'ka"
        },
        {
          "Cities": [],
          "StateName": "Odessa"
        },
        {
          "Cities": [],
          "StateName": "Poltavs'ka"
        },
        {
          "Cities": [],
          "StateName": "Rivnens'ka"
        },
        {
          "Cities": [],
          "StateName": "Sevastopol'"
        },
        {
          "Cities": [],
          "StateName": "Sums'ka"
        },
        {
          "Cities": [],
          "StateName": "Ternopil's'ka"
        },
        {
          "Cities": [],
          "StateName": "Volyns'ka"
        },
        {
          "Cities": [],
          "StateName": "Vynnyts'ka"
        },
        {
          "Cities": [],
          "StateName": "Zakarpats'ka"
        },
        {
          "Cities": [],
          "StateName": "Zaporizhia"
        },
        {
          "Cities": [],
          "StateName": "Zhytomyrs'ka"
        }
      ],
      "CountryName": "Ukraine"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Abu Zabi"
        },
        {
          "Cities": [],
          "StateName": "Ajman"
        },
        {
          "Cities": [],
          "StateName": "Dubai"
        },
        {
          "Cities": [],
          "StateName": "Ras al-Khaymah"
        },
        {
          "Cities": [],
          "StateName": "Sharjah"
        },
        {
          "Cities": [],
          "StateName": "Sharjha"
        },
        {
          "Cities": [],
          "StateName": "Umm al Qaywayn"
        },
        {
          "Cities": [],
          "StateName": "al-Fujayrah"
        },
        {
          "Cities": [],
          "StateName": "ash-Shariqah"
        }
      ],
      "CountryName": "United Arab Emirates"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Aberdeen"
        },
        {
          "Cities": [],
          "StateName": "Aberdeenshire"
        },
        {
          "Cities": [],
          "StateName": "Argyll"
        },
        {
          "Cities": [],
          "StateName": "Armagh"
        },
        {
          "Cities": [],
          "StateName": "Bedfordshire"
        },
        {
          "Cities": [],
          "StateName": "Belfast"
        },
        {
          "Cities": [],
          "StateName": "Berkshire"
        },
        {
          "Cities": [],
          "StateName": "Birmingham"
        },
        {
          "Cities": [],
          "StateName": "Brechin"
        },
        {
          "Cities": [],
          "StateName": "Bridgnorth"
        },
        {
          "Cities": [],
          "StateName": "Bristol"
        },
        {
          "Cities": [],
          "StateName": "Buckinghamshire"
        },
        {
          "Cities": [],
          "StateName": "Cambridge"
        },
        {
          "Cities": [],
          "StateName": "Cambridgeshire"
        },
        {
          "Cities": [],
          "StateName": "Channel Islands"
        },
        {
          "Cities": [],
          "StateName": "Cheshire"
        },
        {
          "Cities": [],
          "StateName": "Cleveland"
        },
        {
          "Cities": [],
          "StateName": "Co Fermanagh"
        },
        {
          "Cities": [],
          "StateName": "Conwy"
        },
        {
          "Cities": [],
          "StateName": "Cornwall"
        },
        {
          "Cities": [],
          "StateName": "Coventry"
        },
        {
          "Cities": [],
          "StateName": "Craven Arms"
        },
        {
          "Cities": [],
          "StateName": "Cumbria"
        },
        {
          "Cities": [],
          "StateName": "Denbighshire"
        },
        {
          "Cities": [],
          "StateName": "Derby"
        },
        {
          "Cities": [],
          "StateName": "Derbyshire"
        },
        {
          "Cities": [],
          "StateName": "Devon"
        },
        {
          "Cities": [],
          "StateName": "Dial Code Dungannon"
        },
        {
          "Cities": [],
          "StateName": "Didcot"
        },
        {
          "Cities": [],
          "StateName": "Dorset"
        },
        {
          "Cities": [],
          "StateName": "Dunbartonshire"
        },
        {
          "Cities": [],
          "StateName": "Durham"
        },
        {
          "Cities": [],
          "StateName": "East Dunbartonshire"
        },
        {
          "Cities": [],
          "StateName": "East Lothian"
        },
        {
          "Cities": [],
          "StateName": "East Midlands"
        },
        {
          "Cities": [],
          "StateName": "East Sussex"
        },
        {
          "Cities": [],
          "StateName": "East Yorkshire"
        },
        {
          "Cities": [],
          "StateName": "England"
        },
        {
          "Cities": [],
          "StateName": "Essex"
        },
        {
          "Cities": [],
          "StateName": "Fermanagh"
        },
        {
          "Cities": [],
          "StateName": "Fife"
        },
        {
          "Cities": [],
          "StateName": "Flintshire"
        },
        {
          "Cities": [],
          "StateName": "Fulham"
        },
        {
          "Cities": [],
          "StateName": "Gainsborough"
        },
        {
          "Cities": [],
          "StateName": "Glocestershire"
        },
        {
          "Cities": [],
          "StateName": "Gwent"
        },
        {
          "Cities": [],
          "StateName": "Hampshire"
        },
        {
          "Cities": [],
          "StateName": "Hants"
        },
        {
          "Cities": [],
          "StateName": "Herefordshire"
        },
        {
          "Cities": [],
          "StateName": "Hertfordshire"
        },
        {
          "Cities": [],
          "StateName": "Ireland"
        },
        {
          "Cities": [],
          "StateName": "Isle Of Man"
        },
        {
          "Cities": [],
          "StateName": "Isle of Wight"
        },
        {
          "Cities": [],
          "StateName": "Kenford"
        },
        {
          "Cities": [],
          "StateName": "Kent"
        },
        {
          "Cities": [],
          "StateName": "Kilmarnock"
        },
        {
          "Cities": [],
          "StateName": "Lanarkshire"
        },
        {
          "Cities": [],
          "StateName": "Lancashire"
        },
        {
          "Cities": [],
          "StateName": "Leicestershire"
        },
        {
          "Cities": [],
          "StateName": "Lincolnshire"
        },
        {
          "Cities": [],
          "StateName": "Llanymynech"
        },
        {
          "Cities": [],
          "StateName": "London"
        },
        {
          "Cities": [],
          "StateName": "Ludlow"
        },
        {
          "Cities": [],
          "StateName": "Manchester"
        },
        {
          "Cities": [],
          "StateName": "Mayfair"
        },
        {
          "Cities": [],
          "StateName": "Merseyside"
        },
        {
          "Cities": [],
          "StateName": "Mid Glamorgan"
        },
        {
          "Cities": [],
          "StateName": "Middlesex"
        },
        {
          "Cities": [],
          "StateName": "Mildenhall"
        },
        {
          "Cities": [],
          "StateName": "Monmouthshire"
        },
        {
          "Cities": [],
          "StateName": "Newton Stewart"
        },
        {
          "Cities": [],
          "StateName": "Norfolk"
        },
        {
          "Cities": [],
          "StateName": "North Humberside"
        },
        {
          "Cities": [],
          "StateName": "North Yorkshire"
        },
        {
          "Cities": [],
          "StateName": "Northamptonshire"
        },
        {
          "Cities": [],
          "StateName": "Northants"
        },
        {
          "Cities": [],
          "StateName": "Northern Ireland"
        },
        {
          "Cities": [],
          "StateName": "Northumberland"
        },
        {
          "Cities": [],
          "StateName": "Nottinghamshire"
        },
        {
          "Cities": [],
          "StateName": "Oxford"
        },
        {
          "Cities": [],
          "StateName": "Powys"
        },
        {
          "Cities": [],
          "StateName": "Roos-shire"
        },
        {
          "Cities": [],
          "StateName": "SUSSEX"
        },
        {
          "Cities": [],
          "StateName": "Sark"
        },
        {
          "Cities": [],
          "StateName": "Scotland"
        },
        {
          "Cities": [],
          "StateName": "Scottish Borders"
        },
        {
          "Cities": [],
          "StateName": "Shropshire"
        },
        {
          "Cities": [],
          "StateName": "Somerset"
        },
        {
          "Cities": [],
          "StateName": "South Glamorgan"
        },
        {
          "Cities": [],
          "StateName": "South Wales"
        },
        {
          "Cities": [],
          "StateName": "South Yorkshire"
        },
        {
          "Cities": [],
          "StateName": "Southwell"
        },
        {
          "Cities": [],
          "StateName": "Staffordshire"
        },
        {
          "Cities": [],
          "StateName": "Strabane"
        },
        {
          "Cities": [],
          "StateName": "Suffolk"
        },
        {
          "Cities": [],
          "StateName": "Surrey"
        },
        {
          "Cities": [],
          "StateName": "Sussex"
        },
        {
          "Cities": [],
          "StateName": "Twickenham"
        },
        {
          "Cities": [],
          "StateName": "Tyne and Wear"
        },
        {
          "Cities": [],
          "StateName": "Tyrone"
        },
        {
          "Cities": [],
          "StateName": "Utah"
        },
        {
          "Cities": [],
          "StateName": "Wales"
        },
        {
          "Cities": [],
          "StateName": "Warwickshire"
        },
        {
          "Cities": [],
          "StateName": "West Lothian"
        },
        {
          "Cities": [],
          "StateName": "West Midlands"
        },
        {
          "Cities": [],
          "StateName": "West Sussex"
        },
        {
          "Cities": [],
          "StateName": "West Yorkshire"
        },
        {
          "Cities": [],
          "StateName": "Whissendine"
        },
        {
          "Cities": [],
          "StateName": "Wiltshire"
        },
        {
          "Cities": [],
          "StateName": "Wokingham"
        },
        {
          "Cities": [],
          "StateName": "Worcestershire"
        },
        {
          "Cities": [],
          "StateName": "Wrexham"
        },
        {
          "Cities": [],
          "StateName": "Wurttemberg"
        },
        {
          "Cities": [],
          "StateName": "Yorkshire"
        }
      ],
      "CountryName": "United Kingdom"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Alabama"
        },
        {
          "Cities": [],
          "StateName": "Alaska"
        },
        {
          "Cities": [],
          "StateName": "Arizona"
        },
        {
          "Cities": [],
          "StateName": "Arkansas"
        },
        {
          "Cities": [],
          "StateName": "Byram"
        },
        {
          "Cities": [
            "Los Angeles",
            "San Diego",
            "San Jose",
            "San Francisco"
          ],
          "StateName": "California"
        },
        {
          "Cities": [],
          "StateName": "Cokato"
        },
        {
          "Cities": [],
          "StateName": "Colorado"
        },
        {
          "Cities": [],
          "StateName": "Connecticut"
        },
        {
          "Cities": [],
          "StateName": "Delaware"
        },
        {
          "Cities": [],
          "StateName": "District of Columbia"
        },
        {
          "Cities": [],
          "StateName": "Florida"
        },
        {
          "Cities": [],
          "StateName": "Georgia"
        },
        {
          "Cities": [],
          "StateName": "Hawaii"
        },
        {
          "Cities": [],
          "StateName": "Idaho"
        },
        {
          "Cities": [],
          "StateName": "Illinois"
        },
        {
          "Cities": [],
          "StateName": "Indiana"
        },
        {
          "Cities": [],
          "StateName": "Iowa"
        },
        {
          "Cities": [],
          "StateName": "Kansas"
        },
        {
          "Cities": [],
          "StateName": "Kentucky"
        },
        {
          "Cities": [],
          "StateName": "Louisiana"
        },
        {
          "Cities": [],
          "StateName": "Lowa"
        },
        {
          "Cities": [],
          "StateName": "Maine"
        },
        {
          "Cities": [],
          "StateName": "Maryland"
        },
        {
          "Cities": [],
          "StateName": "Massachusetts"
        },
        {
          "Cities": [],
          "StateName": "Medfield"
        },
        {
          "Cities": [],
          "StateName": "Michigan"
        },
        {
          "Cities": [],
          "StateName": "Minnesota"
        },
        {
          "Cities": [],
          "StateName": "Mississippi"
        },
        {
          "Cities": [],
          "StateName": "Missouri"
        },
        {
          "Cities": [],
          "StateName": "Montana"
        },
        {
          "Cities": [],
          "StateName": "Nebraska"
        },
        {
          "Cities": [],
          "StateName": "Nevada"
        },
        {
          "Cities": [],
          "StateName": "New Hampshire"
        },
        {
          "Cities": [],
          "StateName": "New Jersey"
        },
        {
          "Cities": [],
          "StateName": "New Jersy"
        },
        {
          "Cities": [],
          "StateName": "New Mexico"
        },
        {
          "Cities": [],
          "StateName": "New York"
        },
        {
          "Cities": [],
          "StateName": "North Carolina"
        },
        {
          "Cities": [],
          "StateName": "North Dakota"
        },
        {
          "Cities": [],
          "StateName": "Ohio"
        },
        {
          "Cities": [],
          "StateName": "Oklahoma"
        },
        {
          "Cities": [],
          "StateName": "Ontario"
        },
        {
          "Cities": [],
          "StateName": "Oregon"
        },
        {
          "Cities": [],
          "StateName": "Pennsylvania"
        },
        {
          "Cities": [],
          "StateName": "Ramey"
        },
        {
          "Cities": [],
          "StateName": "Rhode Island"
        },
        {
          "Cities": [],
          "StateName": "South Carolina"
        },
        {
          "Cities": [],
          "StateName": "South Dakota"
        },
        {
          "Cities": [],
          "StateName": "Sublimity"
        },
        {
          "Cities": [],
          "StateName": "Tennessee"
        },
        {
          "Cities": [],
          "StateName": "Texas"
        },
        {
          "Cities": [],
          "StateName": "Trimble"
        },
        {
          "Cities": [],
          "StateName": "Utah"
        },
        {
          "Cities": [],
          "StateName": "Vermont"
        },
        {
          "Cities": [
            "Richmond",
            "Abingdon",
            "Bluefield"
          ],
          "StateName": "Virginia"
        },
        {
          "Cities": [
            "Seattle",
            "Olympia",
            "Richland",
            "Newport",
            "Port Angeles"
          ],
          "StateName": "Washington"
        },
        {
          "Cities": [],
          "StateName": "West Virginia"
        },
        {
          "Cities": [],
          "StateName": "Wisconsin"
        },
        {
          "Cities": [],
          "StateName": "Wyoming"
        }
      ],
      "CountryName": "United States"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "United States Minor Outlying I"
        }
      ],
      "CountryName": "United States Minor Outlying Islands"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Artigas"
        },
        {
          "Cities": [],
          "StateName": "Canelones"
        },
        {
          "Cities": [],
          "StateName": "Cerro Largo"
        },
        {
          "Cities": [],
          "StateName": "Colonia"
        },
        {
          "Cities": [],
          "StateName": "Durazno"
        },
        {
          "Cities": [],
          "StateName": "FLorida"
        },
        {
          "Cities": [],
          "StateName": "Flores"
        },
        {
          "Cities": [],
          "StateName": "Lavalleja"
        },
        {
          "Cities": [],
          "StateName": "Maldonado"
        },
        {
          "Cities": [],
          "StateName": "Montevideo"
        },
        {
          "Cities": [],
          "StateName": "Paysandu"
        },
        {
          "Cities": [],
          "StateName": "Rio Negro"
        },
        {
          "Cities": [],
          "StateName": "Rivera"
        },
        {
          "Cities": [],
          "StateName": "Rocha"
        },
        {
          "Cities": [],
          "StateName": "Salto"
        },
        {
          "Cities": [],
          "StateName": "San Jose"
        },
        {
          "Cities": [],
          "StateName": "Soriano"
        },
        {
          "Cities": [],
          "StateName": "Tacuarembo"
        },
        {
          "Cities": [],
          "StateName": "Treinta y Tres"
        }
      ],
      "CountryName": "Uruguay"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Andijon"
        },
        {
          "Cities": [],
          "StateName": "Buhoro"
        },
        {
          "Cities": [],
          "StateName": "Buxoro Viloyati"
        },
        {
          "Cities": [],
          "StateName": "Cizah"
        },
        {
          "Cities": [],
          "StateName": "Fargona"
        },
        {
          "Cities": [],
          "StateName": "Horazm"
        },
        {
          "Cities": [],
          "StateName": "Kaskadar"
        },
        {
          "Cities": [],
          "StateName": "Korakalpogiston"
        },
        {
          "Cities": [],
          "StateName": "Namangan"
        },
        {
          "Cities": [],
          "StateName": "Navoi"
        },
        {
          "Cities": [],
          "StateName": "Samarkand"
        },
        {
          "Cities": [],
          "StateName": "Sirdare"
        },
        {
          "Cities": [],
          "StateName": "Surhondar"
        },
        {
          "Cities": [],
          "StateName": "Toskent"
        }
      ],
      "CountryName": "Uzbekistan"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Malampa"
        },
        {
          "Cities": [],
          "StateName": "Penama"
        },
        {
          "Cities": [],
          "StateName": "Sanma"
        },
        {
          "Cities": [],
          "StateName": "Shefa"
        },
        {
          "Cities": [],
          "StateName": "Tafea"
        },
        {
          "Cities": [],
          "StateName": "Torba"
        }
      ],
      "CountryName": "Vanuatu"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Vatican City State (Holy See)"
        }
      ],
      "CountryName": "Vatican City State (Holy See)"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Amazonas"
        },
        {
          "Cities": [],
          "StateName": "Anzoategui"
        },
        {
          "Cities": [],
          "StateName": "Apure"
        },
        {
          "Cities": [],
          "StateName": "Aragua"
        },
        {
          "Cities": [],
          "StateName": "Barinas"
        },
        {
          "Cities": [],
          "StateName": "Bolivar"
        },
        {
          "Cities": [],
          "StateName": "Carabobo"
        },
        {
          "Cities": [],
          "StateName": "Cojedes"
        },
        {
          "Cities": [],
          "StateName": "Delta Amacuro"
        },
        {
          "Cities": [],
          "StateName": "Distrito Federal"
        },
        {
          "Cities": [],
          "StateName": "Falcon"
        },
        {
          "Cities": [],
          "StateName": "Guarico"
        },
        {
          "Cities": [],
          "StateName": "Lara"
        },
        {
          "Cities": [],
          "StateName": "Merida"
        },
        {
          "Cities": [],
          "StateName": "Miranda"
        },
        {
          "Cities": [],
          "StateName": "Monagas"
        },
        {
          "Cities": [],
          "StateName": "Nueva Esparta"
        },
        {
          "Cities": [],
          "StateName": "Portuguesa"
        },
        {
          "Cities": [],
          "StateName": "Sucre"
        },
        {
          "Cities": [],
          "StateName": "Tachira"
        },
        {
          "Cities": [],
          "StateName": "Trujillo"
        },
        {
          "Cities": [],
          "StateName": "Vargas"
        },
        {
          "Cities": [],
          "StateName": "Yaracuy"
        },
        {
          "Cities": [],
          "StateName": "Zulia"
        }
      ],
      "CountryName": "Venezuela"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Bac Giang"
        },
        {
          "Cities": [],
          "StateName": "Binh Dinh"
        },
        {
          "Cities": [],
          "StateName": "Binh Duong"
        },
        {
          "Cities": [],
          "StateName": "Da Nang"
        },
        {
          "Cities": [],
          "StateName": "Dong Bang Song Cuu Long"
        },
        {
          "Cities": [],
          "StateName": "Dong Bang Song Hong"
        },
        {
          "Cities": [],
          "StateName": "Dong Nai"
        },
        {
          "Cities": [],
          "StateName": "Dong Nam Bo"
        },
        {
          "Cities": [],
          "StateName": "Duyen Hai Mien Trung"
        },
        {
          "Cities": [],
          "StateName": "Hanoi"
        },
        {
          "Cities": [],
          "StateName": "Hung Yen"
        },
        {
          "Cities": [],
          "StateName": "Khu Bon Cu"
        },
        {
          "Cities": [],
          "StateName": "Long An"
        },
        {
          "Cities": [],
          "StateName": "Mien Nui Va Trung Du"
        },
        {
          "Cities": [],
          "StateName": "Thai Nguyen"
        },
        {
          "Cities": [],
          "StateName": "Thanh Pho Ho Chi Minh"
        },
        {
          "Cities": [],
          "StateName": "Thu Do Ha Noi"
        },
        {
          "Cities": [],
          "StateName": "Tinh Can Tho"
        },
        {
          "Cities": [],
          "StateName": "Tinh Da Nang"
        },
        {
          "Cities": [],
          "StateName": "Tinh Gia Lai"
        }
      ],
      "CountryName": "Vietnam"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Anegada"
        },
        {
          "Cities": [],
          "StateName": "Jost van Dyke"
        },
        {
          "Cities": [],
          "StateName": "Tortola"
        }
      ],
      "CountryName": "Virgin Islands (British)"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Saint Croix"
        },
        {
          "Cities": [],
          "StateName": "Saint John"
        },
        {
          "Cities": [],
          "StateName": "Saint Thomas"
        }
      ],
      "CountryName": "Virgin Islands (US)"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Alo"
        },
        {
          "Cities": [],
          "StateName": "Singave"
        },
        {
          "Cities": [],
          "StateName": "Wallis"
        }
      ],
      "CountryName": "Wallis And Futuna Islands"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Bu Jaydur"
        },
        {
          "Cities": [],
          "StateName": "Wad-adh-Dhahab"
        },
        {
          "Cities": [],
          "StateName": "al-'Ayun"
        },
        {
          "Cities": [],
          "StateName": "as-Samarah"
        }
      ],
      "CountryName": "Western Sahara"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "'Adan"
        },
        {
          "Cities": [],
          "StateName": "Abyan"
        },
        {
          "Cities": [],
          "StateName": "Dhamar"
        },
        {
          "Cities": [],
          "StateName": "Hadramaut"
        },
        {
          "Cities": [],
          "StateName": "Hajjah"
        },
        {
          "Cities": [],
          "StateName": "Hudaydah"
        },
        {
          "Cities": [],
          "StateName": "Ibb"
        },
        {
          "Cities": [],
          "StateName": "Lahij"
        },
        {
          "Cities": [],
          "StateName": "Ma'rib"
        },
        {
          "Cities": [],
          "StateName": "Madinat San'a"
        },
        {
          "Cities": [],
          "StateName": "Sa'dah"
        },
        {
          "Cities": [],
          "StateName": "Sana"
        },
        {
          "Cities": [],
          "StateName": "Shabwah"
        },
        {
          "Cities": [],
          "StateName": "Ta'izz"
        },
        {
          "Cities": [],
          "StateName": "al-Bayda"
        },
        {
          "Cities": [],
          "StateName": "al-Hudaydah"
        },
        {
          "Cities": [],
          "StateName": "al-Jawf"
        },
        {
          "Cities": [],
          "StateName": "al-Mahrah"
        },
        {
          "Cities": [],
          "StateName": "al-Mahwit"
        }
      ],
      "CountryName": "Yemen"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Central Serbia"
        },
        {
          "Cities": [],
          "StateName": "Kosovo and Metohija"
        },
        {
          "Cities": [],
          "StateName": "Montenegro"
        },
        {
          "Cities": [],
          "StateName": "Republic of Serbia"
        },
        {
          "Cities": [],
          "StateName": "Serbia"
        },
        {
          "Cities": [],
          "StateName": "Vojvodina"
        }
      ],
      "CountryName": "Yugoslavia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Central"
        },
        {
          "Cities": [],
          "StateName": "Copperbelt"
        },
        {
          "Cities": [],
          "StateName": "Eastern"
        },
        {
          "Cities": [],
          "StateName": "Luapala"
        },
        {
          "Cities": [],
          "StateName": "Lusaka"
        },
        {
          "Cities": [],
          "StateName": "North-Western"
        },
        {
          "Cities": [],
          "StateName": "Northern"
        },
        {
          "Cities": [],
          "StateName": "Southern"
        },
        {
          "Cities": [],
          "StateName": "Western"
        }
      ],
      "CountryName": "Zambia"
    },
    {
      "States": [
        {
          "Cities": [],
          "StateName": "Bulawayo"
        },
        {
          "Cities": [],
          "StateName": "Harare"
        },
        {
          "Cities": [],
          "StateName": "Manicaland"
        },
        {
          "Cities": [],
          "StateName": "Mashonaland Central"
        },
        {
          "Cities": [],
          "StateName": "Mashonaland East"
        },
        {
          "Cities": [],
          "StateName": "Mashonaland West"
        },
        {
          "Cities": [],
          "StateName": "Masvingo"
        },
        {
          "Cities": [],
          "StateName": "Matabeleland North"
        },
        {
          "Cities": [],
          "StateName": "Matabeleland South"
        },
        {
          "Cities": [],
          "StateName": "Midlands"
        }
      ],
      "CountryName": "Zimbabwe"
    }
  ];
