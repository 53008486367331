import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonWrapperComponent } from './layout/common-wrapper/common-wrapper.component';
import { AuthInterceptor } from './core/interceptor/auth.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LandingPageModule } from './modules/landing-page/landing-page.module';
import { LayoutModule } from './layout/layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { FormDirective } from './core/directives/form.directive';
import { SharedModule } from './shared/shared.module';




@NgModule({
  declarations: [
    AppComponent,
    CommonWrapperComponent,
    FormDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LandingPageModule,
    LayoutModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
