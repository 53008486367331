<section class="banner_sec">
  <ngb-carousel>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img src="../../../../../assets/images/new-img/slider.jpg" alt="Random first slide">
      </div>
      <div class="carousel-caption">
        <h3>Final <br />Clearance</h3>
        <p>Take 20% Off 'Sale Must-Haves</p>
        <div class="btn_sec">
          <a href="#">Shop Now!</a>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img src="../../../../../assets/images/new-img/slider.jpg" alt="Random Second slide">
      </div>
      <div class="carousel-caption">
        <h3>Final <br />Clearance</h3>
        <p>Take 20% Off 'Sale Must-Haves</p>
        <div class="btn_sec">
          <a href="#">Shop Now!</a>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img src="../../../../../assets/images/new-img/slider.jpg" alt="Random Third slide">
      </div>
      <div class="carousel-caption">
        <h3>Final <br />Clearance</h3>
        <p>Take 20% Off 'Sale Must-Haves</p>
        <div class="btn_sec">
          <a href="#">Shop Now!</a>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
</section>
