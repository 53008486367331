import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { HomeService } from 'src/app/core/apis/home.service';
import { HelperService } from 'src/app/shared/services/helper.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  products: any = [];
  private unsubscribe$: Subject<boolean> = new Subject<boolean>();
  constructor(public homeService: HomeService, private helperService: HelperService,  private readonly _router: Router, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    console.log('this._router.url ', this._router.url.split('/'));
    if(this._router.url.split('/').length === 2)
      this.getProductList();
    this.getSearchTerm();
    this.getSelectedCategory();
    if(this._router.url.split('/').length > 2)
      this.getProductForCategory(this._router.url.split('/')[this._router.url.split('/').length-1]);
  }


  getSearchTerm() {
    this.helperService.emitSearchTerm.subscribe(data => {
      console.log("searchTerm ", data);
    })
  }

  getSelectedCategory() {
    this.helperService.emitSelectedCategory.pipe(take(1)).subscribe(data => {
      console.log("selectedCategory ", data);
      this.getProductForCategory(data.id);
    })
  }


  getProductForCategory(categoryId: string) {
    this.homeService.getProductsForCategory(categoryId).subscribe(res => {
      if(res.status) {
        this.products = res.data;
        console.log('products ', this.products);
      }
    })
  }

  getProductList() {
    this.homeService.getProducts().subscribe(res => {
      if(res.status) {
        this.products = res.data;
        console.log('products0 ', this.products);
      }
    },
    err => {
      console.log("Error ", err);
    })
  }

  onProductSelected(product: any) {
    this._router.navigate([`${product.category.id}/${product.name}/${product.id}`]);
  }

  ngOnDestroy() {
    this.unsubscribe$?.next();
    this.unsubscribe$?.unsubscribe();
    // this.subscription?.unsubscribe();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    console.log('change ');
  }

}
