import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss']
})
export class ToastContainerComponent implements OnInit {

  showToast: boolean = false;
  toastMessage: string = '';
  className: string = '';
  delay: number = 0;
  constructor(private toastService: ToastService) { }

  ngOnInit(): void {
    this.toastService.emitToast.subscribe(toast => {
      this.showToast = true;
      this.toastMessage = toast.message;
      this.className = toast.classname;
      this.delay = toast.delay;
      setTimeout(() => {
        this.showToast = false;
        this.toastMessage = '';
      }, toast.delay);
    });
  }

}
