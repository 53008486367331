import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from 'src/app/shared/models/http-response';
import { UnauthenticatedApiClient } from 'src/app/shared/services/http-api-client.service';


@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private httpApi: UnauthenticatedApiClient) {}
  public getCategory(): Observable<HttpResponse<any>> {
    return this.httpApi.get<any>(`/category`);
  }
  public getProducts(): Observable<HttpResponse<any>> {
    return this.httpApi.get<any>(`/product`);
  }

  public getProductDetails(productId: string): Observable<HttpResponse<any>> {
    return this.httpApi.get<any>(`/product/${productId}`);
  }
  public getProductsForCategory(categoryId: string): Observable<HttpResponse<any>> {
    return this.httpApi.get<any>(`/product/category/${categoryId}`);
  }
}
