import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  // horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  // verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor() { }

  private searchTerm = new Subject<string | any>();
  emitSearchTerm = this.searchTerm.asObservable();

  private selectedCategory = new Subject<string | any>();
  emitSelectedCategory = this.selectedCategory.asObservable();

  searchTermEmit(data: any) {
    this.searchTerm.next(data);
  }

  selectedCategoryEmit(data: any) {
    this.selectedCategory.next(data);
  }
  // showSuccessMessage(message: string, time: number = 3000) {
  //   this.snackBar.open(message, '', {
  //     duration: time,
  //     horizontalPosition: this.horizontalPosition,
  //     verticalPosition: this.verticalPosition,
  //     panelClass: ['success-snackbar']
  //   });
  // }

  // showErrorMessage(message: string, time: number = 2000) {
  //   this.snackBar.open(message, '', {
  //     duration: time,
  //     horizontalPosition: this.horizontalPosition,
  //     verticalPosition: this.verticalPosition,
  //     panelClass: ['error-snackbar']
  //   });
  // }
}
