import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/core/apis/home.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { AuthService } from 'src/app/core/apis/auth.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public isMenuCollapsed = true;
  selectedCategoryId: string = '';
  isUserLoggedIn: boolean = false;
  categories: any = [];
  closeResult = '';
  formGroup: FormGroup;
  password: boolean = true;
  @ViewChild('closeModal') closeModal: ElementRef;


  constructor(private fb: FormBuilder, public homeService: HomeService, private helperService: HelperService, private readonly _router: Router, private modalService: NgbModal,
    private _localStorage: LocalStorageService, public authService: AuthService, private toastService: ToastService) { }
  open(content) {
    this.formGroup = this.fb.group({
      'username': [null, [Validators.required, Validators.email]],
      'password': [null, [Validators.required]],
    })
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  logout() {
    const activeUser = this._localStorage.get('activeUser') as string;
    console.log('activeUser ', activeUser);
    if (activeUser) {
      this.isUserLoggedIn = false;
      this._localStorage.remove('activeUser');
      this.toastService.toastEmit({ message: 'Logged out successfully!', classname: 'bg-success text-light', delay: 3000 });
      this.redirectToLandingPage();
    }
  }

  ngOnInit(): void {
    const activeUser = this._localStorage.get('activeUser') as string;
    if (activeUser) {
      this.isUserLoggedIn = true;
    }
    this.getCategoryList();
  }

  onSubmit():void {
    Object.keys(this.formGroup.controls).forEach(field => { // {1}
      const control = this.formGroup.get(field);            // {2}
      control.markAsTouched({ onlySelf: true });       // {3}
    });
    const activeUser = this._localStorage.get('activeUser') as string;
    console.log('activeUser ', activeUser);
    if (activeUser) {
      this._localStorage.remove('activeUser');
    }
    if(this.formGroup.valid) {
      let data = Object.assign({
        username: this.formGroup.value.username,
        password: this.formGroup.value.password,
        // rememberMe: this.formGroup.value.rememberMe
      });
      this.authService.login(data)
      .subscribe((res)=> {
        if(res.status) {
          this.toastService.toastEmit({ message: 'Successful', classname: 'bg-success text-light', delay: 3000 });
          document.getElementById("closeButton").click();
          this.isUserLoggedIn = true;
          // this.helperService.showSuccessMessage('Successful');
          this._localStorage.set('activeUser', res.data.user);
          this.redirectToLandingPage();
        }
      }, err => {
        console.log("Error in login ", err);
          this.toastService.toastEmit({ message: err.error.message, classname: 'bg-danger text-light', delay: 3000 });
        // this.helperService.showErrorMessage(err.error.message);
      })

    }
  }

  gotoForgotPassword() {
    document.getElementById("closeButton").click();
    this._router.navigate(['/forgot-password']);
  }

  gotoSignUpPage() {
    document.getElementById("closeButton").click();
    this._router.navigate(['/signup']);
  }

  get loginFormfield() {
    return this.formGroup.controls;
  }

  redirectToLandingPage() {
    this._router.navigate(['/']);
  }

  searchProduct(){
    this.helperService.searchTermEmit('test');
  }

  emitSelectedCategory(selectedCategory: any){
    this.isMenuCollapsed = true;
    this.selectedCategoryId = selectedCategory.id;
    this._router.navigate([`/${selectedCategory.name}/${selectedCategory.id}`]);
    this.helperService.selectedCategoryEmit(selectedCategory);
  }
  

  getCategoryList() {
    this.homeService.getCategory().subscribe(res => {
      if(res.status) {
        this.categories = res.data;
      }
    },
    err => {
      console.log("Error ", err);
    })
  }

}
