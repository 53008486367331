import { HttpBackend, HttpErrorResponse } from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpResponse } from '../models/http-response';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, concat, delay, map, mergeMap, retryWhen, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UnauthenticatedApiClient {
  private _baseUrl: string;

  constructor(private readonly _httpClient: HttpClient, private httpBackend: HttpBackend) {
    this._baseUrl = environment.serverUrl;
  }

  delete<TResponse>(
    path: string,
    options?: RequestOptions
  ): Observable<HttpResponse<TResponse>> {
    return this.sendRequest<TResponse>('DELETE', path, options);
  }

  get<TResponse>(
    path: string,
    options?: RequestOptions
  ): Observable<HttpResponse<TResponse>> {
    return this.sendRequest<TResponse>('GET', path, undefined, options);
  }

  patch<TResponse>(
    path: string,
    data: any,
    options?: RequestOptions
  ): Observable<HttpResponse<TResponse>> {
    return this.sendRequest<TResponse>('PATCH', path, data, options);
  }

  post<TResponse>(
    path: string,
    data: any,
    options?: RequestOptions
  ): Observable<HttpResponse<TResponse>> {
    return this.sendRequest<TResponse>('POST', path, data, options);
  }

  async getInstituteSetup(path: string) {
    //Use httpBackend to ignore interceptor
    const url = this.getRequestUrl(path);
    const newHttpClient = new HttpClient(this.httpBackend);
    const options = {
      headers: new HttpHeaders({ "skip":"true" })
    };
    return await newHttpClient.get(url, options).pipe(catchError(this.formatErrors)).toPromise();
  }

  getFile(path: string): Observable<HttpResponse<any>> {
    const url = this.getRequestUrl(path);
    return this._httpClient.get(url, { responseType: 'blob' })
      .pipe(catchError(this.pdfFormatErrors));
  }
  
  getZippedFile(path: string,body:any): Observable<HttpResponse<any>> {
    const url = this.getRequestUrl(path);
    return this._httpClient.post(url, body,{ responseType: 'blob' })
      .pipe(catchError(this.formatErrors));
  }

  formatErrors(error: HttpErrorResponse): Observable<any> {
    if(error && error.error) {
      console.log(error)
      return throwError(error.error);
    }
  }

  pdfFormatErrors(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof Blob) {
      return from(Promise.resolve(error).then(async x => { throw new HttpErrorResponse({ error: JSON.parse(await x.error.text()), headers: x.headers, status: x.status, statusText: x.statusText, url: x.url ?? undefined })}));
    }
    return throwError(error.error);
  }


  put<TResponse>(
    path: string,
    data?: any,
    options?: RequestOptions
  ): Observable<HttpResponse<TResponse>> {
    return this.sendRequest<TResponse>('PUT', path, data, options);
  }

  protected sendRequest<TResponse>(
    method: HttpMethod,
    path: string,
    data?: any,
    options?: RequestOptions
  ): Observable<HttpResponse<TResponse>> {
    options = options || { skipAuth: false };
    options.headers = options.headers || new HttpHeaders();
    const url = this.getRequestUrl(path);

    const httpOptions = {
      params: options.params,
      headers: options.headers
    };

    let ob: Observable<Envelope<TResponse>>;
    switch (method) {
      case 'DELETE':
        ob = this._httpClient.delete<Envelope<TResponse>>(url, httpOptions)
        break;
      case 'GET':
        ob = this._httpClient.get<Envelope<TResponse>>(url, httpOptions).pipe(
          retryWhen(error =>
            error.pipe(
              mergeMap((error: HttpErrorResponse) => {
                if (error.status === 408 || error.error instanceof ErrorEvent) {
                  // a timeout or a client-side or network error occurred. retry
                  return of(error.status).pipe(delay(500));
                }
                return throwError(error);
              }),
              take(3),
              concat(throwError(error))
            )

          )
        );

        break;
      case 'PATCH':
        ob = this._httpClient.patch<Envelope<TResponse>>(url, data, httpOptions);
        break;
      case 'POST':
        ob = this._httpClient.post<Envelope<TResponse>>(url, data, httpOptions);
        break;
      case 'PUT':
        ob = this._httpClient.put<Envelope<TResponse>>(url, data, httpOptions);
        break;
      default:
        throw new Error(`${method} is not a supported method.`);
    }

    return ob.pipe(map((responseData) => {
      if (responseData.data) {
        const r = new HttpResponse<TResponse>(responseData.data);
        r.status = responseData.status;
        r.message = responseData.message;
        r.metadata = responseData.metadata;
        return r;
      } else {
        const r = new HttpResponse<TResponse>(responseData.ReturnData);
        r.status = responseData.status;
        r.message = responseData.message;
        return r;
      }
    }));
  }

  private getRequestUrl(path: string) {
    if (path.includes('category') || path.includes('product') || path.includes('cart') || path.includes('job'))
      return 'https://dev-api-inventory.suryaweb.in' + path;
      // return 'http://localhost:3001' + path;
    return this._baseUrl + path;
  }
}

export type HttpMethod = 'DELETE' | 'GET' | 'PUT' | 'POST' | 'PATCH';

export interface RequestOptions {
  headers?: HttpHeaders;
  params?: HttpParams;
  skipAuth?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
}

interface Envelope<T> {
  responseData?: T;
  ReturnData?: T;
  Status?: boolean;
  Message?: string;
  status?: boolean;
  message?: string;
  data?: any;
  metadata?: any;
}
