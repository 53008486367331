import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ToastContainerComponent } from './components/toast-container/toast-container.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [PageNotFoundComponent, ToastContainerComponent],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule
  ],
  exports: [ToastContainerComponent]
})
export class SharedModule { }
