import { ErrorMessage } from './error-message';

export class HttpResponse<TResponse> {
    public status: boolean = false;
    public message: string = 'Something Went Wrong!';
    public errorMessages?: ErrorMessage[];
    public errorStatus?: number;
    public metadata?: any;

    constructor(public data?: TResponse) {}

    public hasErrors(): boolean {
        return this.status === false;
    }

    public toErrorString(): string {
      if (!this.hasErrors()) {
          return '';
      }

      let m = '';
      this.errorMessages!.forEach(
        e => {
          m += e.message + '\r\n';
      });
      return m;
    }
}