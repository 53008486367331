import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LandingPageRoutingModule } from './landing-page-routing.module';
import { ProductsComponent } from './components/products/products.component';
import { BannersComponent } from './components/banners/banners.component';
import { LandingPageWrapperComponent } from './pages/landing-page-wrapper/landing-page-wrapper.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [BannersComponent, LandingPageWrapperComponent, ProductsComponent],
  imports: [
    CommonModule,
    LandingPageRoutingModule,
    NgbModule,
  ],
  exports: [
    ProductsComponent,
  ]
})
export class LandingPageModule { }
