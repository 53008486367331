<section class="auth_sec">
  <div class="auth_card">
    <div class="row">
      <div class="col-6 left_col">
        <div class="inner_sec" style="padding-top: 50px;">
          <div>
            <img src="../../../../../assets/images/new-img/auth_img.png" alt="Shopping_img">
          </div>
          <p>Get access to your Orders,<br />
            Wishlist and Recommendations</p>
          <div class="btn_sec">
            <button type="submit" class="btn btn-block" [routerLink]="'/signup'">Sign Up</button>
          </div>
        </div>
      </div>
      <div class="col-6 right_col">
        <div class="col_inner">
          <div class="brand-sec">
            <img src="../../../../../assets/images/logo.png" alt="Surya">
          </div>
          <div class="form_sec">
            <form>
              <div class="row">
                <div class="col-lg-8 m-auto">
                  <div class="form-group">
                    <label>Email</label>
                    <div class="input_group">
                      <img src="../../../../../assets/images/new-img/user.png" class="input_icon" alt="Email">
                      <input type="email" class="form-control" placeholder="Enter email">
                      <span class="focus-border"></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-8 m-auto">
                  <div class="form-group">
                    <label>Password</label>
                    <div class="input_group phone_group">
                      <img src="../../../../../assets/images/new-img/password.png" class="input_icon" alt="Lock">
                      <input class="form-control" placeholder="Enter password">
                      <span class="focus-border"></span>
                      <button type="button" class="btn btn-light"><img src="../../../assets/images/hidden.png"
                          alt="Password Hidden"></button>
                      <button type="button" class="btn btn-light"><img src="../../../assets/images/view.png"
                          alt="Password Visible"></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="link_sec">
                <a [routerLink]="'/forgot-password'">Forgot Password?</a>
              </div>
              <div class="btn_sec">
                <button type="submit" class="btn btn-block">Log In</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
