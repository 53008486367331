<section class="product_sec">
  <div class="product_head">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="head_wrapper">
            <h3>All Products</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="product_body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-6" *ngFor="let product of products" [value]="product.id">
          <div class="pro_wrapper">
            <!-- <span class="wishlist_icon">
              <img src="../../../../../assets/images/pro-wishlist.png" alt="wishlist_icon">
            </span> -->
            <a (click)="onProductSelected(product)">
              <div class="pro_img_wrapper">
                <div class="pro_img_inner" *ngFor="let image of product.images">
                  <img src={{image}} alt="product-image">
                </div>
              </div>
            </a>
            <div class="product_info">
              <a (click)="onProductSelected(product)">
                <p class="product_name">{{product.name}}</p>
              </a>
              <div class="small_info">{{product.description}}</div>
              <!-- <div class="rating_info">
                <span class="raing_wrapper">4.9<img src="../../../../../assets/images/rating-star.png"
                    alt="Star_Icon"></span>
                <span class="total_review">(5,772)</span>
              </div> -->
              <a (click)="onProductSelected(product)">
                <div class="price_off_info">
                  <span class="price">${{product.price}}</span>
                  <span class="total_price">${{product.cost}}</span>
                  <span class="percent_off">52% off</span>
                </div>
              </a>
              <div class="product_option_sec">
                <div class="product_color_section">
                  <div class="color_wrapper">
                    <label>
                      <input type="radio" name="color" value="1" checked="true">
                      <span class="custom_radio product_color_white"></span>
                    </label>
                  </div>
                  <div class="color_wrapper">
                    <label>
                      <input type="radio" name="color" value="2">
                      <span class="custom_radio product_color_green"></span>
                    </label>
                  </div>
                  <div class="color_wrapper">
                    <label>
                      <input type="radio" name="color" value="3">
                      <span class="custom_radio product_color_pink"></span>
                    </label>
                  </div>
                  <div class="color_wrapper">
                    <label>
                      <input type="radio" name="color" value="4">
                      <span class="custom_radio product_color_black"></span>
                    </label>
                  </div>
                  <div class="color_wrapper">
                    <label>
                      <input type="radio" name="color" value="5">
                      <span class="custom_radio product_color_gray"></span>
                    </label>
                  </div>
                  <div class="color_wrapper">
                    <label>
                      <input type="radio" name="color" value="6">
                      <span class="custom_radio product_color_yellow"></span>
                    </label>
                  </div>
                </div>
                <div class="product_size_section">
                  <div class="size_wrapper">
                    <label>
                      <input type="radio" name="size" value="1" checked="true">
                      <span class="custom_radio product_size">XS</span>
                    </label>
                  </div>
                  <div class="size_wrapper">
                    <label>
                      <input type="radio" name="size" value="2">
                      <span class="custom_radio product_size">S</span>
                    </label>
                  </div>
                  <div class="size_wrapper">
                    <label>
                      <input type="radio" name="size" value="3">
                      <span class="custom_radio product_size">M</span>
                    </label>
                  </div>
                  <div class="size_wrapper">
                    <label>
                      <input type="radio" name="size" value="5">
                      <span class="custom_radio product_size">L</span>
                    </label>
                  </div>
                  <div class="size_wrapper">
                    <label>
                      <input type="radio" name="size" value="6">
                      <span class="custom_radio product_size">XL</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="product_btn">
                <div class="add_to_cart_btn">
                  <button type="button" class="btn btn-primary btn-add-cart">Add To Cart</button>
                </div>
                <div class="btn-wishlist">
                  <button type="button" class="btn btn-primary btn-wishlist"><img
                      src="../../../../../assets/images/new-img/btn-wishlist.png" alt="Wishlist"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
