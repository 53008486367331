import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppPreloadingStrategyService } from './app-preloading-strategy.service';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { CommonWrapperComponent } from './layout/common-wrapper/common-wrapper.component';
import { SignupComponent } from './modules/auth/components/signup/signup.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './modules/auth/components/forgot-password/forgot-password.component';
import { SigninComponent } from './modules/auth/components/signin/signin.component';

const routes: Routes = [
  {
  path: '',
  component: CommonWrapperComponent,
  children: [
  {
    path: '',
    loadChildren: () => import('./modules/landing-page/landing-page.module').then(m => m.LandingPageModule),
    data: { preload: true, delay: false }
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    data: { preload: true, delay: false }
  },
  {
    path: ':categoryName/:categoryId',
    loadChildren: () => import('./modules/product-listing/product-listing.module').then(m => m.ProductListingModule),
    data: { preload: true, delay: false }
  },
  {
    path: ':categoryId/:productName/:productId',
    loadChildren: () => import('./modules/single-product-view/single-product-view.module').then(m => m.SingleProductViewModule),
    data: { preload: true, delay: false }
  },
  {
    path: 'viewCart',
    loadChildren: () => import('./modules/shopping-cart/shopping-cart.module').then(m => m.ShoppingCartModule),
    data: { preload: true, delay: false }
  },
  {
    path: 'checkout',
    loadChildren: () => import('./modules/checkout-payment/checkout-payment.module').then(m => m.CheckoutPaymentModule),
    data: { preload: true, delay: false }
  }],
},
{
  path: 'login',
  pathMatch: 'full',
  component: SigninComponent
},  
{
    path: 'signup',
    pathMatch: 'full',
    component: SignupComponent
  },
  {
    path: 'forgot-password',
    pathMatch: 'full',
    component: ForgotPasswordComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandlerService }, AppPreloadingStrategyService]
})
export class AppRoutingModule { }