<header class="st_header">
  <div class="top_header">
    <div class="container-fluid">
      <div class="top_header_wrapper">
        <div class="brand_sec">
          <a [routerLink]="'.'">
            <img src="../../../assets/images/logo.png" alt="Surya e-Commerce Logo">
          </a>
        </div>
        <div class="pr_search_wrapper">
          <input class="form-control" type="search" placeholder="Search for a Product, Brand or Category">
          <button type="button" (click)="searchProduct()" class="btn btn-primary btn-search"><img
              src="../../../../../assets/images/search-icon.png" alt="Search-Icon"></button>
        </div>
        <div class="auth_settings">
          <div class="highlight_sec">
            <p>Free shipping on all orders. No minimum purchase</p>
          </div>
          <div class="auth_inner">
            <ul *ngIf="isUserLoggedIn">
              <li>
                <a [routerLink]="'/viewCart'">
                  <img src="../../../assets/images/new-img/cart.png" alt="Bag">
                  Shopping Cart
                  <span class="cart-counter">0</span>
                </a>
              </li>
              <li>
                <a [routerLink]="'.'">
                  <img src="../../../assets/images/new-img/wishlist.png" alt="Wishlist">
                  My Wishlist
                  <span class="cart-counter">0</span>
                </a>
              </li>
              <li>
                <a [routerLink]="''">
                  <img src="../../../assets/images/new-img/auth.png" alt="Profile">
                  <span>Profile</span>
                </a>
                <ul class="authsub_menu" style="display: none;">
                  <li>
                    <a (click)="logout()">
                      <img src="../../../assets/images/logout.png" alt="Logout">
                      <span>Logout</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul *ngIf="!isUserLoggedIn">
              <li>
                <a (click)="open(content)">
                  <img src="../../../assets/images/new-img/auth.png" alt="User">
                  Sign In
                </a>
                or
                <a [routerLink]="'/signup'">
                  Create an Account
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav class="navbar navbar-expand-lg navbar-light st_nav">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">&#9776;</button>
      <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="#">
              <span>
                <img src="../../../assets/images/new-img/top-offer.png" alt="Menu-Icon">
              </span>
              <span>Top Offers</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <span>
                <img src="../../../assets/images/new-img/grocery.png" alt="Menu-Icon">
              </span>
              <span>Grocery</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <span>
                <img src="../../../assets/images/new-img/mobiles.png" alt="Menu-Icon">
              </span>
              <span>Mobiles</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <span>
                <img src="../../../assets/images/new-img/fashion.png" alt="Menu-Icon">
              </span>
              <span>Fashion</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <span>
                <img src="../../../assets/images/new-img/home.png" alt="Menu-Icon">
              </span>
              <span>Home</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <span>
                <img src="../../../assets/images/new-img/appliances.png" alt="Menu-Icon">
              </span>
              <span>Appliances</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <span>
                <img src="../../../assets/images/new-img/beauty-toy.png" alt="Menu-Icon">
              </span>
              <span>Beauty, Toys & More</span>
            </a>
          </li>
          <!-- <li class="nav-item" *ngFor="let category of categories" [value]="category.id">
            <a class="nav-link" [ngClass]="{'active': selectedCategoryId ===category.id}"
              (click)="emitSelectedCategory(category)">{{category.name}}</a>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</header>
<!-- <header class="st_header">
  <div class="top_header">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-md-2 col-12">
          <div class="wrapper_left header-items">
            <a class="navbar-brand" [routerLink]="'.'">
              <img src="../../../../../assets/images/logo.png" alt="Surya e-Commerce Logo">
            </a>
          </div>
        </div>
        <div class="col-md-7 col-12">
          <div class="wrapper_center header-items">
            <div class="nav-form">
              <form>
                <input class="form-control" type="search" placeholder="Search for a Product, Brand or Category">
                <button type="button" (click)="searchProduct()" class="btn btn-primary btn-search"><img
                    src="../../../../../assets/images/search-icon.png" alt="Search-Icon"></button>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-12">
          <div class="wrapper_right header-items">
            <ul *ngIf="isUserLoggedIn">
              <li>
                <a [routerLink]="'.'">
                  <img src="../../../../../assets/images/wishlist_icon.png" alt="Wishlist">
                  <span>Wishlist</span>
                  <span class="number_count">
                    15
                  </span>
                </a>
              </li>
              <li>
                <a [routerLink]="'/viewCart'">
                  <img src="../../../../../assets/images/cart_icon.png" alt="Cart_Icon">
                  <span>Cart</span>
                  <span class="number_count">
                    15
                  </span>
                </a>
              </li>
              <li>
                <a [routerLink]="''">
                  <img src="../../../../../assets/images/profile_icon.png" alt="Profile_Icon">
                  <span>Profile</span>
                </a>
                <ul class="authsub_menu">
                  <li>
                    <a (click)="logout()">
                      <img src="../../../assets/images/logout.png" alt="Logout">
                      <span>Logout</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul *ngIf="!isUserLoggedIn">
              <li>
                <a (click)="open(content)">
                  <img src="../../../assets/images/enter.png" alt="Login">
                  <span>Login</span>
                </a>
              </li>
              <li>
                <a [routerLink]="'/signup'">
                  <img src="../../../assets/images/add-user.png" alt="Signup">
                  <span>Signup</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav class="navbar navbar-expand-lg navbar-light st_nav">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
        &#9776;
      </button>
      <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
        <ul class="navbar-nav"> -->
<!-- <li class="nav-item">
            <span>
              <img src="../../../../../assets/images/menu-icon.png" alt="Menu">
              Search by Category
            </span>
          </li> -->
<!-- <li class="nav-item" *ngFor="let category of categories" [value]="category.id">
            <a class="nav-link" [ngClass]="{'active': selectedCategoryId ===category.id}"
              (click)="emitSelectedCategory(category)">{{category.name}}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header> -->
<!-- Sign In Dialog Modal -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Login</h4>
    <button id="closeButton" type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <img src="../../../assets/images/close.png" alt="Cross">
    </button>
  </div>
  <div class="modal-body">
    <div class="logo_content">
      <div class="logo_sec">
        <img src="../../../../../assets/images/logo.png" alt="Logo">
      </div>
      <div class="surya_head">
        <h6>Welcome back</h6>
        <h4>Sign In to your account</h4>
      </div>
    </div>
    <div class="surya_form">
      <form action="" [formGroup]="formGroup">
        <!-- <div class="form-group">
          <label>Your Email</label>
          <input formControlName="username" type="email" class="form-control" placeholder="Enter email">
          <div class="error-message"
            *ngIf="loginFormfield.username.touched && loginFormfield.username.errors?.required">
            <small>Please enter an email</small>
          </div>
          <div class="error-message" *ngIf="loginFormfield.username.touched && loginFormfield.username.errors?.email">
            <small>Please enter
              a valid email</small>
          </div>
        </div> -->
        <div class="form-group">
          <label>Email</label>
          <div class="input_group">
            <img src="../../../assets/images/new-img/user.png" class="input_icon" alt="Email">
            <input formControlName="username" type="email" class="form-control" placeholder="Enter email">
            <span class="focus-border"></span>
          </div>
          <div class="error-message"
            *ngIf="loginFormfield.username.touched && loginFormfield.username.errors?.required">
            <small>Please enter an email</small>
          </div>
          <div class="error-message" *ngIf="loginFormfield.username.touched && loginFormfield.username.errors?.email">
            <small>Please enter
              a valid email</small>
          </div>
        </div>
        <!-- <div class="form-group">
          <label>Your Password</label>
          <div class="password-input-wrapper">
            <input [type]="password ? 'password' : 'text'" formControlName="password" class="form-control"
              placeholder="Password">
            <button *ngIf="password" (click)="password = !password" type="button" class="btn btn-light"><img
                src="../../../assets/images/hidden.png" alt="Password Hidden"></button>
            <button *ngIf="!password" (click)="password = !password" type="button" class="btn btn-light"><img
                src="../../../assets/images/view.png" alt="Password Visible"></button>
          </div>
          <div class="error-message"
            *ngIf="loginFormfield.password.touched && loginFormfield.password.errors?.required">
            <small>Please enter password</small>
          </div>
        </div> -->
        <div class="form-group">
          <label>Password</label>
          <div class="input_group phone_group">
            <img src="../../../../../assets/images/new-img/password.png" class="input_icon" alt="Lock">
            <input [type]="password ? 'password' : 'text'" formControlName="password" class="form-control"
              placeholder="Password">
            <span class="focus-border"></span>
            <button *ngIf="password" (click)="password = !password" type="button" class="btn btn-light"><img
                src="../../../assets/images/hidden.png" alt="Password Hidden"></button>
            <button *ngIf="!password" (click)="password = !password" type="button" class="btn btn-light"><img
                src="../../../assets/images/view.png" alt="Password Visible"></button>
          </div>
          <div class="error-message"
            *ngIf="loginFormfield.password.touched && loginFormfield.password.errors?.required">
            <small>Please enter password</small>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="checkbox">
              <label>
                <input type="checkbox" value="">
                <span class="custom_checkbox"></span>
                Remember me
              </label>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="forgot_sec">
              <a (click)="gotoForgotPassword()">Forgot password?</a>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="btn_sec">
              <button #closeModal type="submit" class="btn btn-block" (click)="onSubmit()">Sign In</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn_sec">
      Don't have an account? <a (click)="gotoSignUpPage()">Sign up</a>
    </div>
  </div>
</ng-template>
